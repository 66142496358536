import { ColourOverlay } from '@sparx/accessibility';
import { UserSettings, useUpdateUserSetting, useUserSetting } from 'api/usersettings';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { overlayColourOptions } from 'views/settings/Settings';

type OverlayContextType = {
  // returns the current overlay color
  overlayColour: string;
  // sets the overlay color in the app
  setOverlayColour: (color: string) => void;
  // saves the current overlay color to the user settings. If a color is passed, sets that before saving.
  saveOverlayColour: (color?: string) => void;
  // if the colour has been changed but not saved
  unsavedChanges: boolean;
  // sets the local state back to the saved value
  revertUnsavedChanges: () => void;
};

const overlayContext = createContext<OverlayContextType>({
  overlayColour: '',
  setOverlayColour: () => {},
  saveOverlayColour: () => {},
  unsavedChanges: false,
  revertUnsavedChanges: () => {},
});

// OverlayProvider provides a context with methods for getting and updating the colour overlay, aswell
// as rendering the actual overlay in the users selected colour.
export const OverlayProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: serverOverlayColourSetting = overlayColourOptions.None } = useUserSetting(
    UserSettings.overlaySetting,
    {
      suspense: true,
    },
  );
  // keep a ref to the server value so we can access it in the save method
  const serverOverlayColourSettingRef = useRef(serverOverlayColourSetting);
  // keep the ref up to date with the latest server value
  useEffect(() => {
    serverOverlayColourSettingRef.current = serverOverlayColourSetting;
  }, [serverOverlayColourSetting]);

  const [localOverlayColourState, setLocalOverlayColourState] = useState(
    serverOverlayColourSetting || overlayColourOptions.None,
  );

  const overlay = useMemo(
    () => <ColourOverlay hexColour={localOverlayColourState} />,
    [localOverlayColourState],
  );

  const { mutate: updateUserSetting } = useUpdateUserSetting();

  return (
    <overlayContext.Provider
      value={{
        overlayColour: localOverlayColourState,
        setOverlayColour: setLocalOverlayColourState,
        saveOverlayColour: useCallback(
          (colour?: string) => {
            if (colour) {
              setLocalOverlayColourState(colour);
            }
            // save to user settings
            updateUserSetting({
              key: UserSettings.overlaySetting,
              value: colour || localOverlayColourState,
            });
          },
          [localOverlayColourState, updateUserSetting],
        ),
        unsavedChanges: localOverlayColourState !== serverOverlayColourSetting,
        revertUnsavedChanges: useCallback(() => {
          setLocalOverlayColourState(
            serverOverlayColourSettingRef.current || overlayColourOptions.None,
          );
        }, []),
      }}
    >
      {createPortal(overlay, document.body)}
      {children}
    </overlayContext.Provider>
  );
};

export const useOverlayContext = () => {
  const context = useContext(overlayContext);
  if (!context) {
    throw new Error('useOverlay must be used within an OverlayProvider');
  }
  return context;
};
