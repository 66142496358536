import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { formatDistanceStrict } from 'date-fns';
import { useMemo } from 'react';

export const UntilTimestamp = ({ children }: { children?: Timestamp }) => {
  const until = useMemo(() => {
    if (!children) {
      return '';
    }
    return formatDistanceStrict(Timestamp.toDate(children), new Date(), {
      addSuffix: true,
    });
  }, [children]);

  return <>{until}</>;
};
