import { Box, Button, ButtonGroup, Text, VStack } from '@chakra-ui/react';
import { SubstrandSummary } from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';
import { TopicBlueprint } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { useSessionStorage } from '@sparx/react-utils';
import { useTopicWithStrandsLookup } from 'api/content';
import { TopicPicker } from 'components/TopicPicker';
import React, { PropsWithChildren, useState } from 'react';
import { preprocessTopicDescription } from 'utils/content';
import { ActivityPreparationProps } from 'views/lessons/createactivity/activitytypes';

const variantOptions = [
  ['', 'Default'],
  ['cam:wrong', 'Photo wrong'],
  ['cam:always', 'Photo always'],
] as const;

export const CreateMathsContentActivity = ({
  doSubmit,
  getFooter,
  setTaskName,
}: ActivityPreparationProps) => {
  const subject = 'subjects/maths';
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedLevel, setSelectedLevel] = useSessionStorage(
    `sci/lessons/content/${subject}/level`,
    '1',
  );
  const [defaultCurriculum, setDefaultCurriculum] = useSessionStorage(
    `sci/lessons/content/${subject}/curriculum`,
    '',
  );

  const [variant, setVariant] = useState<'' | 'cam:wrong' | 'cam:always'>('cam:wrong');
  const onSubmit = async () => {
    if (selectedTopic) {
      doSubmit({
        content: {
          oneofKind: 'topic',
          topic: TopicBlueprint.create({
            topicName: selectedTopic,
            level: selectedLevel || '1',
            variant: 'maths' + (variant ? `/${variant}` : ''),
          }),
        },
      });
    }
  };

  const topicLookup = useTopicWithStrandsLookup({ suspense: true, subject });
  const [topics, setTopics] = React.useState<string[]>([]);
  const onSelectSubstrand = (substrand: SubstrandSummary | undefined) => {
    setSelectedTopic('');
    setTopics(substrand?.substrand?.topicNames || []);
    const curriculumId = substrand?.substrand?.name.split('/')[1] || '';
    setDefaultCurriculum(curriculumId ? `curriculums/${curriculumId}` : '');
    setTaskName('', true);
  };

  const levelOptions = ['1', '2', '3', '4'];
  return (
    <>
      <Box px={3}>
        <Box mx={3} p={2} bg="gray.100" borderRadius="md" mb={2}>
          <ButtonGroup isAttached variant="outline" display="flex" width="100%">
            {variantOptions.map(([v, name]) => (
              <OptionButton key={v} selected={variant === v} onClick={() => setVariant(v)}>
                {name}
              </OptionButton>
            ))}
          </ButtonGroup>
        </Box>

        <Box mx={3} p={2} bg="gray.100" borderRadius="md">
          <ButtonGroup isAttached variant="outline" display="flex" width="100%">
            {levelOptions.map(level => (
              <OptionButton
                key={level}
                selected={selectedLevel === level}
                onClick={() => setSelectedLevel(level)}
              >
                Level {level}
              </OptionButton>
            ))}
          </ButtonGroup>
        </Box>

        <TopicPicker
          defaultCurriculumName={defaultCurriculum || ''}
          onSelectSubstrand={onSelectSubstrand}
          height="40vh"
          subject={subject}
        >
          <VStack spacing={1} alignItems="stretch" px={4}>
            {topics.map(topicName => {
              const topic = topicLookup[topicName];
              if (!topic) {
                return null;
              }

              const isSelected = selectedTopic === topic.topic.name;
              return (
                <Button
                  key={topicName}
                  onClick={() => {
                    setSelectedTopic(isSelected ? '' : topic.topic.name);
                    setTaskName(topic.topic.displayName, true);
                  }}
                  bg={isSelected ? 'blue.800' : 'gray.50'}
                  color={isSelected ? 'white' : 'gray.800'}
                  cursor="pointer"
                  _hover={{ bg: isSelected ? undefined : 'gray.100' }}
                  px={4}
                  py={2}
                  borderRadius="md"
                  borderWidth={1}
                  borderColor="gray.200"
                  alignItems="flex-start"
                  flexDirection="column"
                  flex={1}
                  fontWeight="normal"
                  isDisabled={topic.skillCount === 0}
                  whiteSpace="normal"
                  textAlign="left"
                >
                  <Text fontWeight="bold">{topic.topic.displayName}</Text>
                  <Text fontSize="sm" color={isSelected ? 'gray.400' : 'gray.500'}>
                    {preprocessTopicDescription(topic.topic.description)}
                  </Text>
                </Button>
              );
            })}
          </VStack>
        </TopicPicker>
      </Box>
      {getFooter(selectedTopic ? onSubmit : undefined)}
    </>
  );
};

const OptionButton = ({
  children,
  selected,
  onClick,
}: PropsWithChildren<{ selected: boolean; onClick: () => void }>) => (
  <Button
    flex={1}
    bg={selected ? 'blue.800' : 'white'}
    color={selected ? 'white' : 'gray.900'}
    onClick={onClick}
    _hover={{ bg: selected ? undefined : 'gray.100' }}
  >
    {children}
  </Button>
);
