import { isRpcError } from '@sparx/api/utils/rpc-error';
import { useMisSyncContext } from '@sparx/mis-sync-import/src/Context';
import { useTimeVisible } from '@sparx/mis-sync-import/src/hooks';
import { SupportLink } from '@sparx/mis-sync-import/src/MisSyncImport/components/common';
import Button from '@sparx/mis-sync-import/src/MisSyncImport/components/common/Button/Button';
import Card from '@sparx/mis-sync-import/src/MisSyncImport/components/common/Card/Card';
import ErrorPanel from '@sparx/mis-sync-import/src/MisSyncImport/components/errors/ErrorPanel/ErrorPanel';
import SyncIcon from '@sparx/mis-sync-import/src/MisSyncImport/components/SyncProgress/SyncIcon';
import { Tick } from '@sparx/sparx-design/icons';
import classNames from 'classnames';

import styles from './SyncProgress.module.css';

const SyncProgress = ({
  isComplete,
  onBack,
  error,
  syncFailed,
  otherUserRunningSync,
}: {
  isComplete: boolean;
  onBack: (timeVisible: number) => void;
  error: Error | null;
  syncFailed: boolean;
  otherUserRunningSync: boolean;
}) => {
  const { onClassManagerClick } = useMisSyncContext();
  const timeVisible = useTimeVisible();
  const buttonDisabled = !error && !otherUserRunningSync && !isComplete;
  const buttonAction = () => {
    if (!buttonDisabled) {
      onBack(timeVisible.getTimeVisible());
    }
  };

  let errorPanel = null;
  if (
    error &&
    isRpcError(error) &&
    error.code === 'ALREADY_EXISTS' &&
    error.message.includes('conflicting syncs run at school')
  ) {
    errorPanel = (
      <ErrorPanel
        title={"We couldn't complete your sync"}
        action={buttonAction}
        actionText={'Go back to Import and Manage Classes'}
      >
        <p>
          Another user has run a sync since yours started and the data we have is now out of date.
        </p>
        <p>Please go back to the previous page and try again.</p>
      </ErrorPanel>
    );
  } else if (
    (error && isRpcError(error) && error.code === 'ALREADY_EXISTS') ||
    otherUserRunningSync
  ) {
    errorPanel = (
      <ErrorPanel
        title={"We couldn't complete your sync"}
        action={buttonAction}
        actionText={'Go back to Import and Manage Classes'}
      >
        <p>Another user is running a sync and the data we have is now out of date.</p>
        <p>Please go back to the previous page and try again.</p>
      </ErrorPanel>
    );
  } else if (error || syncFailed) {
    errorPanel = (
      <ErrorPanel
        title={"We couldn't complete your sync"}
        action={buttonAction}
        actionText={'Go back to Import and Manage Classes'}
      >
        <p>
          We weren&apos;t able to complete your sync due to a technical issue on our end. Please try
          again.
        </p>
        <p>
          If the issue keeps happening, contact <SupportLink linkText="Sparx Support" />.
        </p>
      </ErrorPanel>
    );
  }

  return (
    <>
      {errorPanel}
      {!errorPanel && (
        <Card className={styles.Card}>
          {errorPanel ? (
            errorPanel
          ) : (
            <>
              {isComplete ? (
                <div className={styles.TickCircle}>
                  <Tick variant="White" className={styles.SyncCompleteIcon} />
                </div>
              ) : (
                <SyncIcon className={classNames(styles.SyncIcon, styles.Spinning)} />
              )}
              <p className={styles.Title}>{isComplete ? 'Sync complete!' : 'Syncing changes'}</p>
              {!isComplete && (
                <p className={classNames(styles.ProgressMessage, isComplete && styles.Complete)}>
                  Sync in progress...
                </p>
              )}
            </>
          )}
          <Button
            isDisabled={buttonDisabled}
            variant="contained"
            onClick={() => {
              if (!buttonDisabled) {
                onBack(timeVisible.getTimeVisible());
              }
            }}
          >
            Import and Manage Classes
          </Button>
          {onClassManagerClick && (
            <Button
              isDisabled={buttonDisabled}
              variant="contained"
              onClick={() => {
                if (!buttonDisabled) {
                  onClassManagerClick();
                }
              }}
              className={styles.ClassManagerButton}
            >
              Class Manager
            </Button>
          )}
        </Card>
      )}
    </>
  );
};

export default SyncProgress;
