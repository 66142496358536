import { Alert, AlertIcon, Box, Button, Flex, Textarea, VStack } from '@chakra-ui/react';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import { TaskItem_Contents_Skill } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Assignment } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { Subject } from '@sparx/api/apis/sparx/science/v1/subject';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useCreateOrUpdateAssignment } from 'api/planner';
import { useSession } from 'api/sessions';
import { addDays } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

interface CustomAssignmentFormProps {
  children: (callback: {
    form: React.ReactNode;
    formId: string;
    isSubmitting: boolean;
  }) => React.ReactNode;
  onSuccess?: (assignment: Assignment) => void;
}

export const CustomAssignmentForm = ({ children, onSuccess }: CustomAssignmentFormProps) => {
  const { data: user } = useSession();
  const { mutateAsync, isLoading, error } = useCreateOrUpdateAssignment();

  const [assignment, setAssignment] = useState<Assignment>(
    Assignment.create({
      generatedTimestamp: undefined,
      groups: undefined,
      name: '',
      schoolName: '',
      title: '',
      userIds: user ? [user.userId] : [],
      startTimestamp: Timestamp.fromDate(new Date()),
      endTimestamp: Timestamp.fromDate(addDays(new Date(), 7)),
      spec: {
        contents: {
          oneofKind: 'staticAssignment',
          staticAssignment: {
            annotations: {},
            tasks: [
              {
                title: '',
                annotations: {},
                items: [
                  {
                    title: '',
                    annotations: {},
                    contents: {
                      subject: Subject.SUBJECT_UNDEFINED,
                      contents: {
                        oneofKind: 'skill',
                        skill: TaskItem_Contents_Skill.create(),
                      },
                    },
                  },
                ],
              },
            ],
          },
        },
      },
    }),
  );

  const [valid, setValid] = useState(true);
  const [value, _setValue] = useState(
    Assignment.toJsonString(assignment, { prettySpaces: 2, emitDefaultValues: true }),
  );

  const format = () =>
    _setValue(Assignment.toJsonString(assignment, { prettySpaces: 2, emitDefaultValues: true }));

  const setValue = (value: string) => {
    _setValue(value);
    try {
      setAssignment(Assignment.fromJsonString(value));
      setValid(true);
    } catch (e) {
      setValid(false);
    }
  };

  const formId = useMemo(() => `assignment-form-${uuid()}`, []);
  const form = (
    <VStack
      as="form"
      id={formId}
      alignItems="flex-start"
      spacing={4}
      onSubmit={e => {
        e.preventDefault();
        mutateAsync({ ...assignment, action: 'create' }).then(resp => onSuccess?.(resp));
      }}
    >
      <>
        {error && (
          <Alert status="error">
            <AlertIcon />
            {(error as RpcError).message}
          </Alert>
        )}
        {valid ? (
          <Alert status="success">
            <Flex>
              <AlertIcon />
              Valid input
            </Flex>
            <Box flex={1} />
            <Button my={-2} onClick={format} colorScheme="green" variant="outline" size="sm">
              Format
            </Button>
          </Alert>
        ) : (
          <Alert status="error">
            <AlertIcon />
            Invalid input
          </Alert>
        )}
        <Textarea
          placeholder="Contents"
          value={value}
          onChange={e => setValue(e.target.value)}
          height="70vh"
          fontSize="14px"
          fontFamily="monospace"
        />
      </>
    </VStack>
  );
  return <>{children({ form, isSubmitting: isLoading, formId })}</>;
};
