import { Box, Flex, Text } from '@chakra-ui/react';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faLock, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';

import { TabDetails, TabStatus } from '..';

export const TabContents = ({ title, subtitle, status, icon }: Omit<TabDetails, 'page'>) => (
  <>
    <Flex alignItems="center">
      <Box color="teal.600" pr={3}>
        <FontAwesomeIcon icon={icon} fixedWidth size="lg" />
      </Box>
      <Box>
        {typeof title === 'string' ? <Text>{title}</Text> : title}
        {subtitle && (
          <Text fontSize="xs" color="gray.600">
            {subtitle}
          </Text>
        )}
      </Box>
    </Flex>
    <Box ml={3} minWidth={5}>
      {status && status !== 'loading' && <StatusIcon status={status} />}
    </Box>
  </>
);

const statusToColor: Record<TabStatus, string> = {
  complete: 'green.500',
  error: 'orange.500',
  loading: 'gray.500',
  locked: 'gray.600',
};

const statusToIcon = {
  complete: faCheck,
  error: faTriangleExclamation,
  loading: undefined,
  locked: faLock,
};

export const StatusIcon = ({ status, size }: { status: TabStatus; size?: SizeProp }) => (
  <Box as="span" color={statusToColor[status]}>
    {status === 'loading' ? (
      <LoadingSpinner />
    ) : statusToIcon[status] ? (
      <FontAwesomeIcon icon={statusToIcon[status]} size={size} fixedWidth />
    ) : null}
  </Box>
);
