import { Accordion, Link, Text } from '@chakra-ui/react';
import { TopicSummary } from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';
import { GeneratedAssignmentTopic } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { TopicWithStrand } from 'api/content';
import { Week } from 'api/school';
import { Paginator } from 'components/pagination/Paginator';
import { TopicSearch } from 'components/topicsearch/TopicSearch';
import React, { useState } from 'react';
import { TopicPanel } from 'views/planner/components/TopicPanel';
import { FilterFields, useTopicSearch } from 'views/planner/components/topicSearch';
import { usePlannerContext } from 'views/planner/Context';

interface TopicPickerProps {
  topics: TopicWithStrand[];
  homeworkTopics: GeneratedAssignmentTopic[];
  setHomeworkTopics: (topics: GeneratedAssignmentTopic[]) => void;
  generated?: boolean;
  canRemove?: boolean;
  withSearch?: boolean;
  week: Week;
}

export const TopicPicker = ({
  topics,
  homeworkTopics,
  setHomeworkTopics,
  generated,
  canRemove,
  withSearch,
  week,
}: TopicPickerProps) => {
  const context = usePlannerContext();
  const topicsInHomework = new Set(homeworkTopics.map(t => t.name));

  const onRemove = (topic: TopicSummary) => () => {
    const name = topic.topic?.name;
    if (name) {
      setHomeworkTopics(homeworkTopics.filter(t => t.name !== name));
    }
  };

  const onAdd = (topic: TopicSummary) => () => {
    const name = topic.topic?.name;
    if (name) {
      setHomeworkTopics(homeworkTopics.filter(t => t.name !== name).concat([{ name }]));
    }
  };

  const [filter, setFilter] = useState<FilterFields>({
    curriculum: context.defaultCurriculumName,
    search: '',
  });

  const updateFilter = (update: Partial<FilterFields>) => setFilter({ ...filter, ...update });
  const filteredTopics = useTopicSearch(topics, filter, {
    enabled: withSearch,
  });

  return (
    <Accordion allowMultiple>
      {withSearch && <TopicSearch filter={filter} setFilter={updateFilter} />}
      <Paginator
        key={JSON.stringify(filter)}
        items={filteredTopics}
        keyGetter={t => t.topic.name}
        noItems={
          withSearch && filteredTopics.length === 0 ? (
            <Text fontSize="sm" color="teal.500" p={2}>
              No topics match your search.{' '}
              <Link textDecoration="underline" onClick={() => updateFilter({ search: '' })}>
                Clear search
              </Link>
            </Text>
          ) : undefined
        }
      >
        {topic => (
          <TopicPanel
            topic={topic}
            inHomework={topicsInHomework.has(topic.topic?.name || '')}
            onRemove={onRemove(topic)}
            onAdd={onAdd(topic)}
            canRemove={canRemove}
            generated={generated}
            week={week}
          />
        )}
      </Paginator>
    </Accordion>
  );
};
