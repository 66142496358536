import { Badge, Box, Flex, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import React, { useMemo } from 'react';

interface ProgressBarValue {
  value: number; // [0,1]
  color: string;
  textColor?: string;
  title?: string;
  tooltipOnly?: boolean;
  label?: React.ReactNode;
}

interface ProgressBarProps {
  values: ProgressBarValue[];
  showPercentages?: boolean;
  showTooltip?: boolean;
}

export const ProgressBar = ({ values, showPercentages, showTooltip }: ProgressBarProps) => {
  const valuesWithPercentage = useMemo(
    () =>
      values.map(val => ({
        ...val,
        ...percentage(val.value),
      })),
    [values],
  );

  const progressBar = (
    <Flex direction="column" width="100%">
      {showPercentages && (
        <Box display="flex" width="100%" lineHeight="1em">
          {valuesWithPercentage.map((value, index) => (
            <Label key={index} {...value} />
          ))}
        </Box>
      )}
      <Box
        bg="gray.100"
        width="100%"
        height={showPercentages ? 3 : 4}
        borderRadius="md"
        overflow="hidden"
        whiteSpace="nowrap"
        lineHeight="0" // prevent spacing
      >
        {valuesWithPercentage
          .filter(v => !v.tooltipOnly)
          .map((value, index) => (
            <Bar key={index} {...value} />
          ))}
      </Box>
    </Flex>
  );

  if (showTooltip) {
    return (
      <Tooltip
        p={2}
        fontSize="md"
        border="1px solid #efefef"
        borderRadius="md"
        mb={-2}
        hasArrow
        backgroundColor="white"
        placement="top"
        label={
          <VStack spacing={1} alignItems="flex-start">
            {valuesWithPercentage
              .filter(v => v.percentage > 0)
              .map((value, index) => (
                <HStack spacing={2} key={index}>
                  <Badge
                    variant="solid"
                    color={value.textColor || 'white'}
                    bg={value.color}
                    width={12}
                    textAlign="center"
                    fontSize="md"
                    letterSpacing="-0.5px"
                  >
                    {value.label || value.percentageText}
                  </Badge>
                  <Text color="gray.800">{value.title}</Text>
                </HStack>
              ))}
          </VStack>
        }
      >
        {progressBar}
      </Tooltip>
    );
  }
  return progressBar;
};

interface PercentageValue {
  percentage: number;
  percentageText: string;
}

interface ProgressBarValueWithPercentage extends ProgressBarValue, PercentageValue {}

const Label = ({
  percentage,
  percentageText,
  color,
  textColor,
  label,
}: ProgressBarValueWithPercentage) => (
  <Box
    fontWeight="bold"
    fontSize="xs"
    color={label ? 'gray.400' : textColor || color}
    textAlign="right"
    width={percentageText}
    whiteSpace="nowrap"
  >
    {label || (percentage < 5 ? null : percentageText)}
  </Box>
);

const Bar = ({ percentageText, color }: ProgressBarValueWithPercentage) => (
  <Box
    width={percentageText}
    height="100%"
    display="inline-block"
    bg={color}
    boxShadow="-1px 0 0 rgba(255, 255, 255, 0.05) inset"
    transition={'width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)'}
  />
);

const percentage = (value: number): PercentageValue => {
  if (value < 0) value = 0;
  if (value > 100) value = 100;
  return { percentage: value, percentageText: `${value}%` };
};
