import { Button, HStack } from '@chakra-ui/react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';

interface PaginatorProps<T> {
  items: T[];
  keyGetter: (item: T) => string;
  children: (item: T, index: number) => React.ReactNode;
  perPage?: number;
  noItems?: React.ReactNode;
}

export const Paginator = <T,>({
  items,
  keyGetter,
  children,
  perPage = 15,
  noItems,
}: PaginatorProps<T>) => {
  const pages = useMemo(() => Math.ceil(items.length / perPage), [items, perPage]);
  const [page, setPage] = useState(0);

  // Reset page count when items list changes
  useEffect(() => {
    if (items.length < page * perPage) {
      setPage(Math.floor(items.length / perPage));
    }
  }, [items.length, perPage, page, setPage]);

  const min = page * perPage;
  const max = Math.min(min + perPage, items.length);
  const visibleItems = items.slice(min, max);

  const minPage = page < 3 ? 0 : page - 2;
  const maxPage = Math.min(minPage + 5, pages);

  return (
    <>
      {visibleItems.map((item, index) => (
        <React.Fragment key={keyGetter?.(item) || index}>{children(item, index)}</React.Fragment>
      ))}
      {visibleItems.length === 0 && noItems}
      {pages > 1 && (
        <HStack spacing={2} justifyContent="center" mt={3}>
          <Button
            size="sm"
            variant="ghost"
            leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
            onClick={() => setPage(page - 1)}
            isDisabled={page - 1 < 0}
          >
            Previous
          </Button>
          {Array.from({ length: maxPage - minPage }).map((_, index) => (
            <Button
              size="sm"
              key={index}
              onClick={() => setPage(minPage + index)}
              colorScheme={minPage + index === page ? 'buttonTeal' : 'gray'}
            >
              {minPage + index + 1}
            </Button>
          ))}
          <Button
            size="sm"
            rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
            variant="ghost"
            onClick={() => setPage(page + 1)}
            isDisabled={page + 1 >= pages}
          >
            Next
          </Button>
        </HStack>
      )}
    </>
  );
};
