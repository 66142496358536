// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/packages/v1/insights.proto" (package "sparx.science.packages.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Insights } from './insights';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { ListFocusSkillsResponse } from './insights';
import type { ListFocusSkillsRequest } from './insights';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.science.packages.v1.Insights
 */
export interface IInsightsClient {
  /**
   * @generated from protobuf rpc: ListFocusSkills(sparx.science.packages.v1.ListFocusSkillsRequest) returns (sparx.science.packages.v1.ListFocusSkillsResponse);
   */
  listFocusSkills(
    input: ListFocusSkillsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListFocusSkillsRequest, ListFocusSkillsResponse>;
}
/**
 * @generated from protobuf service sparx.science.packages.v1.Insights
 */
export class InsightsClient implements IInsightsClient, ServiceInfo {
  typeName = Insights.typeName;
  methods = Insights.methods;
  options = Insights.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: ListFocusSkills(sparx.science.packages.v1.ListFocusSkillsRequest) returns (sparx.science.packages.v1.ListFocusSkillsResponse);
   */
  listFocusSkills(
    input: ListFocusSkillsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListFocusSkillsRequest, ListFocusSkillsResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListFocusSkillsRequest, ListFocusSkillsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}
