// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/reports/v1/dashboard.proto" (package "sparx.science.reports.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { DashboardData } from './dashboard';
import type { UpdateUsageDataResponse } from './dashboard';
import type { UpdateUsageDataRequest } from './dashboard';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { UsageData } from './dashboard';
import type { GetUsageDataRequest } from './dashboard';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.science.reports.v1.DashboardData
 */
export interface IDashboardDataClient {
  /**
   * GetUsageData returns the usage data for a school.
   *
   * @generated from protobuf rpc: GetUsageData(sparx.science.reports.v1.GetUsageDataRequest) returns (sparx.science.reports.v1.UsageData);
   */
  getUsageData(
    input: GetUsageDataRequest,
    options?: RpcOptions,
  ): UnaryCall<GetUsageDataRequest, UsageData>;
  /**
   * UpdateUsageData updates the usage data for a school.
   *
   * @generated from protobuf rpc: UpdateUsageData(sparx.science.reports.v1.UpdateUsageDataRequest) returns (sparx.science.reports.v1.UpdateUsageDataResponse);
   */
  updateUsageData(
    input: UpdateUsageDataRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateUsageDataRequest, UpdateUsageDataResponse>;
}
/**
 * @generated from protobuf service sparx.science.reports.v1.DashboardData
 */
export class DashboardDataClient implements IDashboardDataClient, ServiceInfo {
  typeName = DashboardData.typeName;
  methods = DashboardData.methods;
  options = DashboardData.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * GetUsageData returns the usage data for a school.
   *
   * @generated from protobuf rpc: GetUsageData(sparx.science.reports.v1.GetUsageDataRequest) returns (sparx.science.reports.v1.UsageData);
   */
  getUsageData(
    input: GetUsageDataRequest,
    options?: RpcOptions,
  ): UnaryCall<GetUsageDataRequest, UsageData> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetUsageDataRequest, UsageData>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * UpdateUsageData updates the usage data for a school.
   *
   * @generated from protobuf rpc: UpdateUsageData(sparx.science.reports.v1.UpdateUsageDataRequest) returns (sparx.science.reports.v1.UpdateUsageDataResponse);
   */
  updateUsageData(
    input: UpdateUsageDataRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateUsageDataRequest, UpdateUsageDataResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateUsageDataRequest, UpdateUsageDataResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}
