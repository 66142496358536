import { Accordion, Button, Checkbox, HStack, Text } from '@chakra-ui/react';
import { faList, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GetPackageAnswerHistoryResponse_ActivityWithAnswer,
  ListActivitiesResponse,
  ListActivitiesResponse_QuestionAnswer,
  ListActivitiesResponse_StudentActivity,
} from '@sparx/api/apis/sparx/science/packages/v1/activity';
import { useLocalStorage } from '@sparx/react-utils';
import { useQuery } from '@tanstack/react-query';
import { activitiesClient } from 'api';
import { queryClient } from 'api/client';
import { useStudents } from 'api/school';
import { getSchoolID } from 'api/sessions';
import { LargeLoading } from 'components/loading/LargeLoading';
import { StudentName } from 'components/Names';
import React, { useMemo } from 'react';
import { ActivityResult } from 'views/answerhistory/ActivityResult';
import { useLessonViewContext } from 'views/lessons/LessonView';
import { Panel } from 'views/lessons/panel/Panel';
import { showTeacherMarking, TeacherActivityMark } from 'views/lessons/panel/TeacherActivityMark';

interface ActivityPanelProps {
  selectedTaskItem: string;
  selectedTaskIndex: number;
  studentId: string;
  assignmentId?: string;
  onClose: () => void;
}

export const ActivityPanel = ({
  selectedTaskItem,
  selectedTaskIndex,
  studentId,
  assignmentId,
  onClose,
}: ActivityPanelProps) => {
  const { teamTeaching } = useLessonViewContext();
  const { data, isLoading, refetch } = useListActivities(selectedTaskItem);

  const { data: students } = useStudents({ suspense: false });
  const student = useMemo(
    () => students?.find(s => s.studentId === studentId),
    [students, studentId],
  );

  const answerLookup = useAnswerLookup(data);
  const relevantActivities = useMemo(() => {
    const acts =
      data?.activities
        ?.filter(a => a.activity)
        .sort(
          (a, b) =>
            (b.activity?.startTimestamp?.seconds || 0) - (a.activity?.startTimestamp?.seconds || 0),
        ) || [];

    return addAnswerToActivities(acts, answerLookup);
  }, [data?.activities, answerLookup]);

  const { showAnswerOnly, checkbox: answerOnlyCheckbox } = useAnswerModeCheckbox();

  const { setPanel } = useLessonViewContext();
  const openPanel = (type: 'assignmenttaskitem' | 'surveytaskitem') => {
    if (assignmentId) {
      setPanel({
        type: type,
        assignmentId: assignmentId,
        taskIndex: selectedTaskIndex,
        taskItemIndex: parseInt(selectedTaskItem.split('/items/')[1]),
      });
    }
  };

  if (isLoading) {
    return (
      <Panel onClose={onClose}>
        <LargeLoading />
      </Panel>
    );
  }

  return (
    <Panel
      onClose={onClose}
      title={
        student ? (
          <Text fontWeight="bold" data-sentry-mask>
            <StudentName student={student} />
          </Text>
        ) : undefined
      }
      right={answerOnlyCheckbox}
    >
      {assignmentId && teamTeaching && (
        <HStack spacing={1} mb={1}>
          <Button
            onClick={() => openPanel('assignmenttaskitem')}
            width="100%"
            colorScheme="buttonTeal"
            size="sm"
            leftIcon={<FontAwesomeIcon icon={faPencil} />}
          >
            Mark all students
          </Button>
          <Button
            onClick={() => openPanel('surveytaskitem')}
            width="100%"
            mb={1}
            colorScheme="yellow"
            size="sm"
            leftIcon={<FontAwesomeIcon icon={faList} />}
          >
            Poll
          </Button>
        </HStack>
      )}
      <Accordion allowToggle={true} display="flex" flexDirection="column">
        {relevantActivities.length === 0 && <Text p={3}>No attempts at this question yet</Text>}
        {relevantActivities.map((act, index) => (
          <React.Fragment key={act.activity?.name || index}>
            <ActivityResult
              activityWithAnswer={act}
              index={relevantActivities.length - index}
              first={index === 0}
              mode={showAnswerOnly ? 'answer' : 'combined'}
              // mode="answer"
            />
            {act.activity && showTeacherMarking(act, index) && (
              <TeacherActivityMark
                activity={act.activity}
                studentId={studentId}
                onSave={async () => {
                  if (assignmentId) {
                    queryClient.refetchQueries(['packages', 'assignment', assignmentId]);
                  }
                  return refetch();
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Accordion>
    </Panel>
  );
};

export const useAnswerModeCheckbox = () => {
  const [showAnswerMode, setShowAnswerMode] = useLocalStorage(`sci/lessons/showanswer`);

  const showAnswerOnly = showAnswerMode === 'answer';
  const answerOnlyCheckbox = (
    <Checkbox
      colorScheme="buttonTeal"
      size="sm"
      mr={3}
      isChecked={showAnswerOnly}
      onChange={e => setShowAnswerMode(e.target.checked ? 'answer' : '')}
    >
      Answer only
    </Checkbox>
  );

  return { showAnswerOnly, checkbox: answerOnlyCheckbox };
};

export const useListActivities = (parent: string) =>
  useQuery({
    queryKey: ['activities', parent],
    queryFn: async () =>
      activitiesClient.listActivities({
        schoolName: `schools/${await getSchoolID()}`,
        parent: parent,
      }).response,
    refetchInterval: 5000, // every 5 seconds
    refetchIntervalInBackground: true,
    cacheTime: 0, // instantly remove when not needed
  });

export const useAnswerLookup = (data: ListActivitiesResponse | undefined) =>
  useMemo(
    () =>
      data?.correctAnswers?.reduce<Dictionary<string, ListActivitiesResponse_QuestionAnswer>>(
        (p, v) => {
          p[v.questionName] = v;
          return p;
        },
        {},
      ) || {},
    [data?.correctAnswers],
  );

export interface ActivitySummary
  extends GetPackageAnswerHistoryResponse_ActivityWithAnswer,
    ListActivitiesResponse_StudentActivity {}

export const addAnswerToActivities = (
  acts: ListActivitiesResponse_StudentActivity[],
  answerLookup: Dictionary<string, ListActivitiesResponse_QuestionAnswer>,
) =>
  acts.map((act): ActivitySummary => {
    const answer = answerLookup[act.questionName];
    return {
      ...act,
      correctAnswer: answer?.correctAnswer || {},
      correctAnswerGapEvaluations: answer?.correctAnswerGapEvaluations || {},
    };
  });
