import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Show,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCurriculums, useTopicWithStrandsLookup } from 'api/content';
import React, { useMemo, useState } from 'react';
import { useTopicSearch } from 'views/planner/components/topicSearch';

import { ContinuePractice } from './components/ContinuePracticeCard';
import { Hierarchy } from './components/Hierarchy';
import { SeenInHomework } from './components/Misc';
import { PersonalPractice } from './components/PersonalPracticeCard';
import { TopicRow } from './components/TopicRow';
import { useIndependentLearningContext } from './IndependentLearning';
import { filterTopics } from './utils';

export const CreateATask = () => {
  return (
    <VStack align="stretch" spacing={{ base: 4, md: 6 }}>
      <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: 4, md: 6 }}>
        <PersonalPractice />
        <ContinuePractice />
      </Stack>
      <ChooseTopic />
    </VStack>
  );
};

const ChooseTopic = () => {
  const { userState, curriculumName, setCurriculumName } = useIndependentLearningContext();
  const { data: curriculums = [] } = useCurriculums({ suspense: true });

  const [search, setSearch] = useState('');

  const topicLookup = useTopicWithStrandsLookup({ suspense: true });
  const topics = useMemo(
    () => Object.values(topicLookup).filter(t => filterTopics(userState, t.topic)),
    [topicLookup, userState],
  );

  const searchedTopics = useTopicSearch(
    topics,
    { curriculum: curriculumName, search },
    { enabled: search !== '' },
  );

  const curriculumSelector = (
    <Flex
      direction={{ base: 'row', sm: 'column', md: 'row' }}
      alignItems="center"
      justifyContent="center"
      mt={{ base: 2, sm: 0 }}
      alignSelf="flex-start"
    >
      <Text fontSize="sm" color="gray.500" textAlign="right" mr={{ base: 2, sm: 0, md: 2 }}>
        Curriculum
        <Text as="span" display={{ base: 'inline', sm: 'none', md: 'inline' }}>
          :
        </Text>
      </Text>
      <Select
        width={['100%', '160px']}
        value={curriculumName}
        onChange={e => setCurriculumName(e.target.value)}
      >
        {curriculums.map(curr => (
          <option key={curr.curriculum.name} value={curr.curriculum.name}>
            {curr.curriculum.displayName}
          </option>
        ))}
      </Select>
    </Flex>
  );

  return (
    <Box bg="white" boxShadow="elevationLow" borderRadius="lg" p={{ base: 3, sm: 6, md: 8 }}>
      <Box px={[3, 0]} pt={[3, 0]}>
        <Flex justifyContent="space-between" mb={2} alignItems="center">
          <Flex direction="column" mb={1}>
            <Heading fontSize="xl">Choose a topic</Heading>
            <Text fontSize="sm" color="gray.500">
              Create a short task on a topic of your choice.
            </Text>
            <Flex mt={2} alignItems="center">
              <Text fontSize="sm" color="gray.500" fontStyle="italic" mr={1}>
                This icon will show next to topics that have recently been in your{' '}
                <Text whiteSpace="pre" as="span">
                  homework
                  <SeenInHomework ml={1} />
                </Text>
              </Text>
            </Flex>
          </Flex>
          <Show above="sm">{curriculumSelector}</Show>
        </Flex>
        <Divider />
        <Show below="sm">{curriculumSelector}</Show>
        <HStack width="100%" mt={2} justifyContent="flex-end">
          <Show above="sm">
            <Text fontSize="sm" color="gray.500" textAlign="right" whiteSpace="nowrap">
              Search topics:
            </Text>
          </Show>
          <InputGroup>
            <Input
              minWidth={200}
              flex={1}
              backgroundColor="white"
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder="Search topic names or codes"
              pr={10}
            />
            <InputRightElement width={10}>
              {search != '' ? (
                <Button
                  size="sm"
                  color="gray.700"
                  variant="ghost"
                  borderRadius={100}
                  onClick={() => setSearch('')}
                >
                  <FontAwesomeIcon icon={faClose} />
                </Button>
              ) : (
                <FontAwesomeIcon fill="gray.200" icon={faSearch} />
              )}
            </InputRightElement>
          </InputGroup>
        </HStack>
      </Box>
      {search === '' ? (
        <Hierarchy />
      ) : (
        <VStack alignItems="flex-start" p={4}>
          {searchedTopics.length === 0 && (
            <Text width="100%" textAlign="center" color="gray.500">
              No topics found
            </Text>
          )}
          {searchedTopics.map((t, idx) => (
            <Box width="100%" key={t.topic.name}>
              {idx !== 0 && <Divider mb={2} />}
              <TopicRow topic={t} searchResult={true} />
            </Box>
          ))}
        </VStack>
      )}
    </Box>
  );
};
