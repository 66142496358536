import {
  Box,
  Checkbox,
  Grid,
  GridItem,
  Input,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  useToast,
} from '@chakra-ui/react';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { StaffRole } from '@sparx/api/apis/sparx/school/staff/v2/staff';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { FieldMask } from '@sparx/api/google/protobuf/field_mask';
import { useDebounce } from '@sparx/react-utils/hooks/use-debounce';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Crossfade } from '../components/Crossfade';
import { EditableField, HeaderField } from '../components/FormFields';
import { StaffDetailsField } from '../components/StaffDetailsField';
import { formGetErrorMessage } from '../components/validationMessages';
import { useStaffContext } from '../Context';
import { useGetStaff, useListStaff, useUpsertStaff } from '../queries';
import {
  ContactEditModel,
  dirtyFieldsToMask,
  filterUserRolesForProduct,
  getProductName,
} from '../utils';
import { RemoveStaffMemberSection } from './RemoveStaffMemberSection';
import { RolesSection } from './RolesSection';

export interface StaffDetailFormProps {
  form: React.ReactNode;
  onSubmit: () => void;
  name: string;
  isDirty: boolean;
  saveDisabled: boolean;
  isLoading: boolean;
  warningText: string[];
}

interface StaffDetailRenderProps {
  children: (args: StaffDetailFormProps) => React.ReactElement;
  isSparxStaff?: boolean;
}

interface UpdateStaffDetailProps extends StaffDetailRenderProps {
  staffID: string;
  notFoundComponent: React.ReactElement;
}

export const UpdateStaffDetail = ({
  staffID,
  notFoundComponent,
  isSparxStaff,
  children,
}: UpdateStaffDetailProps) => {
  const { data: staff } = useGetStaff(`staff/${staffID}`, { suspense: true });
  if (!staff) {
    return notFoundComponent;
  }
  return (
    <StaffDetailEditor key={staff.name} staff={staff} isSparxStaff={isSparxStaff}>
      {children}
    </StaffDetailEditor>
  );
};

export const NewStaffDetail = ({ children }: StaffDetailRenderProps) => {
  const { defaultProduct } = useStaffContext();
  const defaultStaff = SchoolStaffMember.create({
    productAccess: true,
    roles: [{ product: defaultProduct, role: StaffRole.TEACHER }],
  });
  return (
    <StaffDetailEditor key="staff/new" staff={defaultStaff} isNew={true}>
      {children}
    </StaffDetailEditor>
  );
};

interface StaffDetailEditorProps extends StaffDetailRenderProps {
  staff: SchoolStaffMember;
  isNew?: boolean;
}

const StaffDetailEditor = ({ staff, isNew, isSparxStaff, children }: StaffDetailEditorProps) => {
  const { defaultProduct, onStaffCreated, currentUserID, styles } = useStaffContext();

  const { data: allStaff } = useListStaff({ suspense: true });

  const toast = useToast();
  const updateStaff = useUpsertStaff();
  const formMethods = useForm<ContactEditModel>({
    defaultValues: { staffMember: staff, role: null, blockSave: false, willRemove: false },
    mode: 'all',
  });
  const {
    handleSubmit,
    watch,
    getValues,
    setValue,
    register,
    reset,
    formState: { isDirty, dirtyFields, errors },
  } = formMethods;
  const blockSave = watch('blockSave');

  const isMultiSchoolUser = getValues('staffMember.multipleSchools') === true;
  const staffRoles = watch('staffMember.roles');
  const staffProductRoles = filterUserRolesForProduct(staffRoles, defaultProduct);

  const displayNameSet = !!staff.displayName;

  const [showDisplayName, setShowDisplayName] = useState(displayNameSet);
  const toggleShowDisplayName = () => {
    if (showDisplayName) {
      // The user is unticking to hide the field, so clear it and dirty it if it was previously nonempty
      setValue('staffMember.displayName', '', { shouldTouch: true, shouldDirty: displayNameSet });
    }
    setShowDisplayName(!showDisplayName);
  };

  const [nameIsTouched, setNameIsTouched] = useState(false);

  const [givenName, familyName, displayName] = watch([
    'staffMember.givenName',
    'staffMember.familyName',
    'staffMember.displayName',
  ]);

  // Don't show the nudge on mount - wait until there's at least some attempt to edit
  useEffect(() => {
    if (givenName !== staff.givenName || familyName !== staff.familyName) {
      setNameIsTouched(true);
    }
  }, [givenName, familyName, staff.givenName, staff.familyName]);

  const showNudge =
    nameIsTouched &&
    [
      givenName.trim().length === 1,
      honorifics.includes(givenName.trim().toLocaleLowerCase()),
      familyName.trim().length === 1,
    ].some(Boolean) &&
    displayName.trim() === '';

  const debouncedShowNudge = useDebounce(showNudge, 500);

  if (!allStaff) {
    // This should never happen due to suspense integration.
    // However, it makes the typings better.
    return null;
  }

  const canEditEmail =
    isNew || !isMultiSchoolUser || isSparxStaff || `staff/${currentUserID}` === staff.name;

  const doSubmit = ({ role: _, staffMember: staff }: ContactEditModel) => {
    const updateMask = dirtyFieldsToMask(dirtyFields.staffMember || {});

    if (!staff) {
      return;
    }

    // Always set product access - this handles the case where a user exists but has a role that
    // doesn’t require product access and a new role that does is being added.
    staff.productAccess = true;
    updateMask.push('product_access');

    return updateStaff
      .mutateAsync({
        staff: {
          ...staff,
          emailAddress: staff.emailAddress.toLowerCase(),
        },
        updateMask: FieldMask.create({ paths: updateMask }),
      })
      .then(data => {
        if (isNew) {
          // Navigate back to the staff list. It will handle displaying a success toast itself
          onStaffCreated('Staff member created');
        } else {
          toast({
            title: 'Staff member updated',
            status: 'success',
            position: 'bottom-left',
            duration: 5000,
          });

          if (updateMask.includes('display_name')) {
            // Show a persistent notication with an explanation when the display name field is changed
            toast({
              title: `Changes to preferred display name take effect when this staff member next logs into ${getProductName(
                defaultProduct,
              )}`,
              status: 'info',
              position: 'bottom-left',
              duration: null,
              isClosable: true,
            });
          }
        }

        reset({ role: null, staffMember: data, blockSave: false, willRemove: false });
      })
      .catch((err: RpcError) => {
        toast({
          title: isNew ? 'Error creating staff member' : 'Error updating staff member',
          // This is the main error message we want to tell the user
          description: err.message?.includes('EMAIL_ALREADY_EXISTS')
            ? 'Email address is already in use'
            : undefined,
          status: 'error',
          position: 'bottom-left',
          duration: 5000,
        });
      });
  };

  const form = (
    <Box backgroundColor="white" p={8} borderRadius="md" boxShadow="lg" width="100%">
      <Grid gap={4} templateColumns="repeat(2, 1fr)">
        <GridItem colSpan={2}>
          <HeaderField name="Personal details" first={true} />
        </GridItem>
        <GridItem>
          <StaffDetailsField
            field="givenName"
            mode={isNew ? 'free-entry' : 'none'}
            allStaff={allStaff}
            filterOtherStaff={staffFilter(defaultProduct)}
          />
        </GridItem>
        <GridItem>
          <StaffDetailsField
            field="familyName"
            mode={isNew ? 'free-entry' : 'none'}
            allStaff={allStaff}
            filterOtherStaff={staffFilter(defaultProduct)}
          />
        </GridItem>
        <GridItem colSpan={2} marginBottom={{ base: 4, md: 0 }}>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={2}>
            <Box display="flex" alignItems="center">
              <Checkbox
                isChecked={showDisplayName}
                onChange={toggleShowDisplayName}
                py={1}
                colorScheme="teal"
                borderColor={styles.checkboxColor}
              >
                Use a preferred display name
              </Checkbox>
            </Box>
            <Box
              display="flex"
              flexGrow={1}
              // Adjust position for lining up when stacked vertically
              left="-4px"
              position="relative"
              sx={{ '& p': { display: 'flex', marginLeft: '4px' } }}
            >
              <Crossfade
                timeout={2000}
                components={[
                  {
                    key: 'nudge',
                    in: debouncedShowNudge,
                    component: <DisplayNameNudge />,
                    wrapperProps: { display: 'flex', alignItems: 'center' },
                  },
                  {
                    key: 'no-nudge',
                    in: !debouncedShowNudge,
                    component: <DisplayNameInfo />,
                    wrapperProps: { display: 'flex', alignItems: 'center' },
                  },
                ]}
              />
            </Box>
          </Stack>
        </GridItem>
        {showDisplayName && (
          <GridItem colSpan={2}>
            <EditableField field="displayName" error={errors?.staffMember?.displayName}>
              <Input {...register('staffMember.displayName')} />
            </EditableField>
          </GridItem>
        )}
        <GridItem colSpan={2}>
          <StaffDetailsField
            field="emailAddress"
            mode={isNew ? 'selection-blur' : 'none'}
            allStaff={allStaff}
            readOnly={!canEditEmail}
            filterOtherStaff={staffFilter(defaultProduct)}
            getErrorMessage={formGetErrorMessage}
          />
        </GridItem>

        <RolesSection />
        {!isNew && <RemoveStaffMemberSection />}
      </Grid>
    </Box>
  );

  return children({
    form: <FormProvider {...formMethods}>{form}</FormProvider>,
    onSubmit: handleSubmit(doSubmit),
    name: isNew ? 'New Staff Member' : `${staff.givenName} ${staff.familyName}`,
    isDirty,
    saveDisabled: blockSave || staffProductRoles.length === 0 || !isDirty,
    isLoading: updateStaff.isLoading,
    warningText: [],
  });
};

const staffFilter =
  (prod: Product) =>
  (sm: SchoolStaffMember): boolean =>
    !(
      sm.productAccess &&
      sm.roles.filter(
        ({ role, product }) =>
          (product === Product.PRODUCT_UNKNOWN &&
            (role === StaffRole.OTHER || role === StaffRole.SENIOR_LEADER_OTHER)) ||
          product === prod,
      ).length > 0
    );

const honorifics = ['dr', 'mr', 'mrs', 'ms', 'mx', 'prof']
  .flatMap(h => [h, `${h}.`])
  .concat(['doctor', 'miss', 'mister', 'missus', 'professor', 'sir']);

const DisplayNameInfo = (): JSX.Element => {
  const { getInfoTooltip, defaultProduct } = useStaffContext();
  return (
    <span>
      {getInfoTooltip(
        <>
          We'd like you to provide accurate details. Use a "preferred display name" if you would
          like to change the name that is displayed, for example, so students don't see it when
          sharing {getProductName(defaultProduct)} in the classroom.
        </>,
      )}
    </span>
  );
};

const DisplayNameNudge = (): JSX.Element => {
  return (
    <Tag
      gridColumnGap={2}
      borderRadius="full"
      paddingX="0"
      sx={{
        '& span:first-of-type': { marginLeft: '0!important' },
        '& svg': { fontSize: '16px' },
      }}
    >
      <TagLeftIcon as={DisplayNameInfo} />
      <TagLabel marginRight={2}>
        If you are providing initials or abbreviations, please consider a display name instead
      </TagLabel>
    </Tag>
  );
};
