import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './Card.module.css';

const Card = ({ children, className }: PropsWithChildren & { className?: string }) => {
  return <div className={classNames(styles.Card, className)}>{children}</div>;
};

export default Card;
