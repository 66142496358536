import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useStudentPackageSummaries } from 'api/packages';
import { useStudentScienceState } from 'api/scienceschool';
import { format } from 'date-fns';
import { PropsWithChildren, useMemo } from 'react';
import { StudentHomeworkHistoryTable } from 'views/students/StudentHomeworkHistoryTable';
import { useStudentViewContext } from 'views/students/StudentView';

import { StudentActivityFeed } from './StudentActivityFeed';

export const StudentSummaryView = () => {
  const { student } = useStudentViewContext();
  const { data: packages } = useStudentPackageSummaries(student.studentId, { suspense: true });

  const counts = useMemo(() => {
    const counts = { complete: 0, incomplete: 0 };
    for (const pkg of packages || []) {
      if (pkg.cancelledTime) continue;
      if (pkg.state?.completionTimestamp) {
        counts.complete++;
      } else {
        counts.incomplete++;
      }
    }
    return counts;
  }, [packages]);

  return (
    <Box backgroundColor="white" p={8} width="100%">
      <Text fontWeight="bold" mb={5} textAlign="center" color="gray.600">
        Summary information for this school year:
      </Text>
      <Grid gridTemplateColumns="repeat(2, 1fr)" gap={5} mb={5}>
        <HomeworkHistoryBox counts={counts} />
        <LastLoginBox studentID={student.studentId} />
      </Grid>
      <StatBox>
        <Text fontWeight="bold" color="gray.600">
          Homework history by week
        </Text>
        <StudentHomeworkHistoryTable packages={packages} studentID={student.studentId} />
      </StatBox>
      <Box mt={5}>
        <StudentActivityFeed studentID={student.studentId} />
      </Box>
    </Box>
  );
};

const HomeworkHistoryBox = ({ counts }: { counts: { complete: number; incomplete: number } }) => (
  <StatBox title="Homework history">
    <Grid gridTemplateColumns="repeat(2, 1fr)">
      <GridItem borderRightWidth={2} borderRightColor="gray.200">
        <Text fontSize="5xl" fontWeight="bold" color="red.600">
          {counts.incomplete}
        </Text>
        <Text>Incomplete</Text>
      </GridItem>
      <GridItem>
        <Text fontSize="5xl" fontWeight="bold" color="green.600">
          {counts.complete}
        </Text>
        <Text>Complete</Text>
      </GridItem>
    </Grid>
  </StatBox>
);

const LastLoginBox = ({ studentID }: { studentID: string }) => {
  const { data: state } = useStudentScienceState(studentID, { suspense: true });
  const formattedLastLogin = useMemo(() => {
    if (!state) {
      return 'Never logged in';
    }
    if (state?.lastLoginTimestamp) {
      return format(Timestamp.toDate(state.lastLoginTimestamp), 'eee do MMMM h:mmaaa');
    }
  }, [state]);

  return (
    <StatBox title="Last login to Sparx Science">
      <Flex alignItems="center" justifyContent="center" flex={1}>
        <Text fontSize="3xl" fontWeight="bold" color="gray.600" mb={2}>
          {formattedLastLogin || 'Unknown'}
        </Text>
      </Flex>
    </StatBox>
  );
};

export const StatBox = ({ children, title }: PropsWithChildren<{ title?: string }>) => (
  <Box
    bg="gray.50"
    display="flex"
    flexDirection="column"
    p={5}
    borderRadius="md"
    textAlign="center"
    alignItems="stretch"
  >
    {title && (
      <Text fontWeight="bold" flex={0} mb={5} fontSize="lg" color="gray.700">
        {title}
      </Text>
    )}
    {children}
  </Box>
);
