// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/calendar/v3/calendar.proto" (package "sparx.school.calendar.v3", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { CalendarAPI } from './calendar';
import type { ListSchoolYearsResponse } from './calendar';
import type { ListSchoolYearsRequest } from './calendar';
import type { SchoolYear } from './calendar';
import type { GetSchoolYearRequest } from './calendar';
import type { Empty } from '../../../../../google/protobuf/empty';
import type { DeleteSchoolPeriodRequest } from './calendar';
import type { UpdateSchoolPeriodRequest } from './calendar';
import type { CreateSchoolPeriodRequest } from './calendar';
import type { SchoolPeriod } from './calendar';
import type { GetSchoolPeriodRequest } from './calendar';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { ListSchoolPeriodsResponse } from './calendar';
import type { ListSchoolPeriodsRequest } from './calendar';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * The CalendarAPI service implements a set of operations to query and manage school dates
 *
 * @generated from protobuf service sparx.school.calendar.v3.CalendarAPI
 */
export interface ICalendarAPIClient {
  /**
   * @generated from protobuf rpc: ListSchoolPeriods(sparx.school.calendar.v3.ListSchoolPeriodsRequest) returns (sparx.school.calendar.v3.ListSchoolPeriodsResponse);
   */
  listSchoolPeriods(
    input: ListSchoolPeriodsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListSchoolPeriodsRequest, ListSchoolPeriodsResponse>;
  /**
   * @generated from protobuf rpc: GetSchoolPeriod(sparx.school.calendar.v3.GetSchoolPeriodRequest) returns (sparx.school.calendar.v3.SchoolPeriod);
   */
  getSchoolPeriod(
    input: GetSchoolPeriodRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSchoolPeriodRequest, SchoolPeriod>;
  /**
   * @generated from protobuf rpc: CreateSchoolPeriod(sparx.school.calendar.v3.CreateSchoolPeriodRequest) returns (sparx.school.calendar.v3.SchoolPeriod);
   */
  createSchoolPeriod(
    input: CreateSchoolPeriodRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateSchoolPeriodRequest, SchoolPeriod>;
  /**
   * @generated from protobuf rpc: UpdateSchoolPeriod(sparx.school.calendar.v3.UpdateSchoolPeriodRequest) returns (sparx.school.calendar.v3.SchoolPeriod);
   */
  updateSchoolPeriod(
    input: UpdateSchoolPeriodRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateSchoolPeriodRequest, SchoolPeriod>;
  /**
   * @generated from protobuf rpc: DeleteSchoolPeriod(sparx.school.calendar.v3.DeleteSchoolPeriodRequest) returns (google.protobuf.Empty);
   */
  deleteSchoolPeriod(
    input: DeleteSchoolPeriodRequest,
    options?: RpcOptions,
  ): UnaryCall<DeleteSchoolPeriodRequest, Empty>;
  /**
   * @generated from protobuf rpc: GetSchoolYear(sparx.school.calendar.v3.GetSchoolYearRequest) returns (sparx.school.calendar.v3.SchoolYear);
   */
  getSchoolYear(
    input: GetSchoolYearRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSchoolYearRequest, SchoolYear>;
  /**
   * @generated from protobuf rpc: ListSchoolYears(sparx.school.calendar.v3.ListSchoolYearsRequest) returns (sparx.school.calendar.v3.ListSchoolYearsResponse);
   */
  listSchoolYears(
    input: ListSchoolYearsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListSchoolYearsRequest, ListSchoolYearsResponse>;
}
/**
 * The CalendarAPI service implements a set of operations to query and manage school dates
 *
 * @generated from protobuf service sparx.school.calendar.v3.CalendarAPI
 */
export class CalendarAPIClient implements ICalendarAPIClient, ServiceInfo {
  typeName = CalendarAPI.typeName;
  methods = CalendarAPI.methods;
  options = CalendarAPI.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: ListSchoolPeriods(sparx.school.calendar.v3.ListSchoolPeriodsRequest) returns (sparx.school.calendar.v3.ListSchoolPeriodsResponse);
   */
  listSchoolPeriods(
    input: ListSchoolPeriodsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListSchoolPeriodsRequest, ListSchoolPeriodsResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListSchoolPeriodsRequest, ListSchoolPeriodsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetSchoolPeriod(sparx.school.calendar.v3.GetSchoolPeriodRequest) returns (sparx.school.calendar.v3.SchoolPeriod);
   */
  getSchoolPeriod(
    input: GetSchoolPeriodRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSchoolPeriodRequest, SchoolPeriod> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetSchoolPeriodRequest, SchoolPeriod>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: CreateSchoolPeriod(sparx.school.calendar.v3.CreateSchoolPeriodRequest) returns (sparx.school.calendar.v3.SchoolPeriod);
   */
  createSchoolPeriod(
    input: CreateSchoolPeriodRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateSchoolPeriodRequest, SchoolPeriod> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateSchoolPeriodRequest, SchoolPeriod>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: UpdateSchoolPeriod(sparx.school.calendar.v3.UpdateSchoolPeriodRequest) returns (sparx.school.calendar.v3.SchoolPeriod);
   */
  updateSchoolPeriod(
    input: UpdateSchoolPeriodRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateSchoolPeriodRequest, SchoolPeriod> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateSchoolPeriodRequest, SchoolPeriod>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: DeleteSchoolPeriod(sparx.school.calendar.v3.DeleteSchoolPeriodRequest) returns (google.protobuf.Empty);
   */
  deleteSchoolPeriod(
    input: DeleteSchoolPeriodRequest,
    options?: RpcOptions,
  ): UnaryCall<DeleteSchoolPeriodRequest, Empty> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DeleteSchoolPeriodRequest, Empty>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetSchoolYear(sparx.school.calendar.v3.GetSchoolYearRequest) returns (sparx.school.calendar.v3.SchoolYear);
   */
  getSchoolYear(
    input: GetSchoolYearRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSchoolYearRequest, SchoolYear> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetSchoolYearRequest, SchoolYear>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: ListSchoolYears(sparx.school.calendar.v3.ListSchoolYearsRequest) returns (sparx.school.calendar.v3.ListSchoolYearsResponse);
   */
  listSchoolYears(
    input: ListSchoolYearsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListSchoolYearsRequest, ListSchoolYearsResponse> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListSchoolYearsRequest, ListSchoolYearsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}
