import { useQuery } from '@tanstack/react-query';
import { lessonsClient } from 'api';
import { getSchoolID } from 'api/sessions';
import { useBackLink } from 'app/BackLink';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Lesson } from 'views/lessons/LessonView';

export const ArchivedLessonView = () => (
  <SuspenseRoute>
    <SuspenseArchivedLessonView />
  </SuspenseRoute>
);

const SuspenseArchivedLessonView = () => {
  const { lessonID = '' } = useParams();

  const { state } = useLocation();
  const groupQuery = state?.group ? `?group=${state?.group}` : '';
  useBackLink(`/teacher/lessons/archive${groupQuery}`);

  const { data, error } = useQuery({
    queryKey: ['lesson', 'archive', lessonID],
    queryFn: async () =>
      lessonsClient.getArchivedLesson({
        lessonName: `schools/${await getSchoolID()}/lessons/${lessonID}`,
      }).response,
    retry: 0,
    cacheTime: 0,
    staleTime: Infinity,
    suspense: true,
  });

  if (error) {
    return <>Error.</>;
  }
  if (!data?.lessonState) {
    return <>Error: lesson not found</>;
  }

  return (
    <Lesson lessonID={lessonID} lessonState={data.lessonState} studentStates={data.studentStates} />
  );
};
