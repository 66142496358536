import {
  Box,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  QuestionSummary,
  TopicSummary,
} from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';
import { SparxQuestion, useLayoutSteps } from '@sparx/question';
import { TopicWithStrand, useCurriculumName, useTopicSummary } from 'api/content';
import { useClientEvent } from 'components/ClientEventProvider';
import { LargeLoading } from 'components/loading/LargeLoading';
import { HigherOnlySkillTag, SeparateOnlySkillTag } from 'components/Tag';
import { getAssetUrl, uploadedAssetProvider } from 'components/uploadedasset/UploadedAsset';
import React, { useMemo } from 'react';
import {
  isSkillHigherOrSeparateOnly,
  isTopicHigherOnly,
  isTopicSeparateOnly,
  preprocessTopicDescription,
} from 'utils/content';
import { Chevron } from 'views/planner/components/TopicPanel';

export interface QuestionBrowserModalProps {
  topic: TopicWithStrand;
  children: (onOpen: () => void) => React.ReactNode;
}

export const QuestionBrowserModal = ({ topic, children }: QuestionBrowserModalProps) => {
  const curriculum = useCurriculumName(topic.topic.name);

  const { sendEvent } = useClientEvent();
  const eventContext = { topic: topic.topic.name };
  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => sendEvent({ category: 'question_browser', action: 'open' }, eventContext),
    onClose: () => sendEvent({ category: 'question_browser', action: 'close' }, eventContext),
  });

  const { data, isLoading } = useTopicSummary(topic.topic.name, {
    suspense: false,
    enabled: isOpen,
  });

  const topicDescription = preprocessTopicDescription(topic.topic.description);

  return (
    <>
      {children(onOpen)}
      <Modal isOpen={isOpen} onClose={onClose} allowPinchZoom={true}>
        <ModalOverlay />
        <ModalContent
          padding={2}
          maxWidth="1000px"
          maxHeight="90vh"
          marginTop="5vh"
          marginBottom="5vh"
          display="flex"
          overflow="hidden"
        >
          <ModalHeader>
            <Text>
              {topic.topic.displayName}
              <Text as="span" color="gray.400">
                {' '}
                - {topic?.topic?.code}
              </Text>
            </Text>
            <Text fontSize="xs" color="gray.500" fontWeight="normal">
              {curriculum}
              {topic.strand && (
                <>
                  <Chevron />
                  {topic.strand}
                </>
              )}
              {topic.substrand && (
                <>
                  <Chevron />
                  {topic.substrand}
                </>
              )}
            </Text>
          </ModalHeader>
          <Box display="flex" overflowY="auto" flex={1} flexDirection="column">
            {topicDescription && (
              <Text whiteSpace="pre-line" mb={5} px={6}>
                {topicDescription}
              </Text>
            )}
            <ModalCloseButton />
            {isLoading ? (
              <LargeLoading />
            ) : data?.topicSummary ? (
              <QuestionBrowser topic={data.topicSummary} />
            ) : (
              <>No questions found</>
            )}
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

interface QuestionBrowserProps {
  topic: TopicSummary;
}

interface QuestionPreviewSummary extends QuestionSummary {
  isHigherOnly: boolean;
  isSeparateOnly: boolean;
}

export const QuestionBrowser = ({ topic }: QuestionBrowserProps) => {
  const { sendEvent } = useClientEvent();
  const onChangeTab = (level: string) =>
    sendEvent(
      { category: 'question_browser', action: 'select_level' },
      { topic: topic.topic?.name || '', level },
    );

  const topicIshigher = topic.topic && isTopicHigherOnly(topic.topic);
  const topicIsSeparate = topic.topic && isTopicSeparateOnly(topic.topic);

  const questionByPath = useMemo(() => {
    const questions: Dictionary<string, QuestionPreviewSummary[]> = {};
    const curriuclumID = topic.topic?.name.split('/')[1] || '';
    for (const q of topic.keyQuestions) {
      if (!q.questionSummary) continue;
      if (!questions[q.learningPathName]) questions[q.learningPathName] = [];
      const { isHigherOnly, isSeparateOnly } = isSkillHigherOrSeparateOnly(
        curriuclumID,
        q.flags,
        q.tags,
      );
      questions[q.learningPathName]!.push({
        isHigherOnly: topicIshigher || isHigherOnly,
        isSeparateOnly: topicIsSeparate || isSeparateOnly,
        ...q.questionSummary,
      });
    }
    return questions;
  }, [topic]);

  // Hide level 0
  const learningPaths = topic.learningPaths.filter(path => path.level !== '0');

  return (
    <Tabs
      isLazy={true}
      flex={1}
      px={2}
      flexDirection="column"
      onChange={idx => {
        const path = learningPaths[idx];
        onChangeTab(`Level ${path.level}`);
      }}
    >
      <TabList mt={-2} mx={4} position="sticky" top={0} bg="white" zIndex={10}>
        {learningPaths.map(path => (
          <Tab key={path.specName}>Level {path.level}</Tab>
        ))}
      </TabList>
      <TabPanels flex={1} overflowY="auto">
        {learningPaths.map(path => (
          <TabPanel py={0} key={path.name}>
            {questionByPath[path.name]?.map((q, i) => (
              <QuestionPreview question={q} index={i} key={q.questionName} />
            ))}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

interface QuestionPreviewProps {
  question: QuestionPreviewSummary;
  index: number;
}

const QuestionPreview = ({ question, index }: QuestionPreviewProps) => {
  const steps = useLayoutSteps(question.layoutJson);
  const { sendEvent } = useClientEvent();

  return (
    <Box pt={4} pb={6}>
      <Flex bg="gray.100" px={3} py={2} mb={4} borderRadius="md" alignItems="center">
        <Text fontWeight="bold" color="blue.900" fontSize="sm">
          Question {index + 1}
        </Text>
        {question.isHigherOnly && <HigherOnlySkillTag ml={2} />}
        {question.isSeparateOnly && <SeparateOnlySkillTag ml={2} />}
      </Flex>
      <Box px={3}>
        {steps.length > 0 && steps[0] ? (
          <SparxQuestion
            layout={steps[0].layout}
            input={steps[0].input}
            setInput={() => {
              /* do nothing */
            }}
            shuffleSeed={question.questionName}
            readOnly={true}
            getUploadedAsset={uploadedAssetProvider}
            getAssetUrl={getAssetUrl}
            sendAnalyticEvent={(action, labels) =>
              sendEvent({ category: 'question', action }, labels)
            }
          />
        ) : (
          <>Error loading question</>
        )}
      </Box>
    </Box>
  );
};
