import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Portal,
  Text,
} from '@chakra-ui/react';
import {
  faArrowTurnUp,
  faCaretDown,
  faCopy,
  faEye,
  faICursor,
  faPencil,
  faRocket,
  faShareNodes,
  faTrash,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ResourceStatus,
  SchemeOfLearning,
  SchemeOfLearningTemplate,
} from '@sparx/api/apis/sparx/planning/v1/sol';
import { readableStaffNames } from '@sparx/staff-manager';
import { createColumnHelper } from '@tanstack/react-table';
import { SchoolYearWithDates, useSchoolGroups, useSchoolYears } from 'api/school';
import { GroupWithSettings } from 'api/scienceschool';
import { useSession, useUserType } from 'api/sessions';
import { SCHOOL_GROUPS_ALL, usePublishedSolsWithSchedules } from 'api/sol';
import { useStaffLookup } from 'api/staff';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import { DataTable } from 'components/table/DataTable';
import { RowEditButton } from 'components/table/RowEditButton';
import { PrettyTimestamp } from 'components/timestamp/PrettyTimestamp';
import React, { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DeleteSolModal } from 'views/sol/components/DeleteSolModal';
import { EditSolModal, SolModalAction } from 'views/sol/components/EditSolModal';
import { PublishSolModal } from 'views/sol/components/PublishSolModal';
import { ShareSolModal } from 'views/sol/components/ShareSolModal';
import { LargeMenuItem } from 'views/sol/components/WeekContent';

import { BulkCopySolModal } from './BulkCopySolModal';

export interface AnnotatedSolFields {
  status: ResourceStatus;
  hasDraft?: boolean;
  hasPublished?: boolean;
}

export interface SOL extends SchemeOfLearning {
  isTemplate: false;
}

export interface TemplateSOL extends SchemeOfLearningTemplate {
  isTemplate: true;
}

export interface AnnotatedSchemeOfLearning extends SOL, AnnotatedSolFields {
  assignedGroups: GroupWithSettings[];
}

export interface AnnotatedSchemeOfLearningTemplate extends TemplateSOL, AnnotatedSolFields {
  schoolGroup: string;
  allowEdit: boolean;
}

export type SolOrTemplate = SOL | TemplateSOL;

export type AnnotatedSol = AnnotatedSchemeOfLearning | AnnotatedSchemeOfLearningTemplate;

type SolListTableProps = { defaultYear?: string } & (
  | {
      templateSols: false;
      filteredSols: AnnotatedSchemeOfLearning[];
      archive?: boolean;
    }
  | {
      templateSols: true;
      filteredSols: AnnotatedSchemeOfLearningTemplate[];
      archive?: undefined;
    }
);

export const SolTable = ({
  filteredSols,
  templateSols,
  defaultYear,
  archive,
}: SolListTableProps) => {
  const navigate = useNavigate();
  const { isSparxStaff } = useUserType();

  const { data: schoolGroups } = useSchoolGroups({ suspense: true });

  const [publishingScheme, setPublishScheme] = useState<AnnotatedSol | undefined>();
  const [deleteScheme, setDeleteScheme] = useState<AnnotatedSol | undefined>();
  const [editingScheme, setEditingScheme] = useState<SolModalAction | undefined>();
  const [shareScheme, setShareScheme] = useState<AnnotatedSchemeOfLearning | undefined>();

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<AnnotatedSol>();
    return [
      !templateSols
        ? columnHelper.display({
            id: 'edit',
            cell: row => (
              <RowEditButton
                icon={
                  row.row.original.metadata?.status === ResourceStatus.PUBLISHED || archive
                    ? faEye
                    : faPencil
                }
              />
            ),
            enableSorting: false,
            meta: {
              width: '50px',
              align: 'center',
            },
          })
        : columnHelper.accessor('schoolGroup', {
            header: 'Scheme',
            cell: ({ row }) => (
              <>
                {!row.original.name.startsWith(SCHOOL_GROUPS_ALL) && (
                  <Text fontSize="lg" display="inline-block" mr={2} color="gray.500">
                    <FontAwesomeIcon icon={faShareNodes} fixedWidth={true} />
                  </Text>
                )}
                <Text display="inline-block">
                  {row.original.isTemplate && row.original.schoolGroup}
                </Text>
              </>
            ),
            enableSorting: false,
          }),
      columnHelper.accessor(`displayName`, {
        header: 'Name',
        enableSorting: false,
        cell: ({ row }) =>
          row.original.hasPublished ? (
            <>
              <Text fontSize="lg" display="inline-block" mr={2} color="gray.500">
                <FontAwesomeIcon icon={faArrowTurnUp} rotation={90} fixedWidth={true} />
              </Text>
              <Text display="inline-block">{row.original.displayName}</Text>
            </>
          ) : (
            <Text color="gray.600">{row.original.displayName}</Text>
          ),
      }),
      columnHelper.display({
        header: 'Status',
        cell: ({ row }) => <SolStatus sol={row.original} />,
      }),
      columnHelper.display({
        header: 'Last Edited By',
        cell: ({ row }) => <SolEditStatus sol={row.original} />,
        enableSorting: true,
        sortingFn: (a, b) =>
          (a.original.metadata?.updateTime?.seconds || 0) -
          (b.original.metadata?.updateTime?.seconds || 0),
      }),
      ...(archive
        ? [
            columnHelper.display({
              header: 'Archive Reason',
              cell: ({ row }) => <SolArchivedReason sol={row.original} />,
            }),
          ]
        : []),
      ...(!templateSols && !archive
        ? [
            columnHelper.display({
              header: 'Used by',
              cell: ({ row }) => (
                <>
                  {(!row.original.isTemplate &&
                    row.original.assignedGroups.map(s => s.displayName).join(', ')) ||
                    '-'}
                </>
              ),
            }),
          ]
        : []),
      columnHelper.display({
        id: 'actions',
        meta: {
          width: 150,
          blockClick: true,
        },
        cell: ({ row }) => {
          const allowEdit = !row.original.isTemplate || row.original.allowEdit;
          return (
            <Menu key={row.id}>
              <MenuButton
                as={Button}
                width="120px"
                onClick={e => e.stopPropagation()}
                variant="outline"
                colorScheme="buttonTeal"
                rightIcon={<FontAwesomeIcon icon={faCaretDown} />}
              >
                Options
              </MenuButton>
              <Portal>
                <MenuList>
                  {!archive && row.original.status !== ResourceStatus.PUBLISHED && allowEdit && (
                    <>
                      <LargeMenuItem
                        onClick={() => setPublishScheme(row.original)}
                        icon={faRocket}
                        title="Publish"
                      >
                        {row.original.hasPublished
                          ? 'Publish this draft.'
                          : row.original.isTemplate
                            ? 'Publish this Template for use in other schools.'
                            : 'Publish this Scheme of Learning for use in the Planner.'}
                      </LargeMenuItem>
                      <MenuDivider />
                    </>
                  )}
                  {row.original.isTemplate && (
                    <LargeMenuItem
                      onClick={() =>
                        row.original.isTemplate &&
                        setEditingScheme({ kind: 'useTemplate', sol: row.original })
                      }
                      icon={faUpload}
                      title="Use in my school"
                    >
                      Use this template.
                    </LargeMenuItem>
                  )}
                  {!archive && allowEdit && (
                    <>
                      {row.original.isTemplate && <MenuDivider />}
                      <LargeMenuItem
                        onClick={() => setEditingScheme({ kind: 'rename', sol: row.original })}
                        icon={faICursor}
                        title="Rename"
                        isDisabled={row.original.hasDraft}
                      >
                        {row.original.hasDraft
                          ? 'You must delete the draft first.'
                          : `Rename this ${row.original.isTemplate ? 'template' : 'Scheme of Learning'}.`}
                      </LargeMenuItem>
                    </>
                  )}
                  {!templateSols && (
                    <LargeMenuItem
                      onClick={() => setEditingScheme({ kind: 'copy', sol: row.original })}
                      icon={faCopy}
                      title="Copy"
                    >
                      Copy this Scheme of Learning.
                    </LargeMenuItem>
                  )}
                  {!archive && allowEdit && (
                    <LargeMenuItem
                      onClick={() => setDeleteScheme(row.original)}
                      icon={faTrash}
                      title="Delete"
                      isDisabled={
                        !row.original.isTemplate && row.original.assignedGroups.length > 0
                      }
                    >
                      {!row.original.isTemplate && row.original.assignedGroups.length > 0
                        ? 'You cannot delete a Scheme of Learning that is in use.'
                        : row.original.hasPublished
                          ? 'Delete this draft.'
                          : `Delete this ${row.original.isTemplate ? 'template' : 'Scheme of Learning'}.`}
                    </LargeMenuItem>
                  )}
                  {!archive &&
                    !row.original.hasPublished &&
                    !row.original.isTemplate &&
                    (isSparxStaff || (schoolGroups && schoolGroups.length > 0)) && (
                      <>
                        <MenuDivider />
                        <LargeMenuItem
                          onClick={() => !row.original.isTemplate && setShareScheme(row.original)}
                          icon={faShareNodes}
                          title="Share as template"
                        >
                          Share this Scheme of Learning.
                        </LargeMenuItem>
                      </>
                    )}
                </MenuList>
              </Portal>
            </Menu>
          );
        },
      }),
    ];
  }, [
    setPublishScheme,
    setDeleteScheme,
    setEditingScheme,
    setShareScheme,
    templateSols,
    schoolGroups,
    archive,
    isSparxStaff,
  ]);

  return (
    <>
      <EditSolModal
        action={editingScheme}
        onSave={(name, isNew) => {
          if (isNew) {
            navigate(`/teacher/sol/edit/${name.split('/')[3]}`);
          } else {
            setEditingScheme(undefined);
          }
        }}
        onClose={() => setEditingScheme(undefined)}
        defaultYear={defaultYear}
      />
      <PublishSolModal
        scheme={publishingScheme}
        onPublish={() => setPublishScheme(undefined)}
        onClose={() => setPublishScheme(undefined)}
      />
      <DeleteSolModal
        scheme={deleteScheme}
        sols={filteredSols}
        onDelete={() => setDeleteScheme(undefined)}
        onClose={() => setDeleteScheme(undefined)}
      />
      {!templateSols && (
        <ShareSolModal
          scheme={shareScheme}
          onClose={() => setShareScheme(undefined)}
          onShare={() => {
            navigate(`/teacher/sol/templates`);
            setShareScheme(undefined);
          }}
        />
      )}
      {!templateSols && !archive && (
        <Box pt={4}>
          <Button onClick={() => setEditingScheme({ kind: 'new' })} colorScheme="buttonTeal">
            New blank scheme
          </Button>
        </Box>
      )}
      <Box pt={4}>
        <DataTable
          data={filteredSols}
          columns={columns}
          getRowId={row => `${row.name}/${row.metadata?.status}`}
          rowIsHighlighted={row => (row.hasDraft ? 'gray.100' : 'white')}
          onRowClick={data =>
            navigate(
              `/teacher/sol/${data.status === ResourceStatus.PUBLISHED || archive ? 'view' : 'edit'}/${
                data.name.split('/')[3]
              }${data.isTemplate ? `?sg=${data.name.split('/')[1]}` : ''}${
                archive ? `?archive${data.status === ResourceStatus.DRAFT ? '&draft' : ''}` : ''
              }`,
            )
          }
          noDataRow={
            (filteredSols.length == 0 && !archive && !templateSols && (
              <NoSolsMessage
                defaultYearName={defaultYear}
                onNewScheme={() => setEditingScheme({ kind: 'new' })}
              />
            )) || <DefaultNoSolsMessage templateSols={templateSols} />
          }
        />
      </Box>
    </>
  );
};

export const SolStatus = ({ sol }: { sol: SchemeOfLearning | SchemeOfLearningTemplate }) => {
  const status = sol.metadata?.status;
  if (status === ResourceStatus.DRAFT) {
    return (
      <Text fontWeight="bold" color="gray.600">
        Draft
      </Text>
    );
  } else if (status === ResourceStatus.PUBLISHED) {
    return (
      <Text fontWeight="bold" color="green.600">
        Published
      </Text>
    );
  } else {
    return <Text>Unknown</Text>;
  }
};

export const SolEditStatus = ({ sol }: { sol: AnnotatedSol }) => {
  const { data: staffLookup } = useStaffLookup({ suspense: true });
  const updatedBy = sol.metadata?.updatedBy;
  const authorName = useMemo(() => {
    if (updatedBy === '') return 'Sparx';
    const staffMember = staffLookup?.[updatedBy || ''];
    const { realName } = readableStaffNames(staffMember);
    if (staffMember && realName) {
      return (
        <Box as="span" data-sentry-mask>
          {realName}
        </Box>
      );
    }
    return 'another school';
  }, [staffLookup, updatedBy]);

  return (
    <>
      <PrettyTimestamp fmt="P">{sol.metadata?.updateTime}</PrettyTimestamp>
      <br />
      by {authorName}
    </>
  );
};

const SolArchivedReason = ({ sol }: { sol: AnnotatedSol }) => {
  if (sol.metadata?.deleteTime) {
    return (
      <>
        Deleted on
        <br />
        <PrettyTimestamp fmt="P p">{sol.metadata.deleteTime}</PrettyTimestamp>
      </>
    );
  }

  return <>Last academic year</>;
};

const DefaultNoSolsMessage = ({ templateSols }: { templateSols?: boolean }) => (
  <>No {templateSols ? 'templates' : 'schemes'} to display</>
);

const NoSolsMessage = ({
  defaultYearName,
  onNewScheme,
}: {
  defaultYearName?: string;
  onNewScheme: () => void;
}) => {
  const { data: schoolYears } = useSchoolYears({ suspense: true }, false);

  const defaultYear = schoolYears?.find(sy => sy.name === defaultYearName);
  const otherYear = schoolYears?.find(sy => (defaultYear?.current ? sy.previous : sy.current));

  if (!defaultYear) {
    return <DefaultNoSolsMessage />;
  }

  return (
    <SuspenseRoute>
      <NoSolsMessageInner
        onNewScheme={onNewScheme}
        selectedYear={defaultYear}
        otherYear={otherYear}
      />
    </SuspenseRoute>
  );
};

const NoSolsMessageInner = ({
  selectedYear,
  otherYear,
  onNewScheme,
}: {
  selectedYear: SchoolYearWithDates;
  otherYear?: SchoolYearWithDates;
  onNewScheme: () => void;
}) => {
  // Check For sols in other year
  const { data: allSols = [] } = usePublishedSolsWithSchedules(
    otherYear?.name.split('/')[3] || '',
    {
      suspense: true,
      enabled: !!otherYear,
    },
  );
  const { data: { schoolId } = { schoolId: '' } } = useSession();

  const filteredSols = allSols.filter(
    s =>
      `schools/${schoolId}/years/${s.schoolYear}` === otherYear?.name &&
      s.metadata?.status === ResourceStatus.PUBLISHED &&
      !!s.schedule,
  );

  return (
    <>
      <Text>
        There are no schemes for {selectedYear.current ? 'this' : 'next'} academic year yet.
      </Text>
      <Text maxWidth={600} marginX="auto">
        {filteredSols.length == 0 ? (
          <>
            You can either create a new scheme from scratch or start from one of our template
            schemes.
          </>
        ) : (
          <>
            You can copy your published schemes from {selectedYear.current ? 'last' : 'this'}{' '}
            academic year, start from one of our templates, or create a new scheme from scratch.
          </>
        )}
      </Text>
      <HStack justifyContent="center" mt={4}>
        {filteredSols.length == 0 ? (
          <Button colorScheme="buttonTeal" onClick={onNewScheme}>
            Create new scheme
          </Button>
        ) : (
          <BulkCopySolModal sols={filteredSols} fromYear={otherYear} toYear={selectedYear} />
        )}
        <Button as={Link} to="/teacher/sol/templates" colorScheme="buttonTeal">
          Start from template
        </Button>
      </HStack>
    </>
  );
};
