import { Box, Text } from '@chakra-ui/react';
import { Completion } from '@sparx/api/apis/sparx/progress/v1/completion';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { HandinStatus, useHandInStatus } from '@sparx/packageactivity';
import React from 'react';

interface HandinStatusStyling {
  color: string;
  background: string;
  text?: string;
  statusText: string;
}

const handinStatusStyling: Record<HandinStatus, HandinStatusStyling> = {
  [HandinStatus.NotStarted]: {
    color: 'orange.500',
    background: 'orange.100',
    text: 'New',
    statusText: 'New',
  },
  [HandinStatus.NotStartedLate]: {
    color: 'red.500',
    background: 'red.100',
    statusText: 'Late',
  },
  [HandinStatus.InProgress]: {
    color: 'orange.500',
    background: 'orange.100',
    statusText: 'Started',
  },
  [HandinStatus.InProgressLate]: {
    color: 'red.500',
    background: 'red.100',
    statusText: 'Late',
  },
  [HandinStatus.Complete]: {
    color: 'green.500',
    background: 'green.100',
    statusText: 'Completed',
  },
  [HandinStatus.Cancelled]: {
    color: 'gray.300',
    background: 'gray.100',
    statusText: 'Cancelled',
  },
};

export const getHandinStatusStyling = (value: HandinStatus) => handinStatusStyling[value];

export const getHandinStatusName = (value: HandinStatus) =>
  handinStatusStyling[value].statusText || 'Unknown';

interface CompletionBadgeProps {
  completion: Completion | undefined;
  due?: Timestamp;
  hideIf?: HandinStatus[];
}

export const CompletionBadge = ({ completion, due, hideIf }: CompletionBadgeProps) => {
  const { percentages, status } = useHandInStatus(completion, due);
  const percentagePretty = percentages['C'].roundedPercentage;
  const styles = handinStatusStyling[status];

  if (hideIf?.includes(status)) return null;

  return (
    <Box
      background={styles.background}
      borderColor={styles.background}
      width={16}
      borderWidth={2}
      textAlign="center"
      py={1}
      borderRadius="md"
    >
      <Text fontSize={['sm', 'md']} fontWeight="bold" color={styles.color}>
        {styles.text ? styles.text : <>{percentagePretty}%</>}
      </Text>
    </Box>
  );
};

export const StatusBadge = ({ completion, due }: CompletionBadgeProps) => {
  const { status } = useHandInStatus(completion, due);
  const styles = handinStatusStyling[status];

  return (
    <Box
      py={1}
      borderRadius="md"
      borderWidth={2}
      borderColor={styles.color}
      w={[24, 28]}
      textAlign="center"
    >
      <Text fontSize={['sm', 'md']} color={styles.color} fontWeight="bold">
        {styles.statusText}
      </Text>
    </Box>
  );
};
