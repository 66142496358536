import { Class } from '@sparx/api/apis/sparx/misintegration/wondewitch/v1/wondewitch';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { UserInteractionEventFields, WondeClass } from '@sparx/mis-sync-import/src/types';

const category = 'MIS Sync';

// Config page

export const addedClasses = (newClasses: WondeClass[]): UserInteractionEventFields => ({
  category,
  action: 'Add classes',
  labels: {
    classIDs: newClasses.map(c => c.id).join(','),
    classDisplayNames: newClasses.map(c => c.name).join(','),
  },
});

export const removeNewlyAddedClass = (removedClass: WondeClass): UserInteractionEventFields => ({
  category,
  action: 'Remove newly added class',
  labels: {
    classID: removedClass.id,
    classDisplayName: removedClass.name,
  },
});

export const cancelRemovedCurrentClass = (group: Group): UserInteractionEventFields => ({
  category,
  action: 'Cancel remove current class',
  labels: {
    className: group.name,
    classDisplayName: group.displayName,
  },
});

export const removeCurrentClass = (group: Group): UserInteractionEventFields => ({
  category,
  action: 'Remove current class',
  labels: {
    className: group.name,
    classDisplayName: group.displayName,
  },
});

export const removedAllCurrentClasses = (): UserInteractionEventFields => ({
  category,
  action: 'Removed all current classes',
});

export const viewedPreview = (timeVisible: number): UserInteractionEventFields => ({
  category,
  action: 'Clicked Preview & Sync changes',
  labels: {
    timeVisible,
  },
});

export const clickedPreviewWithMissingClassChanges = (
  timeVisible: number,
): UserInteractionEventFields => ({
  category,
  action: 'Clicked view preview with missing class changes',
  labels: {
    timeVisible,
  },
});

export const updatedYearGroup = (
  classIdentifier: string,
  newYearGroupID: string,
): UserInteractionEventFields => ({
  category,
  action: 'Updated year group',
  labels: {
    groupName: classIdentifier,
    newYearGroupID,
  },
});

export const resolveStudentConflict = (
  studentID: string,
  classes: Class[],
  resolution: string,
  timeVisible: number,
): UserInteractionEventFields => ({
  category,
  action: 'Resolved student conflict',
  labels: {
    studentID,
    groupIDs: classes.map(c => c.id).join(','),
    resolution,
    timeVisible,
  },
});

export const bulkResolveStudentConflicts = (
  studentIDs: string[],
  classes: Class[],
  resolution: string,
  timeVisible: number,
): UserInteractionEventFields => ({
  category,
  action: 'Bulk resolved student conflicts',
  labels: {
    studentIDs: studentIDs.join(','),
    groupIDs: classes.map(c => c.id).join(','),
    resolution,
    timeVisible,
  },
});

// Preview page

export const previewSyncError = (error: Error, duration: number): UserInteractionEventFields => ({
  category,
  action: 'Preview sync error',
  labels: {
    error: error.message,
    duration,
  },
});

export const previewLoadedWithNoChanges = (): UserInteractionEventFields => ({
  category,
  action: 'Viewed preview with no changes',
});

// Sync Plans
export const syncPlanCreated = (duration: number): UserInteractionEventFields => ({
  category,
  action: 'Sync plan created',
  labels: {
    duration,
  },
});

export const syncStartedWithNoChanges = (timeVisible: number): UserInteractionEventFields => ({
  category,
  action: 'Clicked Preview and Sync with no class changes',
  labels: {
    timeVisible,
  },
});

export type ChangeType = 'new' | 'removed' | 'updated' | 'invalid' | 'reinstated';
export const toggleViewStudentChanges = (
  open: boolean,
  type: ChangeType,
): UserInteractionEventFields => ({
  category,
  action: `${open ? 'Open' : 'Close'} ${type} student panel`,
});

export const cancelledPreview = (
  buttonLocation: 'top' | 'bottom',
  timeVisible: number,
): UserInteractionEventFields => ({
  category,
  action: 'Cancelled preview',
  labels: {
    buttonLocation,
    timeVisible,
  },
});

// Sync page

export const syncStarted = (timeVisible: number): UserInteractionEventFields => ({
  category,
  action: 'Clicked sync changes',
  labels: {
    timeVisible,
  },
});

export const syncComplete = (duration: number): UserInteractionEventFields => ({
  category,
  action: 'Sync complete',
  labels: {
    duration,
  },
});

export const syncError = (error: Error, duration: number): UserInteractionEventFields => ({
  category,
  action: 'Sync error',
  labels: {
    error: error.message,
    duration,
  },
});

export const backToConfigFromSync = (timeVisible: number): UserInteractionEventFields => ({
  category,
  action: 'Clicked back to Import & Manage from sync',
  labels: {
    timeVisible,
  },
});
