import { useMisSyncContext } from '@sparx/mis-sync-import/src/Context';
import {
  Action,
  makeSyncConfigReducer,
} from '@sparx/mis-sync-import/src/MisSyncImport/context/config/reducer';
import { createContext, PropsWithChildren, useContext } from 'react';
import { useImmerReducer } from 'use-immer';

import { emptySyncConfig, SyncConfig } from './types';

type ContextData = {
  syncConfig: SyncConfig;
  dispatch: (action: Action) => void;
};

const context = createContext<ContextData | null>(null);

/**
 * Provides access to the configuration for a sync and a dispatch method for making modifications to
 * the configuration.
 *
 * Initial existing classes are derived from the passed in Groups.
 */
export const SyncConfigProvider = ({ children }: PropsWithChildren) => {
  const { debug } = useMisSyncContext();

  const [syncConfig, dispatch] = useImmerReducer<SyncConfig, Action>(
    makeSyncConfigReducer(!!debug),
    emptySyncConfig,
  );

  return <context.Provider value={{ syncConfig, dispatch }}>{children}</context.Provider>;
};

/**
 * Returns the syncConfig and a dispatch method for updating it.
 */
export const useSyncConfig = () => {
  const ctx = useContext(context);
  if (!ctx) {
    throw new Error('useSyncConfig must be used inside of a SyncConfigProvider');
  }
  return ctx;
};
