import { Accordion, Box, Button, Text, VStack } from '@chakra-ui/react';
import { faChevronLeft, faChevronRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PartialMessage } from '@protobuf-ts/runtime';
import {
  ContentContext,
  ScienceAssignmentContext,
} from '@sparx/api/apis/sparx/interaction/feedback/v1/feedback';
import { GetPackageAnswerHistoryResponse_ActivityWithAnswer } from '@sparx/api/apis/sparx/science/packages/v1/activity';
import { Task, TaskItem } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { useTopicLookup } from 'api/content';

import { ActivityResult, Unattempted } from './ActivityResult';
import { ReportProblemHeader } from './ReportIssueHeader';
import { TaskItemPanelContext } from './TaskItemPanelContext';

interface TaskItemPanelProps {
  activities: GetPackageAnswerHistoryResponse_ActivityWithAnswer[];
  feedbackContext: {
    contentContext: PartialMessage<ContentContext>;
    sciContext: PartialMessage<ScienceAssignmentContext>;
  };
}

export const TaskItemPanel = ({ activities, feedbackContext }: TaskItemPanelProps) => (
  <TaskItemPanelContext>
    {!!activities.length && (
      <ReportProblemHeader
        showAnswerToggle={true}
        feedbackTriggerName="answer-history-submit"
        contentContext={feedbackContext.contentContext}
        sciContext={feedbackContext.sciContext}
      />
    )}
    <Box flex={1} mx="3px" mb="3px" borderRadius="4px" overflowY="auto">
      <Accordion allowToggle={true} display="flex" flexDirection="column" minHeight="100%">
        {activities.length === 0 && <Unattempted />}
        {activities.map((activity, index) => (
          <ActivityResult
            activityWithAnswer={activity}
            key={activity.activity?.name || index}
            index={activities.length - index}
            first={index === 0}
          />
        ))}
      </Accordion>
    </Box>
  </TaskItemPanelContext>
);

interface TaskItemPanelHeaderProps {
  previousItem?: string;
  nextItem?: string;
  setSelectedItem: (name?: string) => void;
  selectedTask?: Task;
  selectedTaskItem?: TaskItem;
  noSelectedItemTitle?: string;
}

export const TaskItemPanelHeader = ({
  previousItem,
  nextItem,
  setSelectedItem,
  selectedTask,
  selectedTaskItem,
  noSelectedItemTitle,
}: TaskItemPanelHeaderProps) => {
  const { data: topicLookup, isLoading: loadingTopicLookup } = useTopicLookup({ suspense: false });

  let topicName: string | undefined;
  let topicDisplayName: string | undefined;
  if (selectedTaskItem?.contents?.contents.oneofKind === 'skill') {
    topicName = selectedTaskItem.contents.contents.skill.topicName;
    topicDisplayName = topicLookup?.[topicName]?.topic?.displayName;
  }

  // If there is an option of noSelectedItem, then allow us to go from the first item to the no item option.
  const hasPrevious =
    !!previousItem || (!!noSelectedItemTitle && !previousItem && !!selectedTaskItem);

  return (
    <Box color="white" p={2} alignItems="center" display="flex">
      <Box flex={1} display="flex" justifyContent="flex-start">
        <Button
          colorScheme="blackAlpha"
          leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
          isDisabled={!hasPrevious}
          onClick={() => hasPrevious && setSelectedItem(previousItem)}
        >
          Previous
        </Button>
      </Box>
      <VStack spacing="3px" lineHeight="1em" fontSize={topicName ? 'sm' : 'md'} textAlign="center">
        <Text flex={2} display="flex" justifyContent="center" fontWeight="bold">
          {noSelectedItemTitle && selectedTaskItem === undefined ? (
            noSelectedItemTitle
          ) : (
            <>
              {selectedTask?.title} - {selectedTaskItem?.title}
            </>
          )}
        </Text>
        {topicName && (
          <>
            {loadingTopicLookup ? (
              <Text color="gray.500">
                <FontAwesomeIcon icon={faSpinner} spin={true} />
              </Text>
            ) : (
              <Text flex={1} display="flex" justifyContent="center" color="gray.200">
                {topicDisplayName}
              </Text>
            )}
          </>
        )}
      </VStack>
      <Box flex={1} display="flex" justifyContent="flex-end">
        <Button
          colorScheme="blackAlpha"
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          isDisabled={!nextItem}
          onClick={() => nextItem && setSelectedItem(nextItem)}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};
