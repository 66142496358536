import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useSession } from 'api/sessions';
import { UserSettings, useUpdateUserSetting, useUserSetting } from 'api/usersettings';
import { useWhatsNewPromotedPost } from 'api/whatsnew';
import { useClientEvent } from 'components/ClientEventProvider';
import { BlocksRenderer } from 'components/StrapiBlocksRenderer';
import { isAfter, parseJSON } from 'date-fns';
import { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getMediaURL } from 'utils/strapi-types/media';

import { WhatsNewChip, WhatsNewVideoPlayer } from './WhatsNewView';

export const WhatsNewPromotedDialog = () => {
  const navigate = useNavigate();
  const { data: lastViewed, isSuccess: settingLoaded } = useUserSetting(
    UserSettings.whatsNewPromotedLastViewed,
    {
      suspense: false,
    },
  );
  const { mutate: updateSetting } = useUpdateUserSetting();
  const { data: user, isSuccess: userLoaded } = useSession();
  const { data: post, isSuccess: postLoaded } = useWhatsNewPromotedPost();
  const { sendEvent } = useClientEvent();

  const lastViewedDate = parseJSON(
    lastViewed || (user?.firstLogin && Timestamp.toDate(user.firstLogin)) || new Date(),
  );
  const hasNew =
    settingLoaded &&
    userLoaded &&
    postLoaded &&
    !!post &&
    !!post.attributes.publishedAt &&
    isAfter(parseJSON(post.attributes.publishedAt), lastViewedDate);

  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
    onClose: () => {
      if (post && !post.attributes.SparxStaffOnly) {
        updateSetting({
          key: UserSettings.whatsNewPromotedLastViewed,
          value: post.attributes.publishedAt,
        });
      }
    },
  });

  const doClose = () => {
    onClose();
    if (post) {
      const secondsOpen = (new Date().getTime() - openTime.current.getTime()) / 1000;
      sendEvent(
        { category: 'whats-new-promoted', action: 'close' },
        {
          postID: post.id.toString(),
          postTitle: post.attributes.Title,
          secondsOpen: secondsOpen.toString(),
        },
      );
    }
  };

  const showModal = isOpen && hasNew;

  const openTime = useRef(new Date());
  useEffect(() => {
    if (showModal) {
      // when the modal is opened set the open time
      openTime.current = new Date();
    }
  }, [showModal]);

  if (!showModal) {
    return null;
  }

  const callToActionEvent = (text: string, url: string) => {
    const secondsOpen = (new Date().getTime() - openTime.current.getTime()) / 1000;
    sendEvent(
      { category: 'whats_new_promoted', action: 'call_to_action' },
      {
        postID: post.id.toString(),
        postTitle: post.attributes.Title,
        buttonText: text,
        buttonURL: url,
        secondsOpen: secondsOpen.toString(),
      },
    );
  };

  const attributes = post.attributes;

  const hasVideo = !!attributes.Video?.Video.data.attributes.url;

  return (
    <Modal
      isOpen={isOpen}
      onClose={doClose}
      isCentered={true}
      size={hasVideo ? { base: '2xl', lg: '3xl', xl: '4xl' } : '3xl'}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent p={4} pb={0}>
        <ModalHeader mb={4}>
          <HStack alignItems="center">
            <WhatsNewChip chip={attributes.Chip} />
            <Heading size="md" position="relative">
              {attributes.Title}
            </Heading>
          </HStack>
        </ModalHeader>
        <ModalBody
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mb={4}
        >
          {!hasVideo && (
            <Box height={{ base: 52, lg: 52, xl: 52 }} mb={8}>
              <Image
                src={getMediaURL(attributes.Image.data, 'small')}
                transform="rotateZ(-1.5deg)"
                alt={attributes.Image.data.attributes.alternativeText || undefined}
                maxW="100%"
                maxH="100%"
                boxShadow="elevationLow"
              />
            </Box>
          )}
          <Box width="100%">
            <BlocksRenderer content={attributes.PromotedContent?.Body || []} />
            {hasVideo && <WhatsNewVideoPlayer post={post} analyticsCategory="whats_new_promoted" />}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button mr={2} onClick={doClose} colorScheme="buttonTeal" variant="outline">
            Close
          </Button>
          {attributes.PromotedContent?.Button ? (
            <Button
              as={Link}
              to={attributes.PromotedContent.Button.URL}
              onClick={e => {
                e.preventDefault();
                onClose();
                if (!attributes.PromotedContent?.Button) return; // Make TS happy
                if (attributes.PromotedContent?.Button?.External) {
                  window.open(attributes.PromotedContent.Button.URL, '_blank');
                } else {
                  navigate(attributes.PromotedContent.Button.URL);
                }
                callToActionEvent(
                  attributes.PromotedContent.Button.Text,
                  attributes.PromotedContent.Button.URL,
                );
              }}
              rightIcon={
                attributes.PromotedContent.Button.External ? (
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                ) : undefined
              }
              colorScheme="buttonTeal"
            >
              {attributes.PromotedContent.Button.Text}
            </Button>
          ) : (
            <Button
              onClick={() => {
                onClose();
                const url = `/teacher/whats-new#${post.spxID}`;
                navigate(url);
                callToActionEvent('Find out more', url);
              }}
              colorScheme="buttonTeal"
            >
              Find out more
            </Button>
          )}
          {post.attributes.SparxStaffOnly && (
            <Text
              position="absolute"
              bottom={0}
              left={0}
              color="white"
              fontWeight="bold"
              p={2}
              textTransform="uppercase"
              bg="red.500"
            >
              Sparx preview post
            </Text>
          )}
        </ModalFooter>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};
