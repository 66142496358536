// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/lessons/v1/lessons.proto" (package "sparx.science.lessons.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Lessons } from './lessons';
import type { AcknowledgeLessonNotificationResponse } from './lessons';
import type { AcknowledgeLessonNotificationRequest } from './lessons';
import type { UpdateStudentLessonStateResponse } from './lessons';
import type { UpdateStudentLessonStateRequest } from './lessons';
import type { CreateLessonActivityResponse } from './lessons';
import type { CreateLessonActivityRequest } from './lessons';
import type { UpdateLessonStateResponse } from './lessons';
import type { UpdateLessonStateRequest } from './lessons';
import type { GenerateLessonActivityPackageResponse } from './lessons';
import type { GenerateLessonActivityPackageRequest } from './lessons';
import type { GetArchivedLessonResponse } from './lessons';
import type { GetArchivedLessonRequest } from './lessons';
import type { WatchLessonStateResponse } from './lessons';
import type { WatchLessonStateRequest } from './lessons';
import type { ServerStreamingCall } from '@protobuf-ts/runtime-rpc';
import type { LeaveLessonResponse } from './lessons';
import type { LeaveLessonRequest } from './lessons';
import type { JoinLessonResponse } from './lessons';
import type { JoinLessonRequest } from './lessons';
import type { EndLessonResponse } from './lessons';
import type { EndLessonRequest } from './lessons';
import type { CreateLessonResponse } from './lessons';
import type { CreateLessonRequest } from './lessons';
import type { ListArchivedLessonsResponse } from './lessons';
import type { ListArchivedLessonsRequest } from './lessons';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { ListLessonsResponse } from './lessons';
import type { ListLessonsRequest } from './lessons';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.science.lessons.v1.Lessons
 */
export interface ILessonsClient {
  /**
   * @generated from protobuf rpc: ListLessons(sparx.science.lessons.v1.ListLessonsRequest) returns (sparx.science.lessons.v1.ListLessonsResponse);
   */
  listLessons(
    input: ListLessonsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListLessonsRequest, ListLessonsResponse>;
  /**
   * @generated from protobuf rpc: ListArchivedLessons(sparx.science.lessons.v1.ListArchivedLessonsRequest) returns (sparx.science.lessons.v1.ListArchivedLessonsResponse);
   */
  listArchivedLessons(
    input: ListArchivedLessonsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListArchivedLessonsRequest, ListArchivedLessonsResponse>;
  /**
   * @generated from protobuf rpc: CreateLesson(sparx.science.lessons.v1.CreateLessonRequest) returns (sparx.science.lessons.v1.CreateLessonResponse);
   */
  createLesson(
    input: CreateLessonRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateLessonRequest, CreateLessonResponse>;
  /**
   * @generated from protobuf rpc: EndLesson(sparx.science.lessons.v1.EndLessonRequest) returns (sparx.science.lessons.v1.EndLessonResponse);
   */
  endLesson(
    input: EndLessonRequest,
    options?: RpcOptions,
  ): UnaryCall<EndLessonRequest, EndLessonResponse>;
  /**
   * @generated from protobuf rpc: JoinLesson(sparx.science.lessons.v1.JoinLessonRequest) returns (sparx.science.lessons.v1.JoinLessonResponse);
   */
  joinLesson(
    input: JoinLessonRequest,
    options?: RpcOptions,
  ): UnaryCall<JoinLessonRequest, JoinLessonResponse>;
  /**
   * @generated from protobuf rpc: LeaveLesson(sparx.science.lessons.v1.LeaveLessonRequest) returns (sparx.science.lessons.v1.LeaveLessonResponse);
   */
  leaveLesson(
    input: LeaveLessonRequest,
    options?: RpcOptions,
  ): UnaryCall<LeaveLessonRequest, LeaveLessonResponse>;
  /**
   * @generated from protobuf rpc: WatchLessonState(sparx.science.lessons.v1.WatchLessonStateRequest) returns (stream sparx.science.lessons.v1.WatchLessonStateResponse);
   */
  watchLessonState(
    input: WatchLessonStateRequest,
    options?: RpcOptions,
  ): ServerStreamingCall<WatchLessonStateRequest, WatchLessonStateResponse>;
  /**
   * @generated from protobuf rpc: GetArchivedLesson(sparx.science.lessons.v1.GetArchivedLessonRequest) returns (sparx.science.lessons.v1.GetArchivedLessonResponse);
   */
  getArchivedLesson(
    input: GetArchivedLessonRequest,
    options?: RpcOptions,
  ): UnaryCall<GetArchivedLessonRequest, GetArchivedLessonResponse>;
  /**
   * @generated from protobuf rpc: GenerateLessonActivityPackage(sparx.science.lessons.v1.GenerateLessonActivityPackageRequest) returns (sparx.science.lessons.v1.GenerateLessonActivityPackageResponse);
   */
  generateLessonActivityPackage(
    input: GenerateLessonActivityPackageRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GenerateLessonActivityPackageRequest,
    GenerateLessonActivityPackageResponse
  >;
  /**
   * @generated from protobuf rpc: UpdateLessonState(sparx.science.lessons.v1.UpdateLessonStateRequest) returns (sparx.science.lessons.v1.UpdateLessonStateResponse);
   */
  updateLessonState(
    input: UpdateLessonStateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateLessonStateRequest, UpdateLessonStateResponse>;
  /**
   * @generated from protobuf rpc: CreateLessonActivity(sparx.science.lessons.v1.CreateLessonActivityRequest) returns (sparx.science.lessons.v1.CreateLessonActivityResponse);
   */
  createLessonActivity(
    input: CreateLessonActivityRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateLessonActivityRequest, CreateLessonActivityResponse>;
  /**
   * @generated from protobuf rpc: UpdateStudentLessonState(sparx.science.lessons.v1.UpdateStudentLessonStateRequest) returns (sparx.science.lessons.v1.UpdateStudentLessonStateResponse);
   */
  updateStudentLessonState(
    input: UpdateStudentLessonStateRequest,
    options?: RpcOptions,
  ): UnaryCall<
    UpdateStudentLessonStateRequest,
    UpdateStudentLessonStateResponse
  >;
  /**
   * @generated from protobuf rpc: AcknowledgeLessonNotification(sparx.science.lessons.v1.AcknowledgeLessonNotificationRequest) returns (sparx.science.lessons.v1.AcknowledgeLessonNotificationResponse);
   */
  acknowledgeLessonNotification(
    input: AcknowledgeLessonNotificationRequest,
    options?: RpcOptions,
  ): UnaryCall<
    AcknowledgeLessonNotificationRequest,
    AcknowledgeLessonNotificationResponse
  >;
}
/**
 * @generated from protobuf service sparx.science.lessons.v1.Lessons
 */
export class LessonsClient implements ILessonsClient, ServiceInfo {
  typeName = Lessons.typeName;
  methods = Lessons.methods;
  options = Lessons.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: ListLessons(sparx.science.lessons.v1.ListLessonsRequest) returns (sparx.science.lessons.v1.ListLessonsResponse);
   */
  listLessons(
    input: ListLessonsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListLessonsRequest, ListLessonsResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListLessonsRequest, ListLessonsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: ListArchivedLessons(sparx.science.lessons.v1.ListArchivedLessonsRequest) returns (sparx.science.lessons.v1.ListArchivedLessonsResponse);
   */
  listArchivedLessons(
    input: ListArchivedLessonsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListArchivedLessonsRequest, ListArchivedLessonsResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListArchivedLessonsRequest,
      ListArchivedLessonsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: CreateLesson(sparx.science.lessons.v1.CreateLessonRequest) returns (sparx.science.lessons.v1.CreateLessonResponse);
   */
  createLesson(
    input: CreateLessonRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateLessonRequest, CreateLessonResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateLessonRequest, CreateLessonResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: EndLesson(sparx.science.lessons.v1.EndLessonRequest) returns (sparx.science.lessons.v1.EndLessonResponse);
   */
  endLesson(
    input: EndLessonRequest,
    options?: RpcOptions,
  ): UnaryCall<EndLessonRequest, EndLessonResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<EndLessonRequest, EndLessonResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: JoinLesson(sparx.science.lessons.v1.JoinLessonRequest) returns (sparx.science.lessons.v1.JoinLessonResponse);
   */
  joinLesson(
    input: JoinLessonRequest,
    options?: RpcOptions,
  ): UnaryCall<JoinLessonRequest, JoinLessonResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<JoinLessonRequest, JoinLessonResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: LeaveLesson(sparx.science.lessons.v1.LeaveLessonRequest) returns (sparx.science.lessons.v1.LeaveLessonResponse);
   */
  leaveLesson(
    input: LeaveLessonRequest,
    options?: RpcOptions,
  ): UnaryCall<LeaveLessonRequest, LeaveLessonResponse> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<LeaveLessonRequest, LeaveLessonResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: WatchLessonState(sparx.science.lessons.v1.WatchLessonStateRequest) returns (stream sparx.science.lessons.v1.WatchLessonStateResponse);
   */
  watchLessonState(
    input: WatchLessonStateRequest,
    options?: RpcOptions,
  ): ServerStreamingCall<WatchLessonStateRequest, WatchLessonStateResponse> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<WatchLessonStateRequest, WatchLessonStateResponse>(
      'serverStreaming',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetArchivedLesson(sparx.science.lessons.v1.GetArchivedLessonRequest) returns (sparx.science.lessons.v1.GetArchivedLessonResponse);
   */
  getArchivedLesson(
    input: GetArchivedLessonRequest,
    options?: RpcOptions,
  ): UnaryCall<GetArchivedLessonRequest, GetArchivedLessonResponse> {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetArchivedLessonRequest, GetArchivedLessonResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GenerateLessonActivityPackage(sparx.science.lessons.v1.GenerateLessonActivityPackageRequest) returns (sparx.science.lessons.v1.GenerateLessonActivityPackageResponse);
   */
  generateLessonActivityPackage(
    input: GenerateLessonActivityPackageRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GenerateLessonActivityPackageRequest,
    GenerateLessonActivityPackageResponse
  > {
    const method = this.methods[8],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GenerateLessonActivityPackageRequest,
      GenerateLessonActivityPackageResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: UpdateLessonState(sparx.science.lessons.v1.UpdateLessonStateRequest) returns (sparx.science.lessons.v1.UpdateLessonStateResponse);
   */
  updateLessonState(
    input: UpdateLessonStateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateLessonStateRequest, UpdateLessonStateResponse> {
    const method = this.methods[9],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateLessonStateRequest, UpdateLessonStateResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: CreateLessonActivity(sparx.science.lessons.v1.CreateLessonActivityRequest) returns (sparx.science.lessons.v1.CreateLessonActivityResponse);
   */
  createLessonActivity(
    input: CreateLessonActivityRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateLessonActivityRequest, CreateLessonActivityResponse> {
    const method = this.methods[10],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      CreateLessonActivityRequest,
      CreateLessonActivityResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: UpdateStudentLessonState(sparx.science.lessons.v1.UpdateStudentLessonStateRequest) returns (sparx.science.lessons.v1.UpdateStudentLessonStateResponse);
   */
  updateStudentLessonState(
    input: UpdateStudentLessonStateRequest,
    options?: RpcOptions,
  ): UnaryCall<
    UpdateStudentLessonStateRequest,
    UpdateStudentLessonStateResponse
  > {
    const method = this.methods[11],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateStudentLessonStateRequest,
      UpdateStudentLessonStateResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: AcknowledgeLessonNotification(sparx.science.lessons.v1.AcknowledgeLessonNotificationRequest) returns (sparx.science.lessons.v1.AcknowledgeLessonNotificationResponse);
   */
  acknowledgeLessonNotification(
    input: AcknowledgeLessonNotificationRequest,
    options?: RpcOptions,
  ): UnaryCall<
    AcknowledgeLessonNotificationRequest,
    AcknowledgeLessonNotificationResponse
  > {
    const method = this.methods[12],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      AcknowledgeLessonNotificationRequest,
      AcknowledgeLessonNotificationResponse
    >('unary', this._transport, method, opt, input);
  }
}
