import {
  Button,
  Grid,
  Input,
  Link as ChakraLink,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
} from '@chakra-ui/react';
import {
  ResourceMetadata,
  ResourceStatus,
  SchemeOfLearning,
  SchemeOfLearningWeekType,
} from '@sparx/api/apis/sparx/planning/v1/sol';
import { useCurriculums } from 'api/content';
import {
  SchoolYearWithDates,
  useHolidaysForSchoolYear,
  useSchoolYears,
  useWeeksForYear,
} from 'api/school';
import { useCreateSol, useRenameSol, useRenameTemplateSol, useSol, useTemplateSol } from 'api/sol';
import { useClientEvent } from 'components/ClientEventProvider';
import { EditableField } from 'components/form/FormFields';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { Warning } from 'components/warning';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  copySolWeeks,
  hasTopicsToMigrate,
  migrateWeekTopics,
  weekIsHolidayWeek,
} from '../solHelpers';
import { MigrationWarning } from '../topic-migration/MigrationWarning';
import { useTopicMigrationMap } from '../topic-migration/query';
import { SolOrTemplate, TemplateSOL } from './SolTable';

interface EditableFields {
  name: string;
  curriculum: string;
  academicYear: string; // This is just the year index to match what Maths TP does
  homeworkOffInHolidays: boolean;
}

const defaultFields = (defaultYear?: string): EditableFields => ({
  name: '',
  curriculum: '',
  academicYear: defaultYear?.split('/')[3] || '',
  homeworkOffInHolidays: true,
});

export type SolModalAction =
  // Model to create a new SOL from nothing.
  | {
      kind: 'new';
    }
  // Modal to rename an existing SOL.
  | {
      kind: 'rename';
      sol: SolOrTemplate;
    }
  // Modal to create a new SOL from copying an existing SOL.
  | {
      kind: 'copy';
      sol: SolOrTemplate;
    }
  // Modal to create a new SOL from a template.
  | {
      kind: 'useTemplate';
      sol: TemplateSOL;
    };

interface EditSolModalProps {
  action?: SolModalAction;
  onSave: (name: string, isNew?: boolean) => void;
  onClose: () => void;
  defaultYear?: string;
}

export const EditSolModal = ({ action, onSave, onClose, defaultYear }: EditSolModalProps) => {
  const { sendEvent } = useClientEvent();
  const [fields, setFields] = useState<EditableFields>(defaultFields(defaultYear));

  // Reset fields when scheme changes
  useEffect(
    () =>
      setFields(
        action?.kind === 'new' || !action
          ? defaultFields(defaultYear)
          : {
              name: (action.kind === 'copy' ? `Copy of ` : '') + action.sol.displayName,
              curriculum: action.sol.baseCurriculum,
              academicYear:
                action.sol.isTemplate || action.kind === 'copy'
                  ? defaultYear?.split('/')[3] || ''
                  : action.sol.schoolYear,
              homeworkOffInHolidays: true,
            },
      ),
    [action, setFields, defaultYear],
  );

  const { data: schoolYears = [] } = useSchoolYears({ suspense: true }, true);
  const { data: curriculums = [] } = useCurriculums({ suspense: true });
  const { data: holidays = [], isLoading: loadingHolidays } = useHolidaysForSchoolYear(
    fields.academicYear,
    { suspense: false },
  );
  const { data: calendarWeeks, isLoading: loadingCalendar } = useWeeksForYear(fields.academicYear, {
    suspense: false,
  });
  const { data: topicMigrationMap, isSuccess: migrationMapLoaded } = useTopicMigrationMap();

  // If we are copying we need to load the SOL / template.
  const schoolGroupID = action?.kind === 'useTemplate' ? action.sol.name.split('/')[1] : '';
  const solID =
    action?.kind === 'copy' || action?.kind === 'useTemplate' ? action.sol.name.split('/')[3] : '';
  const solType =
    action?.kind !== 'new' && action?.sol.metadata?.status === ResourceStatus.PUBLISHED
      ? 'published'
      : 'draft';
  const deletedSol = action?.kind === 'copy' && !!action.sol.metadata?.deleteTime;
  const { data: solToCopy, isSuccess: solLoaded } = useSol(solID, solType, deletedSol, {
    suspense: false,
    enabled: action?.kind === 'copy' && !!solID,
  });
  const { data: templateSol, isSuccess: solTemplateLoaded } = useTemplateSol(
    schoolGroupID,
    solID,
    solType,
    {
      suspense: false,
      enabled: action?.kind === 'useTemplate' && !!solID,
    },
  );

  const hasMigratableTopics = hasTopicsToMigrate(
    solToCopy?.schedule?.weeks || [],
    topicMigrationMap || {},
  );

  const patchFields = (field: Partial<EditableFields>) => setFields(p => ({ ...p, ...field }));
  const valid =
    fields.name.trim() &&
    ((action?.kind === 'rename' && action.sol.isTemplate) ||
      (fields.curriculum.trim() && fields.academicYear.trim()));

  const { mutateAsync: createSol, isLoading: isCreating } = useCreateSol();
  const { mutateAsync: renameSol, isLoading: isUpdating } = useRenameSol();
  const { mutateAsync: renameTeplateSol, isLoading: isUpdatingTemplate } = useRenameTemplateSol();
  const isUpdatingCreating = isUpdating || isCreating || isUpdatingTemplate;
  const isLoading =
    isUpdatingCreating ||
    (action?.kind === 'copy' && !solLoaded) ||
    (action?.kind === 'useTemplate' && !solTemplateLoaded) ||
    loadingCalendar ||
    !migrationMapLoaded;

  const selectedCurriculum = curriculums.find(c => c.curriculum.name === fields.curriculum);
  const selectedAcademicYear = schoolYears.find(sy =>
    sy.name.endsWith(`/years/${fields.academicYear}`),
  );
  const sameAY = defaultYear === selectedAcademicYear?.name;

  const submit = () => {
    if (action?.kind === 'new') {
      if (calendarWeeks) {
        createSol(
          SchemeOfLearning.create({
            displayName: fields.name,
            baseCurriculum: fields.curriculum,
            schoolYear: fields.academicYear, // This is just the year index to match what Maths TP does
            schedule: {
              weeks: calendarWeeks.map(w => ({
                weekType:
                  fields.homeworkOffInHolidays && weekIsHolidayWeek(w)
                    ? SchemeOfLearningWeekType.NO_HOMEWORK
                    : SchemeOfLearningWeekType.FULL_HOMEWORK,
                workUnits: [],
                weekIndex: w.index,
              })),
            },
            metadata: ResourceMetadata.create({
              tags: ['subject:science'],
            }),
          }),
        ).then(sol => {
          sendEvent(
            {
              category: 'scheme_of_learning',
              action: 'create_sol',
            },
            {
              solName: sol.name,
              displayName: fields.name,
              baseCurriculum: fields.curriculum,
              schoolYear: fields.academicYear,
              homeworkOffInHolidays: fields.homeworkOffInHolidays ? 'true' : 'false',
            },
          );
          onSave(sol.name, true);
        });
      }
    } else if (action?.kind === 'rename') {
      if (action.sol.isTemplate) {
        renameTeplateSol({ ...action.sol, displayName: fields.name }).then(sol =>
          onSave(sol.name, false),
        );
      } else {
        renameSol({ ...action.sol, displayName: fields.name }).then(sol => onSave(sol.name, false));
      }
    } else if (action?.kind === 'copy') {
      if (!calendarWeeks || !solToCopy || !solToCopy.schedule) return;

      // Migrate from old topics to new, this can be removed once there are no SOLs with old topics accessible.
      const { newWeeks, didMigrateTopics } = migrateWeekTopics(
        solToCopy.schedule.weeks,
        topicMigrationMap || {},
      );
      const weeks = copySolWeeks(newWeeks, calendarWeeks, sameAY, fields.homeworkOffInHolidays);

      const req = SchemeOfLearning.create({
        ...solToCopy,
        name: '',
        displayName: fields.name,
        baseCurriculum: fields.curriculum,
        schoolYear: fields.academicYear, // This is just the year index to match what Maths TP does
        schedule: {
          weeks,
        },
        metadata: ResourceMetadata.create({
          tags: ['subject:science'],
        }),
      });

      createSol(req).then(sol => {
        sendEvent(
          {
            category: 'scheme_of_learning',
            action: 'copy_sol',
          },
          {
            solName: sol.name,
            copiedSolName: solToCopy.name,
            copiedSolVersion: solToCopy.metadata?.version.toString() || 'unknown',
            displayName: fields.name,
            baseCurriculum: fields.curriculum,
            schoolYear: fields.academicYear,
            homeworkOffInHolidays: fields.homeworkOffInHolidays ? 'true' : 'false',
            didMigrateTopics: didMigrateTopics ? 'true' : 'false',
          },
        );
        onSave(sol.name, true);
      });
    } else if (action?.kind === 'useTemplate') {
      if (!calendarWeeks || !templateSol || !templateSol.schedule) return;

      let weeks = [...templateSol.schedule.weeks];
      weeks = copySolWeeks(weeks, calendarWeeks, false, fields.homeworkOffInHolidays);

      const req = SchemeOfLearning.create({
        name: '',
        originalTemplate: templateSol.name,
        displayName: fields.name,
        baseCurriculum: fields.curriculum,
        schoolYear: fields.academicYear, // This is just the year index to match what Maths TP does
        schedule: {
          weeks,
        },
        metadata: ResourceMetadata.create({
          tags: ['subject:science'],
        }),
      });

      createSol(req).then(sol => {
        sendEvent(
          {
            category: 'scheme_of_learning',
            action: 'use_template_sol',
          },
          {
            solName: sol.name,
            templateName: templateSol.name,
            templateVersion: templateSol.metadata?.version.toString() || 'unknown',
            displayName: fields.name,
            baseCurriculum: fields.curriculum,
            schoolYear: fields.academicYear,
            homeworkOffInHolidays: fields.homeworkOffInHolidays ? 'true' : 'false',
          },
        );
        onSave(sol.name, true);
      });
    }
  };

  return (
    <Modal isOpen={Boolean(action)} size="xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb={1}>
          {action?.kind === 'useTemplate'
            ? 'Create Scheme from template'
            : action?.kind !== 'rename'
              ? 'Create a new Scheme of Learning'
              : 'Rename Scheme of Learning'}
        </ModalHeader>
        <ModalBody>
          <Grid gap={4}>
            <EditableField name="Name">
              <Input value={fields.name} onChange={e => patchFields({ name: e.target.value })} />
            </EditableField>
            <EditableField name="Base Curriculum">
              {action?.kind !== 'new' && action?.kind !== 'useTemplate' ? (
                <>{selectedCurriculum?.curriculum.displayName || 'Unknown Curriculum'}</>
              ) : (
                <Select
                  value={fields.curriculum}
                  onChange={e => patchFields({ curriculum: e.target.value })}
                >
                  <option value="" disabled={true}>
                    Select a curriculum
                  </option>
                  {curriculums.map(curr => (
                    <option value={curr.curriculum.name} key={curr.curriculum.name}>
                      {curr.curriculum.displayName}
                    </option>
                  ))}
                </Select>
              )}
            </EditableField>
            {(action?.kind === 'new' ||
              action?.kind === 'useTemplate' ||
              !action?.sol.isTemplate) && (
              <>
                <EditableField name="Academic Year">
                  {action?.kind === 'rename' ? (
                    <>
                      {selectedAcademicYear
                        ? formatAcademicYear(selectedAcademicYear)
                        : 'Unknown year'}
                    </>
                  ) : (
                    <Select
                      value={fields.academicYear}
                      onChange={e => patchFields({ academicYear: e.target.value })}
                    >
                      <option value="" disabled={true}>
                        Select a academic year
                      </option>
                      {schoolYears.map(sy => (
                        <option key={sy.name} value={sy.name.split('/')[3]}>
                          {formatAcademicYear(sy)}
                        </option>
                      ))}
                    </Select>
                  )}
                </EditableField>
                {action?.kind !== 'rename' && (action?.kind !== 'copy' || !sameAY) && (
                  <EditableField name="Automatically turn off homework in holiday weeks">
                    <ToggleSwitch
                      checked={fields.homeworkOffInHolidays && holidays.length > 0}
                      setChecked={checked => patchFields({ homeworkOffInHolidays: checked })}
                      leftLabel="No"
                      rightLabel="Yes"
                      offColour="gray.400"
                      enableColourBG={true}
                      isDisabled={holidays.length === 0}
                    />
                  </EditableField>
                )}

                {action?.kind !== 'rename' &&
                  fields.academicYear &&
                  !loadingHolidays &&
                  holidays.length === 0 && (
                    <Warning
                      status="warning"
                      title={
                        <strong>
                          You haven&apos;t set holiday dates for{' '}
                          {selectedAcademicYear?.current
                            ? 'this'
                            : selectedAcademicYear?.next
                              ? 'next'
                              : 'the selected'}{' '}
                          academic year
                        </strong>
                      }
                    >
                      Set your holiday dates before creating schemes of learning and we&apos;ll use
                      these to help you plan homework correctly. <br />
                      <ChakraLink
                        as={Link}
                        to={`/teacher/school-settings/academic-year${selectedAcademicYear?.next ? '?ay=next' : ''}`}
                        textDecoration="underline"
                      >
                        Manage your holiday dates here.
                      </ChakraLink>
                    </Warning>
                  )}

                {hasMigratableTopics && <MigrationWarning />}
              </>
            )}
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} mr={2} variant="outline" isDisabled={isUpdatingCreating}>
            Close
          </Button>
          <Button
            onClick={submit}
            colorScheme="buttonTeal"
            isDisabled={!valid}
            isLoading={isLoading}
          >
            {action?.kind !== 'rename' ? 'Create' : 'Save'}
          </Button>
        </ModalFooter>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

const formatAcademicYear = (sy: SchoolYearWithDates) =>
  `${format(sy.start, "d MMM y'")} - ` +
  `${format(sy.end, "d MMM y'")} (` +
  `${sy.current ? 'This' : sy.next ? 'Next' : 'Other'} year)`;
