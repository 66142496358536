import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  IconButton,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Show,
  Spacer,
  Text,
  UnorderedList,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SchoolPeriod, SchoolPeriodType } from '@sparx/api/apis/sparx/school/calendar/v3/calendar';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { createColumnHelper } from '@tanstack/react-table';
import {
  SchoolPeriodWithDates,
  SchoolYearWithDates,
  useAllHolidays,
  useCreateOrUpdateSchoolPeriod,
  useDeleteSchoolPeriod,
  useSchool,
  useSchoolYears,
} from 'api/school';
import { EditableField } from 'components/form/FormFields';
import { DataTable } from 'components/table/DataTable';
import { Warning } from 'components/warning';
import { format, isBefore, max, min } from 'date-fns';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DateRange, Range } from 'react-date-range';
import { useSearchParams } from 'react-router-dom';

const noSelectionDateRange: Range[] = [
  {
    startDate: undefined,
    // This is a hack as the library doesn't properly support not having a selected range to begin with
    // https://github.com/hypeserver/react-date-range/issues/330
    endDate: new Date(1),
    showDateDisplay: false,
    key: 'selection',
  },
];

export const AcademicYearView = () => {
  const { mutate: createOrUpdatePeriod, isLoading: createOrUpdateLoading } =
    useCreateOrUpdateSchoolPeriod();
  const toast = useToast();
  const [search, setSearch] = useSearchParams();
  // Store the param so we have it after it's cleared
  const openToNextYear = useRef(search.get('ay') === 'next');
  useEffect(() => {
    if (search.get('ay')) {
      setSearch(
        prev => {
          prev.delete('ay');
          return prev;
        },
        { replace: true },
      );
    }
  }, [setSearch]);

  const { data: school } = useSchool({ suspense: true });

  const { data: schoolYears = [] } = useSchoolYears({ suspense: true }, true);
  const { data: allHolidays = [] } = useAllHolidays({ suspense: true, refetchOnMount: 'always' });

  const [selectedYear, setSelectedYear] = useState<SchoolYearWithDates | undefined>(
    schoolYears.find(sy => (openToNextYear.current ? sy.next : sy.current)),
  );
  useEffect(() => {
    if (schoolYears) {
      setSelectedYear(schoolYears.find(sy => (openToNextYear.current ? sy.next : sy.current)));
    }
  }, [schoolYears]);

  // editPeriod controls displaying the add/edit dialog
  const [editPeriod, setEditPeriod] = useState<Partial<SchoolPeriodWithDates> | undefined>();
  // lastAddedDate is used to focus the calendar on the last date that was added to make adding date a bit slicker
  // it is reset if the selected year changes.
  const [lastAddedDate, setLastAddedDate] = useState<Date | undefined>();
  useEffect(() => {
    setLastAddedDate(undefined);
  }, [selectedYear]);

  const calendarCount = useBreakpointValue({ sm: 1, md: 2 }, { ssr: false });
  const [dateSelection, setDateSelection] = useState<Range[]>(noSelectionDateRange);

  // Filter & sort the holidays for the selected A/Y, also calculate the min &
  // max editable dates, based on the school years, and existing holidays
  const { holidays, minDate, maxDate } = useMemo(() => {
    const allValid = allHolidays.filter(h =>
      schoolYears.some(sy => isBefore(h.start, sy.end) && isBefore(sy.start, h.end)),
    );
    return {
      holidays: (selectedYear
        ? allValid.filter(
            h => isBefore(h.start, selectedYear.end) && isBefore(selectedYear.start, h.end),
          )
        : allValid
      ).sort((a, b) => a.start.getTime() - b.start.getTime()),
      minDate: schoolYears.reduce((acc, sy) => min([sy.start, acc]), new Date()),
      maxDate: schoolYears.reduce((acc, sy) => max([sy.end, acc]), new Date()),
    };
  }, [allHolidays, selectedYear, schoolYears]);

  const doEdit = useCallback((period: SchoolPeriodWithDates) => {
    setDateSelection([
      { startDate: period.start, endDate: period.end, showDateDisplay: false, key: 'selection' },
    ]);
    setEditPeriod({ ...period });
  }, []);

  const doSave = () => {
    const startDate = dateSelection[0].startDate;
    const endDate = dateSelection[0].endDate;

    if (!editPeriod || !startDate || !endDate || !isValidInput(dateSelection, editPeriod)) return;

    // If edit period doesn't have a year will will use the selected year, or the current year.
    const yearNum = selectedYear
      ? selectedYear.name.split('/')[3]
      : schoolYears.find(sy => sy.current)?.name.split('/')[3] || 0;

    // Truncate to day and as UTC before converting to pb timestamps
    const startDay = new Date(
      Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()),
    );
    const endDay = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));

    // create the new period
    const period: SchoolPeriod = {
      name: editPeriod.name || '',
      periodType: editPeriod.periodType || SchoolPeriodType.HOLIDAY,
      schoolYear: editPeriod.schoolYear || Number(yearNum) || 0,
      displayName: editPeriod.displayName || '',
      startDate: Timestamp.fromDate(startDay),
      endDate: Timestamp.fromDate(endDay),
    };

    setLastAddedDate(endDate);

    createOrUpdatePeriod(period, {
      onSuccess: () => {
        setEditPeriod(undefined);
        toast({
          title: `${period.name ? 'Updated' : 'Added'} holiday: ${period.displayName}`,
          status: 'success',
          position: 'bottom-left',
        });
      },
      onError: () => {
        toast({
          title: `Error ${period.name ? 'saving' : 'adding'} holiday`,
          status: 'error',
          position: 'bottom-left',
        });
      },
    });
  };

  const addNewButton = (
    <Button
      colorScheme="buttonTeal"
      leftIcon={<FontAwesomeIcon icon={faPlus} />}
      flexShrink={0}
      ml={2}
      onClick={() => {
        setDateSelection(noSelectionDateRange);
        setEditPeriod({ displayName: '' });
      }}
    >
      Add new holiday
    </Button>
  );

  return (
    <Box>
      <Heading size="md">Holiday dates</Heading>
      <Text mt={2}>Sparx uses holiday dates to:</Text>
      <UnorderedList>
        <ListItem>
          Make it quicker for you to create Schemes of Learning by giving the option to
          automatically turn homework off in holiday weeks.
        </ListItem>
        <ListItem>Extend the due dates for homework that would fall in holiday weeks.</ListItem>
      </UnorderedList>
      <Text mt={2} mb={4}>
        Changes here won&apos;t impact any scheduled homework.
      </Text>
      {/* Show the shared message if they also have Maths (the only other product that uses them at the moment) */}
      {school?.products.includes(Product.SPARX_MATHS) && (
        <Warning status="info">
          These holiday dates will also be used when creating Schemes of Learning in Sparx Maths.
        </Warning>
      )}

      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" my={4}>
        <FormControl display="flex" justifyContent="flex-start" alignItems="center" width="unset">
          <FormLabel marginBottom={0} marginRight={2} textAlign="right">
            Academic Year:
          </FormLabel>
          <Select
            value={selectedYear?.name || 'all'}
            onChange={e => setSelectedYear(schoolYears.find(sy => sy.name === e.target.value))}
            width="unset"
          >
            <option value="" disabled={true}>
              Select an academic year
            </option>
            {schoolYears.map(sy => (
              <option key={sy.name} value={sy.name}>
                {formatAcademicYear(sy)}
              </option>
            ))}
            <option value="all">All</option>
          </Select>
        </FormControl>
        {addNewButton}
      </Box>

      <HolidaysTable holidays={holidays} addNewButton={addNewButton} doEdit={doEdit} />
      <Modal isOpen={!!editPeriod} onClose={() => setEditPeriod(undefined)}>
        <ModalOverlay />
        <ModalContent minWidth="fit-content">
          <ModalHeader>{!editPeriod?.name ? 'Add' : 'Edit'} holiday</ModalHeader>
          <ModalBody>
            <Grid gap={4}>
              <EditableField name="Holiday Name">
                <Input
                  value={editPeriod?.displayName || ''}
                  onChange={e =>
                    editPeriod && setEditPeriod({ ...editPeriod, displayName: e.target.value })
                  }
                />
              </EditableField>
              <EditableField name="Dates">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Text alignSelf="flex-start">
                    {!dateSelection[0].startDate ||
                    !dateSelection[0].endDate ||
                    dateSelection[0].endDate.getTime() === 1
                      ? 'Select a date range below'
                      : formatRange(dateSelection[0].startDate, dateSelection[0].endDate)}
                  </Text>

                  <DateRange
                    months={calendarCount || 1}
                    direction="horizontal"
                    editableDateInputs={true}
                    ranges={dateSelection}
                    onChange={item => setDateSelection([item.selection])}
                    shownDate={!editPeriod?.name ? lastAddedDate || selectedYear?.start : undefined}
                    minDate={editPeriod?.start ? min([minDate, editPeriod.start]) : minDate}
                    maxDate={editPeriod?.end ? max([maxDate, editPeriod.end]) : maxDate}
                  />
                </Box>
              </EditableField>
            </Grid>
          </ModalBody>
          <ModalFooter display="flex" p={3}>
            <Button
              colorScheme="buttonTeal"
              variant="outline"
              onClick={() => setEditPeriod(undefined)}
            >
              Cancel
            </Button>
            <Spacer />
            <Button
              colorScheme="buttonTeal"
              isDisabled={!isValidInput(dateSelection, editPeriod)}
              onClick={doSave}
              isLoading={createOrUpdateLoading}
            >
              {!editPeriod?.name ? 'Add holiday' : 'Save changes'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

type HolidaysTableProps = {
  holidays: SchoolPeriodWithDates[];
} & (
  | { readOnly: true; doEdit?: never; addNewButton?: never }
  | { readOnly?: false; doEdit: (period: SchoolPeriodWithDates) => void; addNewButton: ReactNode }
);

export const HolidaysTable = ({ holidays, readOnly, addNewButton, doEdit }: HolidaysTableProps) => {
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<SchoolPeriodWithDates>();
    return [
      columnHelper.accessor('displayName', {
        header: 'Holiday',
        enableSorting: false,
      }),
      columnHelper.accessor(row => formatRange(row.start, row.end), {
        header: 'Dates',
        enableSorting: false,
      }),
      ...(!readOnly
        ? [
            columnHelper.display({
              id: 'actions',
              cell: info => <HolidayButtons period={info.row.original} doEdit={doEdit} />,
            }),
          ]
        : []),
    ];
  }, [readOnly, doEdit]);

  return (
    <DataTable
      data={holidays}
      columns={columns}
      noDataRow={
        <Box>
          <Text mb={4}>No holiday dates have been added yet.</Text>
          {addNewButton}
        </Box>
      }
    />
  );
};

const HolidayButtons = ({
  period,
  doEdit,
}: {
  period: SchoolPeriodWithDates;
  doEdit: (period: SchoolPeriodWithDates) => void;
}) => {
  const { mutate: deletePeriod, isLoading: deleteLoading } = useDeleteSchoolPeriod();
  const toast = useToast();

  const doDelete = useCallback(
    (period: SchoolPeriodWithDates) => {
      deletePeriod(period.name, {
        onSuccess: () => {
          toast({
            title: `Removed holiday: ${period.displayName}`,
            status: 'success',
            position: 'bottom-left',
          });
        },
        onError: () => {
          toast({
            title: 'Error removing holiday.',
            status: 'error',
            position: 'bottom-left',
          });
        },
      });
    },
    [deletePeriod, toast],
  );

  return (
    <HStack justifyContent="flex-end" spacing={4}>
      <Show above="md">
        <Button
          variant="outline"
          colorScheme="buttonBlue"
          leftIcon={<FontAwesomeIcon icon={faPencil} />}
          onClick={() => doEdit(period)}
        >
          Edit
        </Button>
      </Show>
      <Show below="md">
        <IconButton
          variant="outline"
          colorScheme="buttonBlue"
          icon={<FontAwesomeIcon icon={faPencil} />}
          aria-label="Edit"
          onClick={() => doEdit(period)}
        />
      </Show>
      <Show above="lg">
        <Button
          variant="outline"
          colorScheme="red"
          leftIcon={<FontAwesomeIcon icon={faTrash} />}
          onClick={() => doDelete(period)}
          isLoading={deleteLoading}
        >
          Remove
        </Button>
      </Show>
      <Show below="lg">
        <IconButton
          variant="outline"
          colorScheme="red"
          icon={<FontAwesomeIcon icon={faTrash} />}
          aria-label="Remove"
          onClick={() => doDelete(period)}
          isLoading={deleteLoading}
        />
      </Show>
    </HStack>
  );
};

const formatAcademicYear = (sy: SchoolYearWithDates) =>
  `${sy.current ? 'This' : sy.next ? 'Next' : 'Other'} year (` +
  `${format(sy.start, "d MMM y'")} - ` +
  `${format(sy.end, "d MMM y'")})`;

const formatRange = (start: Date, end: Date) =>
  `${format(start, 'eee d MMM y')} - ${format(end, 'eee d MMM y')}`;

const isValidInput = (
  dateSelection: Range[],
  editPeriod: Partial<SchoolPeriodWithDates> | undefined,
) => {
  const validDates =
    dateSelection[0].startDate &&
    dateSelection[0].endDate &&
    dateSelection[0].endDate.getTime() !== 1;
  const validName = editPeriod?.displayName && editPeriod.displayName.length > 0;

  return validDates && validName;
};
