import { Flex, Link, Tag, TagProps, Text } from '@chakra-ui/react';
import { faFlask } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const Beta = ({ feedbackLink, ...props }: { feedbackLink?: string } & TagProps) => (
  <Tag colorScheme="green" py={1} {...props}>
    <Text mr={2} color="green.500">
      <FontAwesomeIcon icon={faFlask} />
    </Text>
    <Flex flexDirection={{ base: 'column', md: 'row' }}>
      <Text>Beta{feedbackLink && ' feature.'}</Text>
      {feedbackLink && (
        <Link href={feedbackLink} isExternal textDecoration="underline" ml={{ base: 0, md: 1 }}>
          Let us know what you think.
        </Link>
      )}
    </Flex>
  </Tag>
);
