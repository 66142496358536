import { Alert, AlertIcon, Text } from '@chakra-ui/react';
import { translateErrorMessage } from 'api/utils';
import React from 'react';

export const ErrorBox = ({ error }: { error: unknown }) => {
  if (!error) {
    return null;
  }

  return (
    <Alert status="error" borderRadius="md" mb={4}>
      <AlertIcon />
      <Text>
        <strong>Error:</strong> {translateErrorMessage(error)}
      </Text>
    </Alert>
  );
};
