import { BlocksContent } from '@strapi/blocks-react-renderer';

import { Media } from './media';

export enum Chip {
  New = 'new',
  Update = 'update',
}

export interface ScienceWhatsNew {
  id: number;
  spxID: string; // This isn't returned by the api, but added to the response in the query function
  attributes: {
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    Title: string;
    Body: BlocksContent;
    Chip: Chip;
    PromotedContent?: WhatsNewPromoted | null;
    Buttons: ButtonLink[];
    Image: { data: Media };
    SparxStaffOnly?: boolean;
    ShowInDidYouKnow?: boolean;
    PublishTimeOverride?: string;
    RequiredClientReleaseTimestamp?: string;
    Video?: Video;
  };
}

export interface WhatsNewPromoted {
  Body: BlocksContent;
  Button?: ButtonLink;
}

export interface ButtonLink {
  Text: string;
  URL: string;
  External?: boolean;
}

export interface Video {
  Video: { data: Media };
  Subtitles?: { data: Media | null };
  Poster?: { data: Media | null };
}
