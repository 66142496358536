import './index.css';
import 'app/analytics.ts'; // setup analytics
import '@sparx/react-utils/nodeRemoveFix';

import { ColorModeScript } from '@chakra-ui/react';
import { ensureTokenFetcherStarted } from 'api';
import { App } from 'app/App';
import { enGB } from 'date-fns/locale';
import setDefaultDateFnsOptions from 'date-fns/setDefaultOptions';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ResizeObserverPolyfill from 'resize-observer-polyfill'; // ResizeObserver (for old Safari)
import { theme } from 'theme/theme';

// Set the default locale for date-fns, if we want to support other locales in the future we will need to dynamically load them
setDefaultDateFnsOptions({ locale: enGB });

window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

declare global {
  interface Window {
    SCIENCE_SCHOOL_ID?: string;
    SCIENCE_USER_ID?: string;
    SPARX_USER_ID?: string;

    settings?: {
      ENV_NAME?: 'dev' | 'test2' | 'test1' | 'prod';
      AUTH_API_URL?: string;
      SCIENCE_API_URL?: string;
      SCHOOL_SELECT_URL?: string;
      TP_STUDENT_API_URL?: string;
      TP_GROUPS_API_URL?: string;
      CONTENT_API_URL?: string;
      SCHOOLS_API_URL?: string;
      CALENDAR_API_URL?: string;
      PLANNING_API_URL?: string;
      SCHOOL_STAFF_API_URL?: string;
      SCHOOL_STATUS_API_URL?: string;
      TRAINING_PROGRESS_API_URL?: string;
      CLIENT_ANALYTICS_URL?: string;
      FEEDBACK_API_URL?: string;
      WONDESYNC_URL?: string;
      CMS_API_URL?: string;
      INDEXWEAVER_API_URL?: string;
      TEACHERAPI_URL?: string;
      STUDENT_API_URL?: string;
      SPARX_ASSESSMENTS_URL?: string;
    };

    // https://help.hotjar.com/hc/en-us/articles/360034216634-Hotjar-JavaScript-Functions-Reference
    hj?: (event: string, exampleAction?: string, attributes?: Record<string, any>) => void;
  }
}

// Start the token fetcher
ensureTokenFetcherStarted();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.Fragment>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <App />
  </React.Fragment>,
);
