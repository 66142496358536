import ErrorPanel from '@sparx/mis-sync-import/src/MisSyncImport/components/errors/ErrorPanel/ErrorPanel';
import styles from '@sparx/mis-sync-import/src/MisSyncImport/components/errors/ErrorPanel/ErrorPanel.module.css';
import { SupportLink } from '@sparx/mis-sync-import/src/MisSyncImport/components/common';

interface GenericErrorPanelProps {
  task?: string;
}

const GenericErrorPanel = ({ task = 'complete the requested task' }: GenericErrorPanelProps) => {
  return (
    <ErrorPanel
      title="We're sorry, something has gone wrong"
      action={() => window.location.reload()}
      actionText="Try again"
    >
      <p>We weren’t able to {task} due to a technical issue on our end. Please try again.</p>
      <p className={styles.NoWrap}>
        If the issue keeps happening, contact <SupportLink linkText="Sparx Support" />.
      </p>
    </ErrorPanel>
  );
};

export default GenericErrorPanel;
