import { Box, Flex } from '@chakra-ui/react';
import { forwardRef, PropsWithChildren, ReactNode } from 'react';

interface PageProps {
  header?: ReactNode;
  footer?: ReactNode;
  isLastRender: boolean;
  mmToPxRatio: number;
}

export const Page = forwardRef<HTMLDivElement, PropsWithChildren<PageProps>>(
  ({ header, footer, isLastRender, mmToPxRatio, children }, ref) => (
    <Flex
      ref={ref}
      backgroundColor="white"
      flexGrow={1}
      flexDirection="column"
      borderRadius="md"
      boxShadow="md"
      width={mmToPxRatio * 210}
      height={isLastRender ? mmToPxRatio * (297 - 20) : 'initial'}
      my={4}
      mx="auto"
      padding={mmToPxRatio * 10}
      _notLast={{
        breakAfter: 'page',
        pageBreakAfter: 'always',
      }}
      sx={{
        '@media print': {
          display: 'block',
          my: 0,
          overflow: 'hidden',
          height: 'auto',
          maxHeight: '277mm', // 297mm - margin
          width: '190mm', // 210mm - margin
          borderRadius: 'initial',
          boxShadow: 'initial',
          padding: 0,
        },
      }}
    >
      {header}
      {children}
      <Box flex={1} />
      {footer}
    </Flex>
  ),
);
