import { Box, Button } from '@chakra-ui/react';
import {
  TrainingModulesList,
  TrainingProgressWheel,
  useResetTrainingProgress,
} from '@sparx/teacher-training';
import { useUserType } from 'api/sessions';
import { CurrentUserDisplayName } from 'components/Names';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';

export const TeacherTrainingView = () => {
  const { isSparxStaff } = useUserType();

  return (
    <PageContainer maxWidth={800}>
      <PageTitle
        title={
          <>
            <CurrentUserDisplayName givenOnly pluralise /> Sparx Science training
          </>
        }
        subtitle="This training is designed to help you get the most out of Sparx Science."
      >
        {isSparxStaff && <ResetProgressButton />}
        <TrainingProgressWheel />
      </PageTitle>

      <TrainingModulesList />
      {/* Add some spacing for the hotjar survey widget */}
      <Box mb={10} />
    </PageContainer>
  );
};

const ResetProgressButton = () => {
  const { mutate: resetProgress } = useResetTrainingProgress();
  return (
    <Button mr={2} onClick={() => resetProgress([])}>
      Reset progress
    </Button>
  );
};
