import { Box, CircularProgress, CircularProgressLabel, Flex, Text } from '@chakra-ui/react';

export const ProgressWheel = ({
  total,
  complete,
  loading = false,
}: {
  total: number;
  complete: number;
  loading?: boolean;
}) => {
  const allComplete = complete >= total && !loading;
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      direction={{ base: 'column', md: 'row' }}
      ml={3}
      mt={2}
      opacity={loading ? 0 : 1}
      transition="opacity 0.25s"
    >
      <Box textAlign="center">
        <CircularProgress min={0} max={total} value={complete} color="green.400" size={'120px'}>
          <CircularProgressLabel
            fontSize={'3xl'}
            fontWeight="bold"
            color={allComplete ? 'green.400' : undefined}
          >
            {complete}/{total}
          </CircularProgressLabel>
        </CircularProgress>
        {allComplete && (
          <Text fontWeight="bold" color="green.400" whiteSpace="nowrap">
            Complete!
          </Text>
        )}
      </Box>
    </Flex>
  );
};
