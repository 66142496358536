import { Box, BoxProps, Fade } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface CrossfadeComponent {
  key: string;
  in: boolean;
  component: ReactNode;
  wrapperProps?: BoxProps;
}

export interface CrossfadeProps {
  timeout: number;
  components: [CrossfadeComponent, CrossfadeComponent];
}

/** Based on https://www.robinwieruch.de/react-cross-fade-material-ui-mui/ */
export const Crossfade = ({ components, timeout }: CrossfadeProps) => {
  return (
    <Box width="100%" height="100%" position="relative">
      {components.map(({ key, in: inProp, component, wrapperProps }) => (
        <Fade
          key={key}
          in={inProp}
          initial={false}
          transition={{ enter: { duration: timeout / 1000 }, exit: { duration: timeout / 1000 } }}
        >
          <Box position="absolute" top="0" left="0" width="100%" height="100%" {...wrapperProps}>
            {component}
          </Box>
        </Fade>
      ))}
    </Box>
  );
};
