import { intervalToDuration } from 'date-fns';

// Converts a number of seconds to hours and minutes
// If greater than 0 seconds but less than 60, will report as minimum 1 minute
export const toTimeSpentDuration = (seconds: number) => {
  if (seconds > 0 && seconds < 60) {
    seconds = 60;
  }
  const { hours, minutes } = intervalToDuration({ start: 0, end: seconds * 1000 });
  return `${hours}h ${minutes}m`;
};
