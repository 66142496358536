import { useQuery } from '@tanstack/react-query';
import { getCMSURL } from 'api';
import { isPast, parseJSON } from 'date-fns';
import { ScienceWhatsNew } from 'utils/strapi-types/whats-new';

import { Options } from './school';
import { useUserType } from './sessions';

const maxPosts = 10;
const buildDate: string | null = import.meta.env.VITE_RELEASE_TIMESTAMP || null;

const isAtRequiredVersion = (post: ScienceWhatsNew): boolean => {
  if (!post.attributes.RequiredClientReleaseTimestamp || !buildDate) {
    return true;
  }
  return post.attributes.RequiredClientReleaseTimestamp <= buildDate;
};

const isPastPublishTime = (post: ScienceWhatsNew): boolean => {
  const pDate = parseJSON(post.attributes.publishedAt);
  return isPast(pDate);
};

const useWhatsNewQuery = () => {
  const { isSparxStaff } = useUserType();

  return {
    queryKey: ['whatsnew', isSparxStaff],
    queryFn: async () => {
      const base = getCMSURL() + '/science-whats-news';

      const searchParams = new URLSearchParams();
      searchParams.set('sort', 'publishedAt:desc');
      searchParams.set('pagination[limit]', maxPosts.toString());
      searchParams.set('pagination[withCount]', 'false');
      if (!isSparxStaff) {
        searchParams.append('filters[SparxStaffOnly][$eq]', 'false');
      }

      // https://docs.strapi.io/dev-docs/api/rest/populate-select#population
      searchParams.set('populate[PromotedContent][populate]', '*');
      searchParams.set('populate[Image][populate][0]', 'data');
      searchParams.set('populate[Buttons][populate]', '*');
      searchParams.set('populate[Video][populate]', '*');

      const url = base + '?' + searchParams.toString();

      const fetchResp = await fetch(url, {
        method: 'GET',
        mode: 'cors',
      });
      if (!fetchResp.ok) {
        throw new Error('Failed to fetch whats new posts' + fetchResp.statusText);
      }
      const j = await fetchResp.json();

      // Replace the pubilshedAt time as needed
      const posts = (j.data as ScienceWhatsNew[])
        .map<ScienceWhatsNew>(post => ({
          id: post.id,
          spxID: post.attributes.Title.replace(/\W/g, '_'),
          attributes: {
            ...post.attributes,
            publishedAt:
              post.attributes.PublishTimeOverride ||
              post.attributes.publishedAt ||
              new Date().toJSON(),
          },
        }))
        .filter(post => {
          // filter out posts that require a client version newer than the current build
          if (!post.attributes.RequiredClientReleaseTimestamp || !buildDate) {
            return true;
          }
          return post.attributes.RequiredClientReleaseTimestamp <= buildDate;
        })
        .filter(post => {
          // filter out posts that require a newer client version or are published in the future
          return isAtRequiredVersion(post) && isPastPublishTime(post);
        });
      posts.sort((a, b) =>
        a.attributes.publishedAt === b.attributes.publishedAt
          ? 0
          : a.attributes.publishedAt > b.attributes.publishedAt
            ? -1
            : 1,
      );

      return posts;
    },
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnWindowFocus: isSparxStaff,
    suspense: false,
  };
};

export const useWhatsNewPosts = (options: Options) =>
  useQuery({
    ...useWhatsNewQuery(),
    ...options,
  });

export const useWhatsNewPromotedPost = () =>
  useQuery({
    ...useWhatsNewQuery(),
    select: data => data.find(post => !!post.attributes.PromotedContent) || null,
  });
