import {
  Box,
  Button,
  Checkbox,
  HStack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  faEyeSlash,
  faHand,
  faHourglass,
  faPause,
  faUsersRectangle,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  JoinState,
  LessonState,
  StudentActiveState,
  StudentState,
} from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { StudentStateWithHandupIndex, useClearHandsUp } from 'api/lessons';
import { useStudents } from 'api/school';
import { StudentName } from 'components/Names';
import { differenceInSeconds, max } from 'date-fns';
import { AnimatePresence } from 'framer-motion';
import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { whiteboardActiveForUser } from 'utils/whiteboards';
import {
  LessonActivityPositionWrapper,
  LessonActivityTableWrapper,
  SortableHeaderCell,
  useActivityTableContext,
  useTableSort,
} from 'views/lessons/activities/ActivityTable';
import { useLessonViewContext } from 'views/lessons/LessonView';
import { ActivityPanel } from 'views/lessons/panel/ActivityPanel';
import { AssignmentTaskItemPanel } from 'views/lessons/panel/AssignmentTaskItemPanel';
import { SurveyTaskItemPanel } from 'views/lessons/panel/SurveyTaskItemPanel';
import { isStudentPaused } from 'views/lessons/utils';

interface StudentActivityProps {
  lessonID: string;
  studentGroupNames?: string[];
}

export interface StudentAndState {
  studentId: string;
  state?: StudentStateWithHandupIndex;
  student?: Student;
}

export const StudentActivity = ({ lessonID, studentGroupNames }: StudentActivityProps) => {
  const { panel, setPanel, selectedActivities, lessonState, studentStates } =
    useLessonViewContext();

  const { data: students = [] } = useStudents({ suspense: true });
  const studentLookup = useMemo(() => new Map(students.map(s => [s.studentId, s])), [students]);

  // Find the additional students that have not yet signed in
  const additionalStudents = useMemo(() => {
    const additionalStudents = new Set<string>();
    for (const group of studentGroupNames || []) {
      const groupId = group.split('/')[3];
      for (const student of students) {
        if (student.studentGroupIds.includes(groupId)) {
          additionalStudents.add(student.studentId);
        }
      }
    }
    return additionalStudents;
  }, [studentGroupNames, students]);

  const activityOrder = useMemo(
    () => lessonState?.activities.map(a => a.lessonActivityId) || [],
    [lessonState],
  );

  // Combine the logged in students and the additional ones
  const combinedStudents = useMemo(() => {
    const seen = new Set<string>();
    const output: StudentAndState[] = [];
    for (const state of studentStates) {
      const student = studentLookup.get(state.studentId);
      output.push({ studentId: state.studentId, student, state });
      seen.add(state.studentId);
    }
    for (const add of additionalStudents) {
      if (seen.has(add)) continue; // already added
      const student = studentLookup.get(add);
      if (student) {
        output.push({ studentId: add, student });
      }
    }

    return output;
  }, [studentStates, additionalStudents, activityOrder, selectedActivities]);

  let table = <StudentTable students={combinedStudents} />;

  let startPosition = false;
  let current: string[] = [];
  for (const a of activityOrder) {
    if (selectedActivities.has(a)) {
      if (current.length > 0) {
        table = (
          <LessonActivityPositionWrapper ids={current} typ={startPosition ? 'current' : 'before'}>
            {table}
          </LessonActivityPositionWrapper>
        );
        current = [];
      }
      startPosition = true;

      const activity = lessonState?.activities.find(act => act.lessonActivityId === a);
      if (activity) {
        table = (
          <LessonActivityTableWrapper key={a} activity={activity} lessonID={lessonID}>
            {table}
          </LessonActivityTableWrapper>
        );
      }
    } else {
      current.push(a);
    }
  }
  if (current.length > 0) {
    table = (
      <LessonActivityPositionWrapper ids={current} typ={'after'}>
        {table}
      </LessonActivityPositionWrapper>
    );
  }

  return (
    <Box
      display="flex"
      position="absolute"
      inset="0 0 0 0"
      alignItems="flex-start"
      mb={4}
      overflow="hidden"
    >
      {table}
      <AnimatePresence>
        {panel?.type === 'taskitem' && (
          <ActivityPanel
            selectedTaskItem={panel.taskItem}
            selectedTaskIndex={panel.taskIndex}
            studentId={panel.studentId}
            assignmentId={panel.assignmentId}
            onClose={() => setPanel(undefined)}
          />
        )}
        {panel?.type === 'assignmenttaskitem' && (
          <AssignmentTaskItemPanel
            assignmentId={panel.assignmentId}
            taskIndex={panel.taskIndex}
            taskItemIndex={panel.taskItemIndex}
            onClose={() => setPanel(undefined)}
          />
        )}
        {panel?.type === 'surveytaskitem' && (
          <SurveyTaskItemPanel
            assignmentId={panel.assignmentId}
            taskIndex={panel.taskIndex}
            taskItemIndex={panel.taskItemIndex}
            onClose={() => setPanel(undefined)}
          />
        )}
      </AnimatePresence>
    </Box>
  );
};

interface StudentTableProps {
  students: StudentAndState[];
}

const initialSortFunc = (a: StudentAndState, b: StudentAndState) => {
  const { active: aActive } = isStudentActive(a.state);
  const { active: bActive } = isStudentActive(b.state);
  if (aActive && !bActive) return -1;
  if (!aActive && bActive) return 1;
  return 0;
};

const lastActivitySortFunc =
  (lessonState: LessonState) => (a: StudentAndState, b: StudentAndState) => {
    // Round to 10 seconds to reduce some of the jumpiness
    let aLastProgressed = Math.floor(getLastProgressedSeconds(a.state, lessonState) / 10) * 10;
    let bLastProgressed = Math.floor(getLastProgressedSeconds(b.state, lessonState) / 10) * 10;

    // If the times are less than the min number of minutes then group them together
    if (aLastProgressed < minMinutesToShow * 60) aLastProgressed = 0;
    if (bLastProgressed < minMinutesToShow * 60) bLastProgressed = 0;

    return bLastProgressed - aLastProgressed;
  };

const fallbackSortFunc = (a: StudentAndState, b: StudentAndState) => {
  return (
    a.student?.familyName.localeCompare(b.student?.familyName || '') ||
    a.student?.givenName.localeCompare(b.student?.givenName || '') ||
    a.student?.studentId.localeCompare(b.student?.studentId || '') ||
    0
  );
};

type StudentSortFunc = (a: StudentAndState, b: StudentAndState) => number;
const chainSorts =
  (...sorts: (StudentSortFunc | undefined)[]): StudentSortFunc =>
  (a, b) => {
    for (const sort of sorts) {
      if (sort) {
        const result = sort(a, b);
        if (result !== 0) return result;
      }
    }
    return 0;
  };

const flippedSort =
  (sort: StudentSortFunc, flip: boolean): StudentSortFunc =>
  (a, b) =>
    flip ? sort(b, a) : sort(a, b);

const StudentTable = ({ students }: StudentTableProps) => {
  const { teamTeaching, lessonState } = useLessonViewContext();
  const { heading, row, sortFunc } = useActivityTableContext();
  const { sortColumn, flipSort } = useTableSort();
  const containerRef = useRef<HTMLElement>(null);

  const sortedStudents = useMemo(() => {
    let sort = chainSorts(initialSortFunc, sortFunc, fallbackSortFunc);
    if (sortColumn === 'student') {
      sort = flippedSort(fallbackSortFunc, flipSort);
    } else if (sortColumn === 'status' || !sortColumn) {
      sort = chainSorts(
        initialSortFunc,
        flippedSort(lastActivitySortFunc(lessonState), flipSort),
        fallbackSortFunc,
      );
    }
    return [...students].sort(sort);
  }, [lessonState, sortColumn, students, flipSort, sortFunc]);

  return (
    <TableContainer
      boxShadow="md"
      borderRadius="md"
      borderWidth={1}
      overflowY="auto"
      flex={1}
      mx={4}
      overflow="hidden"
      maxHeight="100%"
      style={{ overflowX: 'auto' }}
      as={ScrollContainer}
      hideScrollbars={false}
      innerRef={containerRef}
    >
      <Table size="sm">
        <Thead position="sticky" top={0} zIndex="docked">
          <Tr bg="white" h={8}>
            <Th bg="white" zIndex={2} position="sticky" left={0} />
            {teamTeaching && <Th bg="white" zIndex={2}></Th>}
            <SortableHeaderCell
              width="150px"
              left="52px"
              minWidth="150px"
              position="sticky"
              zIndex={2}
              sortName="student"
            >
              Student
            </SortableHeaderCell>
            <SortableHeaderCell
              width={120}
              position="sticky"
              left="202px"
              zIndex={2}
              sortName="status"
            >
              Status
            </SortableHeaderCell>
            {heading?.()}
          </Tr>
        </Thead>
        <Tbody>
          {sortedStudents.map(({ studentId, student, state }, i) => (
            <StudentRow
              key={studentId || i}
              state={state}
              student={student}
              showHandsUp={teamTeaching}
            >
              {row?.(state, student)}
            </StudentRow>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

interface StudentRowProps {
  state?: StudentStateWithHandupIndex;
  student?: Student;
  showHandsUp?: boolean;
}

const StudentRow = ({
  state,
  student,
  children,
  showHandsUp,
}: PropsWithChildren<StudentRowProps>) => {
  const studentId = student?.studentId || state?.studentId || '';
  const { lessonState, selectedStudents, toggleSelectedStudent, onClickStudent } =
    useLessonViewContext();
  const selected = selectedStudents.has(studentId);
  const paused = isStudentPaused(lessonState, studentId);

  const whiteboardState = lessonState.conferenceState?.whiteboardState;
  const { active: whiteboardActive, selected: whiteboardSelected } = whiteboardActiveForUser(
    whiteboardState,
    studentId,
  );

  const { active, notJoined } = useUpdatingStudentActive(state);

  const handIsUp = Boolean(state?.handUpTime && active);
  const inOneToOne =
    lessonState.conferenceState?.studentCall?.studentName === `students/${studentId}`;

  let bg = 'gray.50';
  if (active) {
    if (handIsUp) bg = 'orange.50';
    else if (paused) bg = 'orange.50';
    else if (inOneToOne) bg = 'blue.50';
    else bg = 'white';
  }

  return (
    <Tr
      sx={{
        '& > td': {
          paddingTop: '0.3rem',
          paddingBottom: '0.3rem',
        },
      }}
      bg={bg}
      transition="background 0.5s"
      color={active ? 'gray.900' : 'gray.500'}
    >
      <Td bg={bg} position="sticky" left={0} width="52px" minWidth="52px" zIndex={3}>
        <Checkbox
          size="lg"
          px={4}
          py={2}
          mx={-4}
          my={-2}
          isChecked={selected}
          onChange={e => toggleSelectedStudent(studentId, e.target.checked)}
        />
        {whiteboardSelected && <WhiteboardStudentDot />}
      </Td>
      {showHandsUp && (
        <Td bg={bg} width="20px" minWidth="20px" px={0} textAlign="center">
          {inOneToOne ? (
            <Text px={2} color="blue.600">
              <FontAwesomeIcon icon={faVideo} />
            </Text>
          ) : whiteboardActive ? (
            <Text px={2} color="teal.600">
              <FontAwesomeIcon icon={faUsersRectangle} fade={true} />
            </Text>
          ) : (
            <HandUp
              active={handIsUp}
              index={state?.handUpIndex}
              studentName={`students/${state?.studentId}`}
            />
          )}
        </Td>
      )}
      <Td bg={bg} position="sticky" width="150px" minWidth="150px" left="52px" zIndex={3}>
        {onClickStudent ? (
          <Text
            onClick={() => onClickStudent(studentId)}
            cursor="pointer"
            _hover={{ opacity: 0.8 }}
          >
            <StudentName student={student} />
          </Text>
        ) : (
          <>
            <StudentName student={student} />
          </>
        )}
      </Td>
      <Td bg={bg} position="sticky" left="202px" zIndex={3}>
        <HStack spacing={4}>
          <Box>
            {paused ? (
              <Text color="orange.600" fontSize="xl">
                <FontAwesomeIcon icon={faPause} width="15px" />
              </Text>
            ) : active ? (
              <LastProgressedTimer studentState={state} lessonState={lessonState} />
            ) : notJoined ? (
              <Tag colorScheme="white">Not joined</Tag>
            ) : (
              <Tag colorScheme="gray">Offline</Tag>
            )}
          </Box>
        </HStack>
      </Td>
      {children}
    </Tr>
  );
};

const minMinutesToShow = 3;

export const LastProgressedTimer = ({
  studentState,
  lessonState,
  extra,
}: {
  studentState: StudentState | undefined;
  lessonState: LessonState | undefined;
  extra?: (active?: boolean) => React.ReactNode;
}) => {
  const timeSeconds = useMemo(
    () => getLastProgressedSeconds(studentState, lessonState),
    [studentState, lessonState],
  );
  const timeMinutes = Math.floor(timeSeconds / 60);
  const active = studentState?.activeState === StudentActiveState.ACTIVE;
  const showTime = timeSeconds > 60 * minMinutesToShow;

  const color = !active && showTime ? 'red.600' : 'orange.600';
  return (
    <HStack spacing={2}>
      {(!active || showTime) && (
        <Text color={color} fontSize="md" my={-2}>
          <FontAwesomeIcon icon={active ? faHourglass : faEyeSlash} fixedWidth={true} />
        </Text>
      )}
      {showTime && (
        <Text color={color} fontSize="sm">
          {timeMinutes} min{timeMinutes > 1 && 's'}
        </Text>
      )}
      {extra?.(active)}
    </HStack>
  );
};

const getLastProgressedSeconds = (
  studentState: StudentState | undefined,
  lessonState: LessonState | undefined,
) => {
  const timestamps: Date[] = [];
  if (studentState?.lastProgressed) {
    timestamps.push(Timestamp.toDate(studentState.lastProgressed));
  }
  if (studentState?.joinTime) {
    timestamps.push(Timestamp.toDate(studentState.joinTime));
  }
  if (lessonState?.lastProgressTimestamp) {
    timestamps.push(Timestamp.toDate(lessonState.lastProgressTimestamp));
  }
  if (timestamps.length === 0) {
    return 0;
  }

  const maxTimestamp = max(timestamps);
  return differenceInSeconds(new Date(), maxTimestamp);
};

interface HandsUpProps {
  active: boolean;
  index?: number;
  studentName: string;
}

export const HandUp = ({ active, index, studentName }: HandsUpProps) => {
  const { lessonID } = useLessonViewContext();
  const { mutate, isLoading } = useClearHandsUp(lessonID);

  return (
    <Button
      variant="link"
      color="orange"
      opacity={active ? 1 : 0}
      visibility={active ? 'visible' : 'hidden'}
      transition={'all 0.5s ease-out'}
      cursor="pointer"
      minWidth={0}
      my={-2}
      py={2}
      px={0}
      _active={{ color: 'orange.500' }}
      isLoading={isLoading}
      onClick={() => mutate([studentName])}
    >
      <HandsUpIconWithNumber index={index} />
    </Button>
  );
};

export const HandsUpIconWithNumber = ({ index }: { index?: number }) => (
  <>
    <FontAwesomeIcon icon={faHand} bounce={true} />
    {index !== undefined && (
      <Text fontSize="xs" ml={1} display="inline-block">
        {index + 1}
      </Text>
    )}
  </>
);

const WhiteboardStudentDot = () => (
  <Box
    w={2}
    h={2}
    bg="teal.500"
    position="absolute"
    right={0}
    top="50%"
    mt={-1}
    borderRadius="50%"
  />
);

export const isStudentActive = (state: StudentState | undefined) => {
  if (!state || !state.lastSeen) return { active: false, notJoined: true };
  if (state.joinState !== JoinState.STUDENT_STATE_JOINED) return { active: false };

  const diff = differenceInSeconds(new Date(), Timestamp.toDate(state.lastSeen));
  if (diff > 30) return { active: false };

  return { active: true, lastSeen: diff };
};

export const useUpdatingStudentActive = (state: StudentState | undefined) => {
  // Ensure that the component is updating periodically
  const [date, setTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 5000);
    return () => clearInterval(interval);
  }, []);

  return useMemo(() => isStudentActive(state), [state, date]);
};
