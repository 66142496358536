import {
  Button,
  CircularProgress,
  Flex,
  Text,
} from '@chakra-ui/react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

import { useGettingStartedProgress } from '../hooks';
import { DelayedInfoPopover } from './InfoPopover';

export const GettingStartedHeader = () => {
  const { total, complete, loading } = useGettingStartedProgress();

  const allComplete = complete >= total && !loading;

  return (
    <DelayedInfoPopover
      dismissedStorageKey="sci/dismissedGettingStartedLinkHint"
      stateTriggerKey="fromGettingStarted"
      text="You can return to your getting started steps at any time by clicking here."
    >
      <Button
        as={Link}
        to="/teacher/getting-started"
        colorScheme="whiteAlpha"
        variant="ghost"
        color="white"
        size={['sm', 'md']}
        mr={2}
      >
        <Text as="span" mr={2}>
          Getting started
        </Text>
        <Flex opacity={loading ? 0 : 1} transition="opacity 0.25s">
          {allComplete ? (
            <Text as="span" color="green.400">
              <FontAwesomeIcon icon={faCheck} />
            </Text>
          ) : (
            <CircularProgress
              min={0}
              max={total}
              value={complete}
              color="green.400"
              size={'18px'}
              thickness={'15px'}
            />
          )}
          <Text as="span" ml={1} fontWeight="normal">
            {complete}/{total}
          </Text>
        </Flex>
      </Button>
    </DelayedInfoPopover>
  );
};
