import { Button, Flex, Text } from '@chakra-ui/react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Assignment } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { useSchoolYears, useWeeksForYear } from 'api/school';
import { useIsInRolloverInterim } from 'api/schoolcalendar';
import { format } from 'date-fns';
import * as React from 'react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Link, useNavigate } from 'react-router-dom';
import { useWeeksWithAssignments } from 'utils/data/assignments';

interface IWeekSelectorProps {
  assignments: Assignment[];
  selected?: string;
  assignmentLink: (name: string) => string;
}

export const WeekSelector = ({ assignments, selected, assignmentLink }: IWeekSelectorProps) => {
  // In interim, we want to show the data from the previous year
  const isInRolloverInterim = useIsInRolloverInterim();
  const { data: schoolYears } = useSchoolYears({ enabled: isInRolloverInterim, suspense: true });
  const previousYear = schoolYears?.find(y => y.previous);
  const yearIndex = (isInRolloverInterim && previousYear?.name.split('/')[3]) || '0';
  const { data: weeks } = useWeeksForYear(yearIndex, { suspense: true });
  const weeksWithAssignments = useWeeksWithAssignments(weeks, assignments).filter(
    a => a.assignments.length > 0,
  );

  const containerRef = useRef<HTMLElement>(null);
  const scroll = (diff: number) => {
    const element = containerRef.current;
    if (element) {
      element.scroll({
        left: element.scrollLeft + diff,
        behavior: 'smooth',
      });
    }
  };

  // Scroll assignment into view
  const scrollIntoView = (name: string, instant?: boolean) => {
    const element = document.getElementById('week-scroller-' + name);
    if (element) {
      element.scrollIntoView({
        inline: instant ? 'center' : 'nearest',
        block: 'nearest',
        behavior: instant ? 'auto' : 'smooth',
      });
    }
  };

  const scrollLeft = () => scroll(-500);
  const scrollRight = () => scroll(500);

  // Behaviour to ensure the initial week is scrolled to
  const [initialScroll, setInitialScroll] = useState(false);
  useEffect(() => {
    if (!initialScroll && selected && weeksWithAssignments.length > 0) {
      scrollIntoView(selected, true);
      setInitialScroll(true);
    }
  }, [selected, weeksWithAssignments, initialScroll, setInitialScroll]);

  // Behaviour to select the latest week if none is selected
  const navigate = useNavigate();
  useEffect(() => {
    if (!selected && weeksWithAssignments.length > 0) {
      // Select the latest set assignment
      const latest = weeksWithAssignments[weeksWithAssignments.length - 1].assignments[0]?.name;
      if (latest) navigate(assignmentLink(latest), { replace: true });
    }
  }, [navigate, assignmentLink, selected, weeksWithAssignments, navigate]);

  const height = 62;
  return (
    <Flex
      backgroundColor="white"
      boxShadow="elevationLow"
      borderRadius="md"
      align="center"
      overflow="hidden"
    >
      <Button
        onClick={scrollLeft}
        height={height}
        borderRadius={0}
        borderRightColor="gray.200"
        borderRightWidth={1}
        zIndex={2}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </Button>
      <Flex
        flex={1}
        align="stretch"
        as={ScrollContainer}
        hideScrollbars={true}
        innerRef={containerRef}
      >
        {weeksWithAssignments
          .map(a => {
            const weekSelected = a.assignments.find(a => a.name === selected);

            let bonusHomework: ReactNode[] = [];
            if (a.assignments.length > 1) {
              bonusHomework = a.assignments.map((assignment, i) => {
                const selectedAssignment = assignment.name === selected;
                return (
                  <Button
                    as={Link}
                    to={assignmentLink(assignment.name)}
                    key={assignment.name}
                    height={height}
                    borderRadius={0}
                    borderColor="gray.200"
                    borderLeftWidth={1}
                    borderRightWidth={1}
                    marginLeft="-1px"
                    colorScheme={selectedAssignment ? 'blue' : 'gray'}
                    backgroundColor={selectedAssignment ? 'blue.800' : 'gray.50'}
                    _hover={selectedAssignment ? { bg: 'blue.900' } : undefined}
                    color={selectedAssignment ? 'white' : 'gray.900'}
                    id={`week-scroller-${assignment.name}`}
                  >
                    {i + 1}
                  </Button>
                );
              });
            }

            return [
              <Button
                as={Link}
                to={assignmentLink(a.assignments[0].name)}
                key={a.week.index}
                colorScheme={weekSelected ? 'blue' : 'gray'}
                backgroundColor={weekSelected ? 'blue.800' : 'white'}
                color={weekSelected ? 'white' : 'gray.900'}
                _hover={weekSelected ? { bg: 'blue.900' } : undefined}
                borderRadius={0}
                onClick={() => scrollIntoView(a.assignments[0].name)}
                id={`week-scroller-${a.assignments[0].name}`}
                width={36}
                height={height}
                display="flex"
                flexDir="column"
                justifyContent="center"
                alignItems="center"
                borderColor="gray.200"
                borderLeftWidth={1}
                borderRightWidth={1}
                marginLeft="-1px"
                flexShrink={0}
                textAlign="center"
              >
                <Text fontSize="lg">Week {a.week.index}</Text>
                <Text
                  fontSize="sm"
                  fontWeight="normal"
                  color={weekSelected ? 'gray.300' : 'gray.500'}
                  mb={-0.5}
                >
                  {format(a.week.startDate, 'dd/MM/yyyy')}
                </Text>
              </Button>,
              ...bonusHomework,
            ];
          })
          .flat()}
      </Flex>
      <Button
        onClick={scrollRight}
        height={height}
        borderRadius={0}
        borderLeftColor="gray.200"
        borderLeftWidth={1}
        zIndex={2}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </Flex>
  );
};
