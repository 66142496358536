import * as RTooltip from '@radix-ui/react-tooltip';
import { PropsWithChildren, ReactNode } from 'react';

import styles from './Tooltip.module.css';

export type TooltipProps = {
  content: string | string[] | ReactNode;
  position: RTooltip.TooltipContentProps['side'];

  title?: string;
  hoverDelay?: RTooltip.TooltipProps['delayDuration'];
  offset?: RTooltip.TooltipContentProps['sideOffset'];
  onOpenChange?: (open: boolean) => void;
  disabled?: boolean;
};

export const Tooltip = ({
  content,
  position,
  offset = 5,
  hoverDelay = 300,
  title,
  onOpenChange,
  disabled,
  children,
}: PropsWithChildren<TooltipProps>) => {
  return (
    <RTooltip.Provider>
      <RTooltip.Root
        onOpenChange={o => {
          if (!disabled) {
            onOpenChange?.(o);
          }
        }}
        delayDuration={hoverDelay}
      >
        <RTooltip.Trigger
          asChild
          onClick={e => {
            if (!disabled) {
              e.preventDefault();
            }
          }}
        >
          {children}
        </RTooltip.Trigger>
        {!disabled && (
          <RTooltip.Portal>
            <RTooltip.Content
              onPointerDownOutside={e => e.preventDefault()}
              side={position}
              className={styles.TooltipContent}
              sideOffset={offset}
              collisionPadding={offset}
            >
              {title && <p className={styles.Title}>{title}</p>}
              {Array.isArray(content) ? (
                content.map((c, i) => <p key={i}>{c}</p>)
              ) : (
                <p>{content}</p>
              )}
              <RTooltip.Arrow className={styles.TooltipArrow} width={15} height={10} />
            </RTooltip.Content>
          </RTooltip.Portal>
        )}
      </RTooltip.Root>
    </RTooltip.Provider>
  );
};
