import { Flex } from '@chakra-ui/react';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import * as Sentry from '@sentry/react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { NotFound } from 'components/errorpages/NotFound';
import { UnknownError } from 'components/errorpages/UnknownError';
import { LargeLoading } from 'components/loading/LargeLoading';
import React, { PropsWithChildren } from 'react';

export const SuspenseRoute = ({
  children,
  verticalCenter,
}: PropsWithChildren<{ verticalCenter?: boolean }>) => (
  <React.Suspense
    fallback={
      verticalCenter ? (
        <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
          <LargeLoading />
        </Flex>
      ) : (
        <LargeLoading />
      )
    }
  >
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <Sentry.ErrorBoundary
          onReset={reset}
          fallback={({ resetError, error }) => <ErrorHandler error={error} reset={resetError} />}
        >
          {children}
        </Sentry.ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  </React.Suspense>
);

const ErrorHandler = ({ error, reset }: { error: Error; reset: () => void }) => {
  const rpcError = error instanceof RpcError ? error : undefined;
  if (rpcError?.code === 'NOT_FOUND' || rpcError?.code === 'INVALID_ARGUMENT') {
    return <NotFound />;
  }
  if (
    rpcError?.code === 'PERMISSION_DENIED' ||
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed.')
  ) {
    // For permission denied errors make reset refresh the page, as this should
    // force us to get a new token too
    // Also force refresh for module import errors as that should fetch the latest index file
    reset = () => location.reload();
  }
  return <UnknownError reset={reset} />;
};
