import { SupportLink } from '@sparx/mis-sync-import/src/MisSyncImport/components/common';
import { WarningMessage } from '@sparx/sparx-design/components';

import styles from './TroubleshootingMessage.module.css';

export const TroubleshootingMessage = () => {
  const message = (
    <div className={styles.Container}>
      <strong>
        We can&apos;t currently access data about classes in your school&apos;s MIS. This may be
        because:
      </strong>
      <p className={styles.Heading}>
        We don&apos;t yet have authorisation to access data in your school&apos;s MIS:
      </p>
      <p>
        If your school is very new to Sparx, it&apos;s likely that our integration partner, Wonde,
        are working with your school&apos;s network or MIS manager to authorise our access to your
        data. Once we receive authorisation, you&apos;ll see your classes listed on this page.
      </p>

      <p className={styles.Heading}>
        Our authorisation to access data in your school&apos;s MIS has been revoked:
      </p>
      <p>
        If you were previously able to see classes listed on this page, it&apos;s likely that
        someone acting on behalf of your school has removed our authorisation to access data in your
        school&apos;s MIS.
      </p>
      <p>
        If you&apos;re not expecting this, we recommend that you contact your school&apos;s network
        or MIS manager to see whether they can provide more information.
      </p>

      <p className={styles.TopSpace}>
        If you don&apos;t think that either of the above scenarios apply to your school, please
        contact <SupportLink linkText="Sparx Support" />.
      </p>
    </div>
  );
  return <WarningMessage message={message} iconPosition="top" className={styles.WarningMessage} />;
};
