import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  HStack,
  Link as ChakraLink,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Assignment } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { usePackageSummaries } from 'api/packages';
import { useCurrentSchoolYear, useStudentsLookup, Week } from 'api/school';
import { useUserType } from 'api/sessions';
import { CancelledWarning, useCancelledStudents } from 'components/CancelledUtils';
import { CopyableResourceName } from 'components/copyable/Copyable';
import { LargeLoading } from 'components/loading/LargeLoading';
import { StudentName } from 'components/Names';
import { PrettyTimestamp } from 'components/timestamp/PrettyTimestamp';
import { InlineTextTooltip } from 'components/tooltip/Tooltip';
import { addDays, isPast, max, min } from 'date-fns';
import React, { PropsWithChildren, useState } from 'react';
import { DeepPartial, safeMerge } from 'utils/deeppatch';
import { articleCancellingHomework } from 'utils/knowledgeBaseArticles';
import { plural } from 'utils/plural';
import { DatePicker, isDateValid } from 'views/planner/components/DatePicker';
import { AddTopics, HomeworkTopics } from 'views/planner/components/Topics';
import { usePlannerContext } from 'views/planner/Context';

import { AssignmentCancelDialog } from './AssignmentCancelDialog';
import { AssignmentContentsSetting } from './AssignmentContentsSetting';
import { GeneratedAssignmentHandInChangeConfirmationModal } from './GeneratedAssignmentHandInChangeConfirmationModal';

interface AssignmentContentsEditorProps {
  week: Week;
  assignment: Assignment;
  updateAssignment: (patch: Assignment) => void;
}

// The maximum number of days that homework handout can be set to (after the end of the homework week)
const maxHomeworkLengthDays = 7 * 7;

export const AssignmentContentsEditor = ({
  week,
  assignment,
  updateAssignment: _updateAssignment,
}: AssignmentContentsEditorProps) => {
  const { jamieMode } = useUserType();
  const toast = useToast();
  const { data: schoolYear } = useCurrentSchoolYear({ suspense: true });
  const startDate = Timestamp.toDate(assignment.startTimestamp!);
  const endDate = Timestamp.toDate(assignment.endTimestamp!);

  const yearEnd = schoolYear?.end || week.endDate;

  const [confirmNewEndDate, setConfirmNewEndDate] = useState<Date | undefined>();

  const isSet = Boolean(assignment.generatedTimestamp);

  const generatedAssignment =
    assignment.spec?.contents.oneofKind === 'generatedAssignment'
      ? assignment.spec.contents.generatedAssignment
      : undefined;
  const [topicScreen, setTopicScreen] = useState<'view' | 'select'>('view');

  const updateAssignment = (patch: DeepPartial<Assignment>) =>
    _updateAssignment(
      safeMerge(assignment, patch, {
        name: patch.name || assignment.name,
      }),
    );

  const setHomeworkTopics = (topics: { name: string }[]) =>
    updateAssignment({
      spec: {
        contents: {
          oneofKind: 'generatedAssignment',
          generatedAssignment: { topics },
        },
      },
    });

  const setHomeworkLength = (lengthMinutes: number) =>
    updateAssignment({
      spec: {
        contents: {
          oneofKind: 'generatedAssignment',
          generatedAssignment: { lengthMinutes },
        },
      },
    });

  let TopicComponent = HomeworkTopics;
  if (topicScreen === 'select' && !assignment.generatedTimestamp) {
    TopicComponent = AddTopics;
  }

  const { doAssignmentAction } = usePlannerContext();
  const deleteHomework = () => doAssignmentAction({ ...assignment, action: 'delete' });

  // Min and max dates, these are functions so whenever they are used they will use the current time if required
  const minHandInDate = () => max([addDays(startDate, 1), new Date()]);
  const maxHandInDate = () => addDays(week.endDate, maxHomeworkLengthDays); // 7 Weeks from the end of the week
  const minHandOutDate = () => max([new Date(), week.startDate]);
  const maxHandOutDate = () => min([addDays(week.endDate, -1), addDays(endDate, -1), yearEnd]);

  return (
    <VStack spacing={4}>
      {isSet && <AssignmentStatusHeading assignment={assignment} />}
      <AssignmentControlContainer
        homeworkValue="on"
        isDisabled={isSet}
        onToggleHomework={val => val === 'off' && deleteHomework()}
      >
        <DatePicker
          name="Hand out"
          date={startDate}
          minDate={minHandOutDate()}
          maxDate={maxHandOutDate()}
          setDate={d => {
            if (isDateValid(d, minHandOutDate(), maxHandOutDate())) {
              updateAssignment({ startTimestamp: Timestamp.fromDate(d) });
            } else {
              toast({
                title: 'Error',
                description: 'The selected hand out date was invalid, please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'bottom-left',
              });
            }
          }}
          readonly={isSet}
        />
        <Box w={6} />
        <DatePicker
          name="Hand in"
          date={endDate}
          minDate={minHandInDate()}
          maxDate={maxHandInDate()}
          setDate={d => {
            if (isDateValid(d, minHandInDate(), maxHandInDate())) {
              if (isSet) {
                setConfirmNewEndDate(d);
              } else {
                updateAssignment({ endTimestamp: Timestamp.fromDate(d) });
              }
            } else {
              toast({
                title: 'Error',
                description: 'The selected hand in date was invalid, please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'bottom-left',
              });
            }
          }}
          readonly={
            isSet &&
            (isPast(addDays(week.endDate, maxHomeworkLengthDays)) || !!assignment.cancelledTime)
          }
        />
        <Spacer />
        {isSet && confirmNewEndDate && (
          <GeneratedAssignmentHandInChangeConfirmationModal
            assignment={assignment}
            newDate={confirmNewEndDate}
            onClose={() => setConfirmNewEndDate(undefined)}
          />
        )}
        {generatedAssignment && (
          <AssignmentContentsSetting title="Homework length">
            <Select
              size="sm"
              value={generatedAssignment?.lengthMinutes || 60}
              onChange={e => setHomeworkLength(parseInt(e.target.value))}
              isDisabled={isSet}
            >
              {getHomeworkLengthOptions(generatedAssignment.lengthMinutes)}
            </Select>
          </AssignmentContentsSetting>
        )}
      </AssignmentControlContainer>
      <Flex
        direction="column"
        backgroundColor="white"
        p={3}
        borderRadius="md"
        boxShadow="elevationLow"
        width="100%"
      >
        <React.Suspense fallback={<LargeLoading />}>
          {assignment.spec?.contents.oneofKind === 'generatedAssignment' ? (
            <TopicComponent
              week={week}
              setTopicScreen={setTopicScreen}
              assignment={assignment}
              generatedAssignment={assignment.spec.contents.generatedAssignment}
              setTopics={setHomeworkTopics}
              generated={Boolean(assignment.generatedTimestamp)}
            />
          ) : (
            <Text>This is a custom homework and cannot be edited.</Text>
          )}
        </React.Suspense>
      </Flex>
      {jamieMode && <CopyableResourceName value={assignment.name} />}
    </VStack>
  );
};

const homeworkLengthOptions = [20, 30, 40, 50, 60, 70, 80, 90];

export const getHomeworkLengthOptions = (current: number | undefined) => (
  <>
    {!homeworkLengthOptions.includes(current || 60) && (
      <option value={current} disabled={true}>
        {current} minutes
      </option>
    )}
    {homeworkLengthOptions.map(option => (
      <option key={option} value={option}>
        {option} minutes
      </option>
    ))}
  </>
);

export const AssignmentControlContainer = ({
  children,
  isDisabled,
  homeworkValue,
  onToggleHomework,
}: PropsWithChildren<{
  homeworkValue: string;
  isDisabled?: boolean;
  onToggleHomework: (value: string) => void;
}>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toggle = (value: string) => {
    if (value === 'off') {
      onOpen();
    } else {
      onToggleHomework(value);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Turn off homework?</ModalHeader>
          <ModalBody>Would you like to turn off homework for this week?</ModalBody>
          <ModalFooter>
            <HStack spacing={2}>
              <Button colorScheme="buttonTeal" variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="buttonTeal"
                type="submit"
                onClick={() => {
                  onClose();
                  onToggleHomework('off');
                }}
              >
                Turn off homework
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex backgroundColor="white" p={3} borderRadius="md" boxShadow="elevationLow" width="100%">
        {children || <Spacer />}
        <Box w={3} />
        <AssignmentContentsSetting title="Homework type">
          <Select
            size="sm"
            value={homeworkValue}
            isDisabled={isDisabled}
            onChange={e => toggle(e.target.value)}
          >
            <option value="on">On</option>
            <option value="off">Off</option>
          </Select>
        </AssignmentContentsSetting>
      </Flex>
    </>
  );
};

const AssignmentStatusHeading = ({ assignment }: { assignment: Assignment }) => {
  const isCancelled = Boolean(assignment.cancelledTime);
  const { data: summaries, isSuccess } = usePackageSummaries(assignment.name.split('/')[1], {
    suspense: false,
    enabled: !isCancelled,
  });
  const { data: studentsMap = {} } = useStudentsLookup({
    enabled: !isCancelled,
    suspense: true,
  });

  const students = useCancelledStudents(studentsMap, summaries);

  if (isCancelled) {
    return (
      <CancelledWarning>
        <Text>
          This homework was originally set on{' '}
          <strong>
            <PrettyTimestamp>{assignment.generatedTimestamp}</PrettyTimestamp>
          </strong>{' '}
          but was then <strong>cancelled.</strong>
          <ChakraLink
            textDecoration="underline"
            href={articleCancellingHomework}
            target="_blank"
            ml={2}
          >
            More info
          </ChakraLink>
        </Text>
      </CancelledWarning>
    );
  }

  return (
    <Alert
      status={isSuccess ? (students.length > 0 ? 'warning' : 'success') : 'loading'}
      borderRadius="md"
      boxShadow="elevationLow"
      justifyContent="space-between"
    >
      <Flex alignItems="center" justifyContent="center">
        <AlertIcon />
        <Box>
          <Text>
            This homework was set on{' '}
            <strong>
              <PrettyTimestamp>{assignment.generatedTimestamp}</PrettyTimestamp>
            </strong>
            .
          </Text>
          {students.length > 0 && (
            <Text>
              It has been{' '}
              <strong>
                cancelled for{' '}
                <InlineTextTooltip
                  text={`${students.length} ${plural(students.length, 'student')}`}
                >
                  <List>
                    {students.map((s, i) => (
                      <ListItem key={i}>
                        <StudentName student={s} />
                      </ListItem>
                    ))}
                  </List>
                </InlineTextTooltip>
              </strong>
              .
            </Text>
          )}
        </Box>
      </Flex>
      <AssignmentCancelDialog
        assignment={assignment}
        isDisabled={!isSuccess}
        summaries={summaries}
        studentsMap={studentsMap}
      />
    </Alert>
  );
};
