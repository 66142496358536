import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PackageSummary } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Assignment } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { getCompletionSummary } from '@sparx/packageactivity';
import { useAssignments } from 'api/planner';
import { dateInWeek, useWeeks, Week } from 'api/school';
import { Tooltip } from 'components/tooltip/Tooltip';
import { useEffect, useMemo, useRef } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Link } from 'react-router-dom';

export const StudentHomeworkHistoryTable = ({
  packages,
  studentID,
}: {
  packages: PackageSummary[] | undefined;
  studentID: string;
}) => {
  const { data: weeks } = useWeeks({ suspense: true });

  const { data: assignments } = useAssignments({ suspense: true });
  const assignmentsByName = useMemo(() => {
    const assignmentsByName: Record<string, Assignment> = {};
    for (const assignment of assignments || []) {
      assignmentsByName[assignment.name] = assignment;
    }
    return assignmentsByName;
  }, [assignments]);

  const packageByWeek = useMemo(() => {
    const packageByWeek: Record<number, PackageSummary[]> = {};
    for (const pkg of packages || []) {
      const assignment = assignmentsByName[pkg.assignmentName];
      if (!assignment || !assignment.startTimestamp) {
        continue;
      }
      const startDate = Timestamp.toDate(assignment.startTimestamp);
      const week = weeks?.find(w => dateInWeek(w, startDate));
      if (!week) {
        continue;
      }
      if (!packageByWeek[week.index]) {
        packageByWeek[week.index] = [];
      }
      packageByWeek[week.index].push(pkg);
    }
    return packageByWeek;
  }, [weeks, packages, assignmentsByName]);

  const currentWeekRef = useRef<HTMLTableCellElement | null>(null);
  useEffect(() => {
    if (currentWeekRef.current) {
      currentWeekRef.current?.scrollIntoView({ block: 'end', inline: 'center' });
    }
  }, [currentWeekRef]);

  const getWeekCell = (week: Week) => {
    const assignment = packageByWeek[week.index]?.[0]?.assignmentName.split('/')[1];
    return (
      <Th
        key={week.index}
        bg={week.current ? 'teal.500' : 'gray.50'}
        color={week.current ? 'gray.50' : 'gray.500'}
        ref={week.current ? currentWeekRef : undefined}
        position="relative"
        fontSize="sm"
        p={0}
        minWidth={12}
        h={12}
        textAlign="center"
      >
        {week.index}
        {assignment && (
          <Tooltip
            label={areAllCancelled(packageByWeek[week.index] || []) ? 'Homework cancelled' : ''}
          >
            <Box
              zIndex={2}
              as={Link}
              position="absolute"
              inset="0 -1px -1000px 0"
              to={`/teacher/handin/answers?assignment=${assignment}&user=${studentID}`}
              state={{ back: `/teacher/student/${studentID}/summary` }}
              _hover={{ bg: 'rgba(0, 0, 0, 0.05)' }}
            />
          </Tooltip>
        )}
      </Th>
    );
  };

  return (
    <Box
      overflowX="auto"
      overflowY="hidden"
      mt={5}
      as={ScrollContainer}
      vertical={false}
      hideScrollbars={false}
    >
      <Table>
        <Thead>
          <Tr>
            <Th position="sticky" left={0} bg="gray.50" textAlign="right" zIndex={2}>
              Week
            </Th>
            {weeks?.map(getWeekCell)}
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Th position="sticky" left={0} bg="gray.50" textAlign="right">
              Completion
            </Th>
            {weeks?.map(week => (
              <CompletionCell key={week.index} summaries={packageByWeek[week.index] || []} />
            ))}
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

const areAllCancelled = (summaries: PackageSummary[]) => {
  if (!summaries.length) return false;
  return summaries.every(s => !!s.cancelledTime);
};

const CompletionCell = ({ summaries }: { summaries: PackageSummary[] }) => {
  if (!summaries.length) {
    return <Td p={0} bg="white" borderLeftWidth={1} borderLeftColor="gray.100" />;
  }

  const notCancelled = summaries.filter(s => !s.cancelledTime);

  const complete = notCancelled.every(s => s.state?.completionTimestamp);
  const completion = Math.round(
    notCancelled
      .map(s => getCompletionSummary(s.state?.completion).percentages.C.roundedPercentage)
      .reduce((a, b) => a + b, 0) / notCancelled.length,
  );

  const allCancelled = notCancelled.length === 0;

  return (
    <Td
      bg="white"
      p={0}
      minWidth={12}
      h={12}
      textAlign="center"
      borderLeftWidth={1}
      borderLeftColor="gray.100"
    >
      {allCancelled ? (
        <Tooltip label="Homework cancelled">
          <Text fontSize="xl" color="red.600">
            <FontAwesomeIcon icon={faBan} size="sm" />
          </Text>
        </Tooltip>
      ) : complete ? (
        <Text fontSize="xl" color="green.500">
          <FontAwesomeIcon icon={faCheck} />
        </Text>
      ) : (
        <Text fontWeight="bold" color="red.600" fontSize="sm">
          {completion}%
        </Text>
      )}
    </Td>
  );
};
