import { Box, Tab, TabList, Tabs } from '@chakra-ui/react';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

export const SchoolSettingsView = () => (
  <SuspenseRoute>
    <SuspenseSchoolSettingsView />
  </SuspenseRoute>
);

const tabs = [{ name: 'Academic Year', path: '/teacher/school-settings/academic-year' }];

const SuspenseSchoolSettingsView = () => {
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(tabs.findIndex(t => t.path === location.pathname));
  useEffect(() => {
    const currentTab = tabs.findIndex(t => t.path === location.pathname);
    if (currentTab !== undefined) setCurrentTab(currentTab);
  }, [setCurrentTab, location]);

  return (
    <PageContainer>
      <PageTitle title="School Settings" />
      <Tabs index={currentTab} onChange={setCurrentTab} colorScheme="buttonTeal">
        <TabList>
          {tabs.map((tab, index) => (
            <Tab key={index} value={index} as={Link} to={tab.path}>
              {tab.name}
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <Box backgroundColor="white" p={8} width="100%">
        <SuspenseRoute>
          <Outlet />
        </SuspenseRoute>
      </Box>
    </PageContainer>
  );
};
