import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { AnnotationKeys } from '@sparx/schoolutils';
import { useHolidaysForSchoolYear } from 'api/school';
import { articleIdHolidayDates } from 'utils/knowledgeBaseArticles';
import { plural } from 'utils/plural';
import { HolidaysTable } from 'views/schoolsettings/AcademicYearView';

import { IsRolloverProps, PageTab, TabDetails } from '..';
import { ActionBox } from '../components/ActionBox';
import { useIsManualRolloverStepComplete, useYearToReference } from '../hooks';

const title = 'Add Holiday Dates';
const requiredHolidays = 3;

const Page = ({ isRollover }: IsRolloverProps) => {
  const { yearV3: schoolYear } = useYearToReference();
  const { data: holidays = [] } = useHolidaysForSchoolYear(
    schoolYear?.next ? schoolYear.name.split('/')[3] : '0',
    { suspense: true },
  );
  const rolloverHolidaysReviewed = useIsManualRolloverStepComplete(
    AnnotationKeys.RolloverStepReviewedHolidays,
  );

  return (
    <>
      <ActionBox
        heading={`Add or review existing Holiday dates for ${isRollover ? 'the new' : schoolYear?.next ? 'next' : 'this'} academic
          year`}
        complete={
          holidays.length >= requiredHolidays && (isRollover ? rolloverHolidaysReviewed : true)
        }
        my={4}
        faIcon={faCalendarAlt}
        button={{
          text: 'Manage Holidays',
          path: `/teacher/school-settings/academic-year${schoolYear?.next ? '?ay=next' : ''}`,
        }}
        knowledgeBaseArticleId={articleIdHolidayDates}
        checkbox={
          isRollover
            ? {
                annotation: AnnotationKeys.RolloverStepReviewedHolidays,
                disabled: holidays.length < requiredHolidays,
              }
            : undefined
        }
      >
        Holiday dates are shared with other Sparx systems so may have already been added
      </ActionBox>
      <HolidaysTable holidays={holidays} readOnly={true} />
    </>
  );
};

const useTabDetails = ({ isRollover }: IsRolloverProps): TabDetails => {
  const { yearV3: schoolYear } = useYearToReference();
  const { data: holidays = [], isSuccess } = useHolidaysForSchoolYear(
    schoolYear?.next ? schoolYear.name.split('/')[3] : '0',
    { suspense: false },
  );
  const rolloverHolidaysReviewed = useIsManualRolloverStepComplete(
    AnnotationKeys.RolloverStepReviewedHolidays,
  );

  const holidaysSet = holidays.length;
  const complete =
    holidaysSet >= requiredHolidays && (isRollover ? rolloverHolidaysReviewed : true);

  return {
    title,
    subtitle:
      isSuccess && holidaysSet === 0
        ? 'Not added yet'
        : `${holidaysSet} ${plural(holidaysSet, 'holiday')} added`,
    status: !isSuccess ? 'loading' : complete ? 'complete' : undefined,
    icon: faCalendarAlt,
  };
};

const HolidayDatesTab: PageTab = {
  detailsHook: useTabDetails,
  path: 'holidays',
  page: Page,
};
export default HolidayDatesTab;
