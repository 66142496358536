import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Package } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { Date as pbDate } from '@sparx/api/google/type/date';
import { usePackages } from 'api/packages';
import { FeatureFlag, useBooleanFlag, useSession, useUserType } from 'api/sessions';
import { useBackLink } from 'app/BackLink';
import { StudentNavigation } from 'app/StudentNavigation';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { addDays, isAfter, isBefore, isEqual } from 'date-fns';
import React, { useMemo } from 'react';
import { JoinLessonModal } from 'views/lessondelivery/JoinLessonModal';
import { DemoPackageCreator } from 'views/packagelist/DemoPackageCreator';
import { PackageRow } from 'views/packagelist/PackageRow';

export const PackageListView = () => {
  // Show a back button to the teacher interface if the user is a teacher
  const { isTeacher } = useUserType();
  useBackLink(isTeacher ? '/teacher' : undefined);
  return (
    <StudentNavigation>
      <HomeworkPackageList />
    </StudentNavigation>
  );
};

export const HomeworkPackageList = () => {
  const { isTeacher } = useUserType();
  const { data: packages } = usePackages({ suspense: true });

  // Ensure the packages are sorted before we group them
  useMemo(
    () => packages?.sort((a, b) => (b.endTimestamp?.seconds || 0) - (a.endTimestamp?.seconds || 0)),
    [packages],
  );

  const { homeworks, old, demo } = useGroupedPackages(packages);
  const lessonsEnabled = useBooleanFlag(FeatureFlag.Lessons);

  return (
    <PageContainer>
      <Box py={2}>
        <PageTitle title={isTeacher ? 'Demo Tasks' : 'My Homework'}>
          {lessonsEnabled && <JoinLessonModal />}
        </PageTitle>
      </Box>
      {isTeacher && <DemoPackageCreator pkgs={demo} />}
      <PackageList pkgs={homeworks.concat(demo)} name="active" />
      <Accordion allowToggle={true} pt={8}>
        <AccordionItem border="none">
          <AccordionButton fontSize="lg" borderRadius="md">
            <AccordionIcon />
            <Text ml={3}>Older homework ({old.length})</Text>
          </AccordionButton>
          <AccordionPanel px={0}>
            <PackageList pkgs={old} name="older" />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </PageContainer>
  );
};

export const useGroupedPackages = (packages?: Package[]) => {
  const { data: session } = useSession({ suspense: true });

  return useMemo(() => {
    const homeworks = packages?.filter(p => p.type === '') || [];
    const oldDate = addDays(new Date(), -7 * 6); // 6 weeks ago
    // Use the year start if we have it to move last years packages into old
    const yearStart = session?.schoolYearStartDate
      ? pbDate.toJsDate(session.schoolYearStartDate, 0, 0, 0, 0)
      : new Date(0);

    return {
      homeworks: homeworks.filter(p => {
        if (!p.endTimestamp || !p.startTimestamp) return false;
        const startDate = Timestamp.toDate(p.startTimestamp);
        const endDate = Timestamp.toDate(p.endTimestamp);

        return isAfter(endDate, oldDate) && isAfter(startDate, yearStart);
      }),
      old: homeworks.filter(p => {
        if (!p.endTimestamp || !p.startTimestamp) return false;
        const startDate = Timestamp.toDate(p.startTimestamp);
        const endDate = Timestamp.toDate(p.endTimestamp);
        return (
          isBefore(endDate, oldDate) || isEqual(endDate, oldDate) || isBefore(startDate, yearStart)
        );
      }),
      demo: packages?.filter(p => p.type.startsWith('demo:')) || [],
    };
  }, [packages, session?.schoolYearStartDate]);
};

interface PackageListProps {
  pkgs: Package[];
  name?: string;
}

const PackageList = ({ pkgs, name }: PackageListProps) => {
  if (pkgs.length === 0) {
    return (
      <Center py={4} display="flex" flexDirection="column" color="gray.600">
        <Text fontSize="xl" fontWeight="bold" pt={2} pb={1}>
          You have no {name} homework
        </Text>
      </Center>
    );
  }
  return (
    <VStack spacing={[2, 3]} align="stretch">
      {pkgs.map(pkg => (
        <PackageRow key={pkg.name} pkg={pkg} />
      ))}
    </VStack>
  );
};
