import { useEffect } from 'react';

// fullScreenSuported returns true if the browser supports fullscreen mode
export const fullScreenSuported = () => {
  const doc: any = document;
  return (
    doc.fullscreenEnabled ||
    doc.mozFullscreenEnabled ||
    doc.webkitFullscreenEnabled ||
    doc.msFullscreenEnabled
  );
};

// isFullScreen returns true if we are currently in fullscreen mode
export const isFullScreen = () => {
  const doc: any = document;
  return !!(
    doc.fullscreenElement ||
    doc.mozFullScreenElement ||
    doc.webkitFullscreenElement ||
    doc.msFullscreenElement
  );
};

const requestFullscreen = () => {
  const elem: any = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  }
};

const exitFullscreen = () => {
  const doc: any = document;
  if (doc.exitFullscreen) {
    doc.exitFullscreen();
  } else if (doc.mozCancelFullScreen) {
    doc.mozCancelFullScreen();
  } else if (doc.webkitExitFullscreen) {
    doc.webkitExitFullscreen();
  } else if (doc.msExitFullscreen) {
    doc.msExitFullscreen();
  }
};

export const toggleFullscreen = () => {
  if (isFullScreen()) {
    exitFullscreen();
  } else {
    requestFullscreen();
  }
};

// useOnFullScreenChange triggeres the onEnter and onExit functions after entering
// and exiting fullscreen respectively
export const useOnFullScreenChange = (onEnter: () => void, onExit: () => void) => {
  // add event handle for the fullscreenchange event and remove it when unmounted.
  useEffect(() => {
    const onChange = () => (isFullScreen() ? onEnter() : onExit());
    document.addEventListener('fullscreenchange', onChange);
    return () => {
      document.removeEventListener('fullscreenchange', onChange);
    };
  }, [onEnter, onExit]);
};
