// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/v2/schoolgroups.proto" (package "sparx.school.v2", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { SchoolGroupsService } from './schoolgroups';
import type { ListSchoolGroupsForSchoolResponse } from './schoolgroups';
import type { ListSchoolGroupsForSchoolRequest } from './schoolgroups';
import type { RemoveSchoolFromGroupRequest } from './schoolgroups';
import type { AddSchoolToGroupRequest } from './schoolgroups';
import type { DeleteSchoolGroupRequest } from './schoolgroups';
import type { UpdateSchoolGroupRequest } from './schoolgroups';
import type { CreateSchoolGroupRequest } from './schoolgroups';
import type { ListSchoolGroupsResponse } from './schoolgroups';
import type { ListSchoolGroupsRequest } from './schoolgroups';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { SchoolGroup } from './schoolgroups';
import type { GetSchoolGroupRequest } from './schoolgroups';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * SchoolGroupsService stores school groups.
 *
 * @generated from protobuf service sparx.school.v2.SchoolGroupsService
 */
export interface ISchoolGroupsServiceClient {
  /**
   * Gets a school group.
   *
   * @generated from protobuf rpc: GetSchoolGroup(sparx.school.v2.GetSchoolGroupRequest) returns (sparx.school.v2.SchoolGroup);
   */
  getSchoolGroup(
    input: GetSchoolGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSchoolGroupRequest, SchoolGroup>;
  /**
   * Lists school groups.
   *
   * @generated from protobuf rpc: ListSchoolGroups(sparx.school.v2.ListSchoolGroupsRequest) returns (sparx.school.v2.ListSchoolGroupsResponse);
   */
  listSchoolGroups(
    input: ListSchoolGroupsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListSchoolGroupsRequest, ListSchoolGroupsResponse>;
  /**
   * Creates a new school group.
   *
   * @generated from protobuf rpc: CreateSchoolGroup(sparx.school.v2.CreateSchoolGroupRequest) returns (sparx.school.v2.SchoolGroup);
   */
  createSchoolGroup(
    input: CreateSchoolGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateSchoolGroupRequest, SchoolGroup>;
  /**
   * Updates an existing school group.
   *
   * @generated from protobuf rpc: UpdateSchoolGroup(sparx.school.v2.UpdateSchoolGroupRequest) returns (sparx.school.v2.SchoolGroup);
   */
  updateSchoolGroup(
    input: UpdateSchoolGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateSchoolGroupRequest, SchoolGroup>;
  /**
   * Soft deletes an existing school group.
   *
   * @generated from protobuf rpc: DeleteSchoolGroup(sparx.school.v2.DeleteSchoolGroupRequest) returns (sparx.school.v2.SchoolGroup);
   */
  deleteSchoolGroup(
    input: DeleteSchoolGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<DeleteSchoolGroupRequest, SchoolGroup>;
  /**
   * Adds a school to an existing group.
   *
   * @generated from protobuf rpc: AddSchoolToGroup(sparx.school.v2.AddSchoolToGroupRequest) returns (sparx.school.v2.SchoolGroup);
   */
  addSchoolToGroup(
    input: AddSchoolToGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<AddSchoolToGroupRequest, SchoolGroup>;
  /**
   * Removes a school from an existing group.
   *
   * @generated from protobuf rpc: RemoveSchoolFromGroup(sparx.school.v2.RemoveSchoolFromGroupRequest) returns (sparx.school.v2.SchoolGroup);
   */
  removeSchoolFromGroup(
    input: RemoveSchoolFromGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<RemoveSchoolFromGroupRequest, SchoolGroup>;
  /**
   * Get all groups for a given school
   *
   * @generated from protobuf rpc: ListSchoolGroupsForSchool(sparx.school.v2.ListSchoolGroupsForSchoolRequest) returns (sparx.school.v2.ListSchoolGroupsForSchoolResponse);
   */
  listSchoolGroupsForSchool(
    input: ListSchoolGroupsForSchoolRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListSchoolGroupsForSchoolRequest,
    ListSchoolGroupsForSchoolResponse
  >;
}
/**
 * SchoolGroupsService stores school groups.
 *
 * @generated from protobuf service sparx.school.v2.SchoolGroupsService
 */
export class SchoolGroupsServiceClient
  implements ISchoolGroupsServiceClient, ServiceInfo
{
  typeName = SchoolGroupsService.typeName;
  methods = SchoolGroupsService.methods;
  options = SchoolGroupsService.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * Gets a school group.
   *
   * @generated from protobuf rpc: GetSchoolGroup(sparx.school.v2.GetSchoolGroupRequest) returns (sparx.school.v2.SchoolGroup);
   */
  getSchoolGroup(
    input: GetSchoolGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSchoolGroupRequest, SchoolGroup> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetSchoolGroupRequest, SchoolGroup>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Lists school groups.
   *
   * @generated from protobuf rpc: ListSchoolGroups(sparx.school.v2.ListSchoolGroupsRequest) returns (sparx.school.v2.ListSchoolGroupsResponse);
   */
  listSchoolGroups(
    input: ListSchoolGroupsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListSchoolGroupsRequest, ListSchoolGroupsResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListSchoolGroupsRequest, ListSchoolGroupsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Creates a new school group.
   *
   * @generated from protobuf rpc: CreateSchoolGroup(sparx.school.v2.CreateSchoolGroupRequest) returns (sparx.school.v2.SchoolGroup);
   */
  createSchoolGroup(
    input: CreateSchoolGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateSchoolGroupRequest, SchoolGroup> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateSchoolGroupRequest, SchoolGroup>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Updates an existing school group.
   *
   * @generated from protobuf rpc: UpdateSchoolGroup(sparx.school.v2.UpdateSchoolGroupRequest) returns (sparx.school.v2.SchoolGroup);
   */
  updateSchoolGroup(
    input: UpdateSchoolGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateSchoolGroupRequest, SchoolGroup> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateSchoolGroupRequest, SchoolGroup>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Soft deletes an existing school group.
   *
   * @generated from protobuf rpc: DeleteSchoolGroup(sparx.school.v2.DeleteSchoolGroupRequest) returns (sparx.school.v2.SchoolGroup);
   */
  deleteSchoolGroup(
    input: DeleteSchoolGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<DeleteSchoolGroupRequest, SchoolGroup> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DeleteSchoolGroupRequest, SchoolGroup>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Adds a school to an existing group.
   *
   * @generated from protobuf rpc: AddSchoolToGroup(sparx.school.v2.AddSchoolToGroupRequest) returns (sparx.school.v2.SchoolGroup);
   */
  addSchoolToGroup(
    input: AddSchoolToGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<AddSchoolToGroupRequest, SchoolGroup> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<AddSchoolToGroupRequest, SchoolGroup>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Removes a school from an existing group.
   *
   * @generated from protobuf rpc: RemoveSchoolFromGroup(sparx.school.v2.RemoveSchoolFromGroupRequest) returns (sparx.school.v2.SchoolGroup);
   */
  removeSchoolFromGroup(
    input: RemoveSchoolFromGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<RemoveSchoolFromGroupRequest, SchoolGroup> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<RemoveSchoolFromGroupRequest, SchoolGroup>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Get all groups for a given school
   *
   * @generated from protobuf rpc: ListSchoolGroupsForSchool(sparx.school.v2.ListSchoolGroupsForSchoolRequest) returns (sparx.school.v2.ListSchoolGroupsForSchoolResponse);
   */
  listSchoolGroupsForSchool(
    input: ListSchoolGroupsForSchoolRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListSchoolGroupsForSchoolRequest,
    ListSchoolGroupsForSchoolResponse
  > {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListSchoolGroupsForSchoolRequest,
      ListSchoolGroupsForSchoolResponse
    >('unary', this._transport, method, opt, input);
  }
}
