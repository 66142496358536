import { CheckIcon, ChevronDownIcon, ChevronUpIcon, TriangleDownIcon } from '@radix-ui/react-icons';
import * as Select from '@radix-ui/react-select';
import { YearGroup } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { updatedYearGroup } from '@sparx/mis-sync-import/src/analytics';
import { useMisSyncContext } from '@sparx/mis-sync-import/src/Context';
import styles from '@sparx/mis-sync-import/src/MisSyncImport/components/SchoolGroupsPanel/SchoolGroupsPanel.module.css';
import { useSyncConfig } from '@sparx/mis-sync-import/src/MisSyncImport/context/config';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import selectStyles from '@sparx/sparx-design/shared-styles/Select.module.css';
import classNames from 'classnames';

export const YearGroupSelect = ({
  yearGroupID,
  yearGroups,
  classIdentifier,
}: {
  yearGroupID: string;
  yearGroups: Record<string, YearGroup>;
  classIdentifier: string;
}) => {
  const { sendEvent } = useMisSyncContext();
  const { dispatch } = useSyncConfig();
  const sortedYearGroups = Object.values(yearGroups).sort((a, b) =>
    a.name.localeCompare(b.name, undefined, { numeric: true }),
  );

  return (
    <Select.Root
      onValueChange={val => {
        dispatch({
          type: 'set_year_group_id',
          classIdentifier: classIdentifier,
          yearGroupId: val,
        });
        sendEvent(updatedYearGroup(classIdentifier, val));
      }}
    >
      <Select.Trigger
        className={classNames(
          selectStyles.SelectTrigger,
          accessibilityStyles.FocusTarget,
          styles.SelectTrigger,
        )}
        aria-label="year group the class belongs to"
      >
        <Select.Value>{yearGroups[yearGroupID]?.name ?? '-'}</Select.Value>
        <Select.Icon>
          <TriangleDownIcon />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content className={classNames(styles.Select)} position="popper">
          <Select.ScrollUpButton>
            <ChevronUpIcon />
          </Select.ScrollUpButton>
          <Select.Viewport className={styles.SelectDropdown}>
            {sortedYearGroups.map(c => (
              <Select.Item
                className={classNames(selectStyles.SelectItem, styles.SelectItem)}
                value={c.yearGroupID}
                key={c.yearGroupID}
              >
                <Select.ItemText>{c.name}</Select.ItemText>
                <Select.ItemIndicator className={selectStyles.SelectItemIndicator}>
                  <CheckIcon />
                </Select.ItemIndicator>
              </Select.Item>
            ))}
          </Select.Viewport>
          <Select.ScrollDownButton>
            <ChevronDownIcon />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};
