import { useEffect, useState } from 'react';

/**
 * A debounced input react component - allows for a delay between the user typing and the onChange event firing
 * @param value the initial value of the component
 * @param onChange callback for when the input value changes
 * @param debounce the delay in milliseconds between the user typing and the onChange event firing
 * @param props any other input props
 * @constructor
 */
export const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string;
  onChange: (value: string) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return <input {...props} value={value} onChange={e => setValue(e.target.value)} />;
};
