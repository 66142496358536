import { Button } from '@sparx/sparx-design/components';
import { Tick, TriangleExclamationIcon } from '@sparx/sparx-design/icons';

import styles from './ConflictLabel.module.css';

type ConflictLabelVariant = 'unresolved' | 'resolved';

interface ConflictLabelProps {
  // whether the conflicts are unroslved or resolved
  variant: ConflictLabelVariant;
  // callback for when the user clicks on the button
  onResolveConflicts: () => void;
}

/**
 * Label for a class on the current classes that indicates if there are student conflicts with the class
 * @param variant whether the conflicts are unroslved or resolved
 * @param onResolveConflicts callback for when the user clicks on the button
 * @constructor
 */
export const ConflictLabel = ({ variant, onResolveConflicts }: ConflictLabelProps) => {
  const icon =
    variant === 'resolved' ? (
      <Tick variant="White" className={styles.ResolvedIcon} />
    ) : (
      <TriangleExclamationIcon className={styles.UnresolvedIcon} />
    );
  return (
    <span className={styles.Root}>
      {icon}
      {variant === 'resolved' && 'Resolved'}
      <Button
        size={'sm'}
        variant="text"
        onClick={onResolveConflicts}
        className={styles.ResolveButton}
      >
        {variant === 'resolved' ? 'View' : 'Resolve conflicts'}
      </Button>
    </span>
  );
};

interface ConflictCountLabelProps {
  // Number of unresolved conflicts
  unresolvedConflictsCount: number;
  // callback for when the user clicks on the button
  onResolveConflicts: () => void;
}

/**
 * A label showing how many unresolved conflicts there are in a set of classes
 * @param unresolvedConflictsCount
 * @param onResolveConflicts
 * @constructor
 */
export const ConflictCountLabel = ({
  unresolvedConflictsCount,
  onResolveConflicts,
}: ConflictCountLabelProps) => {
  return (
    <span className={styles.ConflictCountLabel}>
      <TriangleExclamationIcon className={styles.UnresolvedIcon} />
      <Button
        size={'sm'}
        variant="text"
        onClick={onResolveConflicts}
        className={styles.ConflictCountResolveButton}
      >
        {unresolvedConflictsCount} unresolved conflict{unresolvedConflictsCount > 1 ? 's' : ''}
      </Button>
    </span>
  );
};
