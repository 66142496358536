import {
  Box,
  chakra,
  Flex,
  StyleProps,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';

import { SortOptions } from '../Context';

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  defaultSort?: SortingState;
  onRowClick?: (row: Data) => void;
  getRowId?: (row: Data) => string;
  rowIsHighlighted?: (row: Data) => string | undefined;
  noDataRow?: React.ReactNode;
  headerStyles?: StyleProps;
  sortOptions?: SortOptions;
} & (
  | { onRowSelectionChange?: never; rowSelectionState?: never }
  | {
      onRowSelectionChange: React.Dispatch<React.SetStateAction<RowSelectionState>>;
      rowSelectionState: RowSelectionState;
      getRowId: (row: Data) => string;
    }
);

interface DataTableColumnMeta {
  align?: 'left' | 'right' | 'center';
  isNumeric?: boolean;
  isElement?: boolean;
  blockClick?: boolean;
  blockCellClick?: boolean;
  width?: string | number;
}

export function DataTable<Data extends object>({
  data,
  columns,
  defaultSort = [],
  getRowId,
  onRowClick,
  rowIsHighlighted,
  noDataRow,
  headerStyles = {},
  sortOptions,
  onRowSelectionChange,
  rowSelectionState,
}: DataTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>(defaultSort);
  const table = useReactTable({
    columns,
    data,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: sort => setSorting(sort.length === 0 ? defaultSort : sort),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: !!onRowSelectionChange,
    onRowSelectionChange,
    state: {
      sorting,
      rowSelection: rowSelectionState,
    },
  });

  const [isSticky /*, setIsSticky*/] = React.useState(false);
  // TODO: work out how to fit this in
  //
  // const tableRef = useRef<HTMLTableElement | null>(null);
  // const pageContext = usePageContainerContext();
  // useEffect(() => {
  //   if (pageContext.scrollContainer) {
  //     const callback = debounce(() => {
  //       const container = pageContext.scrollContainer?.getBoundingClientRect();
  //       const table = tableRef.current?.getBoundingClientRect();
  //       setIsSticky(Boolean(container && table && container.top > table.top));
  //     }, 10);
  //     pageContext.scrollContainer.addEventListener('scroll', callback);
  //     return () => pageContext.scrollContainer?.removeEventListener('scroll', callback);
  //   }
  // }, [tableRef.current, pageContext.scrollContainer]);

  // This obviously isn't very sustainable but gives us what we need for now, more or less
  const { upIcon = faCaretUp, downIcon = faCaretDown, hoverStyle } = { ...sortOptions };

  return (
    <Table
      /*ref={tableRef}*/ backgroundColor="white"
      boxShadow="elevationLow"
      borderRadius="md"
      overflow="hidden"
    >
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header, i) => {
              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
              const meta: DataTableColumnMeta | undefined = header.column.columnDef.meta;
              return (
                <Th
                  key={header.id}
                  onClick={
                    header.column.getCanSort() && !meta?.blockClick
                      ? header.column.getToggleSortingHandler()
                      : undefined
                  }
                  isNumeric={meta?.isNumeric}
                  textTransform="none"
                  fontSize="sm"
                  backgroundColor="blue.800"
                  color="white"
                  _hover={header.column.getCanSort() ? hoverStyle : undefined}
                  position="sticky"
                  borderTopLeftRadius={!isSticky && i === 0 ? 'md' : undefined}
                  borderTopRightRadius={
                    !isSticky && i === headerGroup.headers.length - 1 ? 'md' : undefined
                  }
                  textAlign={meta?.align || 'left'}
                  pl={i === 0 ? 4 : 3}
                  pr={3}
                  top={0}
                  py={4}
                  zIndex={5}
                  {...headerStyles}
                  style={{ width: meta?.width }}
                >
                  <Flex align="center">
                    {typeof header.column.columnDef.header === 'string' ? (
                      <Text flex={1}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </Text>
                    ) : (
                      flexRender(header.column.columnDef.header, header.getContext())
                    )}
                    {header.column.getCanSort() && (
                      <chakra.span pl="3" opacity={!header.column.getIsSorted() ? 0.2 : 1}>
                        {header.column.getIsSorted() === 'desc' ? (
                          <FontAwesomeIcon icon={downIcon} aria-label="sorted descending" />
                        ) : (
                          <FontAwesomeIcon icon={upIcon} aria-label="sorted ascending" />
                        )}
                      </chakra.span>
                    )}
                  </Flex>
                  {isSticky && <HeaderGradient />}
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map(row => (
          <Tr
            key={row.id}
            onClick={() => onRowClick?.(row.original)}
            _hover={
              onRowClick
                ? {
                    cursor: 'pointer',
                    background: 'gray.50',
                  }
                : {}
            }
            bg={rowIsHighlighted?.(row.original) || 'white'}
          >
            {row.getVisibleCells().map((cell, i) => {
              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
              const meta: DataTableColumnMeta | undefined = cell.column.columnDef.meta;
              return (
                <Td
                  key={cell.id}
                  lineHeight={meta?.isElement ? '0px' : undefined}
                  onClick={
                    meta?.blockClick || meta?.blockCellClick ? e => e.stopPropagation() : undefined
                  }
                  isNumeric={meta?.isNumeric}
                  py={3}
                  textAlign={meta?.align || 'left'}
                  pl={i === 0 ? 4 : 3}
                  pr={3}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              );
            })}
          </Tr>
        ))}
        {noDataRow && table.getRowModel().rows.length === 0 && (
          <Tr>
            <Td colSpan={table.getAllColumns().length} textAlign="center" color="gray.500">
              {noDataRow}
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
}

const HeaderGradient = () => (
  <Box
    height={4}
    position="absolute"
    bottom={-4}
    left={0}
    right={0}
    bgGradient="linear(to-b, blackAlpha.400, transparent)"
    pointerEvents="none"
  />
);
