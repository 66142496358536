import { Topic } from '@sparx/api/apis/sparx/content/v2/curriculum';
import { GetIndependentLearningStateResponse } from '@sparx/api/apis/sparx/science/packages/v1/independentlearning';
import { Course, CourseTier } from '@sparx/api/apis/sparx/science/schools/settings/v1/settings';
import { isTopicHigherOnly, isTopicSeparateOnly } from 'utils/content';

export const filterTopics = (userState: GetIndependentLearningStateResponse, t: Topic) =>
  !(
    t.deleted ||
    (userState.course !== Course.SEPARATE && isTopicSeparateOnly(t)) ||
    (userState.tier !== CourseTier.LEVEL_HIGHER && isTopicHigherOnly(t))
  );
