import { Box, Button, Flex, HStack, Spacer, Text } from '@chakra-ui/react';
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Assignment,
  GeneratedAssignment,
  GeneratedAssignmentTopic,
} from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { useTopicWithStrandsLookup } from 'api/content';
import { Week } from 'api/school';
import { useClientEvent } from 'components/ClientEventProvider';
import { LargeLoading } from 'components/loading/LargeLoading';
import { Warning } from 'components/warning';
import React, { useMemo } from 'react';
import { AssignmentPreviewModal } from 'views/planner/components/AssignmentPreview';
import { TopicPicker } from 'views/planner/components/TopicPicker';

import { usePlannerContext } from '../Context';
import { useManageConsolidationTopicsModal } from '../ManageConsolidationTopics/Context';

interface TopicComponentProps {
  week: Week;
  assignment: Assignment;
  generatedAssignment: GeneratedAssignment;
  setTopicScreen: (screen: 'view' | 'select') => void;
  setTopics: (topics: GeneratedAssignmentTopic[]) => void;
  generated: boolean;
}

export const HomeworkTopics = ({
  week,
  assignment,
  generatedAssignment,
  setTopicScreen,
  setTopics,
  generated,
}: TopicComponentProps) => {
  const topics = useTopicWithStrandsLookup({ suspense: true });
  const homeworkTopics = generatedAssignment.topics
    .map(t => (topics || {})[t.name])
    .filter(t => !!t);
  const { sendEvent } = useClientEvent();
  const { onOpen } = useManageConsolidationTopicsModal();

  const {
    consolidation: { data: consolidationData, isLoading },
    nextHandoutWeek,
  } = usePlannerContext();

  const noTopics =
    homeworkTopics.length === 0 &&
    Object.values(consolidationData?.serverState || {}).every(s => !s.included);

  const manageConsolidation = () => {
    onOpen();
    sendEvent(
      { category: 'planner', action: 'manage_consolidation_topics' },
      { assignment: assignment.name },
    );
  };

  return (
    <Box>
      <Flex alignItems="center" pb={2}>
        <Text fontWeight="bold" fontSize="md" pl={1} py={2}>
          Week {week.index} Topics
        </Text>
        <AssignmentPreviewModal assignment={assignment} />
        <Spacer />
        {!generated && (
          <Button
            colorScheme="buttonTeal"
            onClick={() => setTopicScreen('select')}
            leftIcon={<FontAwesomeIcon icon={faPlus} />}
          >
            Add Topic to Week {week.index}
          </Button>
        )}
      </Flex>
      {!generated && week.index === nextHandoutWeek?.index && (noTopics || isLoading) ? (
        isLoading ? (
          <LargeLoading />
        ) : (
          <Warning status="warning">
            <Flex justifyContent="space-between" alignItems="center">
              <Text>
                No topics scheduled and no consolidation available. Homework will{' '}
                <strong>not be set</strong> unless topics are added.
              </Text>
              <Button colorScheme="gray" onClick={manageConsolidation} minW="unset" ml={4}>
                Manage Consolidation topics
              </Button>
            </Flex>
          </Warning>
        )
      ) : (
        <>
          <TopicPicker
            topics={homeworkTopics}
            homeworkTopics={generatedAssignment.topics}
            setHomeworkTopics={setTopics}
            generated={generated}
            week={week}
            canRemove={true}
          />
          <Flex
            backgroundColor="gray.50"
            borderWidth={1}
            borderColor="gray.200"
            mt={homeworkTopics.length === 0 ? 0 : 4}
            px={4}
            py={2}
            alignItems="center"
          >
            <Flex direction="column" alignItems="flex-start" textAlign="left">
              <Flex alignItems="center">
                <Text color={'black'} fontWeight="500">
                  Consolidation
                </Text>
              </Flex>
              <Text fontSize="xs" color="gray.500">
                Spaced retrieval of topics from previous homework
              </Text>
            </Flex>
            <Box flex="1" />
            <Button colorScheme="gray" onClick={manageConsolidation}>
              Manage Consolidation topics
            </Button>
          </Flex>
        </>
      )}
    </Box>
  );
};

export const AddTopics = ({
  week,
  assignment,
  generatedAssignment,
  setTopicScreen,
  setTopics,
  generated,
}: TopicComponentProps) => {
  const topicLookup = useTopicWithStrandsLookup({ suspense: true });
  const topics = useMemo(() => Object.values(topicLookup), []);

  return (
    <Box>
      <HStack spacing={3} pb={2} fontWeight="bold" fontSize="md" color="gray.600">
        <Button
          onClick={() => setTopicScreen('view')}
          leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          fontWeight="bold"
          fontSize="md"
        >
          Week {week.index} Topics
        </Button>
        <Text color="gray.300">/</Text>
        <Text>Add Topic</Text>
        <AssignmentPreviewModal assignment={assignment} />
        <Spacer />
      </HStack>
      <TopicPicker
        topics={topics}
        homeworkTopics={generatedAssignment.topics}
        setHomeworkTopics={setTopics}
        generated={generated}
        week={week}
        canRemove={false}
        withSearch={true}
      />
    </Box>
  );
};
