import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Spacer,
  Text,
} from '@chakra-ui/react';
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
} from '@dnd-kit/core';
import { faPencil, faRocket, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ResourceStatus,
  SchemeOfLearning,
  SchemeOfLearningSchedule,
  SchemeOfLearningTemplate,
  SchemeOfLearningWeek,
  SchemeOfLearningWeekType,
  WorkUnit,
} from '@sparx/api/apis/sparx/planning/v1/sol';
import { useTopicWithStrandsLookup } from 'api/content';
import { useSchoolYears, useWeeksForYear, Week } from 'api/school';
import { useUserType } from 'api/sessions';
import { useEditableSols, useSol, useTemplateSol, useUpdateSolSchedule } from 'api/sol';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import { TopicSearchContextProvider } from 'components/topicsearch/Context';
import { FilterFields, TopicSearchCurriculum } from 'components/topicsearch/TopicSearch';
import { UndoButtons } from 'components/undobuttons/UndoButtons';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Link,
  Navigate,
  unstable_usePrompt,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { v4 as uuid } from 'uuid';
import { WeekWrapper } from 'views/planner/components/WeekButton';
import { FindTopics } from 'views/sol/components/FindTopics';
import { JsonEditModal } from 'views/sol/components/JsonEditModal';
import { PublishSolModal } from 'views/sol/components/PublishSolModal';
import { TopicData } from 'views/sol/components/TopicData';
import { WeekContent } from 'views/sol/components/WeekContent';
import { getSolActions, parseDraggableId, SolWeekSetter } from 'views/sol/solHelpers';

import { EditSolModal } from './components/EditSolModal';
import { useCanEditTemplate } from './SolTemplates';

interface SolBuilderViewProps {
  readonly?: boolean;
}

export const SolBuilderView = (props: SolBuilderViewProps) => {
  // Add a key to the content so that it re-renders if switching between readonly and editable
  // versions of the same SOL.
  return (
    <SuspenseRoute>
      <SolBuilderContent key={props.readonly ? 'ro' : 'editable'} {...props} />
    </SuspenseRoute>
  );
};

const SolBuilderContent = (props: SolBuilderViewProps) => {
  const solId = useParams().solID || '';
  const [searchParams] = useSearchParams();
  const schoolGroupID = searchParams.get('sg') || '';
  // Params related to archived sols, archive means we should allow deleted,
  // and draft means we should load the draft but as readonly
  const archiveView = searchParams.get('archive') !== null;
  const draftOverride = searchParams.get('draft') !== null;

  const { data: templateSol } = useTemplateSol(
    schoolGroupID,
    solId,
    props.readonly ? 'published' : 'draft',
    { suspense: true },
  );
  const { data: sol } = useSol(
    schoolGroupID ? '' : solId,
    props.readonly && !draftOverride ? 'published' : 'draft',
    archiveView,
    {
      suspense: true,
    },
  );

  if (sol?.schedule) {
    // TODO: mix in weeks into sol schedule
    return <SolScheduleEditorWithUndo isTemplate={false} sol={sol} {...props} />;
  } else if (templateSol?.schedule) {
    return <SolScheduleEditorWithUndo isTemplate={true} sol={templateSol} {...props} />;
  }

  return <>Scheme of Learning not found</>; // TODO
};

type SolScheduleEditorWithUndoProps = SolBuilderViewProps &
  (
    | {
        isTemplate: false;
        sol: SchemeOfLearning;
      }
    | {
        isTemplate: true;
        sol: SchemeOfLearningTemplate;
      }
  );

const cloneSol = (sol: SchemeOfLearningSchedule) =>
  SchemeOfLearningSchedule.fromJson(SchemeOfLearningSchedule.toJson(sol));

const SolScheduleEditorWithUndo = ({
  isTemplate,
  sol,
  readonly,
}: SolScheduleEditorWithUndoProps) => {
  const { displayName, name, baseCurriculum } = sol;

  const navigate = useNavigate();

  const [weeks, setWeeks] = useState(sol.schedule?.weeks || []);

  const [lastPushed, setLastPushed] = useState<SchemeOfLearningSchedule>(cloneSol({ weeks }));
  const [history, setHistory] = useState<SchemeOfLearningSchedule[]>([]);
  const [future, setFuture] = useState<SchemeOfLearningSchedule[]>([]);
  const [savedTime, setSavedTime] = useState<Date | undefined>();

  const canEdit = useCanEditTemplate();
  const allowEdit = isTemplate ? canEdit(sol) : true;

  // Load the school year for this sol so we can see if the back button should return to the
  // 'next academic year' tab.
  const { data: schoolYears } = useSchoolYears({ suspense: true });
  const schoolYear = isTemplate
    ? undefined
    : schoolYears?.find(y => y.name.endsWith(`/years/${sol.schoolYear}`));
  const isArchive =
    !!sol.metadata?.deleteTime || (!isTemplate && !schoolYear?.current && !schoolYear?.next);

  const [next, setNext] = useState<SchemeOfLearningSchedule | undefined>();
  const { mutate, isLoading, isSuccess } = useUpdateSolSchedule(name, isTemplate, {
    onSuccess: data => {
      if (next) {
        setNext(undefined);
        mutate({ schedule: next, metadata: data.metadata });
      } else {
        setSavedTime(new Date());
      }
    },
  });

  unstable_usePrompt({
    message: 'You have unsaved changes. Are you sure you want to leave?',
    when: isLoading,
  });

  const update = useCallback(
    (update: SchemeOfLearningSchedule) => {
      setLastPushed(cloneSol(update));
      if (isLoading) {
        setNext(update);
      } else {
        mutate({ schedule: update, metadata: sol.metadata });
      }
    },
    [isLoading, mutate, sol.metadata],
  );

  const doUndo = () => {
    if (history.length > 0) {
      const last = history[history.length - 1];
      setFuture(future.concat([lastPushed]));
      setHistory(history.slice(0, -1));
      setWeeks(last.weeks);
      update(last);
    }
  };

  const doRedo = () => {
    if (future.length > 0) {
      const next = future[future.length - 1];
      setHistory(history.concat([lastPushed]));
      setFuture(future.slice(0, -1));
      setWeeks(next.weeks);
      update(next);
    }
  };

  const doPush = useCallback(
    (newSchedule: SchemeOfLearningSchedule) => {
      if (!SchemeOfLearningSchedule.equals(newSchedule, lastPushed)) {
        setHistory(history.splice(-29).concat([lastPushed]));
        setFuture([]);
        setWeeks(newSchedule.weeks);
        update(newSchedule);
      }
    },
    [history, lastPushed, update],
  );

  const [pushNextRender, setPushNextRender] = useState(false);
  useEffect(() => {
    if (pushNextRender) {
      doPush({ weeks });
      setPushNextRender(false);
    }
  }, [pushNextRender, setPushNextRender, doPush, weeks]);

  const pushChange = () => setPushNextRender(true);

  const [publishing, setPublishing] = useState(false);
  const [useInSchool, setUseInSchool] = useState(false);

  // If the user is not allowed to edit this SOL but is trying to, redirect them.
  if (!allowEdit && !readonly) {
    return <Navigate to="/teacher/sol" replace={true} />;
  }

  if (!readonly && isArchive) {
    return <Navigate to={`/teacher/sol/view/${sol.name.split('/')[3]}`} replace={true} />;
  }

  return (
    <Box height="100%" width="100%" display="flex" position="absolute">
      <Container maxW={1400} flex={1} py={4}>
        <SolScheduleEditor
          solName={name}
          title={displayName}
          baseCurriculumName={baseCurriculum}
          solWeeks={weeks}
          pushChange={pushChange}
          setSolWeeks={setWeeks}
          readonly={readonly || !allowEdit || isArchive}
          schoolYear={isTemplate ? undefined : sol.schoolYear}
          isTemplate={isTemplate}
          allowEdit={!isArchive && allowEdit}
          setUseInSchool={setUseInSchool}
        >
          <PageTitle
            title={
              (readonly ? 'Preview ' : 'Edit ') +
              'Scheme of Learning' +
              (isTemplate ? ' Template' : '')
            }
            backButton={
              isTemplate
                ? '/teacher/sol/templates'
                : schoolYear?.previous || !!sol.metadata?.deleteTime
                  ? '/teacher/sol/archive'
                  : schoolYear?.next
                    ? '/teacher/sol/nextyear'
                    : '/teacher/sol'
            }
          >
            {!readonly && (
              <>
                <UndoButtons
                  isLoading={isLoading}
                  isSuccess={isSuccess}
                  undo={doUndo}
                  redo={doRedo}
                  hasHistory={history.length > 0}
                  hasFuture={future.length > 0}
                  savedTime={savedTime}
                />
                <Button
                  colorScheme="buttonTeal"
                  variant="outline"
                  ml={5}
                  onClick={() => setPublishing(true)}
                  isDisabled={isLoading || sol.metadata?.status === ResourceStatus.PUBLISHED}
                  leftIcon={<FontAwesomeIcon icon={faRocket} />}
                >
                  Publish
                </Button>
              </>
            )}
          </PageTitle>
        </SolScheduleEditor>
        <PublishSolModal
          scheme={
            publishing
              ? isTemplate
                ? { isTemplate: true, ...sol }
                : { isTemplate: false, ...sol }
              : undefined
          }
          onClose={() => setPublishing(false)}
          onPublish={() =>
            navigate(
              !isTemplate
                ? schoolYear?.next
                  ? '/teacher/sol/nextyear'
                  : '/teacher/sol/thisyear'
                : '/teacher/sol/templates',
            )
          }
        />
        <EditSolModal
          action={
            isTemplate && useInSchool
              ? { kind: 'useTemplate', sol: { isTemplate: true, ...sol } }
              : undefined
          }
          onClose={() => setUseInSchool(false)}
          onSave={name => navigate(`/teacher/sol/edit/${name.split('/')[3]}`)}
        />
      </Container>
    </Box>
  );
};

interface SolScheduleEditorProps {
  solName: string;
  title: string;
  baseCurriculumName: string;
  solWeeks: SchemeOfLearningWeek[];
  setSolWeeks: SolWeekSetter;
  pushChange: () => void;
  schoolYear?: string;
  readonly?: boolean;
  isTemplate: boolean;
  allowEdit?: boolean;
  setUseInSchool: (use: boolean) => void;
}

const SolScheduleEditor = ({
  solName,
  title,
  children,
  baseCurriculumName,
  solWeeks,
  setSolWeeks,
  pushChange,
  schoolYear,
  readonly,
  isTemplate,
  allowEdit,
  setUseInSchool,
}: PropsWithChildren<SolScheduleEditorProps>) => {
  const viewportRef = useRef<HTMLDivElement>(null);

  const { isSparxStaff } = useUserType();
  const { data: weeks = [] } = useWeeksForYear(schoolYear || '', {
    suspense: true,
    enabled: !!schoolYear,
  });
  const topicLookup = useTopicWithStrandsLookup({ suspense: true });
  const [newItems, setNewItems] = useState<WorkUnit[]>([]);
  const [filter, _setFilter] = useState<FilterFields>({
    curriculum: baseCurriculumName || '',
    search: '',
  });
  const setFilter = (newFilter: Partial<FilterFields>) => _setFilter({ ...filter, ...newFilter });

  const refreshNewItem = (itemID: string) =>
    setNewItems(items =>
      items.map(i => (i.workUnitId === itemID ? { ...i, workUnitId: uuid() } : i)),
    );

  const allItems = useMemo(
    () => newItems.concat(...solWeeks.map(w => w.workUnits)),
    [newItems, solWeeks],
  );
  const itemToWeek = useMemo(
    () =>
      solWeeks.reduce<Dictionary<string, number>>((p, v) => {
        for (const unit of v.workUnits) {
          p[unit.workUnitId] = v.weekIndex;
        }
        return p;
      }, {}),
    [solWeeks],
  );

  const [active, setActive] = useState<string | null>(null);
  const activeItem = allItems.find(i => i.workUnitId === active);

  const {
    moveAllFrom,
    setWeekType,
    addTopicToWeek,
    removeTopicFromWeek,
    reorderTopicInWeek,
    deduplicateTopics,
  } = getSolActions(setSolWeeks);

  const handleDragStart = (e: DragStartEvent) => {
    const id = e.active.id;
    if (typeof id === 'string') {
      setActive(id);
    }
  };

  const handleDragOver = (e: DragOverEvent) => {
    const over = parseDraggableId(e.over?.id);
    const item = allItems.find(i => i.workUnitId === e.active.id);
    const currentWeek = itemToWeek[item?.workUnitId || ''];

    const overWeek = over?.week || itemToWeek[over?.id || ''];
    if (overWeek && item) {
      if (!currentWeek || currentWeek !== overWeek) {
        addTopicToWeek(overWeek, item);
        if (currentWeek) {
          removeTopicFromWeek(currentWeek, item.workUnitId);
        }
        refreshNewItem(item.workUnitId);
      }
    }
  };

  const handleDragEnd = (e: DragEndEvent) => {
    setActive(null);

    const over = parseDraggableId(e.over?.id);
    if (over?.id && typeof e.active.id === 'string') {
      const currentWeek = itemToWeek[e.active.id];
      if (currentWeek) {
        reorderTopicInWeek(currentWeek, e.active.id, over.id);
      }
    }
    deduplicateTopics();
    pushChange();
  };

  const topicFinder = readonly ? null : (
    <TopicSearchContextProvider value={{ filter, setFilter }}>
      <Panel>
        <Flex
          bg="blue.800"
          px={4}
          py={3}
          justifyContent="space-between"
          alignItems="center"
          minH={14}
        >
          <Text fontWeight="bold" color="white" fontSize="lg">
            Find Topics
          </Text>
          <TopicSearchCurriculum
            mustSelect={true}
            hideSearch={true}
            selectProps={{ size: 'sm', borderRadius: 'md', width: '150px' }}
            textProps={{ color: 'white' }}
          />
        </Flex>
        <FindTopics items={newItems} setItems={setNewItems} topicLookup={topicLookup} />
      </Panel>
    </TopicSearchContextProvider>
  );

  // See if we have draft version of this sol
  const { data: sols = [] } = useEditableSols({ suspense: true, enabled: readonly });
  const hasDraftSol = sols.find(
    s => s.name === solName && s.metadata?.status === ResourceStatus.DRAFT,
  );

  const previewModal = readonly ? (
    <Panel>
      <Box bg="blue.800" px={4} py={3}>
        <Text fontWeight="bold" color="white" fontSize="lg">
          Previewing {isTemplate ? 'template' : 'Scheme of Learning'}
        </Text>
      </Box>
      {allowEdit && (
        <Box p={4}>
          <Text mb={4}>
            This {isTemplate ? 'template' : 'Scheme of Learning'} has been published.
          </Text>
          {hasDraftSol && (
            <Box
              px={4}
              py={3}
              color="orange.700"
              borderRadius="md"
              bg="orange.100"
              mb={4}
              fontWeight="bold"
            >
              You have an unpublished draft of this {isTemplate ? 'template' : 'Scheme of Learning'}
              .
            </Box>
          )}
          <Button
            colorScheme="buttonTeal"
            leftIcon={<FontAwesomeIcon icon={faPencil} />}
            onClick={() => undefined}
            as={Link}
            to={`/teacher/sol/edit/${solName.split('/')[3]}${isTemplate ? `?sg=${solName.split('/')[1]}` : ''}`}
          >
            {hasDraftSol ? 'Continue editing' : 'Make changes'}
          </Button>
        </Box>
      )}
      {isTemplate && (
        <Box p={4}>
          <Text mb={4}>This is a shared template.</Text>
          <Button
            onClick={() => setUseInSchool(true)}
            colorScheme="buttonTeal"
            leftIcon={<FontAwesomeIcon icon={faUpload} />}
          >
            Use in my school
          </Button>
        </Box>
      )}
    </Panel>
  ) : null;

  const editor = (
    <Panel>
      <Box bg="blue.800" display="flex" alignItems="center" pr={2}>
        {!isTemplate && <AcademicYearHeader />}
        <Text fontWeight="bold" color="white" fontSize="lg" py={3} px={4}>
          {title}
        </Text>
        <Spacer />
        {isSparxStaff && (
          <JsonEditModal
            scheme={{ weeks: solWeeks }}
            onSave={scheme => {
              setSolWeeks(() => scheme.weeks);
              pushChange();
            }}
          />
        )}
      </Box>
      {!isTemplate && !solWeeks.some(w => w.weekType === SchemeOfLearningWeekType.NO_HOMEWORK) && (
        <Alert status="warning" py={2} px={3}>
          <AlertIcon />
          You have not turned off homework for any weeks of the year.
        </Alert>
      )}
      <Box bg="white" flex={1} position="relative">
        <Box position="absolute" inset="0 0 0 0" overflowY="auto" ref={viewportRef}>
          <ViewportList viewportRef={viewportRef} items={solWeeks || []}>
            {week => {
              const content = (
                <WeekContent
                  weekIndex={week.weekIndex}
                  readonly={readonly}
                  week={week}
                  topics={topicLookup}
                  onRemoveItem={id => {
                    removeTopicFromWeek(week.weekIndex, id);
                    pushChange();
                  }}
                  onMoveAll={dir => {
                    moveAllFrom(week.weekIndex, dir);
                    pushChange();
                  }}
                  onSetWeekType={wt => {
                    setWeekType(week.weekIndex, wt);
                    pushChange();
                  }}
                  canMoveUp={Boolean(
                    solWeeks.find(
                      w =>
                        w.weekIndex < week.weekIndex &&
                        w.weekType !== SchemeOfLearningWeekType.NO_HOMEWORK,
                    ),
                  )}
                  canMoveDown={Boolean(
                    solWeeks.find(
                      w =>
                        w.weekIndex > week.weekIndex &&
                        w.weekType !== SchemeOfLearningWeekType.NO_HOMEWORK,
                    ),
                  )}
                  isTemplate={isTemplate}
                />
              );

              let yearWeek: Partial<Week> | undefined = weeks.find(w => w.index === week.weekIndex);
              if (!yearWeek && !isTemplate) {
                return content;
              }
              const idx = yearWeek?.index || week.weekIndex;
              if (!yearWeek) {
                yearWeek = {
                  index: idx,
                };
              }

              return (
                <WeekWrapper week={yearWeek} key={yearWeek.index} hideNow={true}>
                  {content}
                </WeekWrapper>
              );
            }}
          </ViewportList>
        </Box>
      </Box>
    </Panel>
  );

  return (
    <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragOver={handleDragOver}>
      <Grid
        height="100%"
        gridTemplateColumns="repeat(2, 1fr)"
        gridTemplateRows="min-content 1fr"
        columnGap={4}
      >
        <GridItem colSpan={2}>{children}</GridItem>
        {topicFinder}
        {previewModal}
        {editor}
      </Grid>
      <DragOverlay>
        {activeItem?.payload.oneofKind === 'topic' ? (
          <TopicData topic={topicLookup[activeItem.payload.topic]} dragged={true} />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

const Panel = ({ children }: PropsWithChildren) => (
  <GridItem
    display="flex"
    flexDirection="column"
    borderRadius="md"
    overflow="hidden"
    boxShadow="md"
    bg="white"
  >
    {children}
  </GridItem>
);

const AcademicYearHeader = () => (
  <Box
    width="85px"
    borderRight="1px solid rgba(255, 255, 255, 0.2)"
    fontSize="xs"
    display="flex"
    alignItems="center"
    justifyContent="center"
    color="white"
    fontWeight="bold"
    textAlign="center"
    lineHeight="1.2em"
    minH={14}
  >
    Academic
    <br />
    Year
  </Box>
);
