import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react';
import { useLocalStorage } from '@sparx/react-utils';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const DelayedInfoPopover = ({
  dismissedStorageKey,
  stateTriggerKey,
  text,
  children,
}: PropsWithChildren<{ dismissedStorageKey: string; stateTriggerKey: string; text: string }>) => {
  const { state } = useLocation();
  const [dismissed, setDismissed] = useLocalStorage(dismissedStorageKey);
  const [isOpen, setIsOpen] = useState(false);
  const timeoutID = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (state?.[stateTriggerKey] && dismissed !== 'true' && timeoutID.current === null) {
      // Start the timer, we don't clear the timeout on unmount so it's not affected by navigation, and will still trigger
      // Since it's short this mainly handles automatic redirects
      timeoutID.current = setTimeout(() => {
        setIsOpen(true);
      }, 2000);
      // Remove the state, otherwise a refresh will cause it to appear on every page until dismissed
      const { [stateTriggerKey]: _, ...rest } = state;
      history.replaceState(rest, '');
    }
  }, [state, dismissed]);

  return (
    <Popover
      placement="bottom"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        setDismissed('true');
      }}
      closeOnBlur={false}
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent
          variants={{
            enter: { opacity: 1, y: 0 },
            exit: { opacity: 0, y: 75 },
          }}
        >
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody px={4} py={6} textAlign="center">
            <Text>{text}</Text>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
