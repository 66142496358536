import { Box } from '@chakra-ui/react';
import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { readableStaffNames } from '@sparx/staff-manager';
import { useSession } from 'api/sessions';
import { useCurrentStaffUser } from 'api/staff';

export const StudentName = ({
  student,
  givenNameDefault = 'Unknown',
  familyNameDefault = 'Student',
}: {
  student?: Student;
  givenNameDefault?: string;
  familyNameDefault?: string;
}) => {
  return (
    <Box as="span" data-sentry-mask>
      {student?.givenName || givenNameDefault} {student?.familyName || familyNameDefault}
    </Box>
  );
};

export const CurrentUserDisplayName = ({
  givenOnly,
  pluralise,
}: {
  givenOnly?: boolean;
  pluralise?: boolean;
}) => {
  const { data: user } = useSession({ suspense: true });
  const { data: staffUser } = useCurrentStaffUser();

  const name =
    staffUser?.displayName ||
    buildName(staffUser?.givenName, staffUser?.familyName, givenOnly) ||
    buildName(user?.givenName, user?.familyName, givenOnly);
  return (
    <Box as="span" data-sentry-mask>
      {pluralise ? makePlural(name) : name}
    </Box>
  );
};

export const SessionGivenName = () => {
  const { data: user } = useSession();
  return (
    <Box as="span" data-sentry-mask>
      {user?.givenName}
    </Box>
  );
};

export const StaffRealName = ({ staff }: { staff?: SchoolStaffMember }) => {
  const { realName, fallback } = readableStaffNames(staff);
  return (
    <Box as="span" data-sentry-mask>
      {realName || fallback}
    </Box>
  );
};

const makePlural = (name?: string) => (name ? `${name}'${name.slice(-1) !== 's' ? 's' : ''} ` : '');
const buildName = (given?: string, family?: string, givenOnly?: boolean) =>
  givenOnly ? (given || '').trim() : `${given || ''} ${family || ''}`.trim();
