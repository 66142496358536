import { Box, Button, Flex, Grid, HStack, Text, Tooltip } from '@chakra-ui/react';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { unstable_usePrompt } from 'react-router-dom';

interface BulkEditorProps<Settings extends object, Entity> {
  entities: Entity[];
  settings: Partial<Settings>;
  setSettings: (settings: Partial<Settings>) => void;
  entityName: [string, string];
  saveButton: (disabled: boolean) => React.ReactNode;
  columns?: number;
  setHeight?: (height: number) => void;
  rightButton?: React.ReactNode;
}

export const BulkEditor = <Settings extends object, Entity>({
  entities,
  setSettings,
  settings,
  entityName,
  saveButton,
  children,
  columns = 4,
  setHeight,
  rightButton,
}: PropsWithChildren<BulkEditorProps<Settings, Entity>>) => {
  const [showControls, setShowControls] = useState(false);

  const toggleControls = () => {
    if (!showControls) setSettings({});
    setShowControls(!showControls);
  };

  // Hide controls if no students are selected
  useEffect(() => {
    if (entities.length === 0 && showControls) {
      setShowControls(false);
    }
  }, [entities, showControls, setShowControls]);

  const changedFields = useMemo(() => Object.keys(settings).length, [settings]);

  unstable_usePrompt({
    message: 'You have unsaved changes. Are you sure you want to leave?',
    when: showControls && !!changedFields,
  });

  const controls = (
    <Box bg="white" borderRadius="md" py={4} px={5} mb={2} borderColor="gray.200" borderWidth={1}>
      <Grid gap={4} templateColumns={`repeat(${columns}, 1fr)`}>
        {children}
      </Grid>
      <HStack spacing={4} justifyContent="flex-end" mt={4}>
        {changedFields > 0 && (
          <Text color="gray.500">
            Making{' '}
            <Text as="span" color="blue.900" fontWeight="bold">
              {changedFields}
            </Text>{' '}
            change{changedFields === 1 ? '' : 's'} to{' '}
            <Text as="span" color="blue.900" fontWeight="bold">
              {entities.length}
            </Text>{' '}
            {entities.length === 1 ? entityName[0] : entityName[1]}
          </Text>
        )}
        <Button colorScheme="buttonTeal" variant="outline" onClick={toggleControls}>
          Cancel
        </Button>
        {saveButton(!changedFields)}
      </HStack>
    </Box>
  );

  const wrapperRef = useRef<HTMLDivElement>(null);
  // report the height of the wrapper to the parent which is useful when making it sticky with other components.
  useEffect(() => {
    if (wrapperRef.current && setHeight) {
      setHeight(wrapperRef.current.getBoundingClientRect().height);
    }
  }, [setHeight, wrapperRef, showControls]);

  return (
    <Box ref={wrapperRef} pb={1}>
      <Flex justifyContent="space-between">
        <Tooltip
          hasArrow
          label={
            entities.length === 0 ? (
              <>Select {entityName[1]} below to be able to edit them at the same time.</>
            ) : undefined
          }
        >
          <Button
            mb={2}
            leftIcon={<FontAwesomeIcon icon={faPencil} />}
            isDisabled={entities.length === 0}
            onClick={toggleControls}
            colorScheme="buttonTeal"
          >
            Edit{showControls && 'ing'} selected {entityName[1]}
            {` (${entities.length})`}
          </Button>
        </Tooltip>
        {rightButton}
      </Flex>
      {showControls && controls}
    </Box>
  );
};
