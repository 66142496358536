import { Box } from '@chakra-ui/react';
import { useActivity, usePackage } from 'api/packages';
import { useBackLink } from 'app/BackLink';
import { NotFound } from 'components/errorpages/NotFound';
import { LargeLoading } from 'components/loading/LargeLoading';
import { Title } from 'components/pagetitle/PageTitle';
import { AnimatePresence } from 'framer-motion';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { HomeworkCancelledDialog } from 'views/package/HomeworkCancelledDialog';
import { AnimatedPage } from 'views/task/AnimatedPage';
import { Navigator } from 'views/task/Navigator';
import { Results } from 'views/task/Results';

import { ActivityDeliveryWithUnload } from './ActivityDelivery';

export const TaskView = () => {
  const navigate = useNavigate();

  const params = useParams();
  const packageID = params.packageID;
  const taskID = params.taskID;
  const page = params.taskItem
    ? params.taskItem === 'results'
      ? 'results'
      : parseInt(params.taskItem)
    : undefined;

  const { data: pkgData } = usePackage(packageID || '', { enabled: !!packageID });

  // If this is an IL package then the back link should go back to IL
  const backlink = useMemo(() => {
    if (pkgData?.package?.type === 'independentlearning') {
      return `/independentlearning`;
    } else if (pkgData?.package?.type === 'assessment-fixup') {
      return `/assessments`;
    } else {
      return `/packages/${packageID}`;
    }
  }, [pkgData, packageID]);

  useBackLink(backlink);

  if (!packageID || !taskID) {
    return <NotFound />;
  }

  const taskName = `packages/${packageID}/tasks/${taskID}`;

  return (
    <>
      <TaskDelivery
        packageID={packageID}
        taskID={taskID}
        page={page}
        onSetPage={(page, replace = false) => navigate(`/${taskName}/${page}`, { replace })}
        onBack={() => navigate(backlink)}
      />
      <HomeworkCancelledDialog pkg={pkgData?.package} />
    </>
  );
};

interface TaskDeliveryProps {
  packageID: string;
  taskID: string;
  page?: number | 'results';
  onSetPage: (page: number | 'results', replace?: boolean) => void;
  onBack: () => void;
}

export const TaskDelivery = ({ packageID, taskID, page, onSetPage, onBack }: TaskDeliveryProps) => {
  const taskName = `packages/${packageID}/tasks/${taskID}`;
  const taskItemName = page && page !== 'results' ? `${taskName}/items/${page}` : '';

  const { data: activity } = useActivity(taskItemName);
  const { data: pkgData, isLoading: isLoadingPackages } = usePackage(packageID);
  const task = pkgData?.package?.contents?.tasks.find(t => t.name === taskName);
  const taskItem = task?.contents?.taskItems.find(i => i.name === taskItemName);

  const isAssessmentFixUp = pkgData?.package?.type === 'assessment-fixup';

  // Behaviour for navigating between task items and ensuring animation between them
  const [lastIndex, setLastIndex] = useState(-1);
  const fakePageIndex = page === 'results' ? 10000 : page || 0;
  const reverse = fakePageIndex < lastIndex;
  const openTaskItem = useCallback(
    (page: number | 'results', replace = false) => {
      setLastIndex(fakePageIndex);
      onSetPage(page, replace);
    },
    [onSetPage, setLastIndex],
  );

  // Navigate to the first incomplete item if landing on the root page
  useEffect(() => {
    if (!page && task?.contents?.taskItems && task.contents.taskItems.length > 0) {
      const firstIncompleteItem = task.contents.taskItems.findIndex(itm => !itm.state?.completed);
      const nextPage = firstIncompleteItem === -1 ? 'results' : firstIncompleteItem + 1;
      console.log('Navigating to first incomplete task item:', nextPage);
      openTaskItem(nextPage, true);
    }
  }, [page, task, openTaskItem]);

  if (isLoadingPackages) {
    return <LargeLoading />;
  }
  if (!task) {
    return <NotFound />;
  }

  const actions = {
    onFinish: () => onBack(),
    onContinue: () => {
      for (const start of [page === 'results' ? 0 : page || 0, 0]) {
        for (let i = start; i < (task?.contents?.taskItems?.length || 0); i++) {
          const taskItem = task?.contents?.taskItems[i];
          if (!taskItem?.state?.completed) {
            return openTaskItem(i + 1);
          }
        }
      }
      return openTaskItem('results');
    },
  };

  const content =
    page === 'results' ? (
      <AnimatedPage key="results" reverse={reverse}>
        <Results task={task} {...actions} />
      </AnimatedPage>
    ) : (
      activity &&
      taskItem && (
        <AnimatedPage key={taskItemName} reverse={reverse}>
          <ActivityDeliveryWithUnload
            activity={activity}
            taskItem={taskItem}
            canSkip={(task?.contents?.taskItems?.filter(i => !i.state?.completed).length || 0) > 1}
            showTaskItemTitle={isAssessmentFixUp}
            hidePeriodicTable={isAssessmentFixUp}
            {...actions}
          />
        </AnimatedPage>
      )
    );

  return (
    <Box
      height="100%"
      width="100%"
      p={{ base: 0, sm: 4 }}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        height="100%"
        width="100%"
        flexDirection="column"
        display="flex"
        maxWidth="1600px"
        maxHeight="1400px"
        py={{ base: 0, '2xl': 8 }}
      >
        {task && pkgData?.package && <Title title={`${task.title} - ${pkgData.package.title}`} />}
        <Navigator task={task} selected={page || 0} setSelected={openTaskItem} />
        <ContentAnimationWrapper reverse={reverse}>{content}</ContentAnimationWrapper>
      </Box>
    </Box>
  );
};

const ContentAnimationWrapper = ({
  children,
  reverse,
}: PropsWithChildren<{ reverse: boolean }>) => {
  const container = useRef<HTMLDivElement | null>(null);
  const hasChildren = Boolean(children);

  return (
    <Box
      bg="white"
      rounded={['none', 'md']}
      boxShadow="elevationMedium"
      flex={1}
      style={{ overflow: 'clip' }} // If not supported, fallback to overflow: hidden below
      overflow="hidden"
      // NOTE: Ideally we would use `overflow: clip` but this is a recent addition
      // so is not supported on a lot of browsers.
      //
      // I am finding that the scrollLeft of this container is changing somehow
      // which is causing glitches in the animation. `overflow: clip` should fix
      // it - but instead I am using this hack to ensure that the container is
      // always fixed to the left. You can see this behaviour by removing the
      // `animate` prop on the AnimatedPage component, then examining the scrollLeft
      // of this div element at runtime - you will see it can be non-zero.
      onScroll={() => container.current && (container.current.scrollLeft = 0)}
      position="relative"
      transform="translate(0px, 0px)"
      width="100%"
      ref={container}
    >
      <ContentLoading active={!hasChildren} />
      <AnimatePresence mode="wait" custom={reverse}>
        {children}
      </AnimatePresence>
    </Box>
  );
};

const ContentLoading = ({ active }: { active: boolean }) => (
  <Box
    inset="0 0 0 0"
    position="absolute"
    display="flex"
    alignItems="center"
    justifyContent="center"
    pointerEvents="none"
    opacity={active ? 1 : 0}
    style={{ transitionDelay: active ? '1s' : '0s' }}
    transition={active ? 'opacity 1s ease-in-out' : 'opacity 0.3s ease-in-out'}
  >
    <LargeLoading color="gray.100" />
  </Box>
);

export default TaskView;
