import { Box, Button, Text, Tooltip } from '@chakra-ui/react';
import { faChevronRight, faListUl, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SchemeOfLearning } from '@sparx/api/apis/sparx/planning/v1/sol';
import { TimeOfDay } from '@sparx/api/google/type/timeofday';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { readableStaffNames } from '@sparx/staff-manager';
import { createColumnHelper } from '@tanstack/react-table';
import { useStudents } from 'api/school';
import { GroupWithSettings, useSuspenseGroupsWithSettings } from 'api/scienceschool';
import { useAssignableSols } from 'api/sol';
import { useStaffLookup } from 'api/staff';
import { InterimLock } from 'components/InterimLock';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import { DataTable } from 'components/table/DataTable';
import { getTableEditColumns } from 'components/table/TableColumns';
import React, { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getWeekdayNameShort } from 'utils/weekdays';
import { GroupBulkEdit } from 'views/groups/GroupBulkEdit';

export const GroupListView = () => (
  <SuspenseRoute>
    <InterimLock title="Class manager">
      <SuspenseGroupListView />
    </InterimLock>
  </SuspenseRoute>
);

interface GroupRow extends GroupWithSettings {
  selected?: boolean;
  solName?: string;
  studentCount: number;
  teachers: string[];
}

const SuspenseGroupListView = () => {
  const navigate = useNavigate();
  const groups = useSuspenseGroupsWithSettings();
  const { data: staffLookup } = useStaffLookup({ suspense: true });

  const { data: sols = [] } = useAssignableSols({ suspense: true });
  const solLookup = useMemo(() => {
    const lookup: Dictionary<string, SchemeOfLearning> = {};
    sols.forEach(sol => {
      lookup[sol.name] = sol;
    });
    return lookup;
  }, [sols]);

  const { data: students } = useStudents({ suspense: true });
  const groupCounts = useMemo(() => {
    const lookup: Dictionary<string, number> = {};
    for (const student of students || []) {
      for (const group of student.studentGroupIds) {
        lookup[group] = (lookup[group] || 0) + 1;
      }
    }
    return lookup;
  }, [students]);

  const [selectedGroupNames, setSelectedGroupNames] = useState(new Set<string>());
  const toggleGroup = (checked: boolean, ...ids: string[]) =>
    setSelectedGroupNames(s => {
      const newSet = new Set(s);
      if (checked) ids.forEach(id => newSet.add(id));
      else ids.forEach(id => newSet.delete(id));
      return newSet;
    });

  const filteredGroups: GroupRow[] = useMemo(
    () =>
      groups
        .filter(g => g.type === StudentGroupType.CLASS_SCIENCE)
        .map(s => ({
          ...s,
          selected: selectedGroupNames.has(s.name),
          solName: solLookup[s.scienceSettings.solName]?.displayName,
          studentCount: groupCounts[s.name.split('/')[3]] || 0,
          teachers: s.staff
            .filter(s => !!staffLookup?.[s.staffID])
            .map(s => {
              const { realName, fallback } = readableStaffNames(staffLookup?.[s.staffID]);
              return realName || fallback;
            }),
        })) || [],
    [groups, solLookup, selectedGroupNames, groupCounts, staffLookup],
  );

  const columns = useColumns(toggleGroup);

  const [stickyOffset, setStickyOffset] = useState(0);

  return (
    <PageContainer>
      <PageTitle title="Class Manager" />
      <DataTable
        data={filteredGroups}
        columns={columns}
        defaultSort={[{ id: 'displayName', desc: false }]}
        onRowClick={row => navigate(`/teacher/group/${row.name.split('/')[3]}/details`)}
        rowIsHighlighted={s => (s.selected ? 'gray.50' : 'white')}
        noDataRow={<>No classes to display</>}
        extraHeaderHeight={stickyOffset}
        extraHeader={
          <GroupBulkEdit
            groups={filteredGroups.filter(g => g.selected)}
            setHeight={setStickyOffset}
            rightButton={
              <Button
                colorScheme="buttonTeal"
                onClick={() => navigate('/teacher/mis-sync-import')}
                leftIcon={<FontAwesomeIcon icon={faListUl} />}
              >
                Import and Manage Classes
              </Button>
            }
          />
        }
      />
    </PageContainer>
  );
};

const useColumns = (toggle: (checked: boolean, ...ids: string[]) => void) => {
  const columnHelper = createColumnHelper<GroupRow>();
  return useMemo(
    () => [
      ...getTableEditColumns(columnHelper, g => g.name, toggle),

      columnHelper.accessor('displayName', {
        header: 'Class',
        cell: info => (
          <Text color="blue.900" fontWeight="bold">
            {info.getValue()}
          </Text>
        ),
      }),
      columnHelper.accessor('teachers', {
        header: 'Teacher(s)',
        cell: info => {
          const teachers = info.getValue();
          if (teachers.length === 0) return <NoTeacherChip />;
          return (
            <Box as="span" data-sentry-mask>
              {teachers[0]}
              {teachers.length > 1 && (
                <>
                  , <AllTeachersChip teachers={teachers} />
                </>
              )}
            </Box>
          );
        },
        enableSorting: false,
      }),
      columnHelper.accessor('solName', {
        header: 'Scheme',
        cell: info => (info.getValue() ? <Text>{info.getValue()}</Text> : <NoneSetChip />),
      }),

      columnHelper.accessor('scienceSettings.defaultHomeworkLengthMinutes', {
        header: 'Length (m)',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('scienceSettings.defaultSetDay', {
        header: 'Hand out',
        cell: info => (
          <>
            {formatTime(info.row.original.scienceSettings.defaultSetTime)}{' '}
            {getWeekdayNameShort(info.getValue())}
          </>
        ),
      }),
      columnHelper.accessor('scienceSettings.defaultDueDay', {
        header: 'Hand in',
        cell: info => (
          <>
            {formatTime(info.row.original.scienceSettings.defaultDueTime)}{' '}
            {getWeekdayNameShort(info.getValue())}
          </>
        ),
      }),
      columnHelper.accessor('studentCount', {
        header: 'Students',
        meta: {
          width: 100,
          align: 'center',
          blockCellClick: true,
        },
        cell: info => (
          <Text
            as={Link}
            to={`/teacher/student?group=${info.row.original.name.split('/')[3]}`}
            fontWeight="bold"
            color="gray.600"
            _hover={{ textDecoration: 'underline' }}
            p={2}
            m={-2}
          >
            {info.getValue()}
            <Text display="inline-block" ml={2} mr={3} color="blue.800">
              <FontAwesomeIcon icon={faUsers} />
            </Text>
            <Text display="inline-block" color="blue.500" fontSize="sm">
              <FontAwesomeIcon icon={faChevronRight} />
            </Text>
          </Text>
        ),
      }),
    ],
    [columnHelper, toggle],
  );
};

const formatTime = (tod: TimeOfDay | undefined) => {
  if (!tod) return '-';
  let hours = tod.hours.toString();
  if (hours.length < 2) hours = '0' + hours;
  let minutes = tod.minutes.toString();
  if (minutes.length < 2) minutes = '0' + minutes;
  return `${hours}:${minutes}`;
};

export const NoneSetChip = () => (
  <Box
    bg="red.100"
    display="inline-block"
    color="red.700"
    borderRadius="sm"
    px={2}
    py={0.5}
    my={-0.5}
    fontSize="sm"
  >
    None set
  </Box>
);

export const NoTeacherChip = () => (
  <Box
    bg="red.100"
    display="inline-block"
    color="red.700"
    borderRadius="sm"
    px={2}
    py={0.5}
    my={-0.5}
    fontSize="sm"
  >
    No teacher
  </Box>
);

export const AllTeachersChip = ({ teachers }: { teachers: string[] }) => (
  <Tooltip
    placement="top"
    hasArrow
    label={
      <>
        {teachers.map((n, i) => (
          <Text key={i}>{n}</Text>
        ))}
      </>
    }
  >
    <Box
      bg="gray.100"
      display="inline-block"
      borderRadius="sm"
      px={2}
      py={0}
      my={-0.5}
      fontSize="sm"
    >
      ...
    </Box>
  </Tooltip>
);
