import { Box, BoxProps, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { faArrowsRotate, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressWheel } from 'components/ProgressWheel';
import { Link } from 'react-router-dom';

import { NewYearSetupDisplayState, useNewYearSetupProgress } from '../hooks';

export const NewYearSetupBanner = ({
  displayStatus,
  ...props
}: { displayStatus: NewYearSetupDisplayState } & BoxProps) => {
  const isBefore = displayStatus === 'before';

  if (displayStatus === 'reset-complete') return null;

  return (
    <Box {...props}>
      <Box py={2} px={8} bgColor={isBefore ? 'orange.400' : 'red.500'} borderTopRadius="md">
        <Heading size="md" color="white" textTransform={!isBefore ? 'uppercase' : undefined}>
          {isBefore ? (
            'Prepare for the new academic year'
          ) : (
            <>
              <FontAwesomeIcon icon={faTriangleExclamation} fixedWidth={true} /> Action Required
            </>
          )}
        </Heading>
      </Box>
      <Flex
        bgColor={isBefore ? 'orange.100' : 'red.100'}
        alignItems="center"
        justifyContent="space-between"
        borderBottomRadius="md"
        pb={6}
        px={8}
        position={'relative'}
        overflow={'hidden'}
      >
        {isBefore && (
          <Box position="absolute" bottom={-6} right={-6} transform="rotate(45deg)" opacity={0.1}>
            <FontAwesomeIcon icon={faArrowsRotate} size="8x" />
          </Box>
        )}
        <Box>
          <Box my={4}>
            <Heading size="sm" mb={2} color="black">
              Your school {isBefore ? 'is about to start the' : 'has entered a'} new academic year.
            </Heading>
            <Text fontSize="sm">
              Your <strong>Sparx Leader</strong> or <strong>Head of Department</strong>{' '}
              {isBefore
                ? 'can get a head start setting up your Sparx Science site.'
                : 'needs to get your Sparx Science site ready for new and previous students.'}
            </Text>
          </Box>
          <Button size="md" as={Link} to="/teacher/new-year-setup">
            Follow our guide here
          </Button>
        </Box>
        {!isBefore && <ProgressWheelWrapper />}
      </Flex>
    </Box>
  );
};

const ProgressWheelWrapper = () => {
  const props = useNewYearSetupProgress();
  return <ProgressWheel {...props} />;
};
