import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalOverlay,
  Select,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { faCaretDown, faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTopicLookup } from 'api/content';
import { useAssignmentInsights } from 'api/packages';
import { useWeekForDate } from 'api/school';
import logo from 'app/images/science_blue.svg';
import { useClientEvent } from 'components/ClientEventProvider';
import { LargeLoading } from 'components/loading/LargeLoading';
import React, { PropsWithChildren, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toggleFullscreen, useOnFullScreenChange } from 'utils/fullscreen';
import { findSubject, subjects } from 'utils/subjects';
import { useAssignmentLinker } from 'views/handin/HandInView';
import { NoFollowUpFive } from 'views/insights/InsightsView';

import { FocusSkillContainer } from './FocusSkillContainer';

export const InsightsDisplayView = () => (
  <React.Suspense fallback={<LargeLoading />}>
    <SuspenseInsightsDisplayView />
  </React.Suspense>
);

const skillPositioning = [
  ['1/8'],
  ['1/8', '1/8'],
  ['1/4', '4/7', '1/8'],
  ['1/4', '4/7', '1/4', '4/7'],
  ['1/3', '3/5', '5/7', '1/4', '4/7'],
];

const SuspenseInsightsDisplayView = () => {
  const { group, foundAssignment, assignmentID, groupID, query } =
    useAssignmentLinker(`/teacher/insights/display`);

  const subject = query.subject?.toString() || '';

  const { data } = useAssignmentInsights(assignmentID, subject, { suspense: true });
  const { data: topicLookup } = useTopicLookup({ suspense: true });
  const week = useWeekForDate(foundAssignment?.startTimestamp, { suspense: true });

  const [questionIndex, setQuestionIndex] = useState(0);
  const [showAnswers, setShowAnswers] = useState(0b00000);
  const [focusQuestion, setFocusQuestion] = useState<undefined | number>();

  const [isFullScreen, setIsFullScreen] = useState(false);
  useOnFullScreenChange(
    () => setIsFullScreen(true),
    () => setIsFullScreen(false),
  );

  const maxQuestionIndex = Math.max(...(data?.skills?.map(s => s.questions.length) || []));
  const positions = skillPositioning[Math.min(5, data?.skills.length || 1) - 1];

  const { sendEvent } = useClientEvent();
  const sendInsightsEvent = (action: string, labels?: Dictionary<string, string>) =>
    sendEvent({ category: 'insights_presentation', action }, { assignmentID, groupID, ...labels });

  const controls = (
    <HStack spacing={2}>
      {maxQuestionIndex > 1 && (
        <Select
          width="180px"
          onChange={e => {
            setQuestionIndex(parseInt(e.target.value));
            sendInsightsEvent('toggle_fill', { fill: e.target.value });
          }}
          value={questionIndex}
        >
          {Array.from({ length: maxQuestionIndex }).map((_, i) => (
            <option key={i} value={i}>
              Question set {i + 1}
            </option>
          ))}
        </Select>
      )}

      <Button
        onClick={() => {
          setShowAnswers(showAnswers === 0 ? 0b11111 : 0);
          sendInsightsEvent('show_all_answers', { enabled: showAnswers === 0 ? 'on' : 'off' });
        }}
        colorScheme={showAnswers ? 'blue' : 'gray'}
        width="170px"
      >
        {showAnswers ? 'Hide' : 'Show'} all answers
      </Button>
      <Button
        leftIcon={<FontAwesomeIcon icon={faExpand} />}
        onClick={() => {
          toggleFullscreen();
          sendInsightsEvent('fullscreen_toggle', { fullscreen: isFullScreen ? 'off' : 'on' });
        }}
      >
        {isFullScreen ? 'Exit full screen' : 'Full screen'}
      </Button>
    </HStack>
  );

  const toggleAnswer = (index: number) => {
    setShowAnswers(showAnswers ^ (1 << index));
    sendInsightsEvent('show_answer', {
      index: index.toString(),
      enabled: showAnswers & (1 << index) ? 'off' : 'on',
    });
  };

  return (
    <>
      <Grid
        width="100%"
        height="100%"
        gridTemplateColumns="repeat(6, 1fr)"
        gridTemplateRows="auto repeat(2, 1fr)"
        gridGap={2}
        p={2}
        // Ensure that we rerender children on fullscreen toggle
        key={isFullScreen ? 'fs' : 'nfs'}
      >
        <GridItem gridColumn="1/7" display="flex" alignItems="center">
          <Image
            src={logo}
            alt="Sparx Science"
            height="27px"
            mt="4px"
            ml={3}
            mr={6}
            display={{ base: 'none', lg: 'block' }}
          />
          <Text
            fontWeight="bold"
            fontSize="lg"
            color="blue.900"
            borderRadius="full"
            bg="gray.200"
            px={5}
            py={1}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            Follow-up 5 - {group?.displayName || '?'} - Week {week?.index || '?'}
          </Text>
          <SubjectDropdown subject={subject} />
          <Spacer />
          {controls}
        </GridItem>
        {data?.skills.slice(0, 5).map((skill, index) => (
          <GridWrapper gridColumn={positions[index]} key={index}>
            <FocusSkillContainer
              index={index + 1}
              question={skill.questions[questionIndex]}
              topic={topicLookup?.[skill.topicName]?.topic}
              showAnswers={(showAnswers & (1 << index)) !== 0}
              onToggleAnswer={() => toggleAnswer(index)}
              onOpenLarge={() => {
                setFocusQuestion(index);
                sendInsightsEvent('focus', { index: index.toString(), enabled: 'on' });
              }}
            />
          </GridWrapper>
        ))}
        {data?.skills.length === 0 && (
          <GridItem colSpan={6} rowSpan={2}>
            <Center height="100%">
              <NoFollowUpFive />
            </Center>
          </GridItem>
        )}
      </Grid>

      <Modal
        isOpen={focusQuestion !== undefined}
        onClose={() => {
          setFocusQuestion(undefined);
          sendInsightsEvent('focus', { index: focusQuestion?.toString() || '?', enabled: 'off' });
        }}
      >
        <ModalOverlay />
        {focusQuestion !== undefined && (
          <Box
            position="fixed"
            inset="0 0 0 0"
            display="flex"
            flexDirection="column"
            zIndex={2000}
            p={8}
          >
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              borderRadius="md"
              overflow="hidden"
              boxShadow="xl"
            >
              <FocusSkillContainer
                index={focusQuestion + 1}
                question={data?.skills[focusQuestion].questions[questionIndex]}
                topic={topicLookup?.[data?.skills[focusQuestion]?.topicName || '']?.topic}
                showAnswers={(showAnswers & (1 << focusQuestion)) !== 0}
                onToggleAnswer={() => toggleAnswer(focusQuestion)}
                onCloseOverlay={() => {
                  setFocusQuestion(undefined);
                  sendInsightsEvent('focus', { index: focusQuestion.toString(), enabled: 'off' });
                }}
                nextQuestion={
                  (data?.skills.length || 0) > 1
                    ? () => {
                        const idx = (focusQuestion + 1) % (data?.skills.length || 1);
                        setFocusQuestion(idx);
                        sendInsightsEvent('focus-next', { index: idx.toString(), enabled: 'on' });
                      }
                    : undefined
                }
                prevQuestion={
                  (data?.skills.length || 0) > 1
                    ? () => {
                        const idx =
                          focusQuestion === 0 ? (data?.skills.length || 1) - 1 : focusQuestion - 1;
                        setFocusQuestion(idx);
                        sendInsightsEvent('focus-prev', { index: idx.toString(), enabled: 'on' });
                      }
                    : undefined
                }
              />
            </Box>
          </Box>
        )}
      </Modal>
    </>
  );
};

const GridWrapper = ({ children, gridColumn }: PropsWithChildren<{ gridColumn: string }>) => (
  <GridItem
    gridColumn={gridColumn}
    display="flex"
    flexDirection="column"
    borderRadius="md"
    boxShadow="sm"
    borderWidth="1px"
    borderColor="gray.300"
    overflow="hidden"
    bg="white"
  >
    {children}
  </GridItem>
);

const SubjectDropdown = ({ subject }: { subject: string }) => {
  const [_, setSearchParams] = useSearchParams();
  const setSubject = (subject: string) =>
    setSearchParams(prev => {
      prev.set('subject', subject);
      return prev;
    });

  const subjectData = findSubject(subject);
  if (!subjectData) {
    return null;
  }

  return (
    <Menu>
      <MenuButton
        as={Text}
        ml={2}
        fontWeight="bold"
        fontSize="lg"
        color="blue.900"
        borderRadius="full"
        bg="blue.100"
        _hover={{ cursor: 'pointer', bg: 'blue.200' }}
        px={5}
        py={1}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {subjectData.icon && (
          <Text as="span" mr={3} opacity={0.8}>
            {subjectData.icon}
          </Text>
        )}
        {subjectData.name}
        <Text as="span" ml={3} opacity={0.8}>
          <FontAwesomeIcon icon={faCaretDown} />
        </Text>
      </MenuButton>
      <MenuList py={0} zIndex={5}>
        {subjects.map(({ name, key, icon }) => (
          <MenuItem icon={icon} key={key} onClick={() => setSubject(key)}>
            {name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
