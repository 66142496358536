import { useQuery } from '@tanstack/react-query';
import { activitiesClient } from 'api';
import { queryClient } from 'api/client';
import { updateActivityState } from 'api/packages';
import { useState } from 'react';

export const useTetheringSession = (sessionName: string) => {
  const [lastTetherId, setLastTetherId] = useState('');
  const query = useQuery({
    queryKey: ['tethering', sessionName],
    queryFn: async ({ signal }) =>
      activitiesClient.getTetheringActivity(
        {
          lastTetherId,
          sessionName,
        },
        { abortsignal: signal },
      ).response,
    refetchInterval: 100, // instantly refresh when loaded
    refetchIntervalInBackground: true, // refetch in bg
    onSuccess: data => {
      data.activity && updateActivityState(data.activity);
      setLastTetherId(data.tetherId);
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const restartActivity = async () => {
    setLastTetherId('');
    await queryClient.cancelQueries(['tethering', sessionName]);
  };

  return { ...query, restartActivity };
};
