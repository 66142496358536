import _unsafeMerge from 'ts-deepmerge';

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export const safeMerge = <T extends object>(obj: T, ...patch: Array<DeepPartial<T>>): T =>
  _unsafeMerge.withOptions({ mergeArrays: false }, obj as object, ...(patch as Array<object>)) as T;
