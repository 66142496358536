import { StudentSummaryDetails, useStudentSummaryDetails } from '@sparx/mis-sync-import/src/hooks';
import {
  DuplicateUPNs,
  getSystemOptions,
  joinStringsWithAnd,
} from '@sparx/mis-sync-import/src/utils';

const getStudentDescription = (summaryDetails: StudentSummaryDetails) => {
  const classDescriptions = [];
  for (const c of summaryDetails.currentProductClasses) {
    classDescriptions.push(c.wondeClassName);
  }
  for (const c of summaryDetails.otherProductClasses) {
    let desc = '';
    if (c.subject !== undefined) {
      const system = getSystemOptions(c.subject)?.system;
      if (system) {
        desc = `${system} class `;
      }
    }
    desc += c.wondeClassName;
    classDescriptions.push(desc);
  }

  return (
    (summaryDetails?.name || '?') +
    (classDescriptions.length > 0 ? ` (in ${joinStringsWithAnd(classDescriptions)})` : '')
  );
};

/**
 * An error box that is shown when there is a UPN conflict between two students, giving details of the students
 * involved in the conflict and their classes
 * @param error
 * @constructor
 */
const SyncPlanUPNConflictError = ({ error }: { error: DuplicateUPNs }) => {
  const studentSummaryDetails = useStudentSummaryDetails();
  return (
    <>
      <p>Duplicate UPNs (student UPNs must be unique within your MIS)</p>
      <ul>
        {error.duplicates.map((upnConflict, idx) => {
          const students = upnConflict.message
            .split(',')
            .map(studentWondeID => studentWondeID.trim());
          const studentDescriptions = students
            .map(s => {
              const summaryDetails = studentSummaryDetails.get(s);
              if (summaryDetails) {
                return getStudentDescription(summaryDetails);
              }
              return '';
            })
            .filter(s => s);
          return (
            <li key={idx}>
              <p>UPN {upnConflict.upn} is shared by the following students:</p>
              <ul>
                {studentDescriptions.map((sd, idx) => (
                  <li key={idx}>{sd}</li>
                ))}
              </ul>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default SyncPlanUPNConflictError;
