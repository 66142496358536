import { Button, Select, useDisclosure } from '@chakra-ui/react';
import { GroupSettings } from '@sparx/api/apis/sparx/science/schools/settings/v1/settings';
import { TimeOfDay } from '@sparx/api/google/type/timeofday';
import { GroupWithSettings } from 'api/scienceschool';
import { useAssignableSols } from 'api/sol';
import { BulkEditor } from 'components/bulkeditor/BulkEditor';
import { EditableField } from 'components/form/FormFields';
import React, { ReactNode, useState } from 'react';
import { getWeekdayOptions } from 'utils/weekdays';
import { SaveGroupSettingsModal } from 'views/groups/SaveGroupSettingsModal';
import { getHomeworkLengthOptions } from 'views/planner/components/AssignmentContentsEditor';
import { getTimeOptions } from 'views/planner/components/DatePicker';

interface GroupBulkEditProps {
  groups: GroupWithSettings[];
  setHeight?: (height: number) => void;
  rightButton?: ReactNode;
}

export const GroupBulkEdit = ({ groups, setHeight, rightButton }: GroupBulkEditProps) => {
  const [settings, setSettings] = useState<Partial<GroupSettings>>({});

  const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure();

  return (
    <BulkEditor
      entities={groups}
      settings={settings}
      setSettings={setSettings}
      entityName={['class', 'classes']}
      saveButton={disabled => (
        <Button isDisabled={disabled} colorScheme="buttonTeal" onClick={onOpen}>
          Preview changes
        </Button>
      )}
      setHeight={setHeight}
      rightButton={rightButton}
    >
      <EditGroupSettings settings={settings} setSettings={setSettings} allowEmpty={true} />
      <SaveGroupSettingsModal
        isOpen={isOpen}
        groups={groups.map(g => ({
          ...g,
          scienceSettings: { ...g.scienceSettings, ...settings },
        }))}
        onClose={() => {
          onCloseModal();
        }}
        onSubmit={() => {
          onCloseModal();
          setSettings({});
        }}
        solChanged={!!settings.solName}
      />
    </BulkEditor>
  );
};

interface EditGroupSettingsProps {
  settings: Partial<GroupSettings>;
  setSettings: (settings: Partial<GroupSettings>) => void;
  allowEmpty?: boolean;
}

type KeysOfType<T, V> = keyof {
  [P in keyof T as T[P] extends V ? P : never]: V;
};

export const EditGroupSettings = ({
  settings,
  setSettings,
  allowEmpty,
}: EditGroupSettingsProps) => {
  const { data: sols } = useAssignableSols({ suspense: true });

  const NO_CHANGE_TEXT = '- No change -';

  const patchSettings = (s: Partial<GroupSettings>) => {
    const ns = { ...settings, ...s };
    for (const [k, v] of Object.entries(ns)) {
      if (v === undefined) {
        delete ns[k as keyof GroupSettings]; // clear out undefines
      }
    }
    setSettings(ns);
  };

  const registerNumber = (name: KeysOfType<GroupSettings, number>) => ({
    value: settings[name] || 0,
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (e.target.value !== NO_CHANGE_TEXT) {
        patchSettings({ [name]: parseInt(e.target.value) });
      } else if (allowEmpty) {
        patchSettings({ [name]: undefined });
      }
    },
  });

  const registerHours = (name: 'defaultSetTime' | 'defaultDueTime') => ({
    value: settings[name]?.hours ?? 0,
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (e.target.value !== NO_CHANGE_TEXT) {
        patchSettings({ [name]: TimeOfDay.create({ hours: parseInt(e.target.value) }) });
      } else if (allowEmpty) {
        patchSettings({ [name]: undefined });
      }
    },
  });

  const registerString = (name: KeysOfType<GroupSettings, string>) => ({
    value: settings[name] ?? NO_CHANGE_TEXT,
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      if (e.target.value !== NO_CHANGE_TEXT) {
        patchSettings({ [name]: e.target.value });
      } else if (allowEmpty) {
        patchSettings({ [name]: undefined });
      }
    },
  });

  const emptyOption = allowEmpty ? <option value={undefined}>{NO_CHANGE_TEXT}</option> : null;

  return (
    <>
      <EditableField span={2} name="Scheme of Learning">
        <Select {...registerString('solName')}>
          {emptyOption}
          <option value="">None</option>
          {sols?.map(s => (
            <option value={s.name} key={s.name}>
              {s.displayName}
            </option>
          ))}
        </Select>
      </EditableField>
      <EditableField name="Homework length" span={2}>
        <Select {...registerNumber('defaultHomeworkLengthMinutes')}>
          {emptyOption}
          {getHomeworkLengthOptions(settings.defaultHomeworkLengthMinutes)}
        </Select>
      </EditableField>
      <EditableField name="Hand out">
        <Select {...registerNumber('defaultSetDay')}>
          {emptyOption}
          {getWeekdayOptions(settings.defaultSetDay)}
        </Select>
      </EditableField>
      <EditableField>
        <Select {...registerHours('defaultSetTime')}>
          {emptyOption}
          {getTimeOptions(settings.defaultSetTime?.hours ?? 9)}
        </Select>
      </EditableField>
      <EditableField name="Hand in">
        <Select {...registerNumber('defaultDueDay')}>
          {emptyOption}
          {getWeekdayOptions(settings.defaultDueDay)}
        </Select>
      </EditableField>
      <EditableField>
        <Select {...registerHours('defaultDueTime')}>
          {emptyOption}
          {getTimeOptions(settings.defaultDueTime?.hours ?? 15)}
        </Select>
      </EditableField>
    </>
  );
};
