import {
  ApplySyncPlanRequest,
  ApplySyncPlanResponse,
  CreateSyncPlanRequest,
  CreateSyncPlanResponse,
  IsSyncInProgressResponse,
} from '@sparx/api/apis/sparx/misintegration/wondesync/v1/wondesync';
import {
  Class,
  ListClassesResponse,
  ListSubjectsResponse,
  Subject,
} from '@sparx/api/apis/sparx/misintegration/wondewitch/v1/wondewitch';
import {
  Group,
  ListGroupsResponse,
  ListYearGroupsResponse,
} from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import {
  ListStudentsResponse,
  Student,
} from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { YearGroup } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useState } from 'react';

import { useMisSyncContext } from './Context';
import { WONDE_REGISTRATION_GROUP_SUBJECT_ID } from './types';
import { schoolIDFromName } from './utils';

export const schoolDataQueryKey = 'school-data';
export const initialSyncInProgressKey = 'initial-sync-in-progress';
export const manualSyncInProgressKey = 'manual-sync-in-progress';
export const activeSyncInProgressKey = 'active-sync-in-progress';

/**
 * School Data Queries
 */

// Get student groups in the school
export function useGetStudentGroups(
  schoolName: string,
  options?: UseQueryOptions<ListGroupsResponse, Error, Group[]>,
) {
  const { groupsClient } = useMisSyncContext();
  return useQuery({
    queryKey: [schoolDataQueryKey, 'useGetStudentGroups', schoolName],
    queryFn: () =>
      groupsClient.listGroups({
        parent: schoolName,
        includeHomeworkPlans: false,
        includeArchived: true,
        groupIds: [],
      }).response,
    staleTime: Infinity,
    select: data => data.groups,
    enabled: schoolName !== '',
    ...options,
  });
}

// List all students in the school, returned in a lookup map by studentId
export function useListStudents(
  schoolId: string,
  options?: UseQueryOptions<ListStudentsResponse, Error, Record<string, Student>>,
) {
  const { studentsClient } = useMisSyncContext();
  return useQuery({
    queryKey: [schoolDataQueryKey, 'useListStudents', schoolId],
    queryFn: () => studentsClient.listStudents({ schoolId }).response,
    staleTime: Infinity,
    select: data =>
      data.students.reduce<Record<string, Student>>((acc, s) => {
        acc[s.studentId] = s;
        return acc;
      }, {}),
    enabled: schoolId !== '',
    ...options,
  });
}

// List all year groups in the school, returned in a lookup map by yearGroupID
export function useListYearGroups(
  schoolName: string,
  options?: UseQueryOptions<ListYearGroupsResponse, Error, Record<string, YearGroup>>,
) {
  const { groupsClient } = useMisSyncContext();
  return useQuery({
    queryKey: [schoolDataQueryKey, 'useListYearGroups', schoolName],
    queryFn: () =>
      groupsClient.listYearGroups({
        schoolName,
      }).response,
    select: data =>
      data.yearGroups.reduce<Record<string, YearGroup>>((acc, yg) => {
        acc[yg.yearGroupID] = yg;
        return acc;
      }, {}),
    staleTime: Infinity,
    enabled: schoolName !== '',
    ...options,
  });
}

/**
 * Wonde Data Queries
 */

// List all classes from the school's MIS
export function useListMisClasses(
  schoolId: string,
  options?: UseQueryOptions<ListClassesResponse, Error, Class[]>,
) {
  const { wondeSyncClient } = useMisSyncContext();
  return useQuery({
    queryKey: ['useListMisClasses', schoolId],
    queryFn: () =>
      wondeSyncClient.listClasses({
        schoolId,
        subjectWondeId: '',
      }).response,
    select: data => data.classes,
    staleTime: Infinity,
    enabled: schoolId !== '',
    ...options,
  });
}

export function useListMisRegistrationGroups(
  schoolId: string,
  options?: UseQueryOptions<ListClassesResponse, Error, Class[]>,
) {
  const { wondeSyncClient } = useMisSyncContext();
  return useQuery({
    queryKey: ['useListMisRegistrationGroups', schoolId],
    queryFn: () =>
      wondeSyncClient.listClasses({
        schoolId,
        subjectWondeId: WONDE_REGISTRATION_GROUP_SUBJECT_ID,
      }).response,
    select: data =>
      data.classes.map(c => ({
        ...c,
        subjectId: WONDE_REGISTRATION_GROUP_SUBJECT_ID,
      })),
    staleTime: Infinity,
    enabled: schoolId !== '',
    ...options,
  });
}

// Get all subjects from the school's MIS
export function useListWondeSubjects(
  schoolId: string,
  options?: UseQueryOptions<ListSubjectsResponse, Error, Record<string, Subject>>,
) {
  const { wondeSyncClient } = useMisSyncContext();
  return useQuery({
    queryKey: ['useListSubjects', schoolId],
    queryFn: () => wondeSyncClient.listSubjects({ schoolId }).response,
    select: data =>
      data.subjects.reduce((acc: Record<string, Subject>, s) => {
        acc[s.id] = s;
        return acc;
      }, {}),
    staleTime: Infinity,
    enabled: schoolId !== '',
    ...options,
  });
}

/**
 * Mutation for creating a sync plan.
 */
export function useCreateSyncPlan(
  options?: UseMutationOptions<CreateSyncPlanResponse, Error, CreateSyncPlanRequest>,
) {
  const { wondeSyncClient } = useMisSyncContext();

  return useMutation({
    mutationKey: ['create-sync-plan'],
    mutationFn: req => wondeSyncClient.createSyncPlan(req).response,
    ...options,
  });
}

/**
 * Mutation for applying a sync plan.
 */
export function useApplySyncPlan(
  options?: UseMutationOptions<ApplySyncPlanResponse, Error, ApplySyncPlanRequest>,
) {
  const { wondeSyncClient } = useMisSyncContext();

  return useMutation({
    mutationKey: ['apply-sync-plan'],
    mutationFn: req => wondeSyncClient.applySyncPlan(req).response,
    ...options,
  });
}

type SyncInProgressOptions = Omit<
  UseQueryOptions<
    IsSyncInProgressResponse,
    Error,
    { inProgress: boolean; lastSyncFailed: boolean }
  >,
  'queryKey'
>;

/**
 * Query for checking the current sync status of the school. This requires a queryKey due to the
 * usage pattern being different in each case. Having separate query keys means that we can track
 * the state independantly of other checks at different steps of the syncing flow.
 */
export function useSyncInProgress(queryKey: QueryKey, options?: SyncInProgressOptions) {
  const { wondeSyncClient, school } = useMisSyncContext();
  const schoolId = schoolIDFromName(school?.name);

  return useQuery({
    queryKey: queryKey,
    queryFn: () => wondeSyncClient.isSyncInProgress({ schoolId }).response,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    ...options,
    enabled: !!schoolId && (options?.enabled ?? true),
  });
}

/**
 * This is a wrapper around useSyncInProgress which will automatically poll to check if there is a
 * remote sync until it gets a response that indicates
 */
export const useAutoPollingSyncInProgress = (
  queryKey: QueryKey,
  startPolling: boolean,
  options?: SyncInProgressOptions,
) => {
  const [poll, setPoll] = useState(startPolling);

  const query = useSyncInProgress(queryKey, {
    refetchInterval: 5000,
    ...options,
    enabled: poll,
    onSuccess: data => {
      setPoll(data.inProgress);
      options?.onSuccess?.(data);
    },
    onError: () => setPoll(false),
  });

  return {
    ...query,
    startPolling: () => setPoll(true),
  };
};
