import { Checkbox } from '@chakra-ui/react';
import { ColumnHelper } from '@tanstack/react-table';
import { RowEditButton } from 'components/table/RowEditButton';
import React from 'react';

export const getTableEditColumns = <T extends { selected?: boolean }>(
  columnHelper: ColumnHelper<T>,
  key: (value: T) => string,
  toggle: (checked: boolean, ...ids: string[]) => void,
) => {
  return [
    columnHelper.display({
      id: 'checkbox',
      header: info => (
        <Checkbox
          size="lg"
          colorScheme="white"
          p={4}
          m={-4}
          isChecked={info.table.getRowModel().rows.every(r => r.original.selected)}
          onChange={e =>
            toggle(e.target.checked, ...info.table.getRowModel().rows.map(r => key(r.original)))
          }
        />
      ),
      cell: info => (
        <Checkbox
          size="lg"
          p={4} // Hit slop
          m={-4} // Hit slop
          isChecked={info.row.original.selected}
          key={key(info.row.original)}
          onChange={e => toggle(e.target.checked, key(info.row.original))}
        />
      ),
      meta: { align: 'center', isElement: true, blockClick: true, width: 60 },
    }),
    columnHelper.display({
      header: 'Edit',
      cell: () => <RowEditButton />,
      enableSorting: false,
      meta: {
        align: 'center',
        width: 60,
      },
    }),
  ];
};
