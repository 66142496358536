import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { IdenticalClassesModal } from '@sparx/mis-sync-import/src/MisSyncImport/components/IdenticalClassesModal';
import { parseSubjectName } from '@sparx/mis-sync-import/src/MisSyncImport/components/MISClassesPanel/utils';
import { useSyncConfig } from '@sparx/mis-sync-import/src/MisSyncImport/context/config';
import checkboxStyles from '@sparx/mis-sync-import/src/shared-styles/Checkbox.module.css';
import tableStyles from '@sparx/mis-sync-import/src/shared-styles/Tables.module.css';
import { WondeClass, WondeData } from '@sparx/mis-sync-import/src/types';
import { deleteFromSet, getSystemOptions } from '@sparx/mis-sync-import/src/utils';
import { Checkbox } from '@sparx/sparx-design/components';
import { TriangleExclamationIcon } from '@sparx/sparx-design/icons';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';

import styles from './MISClassesPanel.module.css';

interface MISClassesTableProps {
  selectedClasses: Set<WondeClass>;
  setSelectedClasses: (selectedClasses: Set<WondeClass>) => void;
  misData: WondeData;
  filteredWondeClasses: WondeClass[];
  existingClassWondeIDs: Set<string>;
  identicalClasses: Set<string>;
  groupsAddedInOtherSubjects: Record<string, Group>;
}

/**
 * Table for displaying classes from the MIS
 * @param selectedClasses
 * @param setSelectedClasses
 * @param misData
 * @param wondeClasses
 * @param existingClassWondeIDs
 * @param identicalClasses
 * @param groupsAddedInOtherSubjects
 * @constructor
 */
export const MISClassesTable = ({
  selectedClasses,
  setSelectedClasses,
  misData,
  filteredWondeClasses,
  existingClassWondeIDs,
  identicalClasses,
  groupsAddedInOtherSubjects,
}: MISClassesTableProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const { syncConfig } = useSyncConfig();
  // Function to determine whether a Wonde Class is in the list of classes to add, in which case it
  // should be styled differently in the table.
  const wondeClassInClassesToAdd = useCallback(
    (clsID: string) => !!syncConfig.classesToAdd.find(cta => cta.id === clsID),
    [syncConfig.classesToAdd],
  );

  // Classes that are not already in the list of classes to add or in the list of existing classes
  const selectableWondeClasses = useMemo(
    () =>
      filteredWondeClasses.filter(
        c =>
          !wondeClassInClassesToAdd(c.id) &&
          !existingClassWondeIDs.has(c.id) &&
          !groupsAddedInOtherSubjects[c.id],
      ),
    [
      filteredWondeClasses,
      wondeClassInClassesToAdd,
      existingClassWondeIDs,
      groupsAddedInOtherSubjects,
    ],
  );

  return (
    <>
      {modalOpen && <IdenticalClassesModal onClose={() => setModalOpen(false)} />}
      <div className={tableStyles.Container}>
        <table className={tableStyles.Table}>
          <thead className={tableStyles.TableHeader}>
            <tr>
              <CheckboxCell
                checked={
                  !!selectableWondeClasses.length &&
                  selectedClasses.size === selectableWondeClasses.length
                }
                onCheckedChange={e => {
                  if (e) {
                    setSelectedClasses(new Set(selectableWondeClasses));
                  } else {
                    setSelectedClasses(new Set());
                  }
                }}
                inverted={true}
              />
              <th className={styles.NameHeaderCell}>Class</th>
              <th>Year Group</th>
              <th>Subject</th>
              <th>Students</th>
            </tr>
            {identicalClasses.size > 0 && (
              <tr className={styles.WarningRow}>
                <td colSpan={5}>
                  <TriangleExclamationIcon />
                  Your selection includes classes with identical students.
                  <button onClick={() => setModalOpen(true)}>More info</button>
                </td>
              </tr>
            )}
          </thead>

          <tbody>
            {filteredWondeClasses.map(c => {
              const otherSubjectImportedClass = groupsAddedInOtherSubjects[c.id];
              const isDisabled =
                wondeClassInClassesToAdd(c.id) ||
                existingClassWondeIDs.has(c.id) ||
                !!otherSubjectImportedClass;
              return (
                <tr
                  key={c.id}
                  className={classNames(tableStyles.TableBodyRow, styles.TableBodyRow, {
                    [tableStyles.Active]: selectedClasses.has(c),
                    [tableStyles.Disabled]: isDisabled,
                    [styles.TableBodyRowHover]: !isDisabled,
                  })}
                  onClick={() => {
                    if (isDisabled) {
                      return;
                    }
                    setSelectedClasses(
                      selectedClasses.has(c)
                        ? deleteFromSet(selectedClasses, c)
                        : new Set(selectedClasses).add(c),
                    );
                  }}
                >
                  <CheckboxCell
                    checked={selectedClasses.has(c)}
                    disabled={isDisabled}
                    onCheckedChange={e => {
                      if (e) {
                        setSelectedClasses(new Set(selectedClasses).add(c));
                      } else {
                        setSelectedClasses(deleteFromSet(selectedClasses, c));
                      }
                    }}
                  />
                  <td className={styles.ClassNameCell}>
                    <div>
                      <span className={styles.WarningTriangleContainer}>
                        {identicalClasses.has(c.id) && <TriangleExclamationIcon />}
                      </span>
                      <div>
                        {c.name}
                        {otherSubjectImportedClass && (
                          <div className={styles.AlreadyImportedSubtext}>
                            Imported in {getSystemOptions(otherSubjectImportedClass.type).system}
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>{c.estimatedYearGroup ? `Year ${c.estimatedYearGroup}` : 'Unknown'}</td>
                  <td>{parseSubjectName(misData.wondeSubjects[c.subjectId].name)}</td>
                  <td>{c.students.length}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const CheckboxCell = ({
  disabled,
  checked,
  onCheckedChange,
  inverted,
}: {
  disabled?: boolean;
  checked: boolean;
  onCheckedChange: (val: boolean) => void;
  inverted?: boolean;
}) => (
  <td className={styles.CheckboxCell}>
    <Checkbox
      className={classNames(checkboxStyles.Checkbox, {
        [checkboxStyles.CheckboxDisabled]: disabled,
      })}
      checked={checked}
      disabled={disabled}
      onCheckedChange={onCheckedChange}
      variant={inverted ? 'inverted' : 'default'}
    />
  </td>
);
