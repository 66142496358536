import { Alert, AlertIcon, Box, Text } from '@chakra-ui/react';
import {
  NewStaffDetail as NewStaffDetailComponent,
  StaffDetailFormProps,
  UpdateStaffDetail as UpdateStaffDetailComponent,
} from '@sparx/staff-manager';
import { useSession, useUserType } from 'api/sessions';
import { NotFound } from 'components/errorpages/NotFound';
import { PageSubmitFooter, useFormId } from 'components/form/FormFields';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useOverlayContext } from 'views/settings/Overlay';
import { UserSettings } from 'views/settings/Settings';

export const StaffDetailView = ({ isNew }: { isNew: boolean }) => (
  <SuspenseRoute>{isNew ? <NewStaffDetail /> : <UpdateStaffDetail />}</SuspenseRoute>
);

const UpdateStaffDetail = () => {
  const params = useParams();
  const staffID = params.staffID || '';
  const { isSparxStaff } = useUserType();

  return (
    <UpdateStaffDetailComponent
      staffID={staffID}
      notFoundComponent={<NotFound />}
      isSparxStaff={isSparxStaff}
    >
      {props => <Form {...props} />}
    </UpdateStaffDetailComponent>
  );
};

const NewStaffDetail = () => (
  <NewStaffDetailComponent>{props => <Form isNew={true} {...props} />}</NewStaffDetailComponent>
);

interface FormProps extends StaffDetailFormProps {
  isNew?: boolean;
}

const Form = ({
  form,
  onSubmit,
  name,
  isDirty,
  saveDisabled,
  isLoading,
  isNew,
  warningText,
}: FormProps) => {
  const backLink = `/teacher/staff`;

  const params = useParams();
  const staffId = params.staffID || '';
  const { data: user } = useSession();
  const showUserSettings = staffId === user?.sparxUserId;

  const {
    overlayColour,
    setOverlayColour,
    saveOverlayColour,
    unsavedChanges,
    revertUnsavedChanges,
  } = useOverlayContext();

  // on unmount, revert any unsaved changes
  useEffect(() => {
    return () => {
      revertUnsavedChanges();
    };
  }, [revertUnsavedChanges]);

  const warn =
    warningText.length > 0 ? (
      <Alert
        px={3}
        py={2}
        mt={1}
        status="warning"
        borderRadius="md"
        width="inherit"
        mr="auto"
        backgroundColor="transparent"
      >
        <AlertIcon />
        <Box>
          {warningText.map((s, i) => (
            <Text key={i} fontSize="sm">
              {s}
            </Text>
          ))}
        </Box>
      </Alert>
    ) : null;

  const formID = useFormId();

  return (
    <PageContainer>
      <Box as="form" id={formID} onSubmit={onSubmit} data-sentry-mask>
        <PageTitle title={name} backButton={isNew ? undefined : backLink} />
        {form}
      </Box>
      {showUserSettings && (
        <Box mt={5}>
          <UserSettings currentValue={overlayColour} isTeacher={true} onUpdate={setOverlayColour} />
        </Box>
      )}
      <PageSubmitFooter
        formID={formID}
        isDirty={isDirty}
        saveDisabled={saveDisabled}
        isLoading={isLoading}
        backLink={backLink}
        warningText={warn}
        additionalSaveHandler={() => saveOverlayColour(overlayColour)}
        additionalSaveHandlerDisabled={!unsavedChanges}
      />
    </PageContainer>
  );
};
