import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { PropsWithChildren, ReactNode } from 'react';

export const Tooltip = ({
  label,
  contentProps,
  children,
  portalContainerRef,
  ...props
}: PropsWithChildren<
  {
    label: ReactNode;
    contentProps?: PopoverContentProps;
    portalContainerRef?: React.RefObject<HTMLElement | null>;
  } & Omit<PopoverProps, 'children'>
>) => {
  if (!label) {
    return <>{children}</>;
  }
  return (
    <Popover trigger="hover" autoFocus={false} {...props}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal containerRef={portalContainerRef}>
        <PopoverContent width="unset" boxShadow="elevationMedium" {...contentProps}>
          <PopoverArrow />
          <PopoverBody>{label}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export const InlineTextTooltip = ({
  text,
  portalContainerRef,
  children,
}: PropsWithChildren<{
  text?: string;
  portalContainerRef?: React.RefObject<HTMLElement | null>;
}>) => {
  if (!children) {
    return <Box as="span">{text}</Box>;
  }
  return (
    <Tooltip label={children} portalContainerRef={portalContainerRef}>
      <Box
        as="span"
        textDecoration="underline"
        textDecorationStyle="dotted"
        textUnderlineOffset={2}
        cursor="help"
        _hover={{
          textDecorationStyle: 'solid',
        }}
      >
        {text}
      </Box>
    </Tooltip>
  );
};
