import { HStack, Tab, TabList, TabProps, Tabs, Text } from '@chakra-ui/react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useStaffContext } from '../Context';

export const TabBar = ({ index, warning }: { index: 0 | 1; warning: boolean }) => {
  const { onSwitchTab, styles } = useStaffContext();
  const tabProps: TabProps = {
    bg: 'white',
    fontWeight: 'bold',
    _selected: { bg: styles.headerBackgroundColor, color: 'white' },
  };

  return (
    <Tabs mb={4} index={index} onChange={onSwitchTab} variant="enclosed" colorScheme="buttonTeal">
      <TabList as={HStack} spacing={2} borderBottomWidth={2}>
        <Tab key="STAFF" {...tabProps}>
          <Text>Staff</Text>
        </Tab>
        <Tab key="KEY_CONTACTS" {...tabProps}>
          <HStack>
            <Text sx={{ textTransform: 'none' }}>Key contacts</Text>
            {warning && <FontAwesomeIcon icon={faExclamationTriangle} color="orange" />}
          </HStack>
        </Tab>
      </TabList>
    </Tabs>
  );
};
