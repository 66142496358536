import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stat,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Topic } from '@sparx/api/apis/sparx/content/v2/curriculum';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import {
  ActivityEntry,
  AssignmentActivityEntry,
  ILActivityEntry,
  useDailyActivityFeed,
} from 'api/activityfeed';
import { useTopicLookup } from 'api/content';
import { useAssignment } from 'api/planner';
import { useWeekForDate } from 'api/school';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import { format, startOfMonth, subMonths } from 'date-fns';
import React, { ReactNode, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useResourceSubject } from 'utils/subjects';

import { StatBox } from './StudentSummaryView';

export const StudentActivityFeed = ({ studentID }: { studentID: string }) => {
  const [monthIndex, setMonthIndex] = useState(0);
  const months = useMemo(() => {
    let monthStart = startOfMonth(new Date());
    const months: Date[] = [monthStart];
    while (months.length < 6) {
      monthStart = subMonths(monthStart, 1);
      months.push(monthStart);
    }
    return months;
  }, []);

  return (
    <StatBox>
      <Text fontWeight="bold" color="gray.600">
        Activity feed
      </Text>
      <Tabs index={monthIndex} onChange={setMonthIndex}>
        <TabList>
          {months.map((month, idx) => (
            <Tab key={idx} value={idx}>
              {format(month, 'MMMM yy')}
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <Box>
        <SuspenseRoute>
          <MonthFeed studentID={studentID} selectedMonth={months[monthIndex] || new Date()} />
        </SuspenseRoute>
      </Box>
      <Text fontSize="xs" textAlign="right" mt={4} mb={-2}>
        * Only showing data for the last 6 months.
      </Text>
    </StatBox>
  );
};

const MonthFeed = ({ studentID, selectedMonth }: { studentID: string; selectedMonth: Date }) => {
  const { data: activityFeed } = useDailyActivityFeed(studentID, selectedMonth, {
    suspense: false,
  });

  return (
    <VStack alignItems="stretch" mt={4} spacing={4}>
      {!activityFeed || activityFeed.length === 0 ? (
        <Text>No activity</Text>
      ) : (
        activityFeed.map((act, idx) => (
          <Flex key={idx}>
            <Flex alignItems="center" flexDirection="column">
              <Box w={5} h={5} bg={`gray.300`} borderRadius="full" position="relative" />
              <Box
                w={1}
                flex={1}
                transition="background-image 5s ease-in-out"
                bgGradient={`linear(to-b, gray.300, gray.300)`}
              />
            </Flex>
            <VStack flex={1} alignItems="stretch" shouldWrapChildren>
              <Text fontSize="sm" textAlign="left" ml={2}>
                {format(act.day, 'EEEE do MMMM')}
              </Text>
              {act.entries.map((entry, i) => {
                return entry.type === 'assignment' ? (
                  <AssignmentActivity key={i} entry={entry} studentID={studentID} />
                ) : (
                  <ILActivity key={i} entry={entry} />
                );
              })}
            </VStack>
          </Flex>
        ))
      )}
    </VStack>
  );
};

const toMinutes = (seconds: number) => {
  const ltMin = seconds < 60 && seconds > 0;
  const mins = ltMin ? 1 : Math.floor(seconds / 60);
  return (
    <>
      {mins} min{mins !== 1 && 's'}
    </>
  );
};

const AssignmentActivity = ({
  entry,
  studentID,
}: {
  entry: AssignmentActivityEntry;
  studentID: string;
}) => {
  const assignmentID = entry.assignmentName.split('/')[1];
  const { data: assignment } = useAssignment(assignmentID, {
    suspense: true,
  });
  const week = useWeekForDate(assignment?.startTimestamp, { suspense: true });

  const title = (
    <Flex direction="column" flex="unset">
      <Text fontSize="lg" fontWeight="bold">
        Week {week?.index} Homework
      </Text>
      {assignment?.endTimestamp && (
        <Text fontSize="xs" color="gray.400" ml={0}>
          Due {format(Timestamp.toDate(assignment.endTimestamp), 'EEE do MMMM HH:mm')}
        </Text>
      )}
    </Flex>
  );

  const ansHistory = (
    <Button
      as={Link}
      to={`/teacher/handin/answers?assignment=${assignmentID}&user=${studentID}`}
      state={{ back: `/teacher/student/${studentID}/summary` }}
    >
      Answer history{' '}
      <Text as="span" fontSize="xs" ml={1} mb={-0.5}>
        <FontAwesomeIcon icon={faChevronRight} />
      </Text>
    </Button>
  );

  return <ActivityBox left={title} right={ansHistory} entry={entry} />;
};

const TopicWithCode = ({ topic }: { topic: Topic }) => {
  const subject = useResourceSubject(topic.name, {
    suspense: true,
  });

  const Icon = subject?.iconComponent;

  return (
    <>
      {Icon && <Icon height="16px" width="16px" mr={2} mb={1} />}
      {topic.displayName}{' '}
      <Text as="span" color="gray.400">
        - {topic.code}
      </Text>
    </>
  );
};

const ILActivity = ({ entry }: { entry: ILActivityEntry }) => {
  const { data: topicSummaries } = useTopicLookup({ suspense: true });

  const topicArray = Array.from(entry.topics.values());
  const topics = topicArray.map((name, idx) => {
    const topic = topicSummaries?.[name]?.topic;
    return <Text key={idx}>{topic ? <TopicWithCode topic={topic} /> : 'Unknown topic'}</Text>;
  });

  const title = (
    <Text fontSize="lg" fontWeight="bold">
      Independent Learning
    </Text>
  );

  const initialRef = React.useRef(null);
  const practicedTopics =
    topics.length > 0 ? (
      <Popover placement="top" initialFocusRef={initialRef}>
        <PopoverTrigger>
          <Button
            variant="unstyled"
            color="blue.500"
            textDecoration="underline"
            textDecorationStyle="dotted"
            textUnderlineOffset={2}
            cursor="help"
          >
            Topics practiced
          </Button>
        </PopoverTrigger>
        <PopoverContent ref={initialRef} _focus={{ boxShadow: 'none' }}>
          <PopoverArrow />
          <PopoverBody overflowY="auto" maxH="60vh">
            {topics}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    ) : null;

  return <ActivityBox left={title} right={practicedTopics} entry={entry} />;
};

const ActivityBox = ({
  left,
  right,
  entry,
}: {
  left: ReactNode;
  right: ReactNode;
  entry: ActivityEntry;
}) => (
  <Flex
    alignItems="center"
    borderColor="gray.100"
    borderWidth={1}
    borderRadius="lg"
    bg="white"
    overflow="hidden"
    p={4}
    justifyContent="space-between"
    mx={2}
  >
    <Box flex={1} textAlign="left">
      {left}
    </Box>
    <Stat flex="unset" mx={4}>
      <StatLabel>Questions correct</StatLabel>
      <StatNumber fontSize="large">{entry.correctActivities}</StatNumber>
    </Stat>
    <Stat flex="unset" mx={4}>
      <StatLabel>Time spent</StatLabel>
      <StatNumber fontSize="large">{toMinutes(entry.totalSeconds)}</StatNumber>
    </Stat>
    <Box width={40} ml={2}>
      {right}
    </Box>
  </Flex>
);
