import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Flex,
  Hide,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ConsolidationTopicAction,
  ConsolidationTopicAction_Action,
} from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { TopicWithStrand } from 'api/content';
import { ConsolidationTopicData, useUpdateConsolidationTopicActions } from 'api/planner';
import { LargeLoading } from 'components/loading/LargeLoading';
import { Warning } from 'components/warning';
import React, { useEffect, useMemo } from 'react';

import { TopicDisplayDetails } from '../components/TopicPanel';
import { useTopicSearch } from '../components/topicSearch';
import { usePlannerContext } from '../Context';
import { useManageConsolidationTopicsModal } from './Context';

export const ManageConsolidationTopicsModal = () => {
  const {
    isOpen,
    onClose: _onClose,
    groupName,
    motionPreset,
    setMotionPreset,
  } = useManageConsolidationTopicsModal();
  const {
    consolidation: {
      data: consolidationData = { serverState: {}, topics: [] },
      isLoading: loadingData,
      isError: actionsError,
      refetch: refetchActions,
    },
  } = usePlannerContext();
  const unsavedDisclosure = useDisclosure();

  const update = useUpdateConsolidationTopicActions();

  const [changedtopics, setChangedTopics] = React.useState<string[]>([]);

  // Reset the changed topics when the modal is opened
  useEffect(() => {
    if (isOpen) {
      refetchActions();
      setChangedTopics([]);
      //reset the motion preset, so we always close the same way
      setMotionPreset('scale');
    }
  }, [isOpen, setMotionPreset, refetchActions]);

  const toggleTopic = (topicName: string) => {
    setChangedTopics(prevTopics =>
      prevTopics.includes(topicName)
        ? prevTopics.filter(t => t !== topicName)
        : [...prevTopics, topicName],
    );
  };

  const doClose = () => {
    unsavedDisclosure.onClose();
    _onClose();
    update.reset();
  };

  const onClose = () => {
    if (update.isLoading) {
      return;
    }
    if (changedtopics.length > 0) {
      unsavedDisclosure.onOpen();
      return;
    }
    doClose();
  };

  const onSave = async () => {
    const changes = makeActionUpdates(changedtopics, consolidationData.serverState);
    await update.mutateAsync({ groupName, changes });
    doClose();
  };

  // If there are >=3 topics, and < 3 topics in consolidation, show a warning
  const showWarning = useMemo(() => {
    if (consolidationData.topics.length < 3) return false;

    let includedCount = 0;
    for (const ct of Object.values(consolidationData.serverState)) {
      const toggled = changedtopics.includes(ct.topicName);
      if (toggled ? !ct.included : ct.included) {
        includedCount++;
        if (includedCount >= 3) return false;
      }
    }
    return true;
  }, [consolidationData, changedtopics]);

  const unsavedCloseRef = React.useRef<HTMLButtonElement>(null);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="5xl"
        isCentered={true}
        autoFocus={false}
        scrollBehavior="inside"
        motionPreset={motionPreset}
      >
        <ModalOverlay />
        <ModalContent height="85vh" overflow="hidden">
          <ModalHeader display="flex" justifyContent="space-between">
            <Text>Manage Consolidation topics</Text>
          </ModalHeader>
          <ModalBody display="flex" flexDirection="column" fontSize={{ base: 'sm', md: 'md' }}>
            <Text mb={1}>
              Each week, Sparx automatically provides spaced retrieval of topics from previous weeks
              in the Consolidation section of homework.
            </Text>
            <Text mb={1}>
              You can exclude a topic below to prevent it from appearing in the Consolidation
              section of <strong>all future homework</strong>.
            </Text>
            <Text mb={4}>Topics are added to this list each time homework is set.</Text>
            {actionsError ? (
              <Warning status="error" mt={6} width="unset" alignSelf="center">
                <Text>An error occurred loading the page. Please refresh and try again.</Text>
              </Warning>
            ) : loadingData ? (
              <LargeLoading />
            ) : (
              <TopicList
                data={consolidationData}
                changed={changedtopics}
                toggleTopic={toggleTopic}
              />
            )}
            {update.isError && (
              <Warning status="error" mt={2} mb={0}>
                <Text>There was an error saving your changes. Please try again.</Text>
              </Warning>
            )}
          </ModalBody>
          <ModalFooter display="flex" justifyContent="space-between" alignItems="flex-end">
            {showWarning && (
              <Warning status="warning" width="unset" m={0} fontSize="xs">
                <Text>We recommend leaving at least three topics in Consolidation.</Text>
                <Text display={{ base: 'none', md: 'block' }}>
                  Without this, Sparx may set shorter homework than requested or fail to set any
                  homework.
                </Text>
              </Warning>
            )}
            <Box flex={1} />
            <Box ml={2} whiteSpace="nowrap">
              <Button
                colorScheme="buttonTeal"
                onClick={onClose}
                variant="outline"
                mr={4}
                isDisabled={update.isLoading}
              >
                Cancel
              </Button>
              <Button
                colorScheme="buttonTeal"
                onClick={onSave}
                isLoading={update.isLoading}
                variant="solid"
                isDisabled={changedtopics.length === 0}
              >
                Save
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={unsavedDisclosure.isOpen}
        onClose={unsavedDisclosure.onClose}
        leastDestructiveRef={unsavedCloseRef}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Unsaved changes</AlertDialogHeader>
          <AlertDialogBody>
            <Text>
              You have some unsaved changes to the Consolidation topics. Are you sure you want to
              close without saving?
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme="buttonTeal" onClick={unsavedDisclosure.onClose} variant="outline">
              Back
            </Button>
            <Button colorScheme="buttonTeal" onClick={doClose} ml={4} ref={unsavedCloseRef}>
              Close without saving
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

const TopicList = ({
  data,
  changed,
  toggleTopic,
}: {
  data: ConsolidationTopicData;
  changed: string[];
  toggleTopic: (topicName: string) => void;
}) => {
  const [search, setSearch] = React.useState('');

  const filteredTopics = useTopicSearch(
    data.topics,
    { search, curriculum: '' },
    { enabled: search !== '', includeDeleted: true },
  );

  if (data.topics.length === 0) {
    return (
      <>
        <Text textAlign="center" color="gray.500" mt={8}>
          This class has not been set homework yet this academic year.
        </Text>
        <Text textAlign="center" color="gray.500">
          Once their first homework has been set, the topics assigned will be eligible for
          consolidation and will appear here.
        </Text>
      </>
    );
  }

  return (
    <>
      <HStack width="100%" justifyContent="flex-end" mb={4}>
        <InputGroup>
          <Input
            minWidth={200}
            flex={1}
            backgroundColor="white"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Search topics..."
            pr={10}
          />
          <InputRightElement width={10}>
            {search != '' ? (
              <Button
                size="sm"
                color="gray.700"
                variant="ghost"
                borderRadius={100}
                onClick={() => setSearch('')}
              >
                <FontAwesomeIcon icon={faClose} />
              </Button>
            ) : (
              <FontAwesomeIcon fill="gray.200" icon={faSearch} />
            )}
          </InputRightElement>
        </InputGroup>
      </HStack>
      <Box flex={1} overflowY="auto">
        {search && filteredTopics.length === 0 && (
          <Text mt={6} textAlign="center" color="gray.500">
            No topics match your search.{' '}
            <Link textDecoration="underline" onClick={() => setSearch('')}>
              Clear search
            </Link>
          </Text>
        )}
        <VStack align="stretch" spacing={0}>
          {filteredTopics.map(topic => {
            const ct = data.serverState[topic.topic.name];
            const toggled = changed.includes(ct.topicName);
            const checked = toggled ? !ct.included : ct.included;

            return (
              <Flex
                key={ct.topicName}
                backgroundColor="gray.50"
                _hover={{ backgroundColor: 'gray.100' }}
                _first={{ borderTopWidth: 1 }}
                borderLeftWidth={1}
                borderRightWidth={1}
                borderBottomWidth={1}
                borderColor="gray.200"
                px={4}
                py={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <TopicDisplayDetails topic={ct.topic} width="340px" />
                <Hide below="md">
                  <Text fontSize="sm" color="gray.500">
                    Last assigned <Hide below="lg">to homework </Hide>in week {ct.lastAssignedWeek}
                  </Text>
                </Hide>
                <Text
                  ml={2}
                  display={{ base: 'none', sm: 'block', md: 'none' }}
                  fontSize="sm"
                  color="gray.500"
                  textAlign="center"
                  whiteSpace="nowrap"
                >
                  Week {ct.lastAssignedWeek}
                </Text>

                <Checkbox
                  isChecked={checked}
                  onChange={() => toggleTopic(ct.topicName)}
                  flexDirection="row-reverse"
                  spacing={0}
                  ml={4}
                >
                  <Text display={{ base: 'none', sm: 'block' }} mr={2} fontSize="sm">
                    Include<Hide below="md"> in consolidation</Hide>
                  </Text>
                </Checkbox>
              </Flex>
            );
          })}
        </VStack>
      </Box>
    </>
  );
};

interface ConsolidationTopic {
  topicName: string;
  topic: TopicWithStrand;
  included: boolean;
  lastAssignedWeek: number;
}

const makeActionUpdates = (
  changedTopics: string[],
  stateMap: Record<string, ConsolidationTopic>,
): ConsolidationTopicAction[] =>
  changedTopics.map(topicName => {
    const state = stateMap[topicName];
    return {
      topicName,
      action:
        !state || !state.included
          ? ConsolidationTopicAction_Action.INCLUDE
          : ConsolidationTopicAction_Action.EXCLUDE,
    };
  });
