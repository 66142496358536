import {
  Box,
  Button,
  Card,
  CardBody,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Input,
  Spacer,
  Tag,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { faPencil, faPlus, faTrash, faWandSparkles } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Assignment } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { useAssignments } from 'api/planner';
import { useUserType } from 'api/sessions';
import { AssignmentForm } from 'components/assignment/AssignmentForm';
import { CustomAssignmentForm } from 'components/assignment/CustomAssignmentForm';
import { PageContainer } from 'components/PageContainer';
import { Paginator } from 'components/pagination/Paginator';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import { PrettyTimestamp } from 'components/timestamp/PrettyTimestamp';
import { UndoButtons } from 'components/undobuttons/UndoButtons';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { DeleteAssignmentModal } from 'views/planner/components/DeleteAssignmentModal';
import { PlannerContext } from 'views/planner/Context';
import { usePlannerUndoState } from 'views/planner/PlannerUndoState';

export const AssignmentListView = () => (
  <SuspenseRoute>
    <SuspenseAssignmentListView />
  </SuspenseRoute>
);

const SuspenseAssignmentListView = () => {
  const { data: assignments } = useAssignments({ suspense: true });
  const [editingAssignment, setEditingAssignment] = useState<Assignment>();

  const { updateAssignment, ...undoProps } = usePlannerUndoState(assignments || []);

  useMemo(
    () =>
      assignments?.sort(
        (a, b) =>
          (b.startTimestamp?.seconds || 0) - (a.startTimestamp?.seconds || 0) ||
          a.name.localeCompare(b.name),
      ),
    [assignments],
  );

  const [search, setSearch] = useState('');
  const filteredAssignments = useMemo(
    () => assignments?.filter(a => a.name.includes(search) || a.title.includes(search)),
    [assignments, search],
  );

  return (
    <PageContainer>
      <PlannerContext doAssignmentAction={updateAssignment} weekWithAssignments={[]}>
        <HStack mb={4} spacing={2}>
          <CreateAssignmentDrawer
            assignment={editingAssignment}
            onClosed={() => setEditingAssignment(undefined)}
          />
          <CustomAssignmentDrawer />
          <Spacer />
          <UndoButtons {...undoProps} />
          <Input
            width={300}
            backgroundColor="white"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Search"
          />
        </HStack>
        <Paginator items={filteredAssignments || []} keyGetter={a => a.name} perPage={10}>
          {assignment => (
            <Card key={assignment.name} bg="white" mb={2}>
              <CardBody display="flex" alignItems="center">
                <Box width="40%">
                  <Text fontSize="sm" fontFamily="monospace" color="gray.500">
                    {assignment.name.split('/')[1]}
                  </Text>
                  <Text fontWeight="bold" mb={1}>
                    {assignment.title}
                  </Text>
                  {assignment.groups.map(grp => (
                    <Tag key={grp.name} fontSize="xs">
                      {grp.name.split('/studentGroups/')[1]}
                    </Tag>
                  ))}
                  {assignment.groups.length === 0 && (
                    <Tag fontSize="xs" colorScheme="blue">
                      user_id assignment
                    </Tag>
                  )}
                  <Text mt={2} fontSize="xs">
                    <strong style={{ width: 40, display: 'inline-block' }}>Type: </strong>
                    {assignment.spec?.contents.oneofKind}
                  </Text>
                </Box>
                <Box fontSize="xs" mt={2} flex={1}>
                  <Text>
                    <strong style={{ width: 40, display: 'inline-block' }}>Start: </strong>
                    <PrettyTimestamp>{assignment.startTimestamp}</PrettyTimestamp>
                  </Text>
                  <Text>
                    <strong style={{ width: 40, display: 'inline-block' }}>End: </strong>
                    <PrettyTimestamp>{assignment.endTimestamp}</PrettyTimestamp>
                  </Text>
                  {assignment.generatedTimestamp && (
                    <Text>
                      <strong style={{ width: 40, display: 'inline-block' }}>Gen: </strong>
                      <PrettyTimestamp>{assignment.generatedTimestamp}</PrettyTimestamp>
                    </Text>
                  )}
                </Box>
                <VStack spacing={2}>
                  {assignment.generatedTimestamp ? (
                    <Button
                      as={Link}
                      to={`/teacher/assignments/handin?assignment=${assignment.name.replace(
                        'assignments/',
                        '',
                      )}`}
                      colorScheme="buttonTeal"
                    >
                      Handin
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setEditingAssignment(assignment)}
                      leftIcon={<FontAwesomeIcon icon={faPencil} />}
                    >
                      Edit
                    </Button>
                  )}
                  <DeleteAssignmentModal
                    assignment={assignment}
                    button={onOpen => (
                      <Button onClick={onOpen} leftIcon={<FontAwesomeIcon icon={faTrash} />}>
                        Delete
                      </Button>
                    )}
                  />
                </VStack>
              </CardBody>
            </Card>
          )}
        </Paginator>
      </PlannerContext>
    </PageContainer>
  );
};

interface CreateAssignmentDrawerProps {
  assignment?: Assignment;
  onClosed?: () => void;
}

const CreateAssignmentDrawer = ({ assignment, onClosed }: CreateAssignmentDrawerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: onClosed,
  });
  const btnRef = React.useRef(null);

  // Immediately open if an assignment is passed in
  useEffect(() => {
    if (assignment && !isOpen) {
      onOpen();
    }
  }, [assignment, isOpen, onOpen]);

  return (
    <>
      <Button
        flexShrink={0}
        ref={btnRef}
        colorScheme="buttonTeal"
        onClick={onOpen}
        leftIcon={<FontAwesomeIcon icon={faPlus} />}
      >
        Create
      </Button>
      <Drawer
        size="lg"
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        closeOnOverlayClick={false}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <AssignmentForm assignment={assignment} key={assignment?.name} onSuccess={onClose}>
          {({ form, isSubmitting, formId }) => (
            <DrawerContent>
              <DrawerCloseButton isDisabled={isSubmitting} />
              <DrawerHeader>{assignment ? 'Edit' : 'Create'} assignment</DrawerHeader>
              <DrawerBody>{form}</DrawerBody>
              <DrawerFooter>
                <Button variant="outline" mr={3} onClick={onClose} isDisabled={isSubmitting}>
                  Cancel
                </Button>
                <Button
                  colorScheme="buttonTeal"
                  type="submit"
                  form={formId}
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </DrawerFooter>
            </DrawerContent>
          )}
        </AssignmentForm>
      </Drawer>
    </>
  );
};

const CustomAssignmentDrawer = () => {
  const { isSparxStaff } = useUserType();
  const { isOpen, onOpen, onClose } = useDisclosure({});
  const btnRef = React.useRef(null);

  // Hide for users that are not sparx staff
  if (!isSparxStaff) {
    return null;
  }

  return (
    <>
      <Button
        flexShrink={0}
        ref={btnRef}
        colorScheme="buttonTeal"
        onClick={onOpen}
        leftIcon={<FontAwesomeIcon icon={faWandSparkles} />}
      >
        Custom
      </Button>
      <Drawer
        size="lg"
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        closeOnOverlayClick={false}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <CustomAssignmentForm onSuccess={onClose}>
          {({ form, isSubmitting, formId }) => (
            <DrawerContent>
              <DrawerCloseButton isDisabled={isSubmitting} />
              <DrawerHeader>Create assignment</DrawerHeader>
              <DrawerBody>{form}</DrawerBody>
              <DrawerFooter>
                <Button variant="outline" mr={3} onClick={onClose} isDisabled={isSubmitting}>
                  Cancel
                </Button>
                <Button
                  colorScheme="buttonTeal"
                  type="submit"
                  form={formId}
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </DrawerFooter>
            </DrawerContent>
          )}
        </CustomAssignmentForm>
      </Drawer>
    </>
  );
};
