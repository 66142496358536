import { BoxProps } from '@chakra-ui/react';

import { useShowGettingStartedMessaging, useShowNewYearSetupMessaging } from '../hooks';
import { GettingStartedBanner } from './GettingStartedBanner';
import { NewYearSetupBanner } from './NewYearSetupBanner';

export const SchoolSetupBanner = ({ ...props }: BoxProps) => {
  const gettingStarted = useShowGettingStartedMessaging();
  const newYear = useShowNewYearSetupMessaging();
  if (newYear) {
    return <NewYearSetupBanner displayStatus={newYear} {...props} />;
  }
  if (gettingStarted) {
    return <GettingStartedBanner {...props} />;
  }
  return null;
};
