// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/lessons/v1/notes.proto" (package "sparx.science.lessons.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { LessonNotes } from './notes';
import type { UpdateStudentNotesResponse } from './notes';
import type { UpdateStudentNotesRequest } from './notes';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetStudentNotesResponse } from './notes';
import type { GetStudentNotesRequest } from './notes';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.science.lessons.v1.LessonNotes
 */
export interface ILessonNotesClient {
  /**
   * @generated from protobuf rpc: GetStudentNotes(sparx.science.lessons.v1.GetStudentNotesRequest) returns (sparx.science.lessons.v1.GetStudentNotesResponse);
   */
  getStudentNotes(
    input: GetStudentNotesRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentNotesRequest, GetStudentNotesResponse>;
  /**
   * @generated from protobuf rpc: UpdateStudentNotes(sparx.science.lessons.v1.UpdateStudentNotesRequest) returns (sparx.science.lessons.v1.UpdateStudentNotesResponse);
   */
  updateStudentNotes(
    input: UpdateStudentNotesRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateStudentNotesRequest, UpdateStudentNotesResponse>;
}
/**
 * @generated from protobuf service sparx.science.lessons.v1.LessonNotes
 */
export class LessonNotesClient implements ILessonNotesClient, ServiceInfo {
  typeName = LessonNotes.typeName;
  methods = LessonNotes.methods;
  options = LessonNotes.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetStudentNotes(sparx.science.lessons.v1.GetStudentNotesRequest) returns (sparx.science.lessons.v1.GetStudentNotesResponse);
   */
  getStudentNotes(
    input: GetStudentNotesRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStudentNotesRequest, GetStudentNotesResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetStudentNotesRequest, GetStudentNotesResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: UpdateStudentNotes(sparx.science.lessons.v1.UpdateStudentNotesRequest) returns (sparx.science.lessons.v1.UpdateStudentNotesResponse);
   */
  updateStudentNotes(
    input: UpdateStudentNotesRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateStudentNotesRequest, UpdateStudentNotesResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateStudentNotesRequest,
      UpdateStudentNotesResponse
    >('unary', this._transport, method, opt, input);
  }
}
