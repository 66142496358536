import classNames from 'classnames';
import React from 'react';

import styles from './Chip.module.css';

export type ChipColourVariant =
  | 'Interactable'
  | 'Selected'
  | 'Complete'
  | 'Correct'
  | 'Incorrect'
  | 'Locked'
  | 'InProgress';
export type ChipShapeVariant = 'Boxy' | 'Rounded';
export type ChipStyleVariant = 'Filled' | 'Outlined';
export type ChipSize = 'sm' | 'md' | 'lg';

interface ChipProps {
  children?: React.ReactNode;
  colourVariant?: ChipColourVariant;
  shapeVariant?: ChipShapeVariant;
  styleVariant?: ChipStyleVariant;
  size?: ChipSize;
  className?: string;
  responsive?: boolean;
}

export const Chip = ({
  children,
  colourVariant = 'Interactable',
  shapeVariant = 'Rounded',
  styleVariant = 'Filled',
  size = 'md',
  className,
  responsive,
}: ChipProps) => {
  return (
    <div
      className={classNames(
        styles.Chip,
        styles[colourVariant],
        styles[shapeVariant],
        styles[styleVariant],
        styles[size],
        className,
        responsive && styles.Responsive,
      )}
    >
      {children}
    </div>
  );
};
