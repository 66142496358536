import { Text } from '@chakra-ui/react';
import { faFileLines, faList } from '@fortawesome/free-solid-svg-icons';
import { ResourceStatus, SchemeOfLearning } from '@sparx/api/apis/sparx/planning/v1/sol';
import { createColumnHelper } from '@tanstack/react-table';
import { useSession } from 'api/sessions';
import { useEditableSols } from 'api/sol';
import { DataTable } from 'components/table/DataTable';
import { useMemo } from 'react';
import { articleIdCreateSoLs } from 'utils/knowledgeBaseArticles';
import { plural } from 'utils/plural';
import { SolStatus } from 'views/sol/components/SolTable';

import { IsRolloverProps, PageTab, TabDetails } from '..';
import { ActionBox } from '../components/ActionBox';
import { useYearToReference } from '../hooks';

const title = 'Create Schemes of Learning';

const useSols = ({ suspense }: { suspense: boolean }) => {
  const { yearV3: schoolYear } = useYearToReference();
  const { data: unsortedSols = [], isSuccess: solsSuccess } = useEditableSols({ suspense });
  const { data: { schoolId } = { schoolId: '' } } = useSession();

  const sols = unsortedSols
    .filter(
      s =>
        `schools/${schoolId}/years/${s.schoolYear}` === schoolYear?.name && // Only show for selected year
        s.metadata?.status === ResourceStatus.PUBLISHED, // Only show published
    )
    .sort(
      (a, b) =>
        a.name.localeCompare(b.name) || (b.metadata?.status || 0) - (a.metadata?.status || 0),
    );

  return {
    sols,
    schoolYear,
    isSuccess: solsSuccess,
  };
};

const Page = ({ isRollover }: IsRolloverProps) => {
  const { sols, schoolYear } = useSols({ suspense: true });

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<SchemeOfLearning>();
    return [
      columnHelper.accessor(`displayName`, {
        header: 'Name',
        enableSorting: false,
        cell: ({ row }) => <Text color="gray.600">{row.original.displayName}</Text>,
      }),
      columnHelper.display({
        header: 'Status',
        cell: ({ row }) => <SolStatus sol={row.original} />,
      }),
    ];
  }, []);

  return (
    <>
      <ActionBox
        heading={`Add and publish Schemes of Learning for ${isRollover ? 'the new' : schoolYear?.next ? 'next' : 'this'} academic year`}
        complete={sols.length > 0}
        my={4}
        faIcon={faFileLines}
        button={{
          text: 'Manage Schemes of Learning',
          path: `/teacher/sol/${schoolYear?.next ? 'nextyear' : 'thisyear'}`,
        }}
        knowledgeBaseArticleId={articleIdCreateSoLs}
      >
        {sols.length || 'No'} {plural(sols.length, 'Scheme')} of Learning published
      </ActionBox>
      <DataTable
        data={sols}
        columns={columns}
        getRowId={row => `${row.name}/${row.metadata?.status}`}
        noDataRow={<>No schemes to display</>}
      />
    </>
  );
};

const useTabDetails = (_: IsRolloverProps): TabDetails => {
  const { sols, isSuccess } = useSols({ suspense: true });

  const complete = isSuccess && sols.length > 0;

  return {
    title,
    subtitle: complete ? `${sols.length} created` : 'Action required',
    status: !isSuccess ? 'loading' : complete ? 'complete' : undefined,
    icon: faList,
  };
};

const SchemesOfLearningTab: PageTab = {
  path: 'sols',
  detailsHook: useTabDetails,
  page: Page,
};
export default SchemesOfLearningTab;
