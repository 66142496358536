import { SkillActivity as paSkillActivity } from '@sparx/api/apis/sparx/packageactivity/v1/activity';
import {
  SkillActivity as scSkillActivity,
  SkillActivity_Steps_Delivery,
} from '@sparx/api/apis/sparx/science/packages/v1/activity';
import { IInput } from '@sparx/question';

export const stepIndexToDisplayNum = (
  stepToDisplayNum:
    | {
        [key: number]: number;
      }
    | undefined,
  index: number,
) => stepToDisplayNum?.[index] ?? index + 1;

export const shouldRequireAudioStepRepeat = ({
  visibleStep,
  activeStep,
  skillActivity,
  getStepInput,
}: {
  visibleStep: number;
  activeStep: number;
  skillActivity?: paSkillActivity | scSkillActivity;
  getStepInput: (i: number) => IInput;
}) => {
  const visibleInput = getStepInput(visibleStep) || {};
  const isAudioStep = Object.keys(visibleInput?.media_fields || {}).length === 1;
  const visibleDisplay = stepIndexToDisplayNum(
    skillActivity?.question?.steps?.stepDisplayNumberMap,
    visibleStep,
  );
  const activeDisplay = stepIndexToDisplayNum(
    skillActivity?.question?.steps?.stepDisplayNumberMap,
    activeStep,
  );

  if (
    skillActivity?.question?.steps?.delivery === SkillActivity_Steps_Delivery.STEPPED &&
    isAudioStep &&
    visibleDisplay === activeDisplay &&
    visibleStep !== activeStep
  ) {
    return true;
  }
  return false;
};
