import { CheckIcon, ChevronDownIcon, ChevronUpIcon, TriangleDownIcon } from '@radix-ui/react-icons';
import * as Select from '@radix-ui/react-select';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import styles from '@sparx/mis-sync-import/src/MisSyncImport/components/SchoolGroupsPanel/SchoolGroupsPanel.module.css';
import { useSyncConfig } from '@sparx/mis-sync-import/src/MisSyncImport/context/config';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import selectStyles from '@sparx/sparx-design/shared-styles/Select.module.css';
import classNames from 'classnames';
import { addDays, format, isAfter } from 'date-fns';

const formatEndDate = (timestamp?: Timestamp) =>
  timestamp ? format(addDays(Timestamp.toDate(timestamp), 1), 'dd/MM/yy') : '?';

const isExpired = (group: Group) => {
  if (!group.endDate) {
    return false;
  }
  const now = new Date();
  const endDate = Timestamp.toDate(group.endDate);
  return !isAfter(endDate, now);
};

/**
 * A combo box for selecting a Sparx class to match with a Wonde class in the table. This is only shown when Sparx
 * staff features are enabled.
 * @param wondeID the Wonde ID of the class
 * @param sparxClassMatch the Sparx class that the Wonde class is currently matched to
 * @param existingClassOptions the list of current unexpired Sparx classes that can be matched to the Wonde class. This
 *  list is sorted by name, and only included classes not assigned to any other Wonde class.
 * @param recentlyExpiredClassOptions the list of recently expired Sparx classes that can be matched to the Wonde class.
 *   This is list is sorted by name, and only includes classes that are not assigned to any other Wonde class, and were
 *   expired within the last 30 days
 * @param matchingExpiredClass an expired class with the same Wonde ID as the class being shown. If this is present,
 *   there will be the option to unexpire this class instead of the "Create class" option
 * @constructor
 */
export const SparxStaffClassSelect = ({
  wondeID,
  sparxClassMatch,
  existingClassOptions,
  recentlyExpiredClassOptions,
  matchingExpiredClass,
}: {
  wondeID: string;
  sparxClassMatch?: Group;
  existingClassOptions?: Group[];
  recentlyExpiredClassOptions?: Group[];
  matchingExpiredClass?: Group;
}) => {
  const { dispatch } = useSyncConfig();

  const value: string = sparxClassMatch?.name || '';

  const valueDisplay = sparxClassMatch
    ? isExpired(sparxClassMatch)
      ? `${sparxClassMatch.displayName} removed on ${formatEndDate(sparxClassMatch.endDate)}`
      : sparxClassMatch.displayName
    : 'Create class';

  const options = [];

  const optionValues = new Set<string>();

  // Show the currently matched class if there is one
  if (sparxClassMatch) {
    options.push(
      <Select.Item
        className={classNames(
          selectStyles.SelectItem,
          styles.SelectItem,
          styles.SelectItemExpired,
          styles.SparxClassSelectItem,
          { [styles.SelectItemExpired]: isExpired(sparxClassMatch) },
        )}
        value={sparxClassMatch.name}
        key={sparxClassMatch.name}
      >
        <Select.ItemText>{valueDisplay}</Select.ItemText>
        <Select.ItemIndicator className={selectStyles.SelectItemIndicator}>
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>,
    );
    optionValues.add(sparxClassMatch.name);
  }

  // If there is a matching expired class show it as an option to unexpire, otherwise show the "Create class" option
  if (matchingExpiredClass) {
    if (!optionValues.has(matchingExpiredClass.name)) {
      options.push(
        <Select.Item
          className={classNames(
            selectStyles.SelectItem,
            styles.SelectItem,
            styles.SelectItemExpired,
            styles.SparxClassSelectItem,
          )}
          value={matchingExpiredClass.name}
          key={matchingExpiredClass.name}
        >
          <Select.ItemText>{`${matchingExpiredClass.displayName} removed on ${formatEndDate(matchingExpiredClass.endDate)}`}</Select.ItemText>
          <Select.ItemIndicator className={selectStyles.SelectItemIndicator}>
            <CheckIcon />
          </Select.ItemIndicator>
        </Select.Item>,
      );
      optionValues.add(matchingExpiredClass.name);
    }
  } else {
    options.push(
      <Select.Item
        className={classNames(
          selectStyles.SelectItem,
          styles.SelectItem,
          styles.SparxClassSelectItem,
        )}
        value={''}
      >
        <Select.ItemText>Create class</Select.ItemText>
        <Select.ItemIndicator className={selectStyles.SelectItemIndicator}>
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>,
    );
    optionValues.add('');
  }

  // Add existing class options (unless already added)
  for (const c of existingClassOptions || []) {
    if (!optionValues.has(c.name)) {
      options.push(
        <Select.Item
          className={classNames(
            selectStyles.SelectItem,
            styles.SelectItem,
            styles.SparxClassSelectItem,
          )}
          value={c.name}
          key={c.name}
        >
          <Select.ItemText>{c.displayName}</Select.ItemText>
          <Select.ItemIndicator className={selectStyles.SelectItemIndicator}>
            <CheckIcon />
          </Select.ItemIndicator>
        </Select.Item>,
      );
    }
    optionValues.add(c.name);
  }

  // Add recently expired class options (unless already added)
  for (const c of recentlyExpiredClassOptions || []) {
    if (!optionValues.has(c.name)) {
      options.push(
        <Select.Item
          className={classNames(
            selectStyles.SelectItem,
            styles.SelectItem,
            styles.SelectItemExpired,
            styles.SparxClassSelectItem,
          )}
          value={c.name}
          key={c.name}
        >
          <Select.ItemText>{`${c.displayName} removed on ${formatEndDate(c.endDate)}`}</Select.ItemText>
          <Select.ItemIndicator className={selectStyles.SelectItemIndicator}>
            <CheckIcon />
          </Select.ItemIndicator>
        </Select.Item>,
      );
    }
  }

  return (
    <Select.Root
      value={value}
      onValueChange={val => {
        const sparxClass =
          existingClassOptions?.find(c => c.name === val) ||
          recentlyExpiredClassOptions?.find(c => c.name === val) ||
          (matchingExpiredClass && matchingExpiredClass.name === val
            ? matchingExpiredClass
            : undefined);
        dispatch({
          type: 'add_class_match',
          wondeID,
          sparxClass,
        });
      }}
    >
      <Select.Trigger
        className={classNames(
          selectStyles.SelectTrigger,
          accessibilityStyles.FocusTarget,
          styles.SelectTrigger,
          { [styles.SelectItemExpired]: sparxClassMatch && isExpired(sparxClassMatch) },
        )}
        aria-label="class to use with Sparx"
      >
        <Select.Value>{valueDisplay}</Select.Value>
        <Select.Icon>
          <TriangleDownIcon />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content className={styles.Select} position="popper">
          <Select.ScrollUpButton>
            <ChevronUpIcon />
          </Select.ScrollUpButton>
          <Select.Viewport>{options}</Select.Viewport>
          <Select.ScrollDownButton>
            <ChevronDownIcon />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};
