import { Box, StyleProps, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faFileAlt, faUsersGear } from '@fortawesome/free-solid-svg-icons';
import { SchemeOfLearning } from '@sparx/api/apis/sparx/planning/v1/sol';
import { Course, CourseTier } from '@sparx/api/apis/sparx/science/schools/settings/v1/settings';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { readableStaffNames } from '@sparx/staff-manager/src/staff-names';
import { useGroups, useStudents } from 'api/school';
import {
  useGroupSettings,
  useGroupsWithSettings,
  useStudentSettings,
  useStudentsWithSettings,
} from 'api/scienceschool';
import { useAssignableSols } from 'api/sol';
import { useStaffLookup } from 'api/staff';
import { useMemo } from 'react';
import { articleIdScheduleHW } from 'utils/knowledgeBaseArticles';
import { AllTeachersChip, NoneSetChip, NoTeacherChip } from 'views/groups/GroupListView';

import { IsRolloverProps, PageTab, TabDetails, TabStatus } from '..';
import { ActionBox } from '../components/ActionBox';
import { useGroupsInfo } from './ImportClasses';
import { useMISStatus } from './MIS';
import { useMISResetStatus } from './MISReset';

const title = 'Schedule Homework';

const useScienceGroups = ({ suspense }: { suspense: boolean }) => {
  const { data: groupSchoolman, isSuccess: groupsSuccess } = useGroups({ suspense });
  const { data: groupSettings, isSuccess: settingsSuccess } = useGroupSettings({ suspense });

  const groupsWithSettings = useGroupsWithSettings(groupSchoolman, groupSettings);

  return {
    groups: groupsWithSettings.filter(g => g.type === StudentGroupType.CLASS_SCIENCE),
    isSuccess: groupsSuccess && settingsSuccess,
  };
};

const Page = ({ isRollover }: IsRolloverProps) => {
  const { groups } = useScienceGroups({ suspense: true });
  const { data: studentsData } = useStudents({ suspense: true });
  const { data: studentSettings } = useStudentSettings({ suspense: true });
  const students = useStudentsWithSettings(studentsData || [], studentSettings);
  const { data: sols = [] } = useAssignableSols({ suspense: true });
  const { data: staffLookup } = useStaffLookup({ suspense: true });
  const { status } = useStatus({ isRollover, suspense: true });

  const groupsWithCounts = useMemo(() => {
    const higher: Dictionary<string, number> = {};
    const separate: Dictionary<string, number> = {};
    const total: Dictionary<string, number> = {};
    for (const student of students) {
      for (const group of student.studentGroupIds) {
        if (student.scienceSettings.course === Course.SEPARATE) {
          separate[group] = (separate[group] || 0) + 1;
        }
        if (student.scienceSettings.tier === CourseTier.LEVEL_HIGHER) {
          higher[group] = (higher[group] || 0) + 1;
        }
        total[group] = (total[group] || 0) + 1;
      }
    }

    const solLookup = sols.reduce<Dictionary<string, SchemeOfLearning>>((acc, sol) => {
      acc[sol.name] = sol;
      return acc;
    }, {});

    const groupsWithCounts = groups
      .sort((a, b) => a.displayName.localeCompare(b.displayName))
      .map(group => ({
        ...group,
        studentCount: total[group.name.split('/')[3]] || 0,
        higherCount: higher[group.name.split('/')[3]] || 0,
        separateCount: separate[group.name.split('/')[3]] || 0,
        sol: solLookup[group.scienceSettings.solName],
        teachers: group.staff
          .filter(s => !!staffLookup?.[s.staffID])
          .map(s => {
            const { realName, fallback } = readableStaffNames(staffLookup?.[s.staffID]);
            return realName || fallback;
          }),
      }));
    return groupsWithCounts;
  }, [groups, students, sols, staffLookup]);

  const headerStyles: StyleProps = {
    backgroundColor: 'blue.800',
    textTransform: 'none',
    fontSize: 'sm',
    color: 'white',
    zIndex: 10,
    top: 0,
    px: 6,
    py: 4,
  };

  return (
    <>
      <Box borderRadius="md" borderWidth={1} my={4}>
        <ActionBox
          heading="Schedule homework by assigning a Scheme of Learning to each class"
          complete={status === 'complete'}
          faIcon={faClock}
          button={{ text: 'Class Manager', path: '/teacher/group' }}
          borderWidth={0}
          borderRadius="unset"
          borderBottomWidth={1}
          knowledgeBaseArticleId={articleIdScheduleHW}
        >
          Also set the hand-out and hand-in dates and assign teachers to each class
        </ActionBox>
        <ActionBox
          heading="Set students to higher & separate science where appropriate"
          faIcon={faUsersGear}
          button={{ text: 'Student Manager', path: '/teacher/student' }}
          borderWidth={0}
          borderRadius="unset"
        >
          We exclude higher-only questions from students&apos; homework unless the{' '}
          <strong>Higher</strong> setting is turned on (and the same for <strong>Separate</strong>)
        </ActionBox>
      </Box>
      <TableContainer whiteSpace="unset" boxShadow="elevationLow" borderRadius="md">
        <Table backgroundColor="white" overflow="hidden">
          <Thead>
            <Tr>
              <Th {...headerStyles}>Class</Th>
              <Th {...headerStyles}>SoL assigned</Th>
              <Th {...headerStyles}>Teachers assigned</Th>
              <Th {...headerStyles}>Higher</Th>
              <Th {...headerStyles}>Separate</Th>
            </Tr>
          </Thead>
          <Tbody>
            {groupsWithCounts.map(group => (
              <Tr key={group.name}>
                <Td>{group.displayName}</Td>
                <Td>{group.sol ? group.sol.displayName : <NoneSetChip />}</Td>
                <Td data-sentry-mask={group.teachers.length !== 0}>
                  {group.teachers.length === 0 ? (
                    <NoTeacherChip />
                  ) : (
                    <>
                      {group.teachers[0]}
                      {group.teachers.length > 1 && (
                        <>
                          , <AllTeachersChip teachers={group.teachers} />
                        </>
                      )}
                    </>
                  )}
                </Td>
                <HigherSeparateCells
                  higher={group.higherCount}
                  separate={group.separateCount}
                  total={group.studentCount}
                />
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

const HigherSeparateCells = ({
  higher,
  separate,
  total,
}: {
  higher: number;
  separate: number;
  total: number;
}) => {
  if (total === 0) {
    return <Td colSpan={2}>No students</Td>;
  }
  return (
    <>
      <Td>
        <YesNoCell numYes={higher} total={total} />
      </Td>
      <Td>
        <YesNoCell numYes={separate} total={total} />
      </Td>
    </>
  );
};

const YesNoCell = ({ numYes, total }: { numYes: number; total: number }) => {
  if (numYes === total) {
    return <>Yes - All</>;
  }
  if (numYes === 0) {
    return <>No - All</>;
  }

  return (
    <>
      Yes - {numYes}
      <br />
      No - {total - numYes}
    </>
  );
};

const useStatus = ({
  isRollover,
  suspense = false,
}: IsRolloverProps & {
  suspense?: boolean;
}): {
  status?: TabStatus;
  subtitle?: string;
} => {
  const { isWondeAuthorised, isSuccess: statusSuccess } = useMISStatus({ suspense });
  const { status: resetStatus } = useMISResetStatus({ suspense, enabled: isRollover });

  const misComplete = isRollover ? resetStatus === 'complete' : isWondeAuthorised;
  const misSuccess = isRollover ? resetStatus !== 'loading' : statusSuccess;
  const lockedMsg = isRollover ? 'Reset Sparx Science first' : 'Connect to your MIS first';

  const {
    numNonEmptyGroups,
    groupsWithCount,
    isSuccess: infoSuccess,
  } = useGroupsInfo({
    suspense,
    enabled: misComplete,
  });
  const { groups: groupsWithSettings, isSuccess: groupsSuccess } = useScienceGroups({
    suspense,
  });

  if (!misComplete || !misSuccess) {
    return {
      status: 'locked',
      subtitle: lockedMsg,
    };
  }

  if (!infoSuccess) {
    return {
      status: 'loading',
    };
  }

  if (numNonEmptyGroups === 0) {
    return {
      status: 'locked',
      subtitle: 'Import Classes & Students first',
    };
  }

  if (!groupsSuccess) {
    return {
      status: 'loading',
    };
  }

  const numWithSol = groupsWithSettings.reduce((acc, group) => {
    const numStudents = groupsWithCount.find(g => g.name === group.name)?.studentCount || 0;
    if (numStudents === 0 || !group.scienceSettings.solName) {
      return acc;
    }
    return acc + 1;
  }, 0);

  if (numWithSol >= numNonEmptyGroups) {
    return {
      status: 'complete',
      subtitle: 'All classes have SoL assigned',
    };
  }

  return {
    subtitle: `${numNonEmptyGroups - numWithSol} classes have no SoL assigned`,
  };
};

const useTabDetails = ({ isRollover }: IsRolloverProps): TabDetails => {
  const { status, subtitle } = useStatus({ isRollover });
  return {
    title,
    subtitle,
    status,
    icon: faFileAlt,
  };
};

const ScheduleHomeworkTab: PageTab = {
  detailsHook: useTabDetails,
  path: 'homework',
  page: Page,
};
export default ScheduleHomeworkTab;
