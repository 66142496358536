import { useQuery } from '@tanstack/react-query';

// We lazy load the map so that it will be separate from the main bundle and only fetched on pages that need it
export const useTopicMigrationMap = () =>
  useQuery({
    queryKey: ['topic-migration-map'],
    queryFn: async () => {
      const { TOPIC_MIGRATION_MAP } = await import('views/sol/topic-migration/migration_map');
      return TOPIC_MIGRATION_MAP;
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });
