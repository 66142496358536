import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from 'api/client';
import { router } from 'app/Content';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { theme } from 'theme/theme';

import { NavigationControlProvider } from './BackLink';

export const App = () => {
  return (
    <Sentry.ErrorBoundary showDialog>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider
          theme={theme}
          toastOptions={{
            defaultOptions: {
              position: 'bottom-left',
            },
          }}
        >
          <NavigationControlProvider>
            <RouterProvider router={router} />
          </NavigationControlProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </ChakraProvider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};
