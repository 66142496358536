import { Button, GridItem, Heading, HStack, Input, Text } from '@chakra-ui/react';
import { PageFooter } from 'components/PageContainer';
import { InfoTooltip } from 'components/tooltip/InfoTooltip';
import React, { PropsWithChildren, ReactNode, useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

interface StudentFieldProps {
  name?: string;
  error?: FieldError;
  span?: number;
  tooltip?: React.ReactNode;
}

export const EditableField = ({
  name,
  children,
  error,
  span,
  tooltip,
}: PropsWithChildren<StudentFieldProps>) => (
  <GridItem display="flex" flexDirection="column" colSpan={span || 1}>
    <Text pb={1} color="gray.600" whiteSpace="nowrap">
      {name}
      {name && !name.endsWith('?') ? ':' : <>&nbsp;</>}
      {tooltip && <InfoTooltip text={tooltip} color="gray.300" />}
    </Text>
    {children}
    {error && (
      <Text color="red" fontSize="sm" pt={1}>
        {error.type === 'required'
          ? `${name} is required`
          : error.message || 'This field is invalid'}
      </Text>
    )}
  </GridItem>
);

interface ReadonlyStudentFieldProps {
  name: string;
  value?: string;
  tooltip?: React.ReactNode;
  // Whether to add data-sentry-mask to the input field, to mask it in Sentry replays
  sentryMask?: boolean;
}

export const ReadonlyField = ({
  name,
  children,
  value,
  tooltip,
  sentryMask,
}: PropsWithChildren<ReadonlyStudentFieldProps>) => (
  <EditableField name={name} tooltip={tooltip}>
    <Input
      value={value}
      onChange={() => undefined}
      variant="filled"
      isDisabled={true}
      opacity="1 !important"
      data-sentry-mask={sentryMask}
    />
    {children}
  </EditableField>
);

interface HeaderFieldProps {
  name: string;
  first?: boolean;
}

export const HeaderField = ({ name, first }: HeaderFieldProps) => (
  <GridItem
    colSpan={2}
    borderTop={first ? 0 : '2px'}
    borderTopColor="gray.200"
    pt={first ? 0 : 8}
    mt={first ? 0 : 4}
    mb={2}
  >
    <Heading fontSize="lg">{name}</Heading>
  </GridItem>
);

interface SubHeaderFieldProps {
  name: string;
  subText?: string;
}

export const SubHeaderField = ({ name, subText }: SubHeaderFieldProps) => (
  <GridItem colSpan={2}>
    <Heading fontSize="md" fontWeight="500">
      {name}
    </Heading>
    {subText && (
      <Text pt={2} fontSize="sm" color="gray.600">
        {subText}
      </Text>
    )}
  </GridItem>
);

export const useFormId = () => useMemo(uuid, []);

interface PageSubmitFooterProps {
  formID: string;
  backLink?: string;
  saveDisabled?: boolean;
  isDirty: boolean;
  isLoading?: boolean;
  saveText?: string;
  warningText?: ReactNode;
  // additionalSaveHandler is called when the save button is clicked, in addition to the form submission
  additionalSaveHandler?: () => void;
  // additionalSaveHandlerDisabled will disable the additionalSaveHandler callback, but allow the form
  // submission to continue if the form is valid.
  additionalSaveHandlerDisabled?: boolean;
}

export const PageSubmitFooter = ({
  formID,
  backLink,
  saveDisabled,
  isDirty,
  isLoading,
  saveText,
  warningText,
  additionalSaveHandler,
  additionalSaveHandlerDisabled,
}: PageSubmitFooterProps) => (
  <PageFooter>
    <HStack spacing={6} px={2} flex={1} justifyContent="flex-end">
      {warningText}
      {backLink && (
        <Button
          colorScheme="buttonTeal"
          variant="outline"
          isLoading={isLoading}
          as={Link}
          to={backLink}
        >
          {isDirty ? 'Cancel' : 'Back'}
        </Button>
      )}
      <Button
        colorScheme="buttonTeal"
        type="submit"
        isLoading={isLoading}
        isDisabled={saveDisabled && additionalSaveHandlerDisabled}
        form={formID}
        onClick={e => {
          if (saveDisabled) {
            e.preventDefault();
          }
          if (!additionalSaveHandlerDisabled) {
            additionalSaveHandler?.();
          }
        }}
      >
        {saveText || 'Save'}
      </Button>
    </HStack>
  </PageFooter>
);
