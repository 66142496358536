import { Box } from '@chakra-ui/react';
import { useDraggable } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { ComponentProps } from 'react';

interface DraggableProps {
  id: string;
  children: (props: ComponentProps<'div'>) => React.ReactNode;
}

export const Draggable = ({ children, id }: DraggableProps) => {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({ id });
  return (
    <Box ref={setNodeRef} style={{ opacity: isDragging ? 0.3 : 1, transition: 'opacity 0.5s' }}>
      {children({ ...listeners, ...attributes })}
    </Box>
  );
};

export const Sortable = ({ children, id, enabled }: DraggableProps & { enabled?: boolean }) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const style = {
    opacity: isDragging ? 0.3 : 1,
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if (!enabled) {
    return <div style={style}>{children({})}</div>;
  }

  return (
    <div ref={setNodeRef} style={style}>
      {children({ ...listeners, ...attributes })}
    </div>
  );
};
