import * as Dialog from '@radix-ui/react-dialog';
import { Table } from '@radix-ui/themes';
import { Class, Student } from '@sparx/api/apis/sparx/misintegration/wondewitch/v1/wondewitch';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import {
  bulkResolveStudentConflicts,
  resolveStudentConflict,
} from '@sparx/mis-sync-import/src/analytics';
import { useMisSyncContext } from '@sparx/mis-sync-import/src/Context';
import { useTimeVisible } from '@sparx/mis-sync-import/src/hooks';
import Button from '@sparx/mis-sync-import/src/MisSyncImport/components/common/Button/Button';
import { ConflictResolution } from '@sparx/mis-sync-import/src/MisSyncImport/context/config/reducer';
import {
  compareStudentsByName,
  getDateOfBirth,
  getNameForWondeStudent,
} from '@sparx/mis-sync-import/src/processing';
import checkboxStyles from '@sparx/mis-sync-import/src/shared-styles/Checkbox.module.css';
import tableStyles from '@sparx/mis-sync-import/src/shared-styles/Tables.module.css';
import { ConflictingStudent, WondeData } from '@sparx/mis-sync-import/src/types';
import { getSystemOptions } from '@sparx/mis-sync-import/src/utils';
import { simulateClickOnEnter } from '@sparx/react-utils/keyboard';
import { Checkbox, WarningMessage } from '@sparx/sparx-design/components';
import { Cross, Tick, TriangleExclamationIcon } from '@sparx/sparx-design/icons';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';

import { ConflictsClassSelect } from './ConflictsClassSelect';
import styles from './ConflictsModal.module.css';

interface ConflictsModalProps {
  // Whether the modal is currently open:
  open: boolean;

  // Called when the user has closed the modal
  setClosed: () => void;

  // list of conflicting students to be resolved here
  conflictingStudents?: ConflictingStudent[];

  // list of conflict resolutions already made:
  conflictResolutions: Record<string, string | undefined>;

  // wonde data to get the names of students and classes
  wondeData: WondeData;

  // callback for when the user chooses a classes for students to resolve conflicts
  onResolveConflicts: (conflictResolutions: ConflictResolution[]) => void;

  // The group subject (this is used to set the theme on dialog content)
  groupSubject: StudentGroupType;
}

/**
 * A dialog for resolving conflicts where students are in more than one class
 */
export const ConflictsModal = ({
  open,
  setClosed,
  conflictingStudents,
  wondeData,
  conflictResolutions,
  onResolveConflicts,
  groupSubject,
}: ConflictsModalProps) => {
  const timeVisible = useTimeVisible();
  const { sendEvent, useLegalNames } = useMisSyncContext();

  const [selectedStudents, setSelectedStudents] = useState<Set<string>>(new Set());

  // Tracks which students have had conflicts resolved since opening the modal so they can be highlighted in green:
  const [studentsResolvedSinceOpened, setStudentsResolvedSinceOpened] = useState<Set<string>>(
    new Set(),
  );

  const {
    sortedConflicts,
    classesByWondeID,
    studentsByWondeID,
    conflictsRemainingCount,
    classesSharedByAllStudents,
  } = useConflictsModalData(conflictingStudents, wondeData, conflictResolutions, useLegalNames);

  const [bulkSelectClassWondeID, setBulkSelectClassWondeID] = useState<string | undefined>();

  // Clear which students have been resolved since the modal was opened
  useEffect(() => {
    if (!open) {
      setStudentsResolvedSinceOpened(new Set());
      setSelectedStudents(new Set());
      setBulkSelectClassWondeID(undefined);
    }
  }, [open]);

  // If a class is chosen in the bulk resolve selector, assign that class to each checked student that has a conflict
  // involving that class.
  const bulkSelectClass = (classWondeID: string) => {
    const conflictResolutions: ConflictResolution[] = sortedConflicts
      .filter(c => c.classWondeIDs.includes(classWondeID) && selectedStudents.has(c.studentWondeID))
      .map(c => ({
        studentWondeID: c.studentWondeID,
        classWondeID,
      }));
    setStudentsResolvedSinceOpened(
      new Set<string>([
        ...studentsResolvedSinceOpened,
        ...conflictResolutions.map(c => c.studentWondeID),
      ]),
    );
    onResolveConflicts(conflictResolutions);
    setBulkSelectClassWondeID(classWondeID);
    sendEvent(
      bulkResolveStudentConflicts(
        Array.from(selectedStudents),
        classesSharedByAllStudents,
        classWondeID,
        timeVisible.getTimeVisible(),
      ),
    );
  };

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className={classNames(styles.DialogOverlay, dialogStyles.DialogOverlay)} />
        <Dialog.Content
          className={classNames(dialogStyles.DialogContent, dialogStyles.FullWidth, styles.Dialog)}
        >
          <button
            className={styles.CloseCrossButton}
            aria-label="Close conflicts dialog"
            onClick={setClosed}
          >
            <Cross className={styles.CloseCross} variant="None" />
          </button>
          <Dialog.Title className={classNames(dialogStyles.DialogTitle, styles.DialogTitle)}>
            Resolve conflicts
          </Dialog.Title>
          <div
            data-theme={getSystemOptions(groupSubject).dataTheme}
            className={styles.ContentContainer}
          >
            {conflictsRemainingCount !== 0 && (
              <div className={styles.WarningBox}>
                <WarningMessage
                  className={styles.WarningMessage}
                  message="Students can only belong to one class in each Sparx product"
                />
                Some students are in multiple classes. To resolve this please choose a preferred
                class for each student.
              </div>
            )}
            <div className={styles.Header}>
              <div className={styles.HeaderLeft}>
                {conflictsRemainingCount > 0 && (
                  <>
                    <TriangleExclamationIcon className={styles.UnresolvedIcon} />
                    <div className={styles.ConflictsRemainingLabel}>
                      {conflictsRemainingCount} conflict{conflictsRemainingCount === 1 ? '' : 's'}{' '}
                      remaining
                    </div>
                  </>
                )}
                {conflictsRemainingCount === 0 && (
                  <>
                    <Tick variant="White" className={styles.ResolvedIcon} />
                    <div className={styles.ConflictsRemainingLabel}>All conflicts resolved</div>
                  </>
                )}
              </div>
              {classesSharedByAllStudents.length > 0 && (
                <div className={styles.HeaderRight}>
                  <span className={styles.BulkResolveLabel}>Bulk resolve conflicts:</span>
                  <ConflictsClassSelect
                    selectedClassWondeID={bulkSelectClassWondeID}
                    classes={classesSharedByAllStudents}
                    onSelectClass={classWondeID => {
                      bulkSelectClass(classWondeID);
                    }}
                    disabled={selectedStudents.size === 0}
                  />
                </div>
              )}
            </div>
            <div className={styles.TableScroller}>
              <Table.Root className={classNames(tableStyles.Table, styles.Table)}>
                <Table.Header className={tableStyles.TableHeader}>
                  <Table.Row>
                    <Table.ColumnHeaderCell>
                      <Checkbox
                        variant="inverted"
                        className={checkboxStyles.Checkbox}
                        checked={selectedStudents.size === conflictingStudents?.length}
                        onCheckedChange={checked => {
                          setBulkSelectClassWondeID(undefined);
                          if (checked) {
                            setSelectedStudents(
                              new Set(conflictingStudents?.map(c => c.studentWondeID)),
                            );
                          } else {
                            setSelectedStudents(new Set());
                          }
                        }}
                      />
                    </Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>DOB</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>Conflicting classes</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell width={390}>
                      Class to use with Sparx
                    </Table.ColumnHeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {sortedConflicts.map(c => {
                    const student = studentsByWondeID.get(c.studentWondeID);
                    if (!student) {
                      return undefined;
                    }
                    const classes = c.classWondeIDs
                      .map(id => classesByWondeID.get(id))
                      .filter(c => !!c) as Class[];
                    const selectedClass =
                      conflictResolutions[c.studentWondeID] || c.defaultResolution;
                    const isSelected = selectedStudents.has(c.studentWondeID);
                    const isResolved =
                      !!selectedClass && studentsResolvedSinceOpened.has(c.studentWondeID);

                    return (
                      <Table.Row
                        key={c.studentWondeID}
                        className={classNames(tableStyles.TableBodyRow, {
                          [styles.RowSelected]: !isResolved && isSelected,
                          [styles.RowConflict]: !selectedClass,
                          [styles.RowResolved]: isResolved,
                        })}
                      >
                        <Table.Cell>
                          <Checkbox
                            className={checkboxStyles.Checkbox}
                            checked={selectedStudents.has(c.studentWondeID)}
                            onCheckedChange={checked => {
                              setBulkSelectClassWondeID(undefined);
                              const newSet = new Set(selectedStudents);
                              if (checked) {
                                newSet.add(c.studentWondeID);
                                setSelectedStudents(newSet);
                              } else {
                                newSet.delete(c.studentWondeID);
                              }
                              setSelectedStudents(newSet);
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell data-hj-suppress>
                          {getNameForWondeStudent(student, useLegalNames)}
                        </Table.Cell>
                        <Table.Cell data-hj-suppress>
                          {getDateOfBirth(student.dateOfBirth)}
                        </Table.Cell>
                        <Table.Cell>{classes.map(c => c?.name).join(', ')}</Table.Cell>
                        <Table.Cell width={390}>
                          <ConflictsClassSelect
                            classes={classes}
                            selectedClassWondeID={selectedClass}
                            onSelectClass={classWondeID => {
                              onResolveConflicts([
                                { studentWondeID: c.studentWondeID, classWondeID },
                              ]);
                              setStudentsResolvedSinceOpened(
                                new Set([...studentsResolvedSinceOpened, c.studentWondeID]),
                              );
                              sendEvent(
                                resolveStudentConflict(
                                  c.studentWondeID,
                                  classes,
                                  classWondeID,
                                  timeVisible.getTimeVisible(),
                                ),
                              );
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table.Root>
            </div>
          </div>
          <div className={styles.ButtonsContainer}>
            <Button
              className={styles.CloseButton}
              as={Dialog.Close}
              rightIcon={<Cross variant="None" />}
              onClick={setClosed}
              onKeyDown={simulateClickOnEnter}
              colour="secondary"
            >
              Close
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

/**
 * Compile data needed for the conflicts modal - a map of students and classes by Wonde ID, a sorted list of conflicts,
 * and a count of how many conflicts remain to be resolved.
 * @param conflictingStudents the list of conflicting students
 * @param wondeData the Wonde data
 * @param conflictResolutions the conflict resolutions so far
 * @param useLegalNames whether to use legal vs preferred names for students
 */
const useConflictsModalData = (
  conflictingStudents: ConflictingStudent[] | undefined,
  wondeData: WondeData,
  conflictResolutions: Record<string, string | undefined>,
  useLegalNames: boolean,
): {
  studentsByWondeID: Map<string, Student>;
  classesByWondeID: Map<string, Class>;
  sortedConflicts: ConflictingStudent[];
  conflictsRemainingCount: number;
  classesSharedByAllStudents: Class[];
} =>
  useMemo(() => {
    const studentsByWondeID = new Map<string, Student>();
    const classesByWondeID = new Map<string, Class>();
    const sortedConflicts: ConflictingStudent[] = [];
    let conflictsRemainingCount = 0;

    if (conflictingStudents === undefined || conflictingStudents.length === 0) {
      return {
        studentsByWondeID,
        classesByWondeID,
        sortedConflicts,
        conflictsRemainingCount,
        classesSharedByAllStudents: [],
      };
    }

    const studentWondeIDs: Set<string> = new Set(conflictingStudents.map(cs => cs.studentWondeID));
    const classWondeIDs: Set<string> = new Set();
    for (const cs of conflictingStudents) {
      for (const classWondeID of cs.classWondeIDs) {
        classWondeIDs.add(classWondeID);
      }
      if (!cs.defaultResolution && !conflictResolutions[cs.studentWondeID]) {
        conflictsRemainingCount++;
      }
    }

    for (const c of wondeData.wondeClasses) {
      if (classWondeIDs.has(c.id)) {
        classesByWondeID.set(c.id, c);
        for (const s of c.students) {
          if (studentWondeIDs.has(s.id)) {
            studentsByWondeID.set(s.id, s);
          }
        }
      }
    }

    sortedConflicts.push(...conflictingStudents);

    // Sort the conflicts by student forename then surname
    sortedConflicts.sort((a, b) => {
      const aStudent = studentsByWondeID.get(a.studentWondeID);
      const bStudent = studentsByWondeID.get(b.studentWondeID);
      if (aStudent && bStudent) {
        return compareStudentsByName(aStudent, bStudent, useLegalNames);
      }
      return 0;
    });

    // Calculate which classes all students are in, for the options for the bulk conflict resolver
    const classesSharedByAllStudents = [...classesByWondeID.values()].filter(c =>
      conflictingStudents.every(cs => cs.classWondeIDs.includes(c.id)),
    );

    return {
      studentsByWondeID,
      classesByWondeID,
      sortedConflicts,
      conflictsRemainingCount,
      classesSharedByAllStudents,
    };
  }, [conflictResolutions, conflictingStudents, wondeData.wondeClasses, useLegalNames]);
