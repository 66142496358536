import { useUserType } from 'api/sessions';
import { useEffect } from 'react';

const teacherComponentIDs: string[] = ['01HY0PXS8YWWRBN6NF9M22Y48X'];
const studentComponentIDs: string[] = ['01HY0PXS8Y4XRBA9TV9PA4BB36'];

declare global {
  interface Window {
    // Status Embed variables
    // IDs of components to show alerts for
    spxStatusEmbedComponentIDs?: string[];
    // Function to update the list of components to show alerts for, and
    // trigger the alert to update if needed
    spxStatusEmbedUpdateComponents?: (componentIDs: string[]) => void;
  }
}

// Hook to configure the components to display based on the user type
export const useConfigureStatusEmbed = () => {
  const { isTeacher, isLoading } = useUserType();

  useEffect(() => {
    if (!isLoading) {
      const newIDs = isTeacher ? teacherComponentIDs : studentComponentIDs;
      if (window.spxStatusEmbedUpdateComponents) {
        window.spxStatusEmbedUpdateComponents(newIDs);
      } else {
        // Embed hasn't started yet
        window.spxStatusEmbedComponentIDs = newIDs;
      }
    }
  }, [isTeacher, isLoading]);
};
