import { UseQueryResult } from '@tanstack/react-query';
import { AssignmentWithAction, ConsolidationTopicData, useConsolidationTopics } from 'api/planner';
import { Week } from 'api/school';
import { GroupWithSettings } from 'api/scienceschool';
import { useAssignableSols } from 'api/sol';
import React, { PropsWithChildren, useMemo } from 'react';
import { TopicWeeks, useTopicWeeks, WeekWithAssignments } from 'utils/data/assignments';

import { ManageConsolidationTopicsContext } from './ManageConsolidationTopics/Context';

interface PlannerContext {
  topicWeeks: TopicWeeks;
  defaultCurriculumName: string;
  doAssignmentAction: (assignment: AssignmentWithAction) => void;
  consolidation: UseQueryResult<ConsolidationTopicData>;
  selectedWeek?: Week;
  nextHandoutWeek?: Week;
}

const context = React.createContext<PlannerContext>({
  topicWeeks: {},
  defaultCurriculumName: '',
  doAssignmentAction: () => undefined,
  consolidation: {} as UseQueryResult<ConsolidationTopicData>,
});

export const usePlannerContext = () => React.useContext(context);

interface ContextProps {
  group?: GroupWithSettings;
  weekWithAssignments: WeekWithAssignments[];
  selectedWeek?: Week;
  doAssignmentAction: (assignment: AssignmentWithAction) => void;
}

export const PlannerContext = ({
  group,
  weekWithAssignments,
  selectedWeek,
  doAssignmentAction,
  children,
}: PropsWithChildren<ContextProps>) => {
  const { data: sols = [] } = useAssignableSols({ suspense: true });
  const defaultCurriculumName =
    sols.find(s => s.name === group?.scienceSettings.solName)?.baseCurriculum || '';

  const topicWeeks = useTopicWeeks(weekWithAssignments);

  const consolidation = useConsolidationTopics(group?.name, weekWithAssignments);

  const nextHandoutWeek = useMemo(() => {
    for (const { week, assignments } of weekWithAssignments) {
      for (const assignment of assignments) {
        if (assignment.spec?.contents.oneofKind !== 'generatedAssignment') continue;
        if (!assignment.generatedTimestamp) {
          return week;
        }
      }
    }
    return undefined;
  }, [weekWithAssignments]);

  return (
    <context.Provider
      value={{
        topicWeeks,
        defaultCurriculumName,
        doAssignmentAction,
        consolidation,
        selectedWeek,
        nextHandoutWeek,
      }}
    >
      <ManageConsolidationTopicsContext
        groupName={group?.name || ''}
        weekWithAssignments={weekWithAssignments}
      >
        {children}
      </ManageConsolidationTopicsContext>
    </context.Provider>
  );
};
