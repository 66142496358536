import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SchemeOfLearningSchedule } from '@sparx/api/apis/sparx/planning/v1/sol';
import { JsonEditable } from 'components/JsonEditable';
import React, { useState } from 'react';

interface JsonEditModalProps {
  scheme: SchemeOfLearningSchedule;
  onSave: (scheme: SchemeOfLearningSchedule) => void;
}

export const JsonEditModal = ({ scheme, onSave }: JsonEditModalProps) => {
  const [value, _setValue] = useState(scheme);
  const [valid, setValid] = useState(true);
  const { onOpen, isOpen, onClose } = useDisclosure({
    onOpen: () => _setValue(scheme),
  });

  const save = () => {
    onSave(value);
    onClose();
  };

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        colorScheme="blackAlpha"
        leftIcon={<FontAwesomeIcon icon={faCode} />}
      >
        Edit json
      </Button>
      <Modal isOpen={isOpen} size="6xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pb={1}>Edit Scheme of Learning</ModalHeader>
          <ModalBody>
            <JsonEditable
              messageType={SchemeOfLearningSchedule}
              original={value}
              set={_setValue}
              setValid={setValid}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} mr={2} variant="outline">
              Close
            </Button>
            <Button onClick={save} colorScheme="buttonTeal" isDisabled={!valid}>
              Save
            </Button>
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};
