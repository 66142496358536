import {
  Button as SpxButton,
  ButtonProps as SpxButtonProps,
} from '@sparx/sparx-design/components/button/Button';
import { ComponentWithAs } from '@sparx/sparx-design/ComponentWithAs';
import classNames from 'classnames';

import styles from './Button.module.css';

type Props = Omit<SpxButtonProps, 'colour'> & {
  colour?: 'primary' | 'secondary';
};

const Button: ComponentWithAs<'button', Props> = ({ colour = 'primary', className, ...rest }) => {
  return (
    <SpxButton
      {...rest}
      className={classNames(className, styles.Base, {
        [styles.Primary]: colour === 'primary',
        [styles.Secondary]: colour === 'secondary',
      })}
      variant={colour === 'primary' ? 'contained' : 'outlined'}
      colour="custom"
    />
  );
};

export default Button;
