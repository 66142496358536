import { CrossCircledIcon } from '@radix-ui/react-icons';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import checkboxStyles from '@sparx/mis-sync-import/src/shared-styles/Checkbox.module.css';
import { DebouncedInput } from '@sparx/shared-components';
import { Checkbox } from '@sparx/sparx-design/components';
import classNames from 'classnames';
import { ReactNode } from 'react';

import styles from './Panel.module.css';

interface PanelProps {
  children: ReactNode;
  header: string;
  searchPlaceholder: string;
  selectAllSubjects?: boolean;
  onSelectAllSubjects?: () => void;
  systemPanel?: boolean;
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  groupSubject?: StudentGroupType;
  className?: string;
}

/**
 * Panel for displaying a section of the page
 * @param children
 * @param header
 * @param width
 * @param searchPlaceholder
 * @param onSelectAllSubjects
 * @param selectAllSubjects
 * @param systemPanel
 * @param searchQuery the current search query
 * @param setSearchQuery callback to update the current search query when the user types into the search input
 * @param className an extra CSS class to apply to the panel
 * @constructor
 */
export const Panel = ({
  children,
  header,
  searchPlaceholder,
  onSelectAllSubjects,
  selectAllSubjects,
  systemPanel,
  searchQuery,
  setSearchQuery,
  groupSubject,
  className,
}: PanelProps) => (
  <div className={classNames(styles.Panel, className)}>
    <div
      className={classNames(styles.PanelHeading, {
        [styles.SystemPanel]: systemPanel,
      })}
    >
      {header}
    </div>
    <div
      className={classNames(styles.Search, {
        [styles.LightenBackground]: groupSubject !== StudentGroupType.CLASS,
      })}
    >
      <div className={styles.SearchInputContainer}>
        <DebouncedInput
          className={styles.SearchInput}
          placeholder={searchPlaceholder}
          value={searchQuery}
          onChange={value => setSearchQuery(value)}
          debounce={100}
        />
        {searchQuery && (
          <CrossCircledIcon
            onClick={() => setSearchQuery('')}
            className={styles.SearchClearButton}
          />
        )}
      </div>
      {onSelectAllSubjects && (
        <Checkbox
          id="all-subjects"
          label="Show all subjects"
          checked={selectAllSubjects}
          onCheckedChange={onSelectAllSubjects}
          className={classNames(checkboxStyles.Checkbox, styles.WithMargin)}
          variant="inverted"
        />
      )}
    </div>
    {children}
  </div>
);
