// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/v2/schoolgroups.proto" (package "sparx.school.v2", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { FieldMask } from '../../../../google/protobuf/field_mask';
import { Timestamp } from '../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.school.v2.SchoolGroup
 */
export interface SchoolGroup {
  /**
   * The resource name of the school group.
   * `schoolGroups/{group_id}`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The name of the School Group.
   *
   * @generated from protobuf field: string display_name = 2;
   */
  displayName: string;
  /**
   * An optional description for the School Group.
   *
   * @generated from protobuf field: string description = 3;
   */
  description: string;
  /**
   * The time the school group record was first created. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp create_time = 4;
   */
  createTime?: Timestamp;
  /**
   * The time the school group record was last updated. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp update_time = 5;
   */
  updateTime?: Timestamp;
  /**
   * The time the school group was deleted. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp delete_time = 6;
   */
  deleteTime?: Timestamp;
  /**
   * The resource names of the schools in this group. The order of
   * of the schools is not important.
   *
   * @generated from protobuf field: repeated string school_names = 7;
   */
  schoolNames: string[];
  /**
   * Annotations for services to store arbitrary data against the school group.
   * Write behaviour is merge.
   *
   * @generated from protobuf field: map<string, string> annotations = 8;
   */
  annotations: {
    [key: string]: string;
  };
  /**
   * User inputted tags for the school group.
   *
   * @generated from protobuf field: repeated string tags = 9;
   */
  tags: string[];
}
/**
 * Request message for GetSchoolGroup
 *
 * @generated from protobuf message sparx.school.v2.GetSchoolGroupRequest
 */
export interface GetSchoolGroupRequest {
  /**
   * The name of the school group.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * Request message for ListSchoolGroups
 *
 * @generated from protobuf message sparx.school.v2.ListSchoolGroupsRequest
 */
export interface ListSchoolGroupsRequest {
  /**
   * Requested page size. Server may return fewer results.
   *
   * @generated from protobuf field: int32 page_size = 1;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return.
   *
   * @generated from protobuf field: string page_token = 2;
   */
  pageToken: string;
}
/**
 * Response message for ListSchoolGroups
 *
 * @generated from protobuf message sparx.school.v2.ListSchoolGroupsResponse
 */
export interface ListSchoolGroupsResponse {
  /**
   * The school groups
   *
   * @generated from protobuf field: repeated sparx.school.v2.SchoolGroup school_groups = 1;
   */
  schoolGroups: SchoolGroup[];
  /**
   * A token to retrieve the next page of results.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * Request message for CreateSchoolGroup.
 *
 * @generated from protobuf message sparx.school.v2.CreateSchoolGroupRequest
 */
export interface CreateSchoolGroupRequest {
  /**
   * The school group to create.
   *
   * @generated from protobuf field: sparx.school.v2.SchoolGroup school_group = 1;
   */
  schoolGroup?: SchoolGroup;
}
/**
 * Request message for UpdateSchoolGroup.
 *
 * @generated from protobuf message sparx.school.v2.UpdateSchoolGroupRequest
 */
export interface UpdateSchoolGroupRequest {
  /**
   * The school group to update.
   * The name field is used to identify the group to update.
   *
   * @generated from protobuf field: sparx.school.v2.SchoolGroup school_group = 1;
   */
  schoolGroup?: SchoolGroup;
  /**
   * Required. The list of fields to update.
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
}
/**
 * Request message for DeleteSchoolGroup.
 *
 * @generated from protobuf message sparx.school.v2.DeleteSchoolGroupRequest
 */
export interface DeleteSchoolGroupRequest {
  /**
   * The name of the school group to delete.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * Request message for AddSchoolToGroup.
 *
 * @generated from protobuf message sparx.school.v2.AddSchoolToGroupRequest
 */
export interface AddSchoolToGroupRequest {
  /**
   * The name of the school group to update.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The name of the school to add.
   *
   * @generated from protobuf field: string school_name = 2;
   */
  schoolName: string;
}
/**
 * Request message for RemoveSchoolFromGroup.
 *
 * @generated from protobuf message sparx.school.v2.RemoveSchoolFromGroupRequest
 */
export interface RemoveSchoolFromGroupRequest {
  /**
   * The name of the school group to update.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The name of the school to remove.
   *
   * @generated from protobuf field: string school_name = 2;
   */
  schoolName: string;
}
/**
 * Request for ListSchoolGroupForSchool.
 *
 * @generated from protobuf message sparx.school.v2.ListSchoolGroupsForSchoolRequest
 */
export interface ListSchoolGroupsForSchoolRequest {
  /**
   * The name of the school.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
}
/**
 * @generated from protobuf message sparx.school.v2.ListSchoolGroupsForSchoolResponse
 */
export interface ListSchoolGroupsForSchoolResponse {
  /**
   * The school groups
   *
   * @generated from protobuf field: repeated sparx.school.v2.SchoolGroup school_groups = 1;
   */
  schoolGroups: SchoolGroup[];
}
// @generated message type with reflection information, may provide speed optimized methods
class SchoolGroup$Type extends MessageType<SchoolGroup> {
  constructor() {
    super('sparx.school.v2.SchoolGroup', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'description',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'create_time', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'update_time', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'delete_time', kind: 'message', T: () => Timestamp },
      {
        no: 7,
        name: 'school_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 8,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      {
        no: 9,
        name: 'tags',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.SchoolGroup
 */
export const SchoolGroup = new SchoolGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolGroupRequest$Type extends MessageType<GetSchoolGroupRequest> {
  constructor() {
    super('sparx.school.v2.GetSchoolGroupRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.GetSchoolGroupRequest
 */
export const GetSchoolGroupRequest = new GetSchoolGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolGroupsRequest$Type extends MessageType<ListSchoolGroupsRequest> {
  constructor() {
    super('sparx.school.v2.ListSchoolGroupsRequest', [
      { no: 1, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.ListSchoolGroupsRequest
 */
export const ListSchoolGroupsRequest = new ListSchoolGroupsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolGroupsResponse$Type extends MessageType<ListSchoolGroupsResponse> {
  constructor() {
    super('sparx.school.v2.ListSchoolGroupsResponse', [
      {
        no: 1,
        name: 'school_groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SchoolGroup,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.ListSchoolGroupsResponse
 */
export const ListSchoolGroupsResponse = new ListSchoolGroupsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSchoolGroupRequest$Type extends MessageType<CreateSchoolGroupRequest> {
  constructor() {
    super('sparx.school.v2.CreateSchoolGroupRequest', [
      { no: 1, name: 'school_group', kind: 'message', T: () => SchoolGroup },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.CreateSchoolGroupRequest
 */
export const CreateSchoolGroupRequest = new CreateSchoolGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSchoolGroupRequest$Type extends MessageType<UpdateSchoolGroupRequest> {
  constructor() {
    super('sparx.school.v2.UpdateSchoolGroupRequest', [
      { no: 1, name: 'school_group', kind: 'message', T: () => SchoolGroup },
      { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.UpdateSchoolGroupRequest
 */
export const UpdateSchoolGroupRequest = new UpdateSchoolGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteSchoolGroupRequest$Type extends MessageType<DeleteSchoolGroupRequest> {
  constructor() {
    super('sparx.school.v2.DeleteSchoolGroupRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.DeleteSchoolGroupRequest
 */
export const DeleteSchoolGroupRequest = new DeleteSchoolGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddSchoolToGroupRequest$Type extends MessageType<AddSchoolToGroupRequest> {
  constructor() {
    super('sparx.school.v2.AddSchoolToGroupRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.AddSchoolToGroupRequest
 */
export const AddSchoolToGroupRequest = new AddSchoolToGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveSchoolFromGroupRequest$Type extends MessageType<RemoveSchoolFromGroupRequest> {
  constructor() {
    super('sparx.school.v2.RemoveSchoolFromGroupRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.RemoveSchoolFromGroupRequest
 */
export const RemoveSchoolFromGroupRequest =
  new RemoveSchoolFromGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolGroupsForSchoolRequest$Type extends MessageType<ListSchoolGroupsForSchoolRequest> {
  constructor() {
    super('sparx.school.v2.ListSchoolGroupsForSchoolRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.ListSchoolGroupsForSchoolRequest
 */
export const ListSchoolGroupsForSchoolRequest =
  new ListSchoolGroupsForSchoolRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolGroupsForSchoolResponse$Type extends MessageType<ListSchoolGroupsForSchoolResponse> {
  constructor() {
    super('sparx.school.v2.ListSchoolGroupsForSchoolResponse', [
      {
        no: 1,
        name: 'school_groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SchoolGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.ListSchoolGroupsForSchoolResponse
 */
export const ListSchoolGroupsForSchoolResponse =
  new ListSchoolGroupsForSchoolResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.school.v2.SchoolGroupsService
 */
export const SchoolGroupsService = new ServiceType(
  'sparx.school.v2.SchoolGroupsService',
  [
    {
      name: 'GetSchoolGroup',
      options: {
        'google.api.http': { get: '/v2/{name=schoolGroups/*}' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:schoolgroup',
          domain: '{name}',
        },
      },
      I: GetSchoolGroupRequest,
      O: SchoolGroup,
    },
    {
      name: 'ListSchoolGroups',
      options: {
        'google.api.http': { get: '/v2/schoolGroups' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:schoolgroup',
          domain: 'schoolGroups',
        },
      },
      I: ListSchoolGroupsRequest,
      O: ListSchoolGroupsResponse,
    },
    {
      name: 'CreateSchoolGroup',
      options: {
        'google.api.http': { post: '/v2/schoolGroups', body: 'school_group' },
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:schoolgroup',
          domain: 'schoolGroups',
        },
      },
      I: CreateSchoolGroupRequest,
      O: SchoolGroup,
    },
    {
      name: 'UpdateSchoolGroup',
      options: {
        'google.api.http': {
          patch: '/v2/{school_group.name=schoolGroups/*}',
          body: 'school_group',
        },
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:schoolgroup',
          domain: '{school_group.name}',
        },
      },
      I: UpdateSchoolGroupRequest,
      O: SchoolGroup,
    },
    {
      name: 'DeleteSchoolGroup',
      options: {
        'google.api.http': { delete: '/v2/{name=schoolGroups/*}' },
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:schoolgroup',
          domain: '{name}',
        },
      },
      I: DeleteSchoolGroupRequest,
      O: SchoolGroup,
    },
    {
      name: 'AddSchoolToGroup',
      options: {
        'google.api.http': {
          patch: '/v2/{name=schoolGroups/*}/addSchool',
          body: 'school_name',
        },
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:schoolgroup',
          domain: '{name}',
        },
      },
      I: AddSchoolToGroupRequest,
      O: SchoolGroup,
    },
    {
      name: 'RemoveSchoolFromGroup',
      options: {
        'google.api.http': {
          patch: '/v2/schoolGroups/{name}/removeSchool',
          body: 'school_name',
        },
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:schoolgroup',
          domain: '{name}',
        },
      },
      I: RemoveSchoolFromGroupRequest,
      O: SchoolGroup,
    },
    {
      name: 'ListSchoolGroupsForSchool',
      options: {
        'google.api.http': { get: '/v2/schoolGroups/{school_name=schools/*}' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:school',
          domain: '{school_name}',
        },
      },
      I: ListSchoolGroupsForSchoolRequest,
      O: ListSchoolGroupsForSchoolResponse,
    },
  ],
);
