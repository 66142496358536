import {
  Box,
  Button,
  Flex,
  HStack,
  Link as ChakraLink,
  List,
  ListItem,
  Select,
  Text,
} from '@chakra-ui/react';
import { faBan, faCalendar, faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListIndependentLearningSummariesResponse_UserSummary } from '@sparx/api/apis/sparx/science/packages/v1/independentlearning';
import {
  ListPackageSummariesResponse,
  PackageSummary,
} from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Assignment } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { Subject } from '@sparx/api/apis/sparx/science/v1/subject';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { CompletionSummary, getCompletionSummary } from '@sparx/packageactivity';
import { UseQueryResult } from '@tanstack/react-query';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { useQueryState } from 'api/client';
import { useIndependentLearningSummaries } from 'api/independentlearning';
import { useBatchPackageSummaries } from 'api/packages';
import { useAssignment } from 'api/planner';
import { dateInWeek, useStudentsLookup, useWeeks } from 'api/school';
import classNames from 'classnames';
import { CancelledWarning, useCancelledStudents } from 'components/CancelledUtils';
import { useClientEvent } from 'components/ClientEventProvider';
import { StudentName } from 'components/Names';
import { ProgressBar } from 'components/progressbar/ProgressBar';
import { DataTable } from 'components/table/DataTable';
import { InfoTooltip } from 'components/tooltip/InfoTooltip';
import { InlineTextTooltip, Tooltip } from 'components/tooltip/Tooltip';
import { format, isBefore } from 'date-fns';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  getCompletionDateColour,
  getCompletionDateDays,
  getCompletionDateText,
} from 'utils/completion';
import { toTimeSpentDuration } from 'utils/duration';
import { articleCancellingHomework } from 'utils/knowledgeBaseArticles';
import { plural } from 'utils/plural';
import { SubjectToName } from 'utils/subjects';

import styles from './HandInTable.module.css';

interface HandinTableProps {
  assignmentID: string;
  assignments?: Assignment[];
  assignmentLinker?: (assignmentID: string, userID?: string) => string;
}

interface HandinRow {
  student?: Student;
  summary: PackageSummary;
  ilSummary?: ListIndependentLearningSummariesResponse_UserSummary;
  status: CompletionSummary;
  displayStatus: CompletionSummary | undefined;
  assignment?: Assignment;
  prevWeeks?: (CompletionSummary | undefined)[];
}

const fallbackNameSortFn = (a: Row<HandinRow>, b: Row<HandinRow>) =>
  (a.original.student?.givenName || '').localeCompare(b.original.student?.givenName || '') ||
  (a.original.student?.familyName || '').localeCompare(b.original.student?.familyName || '');

const completionSortFn = (a: Row<HandinRow>, b: Row<HandinRow>) => {
  const aStatus = a.original.displayStatus;
  const bStatus = b.original.displayStatus;

  if (!aStatus && !bStatus) {
    return fallbackNameSortFn(a, b);
  } else if (!aStatus) {
    return 1;
  } else if (!bStatus) {
    return -1;
  }

  if (aStatus.cancelled && bStatus.cancelled) {
    return fallbackNameSortFn(a, b);
  } else if (aStatus.cancelled) {
    return 1;
  } else if (bStatus.cancelled) {
    return -1;
  }

  return (
    (aStatus.percentages['C']?.roundedPercentage || 0) -
      (bStatus.percentages['C']?.roundedPercentage || 0) ||
    (aStatus.percentages['W']?.roundedPercentage || 0) -
      (bStatus.percentages['W']?.roundedPercentage || 0) ||
    fallbackNameSortFn(a, b)
  );
};

const completionDateSortFn = (a: Row<HandinRow>, b: Row<HandinRow>) => {
  const aStatus = a.original.displayStatus;
  const bStatus = b.original.displayStatus;

  if (!aStatus && !bStatus) {
    return fallbackNameSortFn(a, b);
  } else if (!aStatus) {
    return 1;
  } else if (!bStatus) {
    return -1;
  }

  if (aStatus.cancelled && bStatus.cancelled) {
    return fallbackNameSortFn(a, b);
  } else if (aStatus.cancelled) {
    return 1;
  } else if (bStatus.cancelled) {
    return -1;
  }

  return (
    (a.original.summary.state?.completionTimestamp?.seconds || 0) -
      (b.original.summary.state?.completionTimestamp?.seconds || 0) || completionSortFn(a, b)
  );
};

const useCompletionSubjectFilter = () =>
  useQueryState(`hand-in-selected-subject`, Subject.SUBJECT_UNDEFINED);

const SubjectCompletionHeader = () => {
  const { sendEvent } = useClientEvent();
  const [completionSubject, _setCompletionSubject] = useCompletionSubjectFilter();
  const setCompletionSubject = (s: Subject) => {
    _setCompletionSubject(s);
    sendEvent(
      { action: 'change_completion_subject', category: 'hand-in' },
      {
        from: SubjectToName[completionSubject] || 'All',
        to: SubjectToName[s] || 'All',
      },
    );
  };

  return (
    <Flex direction={{ base: 'column', lg: 'row' }} alignItems="center">
      <Text mr={2} my={1} whiteSpace="pre">
        Completion for
      </Text>
      <Select
        value={completionSubject}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          setCompletionSubject(+e.target.value as Subject);
        }}
        width={40}
        my={{ base: 0, lg: -4 }}
      >
        <option value={Subject.SUBJECT_UNDEFINED}>All Subjects</option>
        <option value={Subject.BIOLOGY}>Biology</option>
        <option value={Subject.CHEMISTRY}>Chemistry</option>
        <option value={Subject.PHYSICS}>Physics</option>
      </Select>
    </Flex>
  );
};

const NAWithTooltip = ({ label }: { label: string }) => (
  <Tooltip label={label} placement="top">
    <Text textAlign="center" color="gray.500">
      N/A
    </Text>
  </Tooltip>
);

const NACompletion = () => {
  const [completionSubject, _] = useCompletionSubjectFilter();
  return (
    <NAWithTooltip
      label={`No ${SubjectToName[completionSubject].toLowerCase()} questions were set this week`}
    />
  );
};

const NACancelled = () => <NAWithTooltip label="Homework cancelled" />;

const useColumns = (
  assignmentID: string,
  splitCompletionBySubject: boolean,
  prevWeeks: { id: string; title: string }[],
  assignmentLinker?: (assignmentID: string, userID?: string) => string,
) => {
  const columnHelper = useMemo(() => createColumnHelper<HandinRow>(), []);

  return useMemo(() => {
    const toAnsHist = (data: HandinRow) =>
      answerHistoryUrlForUser(data.student?.studentId || '', assignmentID);

    return [
      columnHelper.accessor(row => row.student, {
        header: 'Name',
        sortingFn: (a, b) => {
          // Sort by family name, then given name
          const aName = `${a.original.student?.familyName} ${a.original.student?.givenName}`;
          const bName = `${b.original.student?.familyName} ${b.original.student?.givenName}`;
          return aName.localeCompare(bName);
        },
        meta: {
          headerCellProps: {
            borderRightWidth: 1,
          },
          bodyCellProps: {
            borderRightWidth: 1,
            className: styles.HoverLinkUnderline,
          },
          linkTo: (data: HandinRow) => ({
            to: `/teacher/student/${data.student?.studentId}/summary`,
            options: { state: { back: `${window.location.pathname}?${window.location.search}` } },
          }),
        },
        cell: info => (
          <ChakraLink
            as={Link}
            to={`/teacher/student/${info.row.original.student?.studentId}/summary`}
            state={{ back: `${window.location.pathname}?${window.location.search}` }}
            onClick={e => e.stopPropagation()}
            id={`user-${info.row.original.student?.studentId}`}
          >
            <StudentName student={info.getValue()} />
          </ChakraLink>
        ),
      }),
      columnHelper.accessor(row => row.displayStatus?.percentages.C.roundedPercentage || 'N/A', {
        header: () => {
          if (!splitCompletionBySubject) {
            return <Text>Completion</Text>;
          }
          return <SubjectCompletionHeader />;
        },
        id: 'completion',
        meta: {
          headerCellProps: {
            borderRightWidth: splitCompletionBySubject ? 1 : undefined,
          },
          bodyCellProps: {
            borderRightWidth: splitCompletionBySubject ? 1 : undefined,
            className: styles.AnswersLink,
          },
          linkTo: toAnsHist,
        },
        sortingFn: completionSortFn,
        cell: info => {
          const status = info.row.original.displayStatus;
          if (!status) {
            return <NACompletion />;
          }
          return (
            <HStack spacing={2} as={Link} to={toAnsHist(info.row.original)}>
              <CompletionCell
                percent={status.percentages['C'].roundedPercentage}
                late={info.row.original.status.late}
              />
              <ProgressBar
                showTooltip={true}
                values={[
                  {
                    value: status.percentages['C'].roundedPercentage,
                    color: 'green.400',
                    title: 'Complete',
                  },
                  {
                    value: status.percentages['W'].roundedPercentage,
                    color: 'red.400',
                    title: 'Incomplete',
                  },
                  {
                    value: status.percentages['U'].roundedPercentage,
                    color: 'gray.100',
                    textColor: 'gray.500',
                    title: 'Unattempted',
                  },
                ]}
              />
              {status.cancelled && (
                <Tooltip label="Homework cancelled" placement="top">
                  <Box as="span" color="red.600">
                    <FontAwesomeIcon icon={faBan} fixedWidth />
                  </Box>
                </Tooltip>
              )}
            </HStack>
          );
        },
      }),
      columnHelper.accessor(
        row =>
          row.summary.state
            ? row.summary.state.questionTimeSeconds + row.summary.state.supportTimeSeconds
            : 0,
        {
          header: 'Working time',
          meta: {
            align: 'center',
            bodyCellProps: {
              className: styles.AnswersLink,
            },
            linkTo: toAnsHist,
          },
          cell: info => toTimeSpentDuration(info.getValue()),
        },
      ),
      columnHelper.accessor(
        row =>
          getCompletionDateDays(
            row.assignment!.endTimestamp!,
            row.summary.state?.completionTimestamp,
          ),
        {
          sortingFn: completionDateSortFn,
          header: 'Completion day',
          meta: {
            align: 'center',
            bodyCellProps: {
              className: styles.AnswersLink,
            },
            linkTo: toAnsHist,
          },
          cell: info =>
            info.row.original.displayStatus?.cancelled &&
            !info.row.original.summary.state?.completionTimestamp ? (
              <NACancelled />
            ) : (
              <Text color={getCompletionDateColour(info.getValue())}>
                {getCompletionDateText(info.getValue())}
              </Text>
            ),
        },
      ),
      columnHelper.display({
        header: 'Answers',
        meta: {
          align: 'center',
          bodyCellProps: {
            className: classNames(styles.AnswersLink, styles.HoverLinkUnderline),
          },
          linkTo: toAnsHist,
        },
        cell: info => (
          <Button
            as={Link}
            my={-2}
            size="sm"
            to={toAnsHist(info.row.original)}
            rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
            colorScheme="buttonTeal"
            variant="ghost"
            onClick={e => e.stopPropagation()}
            _hover={{
              bg: 'none',
            }}
          >
            View
          </Button>
        ),
      }),
      columnHelper.accessor(row => row.ilSummary?.totalSeconds || 0, {
        id: 'independentlearning',
        header: () => (
          <HStack justifyContent="center">
            <Text>IL</Text>
            <InfoTooltip
              text="Time spent working on Independent Learning between homework hand-out and hand-in"
              color="white"
            />
          </HStack>
        ),
        meta: {
          align: 'center',
          headerCellProps: {
            borderLeftWidth: 1,
          },
          bodyCellProps: {
            borderLeftWidth: 1,
            className: styles.HoverLinkUnderline,
          },
          linkTo: (data: HandinRow) => ({
            to: `/teacher/student/${data.student?.studentId}/summary#activity-feed`,
            options: { state: { back: `${window.location.pathname}?${window.location.search}` } },
          }),
        },
        cell: info => (
          <ChakraLink
            as={Link}
            to={`/teacher/student/${info.row.original.student?.studentId}/summary#activity-feed`}
            state={{ back: `${window.location.pathname}?${window.location.search}` }}
            onClick={e => e.stopPropagation()}
            color={info.getValue() == 0 ? 'gray.500' : undefined}
          >
            {toTimeSpentDuration(info.getValue())}
          </ChakraLink>
        ),
      }),
      ...(prevWeeks.length > 0
        ? [
            columnHelper.group({
              header: 'Previous completion',
              meta: {
                align: 'center',
                headerCellProps: {
                  borderLeftWidth: 1,
                },
                bodyCellProps: {
                  borderLeftWidth: 1,
                },
              },
              columns: prevWeeks.map((week, i) =>
                columnHelper.accessor(row => row?.prevWeeks?.[i], {
                  id: `prev-week-${week}-${i}`,
                  header: () =>
                    assignmentLinker ? (
                      <ChakraLink
                        as={Link}
                        to={assignmentLinker(week.id)}
                        onClick={e => e.stopPropagation()}
                      >
                        {week.title}
                      </ChakraLink>
                    ) : (
                      week.title
                    ),
                  enableSorting: false,
                  meta: {
                    align: 'center',
                    headerCellProps: {
                      pl: i > 0 ? 0 : 3,
                      pr: i < prevWeeks.length - 1 ? 0 : 3,
                      borderLeftWidth: i == 0 ? 1 : undefined,
                    },
                    bodyCellProps: {
                      pl: i > 0 ? 0 : 3,
                      pr: i < prevWeeks.length - 1 ? 0 : 3,
                      borderLeftWidth: i == 0 ? 1 : undefined,
                    },
                  },
                  cell: info => {
                    const status = info.getValue();
                    if (!status) {
                      return <NACompletion />;
                    }
                    if (status.cancelled) {
                      return <NACancelled />;
                    }
                    return (
                      <Flex justifyContent="center">
                        <CompletionCell
                          link={
                            assignmentLinker &&
                            assignmentLinker(week.id, info.row.original.student?.studentId || '')
                          }
                          percent={status.percentages['C'].roundedPercentage}
                        />
                      </Flex>
                    );
                  },
                }),
              ),
            }),
          ]
        : []),
    ];
  }, [columnHelper, assignmentID, splitCompletionBySubject, prevWeeks, assignmentLinker]);
};

const useRows = (
  assignment: Assignment | undefined,
  studentLookup: Record<string, Student | undefined>,
  summaries: ListPackageSummariesResponse | undefined,
  ilLookup: Map<string, ListIndependentLearningSummariesResponse_UserSummary>,
  completionSubject: Subject,
  prevSummaries: UseQueryResult<ListPackageSummariesResponse, unknown>[],
) =>
  useMemo(() => {
    return (
      summaries?.packageSummaries?.reduce<{
        rows: HandinRow[];
        missingStudents: number;
        hasSubjectSplit: boolean;
      }>(
        ({ rows, missingStudents, hasSubjectSplit }, s) => {
          const student = studentLookup[s.studentName.replace('students/', '')];
          if (!student) {
            // Exclude non-active students
            return { rows, missingStudents: missingStudents + 1, hasSubjectSplit };
          }
          const ilSummary = ilLookup.get(s.studentName);

          const subjectStatus = new Map(
            s.state?.subjectCompletion.map(sc => [
              sc.subject,
              getCompletionSummary(sc.completion, undefined, s.cancelledTime),
            ]) || [],
          );

          const overallStatus = getCompletionSummary(
            s.state?.completion,
            undefined,
            s.cancelledTime,
          );
          const displayStatus =
            completionSubject !== Subject.SUBJECT_UNDEFINED
              ? subjectStatus.get(completionSubject)
              : overallStatus;

          const prevWeeks = prevSummaries.map(({ data: pSums }) => {
            const summary = pSums?.packageSummaries.find(p => p.studentName === s.studentName);
            return summary
              ? getCompletionSummary(summary.state?.completion, undefined, summary.cancelledTime)
              : undefined;
          });

          rows.push({
            student,
            summary: s,
            ilSummary,
            status: overallStatus,
            displayStatus,
            assignment,
            prevWeeks,
          });
          return {
            rows,
            missingStudents,
            hasSubjectSplit: hasSubjectSplit || subjectStatus.size > 0,
          };
        },
        { rows: [], missingStudents: 0, hasSubjectSplit: false },
      ) || { rows: [], missingStudents: 0, hasSubjectSplit: false }
    );
  }, [assignment, studentLookup, summaries, ilLookup, completionSubject, prevSummaries]);

export const HandinTable = ({ assignmentID, assignments, assignmentLinker }: HandinTableProps) => {
  const { data: assignment } = useAssignment(assignmentID, { suspense: true });
  const { data: studentsMap = {} } = useStudentsLookup({ suspense: true });
  const { data: ilSummaries } = useIndependentLearningSummaries(assignmentID, { suspense: true });
  const { data: weeks } = useWeeks({ suspense: true });

  const [prevAssignmentIDs, previousWeeks] = useMemo(() => {
    const assignmentStartDate =
      assignment?.startTimestamp && Timestamp.toDate(assignment?.startTimestamp);

    const prev = assignments
      ? assignments
          .filter(
            a =>
              assignmentStartDate &&
              a.startTimestamp &&
              isBefore(Timestamp.toDate(a.startTimestamp), assignmentStartDate),
          )
          .sort((a, b) => (a.startTimestamp?.seconds || 0) - (b.startTimestamp?.seconds || 0))
          .slice(-5) // Last 5 assignments
          .map(a => ({
            assignment: a,
            week: weeks?.find(
              w => a.startTimestamp && dateInWeek(w, Timestamp.toDate(a.startTimestamp)),
            ),
          }))
      : [];

    return [
      prev.map(v => v.assignment.name.split('/')[1]),
      prev.map(v => ({
        id: v.assignment.name.split('/')[1],
        title: 'W' + (v.week ? v.week?.index.toString() : '?'),
      })),
    ];
  }, [assignments, assignment, weeks]);

  const [{ data: summaries }, ...prevSummaries] = useBatchPackageSummaries(
    [assignmentID, ...prevAssignmentIDs],
    { suspense: true },
  );

  const [completionSubject, _setCompletionSubject] = useCompletionSubjectFilter();

  const ilLookup = useMemo(
    () => new Map(ilSummaries?.summaries.map(s => [s.studentName, s]) || []),
    [ilSummaries],
  );

  const { rows, missingStudents, hasSubjectSplit } = useRows(
    assignment,
    studentsMap,
    summaries,
    ilLookup,
    completionSubject,
    prevSummaries,
  );

  const columns = useColumns(assignmentID, hasSubjectSplit, previousWeeks, assignmentLinker);

  // If we have a user in the search params, highlight the row and scroll to it.
  // The highlighting will fade after 5 seconds.
  const [highlightedUser, setHighlightedUser] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const userID = searchParams.get('user');
    if (userID) {
      setHighlightedUser(userID);
      setSearchParams(prev => {
        prev.delete('user');
        return prev;
      });
    }
  }, [searchParams, setSearchParams]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setHighlightedUser(undefined);
    }, 5000);
    const scrollTimeout = setTimeout(() => {
      document
        .getElementById(`user-${highlightedUser}`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 50);
    return () => {
      clearTimeout(timeout);
      clearTimeout(scrollTimeout);
    };
  }, [highlightedUser]);

  if (!assignment) {
    return <>Assignment not found</>; // TODO
  }

  const summaryRows = !assignment.cancelledTime ? rows.filter(r => !r.status.cancelled) : rows;

  const completed = summaryRows.filter(
    r => r.status.percentages['C'].roundedPercentage >= 100,
  ).length;

  return (
    <>
      <Flex px={2} mt={4} mx={-4} whiteSpace="nowrap" flexWrap="wrap" justifyContent="center">
        {assignment.startTimestamp && (
          <HStack spacing={3} mx={4} my={1}>
            <Text color="gray.500">
              <FontAwesomeIcon icon={faCalendar} />
            </Text>
            <Text fontWeight="bold">Set date/time:</Text>
            <Text>{format(Timestamp.toDate(assignment.startTimestamp), 'EEE do LLL p')}</Text>
          </HStack>
        )}
        {assignment.endTimestamp && (
          <HStack spacing={3} mx={4} my={1}>
            <Text color="gray.500">
              <FontAwesomeIcon icon={faCalendar} />
            </Text>
            <Text fontWeight="bold">Due date/time:</Text>
            <Text>{format(Timestamp.toDate(assignment.endTimestamp), 'EEE do LLL p')}</Text>
          </HStack>
        )}
        <HStack spacing={3} mx={4} my={1}>
          <Text color="gray.500">
            <FontAwesomeIcon icon={faCheck} />
          </Text>
          <Text fontWeight="bold">Compulsory completion:</Text>
          <Text>
            {completed}/{summaryRows.length}
          </Text>
        </HStack>
      </Flex>
      <CancelledBanner assignment={assignment} summaries={summaries} studentsMap={studentsMap} />
      <DataTable
        mt={4}
        data={rows}
        columns={columns}
        defaultSort={[{ id: `completion`, desc: false }]}
        className={styles.HandInTable}
        rowIsHighlighted={row =>
          row.student?.studentId === highlightedUser ? 'blue.50' : undefined
        }
      />
      {missingStudents > 0 && (
        <Text textAlign="center" mt={4} fontSize="sm">
          {missingStudents} student{missingStudents != 1 ? 's' : ''} who{' '}
          {missingStudents != 1 ? 'were' : 'was'} originally set this homework no longer exists in
          Sparx, they are excluded from the data above.
        </Text>
      )}
    </>
  );
};

const answerHistoryUrlForUser = (userID: string, assignmentID: string) =>
  `/teacher/handin/answers?${queryString.stringify({
    assignment: assignmentID,
    user: userID,
  })}`;

interface CompletionCellProps {
  percent: number;
  late?: boolean;
  link?: string;
}

const CompletionCell = ({ percent, late, link }: CompletionCellProps) => {
  const complete = percent >= 100;

  let additional = {};
  if (link) {
    additional = {
      as: Link,
      to: link,
      _hover: {
        background: complete ? 'green.100' : late ? 'red.100' : 'gray.100',
        cursor: 'pointer',
      },
    };
  }

  return (
    <Box
      {...additional}
      width={10}
      height={10}
      display="flex"
      alignItems="center"
      justifyContent="center"
      my={-3}
      borderRadius="sm"
      flex="0 0 auto"
    >
      {complete ? (
        <Text color="green">
          <FontAwesomeIcon icon={faCheck} />
        </Text>
      ) : (
        <Text color={late ? 'red' : 'blue.800'} fontSize="sm" fontWeight="bold">
          {percent}%
        </Text>
      )}
    </Box>
  );
};

const CancelledBanner = ({
  assignment,
  summaries,
  studentsMap,
}: {
  assignment: Assignment;
  summaries?: ListPackageSummariesResponse;
  studentsMap: Record<string, Student | undefined>;
}) => {
  const students = useCancelledStudents(studentsMap, summaries);

  if (!assignment.cancelledTime && students.length === 0) {
    return null;
  }

  return (
    <CancelledWarning my={2} justifyContent="center">
      <Text>
        This homework has been{' '}
        <strong>
          cancelled
          {students.length > 0 && !assignment.cancelledTime && (
            <>
              {' '}
              for{' '}
              <InlineTextTooltip text={`${students.length} ${plural(students.length, 'student')}`}>
                <List>
                  {students.map((s, i) => (
                    <ListItem key={i}>
                      <StudentName student={s} />
                    </ListItem>
                  ))}
                </List>
              </InlineTextTooltip>
            </>
          )}
        </strong>
        .{' '}
        <ChakraLink
          textDecoration="underline"
          href={articleCancellingHomework}
          target="_blank"
          ml={2}
        >
          More info
        </ChakraLink>
      </Text>
    </CancelledWarning>
  );
};
