import { Link as ChakraLink, Text } from '@chakra-ui/react';
import { faCheck, faCircleXmark, faWifi } from '@fortawesome/free-solid-svg-icons';
import { AnnotationKeys } from '@sparx/schoolutils';
import { useSchool } from 'api/school';
import { articleIdConnectMIS, supportEmailAddress } from 'utils/knowledgeBaseArticles';

import { IsRolloverProps, PageTab, TabDetails } from '..';
import { ActionBox } from '../components/ActionBox';

const title = 'MIS Connection';
export const MISWondePath = 'https://edu.wonde.com/settings/company/A199180804';

const describeStatus = (status: string) => {
  switch (status) {
    case 'audited':
      return 'Connected';
    case 'approved':
      return 'Pending approval from Wonde';
    case 'pending':
      return 'Not yet approved';
    case 'declined':
      return 'Declined';
    case 'revoked':
      return 'Revoked';
    case 'failed':
      return 'Failed';
    default:
      return 'Not yet requested';
  }
};

const Page = (_: IsRolloverProps) => {
  const { isWondeAuthorised, statusDescription } = useMISStatus({ suspense: true });

  return (
    <>
      <Text mb={4}>
        Sparx Science imports your classes and students from your MIS and keeps them in sync
        automatically. This connection is shared across Sparx systems so may already be connected if
        your school already uses another Sparx system.
      </Text>
      <ActionBox
        heading={
          <strong>
            {isWondeAuthorised ? (
              <>We were able to connect to your MIS.</>
            ) : (
              <>
                We weren&apos;t able to connect to your MIS, please try again or{' '}
                <ChakraLink href={`mailto:${supportEmailAddress}`} textDecoration="underline">
                  get in touch
                </ChakraLink>
                .
              </>
            )}
          </strong>
        }
        // Set the bg colour rather than using the complete property as we don't want the check mark
        bgColor={isWondeAuthorised ? 'green.50' : undefined}
        faIcon={isWondeAuthorised ? faCheck : faCircleXmark}
        button={{
          text: `${isWondeAuthorised ? 'Manage' : 'Connect'} MIS`,
          path: MISWondePath,
          external: true,
        }}
        knowledgeBaseArticleId={articleIdConnectMIS}
      >
        <strong>Status:</strong> {statusDescription}
      </ActionBox>
    </>
  );
};

const useTabDetails = (_: IsRolloverProps): TabDetails => {
  const { isWondeAuthorised, statusDescription, isSuccess } = useMISStatus({ suspense: false });

  return {
    title,
    subtitle: isSuccess ? statusDescription : undefined,
    icon: faWifi,
    status: !isSuccess ? 'loading' : isWondeAuthorised ? 'complete' : 'error',
    statusText: !isSuccess ? undefined : isWondeAuthorised ? 'Connected' : 'Not connected',
  };
};

export const useMISStatus = ({ suspense }: { suspense: boolean }) => {
  const { data: school, isSuccess } = useSchool({ suspense });
  const authStatus = school?.annotations[AnnotationKeys.WondeAuthStatus] || 'Unknown';
  const isWondeAuthorised = authStatus === 'audited';
  const statusDescription = describeStatus(authStatus);

  return {
    authStatus,
    isWondeAuthorised,
    statusDescription,
    isSuccess,
  };
};

const MISTab: PageTab = {
  path: 'mis',
  detailsHook: useTabDetails,
  page: Page,
};
export default MISTab;
