import { Tab, TabList, Tabs, Text } from '@chakra-ui/react';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { useGroupOfType, useGroups, useStudents } from 'api/school';
import {
  StudentWithSettings,
  useStudentSettings,
  useStudentsWithSettings,
} from 'api/scienceschool';
import { NotFound } from 'components/errorpages/NotFound';
import { StudentName } from 'components/Names';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';

export const SuspenseStudentView = () => {
  const { studentID = '' } = useParams();
  return (
    <SuspenseRoute>
      <StudentView studentID={studentID} key={studentID} />
    </SuspenseRoute>
  );
};

export interface StudentViewContext {
  student: StudentWithSettings;
  classGroup?: Group;
  regGroup?: Group;
  backLink?: string;
}

const StudentViewContext = React.createContext<StudentViewContext>({} as StudentViewContext);
export const useStudentViewContext = () => useContext(StudentViewContext);

const StudentView = ({ studentID }: { studentID: string }) => {
  const { data: studentsData } = useStudents({ suspense: true });
  const { data: studentSettings } = useStudentSettings({ suspense: true });
  const students = useStudentsWithSettings(studentsData || [], studentSettings);
  const student = students.find(s => s.studentId === studentID);

  const { data: groups } = useGroups({ suspense: true });

  const classGroup = useGroupOfType(student, groups, StudentGroupType.CLASS_SCIENCE);
  const regGroup = useGroupOfType(student, groups, StudentGroupType.TUTORGROUP);

  const classID = classGroup?.name.split('studentGroups/')[1];
  const { state } = useLocation();
  const backLink = state?.back || (classID ? `/teacher/student?group=${classID}` : undefined);

  const tabs = useMemo(
    () => [
      { name: 'Details', path: `/teacher/student/${studentID}/details` },
      { name: 'Summary', path: `/teacher/student/${studentID}/summary` },
    ],
    [studentID],
  );
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(tabs.findIndex(t => t.path === location.pathname));
  useEffect(() => {
    const currentTab = tabs.findIndex(t => t.path === location.pathname);
    if (currentTab !== undefined) setCurrentTab(currentTab);
  }, [tabs, setCurrentTab, location]);

  if (!student) {
    return <NotFound />;
  }

  return (
    <StudentViewContext.Provider value={{ student, classGroup, regGroup, backLink }}>
      <PageContainer>
        <PageTitle
          pageTitleOverride={`${student.givenName} ${student.familyName}`}
          title={
            <>
              <Text as="span">
                <StudentName student={student} />
              </Text>
              {classGroup && (
                <Text as="span" color="gray.500" ml={4}>
                  ({classGroup.displayName})
                </Text>
              )}
            </>
          }
          backButton={backLink}
        />
        <Tabs index={currentTab} onChange={setCurrentTab} colorScheme="buttonTeal">
          <TabList>
            {tabs.map((tab, index) => (
              <Tab key={index} value={index} as={Link} to={tab.path}>
                {tab.name}
              </Tab>
            ))}
          </TabList>
        </Tabs>
        <SuspenseRoute>
          <Outlet />
        </SuspenseRoute>
      </PageContainer>
    </StudentViewContext.Provider>
  );
};
