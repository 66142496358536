import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  Heading,
  Hide,
  Image,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FixUpTaskStatus } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { useCreateAssessmentFixUpPackage } from 'api/assessment';
import { useUserType } from 'api/sessions';
import { useBackLink } from 'app/BackLink';
import { StudentNavigation } from 'app/StudentNavigation';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { Warning } from 'components/warning';
import { ReactNode, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { AssessmentFixUpTask, useAssessmentFixUpTasks } from './hooks';
import assessmentIcon from './icons/icon_assessments.svg';
import desatIcon from './icons/icon-desat-assessment.png';

export const AssessmentsView = () => {
  // Show a back button to the teacher interface if the user is a teacher
  const { isTeacher } = useUserType();
  useBackLink(isTeacher ? '/teacher' : undefined);
  return (
    <StudentNavigation>
      <CoreAssessmentsView />
    </StudentNavigation>
  );
};

const CoreAssessmentsView = () => {
  const fixupTasks = useAssessmentFixUpTasks({ suspense: true });

  if (!fixupTasks || fixupTasks.length === 0) {
    // We shouldn't of been able to get here if there are tasks, so
    // redirect to root, even if they are a teacher
    return <Navigate to="/" replace />;
  }

  return (
    <PageContainer>
      <Flex>
        <Box pt={2} position="relative">
          <PageTitle title="Assessment Fix up" />
          <Text mb={4}>
            After completing an assessment, you&apos;ll have access to{' '}
            <Text as="span" fontStyle="italic">
              &apos;fix up activities&apos;
            </Text>{' '}
            to help you address any questions you answered incorrectly or didn&apos;t have time to
            complete.
          </Text>
        </Box>
        <Hide below="sm">
          <Image src={assessmentIcon} width="200px" ml={4} />
        </Hide>
      </Flex>
      <VStack spacing={4}>
        {fixupTasks.map((task, idx) => (
          <FixUpCard key={idx} task={task} />
        ))}
      </VStack>
    </PageContainer>
  );
};

const FixUpCard = ({ task }: { task: AssessmentFixUpTask }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const generate = useCreateAssessmentFixUpPackage();

  const { availableMarks, score } = task.assessment.questions.reduce(
    (acc, question) => {
      acc.availableMarks += question.availableMarks;
      acc.score +=
        task.studentAssessment?.marks.find(mark => mark.assessmentQuestionName === question.name)
          ?.score || 0;
      return acc;
    },
    { availableMarks: 0, score: 0 },
  );

  const onClick = useCallback(() => {
    if (score >= availableMarks) {
      return;
    }
    if (task.package) {
      navigate(`/${task.package.name}`);
    } else if (generate.isIdle) {
      generate.mutateAsync(
        { assessmentName: task.assessmentName },
        {
          onSuccess: data => {
            const pkg = data.package[0];
            const task = pkg.contents!.tasks[0]!;
            navigate(`/${task.name}`);
          },
          onError: () => {
            toast({
              title: 'Error',
              description: 'An error occured, please try again.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            generate.reset();
          },
        },
      );
    }
  }, [generate, navigate, task, toast, score, availableMarks]);

  const noData =
    task.status === FixUpTaskStatus.NO_DATA || task.status === FixUpTaskStatus.UNSPECIFIED;

  const allCorrect = score >= availableMarks;
  const bgColor = allCorrect ? 'green' : 'teal';

  return (
    <Card borderLeftWidth={6} borderColor={allCorrect ? 'green.500' : 'teal.600'} width="100%">
      <CardBody>
        <Heading size="md" mb={4}>
          {task.assessment.displayName}
        </Heading>
        {noData ? (
          <>
            <Warning status="warning">
              We seem to be missing your results for this assessment. Please ask your teacher to
              enter your marks or mark you as absent.
            </Warning>
          </>
        ) : (
          <Button
            height="unset"
            px={[6, 8]}
            py={[4, 6]}
            variant="unstyled"
            maxWidth="500px"
            width="100%"
            bgGradient={`linear(134deg, ${bgColor}.500, ${bgColor}.400, ${bgColor}.500)`}
            color="white"
            transition="background 0.2s ease-in-out"
            backgroundSize={'200%'}
            _hover={{ backgroundPositionX: '100%' }}
            _active={{ backgroundPositionX: '50%' }}
            onClick={onClick}
            overflow={'hidden'}
            cursor={generate.isLoading ? 'not-allowed' : undefined}
          >
            <Image src={desatIcon} position="absolute" right={-6} bottom={-6} width="170px" />
            <Grid
              gridTemplateColumns={['1fr', '1fr 1fr']}
              gridAutoFlow="column"
              width="100%"
              gap={1}
            >
              <StatBlock
                gridCol={1}
                score={score}
                total={availableMarks}
                hasData={task.status !== FixUpTaskStatus.ABSENT && !!task.studentAssessment}
                noDataText={task.status === FixUpTaskStatus.ABSENT ? 'Absent' : 'No data'}
                subtitle="Overall mark"
              >
                Assessment
              </StatBlock>

              {score >= availableMarks ? (
                <>
                  <GridItem
                    gridColumn={{ base: 1, sm: 2 }}
                    rowSpan={3}
                    mt={{ base: 4, sm: 0 }}
                    display="flex"
                    alignItems={['flex-start', 'center']}
                    flexDirection="column"
                    justifyContent={'center'}
                    fontSize={['lg', 'xl']}
                  >
                    <Text>You scored </Text>
                    <Text as="span" fontSize="3xl" textShadow={'var(--spx-shadow-sm-dark)'}>
                      100%
                    </Text>
                    <Text>in the assessment!</Text>
                    <Text mt={2}>Well done!</Text>
                  </GridItem>
                </>
              ) : (
                <StatBlock
                  gridCol={2}
                  score={task.package?.state?.completion?.progress['C']}
                  total={task.package?.state?.completion?.size}
                  hasData={!!task.package}
                  noDataText="Not started"
                  subtitle="Fix up"
                >
                  <Button
                    as={Box}
                    colorScheme="buttonTeal"
                    rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                    width="100%"
                    isLoading={generate.isLoading}
                    size={['sm', 'md']}
                  >
                    Fix up
                  </Button>
                </StatBlock>
              )}
            </Grid>
          </Button>
        )}
      </CardBody>
    </Card>
  );
};

const StatBlock = ({
  score,
  total,
  gridCol,
  noDataText,
  hasData,
  subtitle,
  children,
}: {
  gridCol: number;
  score?: number;
  total?: number;
  subtitle: string;
  noDataText: string;
  hasData: boolean;
  children: ReactNode;
}) => {
  return (
    <>
      <GridItem
        gridColumn={{ base: 1, sm: gridCol }}
        mt={{ base: 4, sm: 0 }}
        display="flex"
        alignItems="center"
        fontSize={['lg', 'xl']}
      >
        {children}
      </GridItem>
      <GridItem gridColumn={{ base: 1, sm: gridCol }} display="flex" alignItems="center" pt={2}>
        <Text fontSize={['lg', '2xl']} textShadow={'var(--spx-shadow-sm-dark)'}>
          {hasData ? (
            <>
              <Text as="span" fontSize={['3xl', '5xl']}>
                {score || 0}
              </Text>
              /{total || 0}
            </>
          ) : (
            <>{noDataText}</>
          )}
        </Text>
      </GridItem>
      <GridItem gridColumn={{ base: 1, sm: gridCol }} display="flex" alignItems="center">
        <Text fontSize={['sm', 'md']}>{subtitle}</Text>
      </GridItem>
    </>
  );
};
