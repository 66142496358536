import { Flex, Grid } from '@chakra-ui/react';
import { CurrentUserDisplayName } from 'components/Names';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { getHours } from 'date-fns';
import { SchoolSetupBanner } from 'views/getting-started/components/SchoolSetupBanner';
import { DidYouKnow } from 'views/teacherhome/DidYouKnow';
import { UsageStats } from 'views/teacherhome/UsageStats';

import { HandinTable } from './HandinTable';
import { TrainingBanner } from './TrainingBanner';

export const TeacherHomeView = () => {
  const hour = getHours(new Date());
  const hourText = hour < 12 ? 'morning' : hour < 18 ? 'afternoon' : 'evening';

  return (
    <PageContainer>
      <PageTitle
        title="Home"
        subtitle={
          <>
            Good {hourText}, <CurrentUserDisplayName givenOnly />
          </>
        }
      />
      <Flex gap={6} mb={6} flexDirection={{ base: 'column', lg: 'row' }}>
        <SchoolSetupBanner flex={1} />
        <TrainingBanner flex={1} />
      </Flex>
      <Grid gridTemplateColumns={{ base: '1fr', lg: 'repeat(2, minmax(0, 1fr))' }} gap={6} mb={6}>
        <UsageStats />
        <DidYouKnow />
      </Grid>

      <HandinTable />
    </PageContainer>
  );
};
