import { DayOfWeek } from '@sparx/api/google/type/dayofweek';
import React from 'react';

const daysOfWeek: [DayOfWeek, string, string, boolean][] = [
  [DayOfWeek.MONDAY, 'Monday', 'Mon', true],
  [DayOfWeek.TUESDAY, 'Tuesday', 'Tue', true],
  [DayOfWeek.WEDNESDAY, 'Wednesday', 'Wed', true],
  [DayOfWeek.THURSDAY, 'Thursday', 'Thur', true],
  [DayOfWeek.FRIDAY, 'Friday', 'Fri', true],
  [DayOfWeek.SATURDAY, 'Saturday', 'Sat', true],
  [DayOfWeek.SUNDAY, 'Sunday', 'Sun', true],
];
export const getWeekdayName = (day: DayOfWeek) => daysOfWeek.find(([d]) => d === day)?.[1] ?? '???';

export const getWeekdayNameShort = (day: DayOfWeek) =>
  daysOfWeek.find(([d]) => d === day)?.[2] ?? '???';

export const getWeekdayOptions = (selected?: DayOfWeek) =>
  daysOfWeek.map(([val, name, _, enabled]) =>
    enabled || selected === val ? (
      <option value={val} key={val} disabled={!enabled}>
        {name}
      </option>
    ) : null,
  );
