import { Product } from '@sparx/api/apis/sparx/types/product';
import { useQuery } from '@tanstack/react-query';
import { schoolStatusClient } from 'api';

import { Options } from './school';
import { getSchoolID } from './sessions';

export const useSchoolMISStatus = (options?: Options) => {
  return useQuery({
    queryKey: ['school-mis-status'],
    queryFn: async () =>
      schoolStatusClient.getMISStatus({
        schoolName: `schools/${await getSchoolID()}`,
        product: Product.SPARX_SCIENCE,
      }).response,

    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};
