import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';

/**
 * Gets the `realName` (as first name + last name), `displayName` (if set) and a constant `fallback` value for
 * a staff member. The expectation is that `realName` ought to be set, but `displayName` may not be. Use
 * real/display name as appropriate to context, but always include the fallback when a potential empty string
 * is not appropriate. Example of usage:
 * ```
 * const { realName, displayName, fallback } = readableStaffNames(staff);
 * console.log(`Action was performed by ${displayName || realName || fallback}`);
 * ```
 */
export const readableStaffNames = <
  T extends Pick<SchoolStaffMember, 'familyName' | 'givenName' | 'displayName'>,
>(
  s?: T,
) => ({
  realName: s && `${s.givenName} ${s.familyName}`.trim(),
  displayName: s && s.displayName,
  fallback: 'Unknown staff member' as const,
});
