import { HStack, Text } from '@chakra-ui/react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface WeekDropdownProps {
  title: React.ReactNode;
  children: React.ReactNode;
  value: string | number | undefined;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const WeekDropdown = ({ title, children, value, onChange }: WeekDropdownProps) => (
  <Text
    as="span"
    color="gray.500"
    backgroundColor="gray.100"
    position="relative"
    borderRadius="md"
    px={3}
    py={2}
    my={-2}
    display="inline-block"
    _hover={{
      background: 'gray.200',
    }}
  >
    <HStack spacing={3} whiteSpace="nowrap">
      <Text as="span">{title}</Text>
      <Text as="span" fontSize="sm" color="gray.400">
        <FontAwesomeIcon icon={faChevronDown} />
      </Text>
    </HStack>
    <select
      style={{
        fontSize: 16,
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        opacity: 0,
        cursor: 'pointer',
      }}
      value={value}
      onChange={onChange}
    >
      {children}
    </select>
  </Text>
);

export const WeekDropdownSeparator = () => (
  <Text as="span" color="gray.500" px={3}>
    /
  </Text>
);
