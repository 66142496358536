import { Flex, Spinner,Text } from '@chakra-ui/react';
import { Week } from 'api/school';
import React from 'react';

import { WeekDropdown, WeekDropdownSeparator } from './WeekDropdown';

interface PlannerTitleProps {
  week: Week | undefined;
  weeks: Week[];
  selectWeek: (week: Week) => void;
}

export const PlannerTitle = ({ week, weeks, selectWeek }: PlannerTitleProps) => (
  <Flex alignItems="center">
    <Text as="span">Planner</Text>
    <WeekDropdownSeparator />
    <WeekDropdown
      title={week ? `Week ${week.index}` : <Spinner size="sm" color="gray.400" />}
      value={week?.index}
      onChange={e => {
        const w = weeks.find(w => w.index === parseInt(e.target.value));
        w && selectWeek(w);
      }}
    >
      {weeks.map(w => (
        <option key={w.index} value={w.index}>
          Week {w.index}
        </option>
      ))}
    </WeekDropdown>
  </Flex>
);
