import { Box, HStack, SkeletonCircle, Spacer, Text } from '@chakra-ui/react';
import { useActivity } from 'api/packages';
import { useTetheringSession } from 'api/tethering';
import { Copyable } from 'components/copyable/Copyable';
import { NotFound } from 'components/errorpages/NotFound';
import { LargeLoadingWithText } from 'components/loading/LargeLoading';
import queryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ActivityDelivery } from 'views/task/ActivityDelivery';

export const TetheringView = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const sessionName = query.session?.toString() || '';

  // Hide this page if no session name is provided
  if (sessionName === '') {
    return <NotFound />;
  }

  return <TetheringContent sessionName={sessionName} />;
};

const TetheringContent = ({ sessionName }: { sessionName: string }) => {
  const { data, isLoading, restartActivity } = useTetheringSession(sessionName);
  const { data: activity } = useActivity(data?.activity?.taskItemName || '');

  const {
    tethering_question_id: questionIndex = '',
    tethering_skill_id: skillID = '',
    tethering_title: tetheringTitle = '',
  } = activity?.annotations || {};

  if (isLoading || !data) {
    return (
      <LargeLoadingWithText>
        Waiting for skill on session: <strong>{sessionName}</strong>
      </LargeLoadingWithText>
    );
  }

  return (
    <Box
      height="100%"
      width="100%"
      flexDirection="column"
      display="flex"
      p={{ base: 1, sm: 4 }}
      position="absolute"
    >
      <HStack spacing={4} px={2} pb={4}>
        <SkeletonCircle startColor="red.300" endColor="red.500" size="3" flexShrink={0} />
        <Text fontWeight="bold">Tethering</Text>
        <Text fontWeight="normal">{sessionName}</Text>
        <Spacer />
        <Text fontWeight="normal" color="gray.500" fontSize="sm" textAlign="right">
          {tetheringTitle}
        </Text>
        <Copyable value={skillID} tail={`/${questionIndex}`} />
      </HStack>
      <Box
        bg="white"
        rounded="md"
        boxShadow="md"
        flex={1}
        overflow="hidden"
        display="flex"
        flexDirection="column"
      >
        {activity && (
          <ActivityDelivery
            activity={activity}
            key={activity.name}
            onContinue={restartActivity}
            onRetry={restartActivity}
          />
        )}
      </Box>
    </Box>
  );
};
