import {
  Badge,
  Box,
  Checkbox,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Tooltip,
  UnorderedList,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { faEllipsisH, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { StaffRoleAssignment } from '@sparx/api/apis/sparx/school/staff/v2/staff';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { Button } from '@sparx/sparx-design/components/button/Button';
import { createColumnHelper, RowSelectionState } from '@tanstack/react-table';
import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import { DataTable } from '../components/DataTable';
import { KeyContactsWarning } from '../components/KeyContactsWarning';
import { TabBar } from '../components/TabBar';
import { useStaffContext } from '../Context';
import { useSendWelcomeEmails, useStaffKeyContacts, useStaffProducts } from '../queries';
import {
  filterUserRolesForProduct,
  getGlobalRoleName,
  getProductName,
  getProductRoleNames,
  getShortProductName,
} from '../utils';
import buttonStyles from './button.module.css';
import { TrainingProgressCell } from './TrainingProgressCell';

interface StaffListViewProps {
  children: (args: {
    systemDropdown: React.ReactNode;
    table: React.ReactNode;
  }) => React.ReactElement;
  isSparxStaff?: boolean;
}

export const StaffListView = ({ children, isSparxStaff }: StaffListViewProps) => {
  const {
    onSelectStaff,
    defaultProduct,
    onSwitchTab,
    styles,
    showTrainingProgress,
    useNavigationMessage,
    keyContactsDisabled,
  } = useStaffContext();

  const productForStyles = ((p: Product) => {
    // Assessments uses the defaultProduct property a little differently
    // TODO: We should have two different properties, one for the default product
    // and one as a client identifier.
    if ((p as number) === -1) {
      return Product.SPARX_ASSESSMENTS;
    }
    return p;
  })(defaultProduct);

  const sendWelcomeEmails = useSendWelcomeEmails();
  const toast = useToast();
  const { data } = useStaffKeyContacts({ suspense: true });
  const products = useStaffProducts();

  const [selectedProduct, setSelectedProduct] = useState<number>(defaultProduct);
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const [sendWelcomeEmailsOpen, setSendWelcomeEmailsOpen] = useState(false);

  useEffect(() => {
    if (!isSparxStaff) {
      setSelectedProduct(defaultProduct);
    }
  }, [isSparxStaff, defaultProduct]);

  const filteredStaff = useMemo(
    () =>
      selectedProduct === -1
        ? data?.allStaff.filter(sm => sm.productAccess)
        : data?.allStaff?.filter(
            sm =>
              sm.productAccess && filterUserRolesForProduct(sm.roles, selectedProduct).length > 0,
          ),
    [selectedProduct, data?.allStaff],
  );

  // Some operations navigate back here with a message, which we should display on mount
  const message = useNavigationMessage();
  useEffect(() => {
    if (message) {
      toast({ title: message, status: 'success', position: 'bottom-left', duration: 5000 });
    }
  }, [toast, message]);

  if (!data) {
    // This should never happen due to suspense integration.
    // However, it makes the typings better.
    return children({
      systemDropdown: null,
      table: <Text>Loading...</Text>,
    });
  }

  const columnHelper = createColumnHelper<SchoolStaffMember>();
  const columns = [
    columnHelper.display({
      id: 'select',
      header: ({ table }) => (
        <Checkbox
          isChecked={table.getIsAllPageRowsSelected()}
          isIndeterminate={table.getIsSomePageRowsSelected()}
          onChange={table.getToggleAllPageRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          borderColor={styles.checkboxColor}
          isChecked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onChange={row.getToggleSelectedHandler()}
        />
      ),
      meta: {
        blockClick: true,
      },
    }),
    columnHelper.display({
      header: 'Edit',
      cell: () => (
        <Text color={styles.editIconColor}>
          <FontAwesomeIcon icon={faPencil} />
        </Text>
      ),
      enableSorting: false,
      meta: {
        align: 'center',
      },
    }),
    columnHelper.accessor('givenName', {
      header: 'First name',
      cell: info => (
        <Text color="blue.900" fontWeight="bold">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('familyName', {
      header: 'Last name',
      cell: info => (
        <Text color="blue.900" fontWeight="bold">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('emailAddress', {
      header: 'Email address',
      cell: info => (
        <Text
          as="div"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          width="25vw"
          maxWidth="360px"
        >
          {info.getValue()}
        </Text>
      ),
      meta: {
        width: '30%',
      },
    }),
    columnHelper.display({
      // If we're a Sparx user, indicate which roles we're seeing, otherwise just 'Roles'
      header: !isSparxStaff ? 'Roles' : roleColumnHeading(selectedProduct),
      enableSorting: false,
      cell: info => (
        <RoleDisplay
          roles={
            selectedProduct > 0
              ? filterUserRolesForProduct(info.row.original.roles, selectedProduct)
              : info.row.original.roles
          }
          product={selectedProduct}
        />
      ),
    }),
    ...(showTrainingProgress
      ? [
          columnHelper.display({
            header: 'Training progress',
            cell: info => <TrainingProgressCell staffName={info.row.original.name} />,
          }),
        ]
      : []),
  ];

  const onClickRow = (row: SchoolStaffMember) => onSelectStaff(row.name);
  const selectedStaffMembers = data.allStaff.filter(s => selectedRows[s.name]);

  const onSendWelcomeEmails = async () => {
    try {
      await sendWelcomeEmails.mutateAsync({
        names: selectedStaffMembers.map(s => s.name),
      });
      setSelectedRows({});
    } catch {
      toast({
        title: 'Error sending account setup emails',
        status: 'error',
        position: 'bottom-left',
        duration: 5000,
      });
      return;
    }
    setSendWelcomeEmailsOpen(false);
  };

  return children({
    systemDropdown: isSparxStaff ? (
      <Box display="flex" flexDir="row" alignItems="center">
        View:
        <Select
          background="white"
          ml={2}
          mr={4}
          value={selectedProduct}
          onChange={e => setSelectedProduct(+e.target.value)}
        >
          {products.map(p => (
            <option key={p} value={p}>
              {getShortProductName(p)} staff
            </option>
          ))}
          <option value={-1}>All staff</option>
        </Select>
      </Box>
    ) : null,
    table: (
      <>
        {sendWelcomeEmailsOpen && (
          <SendWelcomeEmailsDialog
            staff={selectedStaffMembers}
            onConfirm={onSendWelcomeEmails}
            product={productForStyles}
            productName={getProductName(selectedProduct)}
            onClose={() => setSendWelcomeEmailsOpen(false)}
          />
        )}
        {!keyContactsDisabled && <TabBar index={0} warning={data.status.status !== 'ok'} />}
        <Box mb={4} display="flex" justifyContent="space-between">
          <Text lineHeight="unset">
            This area is for staff who will be able to log in and use{' '}
            {getProductName(selectedProduct)}. All staff have the same administrative rights.
          </Text>
          <Box flex="0 0 auto" alignSelf="flex-end" ml="2">
            <Button
              variant="outlined"
              isDisabled={!selectedStaffMembers.length}
              onClick={() => setSendWelcomeEmailsOpen(true)}
              className={classnames({
                [buttonStyles.ScienceButtonBase]: productForStyles === Product.SPARX_SCIENCE,
                [buttonStyles.ScienceButtonOutlined]: productForStyles === Product.SPARX_SCIENCE,
                [buttonStyles.AssessmentsButtonOutlined]:
                  productForStyles === Product.SPARX_ASSESSMENTS,
              })}
            >
              Resend account setup emails
            </Button>
          </Box>
        </Box>
        {!keyContactsDisabled && (
          <KeyContactsWarning
            status={data.status}
            product={defaultProduct}
            kcTabLink={() => onSwitchTab(1)}
          />
        )}
        <DataTable
          data={filteredStaff || []}
          columns={columns}
          defaultSort={[
            { id: `familyName`, desc: false },
            { id: `givenName`, desc: false },
          ]}
          onRowClick={onClickRow}
          noDataRow={<>There are no staff members in this school</>}
          headerStyles={{ backgroundColor: styles.headerBackgroundColor }}
          sortOptions={styles.tableSortOptions}
          rowSelectionState={selectedRows}
          onRowSelectionChange={setSelectedRows}
          getRowId={s => s.name}
        />
      </>
    ),
  });
};

interface RoleDisplayProps {
  roles: StaffRoleAssignment[];
  product: Product;
}

export const RoleDisplay = ({ roles, product }: RoleDisplayProps) => {
  const roleDisplay = product <= 0 ? getGlobalRoleName(roles) : getProductRoleNames(roles, product);

  // This shouldn't happen, nevertheless we ought not to blow up if it does
  if (roleDisplay.length === 0) return <>(None)</>;

  if (roleDisplay.length === 1) return <>{roleDisplay[0]}</>;

  return (
    <>
      {roleDisplay[0]},
      <Tooltip
        hasArrow
        backgroundColor="white"
        placement="top"
        label={
          <VStack spacing={0} p={1}>
            {roleDisplay.map(r => (
              <Text key={r} color="gray.600" align="center">
                {r}
              </Text>
            ))}
          </VStack>
        }
      >
        <Badge ml={2}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </Badge>
      </Tooltip>
    </>
  );
};

const roleColumnHeading = (product: number) =>
  ({
    [-1]: 'All roles',
    [Product.SPARX_MATHS]: 'Maths roles',
    [Product.SPARX_READER]: 'Reader roles',
    [Product.SPARX_SCIENCE]: 'Science roles',
    [Product.PRODUCT_UNKNOWN]: 'Cross-system roles',
  })[product] ?? 'Roles';

const SendWelcomeEmailsDialog = ({
  onClose,
  onConfirm,
  staff,
  productName,
  product,
}: {
  onClose: () => void;
  onConfirm: () => Promise<void>;
  staff: SchoolStaffMember[];
  productName: string;
  product: Product;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <Modal isOpen onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Resend account setup emails</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            An email will be sent to the following staff members with their usernames and links to
            set up a password so they can access your {productName} site:
          </Text>
          <UnorderedList mt="4" pl="4">
            {staff
              .slice()
              .sort((a, b) => {
                const x = a.familyName.localeCompare(b.familyName);
                if (x === 0) {
                  return a.givenName.localeCompare(b.givenName);
                }
                return x;
              })
              .map(s => (
                <ListItem key={s.name}>
                  {s.givenName} {s.familyName}
                </ListItem>
              ))}
          </UnorderedList>
        </ModalBody>
        <ModalFooter columnGap="var(--spx-unit-2)">
          <Button
            variant="outlined"
            onClick={onClose}
            className={classnames({
              [buttonStyles.ScienceButtonBase]: product === Product.SPARX_SCIENCE,
              [buttonStyles.ScienceButtonOutlined]: product === Product.SPARX_SCIENCE,
              [buttonStyles.AssessmentsButtonOutlined]: product === Product.SPARX_ASSESSMENTS,
            })}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classnames({
              [buttonStyles.ReaderButton]: product === Product.SPARX_READER,
              [buttonStyles.ScienceButtonBase]: product === Product.SPARX_SCIENCE,
              [buttonStyles.ScienceButtonContained]: product === Product.SPARX_SCIENCE,
              [buttonStyles.ScienceButtonContainedLoading]:
                product === Product.SPARX_SCIENCE && isSubmitting,
              [buttonStyles.AssessmentsButtonContained]: product === Product.SPARX_ASSESSMENTS,
              [buttonStyles.AssessmentsButtonContainedLoading]:
                product === Product.SPARX_ASSESSMENTS && isSubmitting,
            })}
            isLoading={isSubmitting}
            onClick={async () => {
              setIsSubmitting(true);
              await onConfirm();
              setIsSubmitting(false);
            }}
          >
            Send emails
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
