import { TopicWithStrand } from 'api/content';
import MiniSearch from 'minisearch';
import { useMemo } from 'react';

export interface FilterFields {
  curriculum: string;
  search: string;
}

interface TopicSearchOptions {
  enabled?: boolean;
  // We exclude deleted topics by default, set this to include them
  includeDeleted?: boolean;
  // Filter to apply after search, does not apply when exact match by topic code.
  filter?: (topic: TopicWithStrand) => boolean;
}

export const useTopicSearch = (
  allTopics: TopicWithStrand[],
  filter: FilterFields,
  opts?: TopicSearchOptions,
) => {
  const topics = useMemo(
    () => (opts?.includeDeleted ? allTopics : allTopics.filter(t => !t.topic.deleted)),
    [allTopics, opts?.includeDeleted],
  );

  const engine = useMemo(() => {
    const engine = new MiniSearch({
      fields: ['strand', 'substrand', 'displayName', 'description', 'code'],
      searchOptions: {
        fuzzy: true,
        prefix: true,
        boost: {
          code: 3, // Code has the highest weight
          displayName: 2,
        },
      },
    });

    // Index the topics
    engine.addAll(
      topics.map(t => ({
        id: t.topic.name,
        strand: t.strand,
        substrand: t.substrand,
        displayName: t.topic.displayName,
        description: t.topic.description,
        code: t.topic.code,
      })),
    );

    return engine;
  }, [topics]);

  // Create map for topic name -> topic. Used to lookup topic after
  // we have search results.
  const topicMap = useMemo(() => {
    const map = new Map<string, TopicWithStrand>();
    topics.forEach(t => map.set(t.topic.name, t));
    return map;
  }, [topics]);

  // Perform the filtering.
  return useMemo(() => {
    if (!opts?.enabled) {
      return topics;
    }

    let filteredTopics = topics;
    if (filter.curriculum && !filter.search) {
      filteredTopics = topics.filter(t => t.topic?.name.startsWith(filter.curriculum));
    }
    if (filter.search) {
      // Search for exact topic code, if it looks like a topic code
      const trimmedSearch = filter.search.trim().toUpperCase();
      if (trimmedSearch.length === 4) {
        const topic = topics.find(t => t.topic.code === trimmedSearch);
        if (topic) {
          // If we found a match by topic code return just this topic (ignoring any filters)
          return [topic];
        }
      }

      const results = engine.search(filter.search, {
        // Do curriculum filtering on search result
        filter: result => !filter.curriculum || result.id.includes(filter.curriculum),
      });
      filteredTopics = results.map(r => topicMap.get(r.id)).filter(t => t) as TopicWithStrand[];
    }

    // If we have additional filter, use that here
    if (opts?.filter) {
      filteredTopics = filteredTopics.filter(opts.filter);
    }

    // If we have search term then the results are already ordered by their match. If we
    // don't have a search then we order by subject (strand) then unit (e.g. topic 4.10)
    if (!filter.search) {
      filteredTopics.sort((a, b) => {
        const aText = `${a.strand} ${a.substrand} ${a.topic?.displayName}`;
        const bText = `${b.strand} ${b.substrand} ${b.topic?.displayName}`;
        return aText.localeCompare(bText);
      });
    }

    return filteredTopics;
  }, [opts, engine, topics, topicMap, filter]);
};
