import {
  Class,
  Student as WondeStudent,
} from '@sparx/api/apis/sparx/misintegration/wondewitch/v1/wondewitch';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { getDateOfBirth } from '@sparx/mis-sync-import/src/processing';
import { getWondeIDFromExternalID, RectificationConflicts } from '@sparx/mis-sync-import/src/utils';

import styles from './RectificationConflictError.module.css';

type conflictMessage = {
  upn: string[];
  details: string[];
};

/**
 * SyncPlanRectificationConflictError is a component that displays a readable list of rectification
 * conflicts that have been detected whilst creating a sync plan. This occurs when a Wonde student
 * matches multiple students in Sparx. This list, which should only be available to Sparx staff,
 * shows the external ID of the Wonde student and the details of the Sparx students that match, and
 * the reason each of them match.
 */
export const SyncPlanRectificationConflictError = ({
  error,
  sparxStudents,
  wondeClasses,
}: {
  error: RectificationConflicts;
  sparxStudents?: Record<string, Student>;
  wondeClasses?: Class[];
}) => {
  if (!sparxStudents) {
    return null;
  }

  const wondeStudentMap =
    wondeClasses?.reduce<Record<string, WondeStudent>>((acc, wc) => {
      wc.students.forEach(s => {
        acc[s.id] = s;
      });
      return acc;
    }, {}) || {};

  return (
    <div>
      {error.conflicts.map(conflict => {
        const wondeID = getWondeIDFromExternalID('student', conflict.externalID);
        const wondeStudent = wondeStudentMap[wondeID || ''];
        return (
          <div key={conflict.externalID} className={styles.Conflict}>
            <div className={styles.DetailsContainer}>
              {wondeStudent &&
                getDetail('Name', `${wondeStudent.forename} ${wondeStudent.surname}`)}
              {wondeStudent && getDetail('DOB', getDateOfBirth(wondeStudent.dateOfBirth))}
              {getDetail('External ID', conflict.externalID)}
            </div>
            {getStudentLists(JSON.parse(conflict.message), sparxStudents)}
          </div>
        );
      })}
    </div>
  );
};

const getStudentLists = (message: conflictMessage, sparxStudents: Record<string, Student>) => (
  <>
    {message.upn.length > 0 && (
      <>
        <strong>Matched on UPN:</strong>
        <ul className={styles.List}>
          {message.upn.map(id => getStudentListItem(id, sparxStudents))}
        </ul>
      </>
    )}
    {message.details.length > 0 && (
      <>
        <strong>Matched on Details:</strong>
        <ul className={styles.List}>
          {message.details.map(id => getStudentListItem(id, sparxStudents))}
        </ul>
      </>
    )}
  </>
);

const getStudentListItem = (studentID: string, students: Record<string, Student>) => {
  const student = students[studentID];
  if (!student) {
    return null;
  }
  return <li key={studentID}>{getStudentInfo(student)}</li>;
};

const getStudentInfo = (student: Student) => (
  <div className={styles.DetailsContainer}>
    {getDetail('Name', `${student.givenName} ${student.familyName}`)}
    {getDetail(
      'DOB',
      student.dateOfBirth
        ? Timestamp.toDate(student.dateOfBirth).toLocaleDateString()
        : 'undefined',
    )}
    {getDetail('External ID', student.externalId)}
    {getDetail(
      'Last login',
      student.login?.lastLoginAt
        ? Timestamp.toDate(student.login?.lastLoginAt).toLocaleString()
        : 'never',
    )}
  </div>
);

const getDetail = (label: string, text: string) => (
  <div className={styles.Details}>
    <strong>{label}: </strong> {text}
  </div>
);
