import {
  Box,
  Divider,
  Flex,
  Heading,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TrainingSummary,
  useStaffTrainingSummary,
  useTrainingContext,
} from '@sparx/teacher-training';

import { useStaffContext } from '../Context';
import { getProductName } from '../utils';
import { Product } from '@sparx/api/apis/sparx/types/product';

const ProgressChunks = ({ complete, total }: { complete: number; total: number }) => (
  <Flex h="10px" w="120px" wrap="nowrap" gap={1}>
    {Array.from(Array(total)).map((_, ix) => (
      <Box
        flexGrow={1}
        bg={ix < complete ? 'green.400' : 'gray.200'}
        h="10px"
        key={ix}
        _first={{ borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px' }}
        _last={{ borderTopRightRadius: '6px', borderBottomRightRadius: '6px' }}
      />
    ))}
  </Flex>
);

const ProgressTooltip = ({ staffId }: { staffId: string }) => {
  const { defaultProduct } = useStaffContext();
  const { moduleSpecs } = useTrainingContext();
  const { data: trainingProgress } = useStaffTrainingSummary(staffId, {});

  return (
    <Flex flexDirection={'column'}>
      <Heading size="md" textAlign={'center'} mb={2}>
        {getProductName(defaultProduct)} training progress
      </Heading>
      <Divider mt={2} />
      <Table>
        <Tbody>
          {moduleSpecs.map((spec, ix) => {
            const moduleProgress = trainingProgress?.modules.find(m => m.spec.name === spec.name);
            const tasksComplete = moduleProgress?.steps.filter(s => s.complete).length || 0;
            const tasksTotal = spec?.steps.length || 0;
            return (
              <Tr color="black" key={ix}>
                <Td px={0}>
                  {defaultProduct === Product.SPARX_READER ? `Level ${ix + 1}: ` : `${ix + 1}. `}
                  {spec.title}
                </Td>
                <Td px={4}>
                  <ProgressChunks complete={tasksComplete} total={tasksTotal} />
                </Td>
                <Td px={0}>
                  {tasksComplete}/{tasksTotal} tasks completed
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Flex>
  );
};

export const TrainingProgressCell = ({ staffName }: { staffName: string }) => {
  const staffId = staffName.split('/')[1];
  const { defaultProduct } = useStaffContext();
  const { data: trainingProgress, isLoading } = useStaffTrainingSummary(staffId, {});
  const isCompleted = trainingProgress && trainingProgress.completed >= trainingProgress.total;

  if (isLoading) {
    return <Spinner size="sm" />;
  }

  if (!trainingProgress) {
    return <Text color="red.500">Error loading</Text>;
  }

  return (
    <Tooltip
      display={{ base: 'none', md: 'block' }}
      label={<ProgressTooltip staffId={staffId} />}
      placement={'left'}
      bg="white"
      py={4}
      px={6}
      maxW={'2xl'}
      hasArrow
    >
      <Text
        color={isCompleted ? 'green.500' : undefined}
        textDecoration="underline"
        textDecorationStyle="dotted"
        textUnderlineOffset={3}
      >
        <TrainingProgressCellContent
          isCompleted={Boolean(isCompleted)}
          trainingProgress={trainingProgress}
          defaultProduct={defaultProduct}
        />
      </Text>
    </Tooltip>
  );
};

const TrainingProgressCellContent = ({
  isCompleted,
  trainingProgress,
  defaultProduct,
}: {
  isCompleted: boolean;
  trainingProgress: TrainingSummary;
  defaultProduct: Product;
}) => {
  const stepsComplete = trainingProgress.modules.reduce(
    (acc, m) => acc + m.steps.filter(s => s.complete).length,
    0,
  );
  if (isCompleted) {
    return (
      <>
        Complete <FontAwesomeIcon icon={faCheck} />
      </>
    );
  }
  if (stepsComplete === 0 && defaultProduct === Product.SPARX_READER) {
    return <Text color="red">Not started</Text>;
  }
  if (defaultProduct === Product.SPARX_READER) {
    const level1Complete = trainingProgress.modules.find(
      m => m.spec.name === 'READER_TRAINING_MODULE_ESSENTIAL_LEVEL',
    )?.complete;
    if (level1Complete) {
      return <>Level 2</>;
    }
    return <>Level 1</>;
  }
  return (
    <>
      {trainingProgress.completed}/{trainingProgress.total} modules
    </>
  );
};
