// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "google/rpc/error_details.proto" (package "google.rpc", syntax proto3),// tslint:disable
// @ts-nocheck
//
// Copyright 2024 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
import { MessageType } from '@protobuf-ts/runtime';
import { Duration } from '../protobuf/duration';
/**
 * Describes the cause of the error with structured details.
 *
 * Example of an error when contacting the "pubsub.googleapis.com" API when it
 * is not enabled:
 *
 *     { "reason": "API_DISABLED"
 *       "domain": "googleapis.com"
 *       "metadata": {
 *         "resource": "projects/123",
 *         "service": "pubsub.googleapis.com"
 *       }
 *     }
 *
 * This response indicates that the pubsub.googleapis.com API is not enabled.
 *
 * Example of an error that is returned when attempting to create a Spanner
 * instance in a region that is out of stock:
 *
 *     { "reason": "STOCKOUT"
 *       "domain": "spanner.googleapis.com",
 *       "metadata": {
 *         "availableRegions": "us-central1,us-east2"
 *       }
 *     }
 *
 * @generated from protobuf message google.rpc.ErrorInfo
 */
export interface ErrorInfo {
  /**
   * The reason of the error. This is a constant value that identifies the
   * proximate cause of the error. Error reasons are unique within a particular
   * domain of errors. This should be at most 63 characters and match a
   * regular expression of `[A-Z][A-Z0-9_]+[A-Z0-9]`, which represents
   * UPPER_SNAKE_CASE.
   *
   * @generated from protobuf field: string reason = 1;
   */
  reason: string;
  /**
   * The logical grouping to which the "reason" belongs. The error domain
   * is typically the registered service name of the tool or product that
   * generates the error. Example: "pubsub.googleapis.com". If the error is
   * generated by some common infrastructure, the error domain must be a
   * globally unique value that identifies the infrastructure. For Google API
   * infrastructure, the error domain is "googleapis.com".
   *
   * @generated from protobuf field: string domain = 2;
   */
  domain: string;
  /**
   * Additional structured details about this error.
   *
   * Keys should match /[a-zA-Z0-9-_]/ and be limited to 64 characters in
   * length. When identifying the current value of an exceeded limit, the units
   * should be contained in the key, not the value.  For example, rather than
   * {"instanceLimit": "100/request"}, should be returned as,
   * {"instanceLimitPerRequest": "100"}, if the client exceeds the number of
   * instances that can be created in a single (batch) request.
   *
   * @generated from protobuf field: map<string, string> metadata = 3;
   */
  metadata: {
    [key: string]: string;
  };
}
/**
 * Describes when the clients can retry a failed request. Clients could ignore
 * the recommendation here or retry when this information is missing from error
 * responses.
 *
 * It's always recommended that clients should use exponential backoff when
 * retrying.
 *
 * Clients should wait until `retry_delay` amount of time has passed since
 * receiving the error response before retrying.  If retrying requests also
 * fail, clients should use an exponential backoff scheme to gradually increase
 * the delay between retries based on `retry_delay`, until either a maximum
 * number of retries have been reached or a maximum retry delay cap has been
 * reached.
 *
 * @generated from protobuf message google.rpc.RetryInfo
 */
export interface RetryInfo {
  /**
   * Clients should wait at least this long between retrying the same request.
   *
   * @generated from protobuf field: google.protobuf.Duration retry_delay = 1;
   */
  retryDelay?: Duration;
}
/**
 * Describes additional debugging info.
 *
 * @generated from protobuf message google.rpc.DebugInfo
 */
export interface DebugInfo {
  /**
   * The stack trace entries indicating where the error occurred.
   *
   * @generated from protobuf field: repeated string stack_entries = 1;
   */
  stackEntries: string[];
  /**
   * Additional debugging information provided by the server.
   *
   * @generated from protobuf field: string detail = 2;
   */
  detail: string;
}
/**
 * Describes how a quota check failed.
 *
 * For example if a daily limit was exceeded for the calling project,
 * a service could respond with a QuotaFailure detail containing the project
 * id and the description of the quota limit that was exceeded.  If the
 * calling project hasn't enabled the service in the developer console, then
 * a service could respond with the project id and set `service_disabled`
 * to true.
 *
 * Also see RetryInfo and Help types for other details about handling a
 * quota failure.
 *
 * @generated from protobuf message google.rpc.QuotaFailure
 */
export interface QuotaFailure {
  /**
   * Describes all quota violations.
   *
   * @generated from protobuf field: repeated google.rpc.QuotaFailure.Violation violations = 1;
   */
  violations: QuotaFailure_Violation[];
}
/**
 * A message type used to describe a single quota violation.  For example, a
 * daily quota or a custom quota that was exceeded.
 *
 * @generated from protobuf message google.rpc.QuotaFailure.Violation
 */
export interface QuotaFailure_Violation {
  /**
   * The subject on which the quota check failed.
   * For example, "clientip:<ip address of client>" or "project:<Google
   * developer project id>".
   *
   * @generated from protobuf field: string subject = 1;
   */
  subject: string;
  /**
   * A description of how the quota check failed. Clients can use this
   * description to find more about the quota configuration in the service's
   * public documentation, or find the relevant quota limit to adjust through
   * developer console.
   *
   * For example: "Service disabled" or "Daily Limit for read operations
   * exceeded".
   *
   * @generated from protobuf field: string description = 2;
   */
  description: string;
}
/**
 * Describes what preconditions have failed.
 *
 * For example, if an RPC failed because it required the Terms of Service to be
 * acknowledged, it could list the terms of service violation in the
 * PreconditionFailure message.
 *
 * @generated from protobuf message google.rpc.PreconditionFailure
 */
export interface PreconditionFailure {
  /**
   * Describes all precondition violations.
   *
   * @generated from protobuf field: repeated google.rpc.PreconditionFailure.Violation violations = 1;
   */
  violations: PreconditionFailure_Violation[];
}
/**
 * A message type used to describe a single precondition failure.
 *
 * @generated from protobuf message google.rpc.PreconditionFailure.Violation
 */
export interface PreconditionFailure_Violation {
  /**
   * The type of PreconditionFailure. We recommend using a service-specific
   * enum type to define the supported precondition violation subjects. For
   * example, "TOS" for "Terms of Service violation".
   *
   * @generated from protobuf field: string type = 1;
   */
  type: string;
  /**
   * The subject, relative to the type, that failed.
   * For example, "google.com/cloud" relative to the "TOS" type would indicate
   * which terms of service is being referenced.
   *
   * @generated from protobuf field: string subject = 2;
   */
  subject: string;
  /**
   * A description of how the precondition failed. Developers can use this
   * description to understand how to fix the failure.
   *
   * For example: "Terms of service not accepted".
   *
   * @generated from protobuf field: string description = 3;
   */
  description: string;
}
/**
 * Describes violations in a client request. This error type focuses on the
 * syntactic aspects of the request.
 *
 * @generated from protobuf message google.rpc.BadRequest
 */
export interface BadRequest {
  /**
   * Describes all violations in a client request.
   *
   * @generated from protobuf field: repeated google.rpc.BadRequest.FieldViolation field_violations = 1;
   */
  fieldViolations: BadRequest_FieldViolation[];
}
/**
 * A message type used to describe a single bad request field.
 *
 * @generated from protobuf message google.rpc.BadRequest.FieldViolation
 */
export interface BadRequest_FieldViolation {
  /**
   * A path that leads to a field in the request body. The value will be a
   * sequence of dot-separated identifiers that identify a protocol buffer
   * field.
   *
   * Consider the following:
   *
   *     message CreateContactRequest {
   *       message EmailAddress {
   *         enum Type {
   *           TYPE_UNSPECIFIED = 0;
   *           HOME = 1;
   *           WORK = 2;
   *         }
   *
   *         optional string email = 1;
   *         repeated EmailType type = 2;
   *       }
   *
   *       string full_name = 1;
   *       repeated EmailAddress email_addresses = 2;
   *     }
   *
   * In this example, in proto `field` could take one of the following values:
   *
   * * `full_name` for a violation in the `full_name` value
   * * `email_addresses[1].email` for a violation in the `email` field of the
   *   first `email_addresses` message
   * * `email_addresses[3].type[2]` for a violation in the second `type`
   *   value in the third `email_addresses` message.
   *
   * In JSON, the same values are represented as:
   *
   * * `fullName` for a violation in the `fullName` value
   * * `emailAddresses[1].email` for a violation in the `email` field of the
   *   first `emailAddresses` message
   * * `emailAddresses[3].type[2]` for a violation in the second `type`
   *   value in the third `emailAddresses` message.
   *
   * @generated from protobuf field: string field = 1;
   */
  field: string;
  /**
   * A description of why the request element is bad.
   *
   * @generated from protobuf field: string description = 2;
   */
  description: string;
}
/**
 * Contains metadata about the request that clients can attach when filing a bug
 * or providing other forms of feedback.
 *
 * @generated from protobuf message google.rpc.RequestInfo
 */
export interface RequestInfo {
  /**
   * An opaque string that should only be interpreted by the service generating
   * it. For example, it can be used to identify requests in the service's logs.
   *
   * @generated from protobuf field: string request_id = 1;
   */
  requestId: string;
  /**
   * Any data that was used to serve this request. For example, an encrypted
   * stack trace that can be sent back to the service provider for debugging.
   *
   * @generated from protobuf field: string serving_data = 2;
   */
  servingData: string;
}
/**
 * Describes the resource that is being accessed.
 *
 * @generated from protobuf message google.rpc.ResourceInfo
 */
export interface ResourceInfo {
  /**
   * A name for the type of resource being accessed, e.g. "sql table",
   * "cloud storage bucket", "file", "Google calendar"; or the type URL
   * of the resource: e.g. "type.googleapis.com/google.pubsub.v1.Topic".
   *
   * @generated from protobuf field: string resource_type = 1;
   */
  resourceType: string;
  /**
   * The name of the resource being accessed.  For example, a shared calendar
   * name: "example.com_4fghdhgsrgh@group.calendar.google.com", if the current
   * error is
   * [google.rpc.Code.PERMISSION_DENIED][google.rpc.Code.PERMISSION_DENIED].
   *
   * @generated from protobuf field: string resource_name = 2;
   */
  resourceName: string;
  /**
   * The owner of the resource (optional).
   * For example, "user:<owner email>" or "project:<Google developer project
   * id>".
   *
   * @generated from protobuf field: string owner = 3;
   */
  owner: string;
  /**
   * Describes what error is encountered when accessing this resource.
   * For example, updating a cloud project may require the `writer` permission
   * on the developer console project.
   *
   * @generated from protobuf field: string description = 4;
   */
  description: string;
}
/**
 * Provides links to documentation or for performing an out of band action.
 *
 * For example, if a quota check failed with an error indicating the calling
 * project hasn't enabled the accessed service, this can contain a URL pointing
 * directly to the right place in the developer console to flip the bit.
 *
 * @generated from protobuf message google.rpc.Help
 */
export interface Help {
  /**
   * URL(s) pointing to additional information on handling the current error.
   *
   * @generated from protobuf field: repeated google.rpc.Help.Link links = 1;
   */
  links: Help_Link[];
}
/**
 * Describes a URL link.
 *
 * @generated from protobuf message google.rpc.Help.Link
 */
export interface Help_Link {
  /**
   * Describes what the link offers.
   *
   * @generated from protobuf field: string description = 1;
   */
  description: string;
  /**
   * The URL of the link.
   *
   * @generated from protobuf field: string url = 2;
   */
  url: string;
}
/**
 * Provides a localized error message that is safe to return to the user
 * which can be attached to an RPC error.
 *
 * @generated from protobuf message google.rpc.LocalizedMessage
 */
export interface LocalizedMessage {
  /**
   * The locale used following the specification defined at
   * https://www.rfc-editor.org/rfc/bcp/bcp47.txt.
   * Examples are: "en-US", "fr-CH", "es-MX"
   *
   * @generated from protobuf field: string locale = 1;
   */
  locale: string;
  /**
   * The localized error message in the above locale.
   *
   * @generated from protobuf field: string message = 2;
   */
  message: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class ErrorInfo$Type extends MessageType<ErrorInfo> {
  constructor() {
    super('google.rpc.ErrorInfo', [
      { no: 1, name: 'reason', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'domain', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'metadata',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message google.rpc.ErrorInfo
 */
export const ErrorInfo = new ErrorInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RetryInfo$Type extends MessageType<RetryInfo> {
  constructor() {
    super('google.rpc.RetryInfo', [
      { no: 1, name: 'retry_delay', kind: 'message', T: () => Duration },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message google.rpc.RetryInfo
 */
export const RetryInfo = new RetryInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DebugInfo$Type extends MessageType<DebugInfo> {
  constructor() {
    super('google.rpc.DebugInfo', [
      {
        no: 1,
        name: 'stack_entries',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'detail', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message google.rpc.DebugInfo
 */
export const DebugInfo = new DebugInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuotaFailure$Type extends MessageType<QuotaFailure> {
  constructor() {
    super('google.rpc.QuotaFailure', [
      {
        no: 1,
        name: 'violations',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => QuotaFailure_Violation,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message google.rpc.QuotaFailure
 */
export const QuotaFailure = new QuotaFailure$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuotaFailure_Violation$Type extends MessageType<QuotaFailure_Violation> {
  constructor() {
    super('google.rpc.QuotaFailure.Violation', [
      { no: 1, name: 'subject', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'description',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message google.rpc.QuotaFailure.Violation
 */
export const QuotaFailure_Violation = new QuotaFailure_Violation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreconditionFailure$Type extends MessageType<PreconditionFailure> {
  constructor() {
    super('google.rpc.PreconditionFailure', [
      {
        no: 1,
        name: 'violations',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PreconditionFailure_Violation,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message google.rpc.PreconditionFailure
 */
export const PreconditionFailure = new PreconditionFailure$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreconditionFailure_Violation$Type extends MessageType<PreconditionFailure_Violation> {
  constructor() {
    super('google.rpc.PreconditionFailure.Violation', [
      { no: 1, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'subject', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'description',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message google.rpc.PreconditionFailure.Violation
 */
export const PreconditionFailure_Violation =
  new PreconditionFailure_Violation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BadRequest$Type extends MessageType<BadRequest> {
  constructor() {
    super('google.rpc.BadRequest', [
      {
        no: 1,
        name: 'field_violations',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BadRequest_FieldViolation,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message google.rpc.BadRequest
 */
export const BadRequest = new BadRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BadRequest_FieldViolation$Type extends MessageType<BadRequest_FieldViolation> {
  constructor() {
    super('google.rpc.BadRequest.FieldViolation', [
      { no: 1, name: 'field', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'description',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message google.rpc.BadRequest.FieldViolation
 */
export const BadRequest_FieldViolation = new BadRequest_FieldViolation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequestInfo$Type extends MessageType<RequestInfo> {
  constructor() {
    super('google.rpc.RequestInfo', [
      { no: 1, name: 'request_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'serving_data',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message google.rpc.RequestInfo
 */
export const RequestInfo = new RequestInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResourceInfo$Type extends MessageType<ResourceInfo> {
  constructor() {
    super('google.rpc.ResourceInfo', [
      {
        no: 1,
        name: 'resource_type',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'resource_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'owner', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'description',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message google.rpc.ResourceInfo
 */
export const ResourceInfo = new ResourceInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Help$Type extends MessageType<Help> {
  constructor() {
    super('google.rpc.Help', [
      {
        no: 1,
        name: 'links',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Help_Link,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message google.rpc.Help
 */
export const Help = new Help$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Help_Link$Type extends MessageType<Help_Link> {
  constructor() {
    super('google.rpc.Help.Link', [
      {
        no: 1,
        name: 'description',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'url', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message google.rpc.Help.Link
 */
export const Help_Link = new Help_Link$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocalizedMessage$Type extends MessageType<LocalizedMessage> {
  constructor() {
    super('google.rpc.LocalizedMessage', [
      { no: 1, name: 'locale', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'message', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message google.rpc.LocalizedMessage
 */
export const LocalizedMessage = new LocalizedMessage$Type();
