import {
  Box,
  Button,
  Checkbox,
  Flex,
  FlexProps,
  Link as ChakraLink,
  Text,
  useToast,
} from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowUpRightFromSquare,
  faCheck,
  faChevronRight,
  faExternalLink,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import { useUpdateRolloverAnnotations } from 'api/schoolactions';
import { ReactNode, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { articleIDToURL } from 'utils/knowledgeBaseArticles';

import { useIsManualRolloverStepComplete, useYearToReference } from '../hooks';

interface ActionBoxProps extends FlexProps {
  heading?: ReactNode;
  complete?: boolean;
  faIcon?: IconDefinition;
  button?: {
    text: string;
    path: string;
    external?: boolean;
  };
  knowledgeBaseArticleId?: number;
  checkbox?: {
    annotation: string;
    disabled?: boolean;
  };
}

export const ActionBox = ({
  heading,
  complete,
  faIcon,
  button,
  children,
  knowledgeBaseArticleId,
  checkbox,
  ...props
}: ActionBoxProps) => {
  const { pathname } = useLocation();
  const isRollover = pathname.includes('new-year-setup');
  return (
    <Flex
      p={4}
      alignItems="center"
      justifyContent="space-between"
      color="teal.600"
      borderWidth={1}
      borderRadius={'md'}
      bgColor={complete ? 'green.50' : undefined}
      {...props}
    >
      <Flex alignItems="center">
        {faIcon && (
          <Box mr={4}>
            <FontAwesomeIcon icon={faIcon} fixedWidth size="xl" />
          </Box>
        )}
        <Flex flexDirection="column">
          {heading && (typeof heading === 'string' ? <strong>{heading}</strong> : heading)}
          <Text fontSize="xs">
            {complete && !checkbox && children && (
              <Box as="span" mr={1} color="green.600">
                <FontAwesomeIcon icon={faCheck} fixedWidth size="lg" />
              </Box>
            )}
            {children}
          </Text>
          {knowledgeBaseArticleId && (
            <ChakraLink
              href={articleIDToURL(knowledgeBaseArticleId)}
              isExternal
              textDecoration="underline"
              color="teal.600"
              mt={1}
              fontSize="sm"
              alignSelf="flex-start"
            >
              Help with this
              <FontAwesomeIcon size="sm" style={{ marginLeft: '4px' }} icon={faExternalLink} />
            </ChakraLink>
          )}
        </Flex>
      </Flex>
      {checkbox && <CheckboxAnnotation {...checkbox} />}
      {button && (
        <Button
          as={Link}
          to={button.path}
          state={isRollover ? { fromNewYearSetup: true } : { fromGettingStarted: true }}
          target={button.external ? '_blank' : undefined}
          rel={button.external ? 'external noopener' : undefined}
          ml={4}
          colorScheme="buttonTeal"
          bgColor="white"
          variant="outline"
          minWidth={44}
          flexShrink={0}
          rightIcon={
            <FontAwesomeIcon
              icon={button.external ? faArrowUpRightFromSquare : faChevronRight}
              fixedWidth
            />
          }
        >
          {button.text}
        </Button>
      )}
    </Flex>
  );
};

const CheckboxAnnotation = ({
  annotation,
  disabled,
}: {
  annotation: string;
  disabled?: boolean;
}) => {
  const toast = useToast();
  const { isCurrentYear } = useYearToReference();
  const isChecked = useIsManualRolloverStepComplete(annotation);
  const { mutateAsync: updateRolloverAnns, isLoading } = useUpdateRolloverAnnotations();

  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <Checkbox
      whiteSpace="nowrap"
      ml={4}
      isChecked={disabled ? false : isLoading || checked}
      isDisabled={disabled}
      onChange={e => {
        if (isLoading) return;
        setChecked(e.target.checked);
        updateRolloverAnns(
          {
            currentYear: !!isCurrentYear,
            annotations: { [annotation]: e.target.checked ? 'true' : 'false' },
          },
          {
            onError: () => {
              toast({
                status: 'error',
                title: 'Failed to save step completion, please try again.',
                position: 'bottom-left',
              });
              setChecked(isChecked);
            },
          },
        );
      }}
      icon={isLoading ? <LoadingSpinner /> : undefined}
    >
      I&apos;ve done this
    </Checkbox>
  );
};
