// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/reports/v1/reportgen.proto" (package "sparx.science.reports.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Date } from '../../../../../google/type/date';
/**
 * @generated from protobuf message sparx.science.reports.v1.GetHomeworkCompletionReportRequest
 */
export interface GetHomeworkCompletionReportRequest {
  /**
   * School to generate the report for.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Whether or not to include the student demographic data
   *
   * @generated from protobuf field: bool include_demographic_data = 2;
   */
  includeDemographicData: boolean;
  /**
   * The earliest due date that should be considered. Must be specified with end_date.
   * The service will use current school year dates if these fields are not supplied.
   *
   * @generated from protobuf field: google.type.Date start_date = 3;
   */
  startDate?: Date;
  /**
   * The latest due date that should be considered. Must be specified with start_date.
   * The service will use current school year dates if these fields are not supplied.
   *
   * @generated from protobuf field: google.type.Date end_date = 4;
   */
  endDate?: Date;
}
/**
 * @generated from protobuf message sparx.science.reports.v1.GetHomeworkCompletionReportResponse
 */
export interface GetHomeworkCompletionReportResponse {
  /**
   * Download URL for the generated report.
   *
   * @generated from protobuf field: string download_url = 1;
   */
  downloadUrl: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkCompletionReportRequest$Type extends MessageType<GetHomeworkCompletionReportRequest> {
  constructor() {
    super('sparx.science.reports.v1.GetHomeworkCompletionReportRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'include_demographic_data',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 3, name: 'start_date', kind: 'message', T: () => Date },
      { no: 4, name: 'end_date', kind: 'message', T: () => Date },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.reports.v1.GetHomeworkCompletionReportRequest
 */
export const GetHomeworkCompletionReportRequest =
  new GetHomeworkCompletionReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkCompletionReportResponse$Type extends MessageType<GetHomeworkCompletionReportResponse> {
  constructor() {
    super('sparx.science.reports.v1.GetHomeworkCompletionReportResponse', [
      {
        no: 1,
        name: 'download_url',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.reports.v1.GetHomeworkCompletionReportResponse
 */
export const GetHomeworkCompletionReportResponse =
  new GetHomeworkCompletionReportResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.science.reports.v1.Reports
 */
export const Reports = new ServiceType('sparx.science.reports.v1.Reports', [
  {
    name: 'GetHomeworkCompletionReport',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:student',
        domain: '{school_name}',
      },
    },
    I: GetHomeworkCompletionReportRequest,
    O: GetHomeworkCompletionReportResponse,
  },
]);
