import { Box, Button } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaffListView as StaffListViewComponent } from '@sparx/staff-manager';
import { useUserType } from 'api/sessions';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import React from 'react';
import { Link } from 'react-router-dom';

export const StaffListView = () => {
  const { isSparxStaff } = useUserType();

  return (
    <SuspenseRoute>
      <StaffListViewComponent isSparxStaff={isSparxStaff}>
        {({ systemDropdown, table }) => (
          <PageContainer>
            <PageTitle title="Staff Manager">
              {systemDropdown}
              <AddStaffMemberButton />
            </PageTitle>
            <Box data-sentry-mask>{table}</Box>
          </PageContainer>
        )}
      </StaffListViewComponent>
    </SuspenseRoute>
  );
};

const AddStaffMemberButton = () => (
  <Button
    as={Link}
    to="/teacher/staff/new"
    colorScheme="buttonTeal"
    leftIcon={<FontAwesomeIcon icon={faPlus} />}
  >
    Add staff member
  </Button>
);
