import { NotFound } from 'components/errorpages/NotFound';
import { LargeLoading } from 'components/loading/LargeLoading';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import queryString from 'query-string';
import React from 'react';
import { HandinTable } from 'views/handin/HandInTable';

export const AssignmentHandInView = () => {
  const query = queryString.parse(location.search);
  const assignmentID = query.assignment?.toString() || '';

  if (!assignmentID) {
    return <NotFound />;
  }
  return (
    <React.Suspense fallback={<LargeLoading />}>
      <PageContainer>
        <PageTitle title="Assignment Hand-in" backButton="/teacher/assignments" />
        <HandinTable assignmentID={assignmentID} />
      </PageContainer>
    </React.Suspense>
  );
};
