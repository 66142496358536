import { MisSyncImport } from '@sparx/mis-sync-import';
import { withInterimLocked } from 'components/InterimLock';

import { MisSyncImportContextWrapper } from './MisSyncImportContextWrapper';

export const MisSyncImportView = withInterimLocked(
  () => {
    return (
      <MisSyncImportContextWrapper>
        <MisSyncImport />
      </MisSyncImportContextWrapper>
    );
  },
  { title: 'Import and Manage Classes' },
);
