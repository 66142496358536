import { Box, Flex, IconButton, Image, Text, Tooltip } from '@chakra-ui/react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSchool } from 'api/school';
import logo from 'app/images/science_white.svg';
import { toPng } from 'html-to-image';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { sleep } from 'utils/interval';

export const DownloadableElement = ({
  children,
  downloadable,
  filename,
}: PropsWithChildren<{ downloadable: React.ReactNode; filename: string }>) => {
  const { download, element, isLoading } = useDownloadImage(downloadable, filename);
  return (
    <>
      <Box position="relative" flex={1} height="100%">
        <DownloadImageButton onClick={download} isLoading={isLoading} />
        {children}
      </Box>
      {element}
    </>
  );
};

const DownloadImageButton = ({
  onClick,
  isLoading,
}: {
  onClick: () => void;
  isLoading?: boolean;
}) => (
  <Tooltip
    label="Download as image"
    placement="top"
    hasArrow={true}
    py={1}
    px={0}
    textAlign="center"
    lineHeight="1.3em"
    w={36}
  >
    <IconButton
      aria-label="Download"
      variant="solid"
      size="sm"
      colorScheme="blackAlpha"
      position="absolute"
      top={3}
      right={3}
      zIndex={5}
      onClick={onClick}
      isLoading={isLoading}
    >
      <FontAwesomeIcon icon={faDownload} />
    </IconButton>
  </Tooltip>
);

const useDownloadImage = (element: React.ReactNode, filename: string) => {
  const [show, setShow] = useState(false);
  const visibleElement = show ? (
    <DownloadImage element={element} onDone={() => setShow(false)} filename={filename} />
  ) : null;
  return { download: () => setShow(true), element: visibleElement, isLoading: show };
};

interface DownloadImageProps {
  element: React.ReactNode;
  onDone: () => void;
  filename: string;
}

const DownloadImage = ({ element, onDone, filename }: DownloadImageProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      const containerRef = ref.current;
      (async () => {
        // Slight delay to ensure that the image has time to render
        await sleep(1000);
        try {
          const options = {
            pixelRatio: 2,
            width: 600,
            height: 300,
          };

          // Patch to ensure that the images are loaded on Safari.
          // Don't ask... https://github.com/bubkoo/html-to-image/issues/361#issuecomment-1402537176
          // TODO: remove this - there must be something better
          await toPng(containerRef, options);
          await toPng(containerRef, options);
          await toPng(containerRef, options);

          const dataUrl = await toPng(containerRef, options);

          // Download
          const link = document.createElement('a');
          link.download = `${filename}.png`;
          link.href = dataUrl;
          link.click();
        } catch (e) {
          console.error(e);
        } finally {
          onDone();
        }
      })();
    }
  }, [filename, onDone, ref]);

  return (
    <Box display="none">
      <Box ref={ref} width={600} height={300} top={0} left={0} position="fixed">
        {element}
        <SparxScienceAddon />
      </Box>
    </Box>
  );
};

const SparxScienceAddon = () => {
  const { data: school } = useSchool({ suspense: false });
  return (
    <>
      <Box
        position="absolute"
        inset="0 0 auto 0"
        color="white"
        pt={4}
        px={6}
        fontSize="lg"
        fontWeight="bold"
      >
        {school?.displayName}
      </Box>

      <Flex position="absolute" bottom={0} alignItems="center" width="100%" pb={4} px={6}>
        <Image src={logo} alt="Sparx Science" height="26px" />
        <Box flex={1} />
        <Text color="white">sparxscience.com</Text>
      </Flex>
    </>
  );
};
