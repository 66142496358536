import { Button, HStack, Text } from '@chakra-ui/react';
import { faCheck, faRedo, faSpinner, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import React from 'react';

interface UndoButtonsProps {
  isLoading: boolean;
  isSuccess: boolean;
  undo: () => void;
  redo: () => void;
  hasHistory: boolean;
  hasFuture: boolean;
  savedTime?: Date;
}

export const UndoButtons = ({
  isLoading,
  isSuccess,
  undo,
  redo,
  hasHistory,
  hasFuture,
  savedTime,
}: UndoButtonsProps) => (
  <HStack spacing={2}>
    {isLoading && (
      <HStack spacing={2} color="gray.400" fontSize="sm" pr={4}>
        <FontAwesomeIcon icon={faSpinner} spin />
        <Text color="gray.500">Saving...</Text>
      </HStack>
    )}
    {isSuccess && (
      <Text color="gray.500" fontSize="sm" pr={4} textAlign="right">
        <Text color="green.400" pr={2} as="span">
          <FontAwesomeIcon icon={faCheck} />
        </Text>
        <Text as="span" display={{ base: 'none', xl: 'inline' }}>
          Changes automatically saved
        </Text>
        <Text as="span" display={{ base: 'inline', xl: 'none' }}>
          Saved
        </Text>
        <Text as="span" whiteSpace="nowrap">
          {savedTime && ` at ${format(savedTime, 'pp')}`}
        </Text>
      </Text>
    )}
    <Button leftIcon={<FontAwesomeIcon icon={faUndo} />} onClick={undo} isDisabled={!hasHistory}>
      Undo
    </Button>
    <Button leftIcon={<FontAwesomeIcon icon={faRedo} />} onClick={redo} isDisabled={!hasFuture}>
      Redo
    </Button>
  </HStack>
);
