import { Student } from '@sparx/api/apis/sparx/misintegration/wondewitch/v1/wondewitch';
import { School } from '@sparx/api/apis/sparx/school/v2/schools';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { getNameForWondeStudent } from '@sparx/mis-sync-import/src/processing';
import {
  ConflictingStudent,
  SchoolData,
  WondeClass,
  WondeData,
} from '@sparx/mis-sync-import/src/types';
import {
  getSystemOptions,
  getWondeSyncErrors,
  isGroupExpired,
  schoolIDFromName,
  WONDE_NO_SUBJECT,
  WONDE_REGISTRATION_GROUPS_SUBJECT,
  wondeIDOfGroup,
} from '@sparx/mis-sync-import/src/utils';
import { AnnotationKeys } from '@sparx/schoolutils';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { getConflictingStudentsByProduct } from './conflictingStudents';
import { useMisSyncContext } from './Context';
import { useSyncConfig } from './MisSyncImport/context/config';
import {
  useGetStudentGroups,
  useListMisClasses,
  useListMisRegistrationGroups,
  useListStudents,
  useListWondeSubjects,
  useListYearGroups,
} from './queries';

// Load school data and return it, along with loading and error statuses
export const useGetSchoolData = (schoolName?: string) => {
  const schoolID = schoolIDFromName(schoolName);

  const {
    data: studentGroups,
    isFetching: groupsIsLoading,
    isError: groupsIsError,
  } = useGetStudentGroups(schoolName || '');

  const {
    data: students,
    isFetching: schoolStudentsIsLoading,
    isError: schoolStudentsIsError,
  } = useListStudents(schoolID);

  const {
    data: yearGroups,
    isFetching: schoolYearGroupsIsLoading,
    isError: schoolYearGroupsIsError,
  } = useListYearGroups(schoolName || '');

  // Consider loading to be true if any query is in loading status
  const loading = groupsIsLoading || schoolStudentsIsLoading || schoolYearGroupsIsLoading;

  // Consider error to be true if any query returned an error
  const error = groupsIsError || schoolStudentsIsError || schoolYearGroupsIsError;

  const schoolData = useMemo(() => {
    if (studentGroups && students && yearGroups) {
      return {
        studentGroups,
        students,
        yearGroups,
      };
    }
    return undefined;
  }, [studentGroups, students, yearGroups]);

  return {
    schoolData,
    loading,
    error,
  };
};

// Load Wonde data and return it, along with loading and error statuses
export const useGetWondeData = (schoolName?: string) => {
  const schoolID = schoolIDFromName(schoolName);

  const {
    data: wondeClasses,
    isFetching: wondeClassesIsLoading,
    isError: wondeClassesIsError,
    error: wondeClassesError,
  } = useListMisClasses(schoolID);

  const {
    data: wondeRegistrationGroups,
    isFetching: wondeRegistrationGroupsIsLoading,
    isError: wondeRegistrationGroupsIsError,
    error: wondeRegistrationGroupsError,
  } = useListMisRegistrationGroups(schoolID);

  const {
    data: wondeSubjects,
    isFetching: wondeSubjectsIsLoading,
    isError: wondeSubjectsIsError,
    error: wondeSubjectsError,
  } = useListWondeSubjects(schoolID);

  // Consider loading to be true if any query is in loading status
  const loading =
    wondeClassesIsLoading || wondeRegistrationGroupsIsLoading || wondeSubjectsIsLoading;

  // Consider error to be true if any query returned an error
  const error = wondeClassesIsError || wondeRegistrationGroupsIsError || wondeSubjectsIsError;

  const errors = [
    ...getWondeSyncErrors(wondeClassesError),
    ...getWondeSyncErrors(wondeRegistrationGroupsError),
    ...getWondeSyncErrors(wondeSubjectsError),
  ];

  // If we have any errors, and any of them are unauthorised or permission denied, then we'll need
  // to show a different error message to the user
  const hasWondeTroubleshootingErrors = errors.some(
    e => e.type === 'UNAUTHORIZED' || e.type === 'PERMISSION_DENIED',
  );

  const wondeData: WondeData | undefined = useMemo(() => {
    // Add registration group subject and no subject to Record for lookup purposes
    const allWondeSubjects = {
      ...wondeSubjects,
      [WONDE_REGISTRATION_GROUPS_SUBJECT.id]: WONDE_REGISTRATION_GROUPS_SUBJECT,
      [WONDE_NO_SUBJECT.id]: WONDE_NO_SUBJECT,
    };

    // If all queries have returned data, populate them into wondeData, otherwise the whole object
    // will be undefined
    if (wondeSubjects && wondeClasses && wondeRegistrationGroups) {
      return {
        wondeSubjects: allWondeSubjects,
        wondeClasses: wondeClasses.concat(wondeRegistrationGroups),
      };
    }

    return undefined;
  }, [wondeClasses, wondeRegistrationGroups, wondeSubjects]);

  return {
    wondeData,
    loading,
    error,
    hasWondeTroubleshootingErrors,
  };
};

// Remove the default scroll from Reader on load, place it back when the component is unmounted
export const useRemoveScroll = () => {
  useEffect(() => {
    const els = document.getElementsByClassName('scrollable');
    const setOverflow = (overflow: string) => {
      Array.from(els).forEach(el => {
        (el as HTMLDivElement).style.overflow = overflow;
      });
    };
    setOverflow('hidden');

    return () => setOverflow('');
  }, []);
};

/**
 * Given the state of a sync, returns a list of conflicting students (i.e. students in more than one class for a
 * product). A conflict consists of a student, the classes they are in and the default resolution which is the Wonde ID
 * of the class they are currently in the school (or undefined if they are not in class yet)
 */
export const useConflictingStudentsByProduct = () => {
  const { groupSubject, school, useLegalNames } = useMisSyncContext();
  const { wondeData } = useGetWondeData(school?.name);
  const { schoolData } = useGetSchoolData(school?.name);
  const syncConfig = useSyncConfig();

  return useMemo(() => {
    const classesToAdd = syncConfig.syncConfig.classesToAdd.map(c => c.id);

    // Get the WondeIDs of classes to remove
    const schoolClassesToRemove = syncConfig.syncConfig.classesToRemove;
    const classesToRemove: string[] = [];
    for (const g of syncConfig.syncConfig.existingClasses) {
      if (schoolClassesToRemove.includes(g.name)) {
        const classWondeID = wondeIDOfGroup(g);
        if (classWondeID) {
          classesToRemove.push(classWondeID);
        }
      }
    }

    return getConflictingStudentsByProduct(
      groupSubject,
      wondeData,
      schoolData,
      classesToAdd,
      classesToRemove,
      useLegalNames,
    );
  }, [
    groupSubject,
    schoolData,
    syncConfig.syncConfig.classesToAdd,
    syncConfig.syncConfig.classesToRemove,
    syncConfig.syncConfig.existingClasses,
    useLegalNames,
    wondeData,
  ]);
};

/**
 * Given the state of a sync, returns a list of conflicting students for the current product. A conflict consists of a
 * student, the classes they are in and the default resolution which is the Wonde ID of the class they are currently in
 * the school (or undefined if they are not in class yet). Also returns a list of subjects other than the product
 * subject with unresolved conflicts - these will prevent a sync occurring, as they can only be resolved by Sparx staff
 * at the moment. Also provides details of all the default conflict resolutions (i.e. students' current classes) for
 * each product
 */
export const useConflictingStudents = (): {
  otherSubjectsWithUnresolvedConflicts: Set<StudentGroupType>;
  conflictingStudents: ConflictingStudent[];
  defaultResolutionsByProduct: Map<StudentGroupType, Record<string, string>>;
} => {
  const { groupSubject, debug, school } = useMisSyncContext();
  const { wondeData } = useGetWondeData(school?.name);
  const conflictingStudentsByProduct = useConflictingStudentsByProduct();
  return useMemo(() => {
    const otherSubjectsWithUnresolvedConflicts: Set<StudentGroupType> = new Set<StudentGroupType>();
    const defaultResolutionsByProduct = new Map<StudentGroupType, Record<string, string>>();
    for (const [subject, conflicts] of conflictingStudentsByProduct.entries()) {
      const defaultResolutions: Record<string, string> = {};
      for (const conflict of conflicts) {
        if (conflict.defaultResolution === undefined && subject !== groupSubject) {
          if (debug) {
            const conflictingClassNames = conflict.classWondeIDs.map(
              id => wondeData?.wondeClasses.find(c => c.id === id)?.name,
            );
            console.log(
              `Other subject ${getSystemOptions(subject).system} has unresolved conflict:`,
              {
                studentWondeID: conflict.studentWondeID,
                conflictingClassNames,
                defaultResolution: conflict.defaultResolution,
              },
            );
          }
          otherSubjectsWithUnresolvedConflicts.add(subject);
          break;
        }
        if (conflict.defaultResolution !== undefined) {
          defaultResolutions[conflict.studentWondeID] = conflict.defaultResolution;
        }
      }
      defaultResolutionsByProduct.set(subject, defaultResolutions);
    }
    // Conflicting students for the current product
    const conflictingStudents = conflictingStudentsByProduct.get(groupSubject) || [];
    return {
      otherSubjectsWithUnresolvedConflicts,
      conflictingStudents,
      defaultResolutionsByProduct,
    };
  }, [groupSubject, conflictingStudentsByProduct, debug, wondeData]);
};

/**
 * Given the state of a sync, returns a list of unresolved conflicts for the current product. An unresolved conflict is
 * a student who is in more than one class, where they are not already in one of the classes within Sparx, and the user
 * has not yet resolved the conflict by choosing a class for them to belong to after the sync.
 */
export const useUnresolvedConflicts = () => {
  const { conflictingStudents } = useConflictingStudents();
  const syncConfig = useSyncConfig();
  const { conflictResolutions } = syncConfig.syncConfig;
  return useMemo(
    () =>
      conflictingStudents.filter(
        cs => !cs.defaultResolution && !conflictResolutions[cs.studentWondeID],
      ),
    [conflictingStudents, conflictResolutions],
  );
};

// Create a record of Sparx classes keyed by Wonde Class ID. We use this to quickly see if a Wonde
// Class already has a Sparx equivalent. This explicitly excludes tutor groups unless that tutor
// group has been imported as a class.
export const useSparxClassByWondeID = (schoolData: SchoolData) => {
  return useMemo(() => {
    const byWondeID: Record<string, Group> = {};
    for (const sparxGroup of schoolData.studentGroups) {
      // Ignore reg groups. This doesn't include reg groups that have been imported as a class as
      // they will have a different type.
      if (sparxGroup.type === StudentGroupType.TUTORGROUP) {
        continue;
      }
      const wondeID = wondeIDOfGroup(sparxGroup);
      if (wondeID) {
        byWondeID[wondeID] = sparxGroup;
      }
    }
    return byWondeID;
  }, [schoolData.studentGroups]);
};

// If the last sync time has changed, set hasRemoteUpdates to true which will force the user
// to reload. This ensures they get the most up-to-date data for their school.
export const useCheckForRemoteUpdates = (school: School) => {
  const { reloadSchool } = useMisSyncContext();
  const [, setLastSyncTime] = useState<string>();
  const [hasRemoteUpdates, setHasRemoteUpdates] = useState(false);
  useEffect(() => {
    const lastUpdated = school.annotations[AnnotationKeys.WondeLastSuccess];
    setLastSyncTime(v => {
      if (v && v !== lastUpdated) {
        setHasRemoteUpdates(true);
      }
      return lastUpdated;
    });
  }, [school.annotations]);
  return {
    hasRemoteUpdates,
    checkForRemoteUpdates: reloadSchool,
    resetLastSyncTime: () => setLastSyncTime(undefined),
  };
};

// Get the time a component has been visible on the page.
export const useTimeVisible = () => {
  const [startTime] = useState(new Date());
  return {
    getTimeVisible: () => (new Date().getTime() - startTime.getTime()) / 1000,
  };
};

// Simple timer for tracking time spent on various parts of the sync process.
export const useTimer = () => {
  const [startTime, setStartTime] = useState<Date | undefined>();
  return {
    start: () => setStartTime(new Date()),
    reset: () => setStartTime(undefined),
    get: () => {
      if (!startTime) {
        return 0;
      }
      return (new Date().getTime() - startTime.getTime()) / 1000;
    },
  };
};

/**
 * Calculate how many unsaved changes there are on the config page. This includes the number of classes being added
 * plus the number of classes being removed plus the number of existing classes where the year group has been changed
 * Note: we decided it would be confusing to include conflict resolutions
 */
export const useUnsavedChangesCount = (): number => {
  const { syncConfig } = useSyncConfig();

  return useMemo((): number => {
    let count = 0;

    // Don't include groups removed from the MIS (not changes the user made)
    const groupsRemovedFromWonde = new Set<string>(
      syncConfig.sparxGroupsRemovedFromWonde.map(c => c.name),
    );

    const automaticallyAddedClasses = new Set<string>(syncConfig.automaticallyAddedClasses);

    count += syncConfig.classesToAdd.filter(c => !automaticallyAddedClasses.has(c.id)).length;
    count += syncConfig.classesToRemove.filter(c => !groupsRemovedFromWonde.has(c)).length;
    count += syncConfig.existingClasses.filter(
      c =>
        c.configuredYearGroupId !== c.yearGroupId && !syncConfig.classesToRemove.includes(c.name),
    ).length;

    return count;
  }, [
    syncConfig.automaticallyAddedClasses,
    syncConfig.classesToAdd,
    syncConfig.classesToRemove,
    syncConfig.existingClasses,
    syncConfig.sparxGroupsRemovedFromWonde,
  ]);
};

export type StudentSummaryDetails = {
  currentProductClasses: {
    wondeClassName: string;
  }[];
  otherProductClasses: {
    wondeClassName: string;
    subject?: StudentGroupType;
  }[];
  name: string;
};

/**
 * Returns a map that allows you to lookup details of a student by their Wonde ID.
 * This includes the student's name, and classes within the current product and different products.
 * It is current used to give details about students involved in UPN conflicts errors.
 */
export const useStudentSummaryDetails = (): Map<string, StudentSummaryDetails> => {
  const { groupSubject, school, useLegalNames } = useMisSyncContext();
  const { wondeData } = useGetWondeData(school?.name);
  const { schoolData } = useGetSchoolData(school?.name);
  const syncConfig = useSyncConfig();

  return useMemo(() => {
    // Create a map of which product each class is imported for:
    const importedWondeClassProducts = new Map<string, StudentGroupType>();

    const classesToRemove = new Set<string>();
    const schoolClassesToRemove = syncConfig.syncConfig.classesToRemove;
    for (const g of syncConfig.syncConfig.existingClasses) {
      if (schoolClassesToRemove.includes(g.name)) {
        const classWondeID = wondeIDOfGroup(g);
        if (classWondeID) {
          classesToRemove.add(classWondeID);
        }
      }
    }

    // The classes being added are always imported for the current product
    for (const classToAdd of syncConfig.syncConfig.classesToAdd) {
      importedWondeClassProducts.set(classToAdd.id, groupSubject);
    }

    // Add the group types of all the student groups currently imported, unless they are being removed:
    for (const sg of schoolData?.studentGroups || []) {
      const wondeID = wondeIDOfGroup(sg);
      if (
        wondeID &&
        !classesToRemove.has(wondeID) &&
        sg.type !== StudentGroupType.TUTORGROUP &&
        !isGroupExpired(sg)
      ) {
        importedWondeClassProducts.set(wondeID, sg.type);
      }
    }

    const studentSummaryDetails = new Map<string, StudentSummaryDetails>();

    // Now make a list of every student's classes:
    for (const wc of wondeData?.wondeClasses || []) {
      if (importedWondeClassProducts.has(wc.id)) {
        for (const student of wc.students) {
          let details: StudentSummaryDetails | undefined = studentSummaryDetails.get(student.id);
          if (details === undefined) {
            details = {
              name: getNameForWondeStudent(student, useLegalNames),
              currentProductClasses: [],
              otherProductClasses: [],
            };
          }
          const product = importedWondeClassProducts.get(wc.id);
          if (product === groupSubject) {
            details.currentProductClasses.push({ wondeClassName: wc.name });
          } else {
            details.otherProductClasses.push({
              wondeClassName: wc.name,
              subject: product,
            });
          }
          studentSummaryDetails.set(student.id, details);
        }
      }
    }

    return studentSummaryDetails;
  }, [
    groupSubject,
    schoolData?.studentGroups,
    syncConfig.syncConfig.classesToAdd,
    syncConfig.syncConfig.classesToRemove,
    syncConfig.syncConfig.existingClasses,
    useLegalNames,
    wondeData?.wondeClasses,
  ]);
};

export const useFindIdenticalClasses = (selectedClasses: Set<WondeClass>) => {
  const [identicalClasses, setIdenticalClasses] = useState<Set<string>>(new Set());
  useEffect(() => {
    if (selectedClasses.size === 0) {
      setIdenticalClasses(new Set());
      return;
    }
    const getStudentIDHash = (students: Student[]) =>
      students
        .map(s => s.id)
        .sort()
        .join('#');
    const newIdenticalClasses = new Set<string>();
    selectedClasses.forEach(cls => {
      // Don't compare classes with no students
      if (cls.students.length === 0) {
        return;
      }
      const hash = getStudentIDHash(cls.students);
      selectedClasses.forEach(otherCls => {
        const otherHash = getStudentIDHash(otherCls.students);
        if (cls.id !== otherCls.id && hash === otherHash) {
          newIdenticalClasses.add(cls.id);
          newIdenticalClasses.add(otherCls.id);
        }
      });
    });
    setIdenticalClasses(newIdenticalClasses);
  }, [selectedClasses]);
  return identicalClasses;
};

/**
 * Given a ref to a div, set the height of the div to be the height of the viewport minus the offsetTop of the div
 * minus 85px (the height of the footer). This also attaches a listener to the parent node to check when elements are
 * added or removed, as the height of the element needs to change when banners get removed.
 */
export const useSetContainerHeight = () => {
  const [height, setHeight] = useState<string>();
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    // Get the available height, this is the height of the viewport minus the offsetTop of the div minus 20px
    // (to give a bit of space at the bottom).
    const getAvailableHeight = () => {
      const offsetTop = ref.current?.getBoundingClientRect().top || 0;
      return `calc(100vh - ${offsetTop}px - 20px)`;
    };
    // Set the height on load
    setHeight(getAvailableHeight());
    // Observe the parent node and check when elements are added or removed. This is necessary because the height of
    // the element needs to change when banners get removed.
    const observer = new MutationObserver(() => setHeight(getAvailableHeight()));
    if (ref.current?.parentNode) {
      // Observe the parent node for changes to the child list
      observer.observe(ref.current?.parentNode, { childList: true });
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  return { ref, height };
};
