import {
  Box,
  Button,
  Center,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faAnglesDown, faAnglesUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  SchemeOfLearningWeek,
  SchemeOfLearningWeekType,
} from '@sparx/api/apis/sparx/planning/v1/sol';
import { TopicWithStrand } from 'api/content';
import React, { PropsWithChildren } from 'react';
import { Sortable } from 'views/sol/components/Draggable';
import { ConsolidationTopic, TopicData } from 'views/sol/components/TopicData';

interface WeekContentProps {
  weekIndex: number;
  week: SchemeOfLearningWeek | undefined;
  topics: Dictionary<string, TopicWithStrand>;
  onRemoveItem: (id: string) => void;
  onMoveAll: (dir: 'up' | 'down') => void;
  onSetWeekType: (weekType: SchemeOfLearningWeekType) => void;
  canMoveDown?: boolean;
  canMoveUp?: boolean;
  readonly?: boolean;
  isTemplate: boolean;
}

export const WeekContent = ({
  weekIndex,
  week,
  topics,
  onRemoveItem,
  onMoveAll,
  onSetWeekType,
  canMoveUp,
  canMoveDown,
  readonly,
  isTemplate,
}: WeekContentProps) => {
  const homeworkEnabled = week?.weekType !== SchemeOfLearningWeekType.NO_HOMEWORK;
  const { setNodeRef } = useDroppable({ id: `week/${weekIndex}`, disabled: !homeworkEnabled });

  const droppableEnabled = !readonly;

  const items = (week?.workUnits || []).map(item => (
    <Sortable enabled={droppableEnabled} id={item.workUnitId} key={item.workUnitId}>
      {props =>
        item.payload.oneofKind === 'topic' ? (
          <TopicData
            topic={topics[item.payload.topic]}
            draggableProps={props}
            onRemove={() => onRemoveItem(item.workUnitId)}
            readonly={readonly}
          />
        ) : null
      }
    </Sortable>
  ));

  let content = (
    <VStack spacing={1} alignItems="stretch">
      {droppableEnabled ? (
        <SortableContext
          items={(week?.workUnits || []).map(i => i.workUnitId)}
          strategy={verticalListSortingStrategy}
        >
          {items}
        </SortableContext>
      ) : (
        items
      )}
      {readonly ? (
        <ConsolidationTopic />
      ) : (
        <Box
          borderColor="gray.200"
          borderWidth="2px"
          borderRadius="md"
          borderStyle="dashed"
          py={3}
          px={4}
        >
          <Text color="gray.500" fontSize="sm">
            Drag topics here
          </Text>
        </Box>
      )}
    </VStack>
  );
  if (!homeworkEnabled) {
    content = (
      <Center flex={1} pb={4} color="red.700" fontWeight="bold">
        No homework will be handed out during this week
      </Center>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      p={2}
      ref={droppableEnabled ? setNodeRef : null}
      borderLeftWidth={4}
      borderLeftColor={homeworkEnabled ? 'white' : 'red.400'}
      bg={homeworkEnabled ? 'white' : 'red.50'}
    >
      <HStack spacing={2} pt={2} pb={3} pl={2}>
        {!isTemplate && (
          <>
            <Text>Homework:</Text>
            {!readonly && (
              <Switch
                isChecked={homeworkEnabled}
                onChange={e =>
                  onSetWeekType(
                    e.target.checked
                      ? SchemeOfLearningWeekType.FULL_HOMEWORK
                      : SchemeOfLearningWeekType.NO_HOMEWORK,
                  )
                }
              />
            )}
            <Text>{homeworkEnabled ? 'on' : 'off'}</Text>
          </>
        )}
        <Spacer />
        {!readonly && (canMoveUp || canMoveDown) && (
          <Menu>
            <MenuButton
              visibility={homeworkEnabled ? 'visible' : 'hidden'}
              as={Button}
              size="sm"
              rightIcon={<FontAwesomeIcon icon={faCaretDown} />}
            >
              Move topics
            </MenuButton>
            <MenuList>
              {canMoveUp && (
                <LargeMenuItem
                  onClick={() => onMoveAll('up')}
                  icon={faAnglesUp}
                  title="Move topics up"
                >
                  Move topics in Week {week?.weekIndex} and all future weeks <strong>up</strong> by
                  one week.
                </LargeMenuItem>
              )}
              {canMoveDown && (
                <LargeMenuItem
                  onClick={() => onMoveAll('down')}
                  icon={faAnglesDown}
                  title="Move topics down"
                >
                  Move topics in Week {week?.weekIndex} and all future weeks <strong>down</strong>{' '}
                  by one week.
                </LargeMenuItem>
              )}
            </MenuList>
          </Menu>
        )}
      </HStack>
      {content}
    </Box>
  );
};

interface LargeMenuItemProps {
  title: string;
  onClick: () => void;
  icon: IconDefinition;
  isDisabled?: boolean;
}

export const LargeMenuItem = ({
  title,
  onClick,
  icon,
  children,
  isDisabled,
}: PropsWithChildren<LargeMenuItemProps>) => (
  <MenuItem onClick={onClick} display="flex" isDisabled={isDisabled}>
    <Box fontSize="xl" color="teal.700" ml={2} mr={4}>
      <FontAwesomeIcon icon={icon} fixedWidth={true} />
    </Box>
    <Box>
      <Text fontWeight="bold">{title}</Text>
      <Text fontSize="sm">{children}</Text>
    </Box>
  </MenuItem>
);
