import { useMisSyncContext } from '@sparx/mis-sync-import/src/Context';
import { getSystemOptions } from '@sparx/mis-sync-import/src/utils';

import styles from './SupportLink.module.css';

/**
 * Support link component, which will either be a button or a link depending on the system options
 * @param linkText
 * @constructor
 */
export const SupportLink = ({ linkText }: { linkText: string }) => {
  const { groupSubject, supportCentreTrigger } = useMisSyncContext();
  const { supportLink } = getSystemOptions(groupSubject);

  if (supportCentreTrigger) {
    return (
      <button className={styles.SupportCenterButton} onClick={supportCentreTrigger}>
        {linkText}
      </button>
    );
  }
  return (
    <a className={styles.SupportLink} href={supportLink} target="_blank" rel="noreferrer">
      {linkText}
    </a>
  );
};
