// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/packages/v1/assessment.proto" (package "sparx.science.packages.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Package } from './package';
/**
 * @generated from protobuf message sparx.science.packages.v1.CreateFixUpPackageRequest
 */
export interface CreateFixUpPackageRequest {
  /**
   * @generated from protobuf field: string assessment_name = 1;
   */
  assessmentName: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.CreateFixUpPackageResponse
 */
export interface CreateFixUpPackageResponse {
  /**
   * List of packages that were created. Usually there will only be one.
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.Package package = 1;
   */
  package: Package[];
}
// @generated message type with reflection information, may provide speed optimized methods
class CreateFixUpPackageRequest$Type extends MessageType<CreateFixUpPackageRequest> {
  constructor() {
    super('sparx.science.packages.v1.CreateFixUpPackageRequest', [
      {
        no: 1,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.CreateFixUpPackageRequest
 */
export const CreateFixUpPackageRequest = new CreateFixUpPackageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateFixUpPackageResponse$Type extends MessageType<CreateFixUpPackageResponse> {
  constructor() {
    super('sparx.science.packages.v1.CreateFixUpPackageResponse', [
      {
        no: 1,
        name: 'package',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Package,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.CreateFixUpPackageResponse
 */
export const CreateFixUpPackageResponse = new CreateFixUpPackageResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.science.packages.v1.Assessment
 */
export const Assessment = new ServiceType(
  'sparx.science.packages.v1.Assessment',
  [
    {
      name: 'CreateFixUpPackage',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'sw:student',
          domain: 'sw',
        },
      },
      I: CreateFixUpPackageRequest,
      O: CreateFixUpPackageResponse,
    },
  ],
);
