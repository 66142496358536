import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { ResourceStatus } from '@sparx/api/apis/sparx/planning/v1/sol';
import { useDeleteSol, useDeleteTemplateSol } from 'api/sol';
import { Warning } from 'components/warning';

import { AnnotatedSol } from './SolTable';

interface DeleteSolModalProps {
  scheme: AnnotatedSol | undefined;
  sols: AnnotatedSol[];
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteSolModal = ({ scheme, sols, onClose, onDelete }: DeleteSolModalProps) => {
  const { mutateAsync: deleteSol, isLoading } = useDeleteSol();
  const { mutateAsync: deleteTemplate, isLoading: isLoadingTemplate } = useDeleteTemplateSol();

  // See if we have published/draft version of this sol
  const isDraft = scheme?.metadata?.status === ResourceStatus.DRAFT;
  const isPublished = scheme?.metadata?.status === ResourceStatus.PUBLISHED;
  const hasPublishedSol = Boolean(
    sols.find(s => s.name === scheme?.name && s.metadata?.status === ResourceStatus.PUBLISHED),
  );
  const hasDraft = Boolean(
    sols.find(s => s.name === scheme?.name && s.metadata?.status === ResourceStatus.DRAFT),
  );

  const submit = () =>
    scheme &&
    (scheme.isTemplate
      ? deleteTemplate({
          name: scheme.name,
          resetDraft: hasPublishedSol && isDraft,
        }).then(onDelete)
      : deleteSol({
          name: scheme.name,
          resetDraft: hasPublishedSol && isDraft,
        }).then(onDelete));

  return (
    <Modal isOpen={!!scheme} size="xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb={1}>
          Delete {scheme?.isTemplate && 'Template'} Scheme of Learning
        </ModalHeader>
        <ModalBody>
          <Text>
            Are you sure you want to delete this {scheme?.isTemplate ? 'template' : 'scheme'}?
          </Text>
          {isPublished && hasDraft && (
            <Warning status="warning" mt={3}>
              This published Scheme of Learning has a draft version that will be deleted as well.
            </Warning>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onClose}
            mr={2}
            variant="outline"
            isDisabled={isLoading || isLoadingTemplate}
          >
            Close
          </Button>
          <Button
            onClick={submit}
            colorScheme="buttonTeal"
            isLoading={isLoading || isLoadingTemplate}
          >
            Delete
          </Button>
        </ModalFooter>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};
