import { CircularProgress, Flex, Link as ChakraLink, Text } from '@chakra-ui/react';
import { faListAlt } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren } from 'react';
import { articleIDToURL } from 'utils/knowledgeBaseArticles';

import { IsRolloverProps, PageTab, TabDetails } from '..';
import { StatusIcon } from '../components/Tab';
import { useGettingStartedProgress } from '../hooks';
import groupIcon from '../icons/group.svg';
import mailIcon from '../icons/mail.svg';
import starIcon from '../icons/star_circle.svg';

const title = 'Overview';

export const Page = (_: IsRolloverProps) => {
  return (
    <>
      <Text mb={2}>Top tips for a successful launch</Text>
      <Flex gap={4} flexDirection={{ base: 'column', lg: 'row' }}>
        <TipCard iconSrc={starIcon} articleId="9627197">
          Agree school policies for homework
        </TipCard>
        <TipCard iconSrc={groupIcon} articleId="8917779">
          Introduce student to Sparx Science in an assembly or tutor time
        </TipCard>
        <TipCard iconSrc={mailIcon} articleId="8917754">
          Send a letter home to parents
        </TipCard>
      </Flex>
      <Text mb={2} mt={4}>
        FAQs
      </Text>
      <Flex flexDirection="column" gap={2}>
        <FAQ articleId="8917780">How do students log in?</FAQ>
        <FAQ articleId="8917747">How does Sparx Science communicate with parents?</FAQ>
        <FAQ articleId="8917745">What extra practice can students do in addition to homework?</FAQ>
      </Flex>
    </>
  );
};

const TipCard = ({
  iconSrc,
  articleId,
  children,
}: PropsWithChildren<{ iconSrc: string; articleId: string }>) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    p={6}
    flex={1}
    justifyContent="space-between"
    bgColor="gray.100"
    borderRadius="md"
  >
    <Flex width={50} height={50}>
      <img alt="" src={iconSrc} />
    </Flex>
    <Text textAlign="center" my={2}>
      {children}
    </Text>
    <ChakraLink href={articleIDToURL(articleId)} isExternal color="teal.600">
      Find out more
      <FontAwesomeIcon size="sm" style={{ marginLeft: '4px' }} icon={faArrowRight} />
    </ChakraLink>
  </Flex>
);

const FAQ = ({ articleId, children }: PropsWithChildren<{ articleId: string }>) => (
  <ChakraLink
    display="block"
    bgColor="gray.100"
    href={articleIDToURL(articleId)}
    isExternal
    color="teal.600"
    padding={4}
  >
    {children} <FontAwesomeIcon size="sm" style={{ marginLeft: '4px' }} icon={faArrowRight} />
  </ChakraLink>
);

export const Tab = () => {};

const useTabDetails = (_: IsRolloverProps): TabDetails => {
  const { total, complete, loading } = useGettingStartedProgress();

  const isComplete = complete >= total && !loading;

  const statusText = !loading ? (
    <Flex alignItems="center">
      {isComplete ? (
        <>
          <StatusIcon status="complete" /> All steps complete
        </>
      ) : (
        <>
          <CircularProgress
            min={0}
            max={total}
            value={complete}
            color="green.400"
            size={'18px'}
            thickness={'15px'}
            mr={1}
          />
          {complete}/{total} steps complete
        </>
      )}
    </Flex>
  ) : undefined;

  return {
    title: (
      <Text fontWeight="bold" fontSize="lg">
        {title}
      </Text>
    ),
    icon: faListAlt,
    statusText,
  };
};

const OverviewTab: PageTab = {
  path: 'overview',
  detailsHook: useTabDetails,
  page: Page,
};
export default OverviewTab;
