import { Button, GridItem, Select } from '@chakra-ui/react';
import { StudentSettings } from '@sparx/api/apis/sparx/science/schools/settings/v1/settings';
import { StudentWithSettings, useUpdateStudentSettings } from 'api/scienceschool';
import { getUpdateMask } from 'api/utils';
import { BulkEditor } from 'components/bulkeditor/BulkEditor';
import { ErrorBox } from 'components/errorbox';
import { EditableField } from 'components/form/FormFields';
import React, { useState } from 'react';
import {
  homeworkLevelTooltip,
  initialHomeworkLevelTooltip,
} from 'views/students/StudentDetailView';
import {
  courseOptionNames,
  courseSelectionOptions,
  courseTierOptionNames,
  courseTierOptions,
  getOptionList,
  homeworkTypeNames,
  homeworkTypeOptions,
  initialLevelOptions,
  levelOptionNames,
  levelOptionNamesWithAutomatic,
  levelOptionNamesWithoutAutomatic,
  levelOptions,
  levelOptionsWithoutAutomatic,
} from 'views/students/StudentListView';

interface StudentBulkEditProps {
  students: StudentWithSettings[];
  setHeight?: (height: number) => void;
}

export const StudentBulkEdit = ({ students, setHeight }: StudentBulkEditProps) => {
  const { mutateAsync, isLoading, error } = useUpdateStudentSettings();

  const [settings, setSettings] = useState<Partial<StudentSettings>>({});

  // Mutation control
  const saveStudentSettings = async () => {
    if (!isLoading) {
      const updates = students.map(s => ({
        ...settings,
        name: `students/${s.studentId}`,
      }));
      await mutateAsync({
        students: updates,
        paths: getUpdateMask(StudentSettings, settings),
      });
      setSettings({});
    }
  };

  return (
    <BulkEditor
      entities={students}
      settings={settings}
      setSettings={setSettings}
      entityName={['student', 'students']}
      columns={3}
      saveButton={(disabled: boolean) => (
        <Button
          isDisabled={disabled}
          isLoading={isLoading}
          colorScheme="buttonTeal"
          onClick={saveStudentSettings}
        >
          Save
        </Button>
      )}
      setHeight={setHeight}
    >
      {Boolean(error) && (
        <GridItem colSpan={3}>
          <ErrorBox error={error} />
        </GridItem>
      )}
      <StudentSettingsSelector settings={settings} setSettings={setSettings} showNoChange={true} />
    </BulkEditor>
  );
};

interface StudentSettingsSelectorProps {
  settings: Partial<StudentSettings>;
  setSettings: (settings: Partial<StudentSettings>) => void;
  showNoChange?: boolean;
  forPreview?: boolean;
}

export const StudentSettingsSelector = ({
  settings,
  setSettings,
  showNoChange,
  forPreview,
}: StudentSettingsSelectorProps) => {
  const NO_CHANGE_TEXT = '- No change -';

  const patchSettings = (s: Partial<StudentSettings>) => {
    const ns = { ...settings, ...s };
    for (const [k, v] of Object.entries(ns)) {
      if (v === undefined) {
        delete ns[k as keyof StudentSettings]; // clear out undefines
      }
    }
    setSettings(ns);
  };

  const register = (name: keyof StudentSettings, asInt: boolean) => ({
    value: settings[name] ?? NO_CHANGE_TEXT,
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (e.target.value !== NO_CHANGE_TEXT) {
        patchSettings({ [name]: asInt ? parseInt(e.target.value) : e.target.value });
      } else {
        patchSettings({ [name]: undefined });
      }
    },
  });

  const emptyOption = showNoChange && <option value={undefined}>{NO_CHANGE_TEXT}</option>;

  return (
    <>
      <EditableField
        name="Set separate questions?"
        tooltip="If set to 'No' we will exclude questions only in the separate specification from the student's homework."
      >
        <Select {...register('course', true)}>
          {emptyOption}
          {getOptionList(courseSelectionOptions, courseOptionNames)}
        </Select>
      </EditableField>
      <EditableField
        name="Set higher questions?"
        tooltip="If set to 'No' we will exclude questions only in the higher specification from the student's homework."
      >
        <Select {...register('tier', true)}>
          {emptyOption}
          {getOptionList(courseTierOptions, courseTierOptionNames)}
        </Select>
      </EditableField>
      {!forPreview && (
        <>
          <EditableField
            name="Set homework?"
            tooltip={
              <>
                Half-length will give students half as much as the rest of the class.
                <br />
                If homework is off, students will still have access to Independent Learning.
              </>
            }
          >
            <Select {...register('homeworkLength', true)}>
              {emptyOption}
              {getOptionList(homeworkTypeOptions, homeworkTypeNames)}
            </Select>
          </EditableField>
          <EditableField name="Initial homework level" tooltip={initialHomeworkLevelTooltip}>
            <Select {...register('level', false)}>
              {emptyOption}
              {getOptionList(initialLevelOptions, levelOptionNames)}
            </Select>
          </EditableField>
        </>
      )}
      <EditableField name="Homework level" tooltip={homeworkLevelTooltip}>
        <Select {...register('levelOverride', false)}>
          {emptyOption}
          {getOptionList(
            forPreview ? levelOptionsWithoutAutomatic : levelOptions,
            forPreview ? levelOptionNamesWithoutAutomatic : levelOptionNamesWithAutomatic(),
          )}
        </Select>
      </EditableField>
    </>
  );
};
