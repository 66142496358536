import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport';
import { RpcInterceptor, RpcOptions, RpcTransport, UnaryCall } from '@protobuf-ts/runtime-rpc';
import { AssessmentServiceClient } from '@sparx/api/apis/sparx/assessment/v1/assessment.client';
import {
  CurriculumSummariesClient,
  TopicSummariesClient,
} from '@sparx/api/apis/sparx/content/summaries/v1/curriculum.client';
import {
  StudentFeedbackServiceClient,
  TeacherFeedbackServiceClient,
} from '@sparx/api/apis/sparx/interaction/feedback/v1/feedback.client';
import { WondeSyncClient } from '@sparx/api/apis/sparx/misintegration/wondesync/v1/wondesync.client';
import {
  SchemeOfLearningTemplatesClient,
  SchemesOfLearningClient,
} from '@sparx/api/apis/sparx/planning/v1/sol.client';
import { CalendarAPIClient } from '@sparx/api/apis/sparx/school/calendar/v3/calendar.client';
import { SchoolCalendarServiceClient } from '@sparx/api/apis/sparx/school/calendar/v4/calendar.client';
import { SchoolStaffServiceClient } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff.client';
import { SchoolActionsServiceClient } from '@sparx/api/apis/sparx/school/v2/schoolactions.client';
import { SchoolGroupsServiceClient } from '@sparx/api/apis/sparx/school/v2/schoolgroups.client';
import { SchoolsServiceClient } from '@sparx/api/apis/sparx/school/v2/schools.client';
import { ContentClient } from '@sparx/api/apis/sparx/science/content/v1/content.client';
import { LessonsClient } from '@sparx/api/apis/sparx/science/lessons/v1/lessons.client';
import { LessonNotesClient } from '@sparx/api/apis/sparx/science/lessons/v1/notes.client';
import { ActivitiesClient } from '@sparx/api/apis/sparx/science/packages/v1/activity.client';
import { AssessmentClient as SciAssessmentClient } from '@sparx/api/apis/sparx/science/packages/v1/assessment.client';
import { IndependentLearningClient } from '@sparx/api/apis/sparx/science/packages/v1/independentlearning.client';
import { InsightsClient } from '@sparx/api/apis/sparx/science/packages/v1/insights.client';
import { PackageServiceClient } from '@sparx/api/apis/sparx/science/packages/v1/package.client';
import { PlannerClient } from '@sparx/api/apis/sparx/science/packages/v1/planner.client';
import { DashboardDataClient } from '@sparx/api/apis/sparx/science/reports/v1/dashboard.client';
import { ReportsClient } from '@sparx/api/apis/sparx/science/reports/v1/reportgen.client';
import { SchoolsClient } from '@sparx/api/apis/sparx/science/schools/v1/school.client';
import { SessionsClient } from '@sparx/api/apis/sparx/science/sessions/v1/session.client';
import { GroupsAPIClient } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi.client';
import { SchoolStatusServiceClient } from '@sparx/api/apis/sparx/teacherportal/schoolstatus/v1/schoolstatus.client';
import { StudentAPIClient } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi.client';
import { TrainingProgressServiceClient } from '@sparx/api/apis/sparx/training/progress/v1/trainingprogress.client';
import { AccessTokenProvider, extensionInterceptor } from '@sparx/grpcweb';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { isAnonymousMode } from 'utils/anonymous';

export const authApiURL = import.meta.env.VITE_AUTH_API_URL || window.settings?.AUTH_API_URL;
export const scienceApiURL =
  import.meta.env.VITE_SCIENCE_API_URL || window.settings?.SCIENCE_API_URL;

const tpstudentapiURL = window.settings?.TP_STUDENT_API_URL || '/tpstudentapi';
const tpgroupsapiURL = window.settings?.TP_GROUPS_API_URL || '/groupsapi';
const contentsummaryAPI = window.settings?.CONTENT_API_URL || '/contentapi';
const schoolsapiURL = window.settings?.SCHOOLS_API_URL || '/schoolsapi';
const calendarapiURL = window.settings?.CALENDAR_API_URL || '/calendarapi';
const planningapiURL = window.settings?.PLANNING_API_URL || '/planningapi';
const schoolstaffapiURL = window.settings?.SCHOOL_STAFF_API_URL || '/schoolstaff';
const schoolstatusapiURL = window.settings?.SCHOOL_STATUS_API_URL || '/schoolstatus';
const trainingprogressURL = window.settings?.TRAINING_PROGRESS_API_URL || '/trainingprogress';
const clientAnalyticsURL = window.settings?.CLIENT_ANALYTICS_URL || '';
const reportsURL = scienceApiURL || '';
const feedbackAPIURL = window.settings?.FEEDBACK_API_URL || '/feedback';
const wondesyncURL = window.settings?.WONDESYNC_URL || '/wondesyncapi';
const cmsAPIURL = window.settings?.CMS_API_URL || '/cms';
const teacherAPIURL = window.settings?.TEACHERAPI_URL || '/tpapi';
const studentAPIURL = window.settings?.STUDENT_API_URL || '/studentapi';

export const selectSchoolURL =
  window.settings?.SCHOOL_SELECT_URL || 'https://selectschool.test2.test.sparxmaths.uk';

export const indexweaverApiURL = window.settings?.INDEXWEAVER_API_URL || 'https://sparxmaths.uk';

const getSchoolId = (school: string | (string | null)[] | null | undefined) => {
  // Little hack to get to test2 school more easily.
  if (window.settings?.ENV_NAME === 'test2' && school === 'test2')
    return '50405cea-64e9-45d1-b599-6ac5757fa6eb';

  return (school ? school.toString() : '').replace('schools/', '');
};

export const getSchoolIDFromUrl = () => {
  const { school } = queryString.parse(window.location.search);
  return getSchoolId(school);
};

export const redirectToLogin = async () => {
  const { school, selectschool, is, fg } = queryString.parse(window.location.search);
  const schoolID = getSchoolId(school);
  const shouldSelectSchool = Boolean(selectschool?.toString());
  const route = window.location.toString();
  const isQuery = is?.toString() ? { is: 'true' } : {};
  const fgQuery = fg?.toString() ? { fg: '1' } : {};
  if (!schoolID || shouldSelectSchool) {
    const query = queryString.stringify({
      app: import.meta.env.VITE_SPARX_PRODUCT_NAME || 'sparx_science',
      route,
      ...(shouldSelectSchool ? { forget: 1 } : {}),
    });
    window.location.replace(selectSchoolURL + '?' + query);
  } else {
    const query = queryString.stringify({ school: schoolID, route, ...isQuery, ...fgQuery });
    window.location.replace(authApiURL + '/oauth2/login?' + query);
  }
  return new Promise(() => {
    /*pass*/
  }); // never return
};

export const logout = async () =>
  fetch(authApiURL + '/oauth2/logout', {
    credentials: 'include',
  }).then(() => {
    redirectToLogin();
    return new Promise<Response>(() => {
      /*pass*/
    }); // never return
  });

let tokenFetcherStarted = false;

export const tokenFetcher = () =>
  fetch(authApiURL + '/token', {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      // 'X-CSRF-Token': getCsrfToken(), // TODO?
    },
  }).then(async resp => {
    if (!resp.ok && resp.status === 401) {
      // Unauthorised - redirect to login page
      if (tokenFetcherStarted) {
        await redirectToLogin();
      } else {
        throw new Error('Unauthorised');
      }
    }
    return resp;
  });

// Configure the access token provider and set it to load periodically
const accessTokenProvider: AccessTokenProvider = new AccessTokenProvider(tokenFetcher);
export const ensureTokenFetcherStarted = () => {
  if (!tokenFetcherStarted) {
    tokenFetcherStarted = true;
    accessTokenProvider.start().then(() => console.log('Access Token Provider initialised'));
  }
};

export const requestAccessToken = () => accessTokenProvider.requestAccessToken();

// This is copied from the @sparx/grpc package but removes the streaming interceptor
const authInterceptor = (accessTokenProvider: AccessTokenProvider): RpcInterceptor => ({
  // Based on approach here: https://github.com/timostamm/protobuf-ts/issues/31#issuecomment-733025632
  interceptUnary(next, method, input, options): UnaryCall {
    const callPromise = new Promise<UnaryCall>(resolve => {
      accessTokenProvider.requestAccessToken().then(accessToken => {
        if (!options.meta) options.meta = {};
        options.meta['Authorization'] = accessToken;
        resolve(next(method, input, options));
      });
    });

    return new UnaryCall(
      method,
      options.meta ?? {},
      input,
      callPromise.then(c => c.headers),
      callPromise.then(c => c.response),
      callPromise.then(c => c.status),
      callPromise.then(c => c.trailers),
    );
  },
});

interface TransportOptions {
  keepalive?: boolean;
  noAuthInterceptor?: boolean;
}

export const getTransport = (url: string, options?: TransportOptions): RpcTransport =>
  new GrpcWebFetchTransport({
    baseUrl: url,
    format: 'binary',
    fetchInit: {
      credentials: 'include',
      keepalive: options?.keepalive || false, // Try turning off (TODO: reconsider whether this had an impact)
    },
    interceptors: [
      ...(!options?.noAuthInterceptor ? [authInterceptor(accessTokenProvider)] : []),
      extensionInterceptor,
    ],
  });

/**
 * Helper function to add auth tokens onto requests.
 */
export const tokenMetadata = async (): Promise<RpcOptions> => {
  const token = await accessTokenProvider.requestAccessToken();
  return { meta: { Authorization: token } };
};

export const packagesClient = new PackageServiceClient(getTransport(scienceApiURL));
export const activitiesClient = new ActivitiesClient(getTransport(scienceApiURL));
export const sessionsClient = new SessionsClient(getTransport(scienceApiURL));
export const plannerClient = new PlannerClient(getTransport(scienceApiURL));
export const insightsClient = new InsightsClient(getTransport(scienceApiURL));
export const scienceSchoolsClient = new SchoolsClient(getTransport(scienceApiURL));
export const lessonsClient = new LessonsClient(getTransport(scienceApiURL));
export const lessonsClientWithKeepalive = new LessonsClient(
  getTransport(scienceApiURL, { keepalive: true }),
);
export const lessonsNotesClient = new LessonNotesClient(getTransport(scienceApiURL));
export const scienceContentClient = new ContentClient(getTransport(scienceApiURL));
export const independentLearningClient = new IndependentLearningClient(getTransport(scienceApiURL));
export const scienceAssessmentClient = new SciAssessmentClient(getTransport(scienceApiURL));

export const reportsClient = new ReportsClient(getTransport(reportsURL));
export const dashboardDataClient = new DashboardDataClient(getTransport(reportsURL));

export const studentsClient = new StudentAPIClient(getTransport(tpstudentapiURL));
export const groupsClient = new GroupsAPIClient(getTransport(tpgroupsapiURL));
export const schoolStatusClient = new SchoolStatusServiceClient(getTransport(schoolstatusapiURL));
export const curriculumSummariesClient = new CurriculumSummariesClient(
  getTransport(contentsummaryAPI),
);
export const topicSummariesClient = new TopicSummariesClient(getTransport(contentsummaryAPI));
export const schoolsClient = new SchoolsServiceClient(getTransport(schoolsapiURL));
export const schoolGroupsClient = new SchoolGroupsServiceClient(getTransport(schoolsapiURL));
export const calendarClient = new CalendarAPIClient(getTransport(calendarapiURL));
export const solClient = new SchemesOfLearningClient(getTransport(planningapiURL));
export const templateSolClient = new SchemeOfLearningTemplatesClient(getTransport(planningapiURL));
export const trainingProgressClient = new TrainingProgressServiceClient(
  getTransport(trainingprogressURL),
);
export const studentFeedbackClient = new StudentFeedbackServiceClient(getTransport(feedbackAPIURL));
export const teacherFeedbackClient = new TeacherFeedbackServiceClient(getTransport(feedbackAPIURL));
export const wondeSyncClient = new WondeSyncClient(getTransport(wondesyncURL));
export const schoolCalendarClient = new SchoolCalendarServiceClient(getTransport(teacherAPIURL));
export const schoolActionsClient = new SchoolActionsServiceClient(getTransport(schoolsapiURL));
export const assessmentClient = new AssessmentServiceClient(getTransport(studentAPIURL));

export const getClientAnalyticsURL = () => clientAnalyticsURL;
export const getCMSURL = () => cmsAPIURL;

let anonStaffClientInstance: SchoolStaffServiceClient;
const staffClientInstance = new SchoolStaffServiceClient(getTransport(schoolstaffapiURL));

export const getStaffClient = async () => {
  if (isAnonymousMode()) {
    if (!anonStaffClientInstance) {
      const { AnonymousSchoolStaffClient } = await import('api/fakenames');
      anonStaffClientInstance = new AnonymousSchoolStaffClient(getTransport(schoolstaffapiURL));
    }
    return anonStaffClientInstance;
  }
  return staffClientInstance;
};

export const useSchoolStaffClient = ({ suspense }: { suspense: boolean }) =>
  useQuery({
    queryKey: ['api', 'clients', 'schoolStaffClient'],
    queryFn: getStaffClient,
    cacheTime: Infinity,
    staleTime: Infinity,
    suspense,
  });
