import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';

export type Type = 'new' | 'removed' | 'current';
export type SubType = 'removedByMis';

export type SchoolGroupsRow = {
  class: string;
  yearGroupID: string;
  groupID?: string;
  classIdentifier: string;
  subject?: string;
  wondeStudentsCount?: number;
  sparxStudentsCount?: number;
  removeButtonText?: string;
  removeCallback?: () => void;
  type: Type;
  subType?: SubType;
  conflict?: 'resolved' | 'unresolved';
  onResolveConflicts?: () => void;
  currentWondeID?: string;

  // Sparx Staff features:

  // A Sparx class that has been manually matched to the Wonde class being shown, if using this Sparx Staff feature
  matchedSparxClass?: Group;

  // The expired class with the same Wonde ID as the class being shown if there is one
  matchingExpiredClass?: Group;

  // Whether the row is for a class that doesn't have a unique name in either classes being added or existing classes
  hasNonUniqueName?: boolean;
};

type CountRow = {
  count: number;
  unresolvedConflictsCount: number;
  text: string;
  onResolveConflicts?: () => void;
};

export const isCountRow = (row: SchoolGroupsTableData): row is CountRow =>
  (row as CountRow).count !== undefined;

export type SchoolGroupsTableData = SchoolGroupsRow | CountRow;
