import {
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  Link as ChakraLink,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListPackageSummariesResponse } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Assignment } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { useCancelHomework } from 'api/planner';
import { useGroup } from 'api/school';
import { useClientEvent } from 'components/ClientEventProvider';
import { StudentName } from 'components/Names';
import { Warning } from 'components/warning';
import { useMemo, useState } from 'react';
import { articleCancellingHomework } from 'utils/knowledgeBaseArticles';

interface State {
  tab: 'all' | 'some';
  idsToCancel: string[];
  reason?: cancelReasonKey;
  otherReason: string;
}

interface StudentStatus extends Student {
  cancelled: boolean;
}

const defaultState: State = {
  tab: 'all',
  idsToCancel: [],
  reason: undefined,
  otherReason: '',
};

const cancelReasons = {
  topics: 'Incorrect topics',
  length: 'Wrong length',
  toohard: 'Homework too hard',
  toolong: 'Homework too long',
  accidentlyset: 'Homework set by accident',
  other: 'Other',
};
type cancelReasonKey = keyof typeof cancelReasons;

export const AssignmentCancelDialog = ({
  assignment,
  summaries,
  isDisabled,
  studentsMap,
}: {
  assignment: Assignment;
  isDisabled?: boolean;
  summaries?: ListPackageSummariesResponse;
  studentsMap: Record<string, Student | undefined>;
}) => {
  const { sendEvent } = useClientEvent();
  const [state, setState] = useState<State>(defaultState);
  const cancelHomework = useCancelHomework(assignment.name);

  const { isOpen, onClose, onOpen } = useDisclosure({
    onClose: () => {
      // reset the state
      setState(defaultState);
      cancelHomework.reset();
    },
  });

  const groupID = assignment.groups.length > 0 ? assignment.groups[0].name.split('/')[3] : '';

  const { data: group } = useGroup(groupID, { suspense: true });

  const studentList = useMemo(() => {
    const statusMap = (summaries?.packageSummaries || []).reduce((acc, summary) => {
      const studentID = summary.studentName.split('/')[1];
      const student = studentsMap[studentID];
      if (!student) return acc;

      const status = acc.get(studentID);
      if (status) {
        // If they already exist (multiple packages), prefer not cancelled if there are a mix of states.
        if (!summary.cancelledTime) {
          status.cancelled = false;
        }
      } else {
        acc.set(studentID, {
          ...student,
          cancelled: !!summary.cancelledTime,
        });
      }
      return acc;
    }, new Map<string, StudentStatus>());

    return Array.from(statusMap.values()).sort((a, b) => {
      const aName = `${a.familyName} ${a.givenName}`;
      const bName = `${b.familyName} ${b.givenName}`;
      return aName.localeCompare(bName);
    });
  }, [studentsMap, summaries?.packageSummaries]);

  const checkStudent = (studentID: string, checked: boolean) => {
    setState(v => {
      const newIds = v.idsToCancel.filter(id => id !== studentID);

      if (checked) {
        newIds.push(studentID);
      }

      return {
        ...v,
        idsToCancel: newIds,
      };
    });
  };

  const canSubmit = () => {
    if (!state.reason) return false;
    if (state.reason === 'other' && !state.otherReason) return false;
    if (state.tab === 'some' && state.idsToCancel.length === 0) return false;

    return true;
  };

  const onCancel = async () => {
    if (!canSubmit()) return;

    const userIds = state.tab === 'all' ? undefined : state.idsToCancel;

    // Do the cancel
    cancelHomework.mutate({ userIds }, { onSuccess: onClose });

    // Send an event, including the cancel reason
    sendEvent(
      { action: 'cancel_homework', category: 'planner' },
      {
        assignmentName: assignment.name,
        groupID: groupID,
        reasonKey: state.reason || 'unknown',
        reason: state.reason ? cancelReasons[state.reason] : 'unknown',
        ...(state.reason === 'other' && { customReason: state.otherReason }),
        ...(!!userIds && { userIDs: userIds.join(',') }),
      },
    );
  };

  return (
    <>
      <Button
        isDisabled={isDisabled || !summaries}
        leftIcon={<FontAwesomeIcon icon={faBan} fixedWidth />}
        flex="0 0 auto"
        colorScheme="red"
        variant="outline"
        backgroundColor="white"
        onClick={onOpen}
        border={'unset'}
      >
        Cancel this homework...
      </Button>
      <Modal
        isOpen={isOpen && !!summaries}
        onClose={!cancelHomework.isLoading ? onClose : () => undefined}
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Cancel this homework</ModalHeader>
          <ModalBody>
            {cancelHomework.isError ? (
              <Warning status="error">
                Sorry we weren&apos;t able to complete your request. Please refresh and try again.
              </Warning>
            ) : (
              <>
                <Text>
                  You can cancel this homework if you set it by mistake or a particular student is
                  off sick.
                </Text>
                <Text>
                  It will be shown as cancelled to the student(s) and they will no longer be able to
                  complete it.
                </Text>
                <ChakraLink
                  textDecoration="underline"
                  href={articleCancellingHomework}
                  target="_blank"
                >
                  More info
                </ChakraLink>
                <Text mt={4} mb={2}>
                  Which students should this apply to?
                </Text>
                <RadioGroup
                  value={state.tab}
                  onChange={tab => setState(v => ({ ...v, tab: tab as State['tab'] }))}
                >
                  <HStack spacing={4}>
                    <Radio value="all">All students in {group?.displayName}</Radio>
                    <Radio value="some">Select individual students...</Radio>
                  </HStack>
                </RadioGroup>

                {/* Student selection */}
                {state.tab === 'some' && (
                  <Box mt={4} backgroundColor="gray.50" position="relative">
                    <VStack
                      gap={0}
                      alignItems="flex-start"
                      overflow="auto"
                      maxHeight="max(30vh, 300px)"
                    >
                      {studentList.map((student, idx) => (
                        <Checkbox
                          disabled={student.cancelled}
                          isChecked={
                            student.cancelled || state.idsToCancel.includes(student.studentId)
                          }
                          onChange={e => {
                            checkStudent(student.studentId, e.target.checked);
                          }}
                          key={student.studentId}
                          px={4}
                          py={2}
                          width="100%"
                          _hover={{ backgroundColor: 'gray.100' }}
                          borderColor="gray.200"
                          borderWidth={1}
                          borderTopWidth={idx === 0 ? 1 : 0}
                        >
                          <StudentName student={student} />
                          {student.cancelled && ' - already cancelled'}
                        </Checkbox>
                      ))}
                    </VStack>
                  </Box>
                )}

                <VStack mt={6} alignItems="flex-start">
                  <Text>Why do you need to cancel this homework?</Text>
                  <Select
                    placeholder="-- Select an option --"
                    value={state.reason}
                    onChange={e =>
                      setState(v => ({ ...v, reason: e.target.value as cancelReasonKey }))
                    }
                  >
                    {(Object.keys(cancelReasons) as Array<cancelReasonKey>).map(key => (
                      <option key={key} value={key}>
                        {cancelReasons[key]}
                      </option>
                    ))}
                  </Select>
                  {state.reason === 'other' && (
                    <Input
                      placeholder="Please enter your reason for cancelling this homework"
                      value={state.otherReason}
                      onChange={e => setState(v => ({ ...v, otherReason: e.target.value }))}
                    />
                  )}
                </VStack>
              </>
            )}
          </ModalBody>
          <ModalFooter flexDirection="column" alignItems="flex-end">
            {!cancelHomework.isError && (
              <Warning status="warning">
                <Text>
                  This action cannot be undone, are you sure you want to cancel this homework?
                </Text>
              </Warning>
            )}
            <Box mt={2} whiteSpace="nowrap">
              <Button
                colorScheme="buttonTeal"
                onClick={onClose}
                variant="outline"
                mr={4}
                isDisabled={cancelHomework.isLoading}
              >
                Close
              </Button>
              {!cancelHomework.isError && (
                <Button
                  colorScheme="red"
                  onClick={onCancel}
                  isLoading={cancelHomework.isLoading}
                  variant="solid"
                  isDisabled={!canSubmit()}
                >
                  Cancel this homework
                </Button>
              )}
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
