import { Box, Flex, HStack, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import { faBan, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Assignment } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useTopicLookup } from 'api/content';
import { Week } from 'api/school';
import { LargeLoading } from 'components/loading/LargeLoading';
import { format } from 'date-fns';
import React, { PropsWithChildren, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { usePlannerContext } from '../Context';

interface WeekWrapperProps {
  week: Partial<Week>;
  selected?: boolean;
  link?: string;
  highlightDates?: Set<string>;
  hideNow?: boolean;
}

export const WeekWrapper = React.memo(
  ({
    children,
    week,
    selected,
    link,
    highlightDates,
    hideNow,
  }: PropsWithChildren<WeekWrapperProps>) => {
    const current = week.current && !hideNow;
    const weekDetail = (
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        backgroundColor={current ? 'blue.800' : selected ? 'teal.50' : 'gray.100'}
        color={current ? 'white' : 'gray.800'}
        width="55px"
        borderRight="1px"
        borderRightColor={current ? 'blue.800' : 'gray.300'}
        flexShrink={0}
        borderLeftWidth="4px"
        paddingRight="4px"
        borderLeftColor={selected ? 'teal.200' : current ? 'blue.800' : 'gray.100'}
      >
        <Text fontSize="sm">Week</Text>
        <Text
          my={2}
          fontSize="md"
          backgroundColor={selected ? 'whiteAlpha.300' : undefined}
          px={2}
          borderRadius="lg"
          fontWeight="bold"
        >
          {week.index}
        </Text>
        {week.startDate && week.lastDate && (
          <Text fontSize="xs">
            {format(week.startDate, 'dd MMM')}
            <br />
            to
            <br />
            {format(week.lastDate, 'dd MMM')}
          </Text>
        )}
      </Flex>
    );

    const weekdays = useMemo(
      () =>
        week.dates?.map((date, index) => (
          <Box
            width="100%"
            px={1}
            py={1}
            textAlign="center"
            key={index}
            fontSize="sm"
            backgroundColor={
              highlightDates?.has(format(date.date, 'yyyy-MM-dd'))
                ? 'teal.100'
                : date.outsideAY
                  ? 'gray.100'
                  : date.holiday
                    ? 'gray.200'
                    : 'white'
            }
            color={date.outsideAY ? 'gray.300' : date.holiday ? 'gray.600' : 'gray.800'}
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {format(date.date, 'EEEEEE')}
          </Box>
        )) || [],
      [highlightDates, week],
    );

    return (
      <LinkBox
        as={Flex}
        borderBottom="1px"
        borderBottomColor="gray.300"
        cursor={link ? 'pointer' : 'auto'}
        id={`week-${week.index}`}
        opacity={!hideNow && !selected && week.past ? 0.75 : 1}
      >
        {current && (
          <Box
            backgroundColor="blue.800"
            color="white"
            px={3}
            pb={1}
            pt={2}
            fontSize="xs"
            position="absolute"
            top={0}
            right={0}
            fontWeight="bold"
            borderBottomLeftRadius="md"
          >
            Current week
          </Box>
        )}
        {link && <LinkOverlay as={Link} to={link} />}
        {weekDetail}
        <Flex borderTop={current ? '4px' : 0} borderTopColor="blue.800" flex={1}>
          <Flex direction="column" borderRight="1px" borderRightColor="gray.300">
            {weekdays}
          </Flex>
          {children}
        </Flex>
      </LinkBox>
    );
  },
);
WeekWrapper.displayName = 'WeekWrapper';

interface WeekButtonProps {
  week: Week;
  assignments: Assignment[];
  link?: string;
  selected?: boolean;
}

export const WeekButton = ({ week, link, assignments, selected }: WeekButtonProps) => {
  const assignmentDates = new Set(
    assignments
      .filter(a => a.startTimestamp)
      .map(a => format(Timestamp.toDate(a.startTimestamp!), 'yyyy-MM-dd')),
  );

  const cancelled = assignments.length > 0 && assignments.every(a => !!a.cancelledTime);
  const noAssignments = assignments.length === 0 && !week.past;

  const contents = (
    <Box
      p={4}
      backgroundColor={noAssignments ? 'red.100' : selected ? 'gray.100' : 'white'}
      borderLeft={noAssignments ? '4px' : 0}
      borderLeftColor={noAssignments ? 'red.300' : undefined}
      fontSize="sm"
      flex={1}
    >
      <Text fontWeight="bold" color="blue.800" mb={1}>
        Week {week.index}
        {cancelled && (
          <Text as="span" ml={2} color="gray.900" fontWeight="normal">
            <Box as="span" color="red.600">
              <FontAwesomeIcon icon={faBan} size="sm" />
            </Box>{' '}
            Cancelled
          </Text>
        )}
      </Text>
      {assignments.length === 0 ? (
        week.past ? (
          <Text color="gray.500">No homework was set this week</Text>
        ) : (
          <Text fontWeight="bold" color="red.600">
            Homework is off for this week
          </Text>
        )
      ) : (
        <>
          {assignments.map((a, i) => (
            <React.Fragment key={a.name}>
              {assignments.length > 1 && (
                <Text fontSize="xs" mt={2} color="gray.600">
                  Homework {i + 1}:
                </Text>
              )}
              <HomeworkContentsList assignment={a} week={week} />
            </React.Fragment>
          ))}
        </>
      )}
    </Box>
  );

  return (
    <WeekWrapper week={week} link={link} selected={selected} highlightDates={assignmentDates}>
      {contents}
    </WeekWrapper>
  );
};

export const HomeworkContentsList = ({
  assignment,
  week,
}: {
  assignment: Assignment;
  week: Week;
}) => {
  const { data: topicLookup } = useTopicLookup({ suspense: true });

  const {
    consolidation: { data: consolidationData, isLoading },
    nextHandoutWeek,
  } = usePlannerContext();
  const noTopics =
    assignment.spec?.contents.oneofKind === 'generatedAssignment' &&
    assignment.spec.contents.generatedAssignment.topics.length === 0 &&
    Object.values(consolidationData?.serverState || {}).every(s => !s.included);

  return (
    <>
      {assignment.spec?.contents.oneofKind === 'generatedAssignment' ? (
        week.index === nextHandoutWeek?.index && (noTopics || isLoading) ? (
          isLoading ? (
            <LargeLoading size={100} />
          ) : (
            // We don't use <Warning /> here as that ends up capturing the click event (I don't know why).
            <Flex bgColor="orange.100" px={3} py={2} borderRadius="md">
              <Box color="orange.500" mr={2}>
                <FontAwesomeIcon icon={faCircleExclamation} fixedWidth={true} size="xl" />
              </Box>
              <Text fontWeight="bold" fontSize="xs">
                Homework will not be set.
              </Text>
            </Flex>
          )
        ) : (
          <>
            {assignment.spec.contents.generatedAssignment.topics.map((topic, i) => (
              <HomeworkItem key={i} deleted={topicLookup?.[topic.name]?.topic?.deleted}>
                {topicLookup?.[topic.name]?.topic?.displayName || <i>Unknown topic</i>}
              </HomeworkItem>
            ))}
            <HomeworkItem secondary={true}>Consolidation</HomeworkItem>
          </>
        )
      ) : (
        <HomeworkItem>Custom content</HomeworkItem>
      )}
    </>
  );
};

export const HomeworkItem = ({
  children,
  secondary,
}: {
  children: React.ReactNode;
  secondary?: boolean;
  deleted?: boolean;
}) => (
  <HStack spacing={2} alignItems="flex-start">
    <Text color={secondary ? 'gray.300' : 'gray.400'}>●</Text>
    <Text color={secondary ? 'gray.500' : 'gray.600'} fontStyle={secondary ? 'italic' : undefined}>
      {children}
    </Text>
  </HStack>
);
