import { validate as validateUUID } from 'uuid';
import { SkillBlueprint } from 'views/lessons/createactivity/activitytypes';

export const textToSkillBlueprintArray = (text: string, secondChance?: boolean) => {
  const skills: SkillBlueprint[] = [];
  for (const line of text.split('\n')) {
    const parts = line.trim().split('/');
    let skillId = parts[0].trim();
    let questionId = '';
    if (!skillId) {
      continue;
    }

    // If the skill id has a question id in it also use that
    const pipeSplit = skillId.split('|');
    if (pipeSplit.length === 2) {
      skillId = pipeSplit[0].trim();
      questionId = pipeSplit[1].trim();
    }

    if (!validateUUID(skillId.replace('custom:', ''))) {
      alert('At least one id is not a uuid: ' + skillId);
      return [];
    }
    if (questionId && !validateUUID(questionId)) {
      alert('At least one question id is not a uuid: ' + questionId);
      return [];
    }

    const annotations: Record<string, string> = {};
    if (parts.length > 1) {
      switch (parts[1].trim()) {
        case 'cam:always':
          annotations['camera'] = 'always';
          break;
        case 'cam:wrong':
          annotations['camera'] = 'wrong';
          break;
        case 'mark':
          annotations['pend_correct'] = 'true';
          break;
        default:
          alert('Unknown annotation: ' + parts[1]);
          break;
      }
    }
    if (secondChance) {
      annotations['second_chance'] = 'has';
    }
    skills.push({ skillId, questionId, annotations });
  }
  return skills;
};

export const countSkillsInText = (text: string) => {
  let num = 0;
  for (const line of text.split('\n')) {
    const parts = line.trim().split('/');
    if (!parts[0].trim()) {
      continue;
    }
    num++;
  }
  return num;
};
