import {
  Alert,
  AlertIcon,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import { useIsInLesson, useJoinLesson } from 'api/lessons';
import React, { useState } from 'react';

export const JoinLessonModal = () => {
  const [joinCode, setJoinCode] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => setJoinCode(''),
  });

  const { mutateAsync, isLoading, error, reset } = useJoinLesson();
  const valid = joinCode.trim().length >= 4;
  const submit = () => {
    if (valid) {
      mutateAsync(joinCode.trim()).then(onClose);
    }
  };

  const inLesson = useIsInLesson();

  return (
    <>
      {!inLesson && (
        <Button size={['sm', 'md']} colorScheme="buttonTeal" onClick={onOpen}>
          Join lesson
        </Button>
      )}
      <Modal isOpen={isOpen} size="xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={e => e.preventDefault()}>
          <ModalHeader pb={1}>Join lesson</ModalHeader>
          <ModalBody>
            <Text mb={3}>Enter the lesson code:</Text>
            {Boolean(error) && (
              <Alert status="error" mb={3} borderRadius="md">
                <AlertIcon />
                <Text>
                  <Text fontWeight="bold" color="red.700" display="inline-block" mr={2}>
                    Error:
                  </Text>
                  {translateErrorMesage(error)}
                </Text>
              </Alert>
            )}
            <Input
              value={joinCode}
              onChange={e => {
                if (error) reset();
                setJoinCode(e.target.value);
              }}
              fontSize="3xl"
              height={20}
              inputMode="decimal"
              textAlign="center"
              placeholder="0000"
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} mr={2} variant="outline" isDisabled={isLoading}>
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={submit}
              colorScheme="buttonTeal"
              isDisabled={!valid}
              isLoading={isLoading}
            >
              Join lesson
            </Button>
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};

const translateErrorMesage = (err: unknown) => {
  if (err instanceof RpcError) {
    if (err.code === 'NOT_FOUND') return 'Lesson not found';
    return err.message;
  }
  return 'Unknown error';
};
