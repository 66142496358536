import { Box, Grid, SystemStyleObject, Text } from '@chakra-ui/react';
import { faFileLines, faLightbulb, faStar } from '@fortawesome/free-regular-svg-icons';
import {
  faFileLines as faFileLinesSolid,
  faLightbulb as faLightbulbSolid,
  faStar as faStarSolid,
} from '@fortawesome/free-solid-svg-icons';
import { useAssessmentSummary } from 'api/assessment';
import { SidebarButton } from 'app/Sidebar';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import { PropsWithChildren } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface NavProps {
  assessmentsEnabled: boolean;
}

export const StudentNavigation = ({ children }: PropsWithChildren) => {
  const { data: assessmentSummary } = useAssessmentSummary({ suspense: false });
  const assessmentsEnabled = !!assessmentSummary && assessmentSummary?.fixUpTasks.length > 0;

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      position="absolute"
      flexDirection={{ base: 'column', md: 'row' }}
    >
      <Box display={{ base: 'none', md: 'block' }} height="100%">
        <SideBar assessmentsEnabled={assessmentsEnabled} />
      </Box>
      <Box display={{ base: 'block', md: 'none' }}>
        <TopBar assessmentsEnabled={assessmentsEnabled} />
      </Box>
      <Box flex={1} height="100%" position="relative" userSelect="text">
        <SuspenseRoute>{children}</SuspenseRoute>
      </Box>
    </Box>
  );
};

const TopBar = ({ assessmentsEnabled }: NavProps) => {
  return (
    <Grid
      width="100%"
      templateColumns={`repeat(${assessmentsEnabled ? '3' : '2'},1fr)`}
      backgroundColor="gray.200"
    >
      <TopBarButton title="My Homework" to="/packages" />
      <TopBarButton title="Independent Learning" to="/independentlearning" />
      {assessmentsEnabled && <TopBarButton title="Assessment Fix up" to="/assessments" />}
    </Grid>
  );
};

interface TopbarButtonProps {
  title: string;
  to: string;
}

const TopBarButton = ({ title, to }: TopbarButtonProps) => {
  const location = useLocation();
  const active = location.pathname === to;

  const smallTriangle: SystemStyleObject = {
    content: "''",
    bottom: -2,
    height: 2,
    position: 'absolute',
    display: 'block',
    width: '50%',
    zIndex: 1,
    borderTopWidth: '6px',
    borderTopStyle: 'solid',
    borderColor: active ? 'blue.900' : 'blue.700',
  };

  return (
    <Box
      pt={3}
      pb={1}
      px={1}
      backgroundColor={active ? 'blue.900' : 'blue.700'}
      as={Link}
      to={to}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      position="relative"
      _before={{
        ...smallTriangle,
        left: 0,
        borderRight: active ? '6px solid transparent' : undefined,
      }}
      _after={{
        ...smallTriangle,
        right: 0,
        borderLeft: active ? '6px solid transparent' : undefined,
      }}
    >
      <Text align="center" color="white" fontSize={['xs', 'sm']} fontWeight="bold">
        {title}
      </Text>
    </Box>
  );
};

const SideBar = ({ assessmentsEnabled }: NavProps) => {
  return (
    <Box backgroundColor="white" borderRightColor="gray.200" width={110} padding={2} height="100%">
      <SidebarButton title="Homework" icon={faStar} activeIcon={faStarSolid} to="/packages" />
      <SidebarButton
        title="Independent Learning"
        icon={faLightbulb}
        activeIcon={faLightbulbSolid}
        to="/independentlearning"
      />
      {assessmentsEnabled && (
        <SidebarButton
          title="Assessment Fix up"
          icon={faFileLines}
          activeIcon={faFileLinesSolid}
          to="/assessments"
        />
      )}
    </Box>
  );
};
