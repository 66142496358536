import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { useMisSyncContext } from '@sparx/mis-sync-import/src/Context';
import { SupportLink } from '@sparx/mis-sync-import/src/MisSyncImport/components/common';
import { getSystemOptions, joinList } from '@sparx/mis-sync-import/src/utils';
import { ErrorBox } from '@sparx/sparx-design/components';

/**
 * Message to display when there are errors that prevent the user from continuing with the sync
 * @param groupTypesWithErrors
 * @constructor
 */
export const BlockSyncMessage = ({
  groupTypesWithErrors,
}: {
  groupTypesWithErrors: Set<StudentGroupType>;
}) => {
  const { groupSubject } = useMisSyncContext();
  const { system } = getSystemOptions(groupSubject);

  const getSystemsWithErrors = (groupTypes: StudentGroupType[]): string =>
    joinList(groupTypes.map(s => getSystemOptions(s).system));

  // If there are errors in the current group type which block the sync, show a different message to the user
  if (groupTypesWithErrors.has(groupSubject)) {
    const copy = new Set(groupTypesWithErrors);
    copy.delete(groupSubject);
    // Make sure the current groupType comes first in the list
    const systems = [groupSubject, ...Array.from(copy)];
    const systemsWithErrors = getSystemsWithErrors(systems);
    const systemsWithErrorsString =
      systemsWithErrors.toLowerCase().indexOf('unknown') === -1
        ? `${systems.length === 2 ? 'both' : ''} ${systemsWithErrors}`
        : 'your MIS';

    return (
      <ErrorBox title="Sorry, there's been a problem">
        There are pending changes that affect {systemsWithErrorsString}. Please contact the{' '}
        <SupportLink linkText="School Success Team" /> to help with this issue.
      </ErrorBox>
    );
  }

  const systemsWithErrors = getSystemsWithErrors(Array.from(groupTypesWithErrors));
  const systemsWithErrorsString =
    systemsWithErrors.toLowerCase().indexOf('unknown') === -1
      ? `${getSystemsWithErrors(Array.from(groupTypesWithErrors))} ${
          groupTypesWithErrors.size === 1 ? 'site' : 'sites'
        }`
      : 'school MIS';
  return (
    <ErrorBox title="Sorry, there's been a problem">
      There are pending changes in your {systemsWithErrorsString} that cannot be resolved through{' '}
      {system} yet. Please contact the <SupportLink linkText="School Success Team" /> to help with
      this issue.
    </ErrorBox>
  );
};
