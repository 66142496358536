import { Button, HStack } from '@chakra-ui/react';
import { faBoxArchive } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListLessonsRequest } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { lessonsClient } from 'api';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { LessonListPage } from './components/LessonListPage';
import { CreateLessonModal } from './CreateLessonModal';

export const LessonsListView = () => {
  const [searchParams] = useSearchParams();
  const groupSearchParam = searchParams.get('group');
  const groupQuery = groupSearchParam ? `?group=${groupSearchParam}` : '';

  return (
    <LessonListPage
      title="Lessons"
      path="/teacher/lessons"
      query={args => lessonsClient.listLessons(ListLessonsRequest.create(args)).response}
      titleComponent={
        <HStack spacing={2}>
          <Button
            as={Link}
            to={`/teacher/lessons/archive${groupQuery}`}
            leftIcon={<FontAwesomeIcon icon={faBoxArchive} />}
          >
            Archive
          </Button>
          <CreateLessonModal groupID={groupSearchParam || undefined} />
        </HStack>
      }
    />
  );
};
