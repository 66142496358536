import {
  Box,
  CircularProgress,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faListAlt } from '@fortawesome/free-regular-svg-icons';
import {
  faCalendarAlt,
  faFileAlt,
  faIdCard,
  faList,
  faRotateRight,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, ReactNode } from 'react';

import { IsRolloverProps, PageTab, TabDetails } from '..';
import { StatusIcon } from '../components/Tab';
import { useNewYearSetupProgress, useYearToReference } from '../hooks';
import dataAccess from '../icons/data_no_access.svg';
import rememberLevels from '../icons/remember_levels.svg';
import resetClasses from '../icons/reset_classes.svg';
import studentLogin from '../icons/student_login.svg';
import { useMISResetStatus } from './MISReset';

const title = 'Overview';

const Page = (_: IsRolloverProps) => {
  const { isCurrentYear } = useYearToReference();

  return !isCurrentYear ? <BeforeRollover /> : <AfterRollover />;
};

const BeforeRollover = () => {
  return (
    <>
      <Heading size="md">
        You can start to prepare your Sparx Science for the new academic year now
      </Heading>
      <Text mt={1}>Work through the tabs on this page to get ready for the new academic year.</Text>
      <Flex>
        <Box flex={1}>
          <Flex justifyContent="center" alignItems="flex-end" height={16}>
            <Box
              bgColor="buttonTeal.500"
              py={2}
              px={4}
              color="white"
              fontWeight="bold"
              mb={2}
              borderRadius="md"
              position="relative"
              _after={{
                content: '" "',
                position: 'absolute',
                right: 'calc(50% - 7px)',
                bottom: '-7px',
                borderTop: '7px solid var(--chakra-colors-buttonTeal-500)',
                borderRight: '7px solid transparent',
                borderLeft: '7px solid transparent',
                borderBottom: 'none',
              }}
            >
              You are here
            </Box>
          </Flex>
          <Box height="8px" bgColor="green.300" width="100%" position="relative">
            <Box
              position="absolute"
              left={0}
              top={0}
              borderColor="transparent"
              borderWidth="4px"
              borderRight="none"
              borderLeftColor="white"
            />
            <Box
              position="absolute"
              right={0}
              top={0}
              borderColor="white"
              borderWidth="4px"
              borderRight="none"
              borderLeftColor="transparent"
            />
          </Box>
        </Box>
        <Box flex={1}>
          <Box height={16} />
          <Box height={2} bgColor="teal.200" width="100%" position="relative" ml={0.5}>
            <Box
              position="absolute"
              left={'-4px'}
              top={0}
              borderColor="teal.200"
              borderWidth="4px"
              borderRight="none"
              borderLeftColor="transparent"
            />
            <Box
              position="absolute"
              right={0}
              top={0}
              borderColor="white"
              borderWidth="4px"
              borderRight="none"
              borderLeftColor="transparent"
            />
          </Box>
        </Box>
      </Flex>
      <Grid templateColumns="1fr 1fr" mt={2} gap={4}>
        <GridItem>
          <Heading size="sm" textAlign="center" mb={2}>
            Current academic year
          </Heading>
          <Text>You can already start to prepare for the new academic year by:</Text>
        </GridItem>
        <GridItem>
          <Heading size="sm" textAlign="center" mb={2}>
            New academic year
          </Heading>
          <Text>Once your MIS is ready you&apos;ll be able to:</Text>
          <Text fontSize="sm" fontStyle="italic">
            (This varies by school but is most commonly sometime in the first two weeks of school.)
          </Text>
        </GridItem>
        <GridItem>
          <ItemList
            items={[
              { icon: faIdCard, text: 'Make sure Staff & Key Contacts are up to date.' },
              { icon: faCalendarAlt, text: 'Add Holiday dates to Sparx systems.' },
              { icon: faList, text: 'Create Schemes of Learning ready to assign your classes.' },
            ]}
          />
        </GridItem>
        <GridItem>
          <ItemList
            items={[
              { icon: faRotateRight, text: 'Reset your Sparx Science site.' },
              { icon: faUsers, text: 'Import new Classes & Students.' },
              { icon: faFileAlt, text: 'Set homework for all students.' },
            ]}
          />
        </GridItem>
      </Grid>
    </>
  );
};

const ItemList = ({
  items,
}: PropsWithChildren<{
  items: { icon: IconDefinition; text: string }[];
}>) => (
  <VStack alignItems="flex-start" spacing={6}>
    {items.map(({ icon, text }, idx) => (
      <Flex key={idx} alignItems="center">
        <Box color="teal.600">
          <FontAwesomeIcon size="2x" icon={icon} fixedWidth />
        </Box>
        <Text lineHeight="110%" ml={2}>
          {text}
        </Text>
      </Flex>
    ))}
  </VStack>
);

const AfterRollover = () => {
  const { status: resetStatus } = useMISResetStatus({ suspense: true });
  const hasReset = resetStatus === 'complete';

  return (
    <>
      <Heading size="md">
        {hasReset
          ? 'You have reset your Sparx Science site'
          : 'You need to prepare to reset your Sparx Science site'}
      </Heading>
      <Text mt={3} mb={6}>
        Work through the tabs on this page to get ready{' '}
        {hasReset
          ? 'for the new academic year.'
          : "to reset. You'll need to do these things before you can set your first homework."}
      </Text>
      <Heading size="sm" mb={4}>
        What does resetting your Sparx Science site mean?
      </Heading>
      <Grid templateColumns={{ base: '1fr', lg: '1fr 1fr' }} gap={6}>
        <InfoGridItem iconSrc={resetClasses}>
          All of last year&apos;s classes {hasReset ? 'have been' : 'will be'} removed.
        </InfoGridItem>
        <InfoGridItem iconSrc={studentLogin}>
          Students won&apos;t be able to log in until you import them to their new classes.
        </InfoGridItem>
        <InfoGridItem iconSrc={dataAccess}>
          {hasReset ? 'You can' : "You'll"} no longer{hasReset ? '' : ' be able to'} access data
          from last academic year.
        </InfoGridItem>
        <InfoGridItem iconSrc={rememberLevels}>
          Sparx Science will remember difficulty levels and higher/separate settings.
        </InfoGridItem>
      </Grid>
    </>
  );
};

const InfoGridItem = ({ iconSrc, children }: PropsWithChildren<{ iconSrc: string }>) => (
  <GridItem
    display="flex"
    flexDirection="column"
    textAlign="center"
    justifyContent="center"
    alignItems="center"
    bgColor="gray.100"
    p={6}
    borderRadius="md"
  >
    <Flex width={100} height={100}>
      <img alt="" src={iconSrc} width={100} />
    </Flex>
    {children}
  </GridItem>
);

const useTabDetails = (_: IsRolloverProps): TabDetails => {
  const { total, complete, loading } = useNewYearSetupProgress();
  const { isCurrentYear } = useYearToReference();

  const isComplete = complete >= total && !loading && isCurrentYear;

  let statusText: ReactNode;

  if (!isCurrentYear) {
    statusText = 'Prepare to reset';
  } else if (!loading) {
    statusText = (
      <Text alignItems="center">
        {isComplete ? (
          <>
            <StatusIcon status="complete" /> All steps complete
          </>
        ) : (
          <>
            <CircularProgress
              min={0}
              max={total}
              value={complete}
              color="green.400"
              size={'18px'}
              thickness={'15px'}
              mr={1}
            />
            {complete}/{total} steps complete
          </>
        )}
      </Text>
    );
  }

  return {
    title: (
      <Text fontWeight="bold" fontSize="lg">
        {title}
      </Text>
    ),
    icon: faListAlt,
    statusText,
  };
};

const OverviewTab: PageTab = {
  path: 'overview',
  detailsHook: useTabDetails,
  page: Page,
};
export default OverviewTab;
