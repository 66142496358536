import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { FieldMask } from '@sparx/api/google/protobuf/field_mask';
import { useUpdateGroup } from 'api/school';
import { GroupWithSettings, useUpdateGroupSettings } from 'api/scienceschool';
import { ErrorBox } from 'components/errorbox';
import React, { useState } from 'react';
import {
  NoHolidaysWarning,
  PlannerSettingsChangePreview,
  useSettingsChangeAnalytics,
} from 'views/planner/components/PlannerSettingsChangePreview';

interface SaveGroupSettingsModalProps {
  isOpen: boolean;
  groups: GroupWithSettings[];
  solChanged: boolean;
  staffChanged?: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const SaveGroupSettingsModal = ({
  isOpen,
  groups,
  onClose,
  onSubmit,
  solChanged,
  staffChanged,
}: SaveGroupSettingsModalProps) => {
  const updateSciGroups = useUpdateGroupSettings({});
  const updateGroup = useUpdateGroup();
  const [loadingPreview, setLoadingPreview] = useState(true);

  const isLoading = updateSciGroups.isLoading || updateGroup.isLoading;
  const error = updateSciGroups.error || updateGroup.error;

  const submit = async () => {
    sendAnalytics(true);

    await updateSciGroups.mutateAsync(groups.map(g => ({ ...g.scienceSettings, name: g.name })));

    if (staffChanged) {
      // If there are staff changes, do an update for each group
      for (const group of groups) {
        await updateGroup.mutateAsync({
          fieldMask: FieldMask.create({ paths: ['staff'] }),
          group: Group.create({
            name: group.name,
            staff: group.staff,
          }),
        });
      }
    }

    onSubmit();
  };

  const doClose = () => {
    // Don't allow the modal to close while we are doing the update
    if (!isLoading) {
      sendAnalytics(false);
      onClose();
      updateSciGroups.reset();
      updateGroup.reset();
    }
  };

  const sendAnalytics = useSettingsChangeAnalytics(
    isLoading || loadingPreview,
    'class_manager',
    'change_group_settings_',
    groups,
  );

  return (
    <Modal isOpen={isOpen} size="xl" onClose={doClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb={1}>Update class settings</ModalHeader>
        <ModalBody>
          <ErrorBox error={error} />
          {solChanged && <NoHolidaysWarning />}
          Are you sure you want to update {groups.length === 1
            ? "this class's"
            : 'these classes'}{' '}
          settings?
          <PlannerSettingsChangePreview
            groups={groups}
            setLoading={b => setLoadingPreview(b)}
            updatingSow={solChanged}
          />
        </ModalBody>

        <ModalFooter>
          <Button onClick={doClose} mr={2} variant="outline" isDisabled={isLoading}>
            Continue editing
          </Button>
          <Button onClick={submit} colorScheme="buttonTeal" isLoading={isLoading || loadingPreview}>
            Update
          </Button>
        </ModalFooter>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};
