import {
  Assignment,
  CancelAssignmentRequest,
  ConsolidationTopicAction,
  ConsolidationTopicAction_Action,
  GetPackagePreviewRequest,
  ListAssignmentsRequest,
  UpdateGeneratedAssignmentRequest,
} from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { StudentSettings } from '@sparx/api/apis/sparx/science/schools/settings/v1/settings';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { FetchQueryOptions, useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { plannerClient } from 'api';
import { queryClient } from 'api/client';
import { Options } from 'api/school';
import { fetchInterimAwareYear } from 'api/schoolcalendar';
import { getSchoolID } from 'api/sessions';
import { isAfter } from 'date-fns';
import { useMemo } from 'react';
import { WeekWithAssignments } from 'utils/data/assignments';

import { TopicWithStrand, useTopicWithStrandsLookup } from './content';
import { packageSummariesKey } from './packages';

const assignmentQuery: FetchQueryOptions<Assignment[]> = {
  queryKey: ['assignments'],
  queryFn: async () => {
    const year = await fetchInterimAwareYear();
    if (!year) return [];
    return plannerClient
      .listAssignments(
        ListAssignmentsRequest.create({
          schoolName: `schools/${await getSchoolID()}`,
          query: {
            oneofKind: 'dates',
            dates: {
              startTimestamp: Timestamp.fromDate(year.start),
              endTimestamp: Timestamp.fromDate(year.end),
            },
          },
        }),
      )
      .response.then(r => r.assignments);
  },
};

export const useAssignments = (options: Options) =>
  useQuery({
    ...assignmentQuery,
    ...options,
  });

export const invalidateAssignments = () => queryClient.invalidateQueries(['assignments']);

export const useGroupAssignments = (groupID: string | undefined, options: Options) => {
  const { data: assignments } = useAssignments(options);
  return useMemo(
    () =>
      assignments?.filter(
        a => groupID && a.groups.find(g => g.name === groupID || g.name.endsWith(groupID)),
      ) || [],
    [groupID, assignments],
  );
};

export const useAssignmentsByGroupName = (options: Options) =>
  useQuery({
    ...assignmentQuery,
    ...options,
    select: data =>
      data?.reduce(
        (m, v) => {
          for (const g of v.groups) {
            m[g.name] = [...(m[g.name] || []), v];
          }
          return m;
        },
        {} as Record<string, Assignment[]>,
      ),
  });

export const useAssignment = (assignmentID: string, options: Options) =>
  useQuery({
    ...assignmentQuery,
    ...options,
    select: data => data.find(a => a.name.split('/').pop() === assignmentID),
  });

export const updateAssignmentInQuery = (assignment: Assignment) =>
  queryClient.setQueryData(['assignments'], (assignments: Assignment[] | undefined) => {
    if (!assignments) {
      queryClient.resetQueries(['assignments']);
    }
    return assignments?.filter(a => a.name !== assignment.name).concat(assignment);
  });

export const removeAssignmentInQuery = (assignmentName: string) =>
  queryClient.setQueryData(['assignments'], (assignments: Assignment[] | undefined) => {
    if (!assignments) {
      queryClient.resetQueries(['assignments']);
    }
    return assignments?.filter(a => a.name !== assignmentName);
  });

export interface AssignmentWithAction extends Assignment {
  action: 'create' | 'update' | 'delete';
  deleteExisting?: boolean;
}

export const useCreateOrUpdateAssignment = (options?: {
  onSuccess?: (assignment: Assignment, req: AssignmentWithAction) => void;
  noQueryUpdate?: boolean;
}) =>
  useMutation({
    mutationFn: async (assignment: AssignmentWithAction) => {
      const request = {
        assignment: {
          ...assignment,
          schoolName: `schools/${await getSchoolID()}`,
        },
      };
      switch (assignment.action) {
        case 'delete':
          return plannerClient
            .deleteAssignment({
              name: assignment.name,
              schoolName: `schools/${await getSchoolID()}`,
              deleteGenerated: assignment.deleteExisting || false,
            })
            .response.then(() => assignment);
        case 'update':
          return plannerClient.updateAssignment(request).response;
        case 'create':
          return plannerClient.createAssignment(request).response;
        default:
          throw new Error('Invalid action');
      }
    },
    onSuccess: (data, req) => {
      options?.onSuccess?.(data, req);
      if (!options?.noQueryUpdate) {
        if (req.action === 'delete') removeAssignmentInQuery(data.name);
        else updateAssignmentInQuery(data);
      }
    },
  });

type UpdateExistingAssignmentParams = Pick<Assignment, 'name' | 'endTimestamp'>;

export const useUpdateExistingAssignment = (options?: {
  onSuccess?: (assignment: Assignment) => void;
}) =>
  useMutation({
    mutationFn: async (assignment: UpdateExistingAssignmentParams) =>
      plannerClient.updateGeneratedAssignment(
        UpdateGeneratedAssignmentRequest.create({
          assignment: {
            schoolName: `schools/${await getSchoolID()}`,
            ...assignment,
          },
          updateMask: {
            paths: ['end_timestamp'],
          },
        }),
      ).response,
    onSuccess: data => {
      updateAssignmentInQuery(data);
      options?.onSuccess?.(data);
    },
  });

export const useDeleteAssignment = () =>
  useMutation({
    mutationFn: async (args: { name: string; deleteGenerated: boolean }) => {
      const request = {
        ...args,
        schoolName: `schools/${await getSchoolID()}`,
      };
      return plannerClient.deleteAssignment(request).response;
    },
    onSuccess: (_, { name }) => removeAssignmentInQuery(name),
  });

export const useExampleHomework = (
  assignment: Assignment,
  settings: StudentSettings,
  userID?: string,
  opts?: {
    onSuccess?: () => void;
  },
) =>
  useQuery({
    queryKey: ['assignment', assignment, settings, userID],
    queryFn: async () =>
      plannerClient.getPackagePreview(
        GetPackagePreviewRequest.create({
          assignment: assignment,
          studentSettings: settings,
          userId: userID,
        }),
      ).response,
    retry: false,
    staleTime: Infinity, // never refresh
    cacheTime: 0, // instantly remove when not needed
    ...opts,
  });

export const useAssignmentPackages = (assignmentID: string | undefined, opts: Options) =>
  useQuery({
    queryKey: ['packages', 'assignment', assignmentID],
    queryFn: async () =>
      plannerClient.listAssignmentPackages({
        assignmentName: `assignments/${assignmentID}`,
        schoolName: `schools/${await getSchoolID()}`,
      }).response,
    refetchInterval: 5000, // every 5 seconds
    refetchIntervalInBackground: true,
    enabled: Boolean(assignmentID),
    ...opts,
  });

export const useAssignmentSummaries = (assignmentNames: string[], opts: Options) =>
  useQuery({
    queryKey: ['assignments', 'summaries', assignmentNames],
    queryFn: async () =>
      plannerClient.getAssignmentSummaries({
        schoolName: `schools/${await getSchoolID()}`,
        assignmentNames,
      }).response,
    select: data => data.summaries,
    ...opts,
  });

const useConsolidationTopicActions = (groupName: string, opts: Options) =>
  useQuery({
    queryKey: ['consolidation_actions', groupName],
    queryFn: async () =>
      plannerClient.listConsolidationTopicActions({
        groupName,
      }).response,
    select: data => data.actions,
    cacheTime: 0,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    ...opts,
  });

export const useConsolidationTopics = (
  groupName: string | undefined,
  weekWithAssignments: WeekWithAssignments[],
) => {
  const topicsMap = useTopicWithStrandsLookup({ suspense: false });
  const { data: actions = [], ...rest } = useConsolidationTopicActions(groupName || '', {
    suspense: false,
    enabled: !!groupName,
  });

  const consolidationData = useMemo<ConsolidationTopicData>(
    () =>
      Object.keys(topicsMap).length === 0
        ? { topics: [], serverState: {} }
        : getConsolidationTopics(actions, weekWithAssignments, topicsMap),
    [actions, weekWithAssignments, topicsMap],
  );

  return {
    data: consolidationData,
    ...rest,
  } as UseQueryResult<ConsolidationTopicData>;
};

export const useUpdateConsolidationTopicActions = () =>
  useMutation({
    mutationFn: async (args: { groupName: string; changes: ConsolidationTopicAction[] }) =>
      plannerClient.updateConsolidationTopicActions({
        groupName: args.groupName,
        changes: args.changes,
      }).response,
    onSuccess: (newData, { groupName }) =>
      queryClient.setQueryData(['consolidation_actions', groupName], newData),
  });

export interface ConsolidationTopicData {
  topics: TopicWithStrand[];
  serverState: Record<string, ConsolidationTopic>;
}

export interface ConsolidationTopic {
  topicName: string;
  topic: TopicWithStrand;
  included: boolean;
  lastAssignedWeek: number;
}

// takes the consolidation actions and a groups assigments and returns the consolidation topics along with whether they are included in homework or not.
const getConsolidationTopics = (
  actions: ConsolidationTopicAction[],
  weekWithAssignments: WeekWithAssignments[],
  topicsMap: Record<string, TopicWithStrand>,
) => {
  const consolTopics: Record<string, ConsolidationTopic> = {};

  const excludedTopics = actions.reduce(
    (acc, cv) => {
      if (!cv.timestamp) return acc;
      if (cv.action === ConsolidationTopicAction_Action.EXCLUDE) {
        acc[cv.topicName] = Timestamp.toDate(cv.timestamp);
      }
      return acc;
    },
    {} as Record<string, Date | undefined>,
  );

  for (const { week, assignments } of weekWithAssignments) {
    for (const assignment of assignments) {
      if (assignment.spec?.contents.oneofKind !== 'generatedAssignment') continue;
      if (!assignment.generatedTimestamp || !assignment.startTimestamp) continue;
      const assignmentDate = Timestamp.toDate(assignment.startTimestamp);

      const spec = assignment.spec.contents.generatedAssignment;
      for (const { name } of spec.topics) {
        const excludedTime = excludedTopics[name];
        const topicExcluded = excludedTime && isAfter(excludedTime, assignmentDate);
        if (consolTopics[name]) {
          consolTopics[name].included = consolTopics[name].included || !topicExcluded;
          consolTopics[name].lastAssignedWeek = Math.max(
            week.index,
            consolTopics[name].lastAssignedWeek,
          );
        } else {
          const topic = topicsMap[name];
          consolTopics[name] = {
            topicName: name,
            topic,
            included: !topicExcluded,
            lastAssignedWeek: week.index,
          };
        }
      }
    }
  }

  const topics = Object.values(consolTopics)
    .sort((a, b) => {
      if (a.lastAssignedWeek - b.lastAssignedWeek === 0)
        return a.topic.topic.displayName.localeCompare(b.topic.topic.displayName);
      return b.lastAssignedWeek - a.lastAssignedWeek;
    })
    .map(ct => ct.topic);

  return {
    topics,
    serverState: consolTopics,
  };
};

export const useCancelHomework = (assignmentName: string) =>
  useMutation({
    mutationFn: async ({ userIds }: { userIds?: string[] }) =>
      plannerClient.cancelAssignment(
        CancelAssignmentRequest.create({
          assignmentName,
          schoolName: `schools/${await getSchoolID()}`,
          userIds,
        }),
      ).response,
    onSuccess: newData => {
      if (newData.assignment) updateAssignmentInQuery(newData.assignment);
      // reset the package summaries so we refetch them and show the loading state while we do.
      queryClient.resetQueries(packageSummariesKey(assignmentName.split('/')[1]));
    },
  });
