import {
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { faChevronRight, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PartialMessage } from '@protobuf-ts/runtime';
import {
  ContentContext,
  ScienceAssignmentContext,
  SubmitContentFeedbackRequest,
} from '@sparx/api/apis/sparx/interaction/feedback/v1/feedback';
import { Product } from '@sparx/api/apis/sparx/types/product';
import {
  FeedbackForm,
  FeedbackFormHandle,
  SubmitData,
} from '@sparx/shared-components/feedback-form/FeedbackForm';
import { useSubmitContentFeedback } from 'api/feedback';
import { useSession } from 'api/sessions';
import { LargeLoading } from 'components/loading/LargeLoading';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { useRef, useState } from 'react';

import { useTaskItemPanelContext } from './TaskItemPanelContext';

interface ReportProblenmHeaderProps {
  showAnswerToggle: boolean;

  contentContext: PartialMessage<ContentContext>;
  sciContext: PartialMessage<ScienceAssignmentContext>;
  feedbackTriggerName: string;
}

export const ReportProblemHeader = ({
  showAnswerToggle,
  contentContext,
  sciContext,
  feedbackTriggerName,
}: ReportProblenmHeaderProps) => {
  const { data: session, isLoading } = useSession();
  const { showCorrect, setShowCorrect } = useTaskItemPanelContext();
  const {
    mutate: submitFeedback,
    isLoading: isSubmitting,
    isError: isSubmitError,
    isSuccess: isSubmitSuccess,
    reset,
  } = useSubmitContentFeedback();
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: reset,
  });

  const [formIncomplete, setFormIncomplete] = useState(true);

  const onSubmit = ({ comment }: SubmitData) => {
    submitFeedback(
      SubmitContentFeedbackRequest.create({
        comment: comment,
        content: contentContext,
        scienceAssignment: sciContext,
        tp: {
          applicationVersion: import.meta.env.VITE_RELEASE_VERSION || '',
          pageUrl: window.location.toString(),
          schoolName: session?.schoolId ? `schools/${session?.schoolId}` : '',
          triggerName: feedbackTriggerName,
        },
        product: Product.SPARX_SCIENCE,
      }),
    );
  };

  const feedbackPrompt = (
    <>
      <Text>
        If you believe that there is a problem with the content of this question, please let us
        know. For example, if you feel the question could be better worded, or an answer is
        incorrect.
      </Text>
      <Text mt={2}>
        Please note: this comment will be sent to the <strong>Sparx Science question team</strong>{' '}
        and <strong>not to the student</strong>.
      </Text>
    </>
  );

  const formRef = useRef<FeedbackFormHandle>(null);

  return (
    <>
      <Flex
        mx={1}
        mb={1}
        px={3}
        py={2}
        alignItems="center"
        justifyContent="space-between"
        bgColor="whiteAlpha.400"
        borderRadius="base"
        color="white"
        fontSize="sm"
        fontWeight="bold"
      >
        <Link textDecoration="underline" onClick={onOpen}>
          <FontAwesomeIcon icon={faWarning} style={{ marginRight: 4 }} />
          Report an issue with this question
        </Link>
        {showAnswerToggle && (
          <ToggleSwitch
            checked={showCorrect}
            setChecked={setShowCorrect}
            leftLabel="Student Answers"
            rightLabel="Correct Answers"
          />
        )}
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered={true} autoFocus={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display="flex" alignItems={'center'}>
            <FontAwesomeIcon icon={faWarning} style={{ marginRight: 8 }} />
            Report an issue with this question
          </ModalHeader>
          <ModalBody>
            {isLoading ? (
              <LargeLoading />
            ) : (
              <FeedbackForm
                ref={formRef}
                prompt={feedbackPrompt}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                isSubmitted={isSubmitSuccess}
                submitError={
                  isSubmitError
                    ? 'Oops! Something went wrong whilst sending your feedback, please try again.'
                    : undefined
                }
                isFeedbackThrottled={false}
                hideSentimentChoice={true}
                hideSubmitButton={true}
                formIncompleteCallback={setFormIncomplete}
                placeholderText="Dear Sparx,"
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="buttonTeal" onClick={onClose} variant="outline">
              Close
            </Button>
            {!isSubmitSuccess && (
              <Button
                colorScheme="buttonTeal"
                rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                ml={4}
                onClick={() => formRef.current?.submit()}
                isDisabled={formIncomplete}
                isLoading={isSubmitting}
              >
                Send feedback to Sparx
              </Button>
            )}
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};
