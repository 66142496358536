import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  HStack,
  Link as ChakraLink,
  Text,
} from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowLeft,
  faCalendar,
  faChartSimple,
  faChevronLeft,
  faChevronRight,
  faRuler,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PartialMessage } from '@protobuf-ts/runtime';
import {
  ContentContext,
  ScienceAssignmentContext,
} from '@sparx/api/apis/sparx/interaction/feedback/v1/feedback';
import {
  Evaluation,
  GetPackageAnswerHistoryResponse_ActivityWithAnswer,
} from '@sparx/api/apis/sparx/science/packages/v1/activity';
import {
  Package_Contents,
  PackageSummary,
  TaskItem_Status,
} from '@sparx/api/apis/sparx/science/packages/v1/package';
import { HomeworkLength } from '@sparx/api/apis/sparx/science/schools/settings/v1/settings';
import { getCompletionSummary } from '@sparx/packageactivity';
import { usePackageAnswerHistory, usePackageSummaries } from 'api/packages';
import { useAssignment } from 'api/planner';
import { useStudents } from 'api/school';
import { CancelledWarning } from 'components/CancelledUtils';
import { getHandinStatusName } from 'components/CompletionBadge';
import { NotFound } from 'components/errorpages/NotFound';
import { LargeLoading } from 'components/loading/LargeLoading';
import { StudentName } from 'components/Names';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { ProgressBar } from 'components/progressbar/ProgressBar';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import { PrettyTimestamp } from 'components/timestamp/PrettyTimestamp';
import { InfoTooltip } from 'components/tooltip/InfoTooltip';
import queryString from 'query-string';
import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toTimeSpentDuration } from 'utils/duration';
import { articleCancellingHomework } from 'utils/knowledgeBaseArticles';
import { TaskItemWrapper } from 'views/answerhistory/TaskItemWrapper';

import { PackageContentsViewer } from './PackageContentsViewer';
import selectIcon from './selecticon.svg';
import { TaskItemPanel } from './TaskItemPanel';

export const AnswerHistoryView = () => {
  const query = queryString.parse(location.search);
  const assignmentID = query.assignment?.toString() || '';
  const userID = query.user?.toString() || '';

  if (!assignmentID || !userID) {
    return <NotFound />;
  }

  return (
    <SuspenseRoute>
      <SuspenseAnswerHistoryView assignmentID={assignmentID} userID={userID} />
    </SuspenseRoute>
  );
};

interface SuspenseAnswerHistoryViewProps {
  assignmentID: string;
  userID: string;
}

const SuspenseAnswerHistoryView = ({ assignmentID, userID }: SuspenseAnswerHistoryViewProps) => {
  const { data: summaries } = usePackageSummaries(assignmentID, { suspense: true });
  const foundPackage = summaries?.packageSummaries.find(
    s => s.studentName === `students/${userID}`,
  );

  const completion = getCompletionSummary(
    foundPackage?.state?.completion,
    undefined,
    foundPackage?.cancelledTime,
  );
  const { data: assignment } = useAssignment(assignmentID, { suspense: true });

  const { data: students } = useStudents({ suspense: true });
  const student = students?.find(s => s.studentId === userID);

  // TODO: this currently assumes only one group ID - probably fine for now
  const groupID = assignment?.groups[0]?.name.split('/studentGroups/')[1];
  const { state } = useLocation();
  const backLink =
    state?.back ||
    `/teacher/handin?${queryString.stringify({
      assignment: assignmentID,
      group: groupID,
    })}`;

  if (!foundPackage || !student || !summaries?.packageSummaries) {
    return <NotFound />;
  }

  // Load homework generation values from the package annotations
  const packageLevel = foundPackage.annotations['hwk/level'];
  const packageLength = foundPackage.annotations['hwk/secs/tgt']
    ? parseInt(foundPackage.annotations['hwk/secs/tgt'])
    : undefined;
  const packageIsHalfLength = foundPackage.annotations['hwk/length'] === `${HomeworkLength.HALF}`;

  return (
    <AnswerHistory key={foundPackage.name} packageName={foundPackage.name} userID={userID}>
      <PageTitle
        title={
          <>
            Answer History for{' '}
            <ChakraLink
              as={Link}
              color="blue.900"
              to={`/teacher/student/${student.studentId}/summary`}
            >
              <StudentName student={student} />
            </ChakraLink>
          </>
        }
        pageTitleOverride={`Answer History for ${student.givenName} ${student.familyName}`}
        backButton={backLink}
      >
        <StudentNavigation summaries={summaries.packageSummaries} {...{ assignmentID, userID }} />
      </PageTitle>
      {foundPackage.cancelledTime && (
        <CancelledWarning justifyContent="center" mb={4}>
          <Text>
            This homework was{' '}
            <strong>
              cancelled for this student on{' '}
              <PrettyTimestamp fmt="EEEE do MMMM">{foundPackage.cancelledTime}</PrettyTimestamp>.
            </strong>
            <ChakraLink
              textDecoration="underline"
              href={articleCancellingHomework}
              target="_blank"
              ml={2}
            >
              More info
            </ChakraLink>
          </Text>
        </CancelledWarning>
      )}
      <HStack spacing={8} mr={8}>
        <Stat title="Overall completion" flex={1}>
          <ProgressBar
            showPercentages={true}
            showTooltip={true}
            values={[
              {
                value: completion.percentages['C'].roundedPercentage,
                color: 'green.400',
                title: 'Complete',
              },
              {
                value: completion.percentages['W'].roundedPercentage,
                color: 'red.400',
                title: 'Incomplete',
              },
              {
                value: completion.percentages['U'].roundedPercentage,
                color: 'gray.100',
                textColor: 'gray.500',
                title: 'Unattempted',
              },
            ]}
          />
        </Stat>
        {packageLevel && (
          <Stat title="Level" icon={faChartSimple} flex={0}>
            Level {packageLevel}
          </Stat>
        )}
        {packageLength && (
          <Stat title="Set length" icon={faRuler} flex={0}>
            {toTimeSpentDuration(packageLength)}
            {packageIsHalfLength && <InfoTooltip text="Student was set half length homework" />}
          </Stat>
        )}
        <Stat title="Time taken" icon={faClock} flex={0}>
          {toTimeSpentDuration(
            (foundPackage.state?.questionTimeSeconds || 0) +
              (foundPackage.state?.supportTimeSeconds || 0),
          )}
        </Stat>
        <Stat title="Status" icon={faCalendar} flex={0}>
          {getHandinStatusName(completion.status)}
        </Stat>
      </HStack>
    </AnswerHistory>
  );
};

const StudentNavigation = ({
  assignmentID,
  userID,
  summaries,
}: SuspenseAnswerHistoryViewProps & { summaries: PackageSummary[] }) => {
  const { data: students } = useStudents({ suspense: true });

  const getUserLink = (user: string | undefined) =>
    `/teacher/handin/answers?${queryString.stringify({
      assignment: assignmentID,
      user,
    })}`;

  const assignmentStudents = useMemo(
    () =>
      summaries
        .map(p => students?.find(s => s.studentId === p.studentName.split('/')[1]))
        .sort((a, b) =>
          a && b
            ? a.familyName.localeCompare(b.familyName) || a.givenName.localeCompare(b.givenName)
            : 0,
        )
        .map(s => s?.studentId) || [],
    [summaries, students],
  );

  const currentStudentIndex = assignmentStudents.indexOf(userID);
  const nextStudent = assignmentStudents[currentStudentIndex + 1];
  const previousStudent = assignmentStudents[currentStudentIndex - 1];

  return (
    <>
      <Text mr={4} color="gray.500" textAlign="right">
        Student {currentStudentIndex + 1}/{assignmentStudents.length}
      </Text>
      <ButtonGroup isAttached variant="outline">
        <Button
          leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
          isDisabled={!previousStudent}
          pointerEvents={previousStudent ? 'auto' : 'none'}
          as={Link}
          to={getUserLink(previousStudent)}
          bg="white"
        >
          Previous student
        </Button>
        <Button
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          isDisabled={!nextStudent}
          pointerEvents={nextStudent ? 'auto' : 'none'}
          as={Link}
          to={getUserLink(nextStudent)}
          bg="white"
        >
          Next student
        </Button>
      </ButtonGroup>
    </>
  );
};

interface StatProps {
  title: string;
  icon?: IconDefinition;
  children?: React.ReactNode;
  flex?: number;
}

const Stat = ({ title, icon, children, flex }: StatProps) => (
  <Box flex={flex} display="flex" whiteSpace="nowrap">
    {icon && (
      <Text mr={2} mt={-0.5} color="gray.500">
        <FontAwesomeIcon icon={icon} />
      </Text>
    )}
    <Box display="flex" flexDirection="column" flex={1}>
      <Text textAlign="left" fontSize="sm" color="gray.600" mb={icon ? 1 : 0}>
        {title}
      </Text>
      <Box
        width="100%"
        flex="1"
        display="flex"
        alignItems="center"
        fontWeight="bold"
        color="blue.700"
      >
        {children}
      </Box>
    </Box>
  </Box>
);

interface AnswerHistoryProps {
  packageName: string;
  userID: string;
  children?: React.ReactNode;
}

const AnswerHistoryContainer = ({ children }: PropsWithChildren) => (
  <Grid
    templateColumns="1fr 1.7fr"
    templateRows="min-content auto"
    height="100%"
    width="100%"
    gap={4}
    maxWidth={1400}
    py={[2, 4]}
    px={[4, 6]}
    margin="auto"
  >
    {children}
  </Grid>
);

const getSelectedItem = (contents: Package_Contents | undefined, selectedItem: string) => {
  for (const task of contents?.tasks || []) {
    for (const item of task.contents?.taskItems || []) {
      if (item.name === selectedItem) return { item, task };
    }
  }
  return { item: undefined, task: undefined };
};

export const AnswerHistory = ({ packageName, userID, children }: AnswerHistoryProps) => {
  const { data, isLoading } = usePackageAnswerHistory(packageName, userID, { suspense: false });

  const [selectedItem, _setSelectedItem] = useState('');
  const setSelectedItem = (item?: string) => _setSelectedItem(item || '');
  const activityByItem = useMemo(() => {
    const activityByItem: Dictionary<string, GetPackageAnswerHistoryResponse_ActivityWithAnswer[]> =
      {};
    for (const activity of data?.activities || []) {
      if (!activity.activity) continue;
      if (!activityByItem[activity.activity.taskItemName]) {
        activityByItem[activity.activity.taskItemName] = [];
      }
      activityByItem[activity.activity.taskItemName]!.push(activity);
    }
    return activityByItem;
  }, [data?.activities]);

  // Count the number of wrong attempts, for multi-step support we count each step individually
  const getWrongCount = useCallback(
    (item: string) => {
      const acts = activityByItem[item];
      if (!acts) {
        return 0;
      }

      const totalWrongs = acts.reduce((totalWrongs, a) => {
        const skillAct = a.activity?.state?.skillActivity;
        if (!skillAct) {
          return 0;
        }
        const evals = (skillAct.stepEvaluations || []).reduce<Evaluation[]>((prev, stepEval) => {
          prev.push(...stepEval.evaluations);
          return prev;
        }, []);
        if (evals.length === 0 && skillAct.evaluation) {
          evals.push(skillAct.evaluation);
        }

        return evals.reduce(
          (wrongs, ev) =>
            wrongs +
            (ev.status === TaskItem_Status.INCORRECT || ev.status === TaskItem_Status.SKIPPED
              ? 1
              : 0),
          totalWrongs,
        );
      }, 0);

      return totalWrongs;
    },
    [activityByItem],
  );

  // Build the feedback object used for teacher content feedback
  const feedbackContext = useMemo(() => {
    const { item: taskItem, task } = getSelectedItem(data?.package?.contents, selectedItem);

    const tiContents = taskItem?.contents?.contents;
    const contentContext: PartialMessage<ContentContext> = {};
    if (tiContents?.oneofKind === 'skill') {
      contentContext.topicName = tiContents.skill.topicName;
      contentContext.skillName = `skills/${tiContents.skill.skillId}`;
    }

    const sciContext: PartialMessage<ScienceAssignmentContext> = {
      assignmentId: data?.package?.assignment?.assignmentId,
      taskItemName: selectedItem,
      taskTitle: task?.title,
      userId: userID,
    };

    return {
      contentContext,
      sciContext,
    };
  }, [data?.package, selectedItem]);

  if (isLoading) {
    return (
      <AnswerHistoryContainer>
        <GridItem colSpan={2}>{children}</GridItem>
        <GridItem colSpan={2}>
          <LargeLoading />
        </GridItem>
      </AnswerHistoryContainer>
    );
  }
  if (!data || !data.package) {
    return <NotFound />;
  }

  return (
    <AnswerHistoryContainer>
      <GridItem colSpan={2}>{children}</GridItem>
      <GridItem overflowY="auto">
        <PackageContentsViewer
          contents={data?.package?.contents}
          selectedItemName={selectedItem}
          onClickItem={setSelectedItem}
          getAttemptCount={item => activityByItem[item]?.length || 0}
          getWrongCount={getWrongCount}
        />
      </GridItem>

      {selectedItem ? (
        <TaskItemWrapper
          contents={data?.package?.contents}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
        >
          <TaskItemPanel
            activities={activityByItem[selectedItem] || []}
            feedbackContext={feedbackContext}
            key={selectedItem}
          />
        </TaskItemWrapper>
      ) : (
        <GridItem
          bg="white"
          flex={1}
          borderColor="gray.200"
          borderWidth="2px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          borderRadius="md"
        >
          <img src={selectIcon} alt="" />
          <Box display="flex" fontSize="lg" mt={6} px={4}>
            <Text mr={3} color="blue.700">
              <FontAwesomeIcon icon={faArrowLeft} />
            </Text>
            <Text>Select a task item to view individual attempts</Text>
          </Box>
        </GridItem>
      )}
    </AnswerHistoryContainer>
  );
};
