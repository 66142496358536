import { Alert, AlertIcon, AlertProps, Box } from '@chakra-ui/react';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListPackageSummariesResponse } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { PropsWithChildren, useMemo } from 'react';

export const CancelledWarning = ({
  children,
  ...props
}: PropsWithChildren<Omit<AlertProps, 'children'>>) => (
  <Alert status="error" borderRadius="md" boxShadow="elevationLow" {...props}>
    <AlertIcon width={6}>
      <Box>
        <FontAwesomeIcon icon={faBan} fixedWidth size="lg" />
      </Box>
    </AlertIcon>
    {children}
  </Alert>
);

export const useCancelledStudents = (
  studentsMap: Record<string, Student | undefined>,
  summaries?: ListPackageSummariesResponse,
) =>
  useMemo(() => {
    const names = Array.from(
      (summaries?.packageSummaries || []).reduce<Set<string>>((acc, ps) => {
        if (ps.cancelledTime) {
          acc.add(ps.studentName);
        }
        return acc;
      }, new Set()),
    );
    // Sort the students
    return names
      .map(name => studentsMap[name.split('/')[1]])
      .sort((a, b) => {
        if (a === b) return 0;
        if (!a) return 1;
        if (!b) return -1;
        const aName = `${a.familyName} ${a.givenName}`;
        const bName = `${b.familyName} ${b.givenName}`;
        return aName.localeCompare(bName);
      });
  }, [summaries, studentsMap]);
