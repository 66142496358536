import { Box, BoxProps, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { faRocket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressWheel } from 'components/ProgressWheel';
import { Link } from 'react-router-dom';

import { useGettingStartedProgress } from '../hooks';

export const GettingStartedBanner = ({ ...props }: BoxProps) => {
  const progress = useGettingStartedProgress();

  return (
    <Box
      bg="blue.700"
      color="white"
      display="flex"
      py={6}
      px={8}
      justifyContent="space-between"
      borderRadius="md"
      position="relative"
      {...props}
    >
      <Flex flexDirection="column" justifyContent="space-between" alignItems="flex-start">
        <Box>
          <Heading size="md" mb={2} color="white">
            <FontAwesomeIcon icon={faRocket} fixedWidth={true} /> Getting started
          </Heading>
          <Text>
            We&apos;ve created a step-by-step guide to help you through set-up for a successful
            launch.
          </Text>
        </Box>
        <Button size="md" mt={2} colorScheme="buttonTeal" as={Link} to="/teacher/getting-started">
          Set up Sparx Science
        </Button>
      </Flex>

      <ProgressWheel {...progress} />
    </Box>
  );
};
