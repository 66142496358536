import {
  Heading,
  Link as ChakraLink,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import {
  BlocksContent,
  BlocksRenderer as StrapiBlocksRenderer,
} from '@strapi/blocks-react-renderer';
import { Link } from 'react-router-dom';

const headingLevelToSize = new Map([
  [1, '2xl'],
  [2, 'xl'],
  [3, 'lg'],
  [4, 'md'],
  [5, 'sm'],
  [6, 'xs'],
]);

// Renderer for the Strapi 'Rich text (Blocks)' field type.
export const BlocksRenderer = ({ content }: { content: BlocksContent }) => (
  <StrapiBlocksRenderer
    content={content}
    blocks={{
      paragraph: ({ children }) => <Text my={2}>{children}</Text>,
      heading: ({ children, level }) => (
        <Heading size={headingLevelToSize.get(level) || 'md'} my={2}>
          {children}
        </Heading>
      ),
      list: ({ children, format }) => {
        if (format === 'ordered') {
          return <OrderedList>{children}</OrderedList>;
        }
        return <UnorderedList>{children}</UnorderedList>;
      },
      'list-item': ({ children }) => <ListItem>{children}</ListItem>,
      link: ({ children, url }) => (
        <ChakraLink
          as={Link}
          to={url}
          textDecoration="underline"
          _hover={{ textDecoration: 'none' }}
        >
          {children}
        </ChakraLink>
      ),
    }}
  />
);
