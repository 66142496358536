import { ModuleSpec } from '@sparx/teacher-training';

export const TRAINING_MODULE_INTRODUCTION = 'SCIENCE_TRAINING_MODULE_INTRODUCTION';
const moduleIntroduction: ModuleSpec = {
  name: TRAINING_MODULE_INTRODUCTION,
  title: 'Introduction to Sparx Science',
  steps: [
    {
      kind: 'video',
      id: 'what-is-sparx-science',
      title: 'What is Sparx Science?',
      timeToComplete: 1,
      video: 'intro/what-is-sparx-science-v1.mp4',
      videoCover: 'intro/what-is-sparx-science-v1.png',
      videoSubs: 'intro/what-is-sparx-science-v1.vtt',
    },
    {
      kind: 'video',
      id: 'how-sparx-works',
      title: 'How does Sparx Science work?',
      description: 'How we choose questions for homework.',
      timeToComplete: 1,
      video: 'intro/how-sparx-works-v2.mp4',
      videoCover: 'intro/how-sparx-works-v1.png',
      videoSubs: 'intro/how-sparx-works-v2.vtt',
    },
  ],
};

export const TRAINING_MODULE_PLANNER = 'SCIENCE_TRAINING_MODULE_PLANNER';
const modulePlanner: ModuleSpec = {
  name: TRAINING_MODULE_PLANNER,
  title: 'Planning Homework',
  steps: [
    {
      kind: 'video',
      id: 'planning-homework-intro',
      title: 'Planning Homework',
      description: 'How to set and adjust homework in the Planner page.',
      timeToComplete: 1,
      video: 'planning/planning-homework-v1.mp4',
      videoCover: 'planning/planning-homework-v1.png',
      videoSubs: 'planning/planning-homework-v1.vtt',
    },
  ],
};

export const TRAINING_MODULE_STUDENT_EXPERIENCE = 'SCIENCE_TRAINING_MODULE_STUDENT_EXPERIENCE';
const moduleStudentExperience: ModuleSpec = {
  name: TRAINING_MODULE_STUDENT_EXPERIENCE,
  title: 'Sparx Science as a Student',
  steps: [
    {
      kind: 'video',
      id: 'student-experience-intro',
      title: 'Sparx Science as a Student',
      description: "How Sparx Science looks from a student's perspective.",
      timeToComplete: 1,
      video: 'studentexperience/sparx-as-a-student-v1.mp4',
      videoCover: 'studentexperience/sparx-as-a-student-v1.png',
      videoSubs: 'studentexperience/sparx-as-a-student-v1.vtt',
    },
  ],
};

export const TRAINING_MODULE_HOMEWORK_MONITORING = 'SCIENCE_TRAINING_MODULE_HOMEWORK_MONITORING';
const moduleHomeworkMonitoring: ModuleSpec = {
  name: TRAINING_MODULE_HOMEWORK_MONITORING,
  title: 'Monitoring Homework',
  steps: [
    {
      kind: 'video',
      id: 'monitoring-homework-intro',
      title: 'Monitoring Homework',
      description: "How to use the Hand-in page to monitor your classes' homework completion.",
      timeToComplete: 1,
      video: 'monitoring/monitoring-homework-v1.mp4',
      videoCover: 'monitoring/monitoring-homework-v1.png',
      videoSubs: 'monitoring/monitoring-homework-v1.vtt',
    },
  ],
};

export const TRAINING_MODULE_INSIGHTS = 'SCIENCE_TRAINING_MODULE_INSIGHTS';
const moduleInsights: ModuleSpec = {
  name: TRAINING_MODULE_INSIGHTS,
  title: 'Insights',
  steps: [
    {
      kind: 'video',
      id: 'insights-intro',
      title: 'Insights',
      description:
        'What Insights we provide to inform your teaching and identify students to reward or for intervention.',
      timeToComplete: 1,
      video: 'insights/insights-v1.mp4',
      videoCover: 'insights/insights-v1.png',
      videoSubs: 'insights/insights-v1.vtt',
    },
  ],
};

export const ALL_MODULES: ModuleSpec[] = [
  moduleIntroduction,
  modulePlanner,
  moduleStudentExperience,
  moduleHomeworkMonitoring,
  moduleInsights,
];
