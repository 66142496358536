import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react';
import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Task, TaskItem } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { isComplete } from '@sparx/packageactivity';
import { useTaskItemActivities } from 'api/packages';
import { formatDuration, intervalToDuration } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { isTaskRapidFire } from 'utils/rapidfire';

interface ResultsProps {
  task: Task | undefined;
  onContinue?: () => void;
  onFinish?: () => void;
}

export const Results = ({ task, onContinue, onFinish }: ResultsProps) => {
  const complete = isComplete(task?.state?.completion);
  const completion = task?.state?.completion;
  const completeItems = (completion?.progress?.['C'] || 0) + (completion?.progress?.['SK'] || 0);
  const cftItems = completion?.progress?.['CFT'] || 0;
  const totalItems = completion?.size || 0;

  if (!complete) {
    return (
      <Box py={14}>
        <VStack spacing={2}>
          <Heading size="lg">Keep going!</Heading>
          <Text color="gray.500" fontWeight="bold">
            {completeItems}/{totalItems} complete
          </Text>
          <Box height={2} />
          <Button
            size={['sm', 'md']}
            colorScheme="buttonTeal"
            rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
            onClick={onContinue}
          >
            Keep going
          </Button>
        </VStack>
      </Box>
    );
  }

  const isRapidFire = isTaskRapidFire(task);

  return (
    <Box py={14}>
      <VStack spacing={2}>
        <Box
          width={100}
          height={100}
          color="green.500"
          fontSize="5xl"
          display="flex"
          alignItems="center"
          justifyContent="center"
          background="gray.100"
          borderRadius="full"
          mb={4}
        >
          <FontAwesomeIcon icon={faCheck} />
        </Box>
        <Heading size="lg">
          <Text color="green.500" as="span">
            Task complete!
          </Text>{' '}
          Well done.
        </Heading>
        {!isRapidFire ? (
          <Text color="gray.500" fontWeight="bold">
            You got {cftItems}/{totalItems} correct first time.
          </Text>
        ) : (
          <RapidFireResults taskItem={task?.contents?.taskItems[0]} />
        )}
        <Box height={2} />
        <Button
          size={['sm', 'md']}
          colorScheme="buttonTeal"
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          onClick={onFinish}
        >
          Finish
        </Button>
      </VStack>
    </Box>
  );
};

const RapidFireResults = ({ taskItem }: { taskItem?: TaskItem }) => {
  const { data: activities, isSuccess } = useTaskItemActivities(taskItem?.name || '');

  if (!taskItem || !taskItem.state) {
    return null;
  }

  let accuracy =
    (taskItem.state.correctActivities * 100) /
    (taskItem.state.incorrectActivities + taskItem.state.correctActivities);

  // Round correct up or down to nearest integer if it's close to 0 or 100
  if (accuracy > 0 && accuracy < 1) accuracy = Math.ceil(accuracy);
  else if (accuracy > 99 && accuracy < 100) accuracy = Math.floor(accuracy);
  else accuracy = Math.round(accuracy);

  const totalTime = (activities || []).reduce((acc, activity) => {
    return acc + activity.questionTimeSeconds + activity.supportTimeSeconds;
  }, 0);

  return (
    <>
      <Text color="gray.500" fontWeight="bold">
        You were {accuracy}% accurate!
      </Text>
      <AnimatePresence>
        {isSuccess ? (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.4,
              delay: 0.3,
              ease: [0, 0.71, 0.2, 1.5],
            }}
          >
            <Text color="gray.500" fontWeight="bold">
              You completed it in{' '}
              {formatDuration(intervalToDuration({ start: 0, end: totalTime * 1000 }), {
                format: ['minutes', 'seconds'],
              })}
              !
            </Text>
          </motion.div>
        ) : (
          <Text color="gray.500" fontWeight="bold">
            {/* This is a placeholder to stop the page from shifting when the activities have loaded */}
            &nbsp;
          </Text>
        )}
      </AnimatePresence>
    </>
  );
};
