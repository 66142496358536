import { useMemo } from 'react';

import { IStep, ISteps } from '../question/types';

export const useLayoutSteps = (layoutJSON: string | undefined): (IStep | undefined)[] =>
  useMemo(() => {
    try {
      return JSON.parse(layoutJSON || '[]') as ISteps;
    } catch (e) {
      console.error('Failed to parse question layout', layoutJSON);
      return [];
    }
  }, [layoutJSON]);
