import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  faBook,
  faCheck,
  faChevronRight,
  faRotateRight,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { useTrainingContext } from '../Context';
import { useCurrentTrainingState } from '../queries';
import { ModuleState, ModuleStepState, StepSpec } from '../types';
import { TrainingVideoModal } from './TrainingVideoModal';

export const TrainingModulesList = () => {
  const { onTrainingCompleted } = useTrainingContext();
  const { data: moduleStates } = useCurrentTrainingState({ suspense: true });
  const [searchParams, setSearchParams] = useSearchParams();
  const videoId = searchParams.get('video');
  // Store whether all modules are complete when we are first mounted, so we can trigger an action later
  const allComplete = useRef(moduleStates?.every(m => m.complete));

  const currentVideoStep = useMemo(
    () =>
      videoId
        ? moduleStates
            ?.reduce<ModuleStepState[]>((p, m) => p.concat(m.steps), [])
            .find(s => s.stepName === videoId)
        : undefined,
    [videoId, moduleStates],
  );

  // Group the modules by section
  const noSectionModules = useMemo(
    () => moduleStates?.filter(m => !m.spec.section),
    [moduleStates],
  );
  const sectionModules = useMemo(
    () =>
      moduleStates
        ?.filter(m => m.spec.section)
        .reduce<{ name: string; modules: ModuleState[] }[]>((p, m) => {
          const sectionName = m.spec.section!.name;
          const section = p.find(s => s.name === sectionName);
          if (!section) {
            p.push({ name: sectionName, modules: [m] });
          } else {
            section.modules.push(m);
          }
          return p;
        }, []),
    [moduleStates],
  );

  return (
    <>
      {sectionModules &&
        sectionModules.map(s => (
          <Box key={s.name} mb={6}>
            <Heading fontSize={'xl'} as="h4" mb={4}>
              {s.name}
            </Heading>
            <VStack align="stretch" spacing={3}>
              {s.modules.map((m, idx) => (
                <ModuleCard
                  key={m.spec.name}
                  index={idx + 1}
                  moduleState={m}
                  openVideo={step => {
                    searchParams.set('video', step.stepName);
                    setSearchParams(searchParams);
                  }}
                />
              ))}
            </VStack>
          </Box>
        ))}
      <VStack align="stretch" spacing={3}>
        {noSectionModules &&
          noSectionModules.map((m, idx) => (
            <ModuleCard
              key={m.spec.name}
              index={idx + 1}
              moduleState={m}
              openVideo={step => {
                searchParams.set('video', step.stepName);
                setSearchParams(searchParams);
              }}
            />
          ))}
      </VStack>
      {currentVideoStep && (
        <TrainingVideoModal
          state={currentVideoStep}
          onClose={() => {
            searchParams.delete('video');
            setSearchParams(searchParams);
            // check if all modules are now complete then trigger the custome function if
            // defined, when there are other types of steps we might want to consider if
            // different way to do this is needed.
            if (
              allComplete !== undefined &&
              !allComplete.current &&
              moduleStates?.every(m => m.complete)
            ) {
              allComplete.current = true;
              onTrainingCompleted && onTrainingCompleted();
            }
          }}
        />
      )}
    </>
  );
};

const ModuleCard = ({
  moduleState,
  index,
  openVideo,
}: {
  moduleState: ModuleState;
  index: number;
  openVideo: (stepState: ModuleStepState) => void;
}) => {
  const spec = moduleState.spec;

  return (
    <Card>
      <CardHeader
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems={{ base: 'flex-start', sm: 'center' }}
        py={4}
        pl={6}
        pr={4}
      >
        <Text fontWeight="bold">
          <Text as="span" color="blue.900">
            {index}. {spec.title}
          </Text>
          {moduleState.steps.length === 1 && <StepDetails spec={moduleState.steps[0].spec} />}
        </Text>
        <Box mt={{ base: 2, sm: 0 }}>
          {moduleState.steps.length === 1 && (
            <StepButton stepState={moduleState.steps[0]} openVideo={openVideo} />
          )}
        </Box>
      </CardHeader>
      {moduleState.steps.length > 1 &&
        moduleState.steps.map(step => (
          <CardBody
            key={step.spec.id}
            display="flex"
            flexDirection={{ base: 'column', sm: 'row' }}
            alignItems={{ base: 'flex-start', sm: 'center' }}
            justifyContent="space-between"
            py={4}
            pr={4}
            borderTopWidth={1}
          >
            <Text ml={3}>
              {step.spec.title}
              <StepDetails spec={step.spec} />
            </Text>
            <Box mt={{ base: 2, sm: 0 }} ml={3}>
              <StepButton stepState={step} openVideo={openVideo} />
            </Box>
          </CardBody>
        ))}
    </Card>
  );
};

const StepDetails = ({ spec }: { spec: StepSpec }) => {
  if (spec.kind === 'video') {
    return (
      <Text as="span" whiteSpace="nowrap">
        {spec.timeToComplete && (
          <Text as="span" color="gray.400" fontWeight="normal" ml={2}>
            ({spec.timeToComplete} min{spec.timeToComplete > 1 && 's'})
          </Text>
        )}
        {spec.kind === 'video' && (
          <Text
            as="span"
            color="gray.400"
            fontWeight="normal"
            ml={2}
            display={{ base: 'none', md: 'inline' }}
          >
            <FontAwesomeIcon icon={faVideo} fixedWidth={true} />
          </Text>
        )}
      </Text>
    );
  }
  if (spec.kind === 'link') {
    return (
      <Text as="span" whiteSpace="nowrap">
        <Text
          as="span"
          color="gray.400"
          fontWeight="normal"
          ml={2}
          display={{ base: 'none', md: 'inline' }}
        >
          <FontAwesomeIcon icon={faBook} fixedWidth={true} />
        </Text>
      </Text>
    );
  }
  return null;
};

const StepButton = ({
  stepState,
  openVideo,
}: {
  stepState: ModuleStepState;
  openVideo: (stepState: ModuleStepState) => void;
}) => {
  const { spec } = stepState;
  const isVideo = spec.kind === 'video';
  const isLink = spec.kind === 'link';
  const isComplete = stepState.complete;
  const navigate = useNavigate();
  let clickHandler = isVideo && openVideo;
  if (isLink) {
    clickHandler = () => !isComplete && navigate && navigate(spec.to);
  }

  let icon;
  if (isVideo && isComplete) {
    icon = <FontAwesomeIcon icon={faRotateRight} />;
  } else if (!isComplete && (isVideo || isLink)) {
    icon = <FontAwesomeIcon icon={faChevronRight} />;
  }

  return (
    <Flex alignItems="center">
      {isComplete && (
        <Text color="green.400" mr={2} fontWeight="bold">
          <Text as="span" display={{ base: 'none', md: 'inline' }}>
            Complete{' '}
          </Text>
          <FontAwesomeIcon icon={faCheck} fixedWidth={true} />
        </Text>
      )}
      <Button
        rightIcon={icon}
        onClick={() => clickHandler && clickHandler(stepState)}
        colorScheme="buttonBlue"
        variant={isComplete ? 'outline' : 'solid'}
        size={{ base: 'xs', sm: 'sm', md: 'md' }}
        width={28}
        isDisabled={isComplete && isLink}
      >
        {!isComplete && 'Start'}
        {isComplete && isVideo && 'Revisit'}
        {/* Links cannot be revisited */}
        {isComplete && isLink && 'Complete'}
      </Button>
    </Flex>
  );
};
