import { Box, CircularProgress, CircularProgressLabel, Text } from '@chakra-ui/react';

import { useCurrentTrainingState } from '../queries';

export const TrainingProgressWheel = ({ size = 'md' }: { size?: 'md' | 'lg' }) => {
  const { data: moduleStates } = useCurrentTrainingState({ suspense: true });

  if (!moduleStates || moduleStates.length === 0) {
    return null;
  }

  const completeModules = moduleStates.filter(m => m.complete).length;
  const totalModules = moduleStates.length;
  const allComplete = completeModules >= totalModules;

  return (
    <Box textAlign="center">
      <CircularProgress
        min={0}
        max={totalModules}
        value={completeModules}
        color="green.400"
        size={size === 'md' ? '75px' : '120px'}
      >
        <CircularProgressLabel
          fontSize={size === 'md' ? 'xl' : '3xl'}
          fontWeight="bold"
          color={allComplete ? 'green.400' : undefined}
        >
          {completeModules}/{totalModules}
        </CircularProgressLabel>
      </CircularProgress>
      {allComplete && (
        <Text fontWeight="bold" color="green.400" whiteSpace="nowrap">
          Well done!
        </Text>
      )}
    </Box>
  );
};
