import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubmitStudentSentimentFeedbackRequest } from '@sparx/api/apis/sparx/interaction/feedback/v1/feedback';
import { Product } from '@sparx/api/apis/sparx/types/product';
import {
  FeedbackForm,
  FeedbackFormHandle,
  SubmitData,
} from '@sparx/shared-components/feedback-form/FeedbackForm';
import { useSubmitStudentFeedback } from 'api/feedback';
import { useSession } from 'api/sessions';
import { LargeLoading } from 'components/loading/LargeLoading';
import { SessionGivenName } from 'components/Names';
import { useRef, useState } from 'react';

import FeedbackIcon from './feedback-icon.svg';

export const StudentFeedbackModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { data: session, isLoading } = useSession();

  const {
    mutate: submitFeedback,
    isLoading: isSubmitting,
    isError: isSubmitError,
    isSuccess: isSubmitSuccess,
  } = useSubmitStudentFeedback();

  const [formIncomplete, setFormIncomplete] = useState(true);

  const onSubmit = ({ sentiment, comment }: SubmitData) => {
    submitFeedback(
      SubmitStudentSentimentFeedbackRequest.create({
        sentiment,
        comment,
        sw: {
          feedbackType: 'header-feedback',
          schoolName: session?.schoolId ? `schools/${session?.schoolId}` : '',
          applicationVersion: import.meta.env.VITE_RELEASE_VERSION || '',
          pageUrl: window.location.toString(),
        },
        product: Product.SPARX_SCIENCE,
      }),
    );
  };

  const feedbackPrompt = (
    <Text>
      Hi <SessionGivenName />. You can use this to tell us what you do or don&apos;t like about
      using Sparx Science.
    </Text>
  );

  const formRef = useRef<FeedbackFormHandle>(null);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered={true} autoFocus={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display="flex" alignItems={'center'}>
            <Image src={FeedbackIcon} width={16} mr={2} /> Give Feedback
          </ModalHeader>
          <ModalBody>
            {isLoading ? (
              <LargeLoading />
            ) : (
              <FeedbackForm
                ref={formRef}
                prompt={feedbackPrompt}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                isSubmitted={isSubmitSuccess}
                consentText="I will use this form responsibly. I understand that any feedback I share may be shown to my teachers at my school and with my parents/carers."
                submitError={
                  isSubmitError
                    ? 'Oops! Something went wrong whilst sending your feedback, please try again.'
                    : undefined
                }
                placeholderText="Tell us why..."
                isFeedbackThrottled={false}
                hideSubmitButton={true}
                formIncompleteCallback={setFormIncomplete}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="buttonTeal" onClick={onClose} variant="outline">
              Close
            </Button>
            {!isSubmitSuccess && (
              <Tooltip
                placement="top"
                label={formIncomplete ? 'Complete all sections above to submit your feedback.' : ''}
                hasArrow={true}
              >
                <Button
                  colorScheme="buttonTeal"
                  rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                  ml={4}
                  onClick={() => formRef.current?.submit()}
                  isDisabled={formIncomplete}
                  isLoading={isSubmitting}
                >
                  Send
                </Button>
              </Tooltip>
            )}
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};
