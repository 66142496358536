import { HStack, Skeleton, SkeletonCircle, Text, VStack } from '@chakra-ui/react';
import { TaskItem_Status } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { queryClient } from 'api/client';
import { useStudents } from 'api/school';
import { LargeLoading } from 'components/loading/LargeLoading';
import { StudentName } from 'components/Names';
import React, { useMemo } from 'react';
import { ActivityResult } from 'views/answerhistory/ActivityResult';
import {
  addAnswerToActivities,
  useAnswerLookup,
  useAnswerModeCheckbox,
  useListActivities,
} from 'views/lessons/panel/ActivityPanel';
import { Panel } from 'views/lessons/panel/Panel';
import { TeacherActivityMark } from 'views/lessons/panel/TeacherActivityMark';

interface AssignmentTaskItemPanelProps {
  assignmentId: string;
  taskIndex: number;
  taskItemIndex: number;
  onClose: () => void;
}

export const AssignmentTaskItemPanel = ({
  assignmentId,
  taskIndex,
  taskItemIndex,
  onClose,
}: AssignmentTaskItemPanelProps) => {
  const selectedTaskItem = `assignments/${assignmentId}/tasks/${taskIndex}/items/${taskItemIndex}`;
  const { data, isLoading, refetch } = useListActivities(selectedTaskItem);

  const { showAnswerOnly, checkbox: answerOnlyCheckbox } = useAnswerModeCheckbox();

  const { data: students } = useStudents({ suspense: false });

  const answerLookup = useAnswerLookup(data);
  const relevantActivities = useMemo(() => {
    const acts =
      data?.activities
        ?.filter(
          a =>
            a.activity &&
            a.activity.state?.skillActivity?.evaluation?.completed &&
            a.activity.state?.skillActivity?.evaluation?.status === TaskItem_Status.PENDING_CORRECT,
        )
        ?.sort(
          (a, b) =>
            (a.activity?.endTimestamp?.seconds || 0) - (b.activity?.endTimestamp?.seconds || 0),
        ) || [];

    return addAnswerToActivities(acts, answerLookup);
  }, [data?.activities, answerLookup]);

  if (isLoading) {
    return (
      <Panel onClose={onClose}>
        <LargeLoading />
      </Panel>
    );
  }

  const currentActivity = relevantActivities[0];
  const currentStudent = students?.find(s => s.studentId === currentActivity?.studentId);

  return (
    <Panel
      title={
        <HStack spacing={3} ml={1}>
          <SkeletonCircle startColor="red.100" endColor="red.200" size="3" flexShrink={0} />
          {currentActivity?.activity ? (
            <Text>
              {relevantActivities.length} pending answer
              {relevantActivities.length !== 1 && 's'}
            </Text>
          ) : (
            <Text>Waiting for more answers...</Text>
          )}
        </HStack>
      }
      onClose={onClose}
      right={answerOnlyCheckbox}
    >
      {currentActivity?.activity ? (
        <>
          <ActivityResult
            activityWithAnswer={currentActivity}
            index={0}
            first={true}
            mode={showAnswerOnly ? 'answer' : 'combined'}
            header={
              <Text>
                <StudentName student={currentStudent} />
              </Text>
            }
          />
          <TeacherActivityMark
            activity={currentActivity.activity}
            studentId={currentActivity.studentId}
            onSave={async () => {
              if (assignmentId) {
                queryClient.refetchQueries(['packages', 'assignment', assignmentId]);
              }
              return refetch();
            }}
          />
        </>
      ) : (
        <VStack spacing={2} alignItems="stretch" p={2}>
          <Skeleton endColor="gray.200" height="40px" />
          <Skeleton endColor="gray.200" height="200px" />
        </VStack>
      )}
    </Panel>
  );
};
