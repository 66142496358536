import {
  Activity,
  Evaluation,
  SkillActivity_State,
} from '@sparx/api/apis/sparx/science/packages/v1/activity';
import { TaskItem_Status } from '@sparx/api/apis/sparx/science/packages/v1/package';
import * as annotations from '@sparx/packageactivity/src/annotations';
import { QuestionMarkingMode } from '@sparx/question';

// If the activity was correct, this is the second chance results, the support q is next,
// or this is support, then show the more detailed feedback, otherwise display the summary feedback
export const getQuestionMarkingMode = (
  activity: Activity,
  stepEvaluation?: Evaluation,
): QuestionMarkingMode =>
  stepEvaluation?.status === TaskItem_Status.CORRECT ||
  activity.state?.skillActivity?.evaluation?.status === TaskItem_Status.CORRECT ||
  annotations.isTalkAndLearnTransition(activity.annotations) ||
  (annotations.isSecondChance(activity.annotations) &&
    activity.state?.skillActivity?.state === SkillActivity_State.RESULTS) ||
  annotations.isSupport(activity.annotations)
    ? 'gap'
    : 'gap-summary';
