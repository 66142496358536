import { useDebounce } from '@sparx/react-utils/hooks/use-debounce';
import { useSearchQueryEvent } from 'api/sessions';
import { useEffect, useState } from 'react';

import { ScienceSearchResult } from './OmniSearch';

interface SearchQueryResult {
  query: string;
  results: ScienceSearchResult[];
}

const formatSearchResultTitleForEvent = (r: ScienceSearchResult) =>
  `${r.parent ? `${r.parent} > ` : ''}${r.title}${r.rightText ? ` (${r.rightText})` : ''}`;

const searchResultToEvent = (result: SearchQueryResult) => ({
  searchQuery: result.query,
  selectedResultTitle: '',
  selectedResultUrl: '',
  results: result.results.map(r => ({
    title: formatSearchResultTitleForEvent(r),
    url: r.url,
  })),
});

export const useSearchResultLogger = () => {
  const { mutate: logSearch } = useSearchQueryEvent();
  const [lastSearch, setLastSearch] = useState<SearchQueryResult | undefined>(undefined);
  const debouncedSearch = useDebounce(lastSearch, 250);
  useEffect(() => {
    if (debouncedSearch) {
      logSearch(searchResultToEvent(debouncedSearch));
    }
  }, [logSearch, debouncedSearch]);

  const onSelectResult = (value: ScienceSearchResult) => {
    if (lastSearch) {
      logSearch({
        ...searchResultToEvent(lastSearch),
        selectedResultTitle: formatSearchResultTitleForEvent(value),
        selectedResultUrl: value.url,
      });
    }
  };

  return { onQuery: setLastSearch, onSelect: onSelectResult };
};
