import { Box, BoxProps, Button, Flex, Heading, IconButton, Text } from '@chakra-ui/react';
import { faClose, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { TrainingProgressWheel, useCurrentTrainingState } from '@sparx/teacher-training';
import { useSession } from 'api/sessions';
import { UserSettings, useUpdateUserSetting, useUserSetting } from 'api/usersettings';
import { useClientEvent } from 'components/ClientEventProvider';
import { isBefore, sub } from 'date-fns';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

export const TrainingBanner = ({ ...props }: BoxProps) => {
  const { data } = useCurrentTrainingState({ suspense: false });
  const { data: user } = useSession({ suspense: true });
  const { data: dismissed } = useUserSetting(UserSettings.trainingBannerDismissed, {
    suspense: true,
  });
  const { mutate: updateSetting } = useUpdateUserSetting();
  const [localDismissed, setDismissed] = useState(false);
  const { sendEvent } = useClientEvent();

  const complete = data?.every(m => m.complete);

  const allowDismiss = useMemo(
    () =>
      user?.firstLogin &&
      isBefore(Timestamp.toDate(user.firstLogin), sub(new Date(), { months: 3 })),
    [user],
  );

  if (!data || complete || dismissed || localDismissed) {
    return null;
  }

  const doDismiss = () => {
    setDismissed(true);
    updateSetting({ key: UserSettings.trainingBannerDismissed, value: new Date().toISOString() });
    sendEvent(
      {
        action: 'dismiss_home_training_widget',
        category: 'training',
      },
      {
        completedModules: data.filter(m => m.complete).length.toString(),
        totalModules: data.length.toString(),
      },
    );
  };

  const started = data.some(m => m.steps.some(s => s.complete));

  return (
    <Box
      bg="purple.700"
      color="white"
      borderRadius="md"
      position="relative"
      display="flex"
      {...props}
    >
      {allowDismiss && (
        <IconButton
          position="absolute"
          top={1}
          right={1}
          aria-label="dismiss"
          icon={<FontAwesomeIcon icon={faClose} />}
          variant="ghost"
          size="sm"
          colorScheme="whiteAlpha"
          color="white"
          onClick={doDismiss}
        />
      )}
      <Box my={6} mx={8} display="flex" justifyContent="space-between" flex={1}>
        <Flex flexDirection="column" justifyContent="space-between" alignItems="flex-start">
          <Box>
            <Heading size="md" mb={2} color="white">
              <FontAwesomeIcon icon={faGraduationCap} fixedWidth={true} /> Sparx training
            </Heading>
            <Text>
              {!started
                ? 'Take 10 minutes to complete your Sparx Science training.'
                : 'Continue your Sparx training.'}{' '}
              This will help you get the most out of Sparx.
            </Text>
          </Box>
          <Button size="md" mt={2} colorScheme="buttonTeal" as={Link} to="/teacher/training">
            {!started ? 'Start' : 'Continue'} training
          </Button>
        </Flex>
        <Flex
          justifyContent="center"
          alignItems="center"
          direction={{ base: 'column', md: 'row' }}
          ml={6}
        >
          <TrainingProgressWheel size="lg" />
        </Flex>
      </Box>
    </Box>
  );
};
