// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/content/v1/content.proto" (package "sparx.science.content.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message sparx.science.content.v1.CustomQuestion
 */
export interface CustomQuestion {
  /**
   * @generated from protobuf field: string question_text = 1;
   */
  questionText: string;
  /**
   * @generated from protobuf field: string uploaded_asset_name = 2;
   */
  uploadedAssetName: string;
  /**
   * @generated from protobuf oneof: answer
   */
  answer:
    | {
        oneofKind: 'multipleChoice';
        /**
         * @generated from protobuf field: sparx.science.content.v1.CustomQuestion.MultipleChoiceAnswer multiple_choice = 3;
         */
        multipleChoice: CustomQuestion_MultipleChoiceAnswer;
      }
    | {
        oneofKind: 'text';
        /**
         * @generated from protobuf field: sparx.science.content.v1.CustomQuestion.TextInputAnswer text = 4;
         */
        text: CustomQuestion_TextInputAnswer;
      }
    | {
        oneofKind: 'camera';
        /**
         * @generated from protobuf field: sparx.science.content.v1.CustomQuestion.CameraAnswer camera = 5;
         */
        camera: CustomQuestion_CameraAnswer;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.science.content.v1.CustomQuestion.MultipleChoiceAnswer
 */
export interface CustomQuestion_MultipleChoiceAnswer {
  /**
   * @generated from protobuf field: repeated sparx.science.content.v1.CustomQuestion.MultipleChoiceAnswer.Option options = 1;
   */
  options: CustomQuestion_MultipleChoiceAnswer_Option[];
  /**
   * @generated from protobuf field: bool shuffle = 2;
   */
  shuffle: boolean;
}
/**
 * @generated from protobuf message sparx.science.content.v1.CustomQuestion.MultipleChoiceAnswer.Option
 */
export interface CustomQuestion_MultipleChoiceAnswer_Option {
  /**
   * @generated from protobuf field: string value = 1;
   */
  value: string;
  /**
   * @generated from protobuf field: bool correct = 2;
   */
  correct: boolean;
}
/**
 * @generated from protobuf message sparx.science.content.v1.CustomQuestion.TextInputAnswer
 */
export interface CustomQuestion_TextInputAnswer {
  /**
   * @generated from protobuf field: repeated string answers = 1;
   */
  answers: string[];
}
/**
 * @generated from protobuf message sparx.science.content.v1.CustomQuestion.CameraAnswer
 */
export interface CustomQuestion_CameraAnswer {}
/**
 * @generated from protobuf message sparx.science.content.v1.CreateCustomSkillRequest
 */
export interface CreateCustomSkillRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * @generated from protobuf field: sparx.science.content.v1.CustomQuestion question = 2;
   */
  question?: CustomQuestion;
}
/**
 * @generated from protobuf message sparx.science.content.v1.CreateCustomSkillResponse
 */
export interface CreateCustomSkillResponse {
  /**
   * @generated from protobuf field: string skill_name = 1;
   */
  skillName: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class CustomQuestion$Type extends MessageType<CustomQuestion> {
  constructor() {
    super('sparx.science.content.v1.CustomQuestion', [
      {
        no: 1,
        name: 'question_text',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'uploaded_asset_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'multiple_choice',
        kind: 'message',
        oneof: 'answer',
        T: () => CustomQuestion_MultipleChoiceAnswer,
      },
      {
        no: 4,
        name: 'text',
        kind: 'message',
        oneof: 'answer',
        T: () => CustomQuestion_TextInputAnswer,
      },
      {
        no: 5,
        name: 'camera',
        kind: 'message',
        oneof: 'answer',
        T: () => CustomQuestion_CameraAnswer,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.content.v1.CustomQuestion
 */
export const CustomQuestion = new CustomQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomQuestion_MultipleChoiceAnswer$Type extends MessageType<CustomQuestion_MultipleChoiceAnswer> {
  constructor() {
    super('sparx.science.content.v1.CustomQuestion.MultipleChoiceAnswer', [
      {
        no: 1,
        name: 'options',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => CustomQuestion_MultipleChoiceAnswer_Option,
      },
      { no: 2, name: 'shuffle', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.content.v1.CustomQuestion.MultipleChoiceAnswer
 */
export const CustomQuestion_MultipleChoiceAnswer =
  new CustomQuestion_MultipleChoiceAnswer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomQuestion_MultipleChoiceAnswer_Option$Type extends MessageType<CustomQuestion_MultipleChoiceAnswer_Option> {
  constructor() {
    super(
      'sparx.science.content.v1.CustomQuestion.MultipleChoiceAnswer.Option',
      [
        { no: 1, name: 'value', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: 'correct', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.content.v1.CustomQuestion.MultipleChoiceAnswer.Option
 */
export const CustomQuestion_MultipleChoiceAnswer_Option =
  new CustomQuestion_MultipleChoiceAnswer_Option$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomQuestion_TextInputAnswer$Type extends MessageType<CustomQuestion_TextInputAnswer> {
  constructor() {
    super('sparx.science.content.v1.CustomQuestion.TextInputAnswer', [
      {
        no: 1,
        name: 'answers',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.content.v1.CustomQuestion.TextInputAnswer
 */
export const CustomQuestion_TextInputAnswer =
  new CustomQuestion_TextInputAnswer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomQuestion_CameraAnswer$Type extends MessageType<CustomQuestion_CameraAnswer> {
  constructor() {
    super('sparx.science.content.v1.CustomQuestion.CameraAnswer', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.content.v1.CustomQuestion.CameraAnswer
 */
export const CustomQuestion_CameraAnswer =
  new CustomQuestion_CameraAnswer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateCustomSkillRequest$Type extends MessageType<CreateCustomSkillRequest> {
  constructor() {
    super('sparx.science.content.v1.CreateCustomSkillRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'question', kind: 'message', T: () => CustomQuestion },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.content.v1.CreateCustomSkillRequest
 */
export const CreateCustomSkillRequest = new CreateCustomSkillRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateCustomSkillResponse$Type extends MessageType<CreateCustomSkillResponse> {
  constructor() {
    super('sparx.science.content.v1.CreateCustomSkillResponse', [
      { no: 1, name: 'skill_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.content.v1.CreateCustomSkillResponse
 */
export const CreateCustomSkillResponse = new CreateCustomSkillResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.science.content.v1.Content
 */
export const Content = new ServiceType('sparx.science.content.v1.Content', [
  {
    name: 'CreateCustomSkill',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'tp:homework',
        domain: '{school_name}',
      },
    },
    I: CreateCustomSkillRequest,
    O: CreateCustomSkillResponse,
  },
]);
