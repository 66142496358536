import { Assignment } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { queryClient } from 'api/client';
import {
  AssignmentWithAction,
  removeAssignmentInQuery,
  updateAssignmentInQuery,
  useCreateOrUpdateAssignment,
} from 'api/planner';
import { useState } from 'react';
import { unstable_usePrompt } from 'react-router-dom';

export const usePlannerUndoState = (assignments: Assignment[]) => {
  const [history, setHistory] = useState<AssignmentWithAction[]>([]);
  const [future, setFuture] = useState<AssignmentWithAction[]>([]);

  const [savedTime, setSavedTime] = useState<Date | undefined>();

  const { mutateAsync, isLoading, isSuccess, error } = useCreateOrUpdateAssignment({
    noQueryUpdate: true,
    onSuccess: (data, req) => {
      const queue = (queryClient.getQueryData(['assignments', 'queue']) ||
        []) as AssignmentWithAction[];
      const otherUpdateForThisAssignment = queue.find(a => a.name === data.name);
      if (!otherUpdateForThisAssignment) {
        // for consistency as we optimistically updated earlier
        updateQueryWithAssignment({ ...data, action: req.action });
      }
      if (queue.length > 0) {
        const next = queue[0];
        queryClient.setQueryData(['assignments', 'queue'], queue.slice(1));
        mutateAsync(next);
      } else {
        setSavedTime(new Date());
      }
    },
  });

  unstable_usePrompt({
    message: 'You have unsaved changes. Are you sure you want to leave?',
    when: isLoading,
  });

  const invertAction = (action: 'create' | 'delete' | 'update') => {
    switch (action) {
      case 'delete':
        return 'create';
      case 'create':
        return 'delete';
      default:
        return 'update';
    }
  };

  const getInverseAssignment = (next: AssignmentWithAction): AssignmentWithAction => {
    const assignment = assignments.find(a => a.name === next.name);
    return { ...(assignment || next), action: invertAction(next.action) };
  };

  const updateQueryWithAssignment = (assignment: AssignmentWithAction) => {
    if (assignment.action !== 'delete') updateAssignmentInQuery(assignment);
    else removeAssignmentInQuery(assignment.name);
  };

  const updateAssignment = (assignment: AssignmentWithAction, action?: 'undo' | 'redo') => {
    // History logic
    if (!action) {
      if (!assignment.deleteExisting) {
        setHistory(history.splice(-29).concat([getInverseAssignment(assignment)]));
      }
      setFuture([]);
    }
    updateQueryWithAssignment(assignment);

    if (!isLoading) {
      mutateAsync(assignment);
    } else {
      queryClient.setQueryData(['assignments', 'queue'], data =>
        ((data || []) as AssignmentWithAction[]).concat([assignment]),
      );
    }
  };

  const undo = () => {
    if (history.length > 0) {
      const last = history[history.length - 1];
      setFuture(future.concat([getInverseAssignment(last)]));
      setHistory(history.slice(0, -1));
      updateAssignment(last, 'undo');
    }
  };

  const redo = () => {
    if (future.length > 0) {
      const next = future[future.length - 1];
      setHistory(history.concat([getInverseAssignment(next)]));
      setFuture(future.slice(0, -1));
      updateAssignment(next, 'redo');
    }
  };

  const clearUndoState = () => {
    setHistory([]);
    setFuture([]);
  };

  return {
    isLoading,
    isSuccess,
    error,
    savedTime,
    redo,
    undo,
    hasHistory: history.length > 0,
    hasFuture: future.length > 0,
    updateAssignment,
    clearUndoState,
  };
};
