import { useGroupAssignments } from 'api/planner';
import { useClassSelection } from 'app/ClassSelection';
import { NoHomework } from 'components/errorpages/NoHomework';
import { LargeLoading } from 'components/loading/LargeLoading';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { WeekSelector } from 'components/weekselector/WeekSelector';
import queryString from 'query-string';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { HandinTable } from 'views/handin/HandInTable';

export const HandInView = () => (
  <React.Suspense fallback={<LargeLoading />}>
    <SuspenseHandInView />
  </React.Suspense>
);

const SuspenseHandInView = () => {
  const { assignmentLinker, group, assignments, foundAssignment, assignmentID, groupID } =
    useAssignmentLinker(`/teacher/handin`);

  const assignmentUserLinker = useCallback(
    (assignmentID: string, userID?: string) =>
      `/teacher/handin?${queryString.stringify({
        group: groupID,
        assignment: assignmentID,
        user: userID,
      })}`,
    [groupID],
  );

  if (!groupID) return null;
  const pageTitle = group ? `${group.displayName} Hand-in` : undefined;

  if (!group) {
    return null;
  }
  if (assignments.length === 0) {
    return <NoHomework title={pageTitle} groupID={groupID} />;
  }

  return (
    <PageContainer>
      <PageTitle title="Hand-in" pageTitleOverride={pageTitle} />
      <WeekSelector
        assignments={assignments}
        selected={foundAssignment?.name}
        assignmentLink={a =>
          assignmentLinker({
            assignment: a.replace('assignments/', ''),
          })
        }
      />
      {foundAssignment?.name && (
        <React.Suspense fallback={<LargeLoading />}>
          <HandinTable
            assignmentID={assignmentID}
            assignments={assignments}
            assignmentLinker={assignmentUserLinker}
          />
        </React.Suspense>
      )}
    </PageContainer>
  );
};

export const useAssignmentLinker = (path: string) => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const assignmentID = query.assignment?.toString() || '';
  const groupID = query.group?.toString() || '';

  const assignmentLinker = (partial: Dictionary<string, string>) => {
    const query = queryString.stringify({
      group: groupID,
      assignment: assignmentID,
      ...partial,
    });
    return `${path}?${query}`;
  };

  const group = useClassSelection({
    current: groupID,
    link: (group: string) => assignmentLinker({ group, assignment: '' }),
  });

  const assignments = useGroupAssignments(groupID, { suspense: true }).filter(
    a => a.generatedTimestamp,
  );
  const foundAssignment = assignments.find(a => a.name === `assignments/${assignmentID}`);

  return {
    assignmentLinker,
    group,
    assignments,
    foundAssignment,
    assignmentID,
    groupID,
    query,
  };
};
