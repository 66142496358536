import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Input,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Assignment } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import React, { useState } from 'react';
import { usePlannerContext } from 'views/planner/Context';

export const DeleteAssignmentModal = ({
  assignment,
  button,
}: {
  assignment: Assignment;
  button: (onClick: () => void) => JSX.Element;
}) => {
  const { doAssignmentAction } = usePlannerContext();

  const isGenerated = Boolean(assignment.generatedTimestamp);
  const { isOpen, onOpen, onClose: _onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);

  const [deletePrompt, setDeletePrompt] = useState('');
  const deleteGenerated = deletePrompt === 'remove';

  const isLoading = false;
  const onClose = () => !isLoading && _onClose();
  // TODO: deleteGenerated ??
  const onDelete = () =>
    doAssignmentAction({ ...assignment, action: 'delete', deleteExisting: deleteGenerated });

  return (
    <>
      {button(onOpen)}
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} size="lg">
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Homework
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>Are you sure?</Text>

              {isGenerated && (
                <>
                  <Text pt={2} fontWeight="bold" color="red">
                    Warning: this assignment has been generated. Deleting it will also remove the
                    packages from the student interface. You can't undo this action afterwards.
                  </Text>
                  <Text pt={2} pb={2}>
                    If you still want to remove this assignment, type the word 'remove' below:
                  </Text>
                  <Input value={deletePrompt} onChange={e => setDeletePrompt(e.target.value)} />
                </>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} isDisabled={isLoading}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={onDelete}
                ml={3}
                isLoading={isLoading}
                isDisabled={isGenerated && !deleteGenerated}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
