import { Button, ButtonGroup, Flex } from '@chakra-ui/react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListLessonPageToken } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { useClassSelection } from 'app/ClassSelection';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useGroupLinker = (path: string) => {
  const [search, setSearch] = useSearchParams();
  const groupID = search.get('group') || '';

  const linker = (partial: Dictionary<string, string>) => {
    const query = queryString.stringify(partial);
    return `${path}?${query}`;
  };

  const group = useClassSelection({
    current: groupID,
    link: (group: string) => linker({ group }),
    allowAll: true,
  });

  return { group, groupID, search, setSearch };
};

export const usePagedLessonList = (path: string) => {
  const { group, search, setSearch } = useGroupLinker(path);
  const page = parseInt(search.get('p') || '0') || 0;
  const setPage = (page: number) =>
    setSearch(prev => {
      prev.set('p', `${page}`);
      return prev;
    });

  const pageToken = useMemo(
    () =>
      ListLessonPageToken.create({
        pageSize: 10,
        page,
      }),
    [page],
  );

  const getNavigation = (nextPageToken: ListLessonPageToken | undefined) => (
    <Flex justifyContent="center" pt={5}>
      <ButtonGroup isAttached>
        <Button
          isDisabled={pageToken.page <= 0}
          leftIcon={pageToken.page > 0 ? <FontAwesomeIcon icon={faChevronLeft} /> : undefined}
          onClick={() => setPage(pageToken.page - 1)}
          variant="ghost"
          colorScheme="blue"
        >
          Previous
        </Button>
        <Button
          rightIcon={nextPageToken ? <FontAwesomeIcon icon={faChevronRight} /> : undefined}
          isDisabled={!nextPageToken}
          onClick={() => nextPageToken && setPage(nextPageToken.page)}
          colorScheme="blue"
          variant="ghost"
        >
          Next
        </Button>
      </ButtonGroup>
    </Flex>
  );

  return { group, pageToken, getNavigation };
};
