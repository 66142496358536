import { Box, BoxProps } from '@chakra-ui/react';
import { PageContainerContext } from 'components/PageContainer';
import { HeaderGradient } from 'components/table/DataTable';
import debounce from 'lodash.debounce';
import React, { useEffect, useRef, useState } from 'react';

export const FixedHeightTableContainer = ({ children, ...props }: BoxProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [canScrollDown, setCanScrollDown] = useState(false);
  useEffect(() => {
    if (ref.current) {
      const container = ref.current;
      const callback = debounce(() => {
        const canScroll = container.scrollTop + container.clientHeight < container.scrollHeight;
        setCanScrollDown(canScroll);
      }, 10);

      callback();
      container.addEventListener('scroll', callback);
      return () => container.removeEventListener('scroll', callback);
    }
  }, [setCanScrollDown, ref.current]);

  return (
    <Box position="relative" borderRadius="md" overflow="hidden" boxShadow="elevationLow">
      <Box ref={ref} {...props}>
        <PageContainerContext.Provider
          value={{ scrollContainer: ref.current ?? undefined, outerRef: ref, isSubContainer: true }}
        >
          {children}
        </PageContainerContext.Provider>
      </Box>
      {canScrollDown && <HeaderGradient side="bottom" />}
    </Box>
  );
};
