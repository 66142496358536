export const urlKnowledgeBase = 'https://support.sparxscience.com/en/';
export const urlKnowledgeBaseArticlePrefix = `${urlKnowledgeBase}articles/`;
export const supportEmailAddress = 'schoolsupport@sparx.co.uk';

export const articleIDToURL = (id: string | number) => `${urlKnowledgeBaseArticlePrefix}${id}`;

export const articleDueDatesInHolidays = articleIDToURL(9128958);
export const articleTopicMigration = articleIDToURL(9828621);

export const articleIdHolidayDates = 9556394;
export const articleIdImportClasses = 8975434;
export const articleIdAddingStaff = 8917718;
export const articleIdKeyContacts = 8917743;
export const articleKeyContacts = articleIDToURL(articleIdKeyContacts);
export const articleIdConnectMIS = 9556400;
export const articleIdScheduleHW = 9556398;
export const articleIdCreateSoLs = 8917766;
export const articleIdStaffTraining = 9556392;

export const articleCancellingHomework = articleIDToURL(9827103);
