import styles from './ColourOverlay.module.css';

type ColourOverlayProps = {
  hexColour?: string;
};

export const ColourOverlay = ({ hexColour }: ColourOverlayProps) => (
  <div
    className={styles.ColourOverlay}
    style={{
      backgroundColor: hexColour || '#FFFFFF',
    }}
  />
);
