import {
  Assessment,
  GetAssessmentSummaryResponse_FixUpTask,
} from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { Package } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { usePackages } from 'api/packages';
import { isFuture } from 'date-fns';
import { useMemo } from 'react';
import * as anns from 'utils/annotations';

import { useAssessmentSummary } from '../../api/assessment';

export interface AssessmentFixUpTask extends GetAssessmentSummaryResponse_FixUpTask {
  package?: Package;
  assessment: Assessment;
}

export const useAssessmentFixUpTasks = ({ suspense }: { suspense: boolean }) => {
  const { data: packages } = usePackages({ suspense });
  const { data: assessmentSummary } = useAssessmentSummary({ suspense });

  return useMemo(() => {
    const seenAssessments = new Set<string>();
    const fixUpTasks = assessmentSummary?.fixUpTasks || [];
    return fixUpTasks?.reduce<AssessmentFixUpTask[]>((acc, task) => {
      const assessment = assessmentSummary?.assessments.find(
        assessment => assessment.name === task.assessmentName,
      );
      if (!assessment || seenAssessments.has(assessment.name)) {
        return acc;
      }
      seenAssessments.add(assessment.name);

      const pkg = packages?.find(
        pkg =>
          pkg.type === 'assessment-fixup' &&
          pkg.annotations[anns.PkgAssessmentName] === task.assessmentName &&
          (pkg.endTimestamp === undefined || isFuture(Timestamp.toDate(pkg.endTimestamp))),
      );

      acc.push({
        ...task,
        package: pkg,
        assessment,
      });
      return acc;
    }, []);
  }, [packages, assessmentSummary]);
};
