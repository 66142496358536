// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/packages/v1/assessment.proto" (package "sparx.science.packages.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Assessment } from './assessment';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { CreateFixUpPackageResponse } from './assessment';
import type { CreateFixUpPackageRequest } from './assessment';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.science.packages.v1.Assessment
 */
export interface IAssessmentClient {
  /**
   * @generated from protobuf rpc: CreateFixUpPackage(sparx.science.packages.v1.CreateFixUpPackageRequest) returns (sparx.science.packages.v1.CreateFixUpPackageResponse);
   */
  createFixUpPackage(
    input: CreateFixUpPackageRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateFixUpPackageRequest, CreateFixUpPackageResponse>;
}
/**
 * @generated from protobuf service sparx.science.packages.v1.Assessment
 */
export class AssessmentClient implements IAssessmentClient, ServiceInfo {
  typeName = Assessment.typeName;
  methods = Assessment.methods;
  options = Assessment.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: CreateFixUpPackage(sparx.science.packages.v1.CreateFixUpPackageRequest) returns (sparx.science.packages.v1.CreateFixUpPackageResponse);
   */
  createFixUpPackage(
    input: CreateFixUpPackageRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateFixUpPackageRequest, CreateFixUpPackageResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      CreateFixUpPackageRequest,
      CreateFixUpPackageResponse
    >('unary', this._transport, method, opt, input);
  }
}
