import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { usePasswordReset } from 'api/school';
import React from 'react';

interface StudentPasswordResetProps {
  studentID: string;
}

export const StudentPasswordReset = ({ studentID }: StudentPasswordResetProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);

  const { mutate, isLoading, isError } = usePasswordReset({ onSuccess: onClose });

  return (
    <>
      <Button onClick={onOpen} colorScheme="buttonTeal">
        Reset Password
      </Button>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} size="xl">
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Reset student password
            </AlertDialogHeader>
            <Box px={5}>
              {isError && (
                <Alert mb={2} status="error" borderRadius="md">
                  There was an error resetting the password. Please try again later.
                </Alert>
              )}
            </Box>
            <AlertDialogBody>
              <Text mb={2}>
                Resetting this student&apos;s password will allow them to choose a new password.
              </Text>
              <Text>
                After resetting the password you should instruct the student to visit{' '}
                <Link
                  color="blue.700"
                  href="https://app.sparxscience.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://app.sparxscience.com
                </Link>{' '}
                and use the &apos;<strong>Forgot login details?</strong>&apos; link to enter a new
                password.
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} isDisabled={isLoading}>
                Cancel
              </Button>
              <Button
                colorScheme="buttonTeal"
                onClick={() => mutate([studentID])}
                ml={3}
                isLoading={isLoading}
              >
                Reset student password
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
