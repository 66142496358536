import * as Dialog from '@radix-ui/react-dialog';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { useMisSyncContext } from '@sparx/mis-sync-import/src/Context';
import Button from '@sparx/mis-sync-import/src/MisSyncImport/components/common/Button/Button';
import { getSystemOptions } from '@sparx/mis-sync-import/src/utils';
import { simulateClickOnEnter } from '@sparx/react-utils/keyboard';
import { Cross } from '@sparx/sparx-design/icons';
import dialogStyles from '@sparx/sparx-design/shared-styles/Dialog.module.css';
import classNames from 'classnames';

import styles from './IdenticalClassesModal.module.css';

export const IdenticalClassesModal = ({
  onProceed,
  onClose,
}: {
  onProceed?: () => void;
  onClose: () => void;
}) => {
  const { groupSubject } = useMisSyncContext();
  return (
    <Dialog.Root open>
      <Dialog.Portal>
        <Dialog.Overlay className={classNames(styles.DialogOverlay, dialogStyles.DialogOverlay)} />
        <Dialog.Content className={classNames(dialogStyles.DialogContent, styles.Dialog)}>
          <button
            className={styles.CloseCrossButton}
            aria-label="Close conflicts dialog"
            onClick={onClose}
          >
            <Cross className={styles.CloseCross} variant="None" />
          </button>
          <Dialog.Title className={classNames(dialogStyles.DialogTitle, styles.DialogTitle)}>
            Your selection includes classes with identical students
          </Dialog.Title>
          <div
            data-theme={getSystemOptions(groupSubject).dataTheme}
            className={styles.ContentContainer}
          >
            {groupSubject === StudentGroupType.CLASS_SCIENCE ? (
              <p>
                <strong>We recommend that you deselect some classes.</strong>
              </p>
            ) : (
              <p>
                <strong>We recommend you go back and deselect classes</strong> with identical
                students, leaving only one class for import.
              </p>
            )}

            {groupSubject === StudentGroupType.CLASS_SCIENCE && (
              <p>
                For students taught in separate classes for Biology, Chemistry and Physics, import
                one of these classes. This class can be used to assign homework across all three
                subjects each week.
              </p>
            )}
          </div>
          <div className={styles.ButtonsContainer}>
            {onProceed !== undefined && (
              <Button
                className={styles.ProceedButton}
                as={Dialog.Close}
                onClick={onProceed}
                onKeyDown={simulateClickOnEnter}
                colour="secondary"
              >
                Proceed anyway
              </Button>
            )}

            <Button
              className={styles.CloseButton}
              as={Dialog.Close}
              onClick={onClose}
              onKeyDown={simulateClickOnEnter}
              colour="primary"
            >
              {!onProceed ? 'Close' : 'Go back'}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
