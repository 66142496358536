// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/schools/settings/v1/settings.proto" (package "sparx.science.schools.settings.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { TimeOfDay } from '../../../../../../google/type/timeofday';
import { DayOfWeek } from '../../../../../../google/type/dayofweek';
/**
 * @generated from protobuf message sparx.science.schools.settings.v1.StudentSettings
 */
export interface StudentSettings {
  /**
   * Name of the student these settings apply to. In the format:
   * `students/{student_id}`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Homework level for this student.
   *
   * @generated from protobuf field: string level = 2;
   */
  level: string;
  /**
   * Students course type.
   *
   * @generated from protobuf field: sparx.science.schools.settings.v1.Course course = 3;
   */
  course: Course;
  /**
   * Students course tier.
   *
   * @generated from protobuf field: sparx.science.schools.settings.v1.CourseTier tier = 4;
   */
  tier: CourseTier;
  /**
   * Students homework length.
   *
   * @generated from protobuf field: sparx.science.schools.settings.v1.HomeworkLength homework_length = 5;
   */
  homeworkLength: HomeworkLength;
  /**
   * Level override for this student. If this value is unset
   * then the level_automatic will be used. If level_automatic is
   * also unset, then the level value will be used.
   *
   * @generated from protobuf field: string level_override = 6;
   */
  levelOverride: string;
  /**
   * Algorithmically generated level for this student.
   * This setting cannot be updated by users, it can only be updated
   * by the 'Model.UpdateStudentLevels' endpoint.
   *
   * @generated from protobuf field: string level_automatic = 7;
   */
  levelAutomatic: string;
}
/**
 * @generated from protobuf message sparx.science.schools.settings.v1.GroupSettings
 */
export interface GroupSettings {
  /**
   * Name of the group these settings apply to. In the format:
   * `schools/{school_id}/studentGroups/{group_id}`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Day of the week the homework is set by default
   *
   * @generated from protobuf field: google.type.DayOfWeek default_set_day = 2;
   */
  defaultSetDay: DayOfWeek;
  /**
   * Time of the day the homework is set by default.
   *
   * @generated from protobuf field: google.type.TimeOfDay default_set_time = 3;
   */
  defaultSetTime?: TimeOfDay;
  /**
   * Day of the week the homework is due by default.
   *
   * @generated from protobuf field: google.type.DayOfWeek default_due_day = 4;
   */
  defaultDueDay: DayOfWeek;
  /**
   * Time of the day the homework is due by default.
   *
   * @generated from protobuf field: google.type.TimeOfDay default_due_time = 5;
   */
  defaultDueTime?: TimeOfDay;
  /**
   * Default homework length in minutes.
   *
   * @generated from protobuf field: int32 default_homework_length_minutes = 6;
   */
  defaultHomeworkLengthMinutes: number;
  /**
   * If empty then the group is not using a scheme of learning.
   *
   * @generated from protobuf field: string sol_name = 7;
   */
  solName: string;
}
/**
 * @generated from protobuf enum sparx.science.schools.settings.v1.Course
 */
export enum Course {
  /**
   * @generated from protobuf enum value: COURSE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: COURSE_COMBINED = 1;
   */
  COMBINED = 1,
  /**
   * @generated from protobuf enum value: COURSE_SEPARATE = 2;
   */
  SEPARATE = 2,
}
/**
 * @generated from protobuf enum sparx.science.schools.settings.v1.CourseTier
 */
export enum CourseTier {
  /**
   * @generated from protobuf enum value: LEVEL_UNSPECIFIED = 0;
   */
  LEVEL_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: LEVEL_FOUNDATION = 1;
   */
  LEVEL_FOUNDATION = 1,
  /**
   * @generated from protobuf enum value: LEVEL_HIGHER = 2;
   */
  LEVEL_HIGHER = 2,
}
/**
 * @generated from protobuf enum sparx.science.schools.settings.v1.HomeworkLength
 */
export enum HomeworkLength {
  /**
   * @generated from protobuf enum value: HOMEWORK_LENGTH_UNKNOWN = 0;
   */
  UNKNOWN = 0,
  /**
   * @generated from protobuf enum value: HOMEWORK_LENGTH_FULL = 1;
   */
  FULL = 1,
  /**
   * @generated from protobuf enum value: HOMEWORK_LENGTH_OFF = 2;
   */
  OFF = 2,
  /**
   * @generated from protobuf enum value: HOMEWORK_LENGTH_HALF = 3;
   */
  HALF = 3,
}
// @generated message type with reflection information, may provide speed optimized methods
class StudentSettings$Type extends MessageType<StudentSettings> {
  constructor() {
    super('sparx.science.schools.settings.v1.StudentSettings', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'level', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'course',
        kind: 'enum',
        T: () => [
          'sparx.science.schools.settings.v1.Course',
          Course,
          'COURSE_',
        ],
      },
      {
        no: 4,
        name: 'tier',
        kind: 'enum',
        T: () => ['sparx.science.schools.settings.v1.CourseTier', CourseTier],
      },
      {
        no: 5,
        name: 'homework_length',
        kind: 'enum',
        T: () => [
          'sparx.science.schools.settings.v1.HomeworkLength',
          HomeworkLength,
          'HOMEWORK_LENGTH_',
        ],
      },
      {
        no: 6,
        name: 'level_override',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'level_automatic',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.schools.settings.v1.StudentSettings
 */
export const StudentSettings = new StudentSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GroupSettings$Type extends MessageType<GroupSettings> {
  constructor() {
    super('sparx.science.schools.settings.v1.GroupSettings', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'default_set_day',
        kind: 'enum',
        T: () => ['google.type.DayOfWeek', DayOfWeek],
      },
      { no: 3, name: 'default_set_time', kind: 'message', T: () => TimeOfDay },
      {
        no: 4,
        name: 'default_due_day',
        kind: 'enum',
        T: () => ['google.type.DayOfWeek', DayOfWeek],
      },
      { no: 5, name: 'default_due_time', kind: 'message', T: () => TimeOfDay },
      {
        no: 6,
        name: 'default_homework_length_minutes',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 7, name: 'sol_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.schools.settings.v1.GroupSettings
 */
export const GroupSettings = new GroupSettings$Type();
