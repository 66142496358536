import {
  Box,
  BoxProps,
  GridItem,
  Link,
  ListItem,
  Spinner,
  Text,
  Tooltip,
  UnorderedList,
} from '@chakra-ui/react';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Assignment } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { UpdateGroupSettingsResponse } from '@sparx/api/apis/sparx/science/schools/v1/school';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { StaffClassMembership } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { readableStaffNames } from '@sparx/staff-manager';
import { useQueries } from '@tanstack/react-query';
import { scienceSchoolsClient } from 'api';
import { useAssignmentsByGroupName } from 'api/planner';
import { dateInWeek, useGroups, useHolidaysForSchoolYear, useWeeks } from 'api/school';
import { GroupWithSettings } from 'api/scienceschool';
import { getSchoolID } from 'api/sessions';
import { useStaffLookup } from 'api/staff';
import { useClientEvent } from 'components/ClientEventProvider';
import { Warning } from 'components/warning';
import { differenceInCalendarDays, differenceInSeconds, format } from 'date-fns';
import isequal from 'lodash.isequal';
import React, { useEffect, useMemo, useRef } from 'react';
import { articleDueDatesInHolidays } from 'utils/knowledgeBaseArticles';

const weekListThreshold = 5;

interface PlannerSettingsChangePreviewProps {
  groups: GroupWithSettings[];
  updatingSow?: boolean;
  useDraft?: boolean;
  setLoading?: (b: boolean) => void;
}

export const usePreviewUpdateGroupSettings = (groups: GroupWithSettings[], useDraft?: boolean) => {
  const queries = groups.map(group => ({
    queryKey: ['previewedAssignments', group.scienceSettings],
    queryFn: async () =>
      scienceSchoolsClient.updateGroupSettings({
        schoolName: 'schools/' + (await getSchoolID()),
        settings: [
          {
            ...group.scienceSettings,
            name: group.name,
          },
        ],
        preview: true,
        forceUpdateScheme: useDraft || false,
        useDraftScheme: useDraft || false,
      }).response,
    staleTime: Infinity, // don't reload automatically
    cacheTime: 0, // don't save if changed
    retry: 1, // retry once
  }));

  return useQueries({
    queries,
  });
};

type WeekNumberList = (number | '?')[];

interface GroupChanges {
  noChanges: boolean;
  addedWeeks: WeekNumberList;
  changedWeeks: WeekNumberList;
  deletedWeeks: WeekNumberList;
  ignoredWeeks: WeekNumberList;
  conflictWeeks: WeekNumberList;
  dueDateAdjWeeks: WeekNumberList;
  launchNowWeeks: WeekNumberList;
  nextHWSetDate: Timestamp | null;
  hwDays: number;
  nextHWDays: number | null;
  nextHWNoTopics: boolean;
  staffChanges: StaffChange[];
}

export const PlannerSettingsChangePreview = ({
  groups,
  updatingSow,
  useDraft,
  setLoading,
}: PlannerSettingsChangePreviewProps) => {
  const { data: groupsSchoolman } = useGroups({ suspense: false });
  const { data: assignmentsByGroup } = useAssignmentsByGroupName({ suspense: false });
  const { data: staffLookup } = useStaffLookup({ suspense: false });
  const previews = usePreviewUpdateGroupSettings(groups, useDraft);

  const { data: weeks } = useWeeks({ suspense: true });

  const groupChanges = useMemo(() => {
    if (groups.length === 0) {
      return [];
    }
    if (
      !assignmentsByGroup ||
      previews.some(v => v.isLoading) ||
      !groupsSchoolman ||
      !staffLookup
    ) {
      return 'loading';
    }
    if (previews.some(v => v.isError)) {
      return 'error';
    }

    const weekForAssignment = (assignment: Assignment) =>
      weeks?.find(
        w =>
          assignment?.startTimestamp && dateInWeek(w, Timestamp.toDate(assignment.startTimestamp)),
      )?.index || '?';

    const nowTS = Timestamp.now();

    // For each group create a summary of the changes
    const allSummaries = previews.map(({ data }, groupIdx) => {
      const group = groups[groupIdx];
      const assignments = assignmentsByGroup[group.name] || [];
      const changes = getAssignmentChanges(assignments, data);

      const hwDays =
        group.scienceSettings.defaultDueDay -
        group.scienceSettings.defaultSetDay +
        (group.scienceSettings.defaultDueDay <= group.scienceSettings.defaultSetDay ? 7 : 0);

      const toWeekNums = (assignments: Assignment[]): WeekNumberList => {
        const arr = assignments.map(a => weekForAssignment(a));
        arr.sort((a, b) => {
          if (a === b) return 0;
          if (a === '?' || b === '?') {
            return a === '?' ? 1 : -1;
          }
          return a - b;
        });
        return arr;
      };

      const addedWeeks = toWeekNums(changes.added);
      const changedWeeks = toWeekNums(changes.changed);
      const deletedWeeks = toWeekNums(changes.deleted);
      const ignoredWeeks = toWeekNums(changes.ignored);
      const conflictWeeks = toWeekNums(Array.from(changes.changedConflicts.values()));
      const dueDateAdjWeeks = toWeekNums(changes.dueDateHolAdjusted);
      const launchNowWeeks = toWeekNums(changes.launchNow);

      // Any staff membership changes?
      const staffChanges = getStaffChanges(
        groupsSchoolman.find(g => g.name === group.name)?.staff,
        group.staff,
      );

      return {
        group,
        changes: {
          noChanges: changes.noChanges,
          addedWeeks,
          changedWeeks,
          deletedWeeks,
          ignoredWeeks,
          conflictWeeks,
          dueDateAdjWeeks,
          launchNowWeeks,
          // If we are launching hw now, set the nextDate to now, rather than the one from
          // assignment which might not be exactly the same as other assignments to launch now
          nextHWSetDate: launchNowWeeks.length > 0 ? nowTS : changes.nextHWSetDate,
          hwDays,
          nextHWDays: changes.nextHWLength,
          nextHWNoTopics: data?.nextHomeworkHasNoTopics.includes(group.name) || false,
          staffChanges,
        },
      };
    });

    // Combine the groups where changes are the same.
    const groupedChanges: { changes: GroupChanges; groupDisplayNames: string[] }[] = [];
    for (const s of allSummaries) {
      const found = groupedChanges.find(v => isequal(v.changes, s.changes));
      if (found) {
        found.groupDisplayNames.push(s.group.displayName);
        found.groupDisplayNames.sort();
      } else {
        groupedChanges.push({ changes: s.changes, groupDisplayNames: [s.group.displayName] });
      }
    }

    return groupedChanges;
  }, [weeks, groups, previews, assignmentsByGroup, groupsSchoolman, staffLookup]);

  // Callback to let higher up know we have finished loading data
  useEffect(() => {
    setLoading?.(groupChanges === 'loading' || groupChanges === 'error');
  }, [setLoading, groupChanges]);

  if (groupChanges === 'loading') {
    return (
      <GridItem colSpan={2}>
        <Spinner size="xs" color="teal" mr={2} mt={4} />
        Calculating changes...
      </GridItem>
    );
  }

  if (groupChanges === 'error') {
    return (
      <GridItem colSpan={2} mt={4}>
        <Warning status="error">There was an error generating the preview.</Warning>
      </GridItem>
    );
  }

  const showHWLength = (changes: GroupChanges) => {
    let days = changes.hwDays;
    let typical: number | undefined = undefined;
    if (changes.nextHWDays && changes.nextHWDays !== changes.hwDays) {
      days = changes.nextHWDays;
      typical = changes.hwDays;
    }

    return (
      <>
        Students will have{' '}
        <strong>
          {days} day{days !== 1 && 's'}
        </strong>{' '}
        to complete their homework.
        {typical && (
          <>
            {' '}
            Typically they will have{' '}
            <strong>
              {typical} day{typical !== 1 && 's'}
            </strong>
            .
          </>
        )}
      </>
    );
  };

  return (
    <>
      {groupChanges.map(({ groupDisplayNames, changes }, idx) => (
        <React.Fragment key={idx}>
          <Text fontWeight="bold" mt={4} mb={2}>
            {groupDisplayNames.join(', ')}
          </Text>
          <GridItem colSpan={2}>
            {changes.nextHWNoTopics && changes.nextHWSetDate && (
              <Warning status="warning" title={<strong>The next homework will not be set</strong>}>
                The next homework{' '}
                {changes.launchNowWeeks.length === 0 ? (
                  <>
                    is scheduled for{' '}
                    <strong>
                      {format(Timestamp.toDate(changes.nextHWSetDate), "EEEE do MMMM 'at' HH:mm")}
                    </strong>
                  </>
                ) : (
                  <>
                    would be set <strong>immediately</strong> as hand-out for this week has passed
                  </>
                )}{' '}
                but there are no topics scheduled. We recommend adding topics to{' '}
                <WeekDisplay
                  week={
                    weeks?.find(w => dateInWeek(w, Timestamp.toDate(changes.nextHWSetDate!)))
                      ?.index || '?'
                  }
                />{' '}
                in the scheme.
              </Warning>
            )}
            {changes.launchNowWeeks.length === 0 &&
              changes.nextHWSetDate &&
              !changes.nextHWNoTopics && (
                <Warning>
                  The next homework will be set on{' '}
                  <strong>
                    {format(Timestamp.toDate(changes.nextHWSetDate), "EEEE do MMMM 'at' HH:mm")}
                  </strong>
                  .
                  <br />
                  {showHWLength(changes)}
                </Warning>
              )}
            {changes.launchNowWeeks.length > 0 && !changes.nextHWNoTopics && (
              <Warning status="warning">
                The hand-out for this week has passed and so this week's{' '}
                <strong>homework will be set immediately</strong>.
                <br />
                If you don't want homework to be set this week, turn the{' '}
                <WeekDisplay week={changes.launchNowWeeks[0]} /> homework off in{' '}
                {!updatingSow && 'Planner or'} the Scheme of Learning before applying this change.
              </Warning>
            )}
            <DueDateHolAdjustedWeeks weeks={changes.dueDateAdjWeeks} />
            {changes.addedWeeks.length > 0 &&
              (changes.addedWeeks.length > weekListThreshold ? (
                <Warning status="success">
                  Homework will be turned <strong>on</strong> for{' '}
                  <WeeksTooltip
                    weeks={changes.addedWeeks}
                    text={`${changes.addedWeeks.length} weeks`}
                  />
                  .
                </Warning>
              ) : (
                <Warning
                  status="success"
                  title={
                    <>
                      Homework will be turned <strong>on</strong> for the following week
                      {changes.addedWeeks.length === 1 ? '' : 's'}:
                    </>
                  }
                >
                  <WeekListDisplay weeks={changes.addedWeeks} />
                </Warning>
              ))}
            {changes.changedWeeks.length > 0 &&
              (changes.changedWeeks.length > weekListThreshold ? (
                <Warning status="success">
                  Settings will be updated for{' '}
                  <WeeksTooltip
                    weeks={changes.changedWeeks}
                    text={`${changes.changedWeeks.length} weeks`}
                  />
                  .
                </Warning>
              ) : (
                <Warning
                  status="success"
                  title={`Settings will be updated for the following week${
                    changes.changedWeeks.length !== 1 ? 's' : ''
                  }:`}
                >
                  <WeekListDisplay weeks={changes.changedWeeks} />
                </Warning>
              ))}
            {changes.conflictWeeks.length > 0 &&
              (changes.conflictWeeks.length > weekListThreshold ? (
                <Warning status="warning">
                  Previous changes to{' '}
                  <WeeksTooltip
                    weeks={changes.conflictWeeks}
                    text={`${changes.conflictWeeks.length} weeks`}
                  />{' '}
                  will be overridden.
                </Warning>
              ) : (
                <Warning
                  title={`Previous changes to the following week${
                    changes.conflictWeeks.length === 1 ? '' : 's'
                  } will be overridden:`}
                  status="warning"
                >
                  <WeekListDisplay weeks={changes.conflictWeeks} />
                </Warning>
              ))}
            {changes.deletedWeeks.length > 0 &&
              (changes.deletedWeeks.length > weekListThreshold ? (
                <Warning status="warning">
                  Homework will be turned <strong>off</strong> for{' '}
                  <WeeksTooltip
                    weeks={changes.deletedWeeks}
                    text={`${changes.deletedWeeks.length} weeks`}
                  />
                  .
                </Warning>
              ) : (
                <Warning
                  title={
                    <>
                      Homework will be turned <strong>off</strong>{' '}
                      {changes.deletedWeeks.length === 1 ? 'for this week' : 'in these weeks'}:
                    </>
                  }
                  status="warning"
                >
                  <WeekListDisplay weeks={changes.deletedWeeks} />
                </Warning>
              ))}
            {changes.ignoredWeeks.length > 0 &&
              (changes.ignoredWeeks.length > weekListThreshold ? (
                <Warning>
                  <WeeksTooltip
                    weeks={changes.ignoredWeeks}
                    text={`${changes.ignoredWeeks.length} homework weeks`}
                  />{' '}
                  will <strong>not</strong> be changed.
                </Warning>
              ) : (
                <Warning
                  title={
                    <>
                      {changes.ignoredWeeks.length === 1 ? 'This' : 'These'} homework week
                      {changes.ignoredWeeks.length === 1 ? '' : 's'} will <strong>not</strong> be
                      changed:
                    </>
                  }
                >
                  <WeekListDisplay weeks={changes.ignoredWeeks} />
                </Warning>
              ))}
            {changes.noChanges && (
              <Warning status="success">No changes to future homework.</Warning>
            )}
            {changes.staffChanges.length > 0 && (
              <Warning title="The following teacher associations will be changed:">
                <UnorderedList>
                  {changes.staffChanges
                    .map(c => ({
                      action: c.action,
                      displayName: (() => {
                        const { realName, fallback } = readableStaffNames(staffLookup?.[c.id]);
                        return realName || fallback;
                      })(),
                    }))
                    .sort((a, b) =>
                      a.action !== b.action
                        ? a.action === 'Add'
                          ? -1
                          : 1
                        : a.displayName.localeCompare(b.displayName),
                    )
                    .map((c, i) => (
                      <ListItem key={i}>
                        {c.action} <strong data-sentry-mask>{c.displayName}</strong>
                      </ListItem>
                    ))}
                </UnorderedList>
              </Warning>
            )}
          </GridItem>
        </React.Fragment>
      ))}
    </>
  );
};

const WeekListDisplay = ({ weeks }: { weeks: WeekNumberList }) => {
  return (
    <Box>
      {weeks.map((w, i) => (
        <WeekDisplay key={i} week={w} mr={1} mt={1} />
      ))}
    </Box>
  );
};

const WeekDisplay = ({ week, ...props }: BoxProps & { week: number | '?' }) => (
  <Box
    display="inline-block"
    px={2}
    py={0.5}
    bg="whiteAlpha.600"
    borderRadius="md"
    fontSize="sm"
    {...props}
  >
    Week {week}
  </Box>
);

const WeeksTooltip = ({ weeks, text }: { weeks: WeekNumberList; text: string }) => (
  <Tooltip
    p={2}
    fontSize="md"
    border="1px solid #efefef"
    borderRadius="md"
    mb={-2}
    hasArrow
    backgroundColor="white"
    placement="top"
    color="inherit"
    label={<WeekListDisplay weeks={weeks} />}
  >
    <Text as="span" decoration="underline">
      {text}
    </Text>
  </Tooltip>
);

const getTopicSet = (assignment: Assignment) => {
  if (assignment.spec?.contents.oneofKind === 'generatedAssignment')
    return new Set(assignment.spec?.contents.generatedAssignment.topics.map(t => t.name) || []);
  return new Set<string>();
};

const getAssignmentChanges = (existing: Assignment[], preview?: UpdateGroupSettingsResponse) => {
  const existingAssignments: Dictionary<string, Assignment> = {};
  for (const assignment of existing) {
    existingAssignments[assignment.name] = assignment;
  }
  const added: Assignment[] = [];
  const changed: Assignment[] = [];
  const dueDateHolAdjusted: Assignment[] = [];
  const launchNow: Assignment[] = [];

  const changedConflicts = new Set<Assignment>();

  const changedAssignments = preview?.assignmentChanges;

  let nextHWSetDate: Timestamp | null = null;
  let nextHWDueDate: Timestamp | null = null;

  const updateNextHWSetDate = (assignment: Assignment) => {
    if (!assignment.generatedTimestamp && assignment.startTimestamp && assignment.endTimestamp) {
      if (!nextHWSetDate) {
        nextHWSetDate = assignment.startTimestamp;
        nextHWDueDate = assignment.endTimestamp;
      } else if (assignment.startTimestamp.seconds < nextHWSetDate.seconds) {
        nextHWSetDate = assignment.startTimestamp;
        nextHWDueDate = assignment.endTimestamp;
      }
    }
  };

  const checkAndAddOtherChanges = (assignment: Assignment) => {
    if (changedAssignments?.dueDateAdjustedNames.includes(assignment.name)) {
      dueDateHolAdjusted.push(assignment);
    }
    if (changedAssignments?.launchImmediatelyNames.includes(assignment.name)) {
      launchNow.push(assignment);
    }
  };

  for (const assignment of changedAssignments?.ignored || []) {
    // Find the next HW set date
    updateNextHWSetDate(assignment);
  }

  for (const assignment of changedAssignments?.updated || []) {
    // Find the next HW set date
    updateNextHWSetDate(assignment);

    const existing = existingAssignments[assignment.name];
    if (!existing) {
      added.push(assignment);
      checkAndAddOtherChanges(assignment);
      continue;
    }
    const changedFields = (existing.annotations['changed'] || '').split(',');
    const editedDates =
      changedFields.includes('startTimestamp') || changedFields.includes('endTimestamp');
    const editedLength = changedFields.includes('length');
    const editedTopics = changedFields.includes('topics');

    let hasChanged = false;
    if (
      assignment.startTimestamp?.seconds !== existing.startTimestamp?.seconds ||
      assignment.endTimestamp?.seconds !== existing.endTimestamp?.seconds
    ) {
      hasChanged = true;
      if (editedDates) {
        changedConflicts.add(assignment);
      }
    }
    if (
      assignment.spec?.contents.oneofKind === 'generatedAssignment' &&
      existing.spec?.contents.oneofKind === 'generatedAssignment'
    ) {
      if (
        assignment.spec.contents.generatedAssignment.lengthMinutes !==
        existing.spec?.contents.generatedAssignment.lengthMinutes
      ) {
        hasChanged = true;
        if (editedLength) {
          changedConflicts.add(assignment);
        }
      }

      // Check if the topics have changed
      const existingTopics = getTopicSet(existing);
      const newTopics = getTopicSet(assignment);
      const topicsEqual =
        existingTopics.size === newTopics.size && [...existingTopics].every(x => newTopics.has(x));

      if (!topicsEqual) {
        hasChanged = true;
        if (editedTopics) {
          changedConflicts.add(assignment);
        }
      }
    }

    if (hasChanged) {
      checkAndAddOtherChanges(assignment);
      changed.push(assignment);
    }
  }

  const deleted =
    changedAssignments?.deletedNames
      ?.map(name => existingAssignments[name])
      .filter((a): a is Assignment => !!a) || [];
  const ignored = changedAssignments?.ignored || [];

  const noChanges =
    added.length === 0 &&
    changed.length === 0 &&
    changedConflicts.size === 0 &&
    deleted.length === 0 &&
    ignored.length === 0;

  let nextHWLength: number | null = null;
  if (nextHWSetDate && nextHWDueDate) {
    const set = Timestamp.toDate(nextHWSetDate);
    const due = Timestamp.toDate(nextHWDueDate);
    nextHWLength = differenceInCalendarDays(due, set);
  }

  return {
    added,
    changed,
    deleted,
    ignored,
    changedConflicts,
    noChanges,
    nextHWSetDate,
    nextHWLength,
    dueDateHolAdjusted,
    launchNow,
  };
};

const DueDateHolAdjustedWeeks = ({ weeks }: { weeks: WeekNumberList }) => {
  if (weeks.length === 0) {
    return null;
  }

  const moreInfo = (
    <Text fontSize="xs" mt={1}>
      More information about how to set school holidays and their impact on Sparx{' '}
      <Link href={articleDueDatesInHolidays} isExternal textDecoration="underline">
        here
        <FontAwesomeIcon style={{ marginLeft: '2px' }} icon={faExternalLink} />
      </Link>
    </Text>
  );

  const textBefore = 'We have delayed the due date for';

  return weeks.length > weekListThreshold ? (
    <Warning status="info">
      {textBefore} <WeeksTooltip weeks={weeks} text={`${weeks.length} homeworks`} /> so that they
      aren&apos;t due during school holidays.
      <>{moreInfo}</>
    </Warning>
  ) : (
    <Warning
      status="info"
      title={
        <>
          {textBefore} {weeks.length === 1 ? <WeekDisplay week={weeks[0]} /> : 'these weeks'} so
          that {weeks.length === 1 ? "it isn't" : "they aren't"} due during school holidays
          {weeks.length === 1 ? '.' : ':'}
        </>
      }
    >
      {weeks.length > 1 && <WeekListDisplay weeks={weeks} />}
      {moreInfo}
    </Warning>
  );
};

export const NoHolidaysWarning = ({ yearIndex = '0' }: { yearIndex?: string }) => {
  // Get holidays for the current year
  const { data: holidays } = useHolidaysForSchoolYear(yearIndex, { suspense: false });

  if (!holidays || holidays.length > 0) {
    return null;
  }

  return (
    <Warning status="warning" mb={4}>
      No school holidays are set. We recommend setting school holidays before assigning schemes of
      learning.{' '}
      <Text fontSize="xs" mt={1}>
        More information{' '}
        <Link href={articleDueDatesInHolidays} isExternal textDecoration="underline">
          here
          <FontAwesomeIcon style={{ marginLeft: '2px' }} icon={faExternalLink} />
        </Link>
      </Text>
    </Warning>
  );
};

export const useSettingsChangeAnalytics = (
  isLoading: boolean,
  category: string,
  actionPrefix: string,
  groups: GroupWithSettings[],
) => {
  const { sendEvent } = useClientEvent();

  const changesFirstVisible = useRef<Date | null>(null);
  useEffect(() => {
    if (!isLoading && !changesFirstVisible.current) {
      // Loading has finished so data should now be visible
      changesFirstVisible.current = new Date();
    }
  }, [isLoading]);

  const sendAnalytics = (submit: boolean) => {
    sendEvent(
      {
        action: actionPrefix + (submit ? 'submit' : 'cancel'),
        category,
      },
      {
        groups: groups.map(g => g.name.split('/')[3]).join(','),
        seconds_open: changesFirstVisible.current
          ? differenceInSeconds(new Date(), changesFirstVisible.current).toString()
          : 'no time',
      },
    );
  };

  return sendAnalytics;
};

interface StaffChange {
  action: 'Add' | 'Remove';
  id: string;
}

const getStaffChanges = (
  old: StaffClassMembership[] = [],
  newStaff: StaffClassMembership[] = [],
) => {
  const changes = newStaff
    .filter(s => old.find(gs => gs.staffID === s.staffID) === undefined)
    .map<StaffChange>(s => ({
      action: 'Add',
      id: s.staffID,
    }));

  return changes.concat(
    old
      .filter(s => newStaff.find(gs => gs.staffID === s.staffID) === undefined)
      .map<StaffChange>(s => ({
        action: 'Remove',
        id: s.staffID,
      })),
  );
};
