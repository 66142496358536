import { useQuery } from '@tanstack/react-query';
import { independentLearningClient } from 'api';

import { Options } from './school';
import { getSchoolID } from './sessions';

export const useIndependentLearningUserState = (options: Options) =>
  useQuery({
    queryKey: ['independentlearning', 'userstate'],
    queryFn: () => independentLearningClient.getIndependentLearningState({}).response,
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });

export const useIndependentLearningSummaries = (assignmentID: string, options: Options) =>
  useQuery({
    queryKey: ['independentlearning', 'assignment', assignmentID, 'summaries'],
    queryFn: async () =>
      independentLearningClient.listIndependentLearningSummaries({
        schoolName: `schools/${await getSchoolID()}`,
        query: {
          oneofKind: 'assignmentName',
          assignmentName: `assignments/${assignmentID}`,
        },
      }).response,
    ...options,
  });
