import { CheckIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { SentimentRating } from '@sparx/api/apis/sparx/interaction/feedback/v1/feedback';
import { Button } from '@sparx/sparx-design/components';
import { Checkbox, CheckedState } from '@sparx/sparx-design/components/checkbox/Checkbox';
import classNames from 'classnames';
import React, {
  CSSProperties,
  FormEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

import styles from './FeedbackForm.module.css';
import { ReactComponent as SentimentDislike } from './icons/sentiment-dislike.svg';
import { ReactComponent as SentimentHate } from './icons/sentiment-hate.svg';
import { ReactComponent as SentimentLike } from './icons/sentiment-like.svg';
import { ReactComponent as SentimentLove } from './icons/sentiment-love.svg';
import { ReactComponent as SentimentNeutral } from './icons/sentiment-neutral.svg';

const sentimentLevels = [
  {
    Icon: SentimentHate,
    color: 'var(--colours-sentiment-hate)',
    label: 'Hate',
    value: SentimentRating.HATE,
  },
  {
    Icon: SentimentDislike,
    color: 'var(--colours-sentiment-dislike)',
    label: 'Dislike',
    value: SentimentRating.DISLIKE,
  },
  {
    Icon: SentimentNeutral,
    color: 'var(--colours-sentiment-neutral)',
    label: 'Neutral',
    value: SentimentRating.NEUTRAL,
  },
  {
    Icon: SentimentLike,
    color: 'var(--colours-sentiment-like)',
    label: 'Like',
    value: SentimentRating.LIKE,
  },
  {
    Icon: SentimentLove,
    color: 'var(--colours-sentiment-love)',
    label: 'Love',
    value: SentimentRating.LOVE,
  },
];

export type SubmitData = {
  comment: string;
  sentiment: SentimentRating;
};

type FeedbackFormProps = {
  prompt: ReactNode;
  onSubmit: (data: SubmitData) => void;
  isSubmitted?: boolean;
  isSubmitting?: boolean;
  submitError?: string;
  consentText?: string;
  // if true, shows the success message instead of allowing the user to submit more feedback
  isFeedbackThrottled?: boolean;
  additionalSuccessText?: string;
  placeholderText?: string;

  hideSentimentChoice?: boolean;

  // Don't show a submit button, instead the parent is expected to implement one
  // themselves by using 'formIncompleteCallback' and the 'submit' function on the form ref
  hideSubmitButton?: boolean;
  // Callback used to pass back up whether the form in incomplete or not
  formIncompleteCallback?: (formIncomplete: boolean) => void;
};

export type FeedbackFormHandle = {
  submit: () => void;
};

export const FeedbackForm = React.forwardRef<FeedbackFormHandle, FeedbackFormProps>(
  (
    {
      consentText,
      prompt,
      onSubmit,
      isSubmitting,
      isSubmitted,
      submitError,
      isFeedbackThrottled,
      additionalSuccessText,
      placeholderText,
      hideSentimentChoice,
      hideSubmitButton,
      formIncompleteCallback,
    },
    ref,
  ) => {
    const [sentiment, setSentiment] = useState<SentimentRating>(SentimentRating.UNKNOWN);
    const [comment, setComment] = useState('');
    const [consent, setConsent] = useState(() => consentText === undefined);

    const submitDisabled =
      (!hideSentimentChoice && sentiment === SentimentRating.UNKNOWN) || !consent || !comment;

    const doSubmit = useCallback(() => {
      if (submitDisabled) {
        return;
      }

      onSubmit({
        sentiment,
        comment,
      });
    }, [submitDisabled, onSubmit, sentiment, comment]);

    // Expose the submit function to parents via the reference
    React.useImperativeHandle(
      ref,
      () => ({
        submit: doSubmit,
      }),
      [doSubmit],
    );

    useEffect(() => {
      if (formIncompleteCallback) {
        formIncompleteCallback(submitDisabled);
      }
    }, [formIncompleteCallback, submitDisabled]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      doSubmit();
    };

    const sentimentOptions = sentimentLevels.map(({ color, Icon, label, value }) => (
      <button
        key={label}
        className={styles.SentimentButton}
        tabIndex={0}
        onClick={() => setSentiment(value)}
        role="radio"
        aria-label={label}
        aria-checked={`${sentiment === value}`}
        type="button"
      >
        <Icon
          className={classNames(styles.SentimentIcon, {
            [styles.Selected]: sentiment === value,
          })}
          style={{ '--sentiment-color': color } as CSSProperties}
        />
      </button>
    ));

    const formElements = (
      <form onSubmit={handleSubmit} aria-label="Feedback form">
        <div className={styles.Prompt}>{prompt}</div>
        {!hideSentimentChoice && (
          <div
            className={styles.SentimentContainer}
            role="radiogroup"
            aria-required="true"
            aria-label="How do you feel about Sparx Maths?"
          >
            {sentimentOptions}
          </div>
        )}
        <textarea
          className={styles.Textarea}
          name="comment"
          onChange={e => setComment(e.currentTarget.value)}
          disabled={isSubmitted}
          aria-label="Comments"
          placeholder={placeholderText}
        ></textarea>
        {consentText && (
          <div className={styles.CheckboxContainer}>
            <Checkbox
              id="consent"
              name="consent"
              label={consentText}
              labelPosition="right"
              checked={consent}
              onCheckedChange={(s: CheckedState) => setConsent(s === true)}
              disabled={isSubmitted}
            />
          </div>
        )}
        {!hideSubmitButton && (
          <Button
            variant="contained"
            rightIcon={<ChevronRightIcon height={20} width={20} />}
            className={styles.Button}
            isDisabled={submitDisabled}
            isLoading={isSubmitting}
          >
            Send
          </Button>
        )}
      </form>
    );

    const successMessage = (
      <div>
        <p className={styles.Success}>
          <span>Thank you for your feedback</span>
          <CheckIcon width={25} height={30} />
        </p>
        {additionalSuccessText && <p className={styles.SuccessLimit}>{additionalSuccessText}</p>}
      </div>
    );

    return (
      <section className={styles.FeedbackCard}>
        {isSubmitted || isFeedbackThrottled ? successMessage : formElements}
        {submitError && <p className={styles.Error}>{submitError}</p>}
      </section>
    );
  },
);
