import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Input,
  Link as ChakraLink,
  ListItem,
  Text,
  UnorderedList,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  faArrowUpRightFromSquare,
  faCheck,
  faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetMISStatusResponse } from '@sparx/api/apis/sparx/teacherportal/schoolstatus/v1/schoolstatus';
import { useMutation } from '@tanstack/react-query';
import { useQueryState } from 'api/client';
import { useCompleteRollover } from 'api/schoolactions';
import { useIsInRolloverInterim } from 'api/schoolcalendar';
import { useSchoolMISStatus } from 'api/schoolstatus';
import { useUserType } from 'api/sessions';
import { useClientEvent } from 'components/ClientEventProvider';
import { LargeLoading } from 'components/loading/LargeLoading';
import { Warning } from 'components/warning';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { supportEmailAddress } from 'utils/knowledgeBaseArticles';
import { plural } from 'utils/plural';

import { IsRolloverProps, PageTab, TabDetails, TabStatus } from '..';
import { ActionBox } from '../components/ActionBox';
import { StatusIcon } from '../components/Tab';
import { useYearToReference } from '../hooks';
import { MISWondePath, useMISStatus } from './MIS';

const title = 'Reset Sparx Science';

const Page = (_: IsRolloverProps) => {
  const data = useMISResetStatus({ suspense: true });

  return (
    <>
      <Text mb={6}>
        Reset your Sparx Science site for the new academic year to remove last year&apos;s classes.
        You won&apos;t be able to do this until we can see the new class lists in your MIS.
      </Text>
      {data.status === 'error' ? (
        <ErrorState />
      ) : data.status === 'not-ready' ? (
        <NotReadyState data={data} />
      ) : data.status === 'ready' ? (
        <ReadyState data={data} />
      ) : data.status === 'complete' ? (
        <CompleteState />
      ) : (
        <LargeLoading />
      )}
    </>
  );
};

const CompleteState = () => {
  return (
    <ActionBox
      heading="Your Sparx Science site has been reset!"
      bgColor="green.50"
      faIcon={faCheck}
    >
      <Text as="span" fontSize="sm">
        We suggest{' '}
        <ChakraLink
          as={Link}
          to="/teacher/new-year-setup/import"
          textDecoration="underline"
          fontWeight="bold"
        >
          Importing your Classes & Students
        </ChakraLink>{' '}
        as soon as possible
      </Text>
    </ActionBox>
  );
};

const ReadyState = ({ data }: { data: GetMISStatusResponse }) => {
  return (
    <>
      <Flex borderWidth={1} borderRadius="md" py={6} pr={6} pl={4}>
        <Box>
          <Text mb={4}>
            The data in your MIS <strong>is ready</strong> for the new academic year, so you can go
            ahead and reset Sparx Science.
          </Text>
          <Text mb={1}>Here&apos;s how we know:</Text>
          <Text fontStyle="italic">
            Of the {data.sparxStudents} {plural(data.sparxStudents, 'student')} who were using Sparx
            Science last year, {data.movedStudents} {plural(data.sparxStudents, 'has', 'have')} been
            moved into their new classes in your MIS. This is a high percentage of students which
            suggests your new classes have been set up.
          </Text>
        </Box>
      </Flex>
      <ResetButton ready={true} />
    </>
  );
};

const NotReadyState = ({ data }: { data: GetMISStatusResponse }) => {
  return (
    <>
      <Flex bgColor="orange.100" borderRadius="md" py={6} pr={6} pl={4}>
        <Box mr={4}>
          <StatusIcon status="error" size="xl" />
        </Box>
        <Box>
          <Text mb={4}>
            The data in your MIS is not ready for the new academic year and so you will not be able
            to reset yet.
          </Text>
          <Text mb={1}>Here&apos;s how we know:</Text>
          <Text fontStyle="italic">
            Of the {data.sparxStudents} {plural(data.sparxStudents, 'student')} who were using Sparx
            Science last year, {data.movedStudents} {plural(data.sparxStudents, 'has', 'have')} been
            moved into their new classes in your MIS. This is a low percentage of students which
            suggests your new classes have not been set up.
          </Text>
        </Box>
      </Flex>
      <ResetButton ready={false} />
    </>
  );
};

const CONFIRMATION_PHRASE = 'reset';
const ResetButton = ({ ready }: { ready: boolean }) => {
  const { sendEvent } = useClientEvent();
  const toast = useToast();
  const { isSparxStaff } = useUserType();
  const [canReset, setCanReset] = useState(ready);
  const [hadError, setHadError] = useQueryState('school-year-reset-errored', false);

  //  Confirmation dialog state
  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => {
      sendEvent({ category: 'new-year-setup', action: 'open-reset-confirmation' });
    },
    onClose: () => {
      sendEvent({ category: 'new-year-setup', action: 'close-reset-confirmation' });
    },
  });
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const [confirmationPhrase, setConfirmationPhrase] = useState('');

  const { mutateAsync: completeRollover } = useCompleteRollover();

  const { mutateAsync: doReset, isLoading } = useMutation({
    mutationFn: async () =>
      completeRollover(undefined, {
        onSuccess: async () => {
          sendEvent({ category: 'new-year-setup', action: 'do-reset' }, {}, { immediate: true });
          // Reload page, leave the dialog open until that happens
          window.location.reload();
          await new Promise(() => {});
        },
        onError: () => {
          setHadError(true);
          toast({
            status: 'error',
            duration: null,
            isClosable: true,
            title: 'Error resetting Sparx Science',
            description: 'Please try again or contact support.',
          });
        },
      }),
  });

  return (
    <>
      {hadError ? (
        <Warning status="error" mt={6} title="Error resetting Sparx Science">
          Please refresh this page to try again or{' '}
          <ChakraLink href={`mailto:${supportEmailAddress}`} textDecoration="underline">
            get in touch
          </ChakraLink>{' '}
          if you are still having problems.
        </Warning>
      ) : (
        <Box>
          <Box>
            {!ready && isSparxStaff && (
              <Checkbox
                mt={6}
                isChecked={canReset}
                onChange={e => {
                  setCanReset(e.target.checked);
                }}
              >
                [Sparx Staff] I understand the MIS may not be ready, reset Sparx Science anyway.
              </Checkbox>
            )}
          </Box>
          <Button
            colorScheme="buttonTeal"
            mt={6}
            isDisabled={!canReset}
            onClick={() => {
              if (canReset) onOpen();
            }}
          >
            Reset Sparx Science
          </Button>
        </Box>
      )}
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} size="xl">
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Reset your Sparx Science site
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text>Are you sure you want to reset your Sparx Science site?</Text>
              <UnorderedList mt={2}>
                <ListItem>
                  Last year&apos;s classes will be deleted from Sparx Science. We&apos;ll still
                  remember your students&apos; settings.
                </ListItem>
                <ListItem>
                  Students won&apos;t be able to log in until their new classes are imported.
                </ListItem>
                <ListItem>You will no longer have access to data from last academic year.</ListItem>
              </UnorderedList>
              <Warning
                status="warning"
                title={<strong>This action cannot be reversed</strong>}
                my={4}
              >
                When you are ready, please type{' '}
                <Text
                  as="span"
                  p={1}
                  borderRadius="sm"
                  bg="gray.100"
                  fontFamily="monospace"
                  fontWeight="bold"
                >
                  {CONFIRMATION_PHRASE}
                </Text>{' '}
                and click the button below to proceed.
              </Warning>
              <Input
                value={confirmationPhrase}
                onChange={ev => setConfirmationPhrase(ev.target.value)}
              />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} isDisabled={isLoading}>
                Cancel
              </Button>
              <Button
                colorScheme="buttonTeal"
                onClick={() => doReset().then(onClose)}
                ml={3}
                isLoading={isLoading}
                isDisabled={confirmationPhrase.toLowerCase() !== CONFIRMATION_PHRASE}
              >
                Reset our Sparx Science site
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const ErrorState = () => {
  const { isWondeAuthorised, statusDescription } = useMISStatus({ suspense: true });

  return (
    <Flex bgColor="orange.100" borderRadius="md" py={6} pr={6} pl={4}>
      <Box mr={4}>
        <StatusIcon status="error" size="xl" />
      </Box>
      <Box>
        <Heading size="md" mb={2}>
          We weren&apos;t able to connect to your MIS so you can&apos;t reset your Sparx Science
          site yet.
        </Heading>
        <Flex>
          <Text>
            Please try again by refreshing this page, or{' '}
            <ChakraLink
              onClick={() => {
                window.Intercom?.('showNewMessage');
              }}
              textDecoration="underline"
            >
              get in touch
            </ChakraLink>{' '}
            if you are still having problems.
            {!isWondeAuthorised && (
              <>
                <br />
                <strong>MIS Status:</strong> {statusDescription}
              </>
            )}
          </Text>
          <Button
            as={Link}
            to={MISWondePath}
            target={'_blank'}
            rel={'external noopener'}
            ml={4}
            colorScheme="buttonTeal"
            bgColor="white"
            variant="outline"
            minWidth={44}
            flexShrink={0}
            rightIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} fixedWidth />}
          >
            Connect your MIS
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

const useTabDetails = (_: IsRolloverProps): TabDetails => {
  const { status: misStatus } = useMISResetStatus({ suspense: false });

  let contentBefore: React.ReactNode = undefined;

  let status: TabStatus | undefined;
  let subtitle: string | undefined;
  if (misStatus === 'before') {
    status = 'locked';
    subtitle = 'In the new academic year';
    contentBefore = (
      <Flex justifyContent="center" alignItems="center" px={2} pt={3} pb={1}>
        <Divider />
        <Text fontSize={{ base: 'xs', lg: 'sm' }} whiteSpace="nowrap" px={2}>
          In the new academic year
        </Text>
        <Divider />
      </Flex>
    );
  } else if (misStatus === 'complete') {
    status = 'complete';
  } else if (misStatus === 'loading') {
    status = 'loading';
  } else if (misStatus !== 'ready') {
    status = 'error';
    subtitle = 'Not ready';
  } else if (misStatus === 'ready') {
    subtitle = 'Ready to reset';
  }

  return {
    title,
    subtitle,
    icon: faRotateRight,
    status,
    contentBefore,
  };
};

type MISResetStatus =
  | { status: 'before' | 'complete' | 'error' | 'loading' }
  | ({ status: 'ready' | 'not-ready' } & GetMISStatusResponse);

export const useMISResetStatus = ({
  suspense,
  enabled,
}: {
  suspense: boolean;
  enabled?: boolean;
}): MISResetStatus => {
  const { isCurrentYear } = useYearToReference();
  const inInterim = useIsInRolloverInterim();
  const {
    data: misStatus,
    isError,
    isSuccess,
  } = useSchoolMISStatus({ suspense, enabled: inInterim && (enabled === undefined || enabled) });

  if (!isCurrentYear) {
    return {
      status: 'before',
    };
  }

  if (!inInterim) {
    return {
      status: 'complete',
    };
  }

  if (isError) {
    return {
      status: 'error',
    };
  }

  if (!isSuccess) {
    return {
      status: 'loading',
    };
  }

  const misReady = misStatus?.hasRolledOver;

  return {
    status: misReady ? 'ready' : 'not-ready',
    ...misStatus,
  };
};

const MISTab: PageTab = {
  path: 'mis',
  detailsHook: useTabDetails,
  page: Page,
};
export default MISTab;
