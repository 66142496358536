import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';

import styles from './LoadingMessage.module.css';

const LoadingMessage = ({ message }: { message: string }) => {
  return (
    <div className={styles.Loading}>
      <LoadingSpinner size="lg" />
      <span className={styles.Message}>{message}</span>
      <span className={styles.SecondaryMessage}>This may take a while, please wait...</span>
    </div>
  );
};

export default LoadingMessage;
