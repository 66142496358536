import { AlertProps, Link as ChakraLink } from '@chakra-ui/react';
import { Warning } from 'components/warning';
import { PropsWithChildren } from 'react';
import { articleTopicMigration } from 'utils/knowledgeBaseArticles';

export const MigrationWarning = (props: PropsWithChildren<AlertProps>) => (
  <Warning status="info" {...props}>
    We will automatically migrate this scheme to our new topic structure. See more info{' '}
    <ChakraLink textDecoration="underline" href={articleTopicMigration} target="_blank">
      here
    </ChakraLink>
    .
  </Warning>
);
