import { Alert, AlertIcon, Box, Link, Text } from '@chakra-ui/react';
import { Product } from '@sparx/api/apis/sparx/types/product';

import { useStaffContext } from '../Context';
import { KeyContactData } from '../queries';
import { getRoleName } from '../utils';

export const KeyContactsWarning = ({
  status: { status, missing },
  product,
  kcTabLink,
}: {
  status: KeyContactData['status'];
  product: Product;
  kcTabLink?: () => void;
}) => {
  const { styles } = useStaffContext();
  if (status === 'ok' || !missing || missing.length === 0) {
    return null;
  }

  const text =
    missing.length === 1
      ? 'No staff member has been assigned to the role '
      : 'No staff have been assigned to the following roles: ';

  return (
    <Alert mb={4} status="warning" borderRadius="md">
      <AlertIcon />
      <Box>
        <Text>
          {text}
          <strong>{missing.map(r => getRoleName(r, product)).join(', ')}</strong>. It's important
          that you{' '}
          {kcTabLink ? (
            <Link textDecoration="underline" color={styles.linkColor} onClick={kcTabLink}>
              let us know
            </Link>
          ) : (
            'let us know'
          )}{' '}
          who holds {missing.length === 1 ? 'this role' : 'these roles'} at your school so that
          we're able to direct important communications and support to the right people.
        </Text>
      </Box>
    </Alert>
  );
};
