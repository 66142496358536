// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/reports/v1/dashboard.proto" (package "sparx.science.reports.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.science.reports.v1.UsageData
 */
export interface UsageData {
  /**
   * Resource name of the school in the form `schools/{school_id}`.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Timestamp that the data was last updated.
   *
   * @generated from protobuf field: google.protobuf.Timestamp update_timestamp = 2;
   */
  updateTimestamp?: Timestamp;
  /**
   * Usage data statistics for the current academic year.
   *
   * @generated from protobuf field: sparx.science.reports.v1.UsageDataStatistics statistics = 3;
   */
  statistics?: UsageDataStatistics;
}
/**
 * @generated from protobuf message sparx.science.reports.v1.UsageDataStatistics
 */
export interface UsageDataStatistics {
  /**
   * Total hours of learning in the current academic year.
   *
   * @generated from protobuf field: int32 total_learning_hours = 1;
   */
  totalLearningHours: number;
  /**
   * Total number of questions correct in the current academic year.
   *
   * @generated from protobuf field: int32 total_questions_correct = 2;
   */
  totalQuestionsCorrect: number;
}
/**
 * @generated from protobuf message sparx.science.reports.v1.GetUsageDataRequest
 */
export interface GetUsageDataRequest {
  /**
   * Resource name of the school in the form `schools/{school_id}`.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
}
/**
 * @generated from protobuf message sparx.science.reports.v1.UpdateUsageDataRequest
 */
export interface UpdateUsageDataRequest {
  /**
   * @generated from protobuf field: repeated sparx.science.reports.v1.UsageData usage_datas = 1;
   */
  usageDatas: UsageData[];
}
/**
 * @generated from protobuf message sparx.science.reports.v1.UpdateUsageDataResponse
 */
export interface UpdateUsageDataResponse {}
// @generated message type with reflection information, may provide speed optimized methods
class UsageData$Type extends MessageType<UsageData> {
  constructor() {
    super('sparx.science.reports.v1.UsageData', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'update_timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 3,
        name: 'statistics',
        kind: 'message',
        T: () => UsageDataStatistics,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.reports.v1.UsageData
 */
export const UsageData = new UsageData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UsageDataStatistics$Type extends MessageType<UsageDataStatistics> {
  constructor() {
    super('sparx.science.reports.v1.UsageDataStatistics', [
      {
        no: 1,
        name: 'total_learning_hours',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'total_questions_correct',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.reports.v1.UsageDataStatistics
 */
export const UsageDataStatistics = new UsageDataStatistics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUsageDataRequest$Type extends MessageType<GetUsageDataRequest> {
  constructor() {
    super('sparx.science.reports.v1.GetUsageDataRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.reports.v1.GetUsageDataRequest
 */
export const GetUsageDataRequest = new GetUsageDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUsageDataRequest$Type extends MessageType<UpdateUsageDataRequest> {
  constructor() {
    super('sparx.science.reports.v1.UpdateUsageDataRequest', [
      {
        no: 1,
        name: 'usage_datas',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => UsageData,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.reports.v1.UpdateUsageDataRequest
 */
export const UpdateUsageDataRequest = new UpdateUsageDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUsageDataResponse$Type extends MessageType<UpdateUsageDataResponse> {
  constructor() {
    super('sparx.science.reports.v1.UpdateUsageDataResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.reports.v1.UpdateUsageDataResponse
 */
export const UpdateUsageDataResponse = new UpdateUsageDataResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.science.reports.v1.DashboardData
 */
export const DashboardData = new ServiceType(
  'sparx.science.reports.v1.DashboardData',
  [
    {
      name: 'GetUsageData',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:school',
          domain: '{school_name}',
        },
      },
      I: GetUsageDataRequest,
      O: UsageData,
    },
    {
      name: 'UpdateUsageData',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:school:usagedata',
          domain: 'schools',
        },
      },
      I: UpdateUsageDataRequest,
      O: UpdateUsageDataResponse,
    },
  ],
);
