import { Text } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const RowEditButton = ({ icon = faPencil }: { icon?: IconDefinition }) => (
  <Text color="teal.600">
    <FontAwesomeIcon icon={icon} />
  </Text>
);
