import React, { Box, Menu, MenuButton, MenuList, Portal, Text } from '@chakra-ui/react';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren } from 'react';

export const TeacherContentMoreMenu = ({ children }: PropsWithChildren) => {
  return (
    <Menu placement="right-start">
      <MenuButton
        as={Box}
        mb={2}
        _hover={{ backgroundColor: 'gray.100' }}
        _expanded={{ backgroundColor: 'blue.50' }}
        borderRadius="md"
        py={3}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        textAlign="center"
        cursor="pointer"
      >
        <Text fontSize="2xl" textColor={'gray.400'}>
          <FontAwesomeIcon icon={faBriefcase} />
        </Text>
        <Text fontSize="sm" textColor="gray.900" fontWeight="bold">
          Management
        </Text>
      </MenuButton>
      <Portal>
        <MenuList boxShadow="lg" borderTopLeftRadius={0} pt={1}>
          {children}
        </MenuList>
      </Portal>
    </Menu>
  );
};
