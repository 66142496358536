import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  Container,
  HStack,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { faArrowRight, faChevronRight, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Assignment } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { DayOfWeek } from '@sparx/api/google/type/dayofweek';
import { TimeOfDay } from '@sparx/api/google/type/timeofday';
import { useGroupAssignments } from 'api/planner';
import { dateInWeek, useCurrentSchoolYear, useWeeks, Week } from 'api/school';
import { GroupWithSettings } from 'api/scienceschool';
import { useClassSelection } from 'app/ClassSelection';
import { UnknownError } from 'components/errorpages/UnknownError';
import { InterimLock } from 'components/InterimLock';
import { LargeLoading } from 'components/loading/LargeLoading';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import { PrettyTimestamp } from 'components/timestamp/PrettyTimestamp';
import { UndoButtons } from 'components/undobuttons/UndoButtons';
import {
  getDate,
  getDay,
  getMonth,
  getYear,
  isBefore,
  nextDay,
  set,
  setHours,
  setMinutes,
} from 'date-fns';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useWeeksWithAssignments } from 'utils/data/assignments';
import { v4 as uuid } from 'uuid';
import { setDateNextValid } from 'views/planner/components/DatePicker';
import { PlannerTitle } from 'views/planner/components/PlannerTitle';
import { HomeworkContentsList, WeekButton } from 'views/planner/components/WeekButton';
import { usePlannerUndoState } from 'views/planner/PlannerUndoState';

import {
  AssignmentContentsEditor,
  AssignmentControlContainer,
} from './components/AssignmentContentsEditor';
import { PlannerContext, usePlannerContext } from './Context';

export const PlannerView = () => (
  <SuspenseRoute>
    <InterimLock title="Planner">
      <SuspensePlannerView />
    </InterimLock>
  </SuspenseRoute>
);

interface PlannerViewQueryParams {
  group?: string;
  week?: number;
}

const SuspensePlannerView = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const groupID = query.group?.toString() || '';
  const week = query.week ? parseInt(query.week.toString()) : undefined;

  const link = (params: PlannerViewQueryParams) => {
    const query = queryString.stringify({
      group: groupID,
      week,
      ...params,
    });
    return `/teacher/planner?${query}`;
  };

  const group = useClassSelection(
    {
      current: groupID,
      link: (group: string) => link({ group }),
    },
    { suspense: true },
  );

  if (!group) {
    return null;
  }
  return (
    <SuspensePlannerViewWithGroup
      key={groupID}
      group={group}
      week={week}
      getWeekLink={week => link({ week })}
    />
  );
};

interface SuspensePlannerViewWithGroupProps {
  group: GroupWithSettings;
  week?: number;
  getWeekLink: (week: number) => string;
}

const SuspensePlannerViewWithGroup = ({
  group,
  week,
  getWeekLink,
}: SuspensePlannerViewWithGroupProps) => {
  const navigate = useNavigate();
  const { data: weeks } = useWeeks({ suspense: true });
  const selectedWeek = useMemo(() => weeks?.find(w => w.index === week), [weeks, week]);
  const { data: schoolYear } = useCurrentSchoolYear({ suspense: true });

  const [jumped, setJumped] = useState(false);
  useEffect(() => {
    if (selectedWeek) {
      document.getElementById(`week-${selectedWeek.index}`)?.scrollIntoView({
        block: 'center',
        behavior: jumped ? 'smooth' : 'auto',
      });
      if (!jumped) setJumped(true);
    }
  }, [selectedWeek, setJumped, jumped]);

  useEffect(() => {
    if (!selectedWeek && weeks?.length) {
      const currentWeek = weeks?.find(w => dateInWeek(w, new Date()));
      if (currentWeek) {
        navigate(getWeekLink(currentWeek.index), { replace: true });
      }
    }
  }, [selectedWeek, getWeekLink, weeks, navigate]);

  const assignments = useGroupAssignments(group.name, { suspense: true });

  const { updateAssignment, error, ...undoProps } = usePlannerUndoState(assignments);

  const weekWithAssignments = useWeeksWithAssignments(weeks, assignments);
  const selectedWeekAssignments =
    weekWithAssignments.find(a => a.week.index === selectedWeek?.index)?.assignments || [];

  const title = (
    <PlannerTitle
      week={selectedWeek}
      weeks={weeks || []}
      selectWeek={week => navigate(getWeekLink(week.index))}
    />
  );
  const pageTitle = group && `Week ${selectedWeek?.index} - ${group.displayName} Planner`;

  if (error) {
    // Sentry should also report any error we see here.
    return (
      <UnknownError reset={() => location.reload()}>
        Sorry, there was an unexpected error updating a homework. Please reload the page and try
        again.
      </UnknownError>
    );
  }

  return (
    <PlannerContext
      group={group}
      doAssignmentAction={updateAssignment}
      weekWithAssignments={weekWithAssignments}
      selectedWeek={selectedWeek}
    >
      <Box height="100%" width="100%" display="flex" position="absolute" overflowY="auto">
        <Box flex={1} overflowY="auto">
          <Container maxW={1200} flex={1} py={4}>
            <PageTitle title={title} pageTitleOverride={pageTitle}>
              <UndoButtons {...undoProps} />
            </PageTitle>
            {selectedWeek ? (
              <WeekEditor
                key={selectedWeek.index + group.name}
                week={selectedWeek}
                assignments={selectedWeekAssignments}
                group={group}
                onCreateAssignment={a => updateAssignment({ ...a, action: 'create' })}
                yearEnd={schoolYear?.end || selectedWeek.endDate}
              />
            ) : (
              <LargeLoading />
            )}
          </Container>
        </Box>
        <Box
          w="350px"
          boxShadow="elevationMedium"
          backgroundColor="white"
          flexShrink={0}
          display="flex"
          flexDirection="column"
        >
          <EditSettingsButton group={group} />
          <Box flex={1} overflowY="scroll">
            {weekWithAssignments?.map(({ week, assignments }) => (
              <WeekButton
                week={week}
                assignments={assignments}
                key={week.index}
                selected={week.index === selectedWeek?.index}
                link={getWeekLink(week.index)}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </PlannerContext>
  );
};

const EditSettingsButton = ({ group }: { group: Group }) => {
  const location = useLocation();
  const back = location.pathname + location.search;

  return (
    <Button
      as={Link}
      to={`/teacher/group/${group.name.split('/')[3]}/details`}
      state={{ back }}
      borderRadius="none"
      colorScheme="buttonTeal"
      rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
    >
      Edit {group.displayName} settings
      <Spacer />
    </Button>
  );
};

interface WeekEditorProps {
  week: Week;
  assignments: Assignment[];
  group: GroupWithSettings;
  onCreateAssignment: (assignment: Assignment) => void;
  yearEnd: Date;
}

const setTimeOfDay = (date: Date, time: TimeOfDay) =>
  setHours(setMinutes(date, time.minutes), time.hours);

export const dayOfWeekToNumber = (day: DayOfWeek | undefined) =>
  day === DayOfWeek.SUNDAY ? 0 : day;

const getHomeworkStartDate = (week: Week, group: GroupWithSettings, yearEnd: Date) => {
  const startDay = dayOfWeekToNumber(group.scienceSettings.defaultSetDay) ?? 3;
  const defaultHomeworkStart = setTimeOfDay(
    getDay(week.startDate) === startDay ? week.startDate : nextDay(week.startDate, startDay),
    group.scienceSettings.defaultSetTime || TimeOfDay.create({ hours: 9 }),
  );
  const now = new Date();

  let homeworkStart = defaultHomeworkStart;
  if (isBefore(defaultHomeworkStart, now)) {
    homeworkStart = set(defaultHomeworkStart, {
      year: getYear(now),
      month: getMonth(now),
      date: getDate(now),
    });
    homeworkStart = setDateNextValid(homeworkStart, now);
  }

  const yearEndWithTime = setTimeOfDay(
    yearEnd,
    group.scienceSettings.defaultSetTime || TimeOfDay.create({ hours: 9 }),
  );

  if (isBefore(yearEndWithTime, homeworkStart)) {
    return yearEndWithTime;
  }

  return homeworkStart;
};

export const defaultAssignmentForWeek = (
  week: Week,
  group: GroupWithSettings,
  yearEnd: Date,
): Assignment => {
  const homeworkStart = getHomeworkStartDate(week, group, yearEnd);
  const homeworkEnd = setTimeOfDay(
    nextDay(homeworkStart, dayOfWeekToNumber(group.scienceSettings.defaultDueDay) ?? 2),
    group.scienceSettings.defaultDueTime || TimeOfDay.create({ hours: 15 }),
  );

  return Assignment.create({
    name: `assignments/${uuid()}`,
    title: `Homework`,
    groups: [{ name: group.name }],
    startTimestamp: Timestamp.fromDate(homeworkStart),
    endTimestamp: Timestamp.fromDate(homeworkEnd),
    spec: {
      contents: {
        oneofKind: 'generatedAssignment',
        generatedAssignment: {
          lengthMinutes: group.scienceSettings.defaultHomeworkLengthMinutes ?? 30, // default length
          topics: [],
        },
      },
    },
  });
};

const WeekEditor = ({ week, assignments, group, onCreateAssignment, yearEnd }: WeekEditorProps) => {
  const { doAssignmentAction } = usePlannerContext();

  const [_editingAssignment, setEditingAssignment] = useState<string | undefined>(
    assignments.length === 1 ? assignments[0].name : undefined,
  );
  const createNewAssignment = () =>
    onCreateAssignment(defaultAssignmentForWeek(week, group, yearEnd));

  const numberAssignments = assignments.length;
  const editingAssignment =
    _editingAssignment || numberAssignments === 1 ? assignments[0]?.name : undefined;
  const assignment = assignments.find(a => a.name === editingAssignment);

  // Check if we can set homework this week. If the default date of any new homework
  // is outside of this week (if after 5pm on Friday) then we can't set homework this week.
  const defaultAssignmentStartDate = getHomeworkStartDate(week, group, yearEnd);
  const canSetThisWeek =
    !isBefore(week.endDate, new Date()) && dateInWeek(week, defaultAssignmentStartDate);

  if (!assignment && assignments.length > 0) {
    return (
      <VStack
        spacing={3}
        backgroundColor="white"
        p={4}
        alignItems="flex-start"
        borderRadius="md"
        boxShadow="elevationLow"
        width="100%"
      >
        <Text>There are {assignments.length} assignments for this week:</Text>
        {assignments.map((a, i) => (
          <Card
            key={a.name}
            p={3}
            width="100%"
            variant="outline"
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
          >
            <Box pl={1}>
              <Text as="span" fontSize="lg" fontWeight="bold">
                Homework {i + 1}
              </Text>
              <HStack spacing={2} mb={2}>
                <Text color="gray.800">
                  <PrettyTimestamp>{a.startTimestamp}</PrettyTimestamp>
                </Text>
                <Text color="gray.500">
                  <FontAwesomeIcon icon={faArrowRight} />
                </Text>
                <Text color="gray.800">
                  <PrettyTimestamp>{a.endTimestamp}</PrettyTimestamp>
                </Text>
              </HStack>
              <HomeworkContentsList assignment={a} week={week} />
            </Box>
            <Spacer />
            <Button
              colorScheme="buttonTeal"
              onClick={() => setEditingAssignment(a.name)}
              leftIcon={<FontAwesomeIcon icon={faPencil} />}
            >
              Edit
            </Button>
          </Card>
        ))}
      </VStack>
    );
  } else if (!assignment && !canSetThisWeek) {
    return (
      <Alert status="info" borderRadius="md" boxShadow="elevationLow">
        <AlertIcon />
        <Text>No homework was handed out during this week.</Text>
      </Alert>
    );
  } else if (!assignment) {
    return (
      <VStack spacing={4}>
        <AssignmentControlContainer
          homeworkValue="off"
          onToggleHomework={val => val === 'on' && createNewAssignment()}
        />
        <Alert status="error" borderRadius="md" boxShadow="elevationLow">
          <AlertIcon />
          <Text>Homework is off this week.</Text>
        </Alert>
      </VStack>
    );
  }

  return (
    <AssignmentContentsEditor
      week={week}
      assignment={assignment}
      updateAssignment={a => doAssignmentAction({ ...a, action: 'update' })}
    />
  );
};
