import {
  CreateSchemeTemplateRequest,
  ListSchemesRequest,
  ListSchemeTemplatesRequest,
  ResourceStatus,
  SchemeOfLearning,
  SchemeOfLearningTemplate,
} from '@sparx/api/apis/sparx/planning/v1/sol';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { FetchQueryOptions, useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { solClient, templateSolClient } from 'api';
import { queryClient } from 'api/client';
import { Options, schoolYearQuery } from 'api/school';
import { getSchoolID } from 'api/sessions';
import { sub } from 'date-fns';

const defaultSolFilter = (sol: SchemeOfLearning) => sol.metadata?.tags.includes('subject:science');

export const useAssignableSols = (options: Options) =>
  useQuery({
    queryKey: ['sol', 'list'],
    queryFn: async () => {
      // Load the current school year
      const years = await queryClient.fetchQuery(schoolYearQuery);
      const currentYear = years.find(y => y.current)?.name;
      if (!currentYear) {
        return []; // no current year, so no sols?
      }

      return solClient
        .listSchemes(
          ListSchemesRequest.create({
            parent: `schools/${await getSchoolID()}`,
            schoolYear: currentYear.split('/')[3],
            includeDrafts: false,
            includeSchedules: false,
            product: Product.SPARX_SCIENCE,
          }),
        )
        .response.then(r => r.schemes.filter(defaultSolFilter));
    },
    cacheTime: 1000 * 60 * 15, // 15 minutes
    staleTime: 1000 * 60 * 15, // 15 minutes
    ...options,
  });

export const useEditableSols = (options: Options) =>
  useQuery({
    queryKey: ['sol', 'list', 'editable'],
    queryFn: async () =>
      solClient
        .listSchemes(
          ListSchemesRequest.create({
            parent: `schools/${await getSchoolID()}`,
            schoolYear: '',
            includeDrafts: true,
            includeSchedules: false,
            product: Product.SPARX_SCIENCE,
          }),
        )
        .response.then(r => r.schemes.filter(defaultSolFilter)),
    ...options,
  });

export const usePublishedSolsWithSchedules = (yearID: string, options: Options) =>
  useQuery({
    queryKey: ['sol', 'list', 'published', yearID],
    queryFn: async () =>
      solClient
        .listSchemes(
          ListSchemesRequest.create({
            parent: `schools/${await getSchoolID()}`,
            schoolYear: yearID,
            includeDrafts: false,
            includeSchedules: true,
            product: Product.SPARX_SCIENCE,
          }),
        )
        .response.then(r => r.schemes.filter(defaultSolFilter)),
    refetchOnMount: true,
    ...options,
  });

// Fetches SOLs deleted in the past 4 months
export const useDeletedSols = (options: Options) =>
  useQuery({
    queryKey: ['sol', 'list', 'deleted'],
    queryFn: async () =>
      solClient
        .listSchemes(
          ListSchemesRequest.create({
            parent: `schools/${await getSchoolID()}`,
            schoolYear: '',
            includeDrafts: true,
            includeSchedules: false,
            includeDeletedAfter: Timestamp.fromDate(sub(new Date(), { months: 4 })),
            product: Product.SPARX_SCIENCE,
          }),
        )
        .response.then(r =>
          r.schemes.filter(sol => !!sol.metadata?.deleteTime).filter(defaultSolFilter),
        ),
    ...options,
  });

const updateSolInQuery = (sol: SchemeOfLearning) => {
  queryClient.resetQueries({ queryKey: ['sol', 'list'], exact: true });
  queryClient.setQueryData(['sol', 'list', 'editable'], (data: SchemeOfLearning[] | undefined) => {
    if (!data) return data;
    let output = [...data];
    const index = output.findIndex(
      s => s.name === sol.name && s.metadata?.status === sol.metadata?.status,
    );
    if (index !== -1) output[index] = sol;
    else output = output.concat([sol]);
    return output;
  });
};

const removeSolFromEditable = (name: string, draftOnly?: boolean) => {
  queryClient.setQueryData(['sol', 'list', 'editable'], (data: SchemeOfLearning[] | undefined) => {
    if (!data) return data;
    return data.filter(
      sol =>
        sol.name !== name || (draftOnly ? sol.metadata?.status !== ResourceStatus.DRAFT : false),
    );
  });
};

const removeSolFromList = (name: string, draftOnly?: boolean) => {
  queryClient.setQueryData(['sol', 'list'], (data: SchemeOfLearning[] | undefined) => {
    if (!data) return data;
    return data.filter(
      sol =>
        sol.name !== name || (draftOnly ? sol.metadata?.status !== ResourceStatus.DRAFT : false),
    );
  });
};

const solKey = (sol: SchemeOfLearning | SchemeOfLearningTemplate) =>
  sol.metadata?.status === ResourceStatus.DRAFT ? 'draft' : 'published';

export const useSol = (
  id: string,
  typ: 'draft' | 'published',
  deleted: boolean,
  options: Options,
) =>
  useQuery({
    queryKey: ['sol', id, typ, deleted],
    queryFn: async () =>
      solClient.getScheme({
        name: `schools/${await getSchoolID()}/sols/${id}`,
        status: typ === 'draft' ? ResourceStatus.DRAFT : ResourceStatus.PUBLISHED,
        allowDeleted: deleted,
      }).response,
    ...options,
    enabled: Boolean(id) && (options.enabled === undefined || options.enabled),
  });

// Supports SOLs and Template SOLs
export const useUpdateSolSchedule = (
  name: string,
  isTemplate: boolean,
  options?: {
    onSuccess?: (data: SchemeOfLearning | SchemeOfLearningTemplate) => void;
  },
) =>
  useMutation({
    mutationFn: async ({
      schedule,
      metadata,
    }: Partial<SchemeOfLearning | SchemeOfLearningTemplate>) =>
      !isTemplate
        ? solClient.updateScheme({
            scheme: SchemeOfLearning.create({
              name,
              schedule,
              metadata,
            }),
            updateMask: { paths: ['schedule'] },
          }).response
        : templateSolClient.updateSchemeTemplate({
            template: SchemeOfLearningTemplate.create({
              name,
              schedule,
              metadata,
            }),
            updateMask: { paths: ['schedule'] },
          }).response,
    onSuccess: data => {
      if (isTemplate) {
        queryClient.setQueryData(
          ['templatesol', name.split('/')[1], name.split('/')[3], solKey(data)],
          data,
        );
      } else {
        queryClient.setQueryData(
          ['sol', name.split('/')[3], solKey(data), !!data.metadata?.deleteTime],
          data,
        );
      }
      options?.onSuccess?.(data);
    },
  });

export const usePublishSol = () =>
  useMutation({
    mutationFn: async (scheme: SchemeOfLearning) =>
      solClient.publishScheme({
        name: scheme.name,
        version: scheme.metadata?.version || -1, // -1 will always fail
      }).response,
    onSuccess: (data, req) => {
      removeSolFromEditable(req.name);
      updateSolInQuery(data);
    },
  });

export const useRenameSol = () =>
  useMutation({
    mutationFn: async (scheme: SchemeOfLearning) =>
      solClient.updateScheme({
        scheme,
        updateMask: { paths: ['display_name'] },
      }).response,
    onSuccess: data => updateSolInQuery(data),
  });

export const useDeleteSol = () =>
  useMutation({
    mutationFn: async ({ name, resetDraft }: { name: string; resetDraft: boolean }) =>
      resetDraft
        ? solClient.resetDraftScheme({ name }).response
        : solClient.deleteScheme({ name }).response.then(() => null),
    onSuccess: (data, { name, resetDraft }) => {
      removeSolFromEditable(name, resetDraft);
      removeSolFromList(name, resetDraft);

      // If we reset a draft scheme then the response is the new state of the published
      // scheme, so we update this in the sol query
      data && updateSolInQuery(data);
    },
  });

export const useCreateSol = () =>
  useMutation({
    mutationFn: async (scheme: SchemeOfLearning) =>
      solClient.createScheme({
        parent: `schools/${await getSchoolID()}`,
        scheme,
      }).response,
    onSuccess: data => updateSolInQuery(data),
  });

const templateSolsQuery = (
  groupName: string,
  options: Options,
): FetchQueryOptions<SchemeOfLearningTemplate[]> => ({
  queryKey: ['templatesols', groupName],
  queryFn: async () =>
    templateSolClient
      .listSchemeTemplates(
        ListSchemeTemplatesRequest.create({
          includeDrafts: true,
          parent: groupName,
          product: Product.SPARX_SCIENCE,
        }),
      )
      .response.then(r => r.templates),
  ...options,
});

export const SCHOOL_GROUPS_ALL = 'schoolGroups/ALL';

export const useTemplateSols = (groupNames: string[], options: Options) =>
  useQueries({
    queries: groupNames.map(groupName => templateSolsQuery(groupName, options)),
  });

export const useCreateTemplateSol = () =>
  useMutation({
    mutationFn: async ({
      parent,
      template,
    }: {
      parent: string;
      template: SchemeOfLearningTemplate;
    }) =>
      templateSolClient.createSchemeTemplate(
        CreateSchemeTemplateRequest.create({
          parent,
          template,
        }),
      ).response,
    onSuccess: () => {
      queryClient.invalidateQueries(['templatesols']);
    },
  });

export const useDeleteTemplateSol = () =>
  useMutation({
    mutationFn: async ({ name, resetDraft }: { name: string; resetDraft: boolean }) =>
      resetDraft
        ? templateSolClient.resetDraftSchemeTemplate({ name }).response
        : templateSolClient.deleteSchemeTemplate({ name }).response,
    onSuccess: () => {
      queryClient.invalidateQueries(['templatesols']);
    },
  });

export const useRenameTemplateSol = () =>
  useMutation({
    mutationFn: async (template: SchemeOfLearningTemplate) =>
      templateSolClient.updateSchemeTemplate({
        template,
        updateMask: { paths: ['display_name'] },
      }).response,
    onSuccess: () => {
      queryClient.invalidateQueries(['templatesols']);
    },
  });

export const usePublishTemplateSol = () =>
  useMutation({
    mutationFn: async (template: SchemeOfLearningTemplate) =>
      templateSolClient.publishSchemeTemplate({
        name: template.name,
        version: template.metadata?.version || -1, // -1 will always fail
      }).response,
    onSuccess: () => {
      queryClient.invalidateQueries(['templatesols']);
    },
  });

export const useTemplateSol = (
  schoolGroupId: string,
  solId: string,
  typ: 'draft' | 'published',
  options: Options,
) =>
  useQuery({
    queryKey: ['templatesol', schoolGroupId, solId, typ],
    queryFn: async () =>
      templateSolClient.getSchemeTemplate({
        name: `schoolGroups/${schoolGroupId}/solTemplates/${solId}`,
        status: typ === 'draft' ? ResourceStatus.DRAFT : ResourceStatus.PUBLISHED,
      }).response,
    ...options,
    enabled:
      Boolean(schoolGroupId) &&
      Boolean(solId) &&
      (options.enabled === undefined || options.enabled),
  });

export const useUpdateTemplateSolSchedule = (
  name: string,
  options?: {
    onSuccess?: (data: SchemeOfLearningTemplate) => void;
  },
) =>
  useMutation({
    mutationFn: async ({ schedule, metadata }: Partial<SchemeOfLearningTemplate>) =>
      templateSolClient.updateSchemeTemplate({
        template: SchemeOfLearningTemplate.create({
          name,
          schedule,
          metadata,
        }),
        updateMask: { paths: ['schedule'] },
      }).response,
    onSuccess: data => {
      queryClient.setQueryData(
        ['templatesol', name.split('/')[1], name.split('/')[3], solKey(data)],
        data,
      );
      options?.onSuccess?.(data);
    },
  });
