import { useClientEvent } from 'components/ClientEventProvider';
import { PropsWithChildren, useEffect } from 'react';
import { attemptReload } from 'utils/attempt-reload';

// Listen for vite:preloadError events, then send a page event and attempt to reload
export const WithPreloadErrorHandler = ({ children }: PropsWithChildren) => {
  const { sendEvent } = useClientEvent();

  useEffect(() => {
    const handler = (event: Event) => {
      console.error('Preload Error:', event);
      sendEvent(
        { category: 'app_error', action: 'preload_error' },
        {
          error_message:
            (event as { payload?: { message?: string } })?.payload?.message ||
            'SPX missing error message',
        },
        { immediate: true },
      );
      attemptReload();
    };

    window.addEventListener('vite:preloadError', handler);
    return () => window.removeEventListener('vite:preloadError', handler);
  }, [sendEvent]);

  return children;
};
