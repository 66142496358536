import { Button, ButtonProps, Flex, Text } from '@chakra-ui/react';
import { TopicWithStrand, useCurriculum } from 'api/content';

import { useIndependentLearningContext } from '../IndependentLearning';
import { ILChevron, SeenInHomework } from './Misc';

export const TopicRow = ({
  topic,
  searchResult = false,
}: {
  topic: TopicWithStrand;
  searchResult?: boolean;
}) => {
  const { userState, curriculumName } = useIndependentLearningContext();
  const { createTask } = useIndependentLearningContext();

  const showCurriculumName = !topic.topic.name.startsWith(curriculumName);

  const curriculumID = topic.topic.name.split('/')[1] || '';

  const { data: curriculum } = useCurriculum(`curriculums/${curriculumID}`, {
    suspense: true,
    enabled: showCurriculumName,
  });

  const assignedInHomework = userState.previousTopics.find(t => t.name === topic.topic.name);

  const onClick = () => createTask(topic.topic.name, topic.topic.displayName);

  return (
    <Flex
      alignItems="center"
      p={2}
      _hover={{ backgroundColor: 'blackAlpha.50' }}
      borderRadius={5}
      onClick={onClick}
      cursor="pointer"
    >
      <Flex direction="column">
        <Text fontSize={{ base: 'sm', md: 'md' }}>
          {topic.topic.displayName}
          <Text fontSize={{ base: 'sm', md: 'md' }} as="span" color="gray.500">
            {' '}
            - {topic.topic.code}
          </Text>
        </Text>
        {searchResult && (
          <Text fontSize="xs" color="gray.500">
            {showCurriculumName && curriculum && (
              <>
                {curriculum.curriculum.displayName}
                <ILChevron mx={1} />
              </>
            )}
            {topic.strand}
            <ILChevron mx={1} />
            {topic.substrand}
          </Text>
        )}
      </Flex>
      <Flex grow={1} />
      {assignedInHomework && <SeenInHomework mr={2} />}
      <PractiseButton
        onClick={onClick}
        size={searchResult ? 'sm' : 'xs'}
        variant="outline"
        backgroundColor="white"
      />
    </Flex>
  );
};

export const PractiseButton = ({ onClick, ...props }: ButtonProps & { onClick: () => void }) => {
  return (
    <Button
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}
      rightIcon={<ILChevron />}
      minWidth="auto"
      borderRadius={100}
      colorScheme="buttonBlue"
      {...props}
    >
      Practise
    </Button>
  );
};
