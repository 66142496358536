import { Box, Button, Heading, Image, Text } from '@chakra-ui/react';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';
import { useWhatsNewPosts } from 'api/whatsnew';
import { useClientEvent } from 'components/ClientEventProvider';
import React, { PropsWithChildren, useState } from 'react';
import { Link } from 'react-router-dom';
import { getMediaURL } from 'utils/strapi-types/media';
import { Carousel } from 'views/teacherhome/Carousel';
import didyouknowbg from 'views/teacherhome/images/didyouknowbg.svg?url';
import studentDemoImage from 'views/teacherhome/images/studentdemo.jpg';

export const DidYouKnow = () => {
  const { sendEvent } = useClientEvent();
  const didYouKnow = (
    <Card imageSrc={studentDemoImage}>
      <Text color="white">
        You can use the student demo to see what it is like to answer questions as a student.
      </Text>
      <Button
        size="sm"
        mt={5}
        colorScheme="buttonTeal"
        as={Link}
        to="/packages"
        onClick={() => {
          sendEvent(
            { category: 'did_you_know', action: 'call_to_action' },
            {
              type: 'student_demo',
              buttonText: 'Try Sparx as a student',
              buttonURL: `/packages`,
            },
          );
        }}
      >
        Try Sparx as a student
      </Button>
    </Card>
  );

  const { data: whatsNewPosts } = useWhatsNewPosts({ suspense: false });
  const dykWhatsNewPosts =
    whatsNewPosts
      ?.filter(post => post.attributes.ShowInDidYouKnow)
      .slice(0, 3) // Only show the last 3
      .map(post => (
        <Card
          key={post.spxID}
          imageSrc={getMediaURL(post.attributes.Image.data, 'small')}
          rotateBg={true}
          bg="cyan.900"
        >
          <Heading
            color="white"
            size="sm"
            bgColor="orange.500"
            borderRadius="md"
            py={1}
            px={2}
            mb={4}
          >
            What's new?
          </Heading>
          <Text color="white" fontSize="lg">
            {post.attributes.Title}
          </Text>
          <Button
            size="sm"
            mt={5}
            ml={-3}
            variant="ghost"
            colorScheme="whiteAlpha"
            color="white"
            textDecoration="underline"
            as={Link}
            to={`/teacher/whats-new#${post.spxID}`}
            onClick={() => {
              sendEvent(
                { category: 'did_you_know', action: 'call_to_action' },
                {
                  type: 'whats_new',
                  postID: post.id.toString(),
                  postTitle: post.attributes.Title,
                  buttonText: 'Find out more',
                  buttonURL: `/teacher/whats-new#${post.spxID}`,
                },
              );
            }}
          >
            Find out more
          </Button>
        </Card>
      )) || [];

  const [reverse] = useState(Math.random() < 0.5);

  const pages = reverse ? [didYouKnow, ...dykWhatsNewPosts] : [...dykWhatsNewPosts, didYouKnow];

  return <Carousel title="Did you know?" icon={faLightbulb} pages={pages} />;
};

const Card = ({
  imageSrc,
  rotateBg,
  bg,
  children,
}: PropsWithChildren<{ imageSrc: string; rotateBg?: boolean; bg?: string }>) => (
  <Box
    p={5}
    flex={1}
    display="flex"
    alignItems="center"
    bg={bg || 'blue.900'}
    position="relative"
    justifyContent="space-between"
  >
    <Image
      src={didyouknowbg}
      position="absolute"
      width="100%"
      top={0}
      left={0}
      transform={rotateBg ? 'rotate(180deg)' : undefined}
    />
    <Box
      mr={5}
      pl={2}
      zIndex={1}
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      {children}
    </Box>
    <Image
      zIndex={1}
      src={imageSrc}
      maxHeight={{ base: '200px', lg: '150px' }}
      maxWidth="40%"
      borderRadius="sm"
      boxShadow="elevationLow"
    />
  </Box>
);
