import { Link, Text } from '@chakra-ui/react';
import { EmptyPage } from 'components/emptypage/EmptyPage';
import { PageContainer } from 'components/PageContainer';
import { Title } from 'components/pagetitle/PageTitle';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface NoHomeworkProps {
  title: string | undefined;
  groupID: string;
}

export const NoHomework = ({ title, groupID }: NoHomeworkProps) => (
  <PageContainer>
    <EmptyPage title="This class hasn't been set any homework yet">
      <Title title={title} />
      <Text color="gray.700">
        You can set homework in the{' '}
        <Link as={RouterLink} to={`/teacher/planner?group=${groupID}`} color="blue.500">
          planner
        </Link>
        .
      </Text>
    </EmptyPage>
  </PageContainer>
);
