import { Box, HStack, Text } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

export const AssignmentContentsSetting = ({
  title,
  children,
}: PropsWithChildren<{ title: string }>) => (
  <Box>
    <Text fontSize="xs" mb={1}>
      {title}:
    </Text>
    <HStack spacing={2}>{children}</HStack>
  </Box>
);
