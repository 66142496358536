import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { Package } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { useNavigate } from 'react-router-dom';

export const HomeworkCancelledDialog = ({ pkg }: { pkg?: Package }) => {
  const navigate = useNavigate();

  return (
    <Modal isOpen={!!pkg?.cancelledTime} onClose={() => undefined} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Homework cancelled</ModalHeader>
        <ModalBody>
          <Text>
            This homework has been cancelled by your teacher, you won&apos;t be able to complete the
            homework.
          </Text>
          <Text mt={4}>Speak to your teacher if you need more information.</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="buttonTeal" onClick={() => navigate('/packages')}>
            Back to my homework
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
