import { Box, Center, Text } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

interface EmptyPageProps {
  title: string;
}

export const EmptyPage = ({ children, title }: PropsWithChildren<EmptyPageProps>) => (
  <Center minH={300}>
    <Box
      p={8}
      textAlign="center"
      maxWidth="500px"
      borderRadius="lg"
      borderColor="gray.300"
      borderStyle="dashed"
      borderWidth={2}
    >
      <Text fontSize="xl" fontWeight="bold" color="gray.600" mb={1}>
        {title}
      </Text>
      {children}
    </Box>
  </Center>
);
