import { Button, ButtonGroup, Text } from '@chakra-ui/react';
import { faCheck, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

interface CopyableProps {
  value: string;
  displayValue?: string;
  tail?: string;
  hideIcon?: boolean;
}

export const Copyable: React.FC<CopyableProps> = ({ value, displayValue, tail, hideIcon }) => {
  const { copied, onCopy } = useCopyableValue(() => value);

  return (
    <Button
      flexShrink={0}
      size="xs"
      leftIcon={
        hideIcon ? undefined : (
          <FontAwesomeIcon icon={copied ? faCheck : faCopy} fixedWidth={true} />
        )
      }
      isLoading={hideIcon && copied}
      spinner={<FontAwesomeIcon icon={faCheck} fixedWidth={true} />}
      onClick={onCopy}
      fontWeight="normal"
    >
      {displayValue ?? value}
      {tail && (
        <Text as="span" ml={2} color="gray.500">
          {tail}
        </Text>
      )}
    </Button>
  );
};

export const CopyableResourceName = ({ value }: { value: string }) => {
  const split = value.split('/');

  return (
    <ButtonGroup isAttached={true}>
      {split.map((part, index) => (
        <Copyable
          value={index % 2 === 0 ? `${part}/${split[index + 1]}` : part}
          displayValue={index !== split.length - 1 ? `${part} /` : part}
          key={index}
          hideIcon={index !== 0}
        />
      ))}
    </ButtonGroup>
  );
};

export const useCopyableValue = (getValue: () => string) => {
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    navigator.clipboard.writeText(getValue());
    setCopied(true);
  };
  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, [copied]);

  return { copied, onCopy };
};
