import { PropsWithChildren } from 'react';

import { ErrorMessage } from '../error-message/ErrorMessage';
import styles from './ErrorBox.module.css';

export const ErrorBox = ({ title, children }: PropsWithChildren<{ title: string }>) => (
  <div className={styles.ErrorBox}>
    <ErrorMessage className={styles.ErrorBoxTitle} message={title} />
    <div className={styles.ErrorBoxContent}>{children}</div>
  </div>
);
