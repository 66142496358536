import { Class } from '@sparx/api/apis/sparx/misintegration/wondewitch/v1/wondewitch';
import { School } from '@sparx/api/apis/sparx/school/v2/schools';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { useMisSyncContext } from '@sparx/mis-sync-import/src/Context';
import { SupportLink } from '@sparx/mis-sync-import/src/MisSyncImport/components/common';
import GenericErrorPanel from '@sparx/mis-sync-import/src/MisSyncImport/components/errors/GenericErrorPanel';
import { SyncPlanRectificationConflictError } from '@sparx/mis-sync-import/src/MisSyncImport/components/errors/SyncPlanRectificationConflictError';
import { getSystemOptions, getWondeSyncErrors } from '@sparx/mis-sync-import/src/utils';
import { ErrorBox } from '@sparx/sparx-design/components';
import { format } from 'date-fns';
import { Fragment } from 'react';

import SyncPlanUPNConflictError from '../SyncPlanUPNConflictError';

const PreviewError = ({
  error,
  sparxStudents,
  wondeClasses,
}: {
  error: Error;
  sparxStudents?: Record<string, Student>;
  wondeClasses?: Class[];
}) => {
  const errors = getWondeSyncErrors(error);
  const { groupSubject, school, sparxStaffFeaturesEnabled } = useMisSyncContext();
  const { system } = getSystemOptions(groupSubject);

  if (!errors || !school) {
    return <GenericErrorPanel task={'preview the requested changes'} />;
  }

  let title =
    'We detected some issues with your MIS that will prevent a sync. These must be resolved before you can preview or sync changes:';
  const errorContent = [];
  for (const error of errors) {
    switch (error.type) {
      case 'DUPLICATE_UPNS':
        errorContent.push(<SyncPlanUPNConflictError error={error} />);
        break;
      case 'UNAUTHORIZED':
        errorContent.push(<p>School is not authorised for sync.</p>);
        break;
      case 'MISSING_CONFLICT_RESOLUTION':
        errorContent.push(
          <p>
            Some students are in multiple selected classes. Make sure you provide resolutions for
            these conflicts on the previous screen.
          </p>,
        );
        break;
      case 'YEAR_GROUP_CHANGES':
        title =
          'It looks like you might be trying to import classes for next academic year as some students are moving to a different year group.';
        errorContent.push(
          <p>
            Your school year in {system} {getRolloverDate(school)}. If you want to start importing
            and setting up next year’s classes now, you’ll need to{' '}
            <SupportLink linkText="contact us" /> and we’ll bring your year end date forward.
          </p>,
        );
        break;
      case 'RECTIFICATION_CONFLICTS':
        if (sparxStaffFeaturesEnabled) {
          errorContent.push(
            <p>
              Some students have rectification conflicts. The following external IDs in Wonde have
              been associated with multiple Sparx students:
              <SyncPlanRectificationConflictError
                error={error}
                sparxStudents={sparxStudents}
                wondeClasses={wondeClasses}
              />
            </p>,
          );
        }
        break;
    }
  }

  if (errorContent.length === 0) {
    return <GenericErrorPanel task={'preview the requested changes'} />;
  }

  return (
    <ErrorBox title={title}>
      {errorContent.map((e, i) => (
        <Fragment key={i}>{e}</Fragment>
      ))}
    </ErrorBox>
  );
};

const getRolloverDate = (school: School) => {
  if (!school.academicYearStartDate) {
    return 'has not yet rolled over';
  }
  return `ends on ${format(
    new Date(
      school.academicYearStartDate.year,
      school.academicYearStartDate.month - 1,
      school.academicYearStartDate.day,
    ),
    'do MMMM',
  )}`;
};

export default PreviewError;
