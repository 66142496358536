import { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import {
  ResourceStatus,
  SchemeOfLearning,
  SchemeOfLearningTemplate,
  SchemeOfLearningWeek,
  SchemeOfLearningWeekType,
  WorkUnit,
} from '@sparx/api/apis/sparx/planning/v1/sol';
import { Week } from 'api/school';
import { getDay } from 'date-fns';
import { v4 as uuid } from 'uuid';

export type SolWeekSetter = (
  update: (weeks: SchemeOfLearningWeek[]) => SchemeOfLearningWeek[],
) => void;

export const bulkMoveTopics = (setter: SolWeekSetter, start: number, dir: 'up' | 'down') =>
  setter(weeks => doBulkMoveTopics(weeks, start, dir));

const doBulkMoveTopics = (weeks: SchemeOfLearningWeek[], start: number, dir: 'up' | 'down') => {
  const newWeeks = [...weeks];
  const indexes = newWeeks
    .map((_, i) => i)
    .filter(idx => newWeeks[idx].weekType !== SchemeOfLearningWeekType.NO_HOMEWORK);

  let last: WorkUnit[] = [];

  for (const i in indexes) {
    const idx = indexes[i];
    if (newWeeks[idx].weekIndex < start) continue;
    const lastIndex = indexes[parseInt(i) - 1];
    const nextIndex = indexes[parseInt(i) + 1];
    if (dir === 'down') {
      const current = newWeeks[idx].workUnits;
      if (!newWeeks[nextIndex]) {
        last = current.concat(last);
      }
      newWeeks[idx] = { ...newWeeks[idx], workUnits: last };
      last = current;
    } else {
      if (lastIndex >= 0) {
        const newUnits = newWeeks[lastIndex].workUnits.concat(newWeeks[idx].workUnits);
        newWeeks[lastIndex] = { ...newWeeks[lastIndex], workUnits: newUnits };
        newWeeks[idx] = { ...newWeeks[idx], workUnits: [] };
      }
    }
  }

  // Deduplicate topics
  return newWeeks.map(deduplicateTopicsInWeek);
};

export const updateWeekType = (
  setter: SolWeekSetter,
  weekIndex: number,
  typ: SchemeOfLearningWeekType,
) =>
  setter(weeks => {
    const newWeeks = [...weeks];
    const index = newWeeks.findIndex(w => w.weekIndex === weekIndex);
    newWeeks[index] = {
      ...newWeeks[index],
      weekType: typ,
      workUnits: typ === SchemeOfLearningWeekType.NO_HOMEWORK ? [] : newWeeks[index].workUnits,
    };
    return newWeeks;
  });

export const updateWeekAddTopic = (setter: SolWeekSetter, index: number, unit: WorkUnit) =>
  setter(value => {
    const week = value.find(w => w.weekIndex === index);
    if (!week) {
      // Add a new week: TODO, enable?
      return value;
      // return value.concat([
      //   SchemeOfLearningWeek.create({
      //     weekIndex: index,
      //     workUnits: [unit],
      //   }),
      // ]);
    } else {
      // Add to existing week
      return value.map(s =>
        s.weekIndex === index ? { ...s, workUnits: s.workUnits.concat([unit]) } : s,
      );
    }
  });

export const updateWeekRemoveTopic = (setter: SolWeekSetter, index: number, id: string) =>
  setter(value =>
    value.map(s =>
      s.weekIndex === index ? { ...s, workUnits: s.workUnits.filter(u => u.workUnitId !== id) } : s,
    ),
  );

export const updateWeekReorderTopic = (
  setter: SolWeekSetter,
  index: number,
  active: string,
  over: string,
) =>
  setter(value => {
    for (const week of value) {
      if (week.weekIndex === index) {
        const oldIndex = week.workUnits.findIndex(a => a.workUnitId === active);
        const newIndex = week.workUnits.findIndex(a => a.workUnitId === over);
        if (oldIndex !== -1 && newIndex !== -1) {
          week.workUnits = arrayMove(week.workUnits, oldIndex, newIndex);
        }
        break;
      }
    }
    return value;
  });

const deduplicateTopicsInAllWeeks = (setter: SolWeekSetter) =>
  setter(weeks => weeks.map(deduplicateTopicsInWeek));

const deduplicateTopicsInWeek = (week: SchemeOfLearningWeek) => {
  const seen = new Set<string>();
  const newUnits = [];
  for (const unit of week.workUnits) {
    if (unit.payload.oneofKind === 'topic') {
      if (seen.has(unit.payload.topic)) continue; // skip
      seen.add(unit.payload.topic);
    }
    newUnits.push(unit);
  }
  week.workUnits = newUnits;
  return week;
};

export const removeEmptyWeeks = (weeks: SchemeOfLearningWeek[]) => {
  const newWeeks: SchemeOfLearningWeek[] = [];

  let index = 1;
  for (const week of weeks) {
    if (week.weekType !== SchemeOfLearningWeekType.NO_HOMEWORK && week.workUnits.length > 0) {
      newWeeks.push({ ...week, weekIndex: index });
      index++;
    }
  }
  return newWeeks;
};

const findWeeksInHolidays = (
  weeks: SchemeOfLearningWeek[],
  calendarWeeks: Week[],
  withHomework = false,
): number[] => {
  return weeks
    .filter(week => {
      const calendarWeek = calendarWeeks.find(cw => cw.index === week.weekIndex);
      if (!calendarWeek) {
        return false;
      }

      const include =
        (week.weekType === SchemeOfLearningWeekType.NO_HOMEWORK) !== withHomework &&
        weekIsHolidayWeek(calendarWeek);
      return include;
    })
    .map(w => w.weekIndex);
};

// Minimum number of weekdays in a week to consider it a holiday week.
const MIN_DAYS_HOLIDAY_IN_WEEK = 5;
// Returns if a given week is a holiday week, true if every weekday is either
// a holiday or not during the academic year
export const weekIsHolidayWeek = (week: Week) => {
  const holidayDays = week.dates.filter(d => {
    // If the day is not a holiday and is not outside of the academic year
    if (!d.holiday && !d.outsideAY) return;

    const day = getDay(d.date);
    return !(day === 0 || day === 6);
  }).length;
  return holidayDays >= MIN_DAYS_HOLIDAY_IN_WEEK;
};

// turns off homework holiday weeks while moving all topics down.
// Modifies weeks in place.
export const turnHomeworkOffInHolidays = (weeks: SchemeOfLearningWeek[], calendarWeeks: Week[]) => {
  weeks = fillWeeks(weeks, calendarWeeks.length);

  let weekIndexes = findWeeksInHolidays(weeks, calendarWeeks, true);
  let lastIndexAttempted: number | undefined = undefined;
  while (weekIndexes.length > 0) {
    if (weekIndexes[0] === lastIndexAttempted) {
      break;
    }

    if (weeks[weekIndexes[0] - 1].workUnits.length > 0) {
      // Move all topics down
      weeks = doBulkMoveTopics(weeks, weekIndexes[0], 'down');
    }

    weeks[weekIndexes[0] - 1].weekType = SchemeOfLearningWeekType.NO_HOMEWORK;
    lastIndexAttempted = weekIndexes[0];
    weekIndexes = findWeeksInHolidays(weeks, calendarWeeks, true);
  }

  return weeks;
};

// adds empty weeks as needed, modifies weeks in place.
export const fillWeeks = (weeks: SchemeOfLearningWeek[], numWeeks: number) => {
  while (weeks.length < numWeeks) {
    weeks.push({
      weekIndex: weeks.length + 1,
      weekType: SchemeOfLearningWeekType.FULL_HOMEWORK,
      workUnits: [],
    });
  }
  return weeks;
};

export const squashIntoFinalWeek = (weeks: SchemeOfLearningWeek[], weekCount: number) => {
  const finalHomeworkWeekIdx = weeks.reduce(
    (finalWeekIdx, week, idx) =>
      week.weekType !== SchemeOfLearningWeekType.NO_HOMEWORK && week.weekIndex <= weekCount
        ? idx
        : finalWeekIdx,
    0,
  );
  weeks[finalHomeworkWeekIdx].workUnits = weeks
    .slice(finalHomeworkWeekIdx)
    .flatMap(week => week.workUnits);
  return weeks.slice(0, weekCount);
};

// creates a copy of the given SOL weeks, adjusting them to match the academic year and holidays.
export const copySolWeeks = (
  weeks: SchemeOfLearningWeek[],
  calendarWeeks: Week[],
  sameAY: boolean,
  offInHols: boolean,
) => {
  if (!sameAY) {
    // If we are copying to a different year, we need to adjust the weeks as holidays will be in different places
    // first remove any empty weeks.
    weeks = removeEmptyWeeks(weeks);
    // fill with blank weeks until the end of the year
    weeks = fillWeeks(weeks, calendarWeeks?.length || 0);

    if (offInHols) {
      // If we are turning homework off during holidays, we need to turn them off one by one while moving the topics down as needed.
      weeks = turnHomeworkOffInHolidays(weeks, calendarWeeks || []);
    }
  }

  // Make sure the number of weeks matches the number of weeks in the academic
  // year, by either adding or squashing weeks.
  weeks = fillWeeks(weeks, calendarWeeks?.length || 0);
  weeks = squashIntoFinalWeek(weeks, calendarWeeks?.length || 0);

  return weeks;
};

export const migrateWeekTopics = (
  weeks: SchemeOfLearningWeek[],
  migrationMap: Record<string, string[] | undefined>,
) => {
  let didMigrateTopics = false;
  const newWeeks = weeks.map<SchemeOfLearningWeek>(week => ({
    ...week,
    workUnits: week.workUnits.flatMap(unit => {
      if (unit.payload.oneofKind !== 'topic') return [unit];
      const newTopics = migrationMap[unit.payload.topic];
      if (!newTopics || newTopics.length === 0) return [unit];
      didMigrateTopics = true;
      return newTopics.map<WorkUnit>(topic => ({
        workUnitId: uuid(),
        payload: { oneofKind: 'topic', topic },
      }));
    }),
  }));
  return { newWeeks, didMigrateTopics };
};

export const hasTopicsToMigrate = (
  weeks: SchemeOfLearningWeek[],
  migrationMap: Record<string, string[] | undefined>,
) =>
  weeks.some(week =>
    week.workUnits.some(
      unit => unit.payload.oneofKind === 'topic' && !!migrationMap[unit.payload.topic],
    ),
  );

export const getSolActions = (setter: SolWeekSetter) => ({
  moveAllFrom: (start: number, dir: 'up' | 'down') => bulkMoveTopics(setter, start, dir),
  setWeekType: (weekIndex: number, typ: SchemeOfLearningWeekType) =>
    updateWeekType(setter, weekIndex, typ),
  addTopicToWeek: (weekIndex: number, unit: WorkUnit) =>
    updateWeekAddTopic(setter, weekIndex, unit),
  removeTopicFromWeek: (weekIndex: number, id: string) =>
    updateWeekRemoveTopic(setter, weekIndex, id),
  reorderTopicInWeek: (weekIndex: number, activeTopic: string, overTopic: string) =>
    updateWeekReorderTopic(setter, weekIndex, activeTopic, overTopic),
  deduplicateTopics: () => deduplicateTopicsInAllWeeks(setter),
});

export const parseDraggableId = (id: UniqueIdentifier | undefined) => {
  if (typeof id === 'string') {
    const out: { week: number | undefined; id: string | undefined } = {
      week: undefined,
      id: undefined,
    };
    for (const part of id.split(':')) {
      if (part.startsWith('week/')) {
        out.week = parseInt(part.split('/')[1]);
      } else {
        out.id = part;
      }
    }
    return out;
  }
  return undefined;
};

export const rowHasPublishedVersion = (
  sow: SchemeOfLearning | SchemeOfLearningTemplate,
  sols: SchemeOfLearning[] | SchemeOfLearningTemplate[],
) =>
  sow.metadata?.status === ResourceStatus.DRAFT &&
  sols.find(s => s.name === sow.name && s.metadata?.status === ResourceStatus.PUBLISHED);

export const rowHasDraftVersion = (
  sow: SchemeOfLearning,
  sols: SchemeOfLearning[] | SchemeOfLearningTemplate[],
) =>
  sow.metadata?.status === ResourceStatus.PUBLISHED &&
  sols.find(s => s.name === sow.name && s.metadata?.status === ResourceStatus.DRAFT);
