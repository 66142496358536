import { Box, Button, Grid, GridItem, HStack, Text } from '@chakra-ui/react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubstrandSummary } from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';
import { useCurriculums } from 'api/content';
import { AnimatePresence, motion } from 'framer-motion';
import React, { PropsWithChildren, useState } from 'react';

import { FilterFields, TopicSearch } from './topicsearch/TopicSearch';

interface TopicPickerProps {
  defaultCurriculumName?: string;
  onSelectSubstrand: (substrand: SubstrandSummary | undefined) => void;
  height?: string;
  subject?: string;
}

export const TopicPicker = ({
  children,
  defaultCurriculumName,
  ...props
}: PropsWithChildren<TopicPickerProps>) => {
  const [filter, _setFilter] = useState<FilterFields>({
    curriculum: defaultCurriculumName || '',
    search: '',
  });
  const setFilter = (newFilter: Partial<FilterFields>) => _setFilter({ ...filter, ...newFilter });

  return (
    <TopicPickerWithFilter filter={filter} setFilter={setFilter} {...props}>
      {children}
    </TopicPickerWithFilter>
  );
};

interface TopicPickerWithFilterProps extends TopicPickerProps {
  hideCurriculumSelection?: boolean;
  filter: FilterFields;
  setFilter: (filter: Partial<FilterFields>) => void;
}

export const TopicPickerWithFilter = ({
  onSelectSubstrand: _onSelectSubstrand,
  height,
  subject,
  hideCurriculumSelection,
  filter,
  setFilter,
  children,
}: PropsWithChildren<TopicPickerWithFilterProps>) => {
  const [selectedStrandName, setSelectedStrand] = useState('');
  const [selectedSubstrandName, setSelectedSubstrandName] = useState('');

  const { data: curriculums = [] } = useCurriculums({ suspense: true, subject });
  const selectedCurriculum = curriculums.find(c => c.curriculum?.name === filter.curriculum);
  const curriculumSubject = selectedCurriculum?.curriculum?.subjectKey;
  const selectedStrand = selectedCurriculum?.strandSummaries.find(
    strand => strand.strand?.name === selectedStrandName,
  );
  const selectedSubstrand = selectedStrand?.substrandSummaries.find(
    substrand => substrand.substrand?.name === selectedSubstrandName,
  );

  const onSelectSubstrand = (substrand: SubstrandSummary | undefined) => {
    setSelectedSubstrandName(substrand?.substrand?.name || '');
    _onSelectSubstrand(substrand);
  };

  return (
    <>
      {!hideCurriculumSelection && (
        <Box bg="white" px={4} pt={2}>
          <TopicSearch
            filter={filter}
            setFilter={setFilter}
            mustSelect={true}
            hideSearch={true}
            subject={subject}
          />
        </Box>
      )}
      <Box bg="white" flex={1} position="relative" overflow="hidden" minH={height}>
        <Grid gridTemplateColumns="0.4fr 1fr" p={4} width="100%" height="100%" position="absolute">
          <GridItem
            marginRight="-1px"
            borderRightWidth={1}
            borderRightColor="gray.300"
            overflowY="auto"
          >
            <Text
              mb={2}
              px={4}
              pb={2}
              borderBottomWidth={1}
              borderBottomColor="gray.300"
              color="gray.600"
              fontWeight="bold"
              fontSize="sm"
              position="sticky"
              bg="white"
              top={0}
              zIndex={1}
            >
              {curriculumSubject === 'science' ? 'Subject' : 'Strand'}
            </Text>
            {selectedCurriculum?.strandSummaries.map(strand => (
              <Button
                borderRadius="none"
                variant={selectedStrandName === strand.strand?.name ? 'solid' : 'ghost'}
                width="100%"
                justifyContent="flex-start"
                key={strand.strand?.name}
                colorScheme={selectedStrandName === strand.strand?.name ? 'blue' : 'white'}
                onClick={() => setSelectedStrand(strand.strand?.name || '')}
                whiteSpace="normal"
                textAlign="left"
                height="auto"
                py={3}
              >
                {strand.strand?.displayName}
              </Button>
            ))}
          </GridItem>
          <AnimatePresence>
            {selectedStrand && (
              <GridItem
                as={motion.div}
                initial={{ transform: 'translateX(200px)', opacity: 0 }}
                animate={{ transform: 'translateX(0)', opacity: 1 }}
                exit={{ transform: 'translateX(200px)', opacity: 0 }}
                borderLeftWidth={1}
                borderLeftColor="gray.300"
                overflowY="hidden"
                height="100%"
                display="flex"
                flexDirection="column"
              >
                <Text
                  bg="white"
                  px={4}
                  pb={2}
                  borderBottomWidth={1}
                  borderBottomColor="gray.300"
                  color="gray.600"
                  fontWeight="bold"
                  fontSize="sm"
                  flex={0}
                >
                  {curriculumSubject === 'science' ? 'Unit' : 'Substrand'}
                </Text>
                <Box height="100%" overflowY="auto" flex={1}>
                  <Box mt={2}>
                    {selectedStrand?.substrandSummaries
                      .filter(s => s.substrand?.topicNames.length)
                      .map(substrand => (
                        <Button
                          borderRadius="none"
                          variant="ghost"
                          width="100%"
                          fontWeight="normal"
                          justifyContent="flex-start"
                          key={substrand.substrand?.name}
                          whiteSpace="normal"
                          textAlign="left"
                          py={2}
                          onClick={() => onSelectSubstrand(substrand)}
                          flex={1}
                          height="auto"
                        >
                          {substrand.substrand?.displayName}
                        </Button>
                      ))}
                  </Box>
                </Box>
              </GridItem>
            )}
          </AnimatePresence>
        </Grid>
        <AnimatePresence>
          {selectedSubstrand && (
            <Box
              overflowY="auto"
              position="absolute"
              inset="0 0 0 0"
              bg="white"
              zIndex={2}
              as={motion.div}
              boxShadow="md"
              initial={{ transform: 'translateX(100%)' }}
              animate={{ transform: 'translateX(0)' }}
              exit={{ transform: 'translateX(100%)' }}
            >
              <HStack spacing={4} py={2} px={4} position="sticky" top={0} zIndex={2} bg="white">
                <Button
                  leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                  onClick={() => onSelectSubstrand(undefined)}
                >
                  Back
                </Button>
                <Text fontSize="lg" fontWeight="bold">
                  {selectedSubstrand.substrand?.displayName}
                </Text>
              </HStack>
              {children}
            </Box>
          )}
        </AnimatePresence>
      </Box>
    </>
  );
};
