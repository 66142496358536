import { Student } from '@sparx/api/apis/sparx/misintegration/wondewitch/v1/wondewitch';

/**
 * Estimate the year group from a list of students based on there currentNcYear.
 * @param students
 * @returns
 */
export const estimateYearGroupFromStudents = (students: Student[]): number | null => {
  const frequencyMap: Record<number, number> = students
    // Get the currentNcYear from each student, this represents the year group.
    .map(s => s.educationDetails?.currentNcYear)
    // Count the frequency of each year group.
    .reduce((freqs: Record<number, number>, year) => {
      const yearNumber = Number(year);
      // If the year is a number and not null or undefined, increment the frequency.
      if (year !== '' && !isNaN(yearNumber)) {
        if (freqs[yearNumber] == null) {
          freqs[yearNumber] = 0;
        }
        freqs[yearNumber]++;
      }
      return freqs;
    }, {});

  // If there are no years just return null.
  const years: number[] = Object.keys(frequencyMap).map(Number);
  if (years.length === 0) {
    return null;
  }
  // Find the year group with the highest frequency.
  return years.reduce<number | null>((yearA, yearB) => {
    const f = frequencyMap[yearB];
    if (f > 0) {
      return yearA !== null && frequencyMap[yearA] > f ? yearA : yearB;
    }
    return yearA;
  }, null);
};

// Parse the subject name to replace &amp; with &
export const parseSubjectName = (subjectName: string) => {
  return subjectName.replace(/&amp;/g, '&');
};
