import {
  Alert,
  AlertIcon,
  Box,
  Button,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { faCaretDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import { LessonFeature } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { useCreateLesson } from 'api/lessons';
import { useGroups, useSortedGroups } from 'api/school';
import { getSchoolID } from 'api/sessions';
import { format } from 'date-fns';
import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useTeamTeaching = import.meta.env.VITE_SPARX_PRODUCT === 'SPARX_TEACHING';

export const CreateLessonModal = ({ groupID }: { groupID?: string }) => {
  const navigate = useNavigate();

  const { data: allGroups = [] } = useGroups({ suspense: true });
  const groups = useSortedGroups(allGroups, true);
  const scienceGroups = useMemo(
    () => groups.filter(g => g.type === StudentGroupType.CLASS_SCIENCE),
    [groups],
  );
  const otherGroups = useMemo(
    () => groups.filter(g => g.type !== StudentGroupType.CLASS_SCIENCE),
    [groups],
  );

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [displayName, setDisplayName] = useState('');
  const [selectedGroup, setSelectedGroup] = useState<string[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: async () => {
      const schoolID = await getSchoolID();

      const groupName = groupID ? `schools/${schoolID}/studentGroups/${groupID}` : undefined;
      const foundGroup = groupName ? allGroups.find(g => g.name === groupName) : undefined;
      setSelectedGroup(foundGroup ? [foundGroup.name] : []);
      setDisplayName(`${format(new Date(), 'd/M')} lesson`);

      // Select the lesson name
      setTimeout(() => inputRef.current?.select?.(), 0);
    },
  });

  const selectedGroupName =
    selectedGroup.length === 1 ? groups.find(g => g.name === selectedGroup[0])?.displayName : '';

  const { mutateAsync, isLoading, error, reset } = useCreateLesson();
  const valid = displayName.trim().length >= 4;
  const submit = () => {
    if (valid) {
      mutateAsync({
        displayName: displayName.trim(),
        groupNames: selectedGroup,
        features: useTeamTeaching ? [LessonFeature.TEAM_TEACHING] : [],
      }).then(data => navigate(`/teacher/lessons/${data.lesson?.lessonName.split('/')[3]}`));
    }
  };

  return (
    <>
      <Button
        colorScheme="buttonTeal"
        onClick={onOpen}
        leftIcon={<FontAwesomeIcon icon={faPlus} />}
      >
        Create lesson
      </Button>
      <Modal isOpen={isOpen} size="xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={e => e.preventDefault()}>
          <ModalHeader pb={1}>Create lesson</ModalHeader>
          <ModalBody>
            <Text mb={3}>Enter a name for the lesson:</Text>
            {Boolean(error) && (
              <Alert status="error" mb={3} borderRadius="md">
                <AlertIcon />
                <Text>
                  <Text fontWeight="bold" color="red.700" display="inline-block" mr={2}>
                    Error:
                  </Text>
                  {translateErrorMesage(error)}
                </Text>
              </Alert>
            )}
            <Input
              ref={inputRef}
              value={displayName}
              onChange={e => {
                if (error) reset();
                setDisplayName(e.target.value);
              }}
            />

            <HStack mt={4} spacing={2}>
              <Text color="gray.600">Select class:</Text>
              <Menu gutter={0}>
                <MenuButton as={Button} rightIcon={<FontAwesomeIcon icon={faCaretDown} />}>
                  {selectedGroupName || 'No class'}
                </MenuButton>
                <MenuList py={0} overflow="hidden" zIndex={20}>
                  <Box maxHeight="50vh" overflowY="auto">
                    <MenuGroup title="Science classes">
                      {scienceGroups?.map(group => (
                        <MenuItem key={group.name} onClick={() => setSelectedGroup([group.name])}>
                          {group.displayName}
                        </MenuItem>
                      ))}
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroup title="Other classes">
                      {otherGroups?.map(group => (
                        <MenuItem key={group.name} onClick={() => setSelectedGroup([group.name])}>
                          {group.displayName}
                        </MenuItem>
                      ))}
                    </MenuGroup>
                  </Box>
                </MenuList>
              </Menu>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} mr={2} variant="outline" isDisabled={isLoading}>
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={submit}
              colorScheme="buttonTeal"
              isDisabled={!valid}
              isLoading={isLoading}
            >
              Create lesson
            </Button>
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};

const translateErrorMesage = (err: unknown) => {
  if (err instanceof RpcError) {
    if (err.code === 'NOT_FOUND') return 'Lesson not found';
    return err.message;
  }
  return 'Unknown error';
};
