import * as RadixAccordion from '@radix-ui/react-accordion';
import { ChangeType, toggleViewStudentChanges } from '@sparx/mis-sync-import/src/analytics';
import { useMisSyncContext } from '@sparx/mis-sync-import/src/Context';
import { ChevronRight } from '@sparx/sparx-design/icons';
import classNames from 'classnames';
import { ReactNode, useState } from 'react';

import styles from './Accordion.module.css';

export const Accordion = ({
  preview,
  children,
  variant,
  defaultOpen,
  type,
}: {
  preview: ReactNode;
  children: ReactNode;
  variant?: 'warning';
  defaultOpen?: boolean;
  type: ChangeType;
}) => {
  const { sendEvent } = useMisSyncContext();
  const [isOpen, setIsOpen] = useState<boolean>(!!defaultOpen);
  return (
    <RadixAccordion.Root
      collapsible
      type="single"
      defaultValue={isOpen ? 'open' : undefined}
      className={classNames(styles.Accordion, {
        [styles.Warning]: variant === 'warning',
      })}
    >
      <RadixAccordion.Item value="open">
        <RadixAccordion.Trigger
          onClick={() => {
            setIsOpen(v => !v);
            sendEvent(toggleViewStudentChanges(!isOpen, type));
          }}
          className={classNames(styles.Button, {
            [styles.Warning]: variant === 'warning',
          })}
        >
          <ChevronRight
            className={classNames(styles.Chevron, {
              [styles.Open]: isOpen,
            })}
          />
          <div className={styles.PreviewContent}>{preview}</div>
        </RadixAccordion.Trigger>
        <RadixAccordion.Content className={styles.AccordionContent}>
          {children}
        </RadixAccordion.Content>
      </RadixAccordion.Item>
    </RadixAccordion.Root>
  );
};
