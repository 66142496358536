import { Alert, AlertIcon, Box, Button, Flex, Textarea } from '@chakra-ui/react';
import { MessageType } from '@protobuf-ts/runtime';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import React, { useState } from 'react';

interface JsonEditableProps<T extends object, MT extends MessageType<T>> {
  messageType: MT;
  original: T;
  set: (value: T) => void;
  error?: Error;
  setValid: (valid: boolean) => void;
}

export const JsonEditable = <T extends object, MT extends MessageType<T>>({
  messageType,
  original,
  set,
  error,
  setValid,
}: JsonEditableProps<T, MT>) => {
  const [valid, _setValid] = useState(true);
  const [value, _setValue] = useState(
    messageType.toJsonString(original, { prettySpaces: 2, emitDefaultValues: true }),
  );

  const format = () =>
    _setValue(messageType.toJsonString(original, { prettySpaces: 2, emitDefaultValues: true }));

  const setValue = (value: string) => {
    _setValue(value);
    try {
      set(messageType.fromJsonString(value));
      _setValid(true);
      setValid(true);
    } catch (e) {
      _setValid(false);
      setValid(false);
    }
  };

  return (
    <>
      {error && (
        <Alert status="error">
          <AlertIcon />
          {(error as RpcError).message}
        </Alert>
      )}
      {valid ? (
        <Alert status="success">
          <Flex>
            <AlertIcon />
            Valid input
          </Flex>
          <Box flex={1} />
          <Button my={-2} onClick={format} colorScheme="green" variant="outline" size="sm">
            Format
          </Button>
        </Alert>
      ) : (
        <Alert status="error">
          <AlertIcon />
          Invalid input
        </Alert>
      )}
      <Textarea
        placeholder="Contents"
        value={value}
        onChange={e => setValue(e.target.value)}
        height="70vh"
        fontSize="14px"
        fontFamily="monospace"
      />
    </>
  );
};
