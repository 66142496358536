import {
  InvalidStudent,
  ModifiedClass,
  ModifiedStudent,
  PreviewClass,
  PreviewStudent,
  PreviewSyncSchoolV2Response,
  SyncPlan,
} from '@sparx/api/apis/sparx/misintegration/wondesync/v1/wondesync';
import { Class } from '@sparx/api/apis/sparx/misintegration/wondewitch/v1/wondewitch';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { ChangeType, syncStartedWithNoChanges } from '@sparx/mis-sync-import/src/analytics';
import { useMisSyncContext } from '@sparx/mis-sync-import/src/Context';
import { useTimeVisible } from '@sparx/mis-sync-import/src/hooks';
import {
  Accordion,
  Footer,
  SupportLink,
} from '@sparx/mis-sync-import/src/MisSyncImport/components/common';
import Button from '@sparx/mis-sync-import/src/MisSyncImport/components/common/Button/Button';
import GenericErrorPanel from '@sparx/mis-sync-import/src/MisSyncImport/components/errors/GenericErrorPanel';
import PreviewError from '@sparx/mis-sync-import/src/MisSyncImport/components/errors/PreviewError';
import { StudentChangesTable } from '@sparx/mis-sync-import/src/MisSyncImport/components/StudentChangesTable';
import LoadingMessage from '@sparx/mis-sync-import/src/MisSyncImport/pages/LoadingMessage';
import {
  getDateOfBirth,
  hasBeenUpdated,
  mapClassUpdates,
  mapStudentChanges,
  mapStudentPreviews,
  Preview,
} from '@sparx/mis-sync-import/src/processing';
import { SchoolData } from '@sparx/mis-sync-import/src/types';
import { getSystemOptions, hasChanges } from '@sparx/mis-sync-import/src/utils';
import { Chip, InfoMessage, WarningMessage } from '@sparx/sparx-design/components';
import { ChevronLeft, TriangleExclamationIcon } from '@sparx/sparx-design/icons';
import classNames from 'classnames';
import { ReactNode } from 'react';

import styles from './MisSyncPreviewPage.module.css';

const MisSyncPreviewPage = ({
  preview,
  error,
  schoolData,
  onCancel,
  onSync,
  isLoading,
  syncPlan,
  wondeClasses,
}: {
  preview?: PreviewSyncSchoolV2Response;
  error?: Error | null;
  schoolData: SchoolData;
  onCancel: (buttonLocation: 'top' | 'bottom', timeVisible: number) => void;
  onSync: (timeVisible: number) => void;
  isLoading: boolean;
  syncPlan?: SyncPlan;
  wondeClasses: Class[];
}) => {
  const timeVisible = useTimeVisible();

  const { groupSubject, sendEvent, school } = useMisSyncContext();
  const { dataTheme } = getSystemOptions(groupSubject);

  const classesAreBeingRemoved = !!preview && preview.removedClasses.length > 0;

  const onSubmit = () => {
    if (!error && preview) {
      const { hasClassChanges, hasStudentChanges } = hasChanges(preview);
      if (!hasClassChanges && !hasStudentChanges) {
        sendEvent(syncStartedWithNoChanges(timeVisible.getTimeVisible()));
      }
      onSync(timeVisible.getTimeVisible());
    }
  };

  return (
    <>
      <div className={styles.Content} data-theme={dataTheme}>
        <div className={styles.PageHeader}>
          <Button
            onClick={() => onCancel('top', timeVisible.getTimeVisible())}
            colour="secondary"
            className={styles.BackButton}
          >
            <ChevronLeft />
          </Button>

          <h2>Preview changes</h2>
        </div>

        {error ? (
          <PreviewError
            error={error}
            sparxStudents={schoolData.students}
            wondeClasses={wondeClasses}
          />
        ) : isLoading || school === undefined ? (
          <LoadingMessage message="Loading preview" />
        ) : preview ? (
          <PreviewContent
            preview={preview}
            schoolData={schoolData}
            groupSubject={groupSubject}
            syncPlan={syncPlan}
            schoolProducts={school.products}
          />
        ) : (
          <GenericErrorPanel task={'preview the requested changes'} />
        )}
      </div>

      {!error && !isLoading && (
        <Footer
          variant={classesAreBeingRemoved ? 'warning' : 'info'}
          onCancel={() => onCancel('bottom', timeVisible.getTimeVisible())}
          onSubmit={onSubmit}
          submitText="Sync changes"
          isDisabled={syncPlan?.actions.length === 0}
        >
          <div>
            {classesAreBeingRemoved && (
              <p>
                If you remove classes, their homework plans and hand-in history{' '}
                <strong>will be lost</strong>.
                <br />
                If this isn&apos;t your intention, or if you are unsure, please contact the{' '}
                <SupportLink linkText="School Success Team" /> before proceeding.
              </p>
            )}
            <p>
              If students are moving to new classes, you&apos;ll still be able to see their homework
              history on their student profile, but you&apos;ll no longer be able to see whole-class
              history for the classes that you&apos;re removing.
            </p>
          </div>
        </Footer>
      )}
    </>
  );
};

const ClassChanges = ({ classes, type }: { classes: PreviewClass[]; type: 'new' | 'removed' }) => {
  const { sparxStaffFeaturesEnabled } = useMisSyncContext();

  if (!classes.length) {
    return null;
  }

  const heading = () => {
    switch (type) {
      case 'new':
        return 'Adding classes:';
      case 'removed':
        return 'Removing classes:';
    }
  };

  return (
    <div className={styles.ClassChanges}>
      <p
        className={classNames(styles.Header, {
          [styles.Added]: type === 'new',
          [styles.Removed]: type === 'removed',
        })}
      >
        {type === 'removed' && (
          <span className={styles.Warning}>
            <TriangleExclamationIcon />
          </span>
        )}
        {heading()}
      </p>
      <div className={styles.Content}>
        {classes
          .sort((a, b) => a.displayName.localeCompare(b.displayName, undefined, { numeric: true }))
          .map(c => (
            <Chip
              key={c.displayName}
              shapeVariant="Boxy"
              className={classNames({
                [styles.Added]: type === 'new',
                [styles.Removed]: type === 'removed',
              })}
            >
              {c.displayName} {sparxStaffFeaturesEnabled ? `(${c.wondeId})` : ''}
            </Chip>
          ))}
      </div>
    </div>
  );
};

const ClassUpdates = ({
  classes,
  schoolData,
}: {
  classes: ModifiedClass[];
  schoolData: SchoolData;
}) => {
  const { sparxStaffFeaturesEnabled } = useMisSyncContext();

  const classUpdates = mapClassUpdates(classes);
  if (!classUpdates || !classUpdates.length) {
    return null;
  }

  const renderWondeId = (wondeId: Preview) => {
    if (!sparxStaffFeaturesEnabled) {
      return null;
    }

    if (Array.isArray(wondeId)) {
      return `(${wondeId[0]} -> ${wondeId[1]})`;
    }

    return `(${wondeId})`;
  };

  return (
    <div className={styles.ClassChanges}>
      <p className={classNames(styles.Header, styles.Updated)}>Updating classes:</p>
      <div className={styles.ClassUpdatesContent}>
        {classUpdates.map((c, i) => {
          return (
            <div className={styles.ClassUpdates} key={i}>
              {!hasBeenUpdated(c.displayName) ? (
                <Chip
                  shapeVariant="Boxy"
                  className={classNames(styles.Updated, styles.DisplayName)}
                >
                  {c.displayName} {renderWondeId(c.wondeId)}
                </Chip>
              ) : (
                <>
                  <Chip shapeVariant="Boxy" className={styles.Removed}>
                    {c.displayName[0]} {renderWondeId(c.wondeId)}
                  </Chip>{' '}
                  renamed to{' '}
                  <Chip
                    shapeVariant="Boxy"
                    className={classNames(styles.Added, {
                      [styles.WithMargin]: hasBeenUpdated(c.yearGroupId),
                    })}
                  >
                    {c.displayName[1]}
                  </Chip>
                </>
              )}
              {hasBeenUpdated(c.yearGroupId) && (
                <>
                  <Chip shapeVariant="Boxy" className={styles.Removed}>
                    {schoolData.yearGroups[c.yearGroupId[0]].name}
                  </Chip>{' '}
                  changed to{' '}
                  <Chip shapeVariant="Boxy" className={styles.Added}>
                    {schoolData.yearGroups[c.yearGroupId[1]].name}
                  </Chip>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const StudentPreviews = ({
  students,
  children,
  type,
  schoolProducts,
}: {
  students: PreviewStudent[];
  children: ReactNode;
  type: ChangeType;
  schoolProducts: Product[];
}) => {
  const previewStudents = mapStudentPreviews(students);
  if (!previewStudents || !previewStudents.length) {
    return null;
  }

  return (
    <Accordion preview={children} type={type}>
      <StudentChangesTable students={previewStudents} schoolProducts={schoolProducts} />
    </Accordion>
  );
};

const StudentChanges = ({
  students,
  children,
  type,
  schoolProducts,
}: {
  students: ModifiedStudent[];
  children: ReactNode;
  type: ChangeType;
  schoolProducts: Product[];
}) => {
  const modifiedStudents = mapStudentChanges(students);
  if (!modifiedStudents || !modifiedStudents.length) {
    return null;
  }

  return (
    <Accordion preview={children} type={type}>
      <StudentChangesTable students={modifiedStudents} schoolProducts={schoolProducts} />
    </Accordion>
  );
};

const InvalidStudents = ({
  students,
  children,
}: {
  students: InvalidStudent[];
  children: ReactNode;
}) => {
  if (!students.length) {
    return null;
  }

  // Build the message to display for each student
  const getMessage = (s: InvalidStudent) => {
    const firstName = s.firstName || '[NO FIRST NAME]';
    const lastName = s.lastName || '[NO LAST NAME]';
    const dateOfBirth = s.dateOfBirth ? getDateOfBirth(s.dateOfBirth) : '[NO DATE OF BIRTH]';
    const classes = s.wondeGroups.map(g => g.name).join(', ');

    return (
      <>
        <span data-hj-suppress>{firstName}</span> <span data-hj-suppress>{lastName}</span> (
        <span data-hj-suppress>{dateOfBirth}</span>){' '}
        {s.wondeGroups.length === 1 && ` in class ${classes}`}
        {s.wondeGroups.length > 1 && ` in classes ${classes}`}
      </>
    );
  };

  return (
    <Accordion defaultOpen variant="warning" preview={children} type="invalid">
      <div>
        <p style={{ marginTop: 0 }}>
          Sparx requires all students to have a first name, last name and date of birth. To import
          these students, please resolve this issue in your MIS and try again.
        </p>
        <ul>
          {students.map(s => (
            <li key={s.id}>{getMessage(s)}</li>
          ))}
        </ul>
      </div>
    </Accordion>
  );
};

const PreviewContent = ({
  preview,
  schoolData,
  groupSubject,
  syncPlan,
  schoolProducts,
}: {
  preview: PreviewSyncSchoolV2Response;
  schoolData: SchoolData;
  groupSubject: StudentGroupType;
  syncPlan?: SyncPlan;
  schoolProducts: Product[];
}) => {
  const {
    newClasses,
    removedClasses,
    modifiedClasses,
    newStudents,
    removedStudents,
    modifiedStudents,
    unexpiredStudents,
    invalidStudents,
    removedStudentsWithNoClass,
  } = preview;

  const { hasClassChanges, hasStudentChanges, hasInvalidStudents } = hasChanges(preview);

  const noChanges = !hasClassChanges && !hasStudentChanges;

  const { system } = getSystemOptions(groupSubject);
  const { sparxStaffFeaturesEnabled } = useMisSyncContext();

  const totalActionCount = syncPlan?.actions?.length || 0;
  const removedStudentsWithNoClassCount = removedStudentsWithNoClass.length;

  return (
    <>
      {noChanges && !hasInvalidStudents && (
        <div>
          {!totalActionCount ? (
            <p>There are no changes to preview.</p>
          ) : (
            <>
              <p>
                There are only minor updates available for syncing, such as changes to registration
                groups or student demographic data.
              </p>
              <p>The details of these changes are not available to preview.</p>
            </>
          )}
        </div>
      )}

      {invalidStudents.length > 0 && (
        <div>
          <InvalidStudents students={invalidStudents}>
            <WarningMessage
              className={styles.InvalidStudentsWarning}
              message={
                <>
                  {invalidStudents.length} {invalidStudents.length === 1 ? 'student' : 'students'}{' '}
                  in your MIS/SIS {invalidStudents.length === 1 ? 'is' : 'are'} missing required
                  details and will not be imported
                </>
              }
            />
          </InvalidStudents>
        </div>
      )}

      {hasClassChanges && (
        <div>
          <h3 className={styles.ChangesContainerHeader}>Class changes</h3>
          <div className={styles.ClassChangesContainer}>
            <ClassChanges classes={newClasses} type="new" />
            <ClassChanges classes={removedClasses} type="removed" />
            <ClassUpdates classes={modifiedClasses} schoolData={schoolData} />
          </div>
        </div>
      )}

      {removedStudentsWithNoClassCount > 0 && (
        <InfoMessage
          message={`${removedStudentsWithNoClassCount} ${removedStudentsWithNoClassCount === 1 ? 'student' : 'students'} will be removed from Sparx as they are no longer in any classes.`}
        />
      )}
      {removedStudentsWithNoClass.length > 0 && sparxStaffFeaturesEnabled && (
        <StudentPreviews
          students={removedStudentsWithNoClass}
          type="removed"
          schoolProducts={schoolProducts}
        >
          <h4>
            Students whose accounts will be removed due to being in no class (
            {removedStudentsWithNoClass.length})
          </h4>
        </StudentPreviews>
      )}

      {hasStudentChanges && (
        <div>
          <h3 className={styles.ChangesContainerHeader}>Student changes</h3>
          <div>
            <StudentPreviews
              students={removedStudents}
              type="removed"
              schoolProducts={schoolProducts}
            >
              <h4>Students whose accounts will be removed ({removedStudents.length})</h4>
              <p>
                <strong>
                  {removedStudents.length === 1 ? 'This student' : 'These students'} will no longer
                  be able to log in to their {system === 'Unknown' ? 'Sparx' : system} account.
                </strong>
              </p>
              <p>All their data is preserved in case their account is reinstated.</p>
            </StudentPreviews>

            <StudentPreviews students={newStudents} type="new" schoolProducts={schoolProducts}>
              <h4>New student accounts which will be added ({newStudents.length})</h4>
            </StudentPreviews>

            <StudentChanges
              students={modifiedStudents}
              type="updated"
              schoolProducts={schoolProducts}
            >
              <h4>Students whose accounts will be updated ({modifiedStudents.length})</h4>
            </StudentChanges>

            <StudentChanges
              students={unexpiredStudents}
              type="reinstated"
              schoolProducts={schoolProducts}
            >
              <h4>Students whose accounts will be reinstated ({unexpiredStudents.length})</h4>
            </StudentChanges>
          </div>
        </div>
      )}
    </>
  );
};

export default MisSyncPreviewPage;
