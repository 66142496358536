import { Alert, AlertIcon, Box, Input, Text } from '@chakra-ui/react';
import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { StaffRoleAssignment } from '@sparx/api/apis/sparx/school/staff/v2/staff';
import {
  ChakraStylesConfig,
  createFilter,
  FormatOptionLabelMeta,
  InputActionMeta,
  Select as CRSelect,
} from 'chakra-react-select';
import { ComponentProps, FocusEventHandler, useEffect, useMemo, useState } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import { ContactEditModel } from '../utils';
import { emailValidationRegex } from '../validation';
import { EditableField } from './FormFields';
import { ErrorFormatter, UserEditableField as FieldName } from './validationMessages';

export type AutocompleteMode = 'none' | 'free-entry' | 'selection' | 'selection-blur';

const Select = <T,>(
  props: ComponentProps<typeof CRSelect<T>> & { chakraStyles: ChakraStylesConfig<typeof CRSelect> },
) => <CRSelect {...props} />;

interface FieldProps {
  /** The property this field is controlling */
  field: Exclude<FieldName, 'displayName'>;
  /** Specifies the behaviour of the dropdown, if any */
  mode?: AutocompleteMode;
  /**
   * If present on a control with a dropdown, presents this text as
   * an option to remove the current record
   */
  removeText?: string;
}

interface StaffDetailsFieldProps extends FieldProps {
  required?: boolean;
  allStaff: SchoolStaffMember[];
  keyContactRole?: StaffRoleAssignment;
  filterOtherStaff?: (sm: SchoolStaffMember) => boolean;
  readOnly?: boolean;
  /** A function for returning a relevant error message for this field */
  getErrorMessage?: ErrorFormatter;
}

export const StaffDetailsField = ({
  field,
  mode = 'none',
  allStaff,
  keyContactRole,
  readOnly = false,
  getErrorMessage,
  filterOtherStaff,
  removeText = '',
}: StaffDetailsFieldProps) => {
  const { control, register } = useFormContext<ContactEditModel>();

  register('blockSave', { validate: val => !val });
  register('willRemove');

  // When this is a free entry, we want to *stop* users from entering an existing email address.
  // When this is an autocomplete, we want to *allow* users to select an existing email address.
  const blockExistingEmail = field === 'emailAddress' && (mode === 'none' || mode === 'free-entry');

  const validation: Partial<RegisterOptions<ContactEditModel, `staffMember.${typeof field}`>> = {
    validate: {
      /**
       * Make this field required *unless* `willRemove` is set, allowing the user
       * to unset a key contact. Note the key name `required` which `EditableField`
       * uses to correctly display an appropriate message.
       */
      required: (value, { willRemove }) => willRemove || value.trim() !== '',

      ...(blockExistingEmail && {
        /**
         * Detect an email address that matches an existing contact and provide a link to
         * edit that staff member (see the error logic in `EditableField`)
         */
        alreadyExists: (_, { staffMember }) =>
          allStaff.find(
            s =>
              s.name !== staffMember.name &&
              s.emailAddress.trim().toLocaleLowerCase() ===
                staffMember.emailAddress.trim().toLocaleLowerCase(),
          )?.name ?? true,
      }),
    },

    ...(field === 'emailAddress' && {
      pattern: { value: emailValidationRegex, message: 'Please enter a valid email address' },
    }),
  };

  const otherStaff = useMemo(
    () => (filterOtherStaff ? allStaff.filter(filterOtherStaff) : allStaff),
    [allStaff, filterOtherStaff],
  );

  return (
    <Controller
      name={`staffMember.${field}`}
      control={control}
      rules={validation}
      render={({ field: { ref, onChange, onBlur, value, name }, fieldState: { error } }) => (
        <EditableField
          field={field}
          error={error}
          getErrorMessage={getErrorMessage}
          hideLabel={keyContactRole !== undefined}
        >
          {mode === 'none' || readOnly || !allStaff.length ? (
            <>
              <Input
                name={name}
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                isDisabled={readOnly}
              />
              {/* Assume, for now, there's only one reason the email field would be readOnly */}
              {readOnly && field === 'emailAddress' && (
                <Alert mt={4} status="info" borderRadius="md">
                  <AlertIcon />
                  <Box>
                    <Text>
                      This staff member has access to multiple schools. To change their email
                      address, you can either ask them to log in and change it themselves or contact
                      us.
                    </Text>
                  </Box>
                </Alert>
              )}
            </>
          ) : (
            <RHFSDFAutoComplete
              field={field}
              mode={mode === 'selection-blur' ? 'selection' : mode}
              selectOnBlur={mode === 'selection-blur'}
              allStaff={otherStaff}
              keyContactRole={keyContactRole}
              removeText={removeText}
            />
          )}
        </EditableField>
      )}
    />
  );
};

type Action =
  | { type: 'SELECT_STAFF_MEMBER'; staffMember: Partial<SchoolStaffMember> }
  | { type: 'UPDATE_STAFF_DETAILS'; details: Partial<SchoolStaffMember> }
  | { type: 'SET_SAVE_BLOCKED'; value: boolean }
  | { type: 'REMOVE_STAFF_MEMBER' };

const RHFSDFAutoComplete = ({
  field,
  mode,
  selectOnBlur = false,
  allStaff,
  removeText = '',
}: {
  field: Exclude<FieldName, 'displayName'>;
  mode: Exclude<AutocompleteMode, 'none' | 'selection-blur'>;
  selectOnBlur?: boolean;
  allStaff: SchoolStaffMember[];
  keyContactRole?: StaffRoleAssignment;
  removeText?: string;
}) => {
  const { setValue, reset, watch, formState } = useFormContext<ContactEditModel>();

  const dispatch: (action: Action) => void = action => {
    switch (action.type) {
      case 'SELECT_STAFF_MEMBER': {
        reset(
          { staffMember: { ...action.staffMember } },
          {
            keepDirty: true,
            keepTouched: true,
          },
        );
        return;
      }
      case 'UPDATE_STAFF_DETAILS': {
        setValue(`staffMember.${field}`, action.details[field] || '', {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
        setValue('willRemove', false, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
        return;
      }
      case 'SET_SAVE_BLOCKED': {
        setValue('blockSave', action.value, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
        return;
      }
      case 'REMOVE_STAFF_MEMBER': {
        setValue('staffMember', SchoolStaffMember.create());
        setValue('willRemove', true, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
        return;
      }
    }
  };

  return (
    <SDFAutocomplete
      field={field}
      formData={watch('staffMember')}
      initialData={formState.defaultValues?.staffMember as Partial<SchoolStaffMember> | undefined}
      dispatch={dispatch}
      mode={mode}
      allStaff={allStaff}
      selectOnBlur={selectOnBlur}
      removeText={removeText}
    />
  );
};

type AutocompleteOption =
  | SchoolStaffMember
  | { name: 'updateStaffMember' }
  | { name: 'removeStaffMember' };

const isUpdate = (opt: AutocompleteOption): opt is { name: 'updateStaffMember' } =>
  opt.name === 'updateStaffMember';

const isRemove = (opt: AutocompleteOption): opt is { name: 'removeStaffMember' } =>
  opt.name === 'removeStaffMember';

interface SDFAutocompleteProps extends Required<FieldProps> {
  selectOnBlur?: boolean;
  allStaff: SchoolStaffMember[];
  mode: Exclude<AutocompleteMode, 'none' | 'selection-blur'>;
  formData: SchoolStaffMember;
  initialData?: Partial<SchoolStaffMember>;
  dispatch: (action: Action) => void;
}

const SDFAutocomplete = ({
  field,
  formData,
  initialData,
  dispatch,
  mode,
  allStaff,
  selectOnBlur,
  removeText = '',
}: SDFAutocompleteProps) => {
  const [inputVal, setInputVal] = useState(formData[field]);
  const updateState = mode === 'free-entry' || formData.name === '';

  useEffect(() => {
    setInputVal(formData[field] || '');
  }, [formData, field]);

  const onInputChange = (value: string, meta: InputActionMeta) => {
    if (meta.action === 'input-change') {
      setInputVal(value || '');
      if (updateState) {
        dispatch({
          type: 'UPDATE_STAFF_DETAILS',
          details: { [field]: value },
        });
      } else {
        dispatch({
          type: 'SET_SAVE_BLOCKED',
          value: value !== formData[field],
        });
        if (selectOnBlur && !value && value != formData[field]) {
          dispatch({
            type: 'SELECT_STAFF_MEMBER',
            staffMember: {
              ...formData,
              name: '',
              roles: [],
              [field]: value,
            },
          });
        }
      }
    }
  };

  const onSelect = (opt: AutocompleteOption | null) => {
    if (opt === null) {
      return;
    }
    if (isRemove(opt)) {
      dispatch({
        type: 'REMOVE_STAFF_MEMBER',
      });
    } else if (isUpdate(opt)) {
      if (mode === 'selection') {
        dispatch({
          type: 'SELECT_STAFF_MEMBER',
          staffMember: {
            name: '',
            givenName: formData.givenName !== initialData?.givenName ? formData.givenName : '',
            familyName: formData.familyName !== initialData?.familyName ? formData.familyName : '',
            emailAddress:
              formData.emailAddress !== initialData?.emailAddress ? formData.emailAddress : '',
            [field]: inputVal,
          },
        });
      }
    } else {
      dispatch({
        type: 'SELECT_STAFF_MEMBER',
        staffMember: opt,
      });
    }
    dispatch({
      type: 'SET_SAVE_BLOCKED',
      value: false,
    });
  };

  const onBlur: FocusEventHandler<HTMLInputElement> = evt => {
    const val = evt.target.value;
    if (!updateState && selectOnBlur && val !== formData[field]) {
      dispatch({
        type: 'SELECT_STAFF_MEMBER',
        staffMember: {
          ...formData,
          name: '',
          roles: [],
          [field]: val,
        },
      });
    }
    if (!updateState && !selectOnBlur) {
      setInputVal(formData[field]);
    }
  };

  const options = useMemo(() => {
    const opts = allStaff.slice() as AutocompleteOption[];

    if (removeText !== '') {
      opts.push({ name: 'removeStaffMember' });
    }
    if (!selectOnBlur) {
      opts.push({ name: 'updateStaffMember' });
    }
    return opts;
  }, [allStaff, removeText, selectOnBlur]);

  const filter = createFilter<AutocompleteOption>();
  const filterOption: typeof filter = (opt, input) => {
    const { data } = opt;
    if (isRemove(data)) {
      if (!input && formData.name !== '') {
        return true;
      }
      return false;
    }
    if (input.length < 3) {
      return false;
    }
    if (isUpdate(data)) {
      // Don't show a redundant 'create' option if the entered text exactly matches one of the options
      if (mode === 'selection' && allStaff.some(staff => staff[field] === inputVal)) {
        return false;
      }
      if (initialData && formData.name !== '' && input !== initialData[field]) {
        return true;
      }
      return false;
    }
    if (data.name === formData.name) {
      return false;
    }
    return filter(opt, input);
  };

  return (
    <Select<AutocompleteOption>
      // Match behaviour with Maths staff manager which uses MUI Autocomplete
      // This prop overrides default behaviour which is that <tab> selects the first option.
      // With this set, it will now move focus to the next input.
      tabSelectsValue={false}
      inputValue={inputVal}
      isClearable={false}
      onInputChange={onInputChange}
      onBlur={onBlur}
      value={formData}
      onChange={onSelect}
      // Pass the existing staff values PLUS the 'update' and 'remove' options, if applicable...
      options={options}
      // ...then filter, and remove the 'update' and 'remove' options depending on the current state
      filterOption={filterOption}
      getOptionValue={(opt: AutocompleteOption) =>
        isUpdate(opt) || isRemove(opt) ? '' : opt[field] ?? ''
      }
      isOptionSelected={opt => opt.name === formData.name}
      formatOptionLabel={makeRenderOption({ field, mode, removeText })}
      // Disable some default behaviours of Select
      components={{
        DropdownIndicator: null,
        IndicatorSeparator: null,
      }}
      placeholder=""
      noOptionsMessage={() => null}
      chakraStyles={{
        singleValue: baseStyles => ({ ...baseStyles, display: 'none' }),
        input: baseStyles => ({ ...baseStyles, opacity: 1, cursor: 'auto' }),
      }}
      // Keeps the menu visible
      menuPortalTarget={document.body}
      menuShouldScrollIntoView
      menuPlacement="auto"
    />
  );
};

const makeRenderOption =
  ({ field, removeText, mode }: Pick<SDFAutocompleteProps, 'field' | 'mode' | 'removeText'>) =>
  (opt: AutocompleteOption, { context, inputValue }: FormatOptionLabelMeta<AutocompleteOption>) => {
    if (context === 'value') {
      return isUpdate(opt) || isRemove(opt) ? '' : opt[field] ?? '';
    }
    if (isUpdate(opt)) {
      return (
        <Box width="100%">
          <Text as="p" fontSize="xs" noOfLines={1}>
            {mode === 'free-entry' ? `Update to ${inputValue}` : `Create ${inputValue}`}
          </Text>
        </Box>
      );
    }
    if (isRemove(opt)) {
      return (
        <Box width="100%">
          <Text as="p" fontSize="xs" noOfLines={1}>
            {removeText}
          </Text>
        </Box>
      );
    }
    return (
      <Box width="100%">
        <Text as="p" fontSize="xs" noOfLines={1}>
          <Text as={field === 'givenName' ? 'strong' : 'span'}>{opt.givenName}</Text>
          &nbsp;
          <Text as={field === 'familyName' ? 'strong' : 'span'}>{opt.familyName}</Text>
        </Text>
        <Text as="p" fontSize="xs" noOfLines={1}>
          <Text as={field === 'emailAddress' ? 'strong' : 'span'}>{opt.emailAddress}</Text>
        </Text>
      </Box>
    );
  };
