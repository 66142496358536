import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useQuery } from '@tanstack/react-query';
import { activitiesClient } from 'api';
import { endOfMonth, isEqual, startOfDay, startOfMonth } from 'date-fns';

import { Options } from './school';
import { getSchoolID } from './sessions';

interface CommonActivityEntry {
  firstActivity: Date;
  correctActivities: number;
  totalSeconds: number;
  totalActivities: number;
}

export interface ILActivityEntry extends CommonActivityEntry {
  type: 'independentlearning';
  topics: Set<string>;
}

export interface AssignmentActivityEntry extends CommonActivityEntry {
  type: 'assignment';
  assignmentName: string;
}

export type ActivityEntry = ILActivityEntry | AssignmentActivityEntry;

export interface DailyActivity {
  day: Date;
  entries: ActivityEntry[];
}

export const useDailyActivityFeed = (studentID: string, month: Date, options: Options) => {
  const startTime = startOfMonth(month);
  const endTime = endOfMonth(month);

  return useQuery({
    queryKey: ['activityfeed', studentID, startTime.toISOString()],
    queryFn: async () =>
      activitiesClient.listActivityFeed({
        schoolName: `schools/${await getSchoolID()}`,
        studentName: `students/${studentID}`,
        dateRange: {
          startTime: Timestamp.fromDate(startTime),
          endTime: Timestamp.fromDate(endTime),
        },
      }).response,
    select: data => {
      if (!data?.activities) {
        return [];
      }

      const acts = data.activities;
      acts.sort((a, b) => (b.timestampHour?.seconds || 0) - (a.timestampHour?.seconds || 0));

      const dailyActivities: DailyActivity[] = [];
      const addDailyActivities = (
        currentDay: Date | undefined,
        currentDaysEvents: Map<string, ActivityEntry>,
      ) => {
        const entries = Array.from(currentDaysEvents.values());
        if (!currentDay || entries.length === 0) {
          return;
        }

        dailyActivities.push({
          day: currentDay,
          entries,
        });
      };

      let currentDaysEvents: Map<string, ActivityEntry> = new Map();
      let currentDay: Date | undefined;
      for (const act of acts) {
        if (!act.timestampHour) {
          continue;
        }
        const actDate = Timestamp.toDate(act.timestampHour);
        const actDay = startOfDay(actDate);

        if (!currentDay) {
          currentDay = actDay;
          currentDaysEvents = new Map();
        } else if (!isEqual(currentDay, actDay)) {
          addDailyActivities(currentDay, currentDaysEvents);
          currentDay = actDay;
          currentDaysEvents = new Map();
        }

        let entry = currentDaysEvents.get(act.activityType);

        if (act.activityType.startsWith('assignments/')) {
          if (!entry) {
            entry = {
              type: 'assignment',
              assignmentName: act.activityType,
              firstActivity: actDate,
              correctActivities: 0,
              totalActivities: 0,
              totalSeconds: 0,
            };
          }
          entry.correctActivities += act.correctActivities;
          entry.totalActivities += act.totalActivities;
          entry.totalSeconds += act.totalSeconds;
        } else if (act.activityType === 'independentlearning') {
          if (!entry || entry.type !== 'independentlearning') {
            entry = {
              type: 'independentlearning',
              topics: new Set(),
              firstActivity: actDate,
              correctActivities: 0,
              totalActivities: 0,
              totalSeconds: 0,
            };
          }
          entry.correctActivities += act.correctActivities;
          entry.totalActivities += act.totalActivities;
          entry.totalSeconds += act.totalSeconds;
          act.topics.forEach(entry.topics.add, entry.topics);
        } else {
          console.error('Unknown activity type:', act.activityType);
          continue;
        }
        currentDaysEvents.set(act.activityType, entry);
      }
      addDailyActivities(currentDay, currentDaysEvents);
      return dailyActivities;
    },
    ...options,
  });
};
