import { useEffect } from 'react';

/**
 * A hook to prompt the user before they leave a page
 * @param message A message to show (only shows on some browsers, others show a default message)
 * @param active When true, the prompt is active
 */
export const useBeforeUnload = (message: string, active: boolean) => {
  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      event.returnValue = message;
      return message;
    };
    if (active) {
      window.addEventListener('beforeunload', handler);
    }
    return () => window.removeEventListener('beforeunload', handler);
  }, [active, message]);
};
