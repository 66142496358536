import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { YearGroup } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { SparxStaffClassSelect } from '@sparx/mis-sync-import/src/MisSyncImport/components/SchoolGroupsPanel/SparxStaffClassSelect';
import { YearGroupSelect } from '@sparx/mis-sync-import/src/MisSyncImport/components/SchoolGroupsPanel/YearGroupSelect';
import tableStyles from '@sparx/mis-sync-import/src/shared-styles/Tables.module.css';
import { Button, ErrorMessage, WarningMessage } from '@sparx/sparx-design/components';
import classNames from 'classnames';

import { ConflictCountLabel, ConflictLabel } from './ConflictLabel';
import styles from './SchoolGroupsPanel.module.css';
import { isCountRow, SchoolGroupsTableData } from './types';

const COL_SPAN = 5;

export interface SparxStaffClassMatchOptions {
  existingClassOptions: Group[];
  recentlyExpiredClassOptions: Group[];
  sparxClassNameToWondeID: Record<string, string>;
}

interface SchoolGroupsTableProps {
  rows: SchoolGroupsTableData[];
  yearGroups: Record<string, YearGroup>;

  // If true, the table will show the extra dropdown for the advanced feature of matching a Wonde class to a Sparx class
  sparxStaffFeaturesEnabled?: boolean;

  // A list of Sparx classes that can be matched to Wonde classes if the Sparx
  // Staff feature is enabled
  sparxStaffClassMatchOptions?: SparxStaffClassMatchOptions;
}

/**
 * Table for displaying school groups
 * @param rows
 * @param yearGroups
 * @param showSparxStaffFeatures
 * @param sparxStaffClassMatchOptions
 * @constructor
 */
export const SchoolGroupsTable = ({
  rows,
  yearGroups,
  sparxStaffFeaturesEnabled,
  sparxStaffClassMatchOptions,
}: SchoolGroupsTableProps) => {
  return (
    <div className={tableStyles.Container}>
      <table className={tableStyles.Table}>
        <thead className={tableStyles.TableHeader}>
          <tr>
            <th>Class</th>
            <th>Year Group</th>
            {sparxStaffFeaturesEnabled && <th>Sparx class</th>}
            <th>Subject</th>
            {sparxStaffFeaturesEnabled ? (
              <th colSpan={2}>Students (Wonde/Sparx)</th>
            ) : (
              <>
                <th>Students</th>
                <th />
              </>
            )}
          </tr>
        </thead>

        <tbody>
          {rows.map((row, i) => (
            <TableRow
              yearGroups={yearGroups}
              row={row}
              key={i}
              sparxStaffFeaturesEnabled={sparxStaffFeaturesEnabled}
              sparxStaffClassMatchOptions={sparxStaffClassMatchOptions}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

/**
 * Table row for either a count row or a school group row
 * @param row
 * @param yearGroups
 * @param showSparxStaffFeatures
 * @param sparxStaffClassMatchOptions
 * @constructor
 */
const TableRow = ({
  row,
  yearGroups,
  sparxStaffFeaturesEnabled,
  sparxStaffClassMatchOptions,
}: {
  row: SchoolGroupsTableData;
  yearGroups: Record<string, YearGroup>;
  sparxStaffFeaturesEnabled?: boolean;
  sparxStaffClassMatchOptions?: SparxStaffClassMatchOptions;
}) => {
  if (isCountRow(row)) {
    const colSpan = sparxStaffFeaturesEnabled ? COL_SPAN + 1 : COL_SPAN;
    return (
      <tr>
        <td className={styles.CountRow} colSpan={colSpan}>
          <p>
            {row.text} ({row.count})
            {row.unresolvedConflictsCount > 0 && row.onResolveConflicts && (
              <ConflictCountLabel
                unresolvedConflictsCount={row.unresolvedConflictsCount}
                onResolveConflicts={row.onResolveConflicts}
              />
            )}
          </p>
        </td>
      </tr>
    );
  }

  // Calculate contents of the student count cell
  let studentCountCellContents = '';
  if (row.subType !== 'removedByMis') {
    studentCountCellContents = `${row.wondeStudentsCount}`;
    if (sparxStaffFeaturesEnabled) {
      studentCountCellContents += `/${row.sparxStudentsCount}`;
    }
  }

  let warningMessage = '';
  if (row.hasNonUniqueName) {
    warningMessage = 'Identical class name';
  } else if (row.yearGroupID === '') {
    warningMessage = 'Select a year group';
  }

  return (
    <tr
      className={classNames(tableStyles.TableBodyRow, {
        [styles.ClassToBeAdded]: row.type === 'new',
        [styles.ClassToBeRemoved]: row.type === 'removed',
        [styles.ClassWithUnresolvedConflicts]: row.conflict === 'unresolved',
        [styles.ClassWithResolvedConflicts]: row.conflict === 'resolved',
        [styles.ClassWithWarning]: !!warningMessage,
      })}
    >
      <td>
        <div>
          {row.subType === 'removedByMis' ? (
            <>
              <div>
                <ErrorMessage message={row.class} className={styles.RemovedClassName} />
                <div className={styles.ExplainerText}>Class has been removed from your MIS</div>
              </div>
            </>
          ) : (
            <>
              {row.class}
              {!!warningMessage && (
                <div className={styles.WarningMessageContainer}>
                  <WarningMessage message={warningMessage} className={styles.WarningMessage} />
                </div>
              )}
            </>
          )}
          {row.conflict && row.onResolveConflicts && (
            <ConflictLabel variant={row.conflict} onResolveConflicts={row.onResolveConflicts} />
          )}
        </div>
      </td>
      <td>
        {row.type === 'removed' ? (
          yearGroups[row.yearGroupID]?.name
        ) : (
          <YearGroupSelect
            yearGroupID={row.yearGroupID}
            yearGroups={yearGroups}
            classIdentifier={row.classIdentifier}
          />
        )}
      </td>
      {sparxStaffFeaturesEnabled && (
        <td className={styles.SparxClassColumn}>
          {row.currentWondeID && row.type === 'new' && (
            <SparxStaffClassSelect
              wondeID={row.currentWondeID}
              sparxClassMatch={row.matchedSparxClass}
              existingClassOptions={sparxStaffClassMatchOptions?.existingClassOptions || []}
              recentlyExpiredClassOptions={
                sparxStaffClassMatchOptions?.recentlyExpiredClassOptions || []
              }
              matchingExpiredClass={row.matchingExpiredClass}
            />
          )}
        </td>
      )}
      <td>{row.subject}</td>
      <td>{studentCountCellContents}</td>
      <td>
        <Button
          variant="text"
          className={classNames(styles.Button, {
            [styles.Red]: row.type === 'current',
          })}
          onClick={row.removeCallback}
        >
          {row.removeButtonText}
        </Button>
      </td>
    </tr>
  );
};
