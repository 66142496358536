import { UpdateUserFlagsRequest } from '@sparx/api/apis/sparx/science/sessions/v1/session';
import { useMutation, useQuery } from '@tanstack/react-query';
import { sessionsClient } from 'api';

import { queryClient } from './client';
import { Options } from './school';

export const UserSettings = {
  trainingBannerDismissed: 'teacher_training_banner_dismissed',
  whatsNewPromotedLastViewed: 'teacher_whats_new_promoted_last_viewed',
  whatsNewLastViewed: 'teacher_whats_new_last_viewed',
  overlaySetting: 'overlay_colour',
};

const userSettingsQuery = {
  queryKey: ['session', 'usersettings'],
  queryFn: () => sessionsClient.getUserFlags({}).response,
  cacheTime: Infinity,
  staleTime: Infinity,
};

export const useUserSettingsMap = (options: Options) =>
  useQuery({
    ...userSettingsQuery,
    select: data => data.userFlags,
    ...options,
  });

export const useUserSetting = (setting: string, options?: Options) =>
  useQuery({
    ...userSettingsQuery,
    select: data => data.userFlags[setting] || undefined,
    ...options,
  });

export const useUpdateUserSetting = () =>
  useMutation({
    mutationFn: ({ key, value }: { key: string; value: string }) =>
      sessionsClient.updateUserFlags(
        UpdateUserFlagsRequest.create({
          userFlags: {
            [key]: value,
          },
        }),
      ).response,
    onSuccess: data => {
      queryClient.setQueryData(['session', 'usersettings'], data);
    },
  });
