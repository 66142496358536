import { useDisclosure } from '@chakra-ui/react';
import React, { PropsWithChildren, useState } from 'react';
import { WeekWithAssignments } from 'utils/data/assignments';

import { ManageConsolidationTopicsModal } from './Modal';

type ModalMotionPreset = 'scale' | 'slideInRight' | 'slideInBottom' | 'none';
interface Context {
  groupName: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  // motionPreset controls how the modal animates open
  motionPreset: ModalMotionPreset;
  setMotionPreset: (preset: ModalMotionPreset) => void;
}

const context = React.createContext<Context>({
  groupName: '',
  isOpen: false,
  onOpen: () => undefined,
  onClose: () => undefined,
  motionPreset: 'scale',
  setMotionPreset: () => undefined,
});

export const useManageConsolidationTopicsModal = () => React.useContext(context);

export const ManageConsolidationTopicsContext = ({
  groupName,
  children,
}: PropsWithChildren<{ groupName: string; weekWithAssignments: WeekWithAssignments[] }>) => {
  const [motionPreset, setMotionPreset] = useState<ModalMotionPreset>('scale');
  return (
    <context.Provider
      value={{
        ...useDisclosure(),
        groupName,
        motionPreset,
        setMotionPreset,
      }}
    >
      {children}
      <ManageConsolidationTopicsModal />
    </context.Provider>
  );
};
