import {
  ModifiedClass,
  ModifiedStudent,
  PreviewClass,
  PreviewParent,
  PreviewStudent,
} from '@sparx/api/apis/sparx/misintegration/wondesync/v1/wondesync';
import { Student } from '@sparx/api/apis/sparx/misintegration/wondewitch/v1/wondewitch';

/** A representation of string value or, if a change, its before and after values */
export type Preview = string | [string, string];

export const misSupportedSubjects = ['MATHS', 'ENGLISH', 'SCIENCE'] as const;
export type ProductSubject = (typeof misSupportedSubjects)[number];

/** Map of product subjects to preview */
type ClassPreview = Partial<{
  [subject in ProductSubject]: Preview;
}>;

/** A view model for a row previewing student info or changed info */
export interface StudentPreview {
  id: string;
  /** For showing to Sparx staff only */
  wondeID: Preview;
  name: Preview;
  gender: Preview;
  dateOfBirth: Preview;
  emailAddress: Preview;
  classes: ClassPreview;
  contacts: {
    name: Preview;
    email: Preview;
  }[];
  sortValue: string;
}

interface NameFields {
  firstName: string;
  lastName: string;
}

// Sort the changes so that they appear in the order: modified, added, removed
const PARENT_SORT_ORDER = ['modified', 'added', 'removed'];

export const getName = ({ firstName, lastName }: NameFields) =>
  firstName && lastName ? `${firstName} ${lastName}` : '';

/**
 * Get the name of a student from Wonde. This will default to either the legal forename and surname or the
 * preferred forname and surname depending on the value of `useLegalName`.
 * @param student the student record
 * @param useLegalName whether to default to the legal forname and surname
 */
export const getNameForWondeStudent = (student: Student, useLegalName: boolean): string => {
  if (useLegalName) {
    return `${student.legalForename || student.forename} ${
      student.legalSurname || student.surname
    }`;
  }
  return `${student.forename || student.legalForename} ${student.surname || student.legalSurname}`;
};

/**
 * Compares two students by surname, forename, defaulting to the legal version of the names if
 * `useLegalName` is true
 * @param studentA
 * @param studentB
 * @param useLegalNames
 */
export const compareStudentsByName = (
  studentA: Student,
  studentB: Student,
  useLegalNames: boolean,
) => {
  if (useLegalNames) {
    return (
      (studentA.legalSurname || studentA.surname).localeCompare(
        studentB.legalSurname || studentB.surname,
      ) ||
      (studentA.legalForename || studentA.forename).localeCompare(
        studentB.legalForename || studentB.forename,
      )
    );
  }
  return (
    (studentA.surname || studentA.legalSurname).localeCompare(
      studentB.surname || studentB.legalSurname,
    ) ||
    (studentA.forename || studentA.legalForename).localeCompare(
      studentB.forename || studentB.legalForename,
    )
  );
};

/** Return a function which checks if a property has changed between two objects */
export const comparer =
  <T>(before: T, after: T) =>
  (...keys: Array<keyof T>) =>
    keys.some(key => before[key] !== after[key]);

/** Convenience function to produce a `StudentPreview` object from an `IPreviewStudent` */
const getStudentPreview = ({
  id,
  wondeId,
  firstName,
  lastName,
  dateOfBirth,
  emailAddress,
  gender,
  parentContacts,
  groupWondeIds,
}: PreviewStudent): StudentPreview => {
  const classes: ClassPreview = {};
  for (const subject of ['MATHS', 'ENGLISH', 'SCIENCE']) {
    const group = groupWondeIds[subject];
    if (group) {
      classes[subject as ProductSubject] = group.name;
    }
  }

  return {
    id,
    wondeID: wondeId,
    classes,
    name: getName({ firstName, lastName }),
    gender: gender ? `${gender[0].toUpperCase()}${gender.slice(1)}` : '',
    dateOfBirth: dateOfBirth ? getDateOfBirth(dateOfBirth) : '',
    emailAddress,
    contacts: parentContacts.map(pc => ({
      name: getName(pc),
      email: pc.email,
    })),
    sortValue: lastName,
  };
};

const getContactChanges = (before: PreviewParent[], after: PreviewParent[]) => {
  const changes: {
    status: 'added' | 'removed' | 'modified';
    name: Preview;
    email: Preview;
  }[] = [];

  // Add a property to track whether or not contacts in the `after` collection are new.
  const trackedAfter = after.map(a => ({
    ...a,
    isNew: true,
  }));

  for (const beforeContact of before) {
    // Find a matching contact; we can't rely on stable wonde ID, so ideally match
    // via email address (but full name will do) - this doesn't need to be perfect
    // as the parent contact will be added/removed from the student if it's not a perfect match
    const afterContact =
      trackedAfter.filter(c => c.isNew).find(c => c.email === beforeContact.email) ??
      trackedAfter
        .filter(c => c.isNew)
        .find(c => getName(c) && getName(c) === getName(beforeContact));

    if (afterContact) {
      // Modification
      afterContact.isNew = false;
      const hasChanged = comparer<PreviewParent>(beforeContact, afterContact);

      changes.push({
        status: 'modified',
        email: hasChanged('email') ? [beforeContact.email, afterContact.email] : afterContact.email,
        name: hasChanged('firstName', 'lastName')
          ? [getName(beforeContact), getName(afterContact)]
          : getName(afterContact),
      });
    } else {
      // Deletion
      changes.push({
        status: 'removed',
        email: [beforeContact.email, ''],
        name: [getName(beforeContact), ''],
      });
    }
  }

  for (const afterContact of trackedAfter.filter(c => c.isNew)) {
    // Addition
    changes.push({
      status: 'added',
      email: ['', afterContact.email],
      name: ['', getName(afterContact)],
    });
  }

  return changes
    .sort((a, b) => PARENT_SORT_ORDER.indexOf(a.status) - PARENT_SORT_ORDER.indexOf(b.status))
    .map(({ name, email }) => ({ name, email }));
};

const getStudentChanges = (before: PreviewStudent, after: PreviewStudent) => {
  const changes: StudentPreview = getStudentPreview(after);
  const hasChanged = comparer(before, after);

  // Subjects is a combined list of all of the subjects (keys for groupWondeIds)
  // for the before and after previews. A set is used to de-duplicate them.
  const subjects = new Set(
    Object.keys(before.groupWondeIds).concat(Object.keys(after.groupWondeIds)),
  );
  for (const subject of subjects.values()) {
    const beforeGroup = before.groupWondeIds[subject];
    const afterGroup = after.groupWondeIds[subject];
    if (beforeGroup?.wondeId !== afterGroup?.wondeId) {
      changes.classes[subject as ProductSubject] = [
        beforeGroup?.name || '',
        afterGroup?.name || '',
      ];
    }
  }

  if (hasChanged('wondeId')) {
    changes.wondeID = [before.wondeId, after.wondeId];
  }

  if (hasChanged('dateOfBirth')) {
    changes.dateOfBirth = [getDateOfBirth(before.dateOfBirth), getDateOfBirth(after.dateOfBirth)];
  }

  if (hasChanged('emailAddress')) {
    changes.emailAddress = [before.emailAddress, after.emailAddress];
  }

  if (hasChanged('gender')) {
    changes.gender = [before.gender, after.gender];
  }

  if (hasChanged('firstName', 'lastName')) {
    changes.name = [getName(before), getName(after)];
  }

  changes.contacts = getContactChanges(before.parentContacts, after.parentContacts);

  return changes;
};

export const mapStudentChanges = (changes: ModifiedStudent[]): StudentPreview[] | undefined => {
  try {
    return changes
      .map(({ before, after }) => {
        if (!before || !after) {
          // Nothing we can do if the server hasn't provided the information
          throw new Error('Both before and after properties required for a modified student');
        }
        return getStudentChanges(before, after);
      })
      .sort((a, b) => a.sortValue.localeCompare(b.sortValue));
  } catch (err) {
    return undefined;
  }
};

export const mapStudentPreviews = (previews: PreviewStudent[]): StudentPreview[] =>
  previews.map(getStudentPreview).sort((a, b) => a.sortValue.localeCompare(b.sortValue));

type ClassUpdate = { sortBy: string; wondeId: Preview; displayName: Preview; yearGroupId: Preview };
export const mapClassUpdates = (classes: ModifiedClass[]): ClassUpdate[] =>
  classes
    .map(({ before, after }) => {
      if (!before || !after) {
        return;
      }
      const classComparer = comparer<PreviewClass>(before, after);
      return {
        sortBy: after.displayName || before.displayName || '',
        wondeId: classComparer('wondeId') ? [before.wondeId, after.wondeId] : after.wondeId,
        displayName: classComparer('displayName')
          ? [before.displayName, after.displayName]
          : after.displayName,
        yearGroupId: classComparer('yearGroupId')
          ? [before.yearGroupId, after.yearGroupId]
          : after.yearGroupId,
      };
    })
    .filter<ClassUpdate>((c): c is ClassUpdate => c !== undefined)
    .sort((a, b) => a.sortBy.localeCompare(b.sortBy, undefined, { numeric: true }));

export const hasBeenUpdated = (preview: Preview): boolean => preview instanceof Array;

export const getDateOfBirth = (dateOfBirth: string) => {
  const date = new Date(dateOfBirth);
  return date.toLocaleDateString('en-GB');
};
