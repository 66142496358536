import { GridItem, Heading, Text } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';
import { FieldError } from 'react-hook-form';

import { useStaffContext } from '../Context';
import {
  defaultGetErrorMessage,
  displayName,
  ErrorFormatter,
  UserEditableField as FieldName,
} from './validationMessages';

export interface EditableFieldProps {
  field: FieldName;
  hideLabel?: boolean;
  error?: FieldError;
  span?: number;
  tooltip?: React.ReactNode;
  getErrorMessage?: ErrorFormatter;
}

export const EditableField = ({
  field,
  hideLabel,
  children,
  error,
  span,
  tooltip,
  getErrorMessage = defaultGetErrorMessage,
}: PropsWithChildren<EditableFieldProps>) => {
  const { getInfoTooltip } = useStaffContext();
  const label = displayName(field);

  return (
    <GridItem display="flex" flexDirection="column" colSpan={span || 1}>
      {label && !hideLabel ? (
        <Text pb={1} color="gray.600" whiteSpace="nowrap">
          {label}
          {label ? ':' : <>&nbsp;</>}
          {tooltip && getInfoTooltip(tooltip)}
        </Text>
      ) : null}
      {children}
      {error && getErrorMessage(field, error)}
    </GridItem>
  );
};

interface HeaderFieldProps {
  name: string;
  first?: boolean;
}

export const HeaderField = ({ name, first }: HeaderFieldProps) => (
  <GridItem colSpan={2} borderTop={first ? 0 : '2px'} borderTopColor="gray.200" pt={first ? 0 : 4}>
    <Heading fontSize="md">{name}</Heading>
  </GridItem>
);
