import {
  StyleProps,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { useGroups, useStudents } from 'api/school';
import { useMemo } from 'react';
import { articleIdImportClasses } from 'utils/knowledgeBaseArticles';

import { IsRolloverProps, PageTab, TabDetails, TabStatus } from '..';
import { ActionBox } from '../components/ActionBox';
import { useMISStatus } from './MIS';
import { useMISResetStatus } from './MISReset';

const title = 'Import Classes & Students';

export const useGroupsInfo = ({ suspense, enabled }: { suspense: boolean; enabled?: boolean }) => {
  const { data: groups = [], isSuccess: groupsSuccess } = useGroups({ suspense, enabled });
  const { data: students = [], isSuccess: studentsSuccess } = useStudents({ suspense, enabled });

  const resp = useMemo(() => {
    const lookup: Dictionary<string, number> = {};
    for (const student of students) {
      for (const group of student.studentGroupIds) {
        lookup[group] = (lookup[group] || 0) + 1;
      }
    }

    const groupsWithCount = groups
      .filter(g => g.type === StudentGroupType.CLASS_SCIENCE)
      .sort((a, b) => a.displayName.localeCompare(b.displayName))
      .map(group => ({
        ...group,
        studentCount: lookup[group.name.split('/')[3]] || 0,
      }));

    const nonEmptyGroups = groupsWithCount.filter(g => g.studentCount > 0);
    const numStudents = nonEmptyGroups.reduce((acc, group) => acc + group.studentCount, 0);

    return { groupsWithCount, numNonEmptyGroups: nonEmptyGroups.length, numStudents };
  }, [groups, students]);

  return {
    ...resp,
    isSuccess: groupsSuccess && studentsSuccess,
  };
};

const Page = (props: IsRolloverProps) => {
  const { groupsWithCount } = useGroupsInfo({ suspense: true });
  const tabDetails = useTabDetails({ ...props, suspense: true });

  const headerStyles: StyleProps = {
    backgroundColor: 'blue.800',
    textTransform: 'none',
    fontSize: 'sm',
    color: 'white',
    zIndex: 10,
    top: 0,
    px: 6,
    py: 4,
  };

  return (
    <>
      <ActionBox
        heading={
          <Text>
            To import your classes, go to <strong>Class Manager</strong> and select{' '}
            <strong>Import and Manage Classes</strong>.
          </Text>
        }
        bgColor={tabDetails.status === 'complete' ? 'green.50' : undefined}
        my={4}
        faIcon={faUsers}
        button={{ text: 'Class Manager', path: '/teacher/group' }}
        knowledgeBaseArticleId={articleIdImportClasses}
      />
      <TableContainer whiteSpace="unset" boxShadow="elevationLow" borderRadius="md">
        <Table backgroundColor="white" overflow="hidden">
          <Thead>
            <Tr>
              <Th {...headerStyles} borderTopLeftRadius="md">
                Class
              </Th>
              <Th {...headerStyles}>Number of Students</Th>
            </Tr>
          </Thead>
          <Tbody>
            {groupsWithCount.length === 0 && (
              <Tr>
                <Td colSpan={2}>No classes imported</Td>
              </Tr>
            )}
            {groupsWithCount.map(group => (
              <Tr key={group.name}>
                <Td>{group.displayName}</Td>
                <Td>{group.studentCount}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

const useTabDetails = ({
  isRollover,
  suspense = false,
}: IsRolloverProps & { suspense?: boolean }): TabDetails => {
  const { isWondeAuthorised, isSuccess: statusSuccess } = useMISStatus({ suspense });

  const { status: resetStatus } = useMISResetStatus({ suspense, enabled: isRollover });

  const misComplete = isRollover ? resetStatus === 'complete' : isWondeAuthorised;
  const misSuccess = isRollover ? resetStatus !== 'loading' : statusSuccess;
  const lockedMsg = isRollover ? 'Reset Sparx Science first' : 'Connect to your MIS first';

  const { numNonEmptyGroups, numStudents, isSuccess } = useGroupsInfo({
    suspense,
    enabled: misComplete,
  });

  let status: TabStatus | undefined;
  let subtitle: string | undefined;
  if (!misComplete || !misSuccess) {
    status = 'locked';
    subtitle = lockedMsg;
  } else if (misSuccess && isSuccess) {
    if (numNonEmptyGroups > 0) {
      status = 'complete';
      subtitle = `${numStudents} students in ${numNonEmptyGroups} classes imported`;
    } else {
      subtitle = 'Not imported yet';
    }
  } else {
    status = 'loading';
  }

  return {
    title,
    subtitle,
    status,
    icon: faUsers,
  };
};

const ImportClassesTab: PageTab = {
  detailsHook: useTabDetails,
  path: 'import',
  page: Page,
};
export default ImportClassesTab;
