import { Class, Student } from '@sparx/api/apis/sparx/misintegration/wondewitch/v1/wondewitch';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { compareStudentsByName } from '@sparx/mis-sync-import/src/processing';

import { ConflictingStudent, SchoolData, WondeData } from './types';
import {
  getStudentGroupIDFromGroupName,
  getWondeIDFromExternalID,
  isGroupExpired,
  setIntersection,
  wondeIDOfGroup,
} from './utils';

/**
 * Given the state of a sync, returns a list of conflicting students (i.e. students in more than one class for a
 * product). A conflict consists of a student, the classes they are in and the default resolution which is the Wonde ID
 * of the class they are currently in the school (or undefined if they are not in class yet)
 * @param subject the subject the sync is for (e.g. StudentGroupType.CLASS_ENGLISH)
 * @param wondeData the current state of the Wonde data
 * @param schoolData the current state of the school data
 * @param classesToAdd the WondeID of classes being added in the sync - these can contribute to conflicts
 * @param classesToRemove the WondeID of classes being removed in the sync - these will not contribute to conflicts
 * @param useLegalNames whether to use legal names for students vs preferred names
 */
export const getConflictingStudentsByProduct = (
  subject: StudentGroupType,
  wondeData: WondeData | undefined,
  schoolData: SchoolData | undefined,
  classesToAdd: string[],
  classesToRemove: string[],
  useLegalNames = false,
): Map<StudentGroupType, ConflictingStudent[]> => {
  const conflictsByProduct = new Map<StudentGroupType, ConflictingStudent[]>();

  if (wondeData === undefined || schoolData === undefined) {
    return conflictsByProduct;
  }

  // Lookup of classes by their Wonde ID:
  const classesByWondeID: Map<string, Class> = wondeData.wondeClasses.reduce((acc, c) => {
    return acc.set(c.id, c);
  }, new Map<string, Class>());

  // Lookup of students by their Wonde ID:
  const studentsByWondeID: Map<string, Student> = wondeData.wondeClasses.reduce((acc, c) => {
    for (const student of c.students) {
      acc.set(student.id, student);
    }
    return acc;
  }, new Map<string, Student>());

  // Lookup of students in schoolman by their classes' Wonde ID, so we can see if there is a "default" conflict
  // resolution of keeping them in their current class.
  const studentCurrentClasses = getStudentCurrentClasses(schoolData);

  const studentClassesByProduct = getStudentWondeClassesByProduct(
    subject,
    wondeData,
    schoolData,
    classesToAdd,
    classesToRemove,
  );

  for (const product of studentClassesByProduct.keys()) {
    const studentMap = studentClassesByProduct.get(product);
    if (!studentMap) {
      continue;
    }

    const conflicts: ConflictingStudent[] = [];
    for (const studentWondeID of studentMap.keys()) {
      const classes = studentMap.get(studentWondeID);
      if (!classes) {
        continue;
      }
      if (classes.size > 1) {
        const classWondeIDs = Array.from(classes);

        // Sort the classes by name:
        classWondeIDs.sort((a, b) => {
          const classA = classesByWondeID.get(a);
          const classB = classesByWondeID.get(b);
          if (!classA || !classB) {
            return 0;
          }
          return classA.name.localeCompare(classB.name, undefined, { numeric: true });
        });

        const currentClasses = studentCurrentClasses.get(studentWondeID);
        const currentClassesInConflicts: Set<string> = setIntersection(currentClasses, classes);

        const conflict: ConflictingStudent = {
          studentWondeID,
          classWondeIDs,
          defaultResolution:
            currentClassesInConflicts.size === 1 ? [...currentClassesInConflicts][0] : undefined,
        };
        conflicts.push(conflict);
      }
    }

    if (conflicts.length > 0) {
      // Sort the conflicts by student last name, first name:
      conflicts.sort((a, b) => {
        const studentA = studentsByWondeID.get(a.studentWondeID);
        const studentB = studentsByWondeID.get(b.studentWondeID);
        if (!studentA || !studentB) {
          return 0;
        }
        // Sort by surname, then forename:
        return compareStudentsByName(studentA, studentB, useLegalNames);
      });
      conflictsByProduct.set(product, conflicts);
    }
  }

  return conflictsByProduct;
};

/**
 * Returns a map of the Wonde IDs for all the classes for each product that will be included in a sync - i.e. already
 * imported but not being removed plus any classes being added.
 * @param subject the subject the sync is for (e.g. StudentGroupType.CLASS_ENGLISH)
 * @param wondeData the current state of the Wonde data
 * @param schoolData the current state of the school data
 * @param classesToAdd the WondeID of classes being added in the sync
 * @param classesToRemove the WondeID of classes being removed in the sync
 */
export const getWondeClassesByProduct = (
  subject: StudentGroupType,
  wondeData: WondeData,
  schoolData: SchoolData,
  classesToAdd: string[],
  classesToRemove: string[],
): Map<StudentGroupType, Set<string>> => {
  const results = new Map<StudentGroupType, Set<string>>();
  const classesToRemoveSet = new Set(classesToRemove);

  // Add classes being added in the sync to the classes for the current product:
  for (const classToAdd of classesToAdd) {
    const set = results.get(subject) || new Set();
    if (wondeData.wondeClasses.some(c => c.id === classToAdd)) {
      set.add(classToAdd);
      results.set(subject, set);
    }
  }

  // For each school group, find a matching class in Wonde and if one exists, add it to the set for the class's product:
  for (const group of schoolData.studentGroups) {
    // Filter expired groups or tutor groups:
    if (isGroupExpired(group) || group.type === StudentGroupType.TUTORGROUP) {
      continue;
    }
    const groupWondeID = wondeIDOfGroup(group);
    if (groupWondeID && !classesToRemoveSet.has(groupWondeID)) {
      const set = results.get(group.type) || new Set();
      if (wondeData.wondeClasses.some(c => c.id === groupWondeID)) {
        set.add(groupWondeID);
        results.set(group.type, set);
      }
    }
  }

  return results;
};

/**
 * Returns a map of product to a map of student Wonde IDs to the Wonde IDs of classes they are in for that product.
 * (If there are more than 2 classes for a student this will be a conflict)
 * @param subject the subject the sync is for (e.g. StudentGroupType.CLASS_ENGLISH)
 * @param wondeData the current state of the Wonde data
 * @param schoolData the current state of the school data
 * @param classesToAdd the WondeID of classes being added in the sync
 * @param classesToRemove the WondeID of classes being removed in the sync
 */
export const getStudentWondeClassesByProduct = (
  subject: StudentGroupType,
  wondeData: WondeData,
  schoolData: SchoolData,
  classesToAdd: string[],
  classesToRemove: string[],
): Map<StudentGroupType, Map<string, Set<string>>> => {
  const results = new Map<StudentGroupType, Map<string, Set<string>>>();

  const wondeClassesByProduct: Map<StudentGroupType, Set<string>> = getWondeClassesByProduct(
    subject,
    wondeData,
    schoolData,
    classesToAdd,
    classesToRemove,
  );

  for (const wondeClass of wondeData.wondeClasses) {
    for (const product of wondeClassesByProduct.keys()) {
      if (wondeClassesByProduct.get(product)?.has(wondeClass.id)) {
        const studentMap = results.get(product) || new Map();
        for (const student of wondeClass.students) {
          const studentClasses = studentMap.get(student.id) || new Set();
          studentClasses.add(wondeClass.id);
          studentMap.set(student.id, studentClasses);
        }
        results.set(product, studentMap);
      }
    }
  }

  return results;
};

/**
 * Returns a map of student Wonde IDs to the Wonde IDs of classes they are in. (Does not include registration groups,
 * unless a registration group in Wonde is being used as a class for a product)
 * @param schoolData the current state of the school data
 */
export const getStudentCurrentClasses = (schoolData: SchoolData): Map<string, Set<string>> => {
  const studentCurrentClasses = new Map<string, Set<string>>();
  const studentGroupIDsToWondeIDs = schoolData.studentGroups.reduce((acc, group) => {
    const wondeID = wondeIDOfGroup(group);
    if (wondeID && !isGroupExpired(group)) {
      const studentGroupID = getStudentGroupIDFromGroupName(group.name);
      if (studentGroupID) {
        acc.set(studentGroupID, wondeID);
      }
    }
    return acc;
  }, new Map<string, string>());
  const studentGroupTypes = schoolData.studentGroups.reduce((acc, group) => {
    const studentGroupID = getStudentGroupIDFromGroupName(group.name);
    if (studentGroupID && !isGroupExpired(group)) {
      acc.set(studentGroupID, group.type);
    }
    return acc;
  }, new Map<string, StudentGroupType>());

  for (const student of Object.values(schoolData.students)) {
    const studentWondeID = getWondeIDFromExternalID('student', student.externalId);
    if (!studentWondeID) {
      continue;
    }
    const studentClassWondeIDs = new Set<string>();
    for (const studentGroupId of student.studentGroupIds) {
      const wondeID = studentGroupIDsToWondeIDs.get(studentGroupId);
      const groupType = studentGroupTypes?.get(studentGroupId);
      if (wondeID && groupType !== undefined && groupType !== StudentGroupType.TUTORGROUP) {
        studentClassWondeIDs.add(wondeID);
      }
    }
    if (studentClassWondeIDs.size > 0) {
      studentCurrentClasses.set(studentWondeID, studentClassWondeIDs);
    }
  }
  return studentCurrentClasses;
};
