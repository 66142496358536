import {
  CreateSyncPlanResponse,
  ModifiedStudent,
  PreviewClass,
  PreviewParent,
  PreviewStudent,
  PreviewStudentClass,
  PreviewSyncSchoolV2Response,
  SyncPlan,
} from '@sparx/api/apis/sparx/misintegration/wondesync/v1/wondesync';
import {
  Class,
  Student as WondeStudent,
} from '@sparx/api/apis/sparx/misintegration/wondewitch/v1/wondewitch';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Student as ApiStudent } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import {
  Gender,
  Parent,
  Student,
  StudentGroup,
  StudentGroupType,
} from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { format, isBefore } from 'date-fns';

import {
  getStudentGroupIDFromGroupName,
  getWondeIDFromExternalID,
  productSubjectFromGroupType,
} from '.';

export const syncPlanHasChanges = (syncPlan?: SyncPlan) => {
  let hasClassChanges = false;
  let hasStudentChanges = false;
  let hasParentChanges = false;

  for (const { action } of syncPlan?.actions ?? []) {
    if (hasClassChanges && hasStudentChanges && hasParentChanges) {
      break;
    }

    switch (action.oneofKind) {
      case 'addClass':
      case 'updateClass':
      case 'removeClass':
        hasClassChanges = true;
        break;
      case 'addStudent':
      case 'updateStudent':
      case 'removeStudent':
      case 'addStudentGroupMembership':
      case 'removeStudentGroupMembership':
        hasStudentChanges = true;
        break;
      case 'removeParent':
      case 'addParent':
      case 'updateParent':
      case 'addParentLink':
      case 'removeParentLink':
        hasParentChanges = true;
        break;
    }
  }

  return { hasClassChanges, hasStudentChanges, hasParentChanges };
};

const groupToPreviewClass = (group: StudentGroup | undefined): PreviewClass => ({
  id: group?.studentGroupID || '',
  yearGroupId: group?.yearGroupID || '',
  wondeId: getWondeIDFromExternalID('group', group?.externalID) || '',
  displayName: group?.name || '',
});

const getStudentGenderDisplayName = (student: Student | ApiStudent | undefined): string => {
  switch (student?.gender) {
    case Gender.MALE:
      return 'Male';
    case Gender.FEMALE:
      return 'Female';
    case undefined:
    case Gender.UNKNOWN:
      return 'Unknown';
    default:
      return 'Other';
  }
};

const getGroupWondeIDsFromStudent = (
  student: Student | ApiStudent | undefined,
  studentGroupLookup: Record<string, Group>,
): Record<string, PreviewStudentClass> => {
  const groupWondeIDs: Record<string, PreviewStudentClass> = {};
  if (!student) {
    return groupWondeIDs;
  }
  const groupIds = 'studentID' in student ? student.studentGroupID : student.studentGroupIds;

  for (const id of groupIds) {
    const group = studentGroupLookup[id];
    const wondeId = getWondeIDFromExternalID('group', group.externalId);
    if (group && wondeId) {
      switch (group.type) {
        case StudentGroupType.CLASS:
          groupWondeIDs['MATHS'] = {
            wondeId,
            name: group.displayName,
          };
          break;
        case StudentGroupType.CLASS_ENGLISH:
          groupWondeIDs['ENGLISH'] = {
            wondeId,
            name: group.displayName,
          };
          break;
        case StudentGroupType.CLASS_SCIENCE:
          groupWondeIDs['SCIENCE'] = {
            wondeId,
            name: group.displayName,
          };
          break;
        default:
          break;
      }
    }
  }

  return groupWondeIDs;
};

const getStudentPreviewParentContactsFromStudent = (
  student: Student | ApiStudent | undefined,
  parentLookup: Record<string, Parent>,
): PreviewParent[] => {
  const previewParents: PreviewParent[] = [];
  if (!student) {
    return previewParents;
  }

  for (const parent of student?.parents ?? []) {
    let resolvedParent: Parent | undefined = undefined;
    const parentWondeId = getWondeIDFromExternalID('parent', parent.externalID);
    if (parentWondeId) {
      resolvedParent = parentLookup[parentWondeId];
    }
    const wondeId = resolvedParent
      ? getWondeIDFromExternalID('parent', resolvedParent.externalID)
      : parentWondeId;
    previewParents.push({
      firstName: resolvedParent ? resolvedParent.firstName : parent.firstName,
      lastName: resolvedParent ? resolvedParent.lastName : parent.lastName,
      email: resolvedParent ? resolvedParent.emailAddress : parent.emailAddress,
      wondeId: wondeId || '',
    });
  }

  return previewParents;
};

const formatTimestamp = (ts: Timestamp | undefined): string => {
  const date = dateFromTimestamp(ts);
  if (!date) {
    return '';
  }
  return format(date, 'yyyy-MM-dd');
};

const makeModifiedStudentFromStudent = (
  student: Student | ApiStudent | undefined,
  studentGroupLookup: Record<string, Group>,
  parentLookup: Record<string, Parent>,
) => ({
  beforeStudent: makePreviewStudentFromStudent(student, studentGroupLookup, {}),
  afterStudent: makePreviewStudentFromStudent(student, studentGroupLookup, parentLookup),
});

const makePreviewStudentFromStudent = (
  student: Student | ApiStudent | undefined,
  studentGroupLookup: Record<string, Group>,
  parentLookup: Record<string, Parent>,
): PreviewStudent => {
  const previewStudent: PreviewStudent = {
    id: '',
    classWondeId: '',
    wondeId: '',
    gender: '',
    lastName: '',
    firstName: '',
    className: '',
    dateOfBirth: '',
    emailAddress: '',
    groupWondeIds: {},
    parentContacts: [],
  };

  if (!student) {
    return previewStudent;
  }

  if ('studentID' in student) {
    previewStudent.id = student.studentID;
    previewStudent.wondeId = getWondeIDFromExternalID('student', student.externalID) ?? '';
    previewStudent.gender = getStudentGenderDisplayName(student);
    previewStudent.lastName = student.lastName;
    previewStudent.firstName = student.firstName;
    previewStudent.dateOfBirth = formatTimestamp(student.dateOfBirth);
    previewStudent.emailAddress = student.emailAddress;
    previewStudent.groupWondeIds = getGroupWondeIDsFromStudent(student, studentGroupLookup);
    previewStudent.parentContacts = getStudentPreviewParentContactsFromStudent(
      student,
      parentLookup,
    );
  } else {
    previewStudent.id = student.studentId;
    previewStudent.wondeId = getWondeIDFromExternalID('student', student.externalId) ?? '';
    previewStudent.gender = getStudentGenderDisplayName(student);
    previewStudent.lastName = student.familyName;
    previewStudent.firstName = student.givenName;
    previewStudent.dateOfBirth = formatTimestamp(student.dateOfBirth);
    previewStudent.emailAddress = student.login?.emailAddress ?? '';
    previewStudent.groupWondeIds = getGroupWondeIDsFromStudent(student, studentGroupLookup);
    previewStudent.parentContacts = getStudentPreviewParentContactsFromStudent(
      student,
      parentLookup,
    );
  }

  return previewStudent;
};

const dateFromTimestamp = (ts: Timestamp | undefined): Date | undefined =>
  ts !== undefined ? new Date(ts.seconds * 1000 + ts.nanos / 1e6) : undefined;

const hasBeenUnexpired = (
  oldTimestamp: Timestamp | undefined,
  newTimestamp: Timestamp | undefined,
): boolean => {
  const oldDate = dateFromTimestamp(oldTimestamp);
  const newDate = dateFromTimestamp(newTimestamp);
  if (!oldDate || !newDate) {
    return false;
  }

  const now = new Date();
  const wasExpired = isBefore(oldDate, now);
  const currentlyExpired = isBefore(newDate, now);
  return wasExpired && !currentlyExpired;
};

export const convertCreateSyncPlanResponseToPreviewResponse = (
  response: CreateSyncPlanResponse | undefined,
  groupType: StudentGroupType,
  students: Record<string, ApiStudent>,
  wondeClasses: Class[],
  studentGroups: Group[],
): PreviewSyncSchoolV2Response => {
  const previewResponse: PreviewSyncSchoolV2Response = {
    newClasses: [],
    newStudents: [],
    removedClasses: [],
    invalidStudents: response?.invalidStudents ?? [],
    modifiedClasses: [],
    removedStudents: [],
    modifiedStudents: [],
    unexpiredStudents: [],
    removedStudentsWithNoClass: [],
  };

  if (!response?.syncPlan) {
    return previewResponse;
  }

  const productSubject = productSubjectFromGroupType(groupType);

  const wondeClassLookup: Record<string, Class> = {};
  const wondeStudentLookup: Record<string, WondeStudent> = {};
  for (const wondeClass of wondeClasses) {
    wondeClassLookup[wondeClass.id] = wondeClass;
    for (const student of wondeClass.students) {
      wondeStudentLookup[student.id] = student;
    }
  }

  const studentGroupLookup: Record<string, Group> = {};
  for (const group of studentGroups) {
    const studentGroupID = getStudentGroupIDFromGroupName(group.name);
    if (studentGroupID) {
      studentGroupLookup[studentGroupID] = { ...group };
    }
  }

  const { studentLookupByWondeId, parentLookupByWondeId, unprocessedParentStudentsById } =
    Object.values(students).reduce(
      (res, s) => {
        const studentWondeId = getWondeIDFromExternalID('student', s.externalId);
        if (studentWondeId) {
          res.studentLookupByWondeId[studentWondeId] = s;
        }
        for (const parent of s.parents) {
          if (!res.unprocessedParentStudentsById[parent.parentID]) {
            res.unprocessedParentStudentsById[parent.parentID] = new Set();
          }
          res.unprocessedParentStudentsById[parent.parentID].add(s.studentId);
          const parentWondeId = getWondeIDFromExternalID('parent', parent.externalID);
          if (parentWondeId && !res.parentLookupByWondeId[parentWondeId]) {
            res.parentLookupByWondeId[parentWondeId] = parent;
          }
        }
        return res;
      },
      {
        studentLookupByWondeId: {} as Record<string, ApiStudent>,
        parentLookupByWondeId: {} as Record<string, Parent>,
        unprocessedParentStudentsById: {} as Record<string, Set<string>>,
      },
    );

  const markParentStudentIdProcessed = (parentId: string, studentId: string) => {
    if (!unprocessedParentStudentsById[parentId]) {
      return;
    }
    unprocessedParentStudentsById[parentId].delete(studentId);
  };

  const studentActions = [];
  const classMembershipActions = [];
  const parentUpdateActions = [];
  const parentLinkActions = [];

  // Key for identifying parents.
  const makeKey = (parent: Parent | PreviewParent) =>
    `${parent.firstName}${parent.lastName}${
      'email' in parent ? parent.email : parent.emailAddress
    }`;
  // Parents whose actions should be ignored in this sync. This handles cases where a parents external
  // ID is updated but none of the other details are changed.
  const ignoredSyncParents = new Set<string>();
  // Temporary set of keys which represent parents being added & removed in this sync.
  const processedSyncParents = new Set<string>();
  // Handler for processing parents. This will add the parent to the ignore list if they match a
  // parent we have already processed based on their details.
  const handleProcessParent = (parent: Parent) => {
    const key = makeKey(parent);
    // If we've already processed an add or remove action for this parent then we should add them
    // to the ignore list.
    if (processedSyncParents.has(key)) {
      ignoredSyncParents.add(key);
    } else {
      processedSyncParents.add(key);
    }
  };

  // Loop through once to process class changes. We need to do this first so we can update the group
  // names in the studentGroupLookup so that any new names are used when rendering class membership
  // changes.
  for (const { action } of response.syncPlan.actions) {
    switch (action.oneofKind) {
      case 'addClass':
        previewResponse.newClasses.push(groupToPreviewClass(action.addClass.class));
        break;
      case 'updateClass': {
        const { oldClass, newClass } = action.updateClass;
        if (!oldClass || !newClass) {
          console.warn('invalid update class action: old and new class are required');
          continue;
        }
        const unexpired = hasBeenUnexpired(oldClass.endDate, newClass.endDate);
        if (unexpired) {
          previewResponse.newClasses.push(groupToPreviewClass(newClass));
        } else {
          previewResponse.modifiedClasses.push({
            before: groupToPreviewClass(oldClass),
            after: groupToPreviewClass(newClass),
          });
        }
        const group = studentGroupLookup[newClass.studentGroupID];
        if (group) {
          group.displayName = newClass.name;
        }
        break;
      }
      case 'removeClass':
        previewResponse.removedClasses.push(groupToPreviewClass(action.removeClass.class));
        break;
      case 'addParent':
        {
          const parent = action.addParent.parent;
          if (!parent) {
            continue;
          }
          const parentWondeId = getWondeIDFromExternalID('parent', parent.externalID);
          if (parentWondeId) {
            parentLookupByWondeId[parentWondeId] = parent;
          }
          handleProcessParent(parent);
        }
        break;
      case 'removeParent':
        {
          const parent = action.removeParent.parent;
          if (!parent) {
            continue;
          }
          handleProcessParent(parent);
        }
        break;
      case 'updateParent':
        {
          const updatedParent = action.updateParent.newParent;
          if (!updatedParent) {
            continue;
          }
          const parentWondeId = getWondeIDFromExternalID('parent', updatedParent.externalID);
          if (parentWondeId) {
            parentLookupByWondeId[parentWondeId] = updatedParent;
          }
          parentUpdateActions.push(action);
        }
        break;
      case 'addStudentGroupMembership':
      case 'removeStudentGroupMembership':
        classMembershipActions.push(action);
        break;
      case 'addStudent':
      case 'updateStudent':
      case 'removeStudent':
      case 'removeStudentWithNoClass':
        studentActions.push(action);
        break;
      case 'addParentLink':
      case 'removeParentLink':
        parentLinkActions.push(action);
        break;
    }
  }

  for (const action of studentActions) {
    switch (action.oneofKind) {
      case 'addStudent':
        previewResponse.newStudents.push(
          makePreviewStudentFromStudent(
            action.addStudent.student,
            studentGroupLookup,
            parentLookupByWondeId,
          ),
        );
        break;
      case 'updateStudent': {
        const { oldStudent, newStudent } = action.updateStudent;
        if (!oldStudent || !newStudent) {
          console.warn('invalid update student action: old and new student are required');
          continue;
        }

        const modifiedStudent = {
          before: makePreviewStudentFromStudent(oldStudent, studentGroupLookup, {}),
          after: makePreviewStudentFromStudent(
            {
              ...newStudent,
              studentGroupID: oldStudent?.studentGroupID,
              parents: oldStudent?.parents,
            },
            studentGroupLookup,
            parentLookupByWondeId,
          ),
        };
        const unexpired = hasBeenUnexpired(oldStudent?.inactiveDate, newStudent?.inactiveDate);
        if (unexpired) {
          previewResponse.unexpiredStudents.push(modifiedStudent);
        } else {
          previewResponse.modifiedStudents.push(modifiedStudent);
        }
        break;
      }
      case 'removeStudent':
        previewResponse.removedStudents.push(
          makePreviewStudentFromStudent(
            action.removeStudent.student,
            studentGroupLookup,
            parentLookupByWondeId,
          ),
        );
        break;
      case 'removeStudentWithNoClass':
        previewResponse.removedStudentsWithNoClass.push(
          makePreviewStudentFromStudent(
            students[action.removeStudentWithNoClass.studentId],
            studentGroupLookup,
            parentLookupByWondeId,
          ),
        );
        break;
    }
  }

  // Loop through a third time to process actions that require all existing student actions to have
  // been processed.
  for (const action of classMembershipActions) {
    switch (action.oneofKind) {
      case 'addStudentGroupMembership':
        {
          const { studentWondeId, studentGroupWondeId } = action.addStudentGroupMembership;

          const className = wondeClassLookup[studentGroupWondeId].name;

          const previewStudentClass: PreviewStudentClass = {
            wondeId: studentGroupWondeId,
            name: className,
          };

          const previewStudent =
            previewResponse.newStudents.find(s => s.wondeId === studentWondeId) ??
            previewResponse.modifiedStudents.find(s => s.after?.wondeId === studentWondeId)
              ?.after ??
            previewResponse.unexpiredStudents.find(s => s.after?.wondeId === studentWondeId)?.after;

          if (previewStudent) {
            previewStudent.groupWondeIds[productSubject] = previewStudentClass;
            continue;
          }

          const student = Object.values(students).find(
            s => getWondeIDFromExternalID('student', s.externalId) === studentWondeId,
          );
          if (!student) {
            console.warn(
              `invalid add student group membership action: no student found with wonde ID ${studentWondeId}`,
            );
            continue;
          }

          const { beforeStudent, afterStudent } = makeModifiedStudentFromStudent(
            student,
            studentGroupLookup,
            parentLookupByWondeId,
          );

          previewResponse.modifiedStudents.push({
            before: beforeStudent,
            after: {
              ...afterStudent,
              groupWondeIds: {
                ...afterStudent.groupWondeIds,
                [productSubject]: previewStudentClass,
              },
            },
          });
        }
        break;
      case 'removeStudentGroupMembership':
        {
          const { studentWondeId, studentGroupWondeId } = action.removeStudentGroupMembership;

          const studentIsBeingRemoved =
            previewResponse.removedStudents.findIndex(s => s.wondeId === studentWondeId) >= 0;
          if (studentIsBeingRemoved) {
            // We don't want to do anything here if the student is being removed
            continue;
          }

          const unexpiredStudent = previewResponse.unexpiredStudents.find(
            s => s.after?.wondeId === studentWondeId,
          );

          const modifiedStudent =
            previewResponse.modifiedStudents.find(s => s.after?.wondeId === studentWondeId) ??
            unexpiredStudent;

          if (modifiedStudent) {
            // If the wonde ID for the updated student's groupWondeIds for the current product is
            // the same as the one we want to remove then delete the group wonde ID for the student.
            const after = modifiedStudent.after?.groupWondeIds[productSubject];
            if (after?.wondeId === studentGroupWondeId) {
              delete modifiedStudent?.after?.groupWondeIds[productSubject];
            } else if (unexpiredStudent) {
              // If the student is being unexpired we will be removing them from any group that is
              // from another subject. We don't want to show this as a removal, so delete
              // it from both the before and after so the class cell is blank in the table.
              // TODO BOOL-2971 remove this when we are using Phase 2 syncs everywhere
              for (const [groupType, group] of Object.entries(
                unexpiredStudent.after?.groupWondeIds || {},
              )) {
                if (group.wondeId === studentGroupWondeId) {
                  delete unexpiredStudent.after?.groupWondeIds[groupType];
                  delete unexpiredStudent.before?.groupWondeIds[groupType];
                  break;
                }
              }
            }

            continue;
          }

          const student = Object.values(students).find(
            s => getWondeIDFromExternalID('student', s.externalId) === studentWondeId,
          );
          if (!student) {
            console.warn(
              `invalid remove student group membership action: no student found with wonde ID ${studentWondeId}`,
            );
            continue;
          }

          const { beforeStudent, afterStudent } = makeModifiedStudentFromStudent(
            student,
            studentGroupLookup,
            parentLookupByWondeId,
          );
          const newModifiedStudent: ModifiedStudent = {
            before: beforeStudent,
            after: { ...afterStudent, groupWondeIds: { ...afterStudent.groupWondeIds } },
          };
          delete newModifiedStudent.after?.groupWondeIds[productSubject];
          previewResponse.modifiedStudents.push(newModifiedStudent);
        }
        break;
    }
  }

  for (const action of parentLinkActions) {
    switch (action.oneofKind) {
      case 'addParentLink':
        {
          const { parentWondeId, studentWondeId } = action.addParentLink;

          const parent = parentLookupByWondeId[parentWondeId];
          if (!parent) {
            console.warn(`addParentLink: parent with wonde ID ${parentWondeId} not found`);
            continue;
          }

          // Check to see if this is a parent update we should ignore (e.g a Wonde ID change)
          const key = makeKey(parent);
          if (ignoredSyncParents.has(key)) {
            continue;
          }

          const newPreviewParent: PreviewParent = {
            firstName: parent.firstName,
            lastName: parent.lastName,
            email: parent.emailAddress,
            wondeId: parentWondeId,
          };

          const unexpiredStudent = previewResponse.unexpiredStudents.find(
            s => s.after?.wondeId === studentWondeId,
          );
          const modifiedStudent =
            previewResponse.newStudents.find(s => s.wondeId === studentWondeId) ??
            previewResponse.modifiedStudents.find(s => s.after?.wondeId === studentWondeId)
              ?.after ??
            unexpiredStudent?.after;
          if (modifiedStudent) {
            if (modifiedStudent.id) {
              markParentStudentIdProcessed(parent.parentID, modifiedStudent.id);
            }
            modifiedStudent.parentContacts.push(newPreviewParent);
            // If the student is being unexpired, push the parent to the before state as well. This
            // means the parent will be presented in plaintext in the table.
            if (unexpiredStudent) {
              unexpiredStudent.before?.parentContacts.push(newPreviewParent);
            }
            continue;
          }

          const student = studentLookupByWondeId[studentWondeId];
          if (!student) {
            console.warn(`addParentLink: student with wonde ID ${studentWondeId} not found`);
            continue;
          }
          markParentStudentIdProcessed(parent.parentID, student.studentId);

          const { beforeStudent, afterStudent } = makeModifiedStudentFromStudent(
            student,
            studentGroupLookup,
            parentLookupByWondeId,
          );

          previewResponse.modifiedStudents.push({
            before: beforeStudent,
            after: {
              ...afterStudent,
              parentContacts: [...afterStudent.parentContacts, newPreviewParent],
            },
          });
        }
        break;
      case 'removeParentLink':
        {
          const { parentWondeId, studentWondeId } = action.removeParentLink;
          const unexpiredStudent = previewResponse.unexpiredStudents.find(
            s => s.after?.wondeId === studentWondeId,
          );

          // If the student is unexpired we still want to remove the parent from the before and
          // after states so that the old parent doesn't show in the table.
          if (unexpiredStudent?.before) {
            unexpiredStudent.before.parentContacts = unexpiredStudent.before.parentContacts.filter(
              p => p.wondeId !== parentWondeId,
            );
          }
          if (unexpiredStudent?.after) {
            unexpiredStudent.after.parentContacts = unexpiredStudent.after.parentContacts.filter(
              p => p.wondeId !== parentWondeId,
            );
          }

          const parent = parentLookupByWondeId[parentWondeId];
          if (!parent) {
            console.warn(`removeParentLink: parent with wonde ID ${parentWondeId} not found`);
            continue;
          }

          // Check to see if this is a parent update we should ignore (e.g a Wonde ID change)
          const key = makeKey(parent);
          if (ignoredSyncParents.has(key)) {
            continue;
          }

          const removedStudent = previewResponse.removedStudents.find(
            s => s.wondeId === studentWondeId,
          );
          if (removedStudent) {
            continue;
          }

          const modifiedStudent =
            previewResponse.modifiedStudents.find(s => s.after?.wondeId === studentWondeId)
              ?.after ?? unexpiredStudent?.after;
          if (modifiedStudent) {
            if (modifiedStudent.id) {
              markParentStudentIdProcessed(parent.parentID, modifiedStudent.id);
            }
            modifiedStudent.parentContacts = modifiedStudent.parentContacts.filter(
              p => makeKey(parent) !== makeKey(p),
            );
            continue;
          }

          const student = studentLookupByWondeId[studentWondeId];
          if (!student) {
            console.warn(`removeParentLink: student with wonde ID ${studentWondeId} not found`);
            continue;
          }
          markParentStudentIdProcessed(parent.parentID, student.studentId);

          const { beforeStudent, afterStudent } = makeModifiedStudentFromStudent(
            student,
            studentGroupLookup,
            parentLookupByWondeId,
          );
          previewResponse.modifiedStudents.push({
            before: beforeStudent,
            after: {
              ...afterStudent,
              parentContacts: afterStudent.parentContacts.filter(
                p => makeKey(parent) !== makeKey(p),
              ),
            },
          });
        }
        break;
    }
  }

  const previewStudentIds = new Set();
  for (const s of previewResponse.modifiedStudents) {
    if (s.after?.id) {
      previewStudentIds.add(s.after?.id);
    }
  }
  for (const s of previewResponse.unexpiredStudents) {
    if (s.after?.id) {
      previewStudentIds.add(s.after?.id);
    }
  }
  for (const s of previewResponse.removedStudents) {
    previewStudentIds.add(s.id);
  }

  for (const action of parentUpdateActions) {
    const parentId = action.updateParent.oldParent?.parentID;
    if (!parentId) {
      console.warn(`parentUpdate: missing parent ID from update action`, action);
      continue;
    }

    const studentIds = unprocessedParentStudentsById[parentId];
    if (!studentIds) {
      continue;
    }

    for (const studentId of studentIds) {
      // Student already using the updated parent
      if (previewStudentIds.has(studentId)) {
        continue;
      }

      const student = students[studentId];
      if (!student) {
        continue;
      }

      const modifiedStudent =
        previewResponse.modifiedStudents.find(s => s.after?.id === studentId) ??
        previewResponse.unexpiredStudents.find(s => s.after?.id === studentId);

      if (modifiedStudent) {
        // We've updated this student before, so we need to add a new update parent contact action
        const updatedParent = action.updateParent.newParent;
        const oldParent = action.updateParent.oldParent;
        if (!updatedParent) {
          console.warn(`parentUpdate: missing new parent from update action`, action);
          continue;
        }
        // Update the parent contact for the student
        modifiedStudent.after?.parentContacts.map(p => {
          if (p.email === oldParent?.emailAddress) {
            p.firstName = updatedParent.firstName;
            p.lastName = updatedParent.lastName;
            p.email = updatedParent.emailAddress;
          }
          return p;
        });
        continue;
      }

      const { beforeStudent, afterStudent } = makeModifiedStudentFromStudent(
        student,
        studentGroupLookup,
        parentLookupByWondeId,
      );
      previewResponse.modifiedStudents.push({
        before: beforeStudent,
        after: afterStudent,
      });
    }
  }

  return previewResponse;
};
