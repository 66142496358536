import {
  SubmitContentFeedbackRequest,
  SubmitStudentSentimentFeedbackRequest,
} from '@sparx/api/apis/sparx/interaction/feedback/v1/feedback';
import { useMutation } from '@tanstack/react-query';
import { studentFeedbackClient, teacherFeedbackClient } from 'api';

export const useSubmitStudentFeedback = () =>
  useMutation(
    async (req: SubmitStudentSentimentFeedbackRequest) =>
      studentFeedbackClient.submitStudentSentimentFeedback(req).response,
  );

export const useSubmitContentFeedback = () =>
  useMutation(
    async (req: SubmitContentFeedbackRequest) =>
      teacherFeedbackClient.submitContentFeedback(req).response,
  );
