import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { useUserType } from 'api/sessions';
import { CombinedIcon } from 'utils/subjects';

import { useIndependentLearningContext } from '../IndependentLearning';
import { ILChevron } from './Misc';

export const PersonalPractice = () => {
  const { isTeacher } = useUserType();
  const { createTask, userState } = useIndependentLearningContext();

  // Can only do personal practice is topics have been assigned (or they are a teacher who will get a demo version)
  const hasPreviousTopics = userState.previousTopics.length > 0 || isTeacher;

  return (
    <Box
      bg="white"
      boxShadow="elevationLow"
      borderRadius="lg"
      p={{ base: 6, md: 8 }}
      flex={1}
      flexDirection="column"
      display="flex"
      position="relative"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        p={{ base: 6, md: 8 }}
      >
        <CombinedIcon height="100%" maxHeight={100} maxWidth="110px" width="unset" />
      </Box>
      <Heading fontSize="xl" mb={3}>
        Personal practice
      </Heading>
      <Text mb={3} mr="120px">
        Let Sparx choose practice questions for you, based on what you&apos;ve done in your
        homework.
      </Text>
      <Flex grow={1} />
      {hasPreviousTopics ? (
        <Button
          onClick={e => {
            e.stopPropagation();
            createTask();
          }}
          colorScheme="buttonTeal"
          rightIcon={<ILChevron />}
          maxWidth={200}
        >
          Start
        </Button>
      ) : (
        <Text color="gray.500" mr="120px">
          Your personal practice isn&apos;t ready yet. Why not choose a topic below instead.
        </Text>
      )}
    </Box>
  );
};
