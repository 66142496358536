import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useSchoolGroups } from 'api/school';
import {
  useGroupsUsingSol,
  useSuspenseGroupsWithSettings,
  useUpdateGroupSettings,
} from 'api/scienceschool';
import { usePublishSol, usePublishTemplateSol } from 'api/sol';
import { LargeLoading } from 'components/loading/LargeLoading';
import React, { useState } from 'react';
import {
  NoHolidaysWarning,
  PlannerSettingsChangePreview,
  useSettingsChangeAnalytics,
} from 'views/planner/components/PlannerSettingsChangePreview';

import { SolOrTemplate } from './SolTable';

interface PublishSolModalProps {
  scheme: SolOrTemplate | undefined;
  onClose: () => void;
  onPublish: () => void;
}

export const PublishSolModal = ({ scheme, onClose, onPublish }: PublishSolModalProps) => {
  const { mutateAsync: publishSol, isLoading: isLoadingSol } = usePublishSol();
  const { mutateAsync: publishTemplate, isLoading: isLoadingTemplate } = usePublishTemplateSol();
  const { mutateAsync: updateGroupSettings, isLoading: isLoadingGroups } = useUpdateGroupSettings({
    forceSchemeUpdate: true,
  });
  const [loadingPreview, setLoadingPreview] = useState(true);

  const groups = useSuspenseGroupsWithSettings();
  const solGroups = useGroupsUsingSol(groups, scheme?.name || '');

  const { data: schoolGroups, isLoading: loadingSchoolGroups } = useSchoolGroups({
    suspense: false,
    enabled: !!scheme?.isTemplate,
  });
  const isLoading = isLoadingSol || isLoadingGroups || loadingSchoolGroups;

  const schoolGroupName =
    schoolGroups?.find(g => scheme?.name.startsWith(g.name))?.displayName || 'Unknown school group';

  const submit = () => {
    if (!scheme) return;
    if (scheme.isTemplate) {
      publishTemplate(scheme).then(onPublish);
    } else {
      sendAnalytics(true);
      publishSol(scheme)
        .then(async data => {
          if (solGroups.length > 0) {
            await updateGroupSettings(solGroups.map(g => ({ ...g.scienceSettings, name: g.name })));
          }
          return data;
        })
        .then(onPublish);
    }
  };

  const doClose = () => {
    // Don't allow the modal to close while we are doing the update
    if (!isLoadingGroups) {
      sendAnalytics(false);
      onClose();
    }
  };

  const settingsAnalytics = useSettingsChangeAnalytics(
    isLoading || loadingPreview,
    'sol_builder',
    'group_changes_',
    solGroups,
  );

  const sendAnalytics = scheme?.isTemplate ? () => undefined : settingsAnalytics;

  return (
    <Modal isOpen={!!scheme} size="xl" onClose={doClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb={1}>
          Publish Scheme of Learning{scheme?.isTemplate && ' Template'}
        </ModalHeader>
        <ModalBody>
          {scheme?.isTemplate ? (
            loadingSchoolGroups ? (
              <LargeLoading />
            ) : (
              <>
                <Text>
                  This template is visible to all schools in <strong>{schoolGroupName}</strong>.
                </Text>
                <Text>Are you sure you want to publish this template?</Text>
              </>
            )
          ) : (
            <>
              {solGroups.length > 0 && <NoHolidaysWarning yearIndex={scheme?.schoolYear} />}
              Are you sure you want to publish this scheme?
              {solGroups.length > 0 && (
                <Alert status="warning" borderRadius="md" mt={3}>
                  <Text>
                    There {solGroups.length === 1 ? 'is' : 'are'}{' '}
                    <strong>
                      {solGroups.length} {solGroups.length === 1 ? 'class' : 'classes'}
                    </strong>{' '}
                    using this scheme. The homework planners for these classes will be updated.
                  </Text>
                </Alert>
              )}
              <PlannerSettingsChangePreview
                groups={solGroups}
                updatingSow={true}
                useDraft={true}
                setLoading={b => setLoadingPreview(b)}
              />
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={doClose} mr={2} variant="outline" isDisabled={isLoading}>
            Close
          </Button>
          <Button
            onClick={submit}
            colorScheme="buttonTeal"
            isLoading={isLoading || isLoadingTemplate || (!scheme?.isTemplate && loadingPreview)}
          >
            Publish
          </Button>
        </ModalFooter>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};
