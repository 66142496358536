import { Text, TextProps, Tooltip, useBoolean } from '@chakra-ui/react';

export const HigherOnlySkillTag = (props: TextProps) => (
  <Tag
    text="Higher only"
    baseColor="blue.500"
    tooltipText="This question will only be assigned to students set to the Higher Tier in Student Manager."
    {...props}
  />
);

export const SeparateOnlySkillTag = (props: TextProps) => (
  <Tag
    text="Separate only"
    baseColor="purple.500"
    tooltipText="This question will only be assigned to students set to the Separate Course in Student Manager."
    {...props}
  />
);

export const HigherOnlyTopicTag = (props: TextProps) => (
  <Tag
    text="Higher only"
    baseColor="blue.500"
    tooltipText="This topic will only be assigned to students set to the Higher Tier in Student Manager."
    {...props}
  />
);

export const SeparateOnlyTopicTag = (props: TextProps) => (
  <Tag
    text="Separate only"
    baseColor="purple.500"
    tooltipText="This topic will only be assigned to students set to the Separate Course in Student Manager."
    {...props}
  />
);

export const Tag = ({
  baseColor,
  tooltipText,
  text,
  ...props
}: TextProps & { text: string; baseColor: string; tooltipText: string }) => {
  // handle opening the tooltip manually so we can work on click for touch devices
  const [isOpen, setOpen] = useBoolean(false);

  return (
    <Tooltip placement="bottom" label={tooltipText} textAlign="center" hasArrow isOpen={isOpen}>
      <Text
        as="span"
        fontSize="2xs"
        px={1}
        borderWidth={1}
        borderRadius="base"
        borderColor={baseColor}
        color={baseColor}
        fontWeight="bold"
        textAlign="center"
        onMouseEnter={setOpen.on}
        onMouseLeave={setOpen.off}
        onClick={e => {
          e.preventDefault();
          setOpen.on();
        }}
        whiteSpace={'nowrap'}
        {...props}
      >
        {text}
      </Text>
    </Tooltip>
  );
};
