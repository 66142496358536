import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Select,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { faCircleMinus, faPlus, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { GroupSettings } from '@sparx/api/apis/sparx/science/schools/settings/v1/settings';
import { StaffClassMembership } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { readableStaffNames } from '@sparx/staff-manager';
import { GroupWithSettings, useSuspenseGroupsWithSettings } from 'api/scienceschool';
import { useScienceStaffv2 } from 'api/staff';
import { NotFound } from 'components/errorpages/NotFound';
import {
  HeaderField,
  PageSubmitFooter,
  ReadonlyField,
  useFormId,
} from 'components/form/FormFields';
import { InterimLock } from 'components/InterimLock';
import { LargeLoading } from 'components/loading/LargeLoading';
import { StaffRealName } from 'components/Names';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import React, { useMemo, useState } from 'react';
import { Link, unstable_usePrompt, useLocation, useParams } from 'react-router-dom';
import { EditGroupSettings } from 'views/groups/GroupBulkEdit';
import { SaveGroupSettingsModal } from 'views/groups/SaveGroupSettingsModal';

export const GroupDetailView = () => (
  <SuspenseRoute>
    <InterimLock title="Class details">
      <SuspenseGroupDetailView />
    </InterimLock>
  </SuspenseRoute>
);

const SuspenseGroupDetailView = () => {
  const { groupID = '' } = useParams();
  const groups = useSuspenseGroupsWithSettings();
  const group = groups.find(group => group.name.endsWith(`/studentGroups/${groupID}`));

  if (!group) {
    return <NotFound />;
  }
  return <GroupEdit group={group} />;
};

const staffDirty = (a: StaffClassMembership[], b: StaffClassMembership[]) =>
  a.length !== b.length || a.some(sa => !b.find(sb => sb.staffID === sa.staffID));

const GroupEdit = ({ group }: { group: GroupWithSettings }) => {
  const groupID = group.name.split('/')[3];
  const [settings, setSettings] = useState<Partial<GroupSettings>>(group.scienceSettings);
  const [staff, setStaff] = useState(group.staff);

  const addStaff = (ssm: SchoolStaffMember) => {
    const staffID = ssm.name.split('/')[1];
    const schoolID = ssm.school.split('/')[1];

    setStaff(sList => [
      ...sList.filter(s => s.staffID !== staffID),
      {
        staffID,
        schoolID,
        studentGroupID: groupID,
        feedbackEnabled: false, // We don't support this yet
      },
    ]);
  };
  const removeStaff = (name: string) => {
    const staffID = name.split('/')[1];
    setStaff(sList => sList.filter(s => s.staffID !== staffID));
  };

  const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure();
  const isStaffDirty = useMemo(() => staffDirty(staff, group.staff), [staff, group.staff]);
  const isDirty = useMemo(
    () =>
      !GroupSettings.equals(group.scienceSettings, GroupSettings.create(settings)) || isStaffDirty,
    [group, settings, isStaffDirty],
  );

  const solChanged = group.scienceSettings.solName !== settings.solName && !!settings.solName;

  const { state } = useLocation();
  const backLink = state?.back || '/teacher/group';
  const formID = useFormId();

  unstable_usePrompt({
    message: 'You have unsaved changes. Are you sure you want to leave?',
    when: isDirty,
  });

  return (
    <PageContainer>
      <Box
        id={formID}
        as="form"
        onSubmit={e => {
          e.preventDefault();
          onOpen();
        }}
        mb={4}
      >
        <SaveGroupSettingsModal
          isOpen={isOpen}
          groups={[{ ...group, scienceSettings: { ...group.scienceSettings, ...settings }, staff }]}
          onClose={onCloseModal}
          onSubmit={onCloseModal}
          solChanged={solChanged}
          staffChanged={isStaffDirty}
        />
        <PageTitle title={`${group.displayName}`} backButton={backLink}>
          <Button
            as={Link}
            variant="outline"
            to={`/teacher/student?group=${groupID}`}
            colorScheme="buttonTeal"
            leftIcon={<FontAwesomeIcon icon={faUsers} />}
          >
            View students in this class
          </Button>
        </PageTitle>

        <Box backgroundColor="white" p={8} borderRadius="md" boxShadow="lg" width="100%">
          <Grid gap={4} templateColumns="repeat(2, 1fr)">
            <GridItem colSpan={2}>
              <Alert status="info" borderRadius="md">
                <AlertIcon />
                <Text>
                  Some class data can't be changed within Sparx as it's managed by your school's
                  MIS. If you need to make changes to this data, please speak to your school's MIS
                  administrator.
                </Text>
              </Alert>
            </GridItem>
            <HeaderField name="Class details" first={true} />
            <ReadonlyField name="Class name" value={group.displayName} />
            <HeaderField name="Homework details" />
            <EditGroupSettings settings={settings} setSettings={setSettings} />
            <HeaderField name="Teachers" />
            <GridItem colSpan={2}>
              <EditTeachers staff={staff} addStaff={addStaff} removeStaff={removeStaff} />
            </GridItem>
          </Grid>
        </Box>
      </Box>
      <PageSubmitFooter
        formID={formID}
        isDirty={isDirty}
        backLink={backLink}
        saveText="Preview changes"
      />
    </PageContainer>
  );
};

const EditTeachers = ({
  staff,
  addStaff,
  removeStaff,
}: {
  staff: StaffClassMembership[];
  addStaff: (ssm: SchoolStaffMember) => void;
  removeStaff: (name: string) => void;
}) => {
  const { data: staffList, isLoading } = useScienceStaffv2({ suspense: false });
  const [addingTeacher, setAddingTeacher] = useState(false);

  const availableTeachers = useMemo(() => {
    return (staffList || [])
      .sort(
        (a, b) =>
          a.givenName.localeCompare(b.givenName) || a.familyName.localeCompare(b.familyName),
      )
      .filter(s => !staff.find(scm => scm.staffID === s.name.split('/')[1]));
  }, [staffList, staff]);

  if (isLoading || staffList === undefined) {
    return <LargeLoading />;
  }

  return (
    <VStack width="100%" justifyContent="stretch" spacing={1}>
      {staff.map(scm => {
        const s = staffList.find(st => st.name === 'staff/' + scm.staffID);
        if (!s) return null;
        return (
          <Flex
            key={scm.staffID}
            width="100%"
            justifyContent="space-between"
            backgroundColor="gray.100"
            py={2}
            pr={2}
            pl={6}
            alignItems="center"
          >
            <Text>
              <StaffRealName staff={s} />
            </Text>
            <Button
              colorScheme="buttonBlue"
              onClick={() => removeStaff(s.name)}
              leftIcon={<FontAwesomeIcon icon={faCircleMinus} />}
            >
              Remove
            </Button>
          </Flex>
        );
      })}
      {addingTeacher ? (
        <Flex
          width="100%"
          justifyContent="space-between"
          backgroundColor="gray.100"
          py={2}
          pr={2}
          pl={6}
          alignItems="center"
        >
          <Box flex={1}>
            <Select
              onChange={e => {
                const s = staffList.find(s => s.name === e.target.value);
                if (s) addStaff(s);
                setAddingTeacher(false);
              }}
              value={''}
              maxWidth="350px"
              bg="white"
              data-sentry-mask
            >
              <option value="" disabled>
                {availableTeachers.length === 0 ? 'No teachers available' : 'Select a teacher'}
              </option>
              {availableTeachers.map(s => (
                <option key={s.name} value={s.name}>
                  {readableStaffNames(s).realName}
                </option>
              ))}
            </Select>
          </Box>
          <Button
            colorScheme="buttonBlue"
            onClick={() => setAddingTeacher(false)}
            leftIcon={<FontAwesomeIcon icon={faCircleMinus} />}
          >
            Remove
          </Button>
        </Flex>
      ) : (
        <Button
          width="100%"
          textAlign="left"
          justifyContent="flex-start"
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          borderRadius={0}
          onClick={() => setAddingTeacher(true)}
          height={14}
          pl={6}
        >
          Add teacher
        </Button>
      )}
    </VStack>
  );
};
