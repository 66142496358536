import { Button, ButtonGroup, GridItem, HStack, Text } from '@chakra-ui/react';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Package_Contents, TaskItem } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { useUserType } from 'api/sessions';
import { Copyable } from 'components/copyable/Copyable';
import React, { PropsWithChildren, useMemo } from 'react';
import { TaskItemPanelHeader } from 'views/answerhistory/TaskItemPanel';

const curmanURL = 'https://curriculums.internal.algenie.uk';

interface TaskItemWrapperProps {
  contents: Package_Contents | undefined;
  selectedItem?: string;
  setSelectedItem: (item?: string) => void;
  noSelectedItemTitle?: string;
}

export const TaskItemWrapper = ({
  contents,
  selectedItem,
  setSelectedItem,
  noSelectedItemTitle,
  children,
}: PropsWithChildren<TaskItemWrapperProps>) => {
  const { isSparxStaff } = useUserType();
  const [previousItem, nextItem] = useMemo(() => {
    let gatherNext = selectedItem === undefined;
    let previousItem = undefined;
    for (const task of contents?.tasks || []) {
      for (const item of task.contents?.taskItems || []) {
        if (gatherNext) return [previousItem, item.name];
        if (item.name === selectedItem) gatherNext = true;
        if (!gatherNext) previousItem = item.name;
      }
    }
    return [previousItem, undefined];
  }, [contents, selectedItem]);

  const { task, taskItem } = usePackageContentsTaskItems(contents, selectedItem);

  let selectedSkillID: undefined | string;
  let selectedTopicID: undefined | string;
  if (taskItem?.contents?.contents.oneofKind === 'skill') {
    selectedSkillID = taskItem?.contents?.contents.skill.skillId;
    selectedTopicID = taskItem?.contents?.contents.skill.topicName?.split('/topics/')[1];
  }

  const sparxStaffOptions = isSparxStaff && selectedSkillID && (
    <HStack
      spacing={3}
      mx={1}
      mb={1}
      p={2}
      alignItems="center"
      bgColor="whiteAlpha.400"
      borderRadius="base"
    >
      <Text color="white" fontWeight="bold" fontSize="sm" lineHeight="1em">
        Skill:
      </Text>
      <ButtonGroup isAttached={true} size="xs">
        <Copyable value={selectedSkillID} />
        <Button
          bg="gray.300"
          flexShrink={1}
          onClick={() => window.open(`${curmanURL}/skills/${selectedSkillID}`, '_blank')}
          leftIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
        >
          Curman
        </Button>
      </ButtonGroup>
      {selectedTopicID && (
        <>
          <Text color="white" fontWeight="bold" fontSize="sm" lineHeight="1em" pl={2}>
            Topic:
          </Text>
          <Button
            size="xs"
            flexShrink={1}
            onClick={() => window.open(`${curmanURL}/topic/${selectedTopicID}`, '_blank')}
            leftIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
          >
            Curman
          </Button>
        </>
      )}
    </HStack>
  );

  return (
    <GridItem
      overflowY="auto"
      bg="blue.800"
      borderRadius="md"
      overflow="hidden"
      display="flex"
      flexDirection="column"
    >
      {(selectedItem || noSelectedItemTitle) && (
        <TaskItemPanelHeader
          previousItem={previousItem}
          nextItem={nextItem}
          setSelectedItem={setSelectedItem}
          selectedTask={task}
          selectedTaskItem={taskItem}
          noSelectedItemTitle={noSelectedItemTitle}
        />
      )}
      {sparxStaffOptions}
      {children}
    </GridItem>
  );
};

export const usePackageContentsTaskItems = (
  contents: Package_Contents | undefined,
  taskItemName: string | undefined,
) => {
  const selectedTask = useMemo(() => {
    const taskName = taskItemName?.split('/items/')[0];
    return contents?.tasks.find(t => t.name === taskName);
  }, [contents, taskItemName]);

  const selectedTaskItem = useMemo(
    () => selectedTask?.contents?.taskItems.find(t => t.name === taskItemName),
    [selectedTask, taskItemName],
  );

  return { taskItem: selectedTaskItem, task: selectedTask };
};

export const getTaskItemSkill = (taskItem: TaskItem | undefined) =>
  taskItem?.contents?.contents.oneofKind === 'skill' ? taskItem.contents.contents.skill : undefined;
