import Button from '@sparx/mis-sync-import/src/MisSyncImport/components/common/Button/Button';
import Card from '@sparx/mis-sync-import/src/MisSyncImport/components/common/Card/Card';
import { PropsWithChildren } from 'react';

import styles from './ErrorPanel.module.css';

type Props = {
  /**
   * Title for the panel. Defaults to "An error occurred"
   */
  title?: string;
  /**
   * Optional action. Setting both this and the actionText adds a button to the panel.
   */
  action: () => void;
  /**
   * Optional action text to use for the button. Only required if action is also set.
   */
  actionText: string;
};

/**
 * Renders an error panel to the screen with an optional button to perform an action.
 */
const ErrorPanel = ({
  title = 'An error occurred',
  children,
  action,
  actionText,
}: PropsWithChildren<Props>) => {
  return (
    <Card className={styles.Card}>
      <p className={styles.Title}>{title}</p>
      <div className={styles.Content}>{children}</div>
      <Button onClick={() => action()}>{actionText}</Button>
    </Card>
  );
};

export default ErrorPanel;
