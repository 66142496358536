import { Box } from '@chakra-ui/react';
import { KeyContactListView as KeyContactListViewComponent } from '@sparx/staff-manager';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import React from 'react';

export const KeyContactListView = () => {
  return (
    <SuspenseRoute>
      <KeyContactListViewComponent>
        {({ table }) => (
          <PageContainer>
            <PageTitle title="Staff Manager" />
            <Box data-sentry-mask>{table}</Box>
          </PageContainer>
        )}
      </KeyContactListViewComponent>
    </SuspenseRoute>
  );
};
