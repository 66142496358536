import {
  Button,
  Grid,
  Link as ChakraLink,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useToast,
} from '@chakra-ui/react';
import { ResourceMetadata, SchemeOfLearning } from '@sparx/api/apis/sparx/planning/v1/sol';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { useDisclosure } from '@sparx/react-utils';
import { SchoolYearWithDates, useHolidaysForSchoolYear, useWeeksForYear } from 'api/school';
import { useCreateSol } from 'api/sol';
import { useClientEvent } from 'components/ClientEventProvider';
import { EditableField } from 'components/form/FormFields';
import { LargeLoading } from 'components/loading/LargeLoading';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { InlineTextTooltip } from 'components/tooltip/Tooltip';
import { Warning } from 'components/warning';
import React, { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { plural } from 'utils/plural';

import { copySolWeeks, hasTopicsToMigrate, migrateWeekTopics } from '../solHelpers';
import { MigrationWarning } from '../topic-migration/MigrationWarning';
import { useTopicMigrationMap } from '../topic-migration/query';

interface BulkCopySolModalProps {
  sols: SchemeOfLearning[];
  fromYear?: SchoolYearWithDates;
  toYear: SchoolYearWithDates;
}

export const BulkCopySolModal = ({ sols, fromYear, toYear }: BulkCopySolModalProps) => {
  const { sendEvent } = useClientEvent();
  const toast = useToast();

  const [hwOffInHolidays, setHwOffInHolidays] = useState(true);

  const toAcademicYear = toYear.name.split('/')[3];
  // Load holidays for toYear
  const { data: holidays = [], isLoading: loadingHolidays } = useHolidaysForSchoolYear(
    toAcademicYear,
    { suspense: false },
  );
  const { data: calendarWeeks, isLoading: loadingCalendar } = useWeeksForYear(toAcademicYear, {
    suspense: false,
  });
  const { data: topicMigrationMap, isSuccess: migrationMapLoaded } = useTopicMigrationMap();
  const { mutateAsync: createSol, isIdle, reset } = useCreateSol();

  const { open, onOpen, onClose } = useDisclosure({
    onClose: () => {
      // reset state
      reset();
      setHwOffInHolidays(true);
    },
  });

  const isLoading = loadingCalendar || loadingHolidays || !migrationMapLoaded;

  const hasMigratableTopics = useMemo(
    () => sols.some(s => hasTopicsToMigrate(s.schedule?.weeks || [], topicMigrationMap || {})),
    [sols, topicMigrationMap],
  );

  // modal content ref, so the tooltip portal can be placed correctly
  const ref = useRef(null);

  const submit = async () => {
    if (!calendarWeeks) return;

    const promises = [];
    for (const solToCopy of sols) {
      const { newWeeks } = migrateWeekTopics(
        solToCopy.schedule?.weeks || [],
        topicMigrationMap || {},
      );
      const weeks = copySolWeeks(newWeeks, calendarWeeks, false, hwOffInHolidays);

      const req = SchemeOfLearning.create({
        ...solToCopy,
        name: '',
        schoolYear: toAcademicYear, // This is just the year index to match what Maths TP does
        schedule: {
          weeks,
        },
        metadata: ResourceMetadata.create({
          product: Product.SPARX_SCIENCE,
          tags: ['subject:science'],
        }),
      });

      promises.push(createSol(req));
    }
    const startTime = new Date();
    await Promise.allSettled(promises).then(res => {
      const took = new Date().getTime() - startTime.getTime();

      const failedSols: string[] = [];
      const failedData: { name: string; error: string }[] = [];
      const solNames: string[] = [];
      const newSols: string[] = [];
      for (const [i, r] of res.entries()) {
        solNames.push(sols[i].name);
        if (r.status === 'rejected') {
          failedSols.push(sols[i].displayName);
          failedData.push({ name: sols[i].name, error: r.reason });
        } else {
          newSols.push(r.value.name);
        }
      }

      sendEvent(
        { action: 'bulk_copy', category: 'scheme_of_learning' },
        {
          solsToCopy: solNames.join(','),
          newSols: newSols.join(','),
          failedSols: JSON.stringify(failedData),
          homeworkOffInHolidays: hwOffInHolidays && holidays.length > 0 ? 'true' : 'false',
          timeTakem: took.toString(),
        },
      );

      if (failedSols.length > 0) {
        const all = failedSols.length === sols.length;
        toast({
          status: 'error',
          title: `Failed to copy Schemes of Learning`,
          description: all ? (
            <>
              We were unable to copy any Schemes please try again or try copying them individually.
            </>
          ) : (
            <>
              We were unable to copy the following Schemes please try copying them individually:
              <br />
              {failedSols.map((name, idx) => (
                <>
                  {idx !== 0 && ', '}
                  <Text
                    as="span"
                    key={idx}
                    bgColor="whiteAlpha.300"
                    borderRadius="md"
                    px={1}
                    py={0.5}
                  >
                    {name}
                  </Text>
                </>
              ))}
            </>
          ),
          duration: null,
          isClosable: true,
        });
      }

      onClose();
    });
  };

  if (!fromYear) return null;

  const fromYearStr = `${fromYear.start.getFullYear()}/${fromYear.end.getFullYear() - 2000}`;

  return (
    <>
      <Button colorScheme="buttonTeal" onClick={onOpen}>
        Copy schemes from {fromYearStr}
      </Button>
      <Modal isOpen={open} size="xl" onClose={() => isIdle && onClose()}>
        <ModalOverlay />
        <ModalContent ref={ref}>
          <ModalHeader pb={1}>Copy Schemes of Learning from {fromYearStr}</ModalHeader>
          <ModalBody>
            <Grid gap={4}>
              {loadingHolidays ? (
                <LargeLoading />
              ) : (
                <>
                  <Text>
                    <InlineTextTooltip
                      portalContainerRef={ref}
                      text={`${sols.length} ${plural(sols.length, 'Scheme')} of Learning`}
                    >
                      <UnorderedList>
                        {sols
                          .sort((a, b) =>
                            a.displayName.localeCompare(b.displayName, undefined, {
                              numeric: true,
                            }),
                          )
                          .map(sol => (
                            <ListItem key={sol.name}>{sol.displayName}</ListItem>
                          ))}
                      </UnorderedList>
                    </InlineTextTooltip>{' '}
                    will be copied from {fromYear.current ? 'this' : 'last'} academic year to{' '}
                    {toYear.current ? 'this' : 'next'} academic year.
                  </Text>

                  <EditableField name="Automatically turn off homework in holiday weeks">
                    <ToggleSwitch
                      checked={hwOffInHolidays && holidays.length > 0}
                      setChecked={checked => setHwOffInHolidays(checked)}
                      leftLabel="No"
                      rightLabel="Yes"
                      offColour="gray.400"
                      enableColourBG={true}
                      isDisabled={holidays.length === 0}
                    />
                  </EditableField>
                  {!loadingHolidays && holidays.length === 0 && (
                    <Warning
                      status="warning"
                      title={
                        <strong>
                          You haven&apos;t set holiday dates for {toYear?.next ? 'next' : 'this'}{' '}
                          academic year
                        </strong>
                      }
                    >
                      Set your holiday dates before creating schemes of learning and we&apos;ll use
                      these to help you plan homework correctly. <br />
                      <ChakraLink
                        as={Link}
                        to={`/teacher/school-settings/academic-year${toYear?.next ? '?ay=next' : ''}`}
                        textDecoration="underline"
                      >
                        Manage your holiday dates here.
                      </ChakraLink>
                    </Warning>
                  )}
                  {hasMigratableTopics && <MigrationWarning />}
                </>
              )}
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} mr={2} variant="outline" isDisabled={!isIdle}>
              Close
            </Button>
            <Button onClick={submit} colorScheme="buttonTeal" isLoading={isLoading || !isIdle}>
              Copy scheme{sols.length !== 1 && 's'}
            </Button>
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};
