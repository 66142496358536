import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faBarChart,
  faCalendar,
  faChalkboardTeacher,
  faChartLine,
  faFileLines,
  faGraduationCap,
  faList,
  faQuestionCircle,
  faTable,
  faUser,
  faUserGraduate,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { listStaffQuery } from '@sparx/staff-manager/src/queries';
import { getStaffClient } from 'api';
import { queryClient } from 'api/client';
import { findGroupOfType, groupsQuery, studentsQuery } from 'api/school';
import { FeatureFlag, getSchoolID, useBooleanFlag } from 'api/sessions';
import MiniSearch from 'minisearch';
import { useMemo } from 'react';
import { urlKnowledgeBase } from 'utils/knowledgeBaseArticles';

export interface SearchDocument {
  id: string;
  title: string;
  altTitle?: string;
  parent?: string;
  rightText?: string;
  type: 'page' | 'student' | 'staff';
  icon?: IconDefinition;
  url: string;
}

const createEngine = () =>
  new MiniSearch({
    fields: ['title', 'altTitle', 'parent', 'rightText'],
    storeFields: ['title', 'parent', 'rightText', 'type', 'url', 'icon'],
    searchOptions: {
      fuzzy: true,
      prefix: true,
      boost: { title: 2 },
    },
  });

const indexStudents = (engine: MiniSearch<SearchDocument>, students: Student[], groups: Group[]) =>
  engine.addAll(
    students.map(student => {
      const group = findGroupOfType(student, groups, StudentGroupType.CLASS_SCIENCE);
      return {
        id: student.studentId,
        title: `${student.givenName} ${student.familyName}`,
        rightText: group?.displayName,
        type: 'student',
        url: `/teacher/student/${student.studentId}/summary`,
        icon: faUser,
      } satisfies SearchDocument;
    }),
  );

const indexStaff = (engine: MiniSearch<SearchDocument>, staff: SchoolStaffMember[]) =>
  engine.addAll(
    staff.map(staff => {
      const staffId = staff.name.slice('staff/'.length);
      return {
        id: staffId,
        title: `${staff.givenName} ${staff.familyName}`,
        type: 'staff',
        url: `/teacher/staff/${staffId}/details`,
        icon: faUserGraduate,
      } satisfies SearchDocument;
    }),
  );

const getGroupSpecificPages = (group: Group, groupID: string) =>
  [
    {
      title: 'Hand-in',
      altTitle: 'handin',
      url: `/teacher/handin?group=${groupID}`,
      icon: faBarChart,
    },
    { title: 'Insights', url: `/teacher/insights?group=${groupID}`, icon: faChartLine },
    { title: 'Planner', url: `/teacher/planner?group=${groupID}`, icon: faCalendar },
    { title: 'Student Manager', url: `/teacher/student?group=${groupID}`, icon: faUsers },
  ].map(
    page =>
      ({
        id: group.name + '-' + page.title,
        parent: group.displayName,
        title: page.title,
        altTitle: page.altTitle,
        type: 'page',
        url: page.url,
        icon: page.icon,
      }) satisfies SearchDocument,
  );

const getGroupPages = (group: Group) => {
  const groupID = group.name.split('/')[3];
  const groupPage = {
    id: group.name,
    title: group.displayName,
    // alt title is display name without special characters
    altTitle: group.displayName.toLowerCase().replace(/[^a-z0-9]/g, ''),
    type: 'page',
    url: `/teacher/group/${groupID}/details`,
    icon: faChalkboardTeacher,
  } satisfies SearchDocument;
  return [...getGroupSpecificPages(group, groupID), groupPage];
};

const indexGroups = (engine: MiniSearch<SearchDocument>, groups: Group[]) =>
  groups.forEach(grp => engine.addAll(getGroupPages(grp)));

const indexCommonPages = (
  engine: MiniSearch<SearchDocument>,
  opts: {
    lessonsEnabled: boolean;
  },
) => {
  for (const page of [
    {
      title: 'Lessons',
      url: '/teacher/lessons',
      icon: faChalkboardTeacher,
      disabled: !opts.lessonsEnabled,
    },
    { title: 'Reporting', url: '/teacher/reports', icon: faTable },
    { title: 'Class Manager', url: '/teacher/group', icon: faChalkboardTeacher },
    { title: 'Staff Manager', url: '/teacher/staff', icon: faGraduationCap },
    { title: 'Scheme of Learning', url: '/teacher/sol', icon: faFileLines },
    { title: 'Student Demo', url: '/packages', icon: faList },
    { title: 'Help', url: urlKnowledgeBase, icon: faQuestionCircle },
    { title: 'Support centre', url: urlKnowledgeBase, icon: faQuestionCircle },
    { title: 'Training', url: '/teacher/training', icon: faGraduationCap },
    { title: "What's New?", url: '/teacher/whats-new', icon: faGraduationCap },
  ]) {
    if (page.disabled) {
      continue;
    }

    engine.add({
      id: page.title,
      title: page.title,
      type: 'page',
      url: page.url,
      icon: page.icon,
    } satisfies SearchDocument);
  }
};

export const useSearch = () => {
  const lessonsEnabled = useBooleanFlag(FeatureFlag.Lessons);

  return useMemo(async () => {
    const engine = createEngine();
    const staffClient = await getStaffClient();

    // Get all the data we need for the search engine
    const [students, groups, staff] = await Promise.all([
      queryClient.fetchQuery(studentsQuery),
      queryClient.fetchQuery(groupsQuery),
      queryClient.fetchQuery(listStaffQuery({ staffClient, getSchoolID })),
    ]);

    // Index the pages
    indexStudents(engine, students, groups);
    indexStaff(engine, staff);
    indexGroups(engine, groups);
    indexCommonPages(engine, { lessonsEnabled });

    return engine;
  }, [lessonsEnabled]);
};
