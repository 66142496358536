import { Class } from '@sparx/api/apis/sparx/misintegration/wondewitch/v1/wondewitch';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import {
  cancelRemovedCurrentClass,
  removeCurrentClass,
  removedAllCurrentClasses,
  removeNewlyAddedClass,
} from '@sparx/mis-sync-import/src/analytics';
import { useMisSyncContext } from '@sparx/mis-sync-import/src/Context';
import { Panel } from '@sparx/mis-sync-import/src/MisSyncImport/components/common';
import {
  SchoolGroupsTable,
  SparxStaffClassMatchOptions,
} from '@sparx/mis-sync-import/src/MisSyncImport/components/SchoolGroupsPanel/SchoolGroupsTable';
import { useSyncConfig } from '@sparx/mis-sync-import/src/MisSyncImport/context/config';
import { SyncConfig } from '@sparx/mis-sync-import/src/MisSyncImport/context/config/types';
import {
  classTypeToSubjectString,
  ConflictingStudent,
  SchoolData,
  SparxClass,
  WondeClass,
  WondeData,
} from '@sparx/mis-sync-import/src/types';
import {
  createGroupNameFrequencyMap,
  getStudentGroupIDFromGroupName,
  getSystemOptions,
  wondeIDOfGroup,
} from '@sparx/mis-sync-import/src/utils';
import { isBefore, subDays } from 'date-fns';
import { useMemo, useState } from 'react';

import styles from './SchoolGroupsPanel.module.css';
import { SchoolGroupsRow, SchoolGroupsTableData } from './types';

/**
 * Sort classes by display name, treating numbers as numbers rather than alphabetically
 * @param a
 * @param b
 */
const compareClasses = (a: Group, b: Group) => {
  return a.displayName.localeCompare(b.displayName, undefined, { numeric: true });
};

export const SchoolGroupsPanel = ({
  schoolData,
  misData,
  sparxGroupsRemovedFromWonde,
  conflictingStudents,
  conflictResolutions,
  setConflictsBeingResolved,
  initialDuplicateClassNames,
  currentClasses,
  removedGroupsArray,
}: {
  schoolData: SchoolData;
  misData: WondeData;
  sparxGroupsRemovedFromWonde: Group[];
  conflictingStudents: ConflictingStudent[];
  conflictResolutions: Record<string, string | undefined>;
  setConflictsBeingResolved: (data: ConflictingStudent[] | undefined) => void;
  initialDuplicateClassNames: Record<string, string>;
  currentClasses: SparxClass[];
  removedGroupsArray: SparxClass[];
}) => {
  const { groupSubject, sendEvent, sparxStaffFeaturesEnabled } = useMisSyncContext();
  const { system, introImage1, introImage2 } = getSystemOptions(groupSubject);
  const [searchQuery, setSearchQuery] = useState('');

  const { syncConfig, dispatch } = useSyncConfig();

  // A list of Sparx classes that can be matched to the Wonde classes if the Sparx Staff feature is enabled
  const sparxStaffClassMatchOptions = useSparxStaffClassMatchOptions(
    sparxStaffFeaturesEnabled,
    syncConfig,
  );

  const searchTerms = searchQuery.toLowerCase().split(' ');

  // Create a Record of Wonde Classes
  const wondeClassIdToClass = useMemo(
    () =>
      misData.wondeClasses.reduce<Record<string, Class>>((acc, c) => ({ ...acc, [c.id]: c }), {}),
    [misData.wondeClasses],
  );

  // Lookup of Sparx group studentGroupID to number of students in that group
  const studentGroupToNumberOfStudentsLookup = Object.values(schoolData.students).reduce<
    Record<string, number>
  >((acc, student) => {
    for (const groupID of student.studentGroupIds) {
      acc[groupID] = (acc[groupID] || 0) + 1;
    }
    return acc;
  }, {});

  // Lookup function to get the Wonde Class from a Sparx Class
  const getWondeClassFromSparxClass = (sparxClass: SparxClass) => {
    const wondeGroupID = wondeIDOfGroup(sparxClass);
    return wondeGroupID ? wondeClassIdToClass[wondeGroupID] : undefined;
  };

  // Get Wonde details for a Sparx class. Use this to show the Wonde class details of a Sparx class.
  const getWondeClassDetailsFromSparxClass = (sparxClass: SparxClass) => {
    const wondeClass = getWondeClassFromSparxClass(sparxClass);
    let sparxStudentsCount = 0;
    const studentGroupID = getStudentGroupIDFromGroupName(sparxClass.name);
    if (studentGroupID) {
      sparxStudentsCount = studentGroupToNumberOfStudentsLookup[studentGroupID] ?? 0;
    }
    // If we can't find a Wonde class then just show the Sparx class details
    if (!wondeClass) {
      return {
        displayName: sparxClass.displayName,
        subject: classTypeToSubjectString[sparxClass.type],
        wondeStudentsCount: 0,
        sparxStudentsCount,
      };
    }
    return {
      displayName: wondeClass.name,
      subject: misData.wondeSubjects[wondeClass.subjectId]?.name || 'Unknown',
      wondeStudentsCount: wondeClass.students.length,
      sparxStudentsCount,
    };
  };

  // If the user has entered a search query then filter the classes by it. All parts of the search query must
  // match the class display name.
  const filteredCurrentClasses = currentClasses.filter(
    c =>
      !(searchTerms.length > 0 && !searchTerms.every(t => c.displayName.toLowerCase().includes(t))),
  );

  filteredCurrentClasses.sort(compareClasses);
  removedGroupsArray.sort(compareClasses);

  const newClassesArray = Array.from(syncConfig.classesToAdd)
    .filter(
      g => searchTerms.length === 0 || searchTerms.every(t => g.name.toLowerCase().includes(t)),
    )
    .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

  // Work out which wonde classes have resolved and unresolved conflicts:
  const {
    classesWithConflicts,
    classesWithUnresolvedConflicts,
    newClassesWithUnresolvedConflicts,
    currentClassesWithUnresolvedConflicts,
    newClassesUnresolvedConflictsCount,
    currentClassesUnresolvedConflictsCount,
  } = useClassConflicts(conflictingStudents, conflictResolutions, newClassesArray, currentClasses);

  const classNameCountMap = createGroupNameFrequencyMap(currentClasses, newClassesArray, []);

  // Build the table data
  const rows: SchoolGroupsTableData[] = [
    // Add the new classes
    newClassesArray.length
      ? {
          count: newClassesArray.length,
          text: 'Classes to be added',
          unresolvedConflictsCount: newClassesUnresolvedConflictsCount,
          onResolveConflicts: () =>
            setConflictsBeingResolved(
              conflictingStudents.filter(c =>
                c.classWondeIDs.some(id => newClassesWithUnresolvedConflicts.has(id)),
              ),
            ),
        }
      : null,
    ...newClassesArray.map<SchoolGroupsRow>(c => {
      // Find a matching expired class with the same Wonde ID, but only if it hasn't been matched to a different Sparx
      // class
      const expiredClassWithSameWondeID = syncConfig.existingExpiredClasses[c.id];
      let matchingExpiredClass;
      if (expiredClassWithSameWondeID) {
        const classMatchedToExpiredClass =
          sparxStaffClassMatchOptions?.sparxClassNameToWondeID[expiredClassWithSameWondeID.name];
        if (!classMatchedToExpiredClass || classMatchedToExpiredClass === c.id) {
          matchingExpiredClass = expiredClassWithSameWondeID;
        }
      }

      // Get the number of students in the Sparx class if it has been matched to a Wonde class
      const matchedSparxClass = syncConfig.classMatches[c.id];
      let sparxStudentsCount = 0;
      if (matchedSparxClass && sparxStaffFeaturesEnabled) {
        const studentGroupID = getStudentGroupIDFromGroupName(matchedSparxClass.name);
        sparxStudentsCount = studentGroupID
          ? studentGroupToNumberOfStudentsLookup[studentGroupID] || 0
          : 0;
      }

      return {
        class: c.name,
        classIdentifier: c.id,
        yearGroupID: c.configuredYearGroupId,
        // TODO Ben to decide on whether we want this to be the default subject for the product or
        // even remove the column in the SchoolGroupsTable completely
        subject: misData.wondeSubjects[c.subjectId]?.name || 'Unknown',
        wondeStudentsCount: c.students.length,
        sparxStudentsCount,
        removeButtonText: 'Cancel',
        removeCallback: () => {
          dispatch({ type: 'remove_added_class', classId: c.id });
          sendEvent(removeNewlyAddedClass(c));
        },
        type: 'new',
        conflict: classesWithUnresolvedConflicts.has(c.id)
          ? 'unresolved'
          : classesWithConflicts.has(c.id)
            ? 'resolved'
            : undefined,
        onResolveConflicts: () =>
          setConflictsBeingResolved(
            conflictingStudents.filter(s => s.classWondeIDs.includes(c.id)),
          ),
        matchedSparxClass,
        currentWondeID: c.id,
        matchingExpiredClass,
        hasNonUniqueName: classNameCountMap[c.name] > 1,
      };
    }),
    // Add the removed classes
    removedGroupsArray.length + sparxGroupsRemovedFromWonde.length
      ? {
          count: removedGroupsArray.length + sparxGroupsRemovedFromWonde.length,
          text: 'Classes to be removed',
          unresolvedConflictsCount: 0,
        }
      : null,
    // Add classes that are no longer in Wonde
    ...sparxGroupsRemovedFromWonde.map<SchoolGroupsRow>(g => ({
      class: g.displayName,
      classIdentifier: g.name,
      yearGroupID: g.yearGroupId,
      type: 'removed',
      subType: 'removedByMis',
    })),
    // Then add classes that have been removed by the user
    ...removedGroupsArray.map<SchoolGroupsRow>(g => {
      const { displayName, subject, wondeStudentsCount, sparxStudentsCount } =
        getWondeClassDetailsFromSparxClass(g);
      const wondeID = wondeIDOfGroup(g);
      return {
        class: displayName,
        classIdentifier: g.name,
        yearGroupID: g.yearGroupId,
        subject,
        wondeStudentsCount,
        sparxStudentsCount,
        removeButtonText: 'Cancel',
        removeCallback: () => {
          dispatch({ type: 'restore_removed_class', className: g.name });
          sendEvent(cancelRemovedCurrentClass(g));
        },
        type: 'removed',
        matchedSparxClass: wondeID ? syncConfig.classMatches[wondeID] : undefined,
      };
    }),
    // Finally add the current classes
    currentClasses.length > 0 && {
      count: currentClasses.length,
      text: 'Current classes',
      unresolvedConflictsCount: currentClassesUnresolvedConflictsCount,
      onResolveConflicts: () =>
        setConflictsBeingResolved(
          conflictingStudents.filter(c =>
            c.classWondeIDs.some(id => currentClassesWithUnresolvedConflicts.has(id)),
          ),
        ),
    },
    ...filteredCurrentClasses.map<SchoolGroupsRow>(g => {
      const wondeID = wondeIDOfGroup(g);
      const conflict = wondeID
        ? classesWithUnresolvedConflicts.has(wondeID)
          ? 'unresolved'
          : classesWithConflicts.has(wondeID)
            ? 'resolved'
            : undefined
        : undefined;
      const { displayName, subject, wondeStudentsCount, sparxStudentsCount } =
        getWondeClassDetailsFromSparxClass(g);
      return {
        class: displayName,
        classIdentifier: g.name,
        yearGroupID: g.configuredYearGroupId,
        subject,
        wondeStudentsCount,
        sparxStudentsCount,
        removeButtonText: 'Remove',
        removeCallback: () => {
          dispatch({ type: 'remove_class', className: g.name });
          sendEvent(removeCurrentClass(g));
          if (currentClasses.length === 1) {
            sendEvent(removedAllCurrentClasses());
          }
        },
        type: 'current',
        conflict,
        onResolveConflicts: () =>
          setConflictsBeingResolved(
            conflictingStudents.filter(s => wondeID && s.classWondeIDs.includes(wondeID)),
          ),
        matchedSparxClass: wondeID ? syncConfig.classMatches[wondeID] : undefined,
        currentWondeID: wondeID,
        hasNonUniqueName:
          classNameCountMap[g.displayName] > 1 ||
          (wondeID ? !!initialDuplicateClassNames[wondeID] : false),
      };
    }),
  ].filter(r => !!r);

  return (
    <>
      <Panel
        systemPanel
        header={system}
        searchPlaceholder={`Search classes in ${system}`}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        groupSubject={groupSubject}
        className={sparxStaffFeaturesEnabled ? styles.PanelSparxStaffFeatures : undefined}
      >
        {rows.length > 1 ? (
          <SchoolGroupsTable
            rows={rows}
            yearGroups={schoolData.yearGroups}
            sparxStaffFeaturesEnabled={sparxStaffFeaturesEnabled}
            sparxStaffClassMatchOptions={sparxStaffClassMatchOptions}
          />
        ) : searchQuery.trim() ? (
          <div className={styles.NoClasses}>
            <p>No classes found for &quot;{searchQuery}&quot;</p>
          </div>
        ) : (
          <div className={styles.NoClasses}>
            <b>There aren&apos;t any classes in {system} yet</b>
            <p>To start importing...</p>
            {introImage1 && <img alt="" src={introImage1} />}
            <p style={{ marginTop: 0 }}>
              Select classes from your MIS in the left hand panel and click the arrow button
            </p>
            {introImage2 && <img alt="" src={introImage2} />}
            <p style={{ margin: 0 }}>
              Click &quot;Preview changes&quot; to confirm your selection and start the import
            </p>
          </div>
        )}
      </Panel>
    </>
  );
};

/**
 * Hook to determine which classes have conflicts and which have unresolved conflicts
 * @param conflictingStudents
 * @param conflictResolutions
 * @param newClasses
 * @param currentClasses
 */
const useClassConflicts = (
  conflictingStudents: ConflictingStudent[],
  conflictResolutions: Record<string, string | undefined>,
  newClasses: WondeClass[],
  currentClasses: SparxClass[],
) => {
  return useMemo(() => {
    const newClassWondeIDs = new Set<string>(newClasses.map(c => c.id));
    const currentClassWondeIds = new Set<string>(currentClasses.map(c => wondeIDOfGroup(c) || ''));
    const newClassesWithUnresolvedConflicts = new Set<string>();
    const currentClassesWithUnresolvedConflicts = new Set<string>();
    const classesWithConflicts = new Set<string>();
    const classesWithUnresolvedConflicts = new Set<string>();
    let newClassesUnresolvedConflictsCount = 0;
    let currentClassesUnresolvedConflictsCount = 0;
    for (const conflict of conflictingStudents) {
      const foundConflicts = { newClasses: false, currentClasses: false };
      for (const classWondeID of conflict.classWondeIDs) {
        classesWithConflicts.add(classWondeID);
        if (!conflict.defaultResolution && !conflictResolutions[conflict.studentWondeID]) {
          classesWithUnresolvedConflicts.add(classWondeID);
          if (newClassWondeIDs.has(classWondeID)) {
            newClassesWithUnresolvedConflicts.add(classWondeID);
            foundConflicts.newClasses = true;
          }
          if (currentClassWondeIds.has(classWondeID)) {
            currentClassesWithUnresolvedConflicts.add(classWondeID);
            foundConflicts.currentClasses = true;
          }
        }
      }
      if (foundConflicts.newClasses) {
        newClassesUnresolvedConflictsCount++;
      }
      if (foundConflicts.currentClasses) {
        currentClassesUnresolvedConflictsCount++;
      }
    }
    return {
      classesWithConflicts,
      classesWithUnresolvedConflicts,
      newClassesWithUnresolvedConflicts,
      currentClassesWithUnresolvedConflicts,
      newClassesUnresolvedConflictsCount,
      currentClassesUnresolvedConflictsCount,
    };
  }, [newClasses, currentClasses, conflictingStudents, conflictResolutions]);
};

/**
 * Get the options for the Sparx class dropdown menu which appears only when Sparx Staff features are enabled
 * This includes a list of all current Sparx classes that are not yet matched to a Wonde class, and a list
 * of recently expired Sparx classes (expired within the last 30 days) that are not yet matched to a Wonde class.
 * @param sparxStaffFeaturesEnabled
 * @param syncConfig
 */
const useSparxStaffClassMatchOptions = (
  sparxStaffFeaturesEnabled: boolean,
  syncConfig: SyncConfig,
): SparxStaffClassMatchOptions | undefined => {
  return useMemo(() => {
    if (!sparxStaffFeaturesEnabled) {
      return undefined;
    }

    const existingClassOptions: Group[] = [];
    const recentlyExpiredClassOptions: Group[] = [];

    const now = new Date();
    const thirtyDaysAgo = subDays(now, 30);

    const matchedClasses = new Set<string>();
    for (const [_, sparxGroup] of Object.entries(syncConfig.classMatches)) {
      matchedClasses.add(sparxGroup.name);
    }

    const removed = new Set<string>();

    for (const removedClass of syncConfig.sparxGroupsRemovedFromWonde) {
      if (!matchedClasses.has(removedClass.name)) {
        removed.add(removedClass.name);
        existingClassOptions.push(removedClass);
      }
    }

    for (const existingClass of syncConfig.existingClasses) {
      if (!matchedClasses.has(existingClass.name) && !removed.has(existingClass.name)) {
        removed.add(existingClass.name);
        existingClassOptions.push(existingClass);
      }
    }

    for (const [_, expiredClass] of Object.entries(syncConfig.existingExpiredClasses)) {
      if (!matchedClasses.has(expiredClass.name) && expiredClass.endDate) {
        if (!isBefore(Timestamp.toDate(expiredClass.endDate), thirtyDaysAgo)) {
          recentlyExpiredClassOptions.push(expiredClass);
        }
      }
    }

    // Sort by display name
    existingClassOptions.sort(compareClasses);
    recentlyExpiredClassOptions.sort(compareClasses);

    // Make a reverse lookup of sparxClass name to WondeID
    const sparxClassNameToWondeID: Record<string, string> = {};
    for (const [wondeID, sparxClass] of Object.entries(syncConfig.classMatches)) {
      sparxClassNameToWondeID[sparxClass.name] = wondeID;
    }

    return { existingClassOptions, recentlyExpiredClassOptions, sparxClassNameToWondeID };
  }, [syncConfig, sparxStaffFeaturesEnabled]);
};
