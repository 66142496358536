import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { getCompletionSummary, HandinStatus, useHandInStatus } from '@sparx/packageactivity';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useIndependentLearningContext } from '../IndependentLearning';
import { ILChevron } from './Misc';

export const ContinuePractice = () => {
  const { previousPackages } = useIndependentLearningContext();
  const navigate = useNavigate();

  const firstIncomplete = useMemo(
    () =>
      previousPackages.find(
        pkg =>
          getCompletionSummary(pkg.state?.completion, undefined).status !== HandinStatus.Complete,
      ),
    [previousPackages],
  );

  const { percentages } = useHandInStatus(firstIncomplete?.state?.completion, undefined);

  if (!firstIncomplete) {
    return null;
  }

  const percentageCorrect = percentages.C.roundedPercentage;

  return (
    <Box
      bg="white"
      boxShadow="elevationLow"
      borderRadius="lg"
      flex={1}
      ml={{ md: 4 }}
      mt={{ base: 4, md: 0 }}
      p={{ base: 6, md: 8 }}
      flexDirection="column"
      display="flex"
    >
      <Heading fontSize="xl" mb={3}>
        Continue previous task
      </Heading>
      <Text mb={2}>
        Do you want to continue your previous <strong>{firstIncomplete.title}</strong> task?
      </Text>
      <Flex grow={1} />
      <Flex alignItems="center" mt={2}>
        <Button
          onClick={() => {
            navigate(`/${firstIncomplete.name}`);
          }}
          colorScheme="buttonTeal"
          variant="outline"
          rightIcon={<ILChevron />}
          maxWidth={200}
        >
          Continue
          {percentageCorrect > 0 && (
            <Text fontWeight="bold" color="green" ml={2}>
              {percentageCorrect}%
            </Text>
          )}
        </Button>
      </Flex>
    </Box>
  );
};
