import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FlexProps,
  Text,
  Tooltip,
  useAccordionItemState,
  VStack,
} from '@chakra-ui/react';
import { faCheck, faChevronRight, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TopicWithStrand, useCurriculumName } from 'api/content';
import { Week } from 'api/school';
import { HigherOnlyTopicTag, SeparateOnlyTopicTag } from 'components/Tag';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { isTopicHigherOnly, isTopicSeparateOnly, preprocessTopicDescription } from 'utils/content';
import { QuestionBrowserModal } from 'views/planner/components/QuestionBrowser';
import { usePlannerContext } from 'views/planner/Context';

interface TopicPanelProps {
  topic: TopicWithStrand;
  inHomework?: boolean;
  onAdd: () => void;
  onRemove: () => void;
  canRemove?: boolean;
  generated?: boolean;
  week: Week;
}

export const TopicPanel = ({
  topic,
  inHomework,
  onRemove,
  onAdd,
  canRemove,
  generated,
  week,
}: TopicPanelProps) => {
  const context = usePlannerContext();

  const addControls = (
    <VStack mx={4}>
      {inHomework ? (
        canRemove ? (
          <Button
            colorScheme="gray"
            onClick={capture(onRemove)}
            leftIcon={<FontAwesomeIcon icon={faMinus} />}
          >
            Remove from week
          </Button>
        ) : (
          <Tooltip label="This topic is scheduled to go out this week" hasArrow placement="top">
            <Button
              colorScheme="buttonTeal"
              leftIcon={<FontAwesomeIcon icon={faCheck} />}
              isDisabled={true}
            >
              Already scheduled
            </Button>
          </Tooltip>
        )
      ) : (
        <Button
          colorScheme="buttonTeal"
          onClick={capture(onAdd)}
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
        >
          Add to week
        </Button>
      )}
    </VStack>
  );

  const topicWeek = context.topicWeeks[topic.topic?.name || ''];
  const deliveredIn = topicWeek?.delivered?.filter(w => w !== week.index) || [];
  const plannedIn = topicWeek?.planned?.filter(w => w !== week.index) || [];
  const setThisWeek =
    topicWeek?.planned?.includes(week.index) || topicWeek?.delivered?.includes(week.index);

  return (
    <AccordionItem
      backgroundColor="gray.50"
      borderLeft="1px"
      borderRight="1px"
      borderBottom="1px"
      borderLeftColor="gray.200"
      borderRightColor="gray.200"
      borderBottomColor="gray.200"
      marginBottom="-1px"
    >
      <AccordionButton as="div" cursor="pointer">
        <TopicDisplayDetails topic={topic} />
        <Box flex="1" />
        <Flex
          direction="column"
          fontSize="sm"
          fontStyle="italic"
          color="gray.500"
          mr={4}
          textAlign="right"
        >
          {deliveredIn.length > 0 && <Text>Previously in {weekText(deliveredIn)}</Text>}
          {(plannedIn.length > 0 || setThisWeek) && (
            <Text>
              {!canRemove && setThisWeek && <>Set this week</>}
              {plannedIn.length > 0 && (
                <>
                  {!canRemove && setThisWeek ? ', ' : ''}Scheduled for {weekText(plannedIn)}
                </>
              )}
            </Text>
          )}
        </Flex>
        {!generated && addControls}
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel p={4}>
        <LazyAccordionPanel>
          <TopicPanelContent topic={topic} />
        </LazyAccordionPanel>{' '}
      </AccordionPanel>
    </AccordionItem>
  );
};

export const TopicDisplayDetails = ({
  topic,
  ...props
}: FlexProps & { topic: TopicWithStrand }) => {
  const curriculum = useCurriculumName(topic.topic?.name);
  return (
    <Flex direction="column" alignItems="flex-start" textAlign="left" {...props}>
      <Flex alignItems="center">
        <Text color={topic.topic?.deleted ? 'gray.600' : 'black'} fontWeight="500">
          {topic.topic?.displayName}
          <Text as="span" color="gray.400">
            {' '}
            - {topic?.topic?.code}
          </Text>
        </Text>
        {isTopicHigherOnly(topic.topic) && <HigherOnlyTopicTag ml={2} />}
        {isTopicSeparateOnly(topic.topic) && <SeparateOnlyTopicTag ml={2} />}
      </Flex>
      <Text fontSize="xs" color="gray.500">
        {curriculum}
        {topic.strand && (
          <>
            <Chevron />
            {topic.strand}
          </>
        )}
        {topic.substrand && (
          <>
            <Chevron />
            {topic.substrand}
          </>
        )}
      </Text>
    </Flex>
  );
};

const LazyAccordionPanel = ({ children }: PropsWithChildren) => {
  const { isOpen } = useAccordionItemState();
  const [hasBeenOpen, setHasBeenOpen] = useState(false);
  useEffect(() => {
    if (!hasBeenOpen && isOpen) setHasBeenOpen(true);
  }, [isOpen, hasBeenOpen, setHasBeenOpen]);
  if (!isOpen && !hasBeenOpen) return <></>;
  return <>{children}</>;
};

interface TopicPanelContentProps {
  topic: TopicWithStrand;
}

const TopicPanelContent = ({ topic }: TopicPanelContentProps) => (
  <Flex direction="column" alignItems="flex-start">
    <Text whiteSpace="pre-line" mb={4} flex={1}>
      {preprocessTopicDescription(topic.topic.description)}
    </Text>
    {topic.topic && (
      <QuestionBrowserModal topic={topic}>
        {onOpen => (
          <Button onClick={onOpen} colorScheme="buttonTeal" variant="outline">
            View questions
          </Button>
        )}
      </QuestionBrowserModal>
    )}
  </Flex>
);

const weekText = (weeks: number[]) => {
  let output = '';
  const weekText = weeks.sort().map(w => `Week ${w}`);
  if (weeks.length > 1) {
    output = weekText.slice(0, -1).join(', ') + ' and ';
  }
  output += weekText[weekText.length - 1];
  return output;
};

const capture = (handler: () => void) => (e: React.MouseEvent) => {
  e.stopPropagation();
  handler();
};

export const Chevron = React.memo(() => (
  <Text fontSize="2xs" color="gray.400" mx={2} display="inline-block" as="span">
    <FontAwesomeIcon icon={faChevronRight} />
  </Text>
));
