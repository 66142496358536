import { Tab, TabList, Tabs, Text } from '@chakra-ui/react';
import { useSchoolYears } from 'api/school';
import { LargeLoading } from 'components/loading/LargeLoading';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

export const SolHomeView = () => {
  return (
    <SuspenseRoute>
      <SolHomeSuspenseView />
    </SuspenseRoute>
  );
};

interface TabInfo {
  title: string;
  subtitle?: string;
  path: string;
}

const SolHomeSuspenseView = () => {
  const location = useLocation();

  const { data: schoolYears = [] } = useSchoolYears({ suspense: true }, true);

  const tabs = useMemo(() => {
    const tabs: TabInfo[] = [];

    const currentYear = schoolYears.find(sy => sy.current);
    if (currentYear) {
      tabs.push({
        title: 'This Academic Year',
        subtitle: `${format(currentYear.start, 'd MMM y')} - ${format(currentYear.end, 'd MMM y')}`,
        path: '/teacher/sol/thisyear',
      });
    }

    const nextYear = schoolYears.find(sy => sy.next);
    if (nextYear) {
      tabs.push({
        title: 'Next Academic Year',
        subtitle: `${format(nextYear.start, 'd MMM y')} - ${format(nextYear.end, 'd MMM y')}`,
        path: '/teacher/sol/nextyear',
      });
    }

    tabs.push({
      title: ' Templates',
      path: '/teacher/sol/templates',
    });

    tabs.push({
      title: 'Archive',
      path: '/teacher/sol/archive',
    });

    return tabs;
  }, [schoolYears]);

  const selectedTab = tabs.findIndex(tab => tab.path === location.pathname);

  return (
    <PageContainer>
      <PageTitle title="Schemes of Learning" />
      <Tabs index={selectedTab} colorScheme="buttonTeal">
        <TabList>
          {tabs.map(tab => (
            <Tab
              _focus={{ boxShadow: 'none' }}
              key={tab.path}
              flexDirection="column"
              as={Link}
              to={tab.path}
            >
              <Text mb={-0.5}>
                <strong>{tab.title}</strong>
              </Text>
              <Text>{tab.subtitle}</Text>
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <React.Suspense fallback={<LargeLoading />}>
        <Outlet />
      </React.Suspense>
    </PageContainer>
  );
};
