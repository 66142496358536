import { ListArchivedLessonsRequest } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { lessonsClient } from 'api';
import React from 'react';

import { LessonListPage } from './components/LessonListPage';

export const LessonArchiveListView = () => (
  <LessonListPage
    title="Lessons Archive"
    noLessonsMessage="No archived lessons"
    path="/teacher/lessons/archive"
    backButton="/teacher/lessons"
    query={args =>
      lessonsClient.listArchivedLessons(ListArchivedLessonsRequest.create(args)).response
    }
  />
);
