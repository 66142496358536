import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Text,
} from '@chakra-ui/react';
import { Assignment } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useUpdateExistingAssignment } from 'api/planner';
import { Warning } from 'components/warning';
import { format, isPast } from 'date-fns';
import React from 'react';

export const GeneratedAssignmentHandInChangeConfirmationModal = ({
  assignment,
  newDate,
  onClose: _onClose,
}: {
  assignment: Assignment;
  newDate: Date;
  onClose: () => void;
}) => {
  const { mutate, isLoading, isError } = useUpdateExistingAssignment({ onSuccess: _onClose });

  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const onClose = () => !isLoading && _onClose();
  const onUpdate = () =>
    mutate({
      name: assignment.name,
      endTimestamp: Timestamp.fromDate(newDate),
    });

  const currentDate = Timestamp.toDate(assignment.endTimestamp!);

  const currentInPast = isPast(currentDate);

  return (
    <>
      <AlertDialog isOpen={true} leastDestructiveRef={cancelRef} onClose={onClose} size="lg">
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Change homework hand in date
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text mb={2}>This will change the hand in date from</Text>

              <Box backgroundColor="blue.800" px={3} py={2} borderRadius="md" color="white" mb={2}>
                <Text color="white" fontWeight="bold" fontSize="lg" textAlign="center">
                  {format(currentDate, 'EEEE do MMMM')} at {format(currentDate, 'HH:mm')}
                </Text>
                <Text textAlign="center" fontSize="lg" fontWeight="bold">
                  to
                </Text>
                <Text fontWeight="bold" fontSize="lg" textAlign="center">
                  {format(newDate, 'EEEE do MMMM')} at {format(newDate, 'HH:mm')}
                </Text>
              </Box>
              <Text mb={2}>This homework has already been handed out.</Text>
              {currentInPast && <Text mb={2}>This action cannot be undone.</Text>}
              {isError && (
                <Warning status="error">
                  There was an error updating the homework hand-in date. Please refresh and try
                  again.
                </Warning>
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} isDisabled={isLoading}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onUpdate} ml={3} isLoading={isLoading}>
                Update hand-in date
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
