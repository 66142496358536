import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import {
  ResourceMetadata,
  ResourceStatus,
  SchemeOfLearningTemplate,
} from '@sparx/api/apis/sparx/planning/v1/sol';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import { useSchoolGroups } from 'api/school';
import { useUserType } from 'api/sessions';
import { SCHOOL_GROUPS_ALL, useCreateTemplateSol, useSol, useTemplateSols } from 'api/sol';
import { useClientEvent } from 'components/ClientEventProvider';
import { EditableField } from 'components/form/FormFields';
import { Warning } from 'components/warning';
import React, { useEffect, useMemo, useState } from 'react';

import {
  fillWeeks,
  hasTopicsToMigrate,
  migrateWeekTopics,
  removeEmptyWeeks,
  squashIntoFinalWeek,
} from '../solHelpers';
import { MigrationWarning } from '../topic-migration/MigrationWarning';
import { useTopicMigrationMap } from '../topic-migration/query';
import { AnnotatedSchemeOfLearning } from './SolTable';

interface PublishSolModalProps {
  scheme: AnnotatedSchemeOfLearning | undefined;
  onClose: () => void;
  onShare: () => void;
}

export const ShareSolModal = ({ scheme, onClose, onShare }: PublishSolModalProps) => {
  const { sendEvent } = useClientEvent();
  const { isSparxStaff } = useUserType();
  const { data: schoolGroups } = useSchoolGroups({ suspense: true });
  const { mutateAsync: createTemplate, isLoading: isCreatingTemplate } = useCreateTemplateSol();
  const { data: sol, isSuccess: loadedSol } = useSol(
    scheme?.name.split('/')[3] || '',
    scheme?.metadata?.status === ResourceStatus.PUBLISHED ? 'published' : 'draft',
    false,
    {
      suspense: false,
      enabled: !!scheme,
    },
  );
  const { data: topicMigrationMap, isSuccess: migrationMapLoaded } = useTopicMigrationMap();

  const schoolGroupOptions = useMemo(() => {
    const opts =
      schoolGroups?.map(sg => ({
        displayName: sg.displayName,
        name: sg.name,
      })) || [];

    if (isSparxStaff) {
      opts?.push({
        displayName: 'All Sparx schools',
        name: SCHOOL_GROUPS_ALL,
      });
    }
    return opts;
  }, [schoolGroups, isSparxStaff]);

  const [schoolGroup, setSchoolGroup] = useState(schoolGroupOptions?.[0]?.name || '');
  const [syllabus, setSyllabus] = useState('');
  useEffect(() => {
    if (schoolGroupOptions && schoolGroupOptions.length > 0) {
      setSchoolGroup(schoolGroupOptions[0].name);
    }
  }, [schoolGroupOptions]);

  const selectedGroup = schoolGroupOptions?.find(g => g.name === schoolGroup);

  const hasMigratableTopics = hasTopicsToMigrate(
    sol?.schedule?.weeks || [],
    topicMigrationMap || {},
  );

  const doClose = () => {
    // Don't allow the modal to close while we are doing the update
    if (!isCreatingTemplate) {
      onClose();
    }
  };

  const submit = () => {
    if (!scheme || !schoolGroup || !loadedSol) return;
    if (schoolGroup === SCHOOL_GROUPS_ALL && !syllabus) return;

    // Migrate from old topics to new, this can be removed once there are no SOLs with old topics accessible.
    const { newWeeks, didMigrateTopics } = migrateWeekTopics(
      sol.schedule?.weeks || [],
      topicMigrationMap || {},
    );
    let weeks = removeEmptyWeeks(newWeeks);
    weeks = fillWeeks(weeks, 52);
    weeks = squashIntoFinalWeek(weeks, 52);

    const newTemplate: SchemeOfLearningTemplate = {
      name: '',
      displayName: sol.displayName,
      baseCurriculum: sol.baseCurriculum,
      schedule: { weeks },
      metadata: ResourceMetadata.create({
        product: Product.SPARX_SCIENCE,
        tags: ['subject:science'],
      }),
      syllabus: schoolGroup === SCHOOL_GROUPS_ALL ? syllabus : '',
    };

    createTemplate({ parent: schoolGroup, template: newTemplate }).then(tmpl => {
      sendEvent(
        {
          category: 'scheme_of_learning',
          action: 'share_sol',
        },
        {
          templateName: tmpl.name,
          baseSol: sol.name,
          baseSolVersion: sol.metadata?.version.toString() || 'unknown',
          displayName: tmpl.displayName,
          baseCurriculum: tmpl.baseCurriculum,
          migratedTopics: didMigrateTopics ? 'true' : 'false',
        },
      );
      onShare();
    });
  };

  if (!schoolGroupOptions) {
    return null;
  }

  return (
    <Modal isOpen={!!scheme} size="xl" onClose={doClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb={1}>Share Scheme as template</ModalHeader>
        <ModalBody>
          {schoolGroupOptions.length !== 1 && (
            <Box mb={4}>
              <EditableField name="Share with">
                <Select value={schoolGroup} onChange={e => setSchoolGroup(e.target.value)}>
                  {schoolGroupOptions.map(sg => (
                    <option value={sg.name} key={sg.name}>
                      {sg.displayName}
                    </option>
                  ))}
                </Select>
              </EditableField>
            </Box>
          )}
          {schoolGroup === SCHOOL_GROUPS_ALL && (
            <SyllabusSelect syllabus={syllabus} setSyllabus={setSyllabus} />
          )}
          <Text>
            This will create a template from your Scheme of Learning that will then be shared with
            schools in <strong>{selectedGroup?.displayName}</strong>. Those schools will then be
            able to create their own Schemes based on the template.
          </Text>
          {hasMigratableTopics && <MigrationWarning mt={4} />}
          {schoolGroup === SCHOOL_GROUPS_ALL && (
            <Warning status="error" mt={4}>
              You&apos;re about to share a template with <strong>ALL</strong> schools.
            </Warning>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={doClose} mr={2} variant="outline">
            Close
          </Button>
          <Button
            onClick={submit}
            colorScheme="buttonTeal"
            isLoading={!loadedSol || isCreatingTemplate || !migrationMapLoaded}
            isDisabled={schoolGroup === SCHOOL_GROUPS_ALL && !syllabus}
          >
            Share Scheme as template
          </Button>
        </ModalFooter>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

const SyllabusSelect = ({
  syllabus,
  setSyllabus,
}: {
  syllabus: string;
  setSyllabus: (s: string) => void;
}) => {
  const [useNew, { on }] = useBoolean(false);
  const solsByGroup = useTemplateSols([SCHOOL_GROUPS_ALL], {
    suspense: false,
  });
  const { data: allSols = [], isLoading } = solsByGroup[0];

  const options = useMemo(() => {
    const opts = allSols.reduce<string[]>((acc, sol) => {
      if (!acc.includes(sol.syllabus)) {
        acc.push(sol.syllabus);
      }
      return acc;
    }, []);
    if (syllabus && !opts.includes(syllabus)) {
      opts.push(syllabus);
    }
    return opts;
  }, [syllabus, allSols]);

  return (
    <Box mb={4}>
      <EditableField name="Syllabus">
        {isLoading ? (
          <LoadingSpinner />
        ) : useNew ? (
          <Input
            value={syllabus}
            onChange={e => setSyllabus(e.target.value)}
            placeholder="Syllabus display name"
          />
        ) : (
          <Select
            value={syllabus}
            onChange={e => {
              if (e.target.value === 'new') {
                setSyllabus('');
                on();
                return;
              }
              setSyllabus(e.target.value);
            }}
          >
            <option value="" disabled={true}>
              Select a syllabus
            </option>
            <option value="new">-- Add new Syllabus --</option>
            {options.map(opt => (
              <option key={opt} value={opt}>
                {opt}
              </option>
            ))}
          </Select>
        )}
      </EditableField>
    </Box>
  );
};
