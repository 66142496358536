import { Product } from '@sparx/api/apis/sparx/types/product';
import { useMisSyncContext } from '@sparx/mis-sync-import/src/Context';
import {
  hasBeenUpdated,
  misSupportedSubjects,
  Preview,
  StudentPreview,
} from '@sparx/mis-sync-import/src/processing';
import tableStyles from '@sparx/mis-sync-import/src/shared-styles/Tables.module.css';
import { productSubjectFromProductType } from '@sparx/mis-sync-import/src/utils';
import { Chip } from '@sparx/sparx-design/components';
import classNames from 'classnames';
import { Fragment } from 'react';

import styles from './StudentChangesTable.module.css';

export const StudentChangesTable = ({
  students,
  schoolProducts,
}: {
  students: StudentPreview[];
  schoolProducts: Product[];
}) => {
  const { sparxStaffFeaturesEnabled: showIds } = useMisSyncContext();
  /**
   * Get a list of product subjects that the school has that can be synced. We use this to determine
   * which Sparx Class columns to show in the table.
   */
  const schoolProductSubjects = schoolProducts.map(p => productSubjectFromProductType(p));
  const misSubjectsForSchool = misSupportedSubjects.filter(subject =>
    schoolProductSubjects.includes(subject),
  );

  const studentColumns = showIds ? 5 : 4;

  return (
    <table className={tableStyles.Table}>
      <thead className={tableStyles.TableHeader}>
        <tr className={classNames(styles.GroupingRow, styles.Bottom)}>
          <td colSpan={studentColumns} className={tableStyles.TableHeaderCellRightBorder}>
            Student
          </td>
          <td colSpan={misSubjectsForSchool.length}>Sparx Class</td>
          <td colSpan={2} className={styles.TableHeaderCellLeftBorder}>
            Parent/Carer
          </td>
        </tr>
        <tr className={classNames(styles.GroupingRow, styles.Bottom)}>
          {/* Student */}
          {showIds && <td>Wonde ID</td>}
          <td>Name</td>
          <td>Date of birth</td>
          <td>Email address</td>
          <td className={tableStyles.TableHeaderCellRightBorder}>Gender</td>
          {/* Sparx classes */}
          {misSubjectsForSchool.includes('MATHS') && <td>Maths</td>}
          {misSubjectsForSchool.includes('ENGLISH') && <td>Reader</td>}
          {misSubjectsForSchool.includes('SCIENCE') && <td>Science</td>}
          {/* Parent/Carer */}
          <td className={styles.TableHeaderCellLeftBorder}>Name</td>
          <td>Email address</td>
        </tr>
      </thead>
      <tbody>
        {students.map(s => (
          <Fragment key={Array.isArray(s.wondeID) ? s.wondeID[0] : s.wondeID}>
            <tr
              className={classNames(tableStyles.TableBodyRow, {
                [tableStyles.WithoutBorder]: s.contacts.length > 1,
              })}
            >
              {/* Student */}
              {showIds && <TableCell value={s.wondeID} />}
              <TableCell value={s.name} sensitive />
              <TableCell value={s.dateOfBirth} sensitive />
              <TableCell value={s.emailAddress} sensitive />
              <TableCell value={s.gender} className={tableStyles.TableCellRightBorder} sensitive />
              {/* Sparx classes */}
              {misSubjectsForSchool.includes('MATHS') && <TableCell value={s.classes.MATHS} />}
              {misSubjectsForSchool.includes('ENGLISH') && <TableCell value={s.classes.ENGLISH} />}
              {misSubjectsForSchool.includes('SCIENCE') && <TableCell value={s.classes.SCIENCE} />}
              {/* Parent/Carer */}
              <TableCell
                value={s.contacts[0]?.name}
                className={styles.TableCellLeftBorder}
                sensitive
              />
              <TableCell value={s.contacts[0]?.email} sensitive />
            </tr>
            {s.contacts.slice(1).map((p, i) => (
              <tr
                className={classNames(tableStyles.TableBodyRow, {
                  [tableStyles.WithoutBorder]: i + 2 < s.contacts.length,
                })}
                key={i}
              >
                <td colSpan={studentColumns} className={tableStyles.TableCellRightBorder} />
                <td colSpan={misSubjectsForSchool.length} />
                <TableCell value={p.name} className={styles.TableCellLeftBorder} sensitive />
                <TableCell value={p.email} sensitive />
              </tr>
            ))}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

const TableCell = ({
  value,
  className,
  sensitive,
}: {
  value: undefined | Preview;
  className?: string;
  /** When true, masks the field to be masked during hotjar recordings */
  sensitive?: boolean;
}) => {
  // If there's no value, show a dash
  if (!value) {
    return <td className={className}>-</td>;
  }
  // If the value is a string then it hasn't changed
  if (!hasBeenUpdated(value)) {
    return (
      <td className={className} data-hj-suppress={sensitive}>
        {value}
      </td>
    );
  }
  // Check the before and after values for changes
  const [before, after] = value;
  return (
    <td className={className}>
      <div className={styles.Diff}>
        {!!before && (
          <Chip shapeVariant="Boxy" size="sm" className={styles.Removed}>
            -
            <span data-hj-suppress={sensitive} className={styles.TableCellChangeValue}>
              {before}
            </span>
          </Chip>
        )}
        {!!after && (
          <Chip shapeVariant="Boxy" size="sm" className={styles.Added}>
            +
            <span data-hj-suppress={sensitive} className={styles.TableCellChangeValue}>
              {after}
            </span>
          </Chip>
        )}
      </div>
    </td>
  );
};
