// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/packages/v1/activity.proto" (package "sparx.science.packages.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Interval } from '../../../../../google/type/interval';
import { Package } from './package';
import { TaskItem_Status } from './package';
import { Subject } from '../../v1/subject';
import { Question } from '../../../content/v2/skill';
import { SkillSupportMaterial } from '../../../content/v2/skill';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.science.packages.v1.Activity
 */
export interface Activity {
  /**
   * Resource name of the Activity. In the format `activities/-`.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * TaskItem this Activity is for. In the format `packages/-/tasks/-/items/-`.
   *
   * @generated from protobuf field: string task_item_name = 2;
   */
  taskItemName: string;
  /**
   * Additional arbitrary annotations on a Activity.
   *
   * @generated from protobuf field: map<string, string> annotations = 3;
   */
  annotations: {
    [key: string]: string;
  };
  /**
   * @generated from protobuf field: sparx.science.packages.v1.Activity.State state = 4;
   */
  state?: Activity_State;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp start_timestamp = 5;
   */
  startTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp end_timestamp = 6;
   */
  endTimestamp?: Timestamp;
}
/**
 * Previous activity that was completed before this one. Only populated
 * if the previous activity would be displayed next to the the current one.
 *
 * @generated from protobuf message sparx.science.packages.v1.Activity.PreviousActivity
 */
export interface Activity_PreviousActivity {
  /**
   * Resource name of the previous activity.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * State of the previous activity.
   *
   * @generated from protobuf field: sparx.science.packages.v1.Activity.State state = 2;
   */
  state?: Activity_State;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.Activity.State
 */
export interface Activity_State {
  /**
   * Current activity token. Activity action requests should match
   * this token or they may be rejected. The token will be regenerated
   * as a result of every activity action.
   *
   * @generated from protobuf field: string token = 3;
   */
  token: string;
  /**
   * Skill activity information.
   *
   * @generated from protobuf field: sparx.science.packages.v1.SkillActivity skill_activity = 1;
   */
  skillActivity?: SkillActivity;
  /**
   * @generated from protobuf field: sparx.science.packages.v1.Activity.PreviousActivity previous_activity = 2;
   */
  previousActivity?: Activity_PreviousActivity;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.SkillActivity
 */
export interface SkillActivity {
  /**
   * @generated from protobuf field: sparx.science.packages.v1.SkillActivity.State state = 1;
   */
  state: SkillActivity_State;
  /**
   * @generated from protobuf field: sparx.science.packages.v1.SkillActivity.Question question = 2;
   */
  question?: SkillActivity_Question;
  /**
   * @generated from protobuf field: sparx.science.packages.v1.Evaluation evaluation = 3;
   */
  evaluation?: Evaluation;
  /**
   * Evaluation on each step of the question. The index of the evaluation
   * corresponds to the step number.
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.StepEvaluation step_evaluations = 4;
   */
  stepEvaluations: StepEvaluation[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.SkillActivity.Steps
 */
export interface SkillActivity_Steps {
  /**
   * @generated from protobuf field: sparx.science.packages.v1.SkillActivity.Steps.Delivery delivery = 1;
   */
  delivery: SkillActivity_Steps_Delivery;
  /**
   * Current step that the student is on this question. This will
   * always be less than or equal to max_step. The question_json
   * will only contain up to this step. 0-indexed.
   *
   * @generated from protobuf field: int32 current_step = 2;
   */
  currentStep: number;
  /**
   * Max step is the number of steps that this question has. The
   * question_json will only contain up to current_step when
   * returned to the student.
   *
   * @generated from protobuf field: int32 max_step = 3;
   */
  maxStep: number;
  /**
   * Visible step is the current step that the student is viewing.
   * This will always be equal to or less than the current_step
   * and to max_step. 0-indexed.
   *
   * @generated from protobuf field: int32 visible_step = 4;
   */
  visibleStep: number;
  /**
   * Map of step index to the index to the step number to actually display
   * for the step. This handles cases where we want to display multiple steps
   * as the same step.
   *
   * @generated from protobuf field: map<int32, int32> step_display_number_map = 5;
   */
  stepDisplayNumberMap: {
    [key: number]: number;
  };
}
/**
 * @generated from protobuf enum sparx.science.packages.v1.SkillActivity.Steps.Delivery
 */
export enum SkillActivity_Steps_Delivery {
  /**
   * @generated from protobuf enum value: DELIVERY_UNSPECIFIED = 0;
   */
  DELIVERY_UNSPECIFIED = 0,
  /**
   * Stepped means that previous steps should not be visible as context
   * when new steps are issued.
   *
   * @generated from protobuf enum value: STEPPED = 1;
   */
  STEPPED = 1,
  /**
   * Continuous means that previous steps should be visible as context
   * when new steps are issued.
   *
   * @generated from protobuf enum value: CONTINUOUS = 2;
   */
  CONTINUOUS = 2,
}
/**
 * @generated from protobuf message sparx.science.packages.v1.SkillActivity.Question
 */
export interface SkillActivity_Question {
  /**
   * @generated from protobuf field: string question_json = 1;
   */
  questionJson: string;
  /**
   * @generated from protobuf field: sparx.content.v2.SkillSupportMaterial support_material = 2;
   */
  supportMaterial?: SkillSupportMaterial;
  /**
   * Optional video id.
   *
   * @generated from protobuf field: string video_id = 3;
   */
  videoId: string;
  /**
   * Multistep questions will contain this additional configuration.
   *
   * @generated from protobuf field: sparx.science.packages.v1.SkillActivity.Steps steps = 4;
   */
  steps?: SkillActivity_Steps;
}
/**
 * @generated from protobuf enum sparx.science.packages.v1.SkillActivity.State
 */
export enum SkillActivity_State {
  /**
   * @generated from protobuf enum value: STATE_UNSPECIFIED = 0;
   */
  STATE_UNSPECIFIED = 0,
  /**
   * combined view
   *
   * @generated from protobuf enum value: QUESTION_ANSWER = 1;
   */
  QUESTION_ANSWER = 1,
  /**
   * @generated from protobuf enum value: RESULTS = 2;
   */
  RESULTS = 2,
  /**
   * @generated from protobuf enum value: TAL_START = 3;
   */
  TAL_START = 3,
  /**
   * @generated from protobuf enum value: TAL_TIMER = 4;
   */
  TAL_TIMER = 4,
  /**
   * @generated from protobuf enum value: STEP_RESULT = 5;
   */
  STEP_RESULT = 5,
}
/**
 * @generated from protobuf message sparx.science.packages.v1.InternalActivityState
 */
export interface InternalActivityState {
  /**
   * @generated from protobuf field: sparx.content.v2.Question question = 1;
   */
  question?: Question;
  /**
   * @generated from protobuf field: map<string, sparx.science.packages.v1.GapEvaluation> internal_gap_evaluations = 2;
   */
  internalGapEvaluations: {
    [key: string]: GapEvaluation;
  };
  /**
   * @generated from protobuf field: double question_time_seconds = 3;
   */
  questionTimeSeconds: number;
  /**
   * @generated from protobuf field: double support_time_seconds = 4;
   */
  supportTimeSeconds: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp last_view_timestamp = 5;
   */
  lastViewTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: string topic_name = 6;
   */
  topicName: string;
  /**
   * @generated from protobuf field: sparx.science.v1.Subject subject = 7;
   */
  subject: Subject;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.GapEvaluation
 */
export interface GapEvaluation {
  /**
   * Correct is whether the gap was evaluated correctly.
   *
   * @generated from protobuf field: bool correct = 1;
   */
  correct: boolean;
  /**
   * Correction is any correction on the gap.
   *
   * @generated from protobuf field: string correction = 2;
   */
  correction: string;
  /**
   * Additional details on the evaluation.
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.GapEvaluationDetail detail = 3;
   */
  detail: GapEvaluationDetail[];
  /**
   * Additional data that can be used by the client.
   *
   * @generated from protobuf field: map<string, string> additional_data = 4;
   */
  additionalData: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.science.packages.v1.GapEvaluationDetail
 */
export interface GapEvaluationDetail {
  /**
   * Number of available marks for this part.
   *
   * @generated from protobuf field: int32 available_marks = 1;
   */
  availableMarks: number;
  /**
   * Number of marks that the student was awarded out of the available marks.
   *
   * @generated from protobuf field: int32 awarded_marks = 2;
   */
  awardedMarks: number;
  /**
   * Text feedback that will be returned to the student.
   *
   * @generated from protobuf field: string feedback = 3;
   */
  feedback: string;
  /**
   * Text hint that will be returned to the student.
   *
   * @generated from protobuf field: string feedback_hint = 5;
   */
  feedbackHint: string;
  /**
   * Whether there was an error marking this gap.
   * Used by AI questions when there is an error evaluating with openai.
   *
   * @generated from protobuf field: bool error_marking = 4;
   */
  errorMarking: boolean;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.Evaluation
 */
export interface Evaluation {
  /**
   * Status of the evaluation.
   *
   * @generated from protobuf field: sparx.science.packages.v1.TaskItem.Status status = 1;
   */
  status: TaskItem_Status;
  /**
   * Whether this evaluation is complete.
   *
   * @generated from protobuf field: bool completed = 2;
   */
  completed: boolean;
  /**
   * Answer that the user submitted.
   *
   * @generated from protobuf field: map<string, string> submitted_answer = 3;
   */
  submittedAnswer: {
    [key: string]: string;
  };
  /**
   * Evaluation on each part of the answer submitted.
   *
   * @generated from protobuf field: map<string, sparx.science.packages.v1.GapEvaluation> gap_evaluations = 4;
   */
  gapEvaluations: {
    [key: string]: GapEvaluation;
  };
  /**
   * Timestamp the evaluation was created.
   *
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 5;
   */
  timestamp?: Timestamp;
  /**
   * Marks awarded for the evaluation.
   *
   * @generated from protobuf field: int32 marks = 6;
   */
  marks: number;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.StepEvaluation
 */
export interface StepEvaluation {
  /**
   * List of evaluations on this step. There can be multiple in the list
   * if there were multiple attempts at the step. The first evaluation
   * in the list is the most recent.
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.Evaluation evaluations = 1;
   */
  evaluations: Evaluation[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.GetActivityRequest
 */
export interface GetActivityRequest {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * @generated from protobuf field: string task_item_name = 2;
   */
  taskItemName: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.GetActivityResponse
 */
export interface GetActivityResponse {
  /**
   * @generated from protobuf field: sparx.science.packages.v1.Activity activity = 1;
   */
  activity?: Activity;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ActivityActionRequest
 */
export interface ActivityActionRequest {
  /**
   * Name of the activity to perform the action on.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Current token on the activity. If provided, this token should match
   * the value on the activity state. If the token does not match, the
   * action will be rejected. If there is no token the request will be
   * allowed and the token will not be regenerated. This should only be
   * used for asynchronous actions that don't impact the state.
   *
   * @generated from protobuf field: string token = 10;
   */
  token: string;
  /**
   * @generated from protobuf oneof: action
   */
  action:
    | {
        oneofKind: 'answer';
        /**
         * @generated from protobuf field: sparx.science.packages.v1.ActivityActionRequest.Answer answer = 2;
         */
        answer: ActivityActionRequest_Answer;
      }
    | {
        oneofKind: 'view';
        /**
         * @generated from protobuf field: sparx.science.packages.v1.ActivityActionRequest.View view = 3;
         */
        view: ActivityActionRequest_View;
      }
    | {
        oneofKind: 'next';
        /**
         * @generated from protobuf field: sparx.science.packages.v1.ActivityActionRequest.Next next = 4;
         */
        next: ActivityActionRequest_Next;
      }
    | {
        oneofKind: 'retry';
        /**
         * @generated from protobuf field: sparx.science.packages.v1.ActivityActionRequest.Retry retry = 5;
         */
        retry: ActivityActionRequest_Retry;
      }
    | {
        oneofKind: 'skip';
        /**
         * @generated from protobuf field: sparx.science.packages.v1.ActivityActionRequest.Skip skip = 6;
         */
        skip: ActivityActionRequest_Skip;
      }
    | {
        oneofKind: 'report';
        /**
         * @generated from protobuf field: sparx.science.packages.v1.ActivityActionRequest.Report report = 7;
         */
        report: ActivityActionRequest_Report;
      }
    | {
        oneofKind: 'help';
        /**
         * @generated from protobuf field: sparx.science.packages.v1.ActivityActionRequest.Help help = 8;
         */
        help: ActivityActionRequest_Help;
      }
    | {
        oneofKind: 'navigateStep';
        /**
         * @generated from protobuf field: sparx.science.packages.v1.ActivityActionRequest.NavigateStep navigate_step = 9;
         */
        navigateStep: ActivityActionRequest_NavigateStep;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ActivityActionRequest.Answer
 */
export interface ActivityActionRequest_Answer {
  /**
   * @generated from protobuf field: map<string, string> components = 1;
   */
  components: {
    [key: string]: string;
  };
  /**
   * For multistep questions, when this value is set the activity will
   * progress to any next available step if the answer is correct.
   *
   * @generated from protobuf field: bool auto_progress_step = 2;
   */
  autoProgressStep: boolean;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ActivityActionRequest.View
 */
export interface ActivityActionRequest_View {
  /**
   * @generated from protobuf field: bool unload = 1;
   */
  unload: boolean;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ActivityActionRequest.Next
 */
export interface ActivityActionRequest_Next {}
/**
 * @generated from protobuf message sparx.science.packages.v1.ActivityActionRequest.Retry
 */
export interface ActivityActionRequest_Retry {}
/**
 * @generated from protobuf message sparx.science.packages.v1.ActivityActionRequest.Skip
 */
export interface ActivityActionRequest_Skip {}
/**
 * @generated from protobuf message sparx.science.packages.v1.ActivityActionRequest.Report
 */
export interface ActivityActionRequest_Report {}
/**
 * @generated from protobuf message sparx.science.packages.v1.ActivityActionRequest.Help
 */
export interface ActivityActionRequest_Help {
  /**
   * @generated from protobuf field: bool viewing = 1;
   */
  viewing: boolean;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ActivityActionRequest.NavigateStep
 */
export interface ActivityActionRequest_NavigateStep {
  /**
   * New step that the user wishes to navigate to. This should
   * always be 1 around the visible step, and not more than the
   * current step or less than 0.
   *
   * @generated from protobuf field: int32 step = 1;
   */
  step: number;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ActivityActionResponse
 */
export interface ActivityActionResponse {
  /**
   * @generated from protobuf field: sparx.science.packages.v1.Activity activity = 1;
   */
  activity?: Activity;
  /**
   * @generated from protobuf field: sparx.science.packages.v1.Package package_update = 2;
   */
  packageUpdate?: Package;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.GetPackageAnswerHistoryRequest
 */
export interface GetPackageAnswerHistoryRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * @generated from protobuf field: string student_name = 2;
   */
  studentName: string;
  /**
   * @generated from protobuf field: string package_name = 3;
   */
  packageName: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.GetPackageAnswerHistoryResponse
 */
export interface GetPackageAnswerHistoryResponse {
  /**
   * @generated from protobuf field: sparx.science.packages.v1.Package package = 1;
   */
  package?: Package;
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.GetPackageAnswerHistoryResponse.ActivityWithAnswer activities = 2;
   */
  activities: GetPackageAnswerHistoryResponse_ActivityWithAnswer[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.GetPackageAnswerHistoryResponse.ActivityWithAnswer
 */
export interface GetPackageAnswerHistoryResponse_ActivityWithAnswer {
  /**
   * @generated from protobuf field: sparx.science.packages.v1.Activity activity = 1;
   */
  activity?: Activity;
  /**
   * @generated from protobuf field: map<string, string> correct_answer = 2;
   */
  correctAnswer: {
    [key: string]: string;
  };
  /**
   * @generated from protobuf field: map<string, sparx.science.packages.v1.GapEvaluation> correct_answer_gap_evaluations = 3;
   */
  correctAnswerGapEvaluations: {
    [key: string]: GapEvaluation;
  };
  /**
   * @generated from protobuf field: double question_time_seconds = 4;
   */
  questionTimeSeconds: number;
  /**
   * @generated from protobuf field: double support_time_seconds = 5;
   */
  supportTimeSeconds: number;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.GetTetheringActivityRequest
 */
export interface GetTetheringActivityRequest {
  /**
   * @generated from protobuf field: string session_name = 1;
   */
  sessionName: string;
  /**
   * @generated from protobuf field: string last_tether_id = 2;
   */
  lastTetherId: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.GetTetheringActivityResponse
 */
export interface GetTetheringActivityResponse {
  /**
   * @generated from protobuf field: sparx.science.packages.v1.Activity activity = 1;
   */
  activity?: Activity;
  /**
   * @generated from protobuf field: string tether_id = 2;
   */
  tetherId: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListActivitiesRequest
 */
export interface ListActivitiesRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Parent of the activity to list. Possible formats:
   * - packages/{package_id}/tasks/{task_id}/items/{index}
   * - assignments/{assignment_id}/tasks/{task_index}/items/{task_item_index}
   *
   * @generated from protobuf field: string parent = 2;
   */
  parent: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListActivitiesResponse
 */
export interface ListActivitiesResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.ListActivitiesResponse.StudentActivity activities = 1;
   */
  activities: ListActivitiesResponse_StudentActivity[];
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.ListActivitiesResponse.QuestionAnswer correct_answers = 2;
   */
  correctAnswers: ListActivitiesResponse_QuestionAnswer[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListActivitiesResponse.StudentActivity
 */
export interface ListActivitiesResponse_StudentActivity {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: string question_name = 2;
   */
  questionName: string;
  /**
   * @generated from protobuf field: sparx.science.packages.v1.Activity activity = 3;
   */
  activity?: Activity;
  /**
   * @generated from protobuf field: double question_time_seconds = 4;
   */
  questionTimeSeconds: number;
  /**
   * @generated from protobuf field: double support_time_seconds = 5;
   */
  supportTimeSeconds: number;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListActivitiesResponse.QuestionAnswer
 */
export interface ListActivitiesResponse_QuestionAnswer {
  /**
   * @generated from protobuf field: string question_name = 1;
   */
  questionName: string;
  /**
   * @generated from protobuf field: map<string, string> correct_answer = 2;
   */
  correctAnswer: {
    [key: string]: string;
  };
  /**
   * @generated from protobuf field: map<string, sparx.science.packages.v1.GapEvaluation> correct_answer_gap_evaluations = 3;
   */
  correctAnswerGapEvaluations: {
    [key: string]: GapEvaluation;
  };
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListUserTaskItemActivitiesRequest
 */
export interface ListUserTaskItemActivitiesRequest {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListUserTaskItemActivitiesResponse
 */
export interface ListUserTaskItemActivitiesResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.ListUserTaskItemActivitiesResponse.StudentActivity activities = 1;
   */
  activities: ListUserTaskItemActivitiesResponse_StudentActivity[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListUserTaskItemActivitiesResponse.StudentActivity
 */
export interface ListUserTaskItemActivitiesResponse_StudentActivity {
  /**
   * @generated from protobuf field: sparx.science.packages.v1.Activity activity = 1;
   */
  activity?: Activity;
  /**
   * @generated from protobuf field: double question_time_seconds = 2;
   */
  questionTimeSeconds: number;
  /**
   * @generated from protobuf field: double support_time_seconds = 3;
   */
  supportTimeSeconds: number;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.SetActivityEvaluationRequest
 */
export interface SetActivityEvaluationRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * @generated from protobuf field: string student_name = 2;
   */
  studentName: string;
  /**
   * @generated from protobuf field: string activity_name = 3;
   */
  activityName: string;
  /**
   * @generated from protobuf field: sparx.science.packages.v1.TaskItem.Status status = 4;
   */
  status: TaskItem_Status;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.SetActivityEvaluationResponse
 */
export interface SetActivityEvaluationResponse {}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListActivityFeedRequest
 */
export interface ListActivityFeedRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * @generated from protobuf field: string student_name = 2;
   */
  studentName: string;
  /**
   * @generated from protobuf field: google.type.Interval date_range = 3;
   */
  dateRange?: Interval;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListActivityFeedResponse
 */
export interface ListActivityFeedResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.ListActivityFeedResponse.AggregateActivity activities = 1;
   */
  activities: ListActivityFeedResponse_AggregateActivity[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListActivityFeedResponse.AggregateActivity
 */
export interface ListActivityFeedResponse_AggregateActivity {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp_hour = 1;
   */
  timestampHour?: Timestamp;
  /**
   * @generated from protobuf field: string activity_type = 2;
   */
  activityType: string;
  /**
   * @generated from protobuf field: int32 total_seconds = 3;
   */
  totalSeconds: number;
  /**
   * @generated from protobuf field: int32 correct_activities = 4;
   */
  correctActivities: number;
  /**
   * @generated from protobuf field: int32 total_activities = 5;
   */
  totalActivities: number;
  /**
   * @generated from protobuf field: repeated string topics = 6;
   */
  topics: string[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.SetTaskItemsComplete_TaskItem
 */
export interface SetTaskItemsComplete_TaskItem {
  /**
   * resource name of the task item
   * Format: packages/<UUID>/tasks/<UUID>/items/<INT>
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * User the task item belongs to
   *
   * @generated from protobuf field: string student_name = 2;
   */
  studentName: string;
  /**
   * School the user is in
   *
   * @generated from protobuf field: string school_name = 3;
   */
  schoolName: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.SetTaskItemsCompleteRequest
 */
export interface SetTaskItemsCompleteRequest {
  /**
   * List of task item resource names
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.SetTaskItemsComplete_TaskItem task_items = 1;
   */
  taskItems: SetTaskItemsComplete_TaskItem[];
  /**
   * Complete the task item even if there are not actvities.
   *
   * @generated from protobuf field: bool complete_if_not_started = 2;
   */
  completeIfNotStarted: boolean;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.SetTaskItemsCompleteResponse
 */
export interface SetTaskItemsCompleteResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.SetTaskItemsComplete_TaskItem updated = 1;
   */
  updated: SetTaskItemsComplete_TaskItem[];
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.SetTaskItemsComplete_TaskItem already_complete = 2;
   */
  alreadyComplete: SetTaskItemsComplete_TaskItem[];
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.SetTaskItemsComplete_TaskItem not_started = 3;
   */
  notStarted: SetTaskItemsComplete_TaskItem[];
}
// @generated message type with reflection information, may provide speed optimized methods
class Activity$Type extends MessageType<Activity> {
  constructor() {
    super('sparx.science.packages.v1.Activity', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'task_item_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      { no: 4, name: 'state', kind: 'message', T: () => Activity_State },
      { no: 5, name: 'start_timestamp', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'end_timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.Activity
 */
export const Activity = new Activity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Activity_PreviousActivity$Type extends MessageType<Activity_PreviousActivity> {
  constructor() {
    super('sparx.science.packages.v1.Activity.PreviousActivity', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'state', kind: 'message', T: () => Activity_State },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.Activity.PreviousActivity
 */
export const Activity_PreviousActivity = new Activity_PreviousActivity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Activity_State$Type extends MessageType<Activity_State> {
  constructor() {
    super('sparx.science.packages.v1.Activity.State', [
      { no: 3, name: 'token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 1,
        name: 'skill_activity',
        kind: 'message',
        T: () => SkillActivity,
      },
      {
        no: 2,
        name: 'previous_activity',
        kind: 'message',
        T: () => Activity_PreviousActivity,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.Activity.State
 */
export const Activity_State = new Activity_State$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SkillActivity$Type extends MessageType<SkillActivity> {
  constructor() {
    super('sparx.science.packages.v1.SkillActivity', [
      {
        no: 1,
        name: 'state',
        kind: 'enum',
        T: () => [
          'sparx.science.packages.v1.SkillActivity.State',
          SkillActivity_State,
        ],
      },
      {
        no: 2,
        name: 'question',
        kind: 'message',
        T: () => SkillActivity_Question,
      },
      { no: 3, name: 'evaluation', kind: 'message', T: () => Evaluation },
      {
        no: 4,
        name: 'step_evaluations',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StepEvaluation,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.SkillActivity
 */
export const SkillActivity = new SkillActivity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SkillActivity_Steps$Type extends MessageType<SkillActivity_Steps> {
  constructor() {
    super('sparx.science.packages.v1.SkillActivity.Steps', [
      {
        no: 1,
        name: 'delivery',
        kind: 'enum',
        T: () => [
          'sparx.science.packages.v1.SkillActivity.Steps.Delivery',
          SkillActivity_Steps_Delivery,
        ],
      },
      {
        no: 2,
        name: 'current_step',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 3, name: 'max_step', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 4,
        name: 'visible_step',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'step_display_number_map',
        kind: 'map',
        K: 5 /*ScalarType.INT32*/,
        V: { kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.SkillActivity.Steps
 */
export const SkillActivity_Steps = new SkillActivity_Steps$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SkillActivity_Question$Type extends MessageType<SkillActivity_Question> {
  constructor() {
    super('sparx.science.packages.v1.SkillActivity.Question', [
      {
        no: 1,
        name: 'question_json',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'support_material',
        kind: 'message',
        T: () => SkillSupportMaterial,
      },
      { no: 3, name: 'video_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'steps', kind: 'message', T: () => SkillActivity_Steps },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.SkillActivity.Question
 */
export const SkillActivity_Question = new SkillActivity_Question$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InternalActivityState$Type extends MessageType<InternalActivityState> {
  constructor() {
    super('sparx.science.packages.v1.InternalActivityState', [
      { no: 1, name: 'question', kind: 'message', T: () => Question },
      {
        no: 2,
        name: 'internal_gap_evaluations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => GapEvaluation },
      },
      {
        no: 3,
        name: 'question_time_seconds',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
      {
        no: 4,
        name: 'support_time_seconds',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
      {
        no: 5,
        name: 'last_view_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 6, name: 'topic_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 7,
        name: 'subject',
        kind: 'enum',
        T: () => ['sparx.science.v1.Subject', Subject],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.InternalActivityState
 */
export const InternalActivityState = new InternalActivityState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GapEvaluation$Type extends MessageType<GapEvaluation> {
  constructor() {
    super('sparx.science.packages.v1.GapEvaluation', [
      { no: 1, name: 'correct', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 2, name: 'correction', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'detail',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => GapEvaluationDetail,
      },
      {
        no: 4,
        name: 'additional_data',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GapEvaluation
 */
export const GapEvaluation = new GapEvaluation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GapEvaluationDetail$Type extends MessageType<GapEvaluationDetail> {
  constructor() {
    super('sparx.science.packages.v1.GapEvaluationDetail', [
      {
        no: 1,
        name: 'available_marks',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'awarded_marks',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 3, name: 'feedback', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'feedback_hint',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'error_marking',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GapEvaluationDetail
 */
export const GapEvaluationDetail = new GapEvaluationDetail$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Evaluation$Type extends MessageType<Evaluation> {
  constructor() {
    super('sparx.science.packages.v1.Evaluation', [
      {
        no: 1,
        name: 'status',
        kind: 'enum',
        T: () => ['sparx.science.packages.v1.TaskItem.Status', TaskItem_Status],
      },
      { no: 2, name: 'completed', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 3,
        name: 'submitted_answer',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      {
        no: 4,
        name: 'gap_evaluations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => GapEvaluation },
      },
      { no: 5, name: 'timestamp', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'marks', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.Evaluation
 */
export const Evaluation = new Evaluation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StepEvaluation$Type extends MessageType<StepEvaluation> {
  constructor() {
    super('sparx.science.packages.v1.StepEvaluation', [
      {
        no: 1,
        name: 'evaluations',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Evaluation,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.StepEvaluation
 */
export const StepEvaluation = new StepEvaluation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActivityRequest$Type extends MessageType<GetActivityRequest> {
  constructor() {
    super('sparx.science.packages.v1.GetActivityRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'task_item_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GetActivityRequest
 */
export const GetActivityRequest = new GetActivityRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActivityResponse$Type extends MessageType<GetActivityResponse> {
  constructor() {
    super('sparx.science.packages.v1.GetActivityResponse', [
      { no: 1, name: 'activity', kind: 'message', T: () => Activity },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GetActivityResponse
 */
export const GetActivityResponse = new GetActivityResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivityActionRequest$Type extends MessageType<ActivityActionRequest> {
  constructor() {
    super('sparx.science.packages.v1.ActivityActionRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 10, name: 'token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'answer',
        kind: 'message',
        oneof: 'action',
        T: () => ActivityActionRequest_Answer,
      },
      {
        no: 3,
        name: 'view',
        kind: 'message',
        oneof: 'action',
        T: () => ActivityActionRequest_View,
      },
      {
        no: 4,
        name: 'next',
        kind: 'message',
        oneof: 'action',
        T: () => ActivityActionRequest_Next,
      },
      {
        no: 5,
        name: 'retry',
        kind: 'message',
        oneof: 'action',
        T: () => ActivityActionRequest_Retry,
      },
      {
        no: 6,
        name: 'skip',
        kind: 'message',
        oneof: 'action',
        T: () => ActivityActionRequest_Skip,
      },
      {
        no: 7,
        name: 'report',
        kind: 'message',
        oneof: 'action',
        T: () => ActivityActionRequest_Report,
      },
      {
        no: 8,
        name: 'help',
        kind: 'message',
        oneof: 'action',
        T: () => ActivityActionRequest_Help,
      },
      {
        no: 9,
        name: 'navigate_step',
        kind: 'message',
        oneof: 'action',
        T: () => ActivityActionRequest_NavigateStep,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ActivityActionRequest
 */
export const ActivityActionRequest = new ActivityActionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivityActionRequest_Answer$Type extends MessageType<ActivityActionRequest_Answer> {
  constructor() {
    super('sparx.science.packages.v1.ActivityActionRequest.Answer', [
      {
        no: 1,
        name: 'components',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      {
        no: 2,
        name: 'auto_progress_step',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ActivityActionRequest.Answer
 */
export const ActivityActionRequest_Answer =
  new ActivityActionRequest_Answer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivityActionRequest_View$Type extends MessageType<ActivityActionRequest_View> {
  constructor() {
    super('sparx.science.packages.v1.ActivityActionRequest.View', [
      { no: 1, name: 'unload', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ActivityActionRequest.View
 */
export const ActivityActionRequest_View = new ActivityActionRequest_View$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivityActionRequest_Next$Type extends MessageType<ActivityActionRequest_Next> {
  constructor() {
    super('sparx.science.packages.v1.ActivityActionRequest.Next', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ActivityActionRequest.Next
 */
export const ActivityActionRequest_Next = new ActivityActionRequest_Next$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivityActionRequest_Retry$Type extends MessageType<ActivityActionRequest_Retry> {
  constructor() {
    super('sparx.science.packages.v1.ActivityActionRequest.Retry', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ActivityActionRequest.Retry
 */
export const ActivityActionRequest_Retry =
  new ActivityActionRequest_Retry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivityActionRequest_Skip$Type extends MessageType<ActivityActionRequest_Skip> {
  constructor() {
    super('sparx.science.packages.v1.ActivityActionRequest.Skip', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ActivityActionRequest.Skip
 */
export const ActivityActionRequest_Skip = new ActivityActionRequest_Skip$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivityActionRequest_Report$Type extends MessageType<ActivityActionRequest_Report> {
  constructor() {
    super('sparx.science.packages.v1.ActivityActionRequest.Report', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ActivityActionRequest.Report
 */
export const ActivityActionRequest_Report =
  new ActivityActionRequest_Report$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivityActionRequest_Help$Type extends MessageType<ActivityActionRequest_Help> {
  constructor() {
    super('sparx.science.packages.v1.ActivityActionRequest.Help', [
      { no: 1, name: 'viewing', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ActivityActionRequest.Help
 */
export const ActivityActionRequest_Help = new ActivityActionRequest_Help$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivityActionRequest_NavigateStep$Type extends MessageType<ActivityActionRequest_NavigateStep> {
  constructor() {
    super('sparx.science.packages.v1.ActivityActionRequest.NavigateStep', [
      { no: 1, name: 'step', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ActivityActionRequest.NavigateStep
 */
export const ActivityActionRequest_NavigateStep =
  new ActivityActionRequest_NavigateStep$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivityActionResponse$Type extends MessageType<ActivityActionResponse> {
  constructor() {
    super('sparx.science.packages.v1.ActivityActionResponse', [
      { no: 1, name: 'activity', kind: 'message', T: () => Activity },
      { no: 2, name: 'package_update', kind: 'message', T: () => Package },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ActivityActionResponse
 */
export const ActivityActionResponse = new ActivityActionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPackageAnswerHistoryRequest$Type extends MessageType<GetPackageAnswerHistoryRequest> {
  constructor() {
    super('sparx.science.packages.v1.GetPackageAnswerHistoryRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'student_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'package_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GetPackageAnswerHistoryRequest
 */
export const GetPackageAnswerHistoryRequest =
  new GetPackageAnswerHistoryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPackageAnswerHistoryResponse$Type extends MessageType<GetPackageAnswerHistoryResponse> {
  constructor() {
    super('sparx.science.packages.v1.GetPackageAnswerHistoryResponse', [
      { no: 1, name: 'package', kind: 'message', T: () => Package },
      {
        no: 2,
        name: 'activities',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => GetPackageAnswerHistoryResponse_ActivityWithAnswer,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GetPackageAnswerHistoryResponse
 */
export const GetPackageAnswerHistoryResponse =
  new GetPackageAnswerHistoryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPackageAnswerHistoryResponse_ActivityWithAnswer$Type extends MessageType<GetPackageAnswerHistoryResponse_ActivityWithAnswer> {
  constructor() {
    super(
      'sparx.science.packages.v1.GetPackageAnswerHistoryResponse.ActivityWithAnswer',
      [
        { no: 1, name: 'activity', kind: 'message', T: () => Activity },
        {
          no: 2,
          name: 'correct_answer',
          kind: 'map',
          K: 9 /*ScalarType.STRING*/,
          V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        },
        {
          no: 3,
          name: 'correct_answer_gap_evaluations',
          kind: 'map',
          K: 9 /*ScalarType.STRING*/,
          V: { kind: 'message', T: () => GapEvaluation },
        },
        {
          no: 4,
          name: 'question_time_seconds',
          kind: 'scalar',
          T: 1 /*ScalarType.DOUBLE*/,
        },
        {
          no: 5,
          name: 'support_time_seconds',
          kind: 'scalar',
          T: 1 /*ScalarType.DOUBLE*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GetPackageAnswerHistoryResponse.ActivityWithAnswer
 */
export const GetPackageAnswerHistoryResponse_ActivityWithAnswer =
  new GetPackageAnswerHistoryResponse_ActivityWithAnswer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTetheringActivityRequest$Type extends MessageType<GetTetheringActivityRequest> {
  constructor() {
    super('sparx.science.packages.v1.GetTetheringActivityRequest', [
      {
        no: 1,
        name: 'session_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'last_tether_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GetTetheringActivityRequest
 */
export const GetTetheringActivityRequest =
  new GetTetheringActivityRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTetheringActivityResponse$Type extends MessageType<GetTetheringActivityResponse> {
  constructor() {
    super('sparx.science.packages.v1.GetTetheringActivityResponse', [
      { no: 1, name: 'activity', kind: 'message', T: () => Activity },
      { no: 2, name: 'tether_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GetTetheringActivityResponse
 */
export const GetTetheringActivityResponse =
  new GetTetheringActivityResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListActivitiesRequest$Type extends MessageType<ListActivitiesRequest> {
  constructor() {
    super('sparx.science.packages.v1.ListActivitiesRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListActivitiesRequest
 */
export const ListActivitiesRequest = new ListActivitiesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListActivitiesResponse$Type extends MessageType<ListActivitiesResponse> {
  constructor() {
    super('sparx.science.packages.v1.ListActivitiesResponse', [
      {
        no: 1,
        name: 'activities',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ListActivitiesResponse_StudentActivity,
      },
      {
        no: 2,
        name: 'correct_answers',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ListActivitiesResponse_QuestionAnswer,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListActivitiesResponse
 */
export const ListActivitiesResponse = new ListActivitiesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListActivitiesResponse_StudentActivity$Type extends MessageType<ListActivitiesResponse_StudentActivity> {
  constructor() {
    super('sparx.science.packages.v1.ListActivitiesResponse.StudentActivity', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'question_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'activity', kind: 'message', T: () => Activity },
      {
        no: 4,
        name: 'question_time_seconds',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
      {
        no: 5,
        name: 'support_time_seconds',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListActivitiesResponse.StudentActivity
 */
export const ListActivitiesResponse_StudentActivity =
  new ListActivitiesResponse_StudentActivity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListActivitiesResponse_QuestionAnswer$Type extends MessageType<ListActivitiesResponse_QuestionAnswer> {
  constructor() {
    super('sparx.science.packages.v1.ListActivitiesResponse.QuestionAnswer', [
      {
        no: 1,
        name: 'question_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'correct_answer',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      {
        no: 3,
        name: 'correct_answer_gap_evaluations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => GapEvaluation },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListActivitiesResponse.QuestionAnswer
 */
export const ListActivitiesResponse_QuestionAnswer =
  new ListActivitiesResponse_QuestionAnswer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserTaskItemActivitiesRequest$Type extends MessageType<ListUserTaskItemActivitiesRequest> {
  constructor() {
    super('sparx.science.packages.v1.ListUserTaskItemActivitiesRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListUserTaskItemActivitiesRequest
 */
export const ListUserTaskItemActivitiesRequest =
  new ListUserTaskItemActivitiesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserTaskItemActivitiesResponse$Type extends MessageType<ListUserTaskItemActivitiesResponse> {
  constructor() {
    super('sparx.science.packages.v1.ListUserTaskItemActivitiesResponse', [
      {
        no: 1,
        name: 'activities',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ListUserTaskItemActivitiesResponse_StudentActivity,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListUserTaskItemActivitiesResponse
 */
export const ListUserTaskItemActivitiesResponse =
  new ListUserTaskItemActivitiesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserTaskItemActivitiesResponse_StudentActivity$Type extends MessageType<ListUserTaskItemActivitiesResponse_StudentActivity> {
  constructor() {
    super(
      'sparx.science.packages.v1.ListUserTaskItemActivitiesResponse.StudentActivity',
      [
        { no: 1, name: 'activity', kind: 'message', T: () => Activity },
        {
          no: 2,
          name: 'question_time_seconds',
          kind: 'scalar',
          T: 1 /*ScalarType.DOUBLE*/,
        },
        {
          no: 3,
          name: 'support_time_seconds',
          kind: 'scalar',
          T: 1 /*ScalarType.DOUBLE*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListUserTaskItemActivitiesResponse.StudentActivity
 */
export const ListUserTaskItemActivitiesResponse_StudentActivity =
  new ListUserTaskItemActivitiesResponse_StudentActivity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetActivityEvaluationRequest$Type extends MessageType<SetActivityEvaluationRequest> {
  constructor() {
    super('sparx.science.packages.v1.SetActivityEvaluationRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'student_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'activity_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'status',
        kind: 'enum',
        T: () => ['sparx.science.packages.v1.TaskItem.Status', TaskItem_Status],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.SetActivityEvaluationRequest
 */
export const SetActivityEvaluationRequest =
  new SetActivityEvaluationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetActivityEvaluationResponse$Type extends MessageType<SetActivityEvaluationResponse> {
  constructor() {
    super('sparx.science.packages.v1.SetActivityEvaluationResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.SetActivityEvaluationResponse
 */
export const SetActivityEvaluationResponse =
  new SetActivityEvaluationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListActivityFeedRequest$Type extends MessageType<ListActivityFeedRequest> {
  constructor() {
    super('sparx.science.packages.v1.ListActivityFeedRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'student_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'date_range', kind: 'message', T: () => Interval },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListActivityFeedRequest
 */
export const ListActivityFeedRequest = new ListActivityFeedRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListActivityFeedResponse$Type extends MessageType<ListActivityFeedResponse> {
  constructor() {
    super('sparx.science.packages.v1.ListActivityFeedResponse', [
      {
        no: 1,
        name: 'activities',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ListActivityFeedResponse_AggregateActivity,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListActivityFeedResponse
 */
export const ListActivityFeedResponse = new ListActivityFeedResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListActivityFeedResponse_AggregateActivity$Type extends MessageType<ListActivityFeedResponse_AggregateActivity> {
  constructor() {
    super(
      'sparx.science.packages.v1.ListActivityFeedResponse.AggregateActivity',
      [
        { no: 1, name: 'timestamp_hour', kind: 'message', T: () => Timestamp },
        {
          no: 2,
          name: 'activity_type',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 3,
          name: 'total_seconds',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 4,
          name: 'correct_activities',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 5,
          name: 'total_activities',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 6,
          name: 'topics',
          kind: 'scalar',
          repeat: 2 /*RepeatType.UNPACKED*/,
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListActivityFeedResponse.AggregateActivity
 */
export const ListActivityFeedResponse_AggregateActivity =
  new ListActivityFeedResponse_AggregateActivity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetTaskItemsComplete_TaskItem$Type extends MessageType<SetTaskItemsComplete_TaskItem> {
  constructor() {
    super('sparx.science.packages.v1.SetTaskItemsComplete_TaskItem', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'student_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.SetTaskItemsComplete_TaskItem
 */
export const SetTaskItemsComplete_TaskItem =
  new SetTaskItemsComplete_TaskItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetTaskItemsCompleteRequest$Type extends MessageType<SetTaskItemsCompleteRequest> {
  constructor() {
    super('sparx.science.packages.v1.SetTaskItemsCompleteRequest', [
      {
        no: 1,
        name: 'task_items',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SetTaskItemsComplete_TaskItem,
      },
      {
        no: 2,
        name: 'complete_if_not_started',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.SetTaskItemsCompleteRequest
 */
export const SetTaskItemsCompleteRequest =
  new SetTaskItemsCompleteRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetTaskItemsCompleteResponse$Type extends MessageType<SetTaskItemsCompleteResponse> {
  constructor() {
    super('sparx.science.packages.v1.SetTaskItemsCompleteResponse', [
      {
        no: 1,
        name: 'updated',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SetTaskItemsComplete_TaskItem,
      },
      {
        no: 2,
        name: 'already_complete',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SetTaskItemsComplete_TaskItem,
      },
      {
        no: 3,
        name: 'not_started',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SetTaskItemsComplete_TaskItem,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.SetTaskItemsCompleteResponse
 */
export const SetTaskItemsCompleteResponse =
  new SetTaskItemsCompleteResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.science.packages.v1.Activities
 */
export const Activities = new ServiceType(
  'sparx.science.packages.v1.Activities',
  [
    {
      name: 'GetActivity',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'sw:student',
          domain: 'sw',
        },
      },
      I: GetActivityRequest,
      O: GetActivityResponse,
    },
    {
      name: 'ActivityAction',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'sw:student',
          domain: 'sw',
        },
      },
      I: ActivityActionRequest,
      O: ActivityActionResponse,
    },
    {
      name: 'GetPackageAnswerHistory',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:homework',
          domain: '{school_name}',
        },
      },
      I: GetPackageAnswerHistoryRequest,
      O: GetPackageAnswerHistoryResponse,
    },
    {
      name: 'GetTetheringActivity',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'sw:student',
          domain: 'sw',
        },
      },
      I: GetTetheringActivityRequest,
      O: GetTetheringActivityResponse,
    },
    {
      name: 'ListUserTaskItemActivities',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'sw:student',
          domain: 'sw',
        },
      },
      I: ListUserTaskItemActivitiesRequest,
      O: ListUserTaskItemActivitiesResponse,
    },
    {
      name: 'ListActivities',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:homework',
          domain: '{school_name}',
        },
      },
      I: ListActivitiesRequest,
      O: ListActivitiesResponse,
    },
    {
      name: 'SetActivityEvaluation',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:homework',
          domain: '{school_name}',
        },
      },
      I: SetActivityEvaluationRequest,
      O: SetActivityEvaluationResponse,
    },
    {
      name: 'ListActivityFeed',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:homework',
          domain: '{school_name}',
        },
      },
      I: ListActivityFeedRequest,
      O: ListActivityFeedResponse,
    },
    {
      name: 'SetTaskItemsComplete',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:homework',
          domain: 'schools',
        },
      },
      I: SetTaskItemsCompleteRequest,
      O: SetTaskItemsCompleteResponse,
    },
  ],
);
