import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { SparxClass, WondeClass } from '@sparx/mis-sync-import/src/types';

/**
 * Tracks the state of the configuration for a sync.
 */
export type SyncConfig = {
  /**
   * List of wonde classes to be imported.
   */
  classesToAdd: WondeClass[];
  /**
   * Existing sparx classes.
   */
  existingClasses: SparxClass[];
  /**
   * Existing expired classes - keyed by their Wonde ID
   */
  existingExpiredClasses: Record<string, Group>;
  /**
   * List of existing class names that should be removed.
   */
  classesToRemove: string[];

  // Map of student wonde IDs to the wonde class ID they should belong to.
  conflictResolutions: Record<string, string>;

  // List of Sparx classes removed from Wonde
  sparxGroupsRemovedFromWonde: Group[];

  // List of the Wonde IDs of classes that have been added automatically because they share a name with a class that
  // was removed from Wonde
  automaticallyAddedClasses: string[];

  // Map of Wonde classes that have been matched to a Sparx class (possibly a recently expired Sparx class), either
  // automatically because they have the same name, or manually by a user using the Sparx Staff class matching
  // capability. Key = wonde id, value = Sparx group
  classMatches: Record<string, Group>;
};

export const emptySyncConfig: SyncConfig = {
  classesToAdd: [],
  classesToRemove: [],
  existingClasses: [],
  existingExpiredClasses: {},
  conflictResolutions: {},
  sparxGroupsRemovedFromWonde: [],
  automaticallyAddedClasses: [],
  classMatches: {},
};
