import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

import {
  Alert,
  AlertIcon,
  Badge,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  Assignment,
  Assignment_Group,
  GeneratedAssignment,
  GeneratedAssignmentTopic,
} from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useTopicLookup } from 'api/content';
import { useCreateOrUpdateAssignment } from 'api/planner';
import { useGroups } from 'api/school';
import { add, isBefore } from 'date-fns';
import React, { useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { DatePicker } from 'views/planner/components/DatePicker'; // theme css file

interface AssignmentFormProps {
  assignment?: Assignment;
  children: (callback: {
    form: React.ReactNode;
    formId: string;
    isSubmitting: boolean;
  }) => React.ReactNode;
  onSuccess?: (assignment: Assignment) => void;
}

export const AssignmentForm = ({ assignment, children, onSuccess }: AssignmentFormProps) => {
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues:
      assignment ||
      Assignment.create({
        spec: {
          contents: {
            oneofKind: 'generatedAssignment',
            generatedAssignment: GeneratedAssignment.create({
              topics: [],
              lengthMinutes: 30,
            }),
          },
        },
        startTimestamp: Timestamp.now(),
        endTimestamp: Timestamp.fromDate(add(new Date(), { hours: 1 })),
      }),
  });

  const { fields: topicFields, replace } = useFieldArray({
    control,
    name: 'spec.contents.generatedAssignment.topics',
  });
  const { fields: groupFields, replace: replaceGroups } = useFieldArray({
    control,
    name: 'groups',
  });

  const { mutateAsync } = useCreateOrUpdateAssignment();

  const { data: topicLookup } = useTopicLookup({ suspense: false });
  const topics = Object.values(topicLookup || {}).sort((a, b) =>
    a.topic.name.localeCompare(b.topic.name),
  );
  const { data: groups } = useGroups({ suspense: false });

  const startDate = Timestamp.toDate(watch('startTimestamp') || Timestamp.now());
  const endDate = Timestamp.toDate(watch('endTimestamp') || Timestamp.now());

  const formId = useMemo(() => `assignment-form-${uuid()}`, []);
  const form = (
    <VStack
      as="form"
      id={formId}
      alignItems="flex-start"
      spacing={4}
      onSubmit={handleSubmit(async a => {
        try {
          const resp = await mutateAsync({ ...a, action: a.name ? 'update' : 'create' });
          onSuccess?.(resp);
        } catch (e) {
          /* ignore */
        }
      })}
    >
      <FormControl isInvalid={!!errors.title} isRequired>
        <FormLabel htmlFor="title">Title</FormLabel>
        <Input id="title" placeholder="Title" {...register('title', { required: true })} />
        <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
      </FormControl>

      <FormControl>
        <FormLabel htmlFor="title">Duration</FormLabel>
        <DatePicker
          name="Hand out"
          date={startDate}
          setDate={date => setValue('startTimestamp', Timestamp.fromDate(date))}
        />
        <DatePicker
          name="Hand in"
          date={endDate}
          setDate={date => setValue('endTimestamp', Timestamp.fromDate(date))}
        />
        {isBefore(startDate, new Date()) && (
          <Alert status="warning" mt={3}>
            <AlertIcon />
            This homework has a hand out in the past so will be set immediately.
          </Alert>
        )}
      </FormControl>

      <FormControl>
        <FormLabel htmlFor="title">Topics</FormLabel>
        <CheckboxGroup
          value={topicFields.map(f => f.name)}
          onChange={values =>
            replace(
              values.map(v =>
                GeneratedAssignmentTopic.create({
                  name: v.toString(),
                }),
              ),
            )
          }
        >
          <VStack spacing={2} alignItems="flex-start">
            {topics?.map(topic => (
              <Checkbox key={topic.topic?.name} value={topic.topic?.name}>
                <HStack spacing={2}>
                  <Text>{topic.topic?.displayName}</Text>
                  <Badge>{topic.topic?.code}</Badge>
                </HStack>
              </Checkbox>
            ))}
          </VStack>
        </CheckboxGroup>
      </FormControl>

      <FormControl>
        <FormLabel htmlFor="title">Groups</FormLabel>
        <CheckboxGroup
          value={groupFields.map(f => f.name)}
          onChange={values =>
            replaceGroups(
              values.map(v =>
                Assignment_Group.create({
                  name: v.toString(),
                }),
              ),
            )
          }
        >
          <VStack spacing={2} alignItems="flex-start">
            {groups?.map(group => (
              <Checkbox key={group.name} value={group.name}>
                <HStack spacing={2}>
                  <Text>{group.displayName}</Text>
                </HStack>
              </Checkbox>
            ))}
          </VStack>
        </CheckboxGroup>
      </FormControl>
    </VStack>
  );

  return <>{children({ form, isSubmitting, formId })}</>;
};
