import { Button, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import { Package } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { useDemoPackage } from 'api/packages';
import { useUserType } from 'api/sessions';
import React, { useEffect, useState } from 'react';

export const DemoPackageCreator = ({ pkgs }: { pkgs: Package[] }) => {
  const { isSparxStaff } = useUserType();
  const { mutate, isLoading, variables } = useDemoPackage();
  const [created, setCreated] = useState(false);

  // Create a demo package as soon as it's mounted (but only try once)
  useEffect(() => {
    if (!created && pkgs.length === 0) {
      setCreated(true);
      mutate('demo');
    }
  }, [mutate, pkgs, created, setCreated]);

  return (
    <>
      {isLoading && (
        <HStack pb={3} spacing={2}>
          <Spinner color="teal" size="sm" />
          <Text>Generating demo package...</Text>
        </HStack>
      )}
      {isSparxStaff && (
        <VStack position="absolute" bottom="0px" right="17px" mb={4} alignItems="flex-end">
          <Button
            isLoading={isLoading && variables === 'demo'}
            onClick={() => mutate('demo')}
            colorScheme="buttonTeal"
            variant="outline"
            size="sm"
          >
            Create demo homework
          </Button>
          <Button
            isLoading={isLoading && variables === 'testcontent'}
            onClick={() => mutate('testcontent')}
            colorScheme="buttonTeal"
            variant="outline"
            size="sm"
          >
            Create test content package
          </Button>
        </VStack>
      )}
    </>
  );
};
