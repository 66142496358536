import { Box, ChakraProvider, extendTheme } from '@chakra-ui/react';
import { LargeLoading, LargeLoadingWithText } from 'components/loading/LargeLoading';
import {
  Children,
  Fragment,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { theme } from 'theme/theme';

import { Page } from './Page';

interface PrintPagesProps {
  instructions?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  mmToPx: number;
}
export const PrintTheme = ({ children }: PropsWithChildren) => {
  // Override the theme to set styles needed for printing
  const printTheme = extendTheme(theme, {
    styles: {
      global: {
        '@page': {
          size: 'A4',
          margin: '0',
        },
        body: {
          backgroundColor: 'pageBackground',
          overflow: 'auto',
        },
        img: {
          maxHeight: '300px !important',
        },
        '#root': {
          position: 'relative',
          overflow: 'initial',
        },
        '@media print': {
          body: {
            overflow: 'visible',
            backgroundColor: 'white',
          },
        },
      },
    },
  });

  return <ChakraProvider theme={printTheme}>{children}</ChakraProvider>;
};

export const PrintPages = ({
  instructions,
  header,
  footer,
  mmToPx,
  children,
}: PropsWithChildren<PrintPagesProps>) => {
  const [currentChildIndex, setCurrentChildIndex] = useState(0);
  const lastChild = useRef(-1);
  // On the last render, it sets the height of all the cards to calculated
  // maxHeightFromWidth.
  const [isLastRender, setIsLastRender] = useState(false);

  const previousCardIndex = useRef(0);
  const currentCardIndex = useRef(0);

  const childrenArray = useRef<ReactNode[]>([]);
  const cardsWithElements = useRef<ReactNode[][]>([[]]);

  // Checks the height of the card, if it exceeds the
  // maximum height, then add another card for the offending element
  // to be added to
  const addNewCardIfMaxHeightExceeded = (node: HTMLDivElement) => {
    if (node === null) {
      return;
    }

    const dimensions = node.getBoundingClientRect();

    // Check height is not already set as well as checking if the card is
    // larger than the max available. Setting the height can sometimes set
    // it different to a few subpixel difference so it's necessary to
    // assert against the last render
    if (!isLastRender && dimensions.height > mmToPx * (297 - 20)) {
      currentCardIndex.current++;
    }
  };

  useEffect(() => {
    childrenArray.current = Children.toArray(children);
  }, [children]);

  useEffect(() => {
    const totalChildren = childrenArray.current.length;
    if (totalChildren <= 0 || mmToPx <= 0) {
      return;
    }

    // If there is no more rendering to be performed then set
    // isLastRender to true, which will set each card to the correct
    // height
    if (currentChildIndex > totalChildren) {
      setIsLastRender(true);
      return;
    }

    // If the child index we last added hasn't changed then exit now as we don't want to duplicate it.
    if (lastChild.current === currentChildIndex) {
      return;
    }
    lastChild.current = currentChildIndex;

    // If the currentCardIndex is greater than the previousCardIndex then
    // remove the last element from the previous card and add it to the new card.
    // A currentCardIndex will only be incremented if adding node has caused
    // a page to exceed its max height.
    if (currentCardIndex.current > previousCardIndex.current) {
      // Remove the last element from the previous card
      const lastElementInPreviousCard = cardsWithElements.current[previousCardIndex.current].pop();

      // Add the last element from the previous card to the current (new) card
      cardsWithElements.current[currentCardIndex.current] = [lastElementInPreviousCard];

      // Reset the previousCardIndex value to prevent spawning new cards
      previousCardIndex.current = currentCardIndex.current;
    }

    // Add the next child in the array to the current card
    cardsWithElements.current[currentCardIndex.current] = [
      ...cardsWithElements.current[currentCardIndex.current],
      childrenArray.current[currentChildIndex],
    ];

    // Increment the child index to cause a re-render and therefore add
    // the next element to the current card
    setTimeout(() => setCurrentChildIndex(currentChildIndex + 1), 100);
  }, [children, currentChildIndex, setCurrentChildIndex, mmToPx]);

  return (
    <>
      {mmToPx <= 0 ? (
        <LargeLoading />
      ) : (
        <>
          {!isLastRender && <LargeLoadingWithText>Organising pages...</LargeLoadingWithText>}
          {instructions && (
            <Box
              opacity={isLastRender ? 1 : 0}
              width={mmToPx * 210}
              mx="auto"
              sx={{
                '@media print': { display: 'none' },
              }}
            >
              {instructions}
            </Box>
          )}
          <Box
            position="relative"
            opacity={isLastRender ? 1 : 0}
            overflow={isLastRender ? undefined : 'hidden'}
          >
            {cardsWithElements.current.map((cardElements, index) => {
              return (
                <Page
                  key={`${index}-${cardElements.length}`}
                  ref={addNewCardIfMaxHeightExceeded}
                  isLastRender={isLastRender}
                  header={header}
                  footer={footer}
                  mmToPxRatio={mmToPx}
                >
                  {cardElements.map((element, eIdx) => (
                    <Fragment key={eIdx}>
                      {eIdx !== 0 && <Box height={2} />}
                      {element}
                    </Fragment>
                  ))}
                </Page>
              );
            })}
          </Box>
        </>
      )}
    </>
  );
};
