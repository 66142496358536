import { ResourceStatus } from '@sparx/api/apis/sparx/planning/v1/sol';
import { SchoolYearWithDates, useSchoolYears } from 'api/school';
import { filterGroupsUsingSol, useSuspenseGroupsWithSettings } from 'api/scienceschool';
import { useSession, useUserType } from 'api/sessions';
import { useDeletedSols, useEditableSols } from 'api/sol';
import { isBefore, sub } from 'date-fns';
import React, { useMemo } from 'react';

import { AnnotatedSchemeOfLearning, SolTable } from './components/SolTable';
import { rowHasDraftVersion, rowHasPublishedVersion } from './solHelpers';

export const getPreviousArchiveYear = (schoolYears: SchoolYearWithDates[]) => {
  let previousYear = schoolYears.find(sy => sy.previous);
  if (previousYear && isBefore(previousYear.end, sub(new Date(), { months: 4 }))) {
    // If the previous year ended 4 or more months ago, don't show it
    previousYear = undefined;
  }
  return previousYear;
};

export const SolArchiveList = () => {
  const { isSparxStaff } = useUserType();
  const { data: unsortedSols = [] } = useEditableSols({ suspense: true });
  const { data: deletedSols = [] } = useDeletedSols({ suspense: true });
  const { data: schoolYears = [] } = useSchoolYears({ suspense: true }, false);
  const groups = useSuspenseGroupsWithSettings();

  const previousYear = getPreviousArchiveYear(schoolYears);

  const { data: { schoolId } = { schoolId: '' } } = useSession();
  const filteredSols: AnnotatedSchemeOfLearning[] = useMemo(() => {
    const previous = unsortedSols
      .filter(
        s => `schools/${schoolId}/years/${s.schoolYear}` === previousYear?.name, // Only show for previous years
      )
      .sort(
        (a, b) =>
          a.displayName.localeCompare(b.displayName, undefined, { numeric: true }) ||
          a.name.localeCompare(b.name) ||
          (b.metadata?.status || 0) - (a.metadata?.status || 0),
      );
    const deleted = deletedSols
      .filter(sol => sol.metadata?.updatedBy !== '' || isSparxStaff)
      .sort(
        (a, b) =>
          (b.metadata?.deleteTime?.seconds || 0) - (a.metadata?.deleteTime?.seconds || 0) ||
          a.displayName.localeCompare(b.displayName, undefined, { numeric: true }) ||
          a.name.localeCompare(b.name) ||
          (b.metadata?.status || 0) - (a.metadata?.status || 0),
      );
    const sols = previous.concat(deleted);
    return sols.map(sol => ({
      ...sol,
      isTemplate: false,
      status: sol.metadata?.status || ResourceStatus.UNKNOWN_STATUS,
      hasDraft: Boolean(rowHasDraftVersion(sol, sols)),
      hasPublished: Boolean(rowHasPublishedVersion(sol, sols)),
      assignedGroups:
        sol.metadata?.status === ResourceStatus.PUBLISHED
          ? filterGroupsUsingSol(groups, sol.name)
          : [],
    }));
  }, [schoolId, previousYear, unsortedSols, deletedSols, groups, isSparxStaff]);

  return (
    <SolTable
      archive={true}
      templateSols={false}
      filteredSols={filteredSols}
      defaultYear={schoolYears.find(sy => sy.current)?.name}
    />
  );
};
