import { Link, Text } from '@chakra-ui/react';
import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { ReactNode } from 'react';
import { FieldError } from 'react-hook-form';

import { useStaffContext } from '../Context';

export type UserEditableField = Extract<
  keyof SchoolStaffMember,
  'emailAddress' | 'givenName' | 'familyName' | 'displayName'
>;

export interface ErrorFormatter {
  (field: UserEditableField, error: FieldError): ReactNode;
}

export const displayName = (fieldName: UserEditableField) => {
  switch (fieldName) {
    case 'emailAddress':
      return 'Email address';
    case 'givenName':
      return 'First name';
    case 'familyName':
      return 'Last name';
    case 'displayName':
      return 'Preferred display name';
  }
};

/** Any other error formatters should fall back to use this */
export const defaultGetErrorMessage: ErrorFormatter = (field, error) => {
  const label = displayName(field);
  return (
    <Text color="red" fontSize="sm" pt={1}>
      {error.type === 'required'
        ? `${label || 'Field'} is required`
        : error.message || 'This field is invalid'}
    </Text>
  );
};

export const formGetErrorMessage: ErrorFormatter = (field, error) => {
  if (field === 'emailAddress' && error.type === 'alreadyExists') {
    const ExistingStaffMessage = () => {
      const { onSelectStaff } = useStaffContext();
      return (
        <Text color="red" fontSize="sm" pt={1}>
          A staff member with this email address already exists at your school.{' '}
          <Link
            fontWeight="bold"
            textDecoration="underline"
            onClick={() => error.message && onSelectStaff(error.message)}
          >
            Click here
          </Link>{' '}
          to discard this form and edit the existing staff member.
        </Text>
      );
    };
    return <ExistingStaffMessage />;
  }
  return defaultGetErrorMessage(field, error);
};
