// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/assessment/v1/assessment.proto" (package "sparx.assessment.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { HelpTheClassSkillQuestion } from '../../dataanalysis/homeworkinsights/v1/homeworkinsights';
import { PackageData } from '../../packages/v1/spxpkg';
import { Completion } from '../../packages/v1/spxpkg';
import { PackageStatus } from '../../homeworkman/v1/homeworkman';
import { PackageType } from '../../packages/v1/spxpkg';
import { Timestamp } from '../../../../google/protobuf/timestamp';
import { AdditionalEquipment } from '../../content/v2/skill';
/**
 * An assessment that can be taken by one or more students.
 *
 * @generated from protobuf message sparx.assessment.v1.Assessment
 */
export interface Assessment {
  /**
   * The resource name of the assessment
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Display name of the assessment
   *
   * @generated from protobuf field: string display_name = 2;
   */
  displayName: string;
  /**
   * Subject the assessment is for
   *
   * @generated from protobuf field: string subject_key = 9;
   */
  subjectKey: string;
  /**
   * Version info for this assessment
   *
   * @generated from protobuf field: sparx.assessment.v1.AssessmentVersionInfo version_info = 8;
   */
  versionInfo?: AssessmentVersionInfo;
  /**
   * The questions in the assessment
   *
   * @generated from protobuf field: repeated sparx.assessment.v1.AssessmentQuestion questions = 4;
   */
  questions: AssessmentQuestion[];
  /**
   * Description of the assessment - visible to teachers
   *
   * @generated from protobuf field: string teacher_description = 5;
   */
  teacherDescription: string;
  /**
   * Description of the assessment - visible to students
   *
   * @generated from protobuf field: string student_description = 10;
   */
  studentDescription: string;
  /**
   * Links to assessment resources
   *
   * @generated from protobuf field: repeated sparx.assessment.v1.Link links = 7;
   */
  links: Link[];
  /**
   * The amount of time (in minutes) allowed for this assessment
   *
   * @generated from protobuf field: int32 time_allowed_minutes = 11;
   */
  timeAllowedMinutes: number;
  /**
   * Whether a calculator is allowed for this assessment
   *
   * @generated from protobuf field: bool calculator_allowed = 12;
   */
  calculatorAllowed: boolean;
  /**
   * Additional equipment required to complete this assessment
   *
   * @generated from protobuf field: repeated sparx.content.v2.AdditionalEquipment additional_equipment = 13;
   */
  additionalEquipment: AdditionalEquipment[];
  /**
   * Whether this assessment should be accessible
   *
   * @generated from protobuf field: bool hidden = 14;
   */
  hidden: boolean;
  /**
   * A description of the assessment to go in the QLA report
   *
   * @generated from protobuf field: string report_description = 15;
   */
  reportDescription: string;
  /**
   * The month the QLA report should be sent out (1-12, 0 means undefined)
   *
   * @generated from protobuf field: int32 report_month = 16;
   */
  reportMonth: number;
  /**
   * The day the QLA report should be sent out (1-31, 0 means undefined)
   *
   * @generated from protobuf field: int32 report_day = 17;
   */
  reportDay: number;
  /**
   * The UK Year group the assessment is for (7-11, 0 means undefined)
   *
   * @generated from protobuf field: int32 uk_year_group = 18;
   */
  ukYearGroup: number;
  /**
   * If set, the assessment will use the revision from the specified assessment rather than its own revision
   *
   * @generated from protobuf field: string revision_assessment_id = 19;
   */
  revisionAssessmentId: string;
  /**
   * Status of the assessment
   *
   * @generated from protobuf field: sparx.assessment.v1.AssessmentStatus status = 20;
   */
  status: AssessmentStatus;
  /**
   * When the assessment is valid from
   *
   * @generated from protobuf field: google.protobuf.Timestamp valid_from = 21;
   */
  validFrom?: Timestamp;
  /**
   * When the assessment is valid until
   *
   * @generated from protobuf field: google.protobuf.Timestamp valid_until = 22;
   */
  validUntil?: Timestamp;
}
/**
 * @generated from protobuf message sparx.assessment.v1.AssessmentVersionInfo
 */
export interface AssessmentVersionInfo {
  /**
   * Which publication this is from
   *
   * @generated from protobuf field: string publication_name = 1;
   */
  publicationName: string;
  /**
   * The assessments revision in Curman
   *
   * @generated from protobuf field: int32 revision_number = 2;
   */
  revisionNumber: number;
  /**
   * Who deployed the publication
   *
   * @generated from protobuf field: string deployed_by = 3;
   */
  deployedBy: string;
  /**
   * When the publication was deployed
   *
   * @generated from protobuf field: google.protobuf.Timestamp deployed_time = 4;
   */
  deployedTime?: Timestamp;
}
/**
 * A question within an assessment
 *
 * @generated from protobuf message sparx.assessment.v1.AssessmentQuestion
 */
export interface AssessmentQuestion {
  /**
   * The resource name of the question
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The display name of the question - e.g. "1", "2a" etc.
   *
   * @generated from protobuf field: string display_name = 2;
   */
  displayName: string; // eg. 2a, 4b
  /**
   * The number of marks available for this question
   *
   * @generated from protobuf field: int32 available_marks = 3;
   */
  availableMarks: number;
  /**
   * Resource names of Curriculum Topics most relevant for this question
   *
   * @generated from protobuf field: repeated string curriculum_topic_names = 4;
   */
  curriculumTopicNames: string[];
  /**
   * Unit for this question
   *
   * @generated from protobuf field: string unit = 5;
   */
  unit: string;
  /**
   * The actual assessment question skill
   *
   * @generated from protobuf field: string assessment_skill = 9;
   */
  assessmentSkill: string;
  /**
   * Skill for online use
   *
   * @generated from protobuf field: string online_skill = 6;
   */
  onlineSkill: string;
  /**
   * The type of question
   *
   * @generated from protobuf field: sparx.assessment.v1.QuestionType type = 8;
   */
  type: QuestionType;
}
/**
 * @generated from protobuf message sparx.assessment.v1.AssessmentGroup
 */
export interface AssessmentGroup {
  /**
   * The resource name of the assessment group in the form `assessmentgroups/<id>`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Version info for this assessment revision
   *
   * @generated from protobuf field: sparx.assessment.v1.AssessmentVersionInfo version_info = 2;
   */
  versionInfo?: AssessmentVersionInfo;
  /**
   * Subject the assessment group is for
   *
   * @generated from protobuf field: string subject_key = 3;
   */
  subjectKey: string;
  /**
   * List of assessment names that are in the assessment group
   *
   * @generated from protobuf field: repeated string assessment_names = 4;
   */
  assessmentNames: string[];
  /**
   * Display name of the assessment group
   *
   * @generated from protobuf field: string display_name = 5;
   */
  displayName: string;
  /**
   * UK year group the assessment group is for
   *
   * @generated from protobuf field: int32 uk_year_group = 6;
   */
  ukYearGroup: number;
  /**
   * When the assessment group is valid from
   *
   * @generated from protobuf field: google.protobuf.Timestamp valid_from = 7;
   */
  validFrom?: Timestamp;
  /**
   * When the assessment group is valid until
   *
   * @generated from protobuf field: google.protobuf.Timestamp valid_until = 8;
   */
  validUntil?: Timestamp;
}
/**
 * Details of a student's attempt at an assessment.
 *
 * @generated from protobuf message sparx.assessment.v1.StudentAssessment
 */
export interface StudentAssessment {
  /**
   * The resource name of the school
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The student id
   *
   * @generated from protobuf field: string student_id = 2;
   */
  studentId: string;
  /**
   * The resource name of the assessment
   *
   * @generated from protobuf field: string assessment_name = 3;
   */
  assessmentName: string;
  /**
   * The individual marks for questions in the assessment
   *
   * @generated from protobuf field: repeated sparx.assessment.v1.Mark marks = 4;
   */
  marks: Mark[];
  /**
   * The timestamp when the student assessment was created
   *
   * @generated from protobuf field: google.protobuf.Timestamp created = 5;
   */
  created?: Timestamp;
  /**
   * The id of the user who created the assessment record (i.e. the teacher)
   *
   * @generated from protobuf field: string created_by = 6;
   */
  createdBy: string;
  /**
   * The time that the assessment was last updated
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_updated = 7;
   */
  lastUpdated?: Timestamp;
  /**
   * The id of the user who last updated the assessment
   *
   * @generated from protobuf field: string last_updated_by = 8;
   */
  lastUpdatedBy: string;
  /**
   * Whether the student was absent on the day of the assessment
   *
   * @generated from protobuf field: bool absent = 9;
   */
  absent: boolean;
  /**
   * The year group id of the year the student is in
   *
   * @generated from protobuf field: string year_group_id = 10;
   */
  yearGroupId: string;
}
/**
 * The mark for an individual question in an assessment
 *
 * @generated from protobuf message sparx.assessment.v1.Mark
 */
export interface Mark {
  /**
   * The resource name of the question
   *
   * @generated from protobuf field: string assessment_question_name = 1;
   */
  assessmentQuestionName: string;
  /**
   * The student's score for the question
   * Omitted if no score has been entered yet
   *
   * @generated from protobuf field: optional int32 score = 2;
   */
  score?: number;
  /**
   * Whether the student attempted the question
   * In general a non-zero score will always be considered attempted,
   * this field is to denote the difference between a 0 when the student
   * attempted the question and one where they did not
   *
   * @generated from protobuf field: bool attempted = 3;
   */
  attempted: boolean;
  /**
   * Whether this mark requires a review. When this mark has been reviewed
   * this flag can be unset.
   *
   * @generated from protobuf field: bool requires_review = 4;
   */
  requiresReview: boolean;
}
/**
 * @generated from protobuf message sparx.assessment.v1.Link
 */
export interface Link {
  /**
   * Display name for the link
   *
   * @generated from protobuf field: string display_name = 1;
   */
  displayName: string;
  /**
   * URL for the download
   *
   * @generated from protobuf field: string url = 2;
   */
  url: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetGroupSettingsRequest
 */
export interface GetGroupSettingsRequest {
  /**
   * The resource name of the group
   * `/schools/{school_id}/studentGroups/{student_group_id}`
   *
   * @generated from protobuf field: string group_name = 1;
   */
  groupName: string;
  /**
   * The resource name of the assessment
   *
   * @generated from protobuf field: string assessment_name = 2;
   */
  assessmentName: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.ListGroupSettingsRequest
 */
export interface ListGroupSettingsRequest {
  /**
   * All settings for this school
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The resource name of the assessment
   *
   * @generated from protobuf field: string assessment_name = 2;
   */
  assessmentName: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.ListGroupSettingsResponse
 */
export interface ListGroupSettingsResponse {
  /**
   * All settings for this school
   *
   * @generated from protobuf field: repeated sparx.assessment.v1.GroupSettings settings = 1;
   */
  settings: GroupSettings[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.Settings
 */
export interface Settings {
  /**
   * The time that the settings were last updated
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_updated = 1;
   */
  lastUpdated?: Timestamp;
  /**
   * The id of the user who last updated the settings
   *
   * @generated from protobuf field: string last_updated_by = 2;
   */
  lastUpdatedBy: string;
  /**
   * Time that fix up was made active for this group
   *
   * @generated from protobuf field: google.protobuf.Timestamp fix_up_started_at = 3;
   */
  fixUpStartedAt?: Timestamp;
  /**
   * Time that fix up was ended for this group
   *
   * @generated from protobuf field: google.protobuf.Timestamp fix_up_ended_at = 4;
   */
  fixUpEndedAt?: Timestamp;
  /**
   * Time that revision was made active for this group
   *
   * @generated from protobuf field: google.protobuf.Timestamp revision_started_at = 5;
   */
  revisionStartedAt?: Timestamp;
  /**
   * Time that revision was ended for this group
   *
   * @generated from protobuf field: google.protobuf.Timestamp revision_ended_at = 6;
   */
  revisionEndedAt?: Timestamp;
  /**
   * If set, this records that the revision is to be used for all assessments in the assessment group with the given
   * name (which the assessment is a member of)
   *
   * @generated from protobuf field: string revision_assessment_group_name = 7;
   */
  revisionAssessmentGroupName: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GroupSettings
 */
export interface GroupSettings {
  /**
   * The resource name of the group
   * `/schools/{school_id}/studentGroups/{student_group_id}`
   *
   * @generated from protobuf field: string group_name = 1;
   */
  groupName: string;
  /**
   * The resource name of the assessment
   *
   * @generated from protobuf field: string assessment_name = 2;
   */
  assessmentName: string;
  /**
   * All settings for this group
   *
   * @generated from protobuf field: sparx.assessment.v1.Settings settings = 3;
   */
  settings?: Settings;
}
/**
 * @generated from protobuf message sparx.assessment.v1.PackageProgress
 */
export interface PackageProgress {
  /**
   * ID of the package
   *
   * @generated from protobuf field: string package_id = 1;
   */
  packageId: string;
  /**
   * Type of package. This will be one of the assessment specific
   * package types
   *
   * @generated from protobuf field: sparx.packages.v1.PackageType package_type = 2;
   */
  packageType: PackageType;
  /**
   * ID of the student this package is for
   *
   * @generated from protobuf field: string student_id = 3;
   */
  studentId: string;
  /**
   * Name of the assessment this package is for
   *
   * @generated from protobuf field: string assessment_name = 4;
   */
  assessmentName: string;
  /**
   * School the student belongs to
   *
   * @generated from protobuf field: string school_id = 5;
   */
  schoolId: string;
  /**
   * Number of task items set in the package
   *
   * @generated from protobuf field: int32 num_task_items = 6;
   */
  numTaskItems: number;
  /**
   * Number of task items the student has completed in the package
   *
   * @generated from protobuf field: int32 num_task_items_complete = 7;
   */
  numTaskItemsComplete: number;
  /**
   * Number of task items the student has got wrong in the package
   *
   * @generated from protobuf field: int32 num_task_items_wrong = 8;
   */
  numTaskItemsWrong: number;
  /**
   * Number of task items the student has got wrong and had watched the video
   * for in the package
   *
   * @generated from protobuf field: int32 num_task_items_video_wrong = 9;
   */
  numTaskItemsVideoWrong: number;
  /**
   * Number of tasks in the package
   *
   * @generated from protobuf field: int32 num_tasks = 10;
   */
  numTasks: number;
  /**
   * Number of completed tasks in the package
   *
   * @generated from protobuf field: int32 num_tasks_complete = 11;
   */
  numTasksComplete: number;
  /**
   * Number of tasks in progress in the package
   *
   * @generated from protobuf field: int32 num_tasks_in_progress = 12;
   */
  numTasksInProgress: number;
  /**
   * Number of question attempts while completing the package
   *
   * @generated from protobuf field: int32 num_attempts = 13;
   */
  numAttempts: number;
  /**
   * Flag if the assessment had ever been complete
   *
   * @generated from protobuf field: bool has_been_complete = 14;
   */
  hasBeenComplete: boolean;
  /**
   * The revision of the package. The number will increase whenever there has
   * been a change in the package information
   *
   * @generated from protobuf field: int32 revision = 15;
   */
  revision: number;
  /**
   * Timestamp of when the package was first completed
   *
   * @generated from protobuf field: google.protobuf.Timestamp completion_date = 16;
   */
  completionDate?: Timestamp;
  /**
   * The number of seconds spent on this package
   *
   * @generated from protobuf field: double time_taken = 17;
   */
  timeTaken: number;
  /**
   * The state of the package
   *
   * @generated from protobuf field: string package_state = 18;
   */
  packageState: string;
  /**
   * Time spent doing question (truncated for each activity)
   *
   * @generated from protobuf field: double truncated_work_time = 19;
   */
  truncatedWorkTime: number;
  /**
   * Time spent watching videos (truncated for each activity)
   *
   * @generated from protobuf field: double truncated_watch_time = 20;
   */
  truncatedWatchTime: number;
  /**
   * Current status of the package
   *
   * @generated from protobuf field: sparx.homeworkman.v1.PackageStatus status = 21;
   */
  status: PackageStatus;
  /**
   * Information about size and progress of the package. The progess field
   * will contain a map of CompletionType (e.g. Complete = "C", SeekHelp = "SH")
   * to numeric value of that CompletionType. The package is complete if the
   * Complete progress value is at least equal to size.
   *
   * @generated from protobuf field: sparx.packages.v1.Completion completion = 22;
   */
  completion?: Completion;
}
/**
 * @generated from protobuf message sparx.assessment.v1.ListAssessmentsRequest
 */
export interface ListAssessmentsRequest {
  /**
   * Whether to include Assessment Group Assessments from the list. Default to false if not set
   *
   * @generated from protobuf field: bool include_assessment_group_assessments = 1;
   */
  includeAssessmentGroupAssessments: boolean;
  /**
   * Subject the assessment is for in the format `subjects/key`. If not set,
   * then the endpoint will default to `subjects/maths`. To select all subjects,
   * use the name `subjects/-`.
   *
   * @generated from protobuf field: string subject_name = 2;
   */
  subjectName: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.ListAssessmentsResponse
 */
export interface ListAssessmentsResponse {
  /**
   * List of all assessments
   *
   * @generated from protobuf field: repeated sparx.assessment.v1.Assessment assessments = 1;
   */
  assessments: Assessment[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetAssessmentRequest
 */
export interface GetAssessmentRequest {
  /**
   * The resource name of the assessment
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetAssessmentGroupRequest
 */
export interface GetAssessmentGroupRequest {
  /**
   * The resource name of the assessment group in the form `assessmentgroups/<id>`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetAssessmentGroupResponse
 */
export interface GetAssessmentGroupResponse {
  /**
   * @generated from protobuf field: sparx.assessment.v1.AssessmentGroup assessment_group = 1;
   */
  assessmentGroup?: AssessmentGroup;
}
/**
 * @generated from protobuf message sparx.assessment.v1.ListAssessmentGroupsRequest
 */
export interface ListAssessmentGroupsRequest {
  /**
   * Subject the assessment is for in the format `subjects/key`. If not set,
   * then the endpoint will default to `subjects/maths`. To select all subjects,
   * use the name `subjects/-`.
   *
   * @generated from protobuf field: string subject_name = 1;
   */
  subjectName: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.ListAssessmentGroupsResponse
 */
export interface ListAssessmentGroupsResponse {
  /**
   * @generated from protobuf field: repeated sparx.assessment.v1.AssessmentGroup assessment_groups = 1;
   */
  assessmentGroups: AssessmentGroup[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.ListLatestAssessmentSnapshotsRequest
 */
export interface ListLatestAssessmentSnapshotsRequest {
  /**
   * The resource name of the school in the format schools/*
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.YearGroupIDs
 */
export interface YearGroupIDs {
  /**
   * @generated from protobuf field: repeated string year_group_ids = 1;
   */
  yearGroupIds: string[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.ListLatestAssessmentSnapshotsResponse
 */
export interface ListLatestAssessmentSnapshotsResponse {
  /**
   * Map of assessment name to timestamp of the latest snapshot
   *
   * @generated from protobuf field: map<string, google.protobuf.Timestamp> assessment_snapshots = 1;
   */
  assessmentSnapshots: {
    [key: string]: Timestamp;
  };
  /**
   * Map of assessment name to year groups in the school that have data included in the snapshot
   *
   * @generated from protobuf field: map<string, sparx.assessment.v1.YearGroupIDs> assessment_year_groups = 2;
   */
  assessmentYearGroups: {
    [key: string]: YearGroupIDs;
  };
}
/**
 * @generated from protobuf message sparx.assessment.v1.ListAssessmentSnapshotsRequest
 */
export interface ListAssessmentSnapshotsRequest {
  /**
   * The resource name of the school in the format schools/*
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Required. The start date of the range to search for snapshots
   *
   * @generated from protobuf field: google.protobuf.Timestamp start_date = 2;
   */
  startDate?: Timestamp;
  /**
   * Required. The end date of the range to search for snapshots
   *
   * @generated from protobuf field: google.protobuf.Timestamp end_date = 3;
   */
  endDate?: Timestamp;
}
/**
 * @generated from protobuf message sparx.assessment.v1.ListAssessmentSnapshotsResponse
 */
export interface ListAssessmentSnapshotsResponse {
  /**
   * Map of assessment name to timestamp of the latest snapshot
   *
   * @generated from protobuf field: map<string, google.protobuf.Timestamp> assessment_snapshots = 1;
   */
  assessmentSnapshots: {
    [key: string]: Timestamp;
  };
  /**
   * Map of assessment name to year groups in the school that have data included in the snapshot
   *
   * @generated from protobuf field: map<string, sparx.assessment.v1.YearGroupIDs> assessment_year_groups = 2;
   */
  assessmentYearGroups: {
    [key: string]: YearGroupIDs;
  };
}
/**
 * @generated from protobuf message sparx.assessment.v1.ListStudentAssessmentsRequest
 */
export interface ListStudentAssessmentsRequest {
  /**
   * The resource name of the school in the format schools/*
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The resource name of the assessment
   *
   * @generated from protobuf field: string assessment_name = 2;
   */
  assessmentName: string;
  /**
   * A list of student ids to find results for (mandatory)
   *
   * @generated from protobuf field: repeated string student_ids = 3;
   */
  studentIds: string[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.ListStudentAssessmentsResponse
 */
export interface ListStudentAssessmentsResponse {
  /**
   * @generated from protobuf field: repeated sparx.assessment.v1.StudentAssessment student_assessments = 1;
   */
  studentAssessments: StudentAssessment[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.BatchGetStudentAssessmentsRequest
 */
export interface BatchGetStudentAssessmentsRequest {
  /**
   * The resource name of the school in the format schools/*
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The resource name of the assessments
   *
   * @generated from protobuf field: repeated string assessment_names = 2;
   */
  assessmentNames: string[];
  /**
   * A list of student ids to find results for (mandatory)
   *
   * @generated from protobuf field: repeated string student_ids = 3;
   */
  studentIds: string[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.BatchGetStudentAssessmentsResponse
 */
export interface BatchGetStudentAssessmentsResponse {
  /**
   * List of student assessments belonging to any student/assessment combination from the request.
   * If one doesn't exist in the db then a blank one will be returned.
   *
   * @generated from protobuf field: repeated sparx.assessment.v1.StudentAssessment student_assessments = 1;
   */
  studentAssessments: StudentAssessment[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.ListAllStudentAssessmentsRequest
 */
export interface ListAllStudentAssessmentsRequest {
  /**
   * Requested page size. Server may return fewer results.
   *
   * @generated from protobuf field: int32 page_size = 1;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return. Should be the
   * value of [ListAllStudentAssessmentsResponse.next_page_token] from a previous
   * request.
   *
   * @generated from protobuf field: string page_token = 2;
   */
  pageToken: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.ListAllStudentAssessmentsResponse
 */
export interface ListAllStudentAssessmentsResponse {
  /**
   * The student assessments
   *
   * @generated from protobuf field: repeated sparx.assessment.v1.StudentAssessment student_assessments = 1;
   */
  studentAssessments: StudentAssessment[];
  /**
   * A token to retrieve the next page of results.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetOwnAssessmentDataRequest
 */
export interface GetOwnAssessmentDataRequest {
  /**
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * Subject name in the format `subjects/*` to filter the topics on when using
   * the wildcard curriculum.
   * If the field is not set it defaults to 'subjects/maths'.
   * To match all subjects use "subjects/-".
   *
   * @generated from protobuf field: string subject_name = 5;
   */
  subjectName: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetOwnAssessmentDataResponse
 */
export interface GetOwnAssessmentDataResponse {
  /**
   * @generated from protobuf field: repeated sparx.assessment.v1.OwnAssessmentData results = 1;
   */
  results: OwnAssessmentData[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.OwnAssessmentData
 */
export interface OwnAssessmentData {
  /**
   * @generated from protobuf field: sparx.assessment.v1.GroupSettings group_settings = 1;
   */
  groupSettings?: GroupSettings;
  /**
   * @generated from protobuf field: sparx.assessment.v1.Assessment assessment = 2;
   */
  assessment?: Assessment;
  /**
   * @generated from protobuf field: sparx.assessment.v1.StudentAssessment student_assessment = 3;
   */
  studentAssessment?: StudentAssessment;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetOwnAssessmentPackagesRequest
 */
export interface GetOwnAssessmentPackagesRequest {
  /**
   * @generated from protobuf field: string assessment_name = 1;
   */
  assessmentName: string;
  /**
   * @generated from protobuf field: string school_id = 2;
   */
  schoolId: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetOwnAssessmentPackagesResponse
 */
export interface GetOwnAssessmentPackagesResponse {
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageData package_data = 1;
   */
  packageData?: PackageData;
}
/**
 * @generated from protobuf message sparx.assessment.v1.ListAssessmentPackageProgressRequest
 */
export interface ListAssessmentPackageProgressRequest {
  /**
   * The resource name of the school in the format schools/*
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The resource name for the assessments
   *
   * @generated from protobuf field: string assessment_name = 2;
   */
  assessmentName: string;
  /**
   * The package type. This service can only handle packages
   * stored in the `assessment.package` table.
   *
   * @generated from protobuf field: sparx.packages.v1.PackageType package_type = 3;
   */
  packageType: PackageType;
  /**
   * A list of student IDs for find assessment packages for
   *
   * @generated from protobuf field: repeated string student_ids = 4;
   */
  studentIds: string[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.ListAssessmentPackageProgressResponse
 */
export interface ListAssessmentPackageProgressResponse {
  /**
   * Map of student ID to their assessment package
   *
   * @generated from protobuf field: map<string, sparx.assessment.v1.PackageProgress> assessment_packages = 1;
   */
  assessmentPackages: {
    [key: string]: PackageProgress;
  };
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetAssessmentInsightsRequest
 */
export interface GetAssessmentInsightsRequest {
  /**
   * The resource name of the school in the format schools/*
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * ID of the assessment to get insights for
   *
   * @generated from protobuf field: string assessment_name = 2;
   */
  assessmentName: string;
  /**
   * Student IDs to get insights for
   *
   * @generated from protobuf field: repeated string student_ids = 3;
   */
  studentIds: string[];
  /**
   * How many results we want. If none provided it will default to 5
   *
   * @generated from protobuf field: int32 n_results = 4;
   */
  nResults: number;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetAssessmentInsightsResponse
 */
export interface GetAssessmentInsightsResponse {
  /**
   * @generated from protobuf field: repeated sparx.assessment.v1.AssessmentInsightsQuestion questions = 1;
   */
  questions: AssessmentInsightsQuestion[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.AssessmentInsightsQuestion
 */
export interface AssessmentInsightsQuestion {
  /**
   * Number of students who have had this question marked
   *
   * @generated from protobuf field: int32 number_marked = 1;
   */
  numberMarked: number;
  /**
   * Number of students who didn't get full marks on the question
   *
   * @generated from protobuf field: int32 number_incorrect = 2;
   */
  numberIncorrect: number;
  /**
   * Question for the assessment skill in the assessment question
   *
   * @generated from protobuf field: sparx.dataanalysis.homeworkinsights.v1.HelpTheClassSkillQuestion assessment_skill_question = 3;
   */
  assessmentSkillQuestion?: HelpTheClassSkillQuestion;
  /**
   * Questions for the online skill in the assessment question
   *
   * @generated from protobuf field: repeated sparx.dataanalysis.homeworkinsights.v1.HelpTheClassSkillQuestion online_skill_questions = 4;
   */
  onlineSkillQuestions: HelpTheClassSkillQuestion[];
  /**
   * Information for the topics associated with the question
   *
   * @generated from protobuf field: repeated sparx.assessment.v1.AssessmentInsightsTopicInformation topics = 5;
   */
  topics: AssessmentInsightsTopicInformation[];
  /**
   * Display name of the question, e.g. 1, 2a, 2b etc.
   *
   * @generated from protobuf field: string display_name = 6;
   */
  displayName: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.AssessmentInsightsTopicInformation
 */
export interface AssessmentInsightsTopicInformation {
  /**
   * Human readable title of the topic
   *
   * @generated from protobuf field: string topic_title = 1;
   */
  topicTitle: string;
  /**
   * Code of the topic
   *
   * @generated from protobuf field: string topic_code = 2;
   */
  topicCode: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetNationalComparisonReportRequest
 */
export interface GetNationalComparisonReportRequest {
  /**
   * The resource name of the school
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The resource name of the assessment
   *
   * @generated from protobuf field: string assessment_name = 2;
   */
  assessmentName: string;
  /**
   * The year the QLA report should be generated for (0 means undefined and will default to the latest year)
   *
   * @generated from protobuf field: int32 year = 3;
   */
  year: number;
  /**
   * Resource names of the schools to provide the comparison data for. If no school names are
   * provided then the national benchmark will be used for comparison data.
   *
   * @generated from protobuf field: repeated string comparison_school_names = 4;
   */
  comparisonSchoolNames: string[];
  /**
   * The year group in the school that the assessment report will cover - should match the year group of the
   * assessment
   *
   * @generated from protobuf field: string year_group_id = 5;
   */
  yearGroupId: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetNationalComparisonReportDryRunRequest
 */
export interface GetNationalComparisonReportDryRunRequest {
  /**
   * The resource name of the school
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The resource name of the assessment
   *
   * @generated from protobuf field: string assessment_name = 2;
   */
  assessmentName: string;
  /**
   * Resource names of the schools to provide the comparison data for. If no school names are
   * provided then the national benchmark will be used for comparison data.
   *
   * @generated from protobuf field: repeated string comparison_school_names = 3;
   */
  comparisonSchoolNames: string[];
  /**
   * The year group in the school that the assessment report will cover - should match the year group of the
   * assessment
   *
   * @generated from protobuf field: string year_group_id = 4;
   */
  yearGroupId: string;
  /**
   * The dry run tag to use for the report
   *
   * @generated from protobuf field: string dry_run_tag = 5;
   */
  dryRunTag: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.AverageByUnit
 */
export interface AverageByUnit {
  /**
   * Average marks for all the fluency questions in a unit
   *
   * @generated from protobuf field: float fluency = 1;
   */
  fluency: number;
  /**
   * Average marks for all the problem solving questions in a unit
   *
   * @generated from protobuf field: float problem_solving = 2;
   */
  problemSolving: number;
  /**
   * Average marks for all questions in a unit
   *
   * @generated from protobuf field: float total = 3;
   */
  total: number;
}
/**
 * @generated from protobuf message sparx.assessment.v1.NationalComparisonReportData
 */
export interface NationalComparisonReportData {
  /**
   * Number of schools in this data set
   *
   * @generated from protobuf field: int32 number_of_schools = 1;
   */
  numberOfSchools: number;
  /**
   * Number of students in this data set
   *
   * @generated from protobuf field: int32 number_of_students = 2;
   */
  numberOfStudents: number;
  /**
   * Average mark for all students in this data set
   *
   * @generated from protobuf field: float average_mark = 3;
   */
  averageMark: number;
  /**
   * Map of total marks in an assessment to percentage of students who scored that mark
   *
   * @generated from protobuf field: map<int32, float> score_distribution = 4;
   */
  scoreDistribution: {
    [key: number]: number;
  };
  /**
   * Map of unit name to AverageByUnit data
   *
   * @generated from protobuf field: map<string, sparx.assessment.v1.AverageByUnit> average_by_unit = 5;
   */
  averageByUnit: {
    [key: string]: AverageByUnit;
  };
  /**
   * Map of question name to average mark for all students in the data set
   *
   * @generated from protobuf field: map<string, float> average_by_question = 6;
   */
  averageByQuestion: {
    [key: string]: number;
  };
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetNationalComparisonReportResponse
 */
export interface GetNationalComparisonReportResponse {
  /**
   * The assessment the response is for
   *
   * @generated from protobuf field: sparx.assessment.v1.Assessment assessment = 1;
   */
  assessment?: Assessment;
  /**
   * Data for the school the response is for
   *
   * @generated from protobuf field: sparx.assessment.v1.NationalComparisonReportData school_data = 2;
   */
  schoolData?: NationalComparisonReportData;
  /**
   * Data for the national benchmark, or the selected schools if provided by the request
   *
   * @generated from protobuf field: sparx.assessment.v1.NationalComparisonReportData national_data = 3;
   */
  nationalData?: NationalComparisonReportData;
  /**
   * The time the snapshot data was created
   *
   * @generated from protobuf field: google.protobuf.Timestamp snapshot_created_at = 4;
   */
  snapshotCreatedAt?: Timestamp;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetTrustNationalComparisonReportRequest
 */
export interface GetTrustNationalComparisonReportRequest {
  /**
   * The resource name of the assessment
   *
   * @generated from protobuf field: string assessment_name = 1;
   */
  assessmentName: string;
  /**
   * The year group IDs to generate the report for
   *
   * @generated from protobuf field: repeated string year_group_ids = 2;
   */
  yearGroupIds: string[];
  /**
   * The dry run tag to use for the report
   *
   * @generated from protobuf field: string dry_run_tag = 3;
   */
  dryRunTag: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetAssessmentRevisionRequest
 */
export interface GetAssessmentRevisionRequest {
  /**
   * The resource name of the assessment revision in the form `assessment/<id>/revision` where
   * `assessment/<id>` is the resource name of the assessment this revision is associated with
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetAssessmentRevisionResponse
 */
export interface GetAssessmentRevisionResponse {
  /**
   * @generated from protobuf field: sparx.assessment.v1.AssessmentRevision assessment_revision = 1;
   */
  assessmentRevision?: AssessmentRevision;
}
/**
 * @generated from protobuf message sparx.assessment.v1.ListAssessmentRevisionsRequest
 */
export interface ListAssessmentRevisionsRequest {}
/**
 * @generated from protobuf message sparx.assessment.v1.ListAssessmentRevisionsResponse
 */
export interface ListAssessmentRevisionsResponse {
  /**
   * @generated from protobuf field: repeated sparx.assessment.v1.AssessmentRevision assessment_revisions = 1;
   */
  assessmentRevisions: AssessmentRevision[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.AssessmentRevision
 */
export interface AssessmentRevision {
  /**
   * The resource name of the assessment revision in the form `assessment/<id>/revision` where
   * `assessment/<id>` is the resource name of the assessment this revision is associated with
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Version info for this assessment revision
   *
   * @generated from protobuf field: sparx.assessment.v1.AssessmentVersionInfo version_info = 2;
   */
  versionInfo?: AssessmentVersionInfo;
  /**
   * Units in the assessment revision
   *
   * @generated from protobuf field: repeated sparx.assessment.v1.AssessmentRevisionUnit units = 3;
   */
  units: AssessmentRevisionUnit[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.AssessmentRevisionUnit
 */
export interface AssessmentRevisionUnit {
  /**
   * Display name of the unit
   *
   * @generated from protobuf field: string display_name = 1;
   */
  displayName: string;
  /**
   * Questions in the unit
   *
   * @generated from protobuf field: repeated sparx.assessment.v1.AssessmentRevisionQuestion questions = 2;
   */
  questions: AssessmentRevisionQuestion[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.AssessmentRevisionQuestion
 */
export interface AssessmentRevisionQuestion {
  /**
   * Resource names of Curriculum Topics most relevant for this question
   *
   * @generated from protobuf field: repeated string curriculum_topic_names = 1;
   */
  curriculumTopicNames: string[];
  /**
   * Resource name of the skill in the question in the form `skills/<id>`
   *
   * @generated from protobuf field: string skill_name = 2;
   */
  skillName: string;
  /**
   * Whether a calculator is allowed for this question
   *
   * @generated from protobuf field: bool calculator_allowed = 3;
   */
  calculatorAllowed: boolean;
  /**
   * Resource name of the optional alternative skill to use in offline resources, in the form
   * `skills/<id>`
   *
   * @generated from protobuf field: string offline_skill_name = 4;
   */
  offlineSkillName: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetAssessmentSummaryRequest
 */
export interface GetAssessmentSummaryRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Subject the assessment is for in the format `subjects/key`. If not set,
   * then the endpoint will default to `subjects/maths`. To select all subjects,
   * use the name `subjects/-`.
   *
   * @generated from protobuf field: string subject_name = 2;
   */
  subjectName: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetAssessmentSummaryResponse
 */
export interface GetAssessmentSummaryResponse {
  /**
   * Assessments with related tasks for the student.
   *
   * @generated from protobuf field: repeated sparx.assessment.v1.Assessment assessments = 1;
   */
  assessments: Assessment[];
  /**
   * Active revision tasks for the student.
   *
   * @generated from protobuf field: repeated sparx.assessment.v1.GetAssessmentSummaryResponse.RevisionTask revision_tasks = 2;
   */
  revisionTasks: GetAssessmentSummaryResponse_RevisionTask[];
  /**
   * Active fix up tasks for the student.
   *
   * @generated from protobuf field: repeated sparx.assessment.v1.GetAssessmentSummaryResponse.FixUpTask fix_up_tasks = 3;
   */
  fixUpTasks: GetAssessmentSummaryResponse_FixUpTask[];
  /**
   * Assessment Groups which have a revision task for the student (which will be assigned to an assessment within the
   * group)
   *
   * @generated from protobuf field: repeated sparx.assessment.v1.AssessmentGroup assessment_groups = 4;
   */
  assessmentGroups: AssessmentGroup[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetAssessmentSummaryResponse.RevisionTask
 */
export interface GetAssessmentSummaryResponse_RevisionTask {
  /**
   * Assessment resource name that the revision task relates to. This assessment is guaranteed to
   * be in the response assessments list.
   *
   * @generated from protobuf field: string assessment_name = 1;
   */
  assessmentName: string;
  /**
   * If set, the revision for the assessment is shared within an assessment group (e.g. one revision for multiple
   * papers for a mock exam)
   *
   * @generated from protobuf field: string assessment_group_name = 2;
   */
  assessmentGroupName: string;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetAssessmentSummaryResponse.FixUpTask
 */
export interface GetAssessmentSummaryResponse_FixUpTask {
  /**
   * Assessment resource name that the fix up task relates to. This assessment is guaranteed to
   * be in the response assessments list.
   *
   * @generated from protobuf field: string assessment_name = 1;
   */
  assessmentName: string;
  /**
   * The students results from the assessment.
   *
   * @generated from protobuf field: sparx.assessment.v1.StudentAssessment student_assessment = 2;
   */
  studentAssessment?: StudentAssessment;
  /**
   * The status of the fix up task. It's important to note that this is separate to the fix up
   * package status.
   *
   * @generated from protobuf field: sparx.assessment.v1.FixUpTaskStatus status = 3;
   */
  status: FixUpTaskStatus;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetAssessmentRevisionInsightsRequest
 */
export interface GetAssessmentRevisionInsightsRequest {
  /**
   * The resource name of the school, in the format `schools/<id>`
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Name of the assessment revision to get insights for, in the format `assessments/<id>/revision`
   *
   * @generated from protobuf field: string assessment_revision_name = 2;
   */
  assessmentRevisionName: string;
  /**
   * Student IDs to get insights for
   *
   * @generated from protobuf field: repeated string student_ids = 3;
   */
  studentIds: string[];
  /**
   * How many results we want. If none provided it will default to 6
   *
   * @generated from protobuf field: int32 n_results = 4;
   */
  nResults: number;
}
/**
 * @generated from protobuf message sparx.assessment.v1.GetAssessmentRevisionInsightsResponse
 */
export interface GetAssessmentRevisionInsightsResponse {
  /**
   * @generated from protobuf field: repeated sparx.assessment.v1.AssessmentRevisionInsightsSkillWithQuestions skills = 1;
   */
  skills: AssessmentRevisionInsightsSkillWithQuestions[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.AssessmentRevisionInsightsSkillWithQuestions
 */
export interface AssessmentRevisionInsightsSkillWithQuestions {
  /**
   * Number of students who have attempted the skill
   *
   * @generated from protobuf field: int32 number_attempted = 1;
   */
  numberAttempted: number;
  /**
   * Number of students who answered the skill incorrectly first time
   *
   * @generated from protobuf field: int32 number_incorrect = 2;
   */
  numberIncorrect: number;
  /**
   * Skills with questions that are included in the Assessment Revision Insights
   *
   * @generated from protobuf field: repeated sparx.dataanalysis.homeworkinsights.v1.HelpTheClassSkillQuestion skills_with_questions = 3;
   */
  skillsWithQuestions: HelpTheClassSkillQuestion[];
  /**
   * Information for the topics associated with the question
   *
   * @generated from protobuf field: repeated sparx.assessment.v1.AssessmentInsightsTopicInformation topics = 4;
   */
  topics: AssessmentInsightsTopicInformation[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.BatchGetAssessmentRevisionProgressRequest
 */
export interface BatchGetAssessmentRevisionProgressRequest {
  /**
   * Resource name for the school
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The resource name of the assessment
   *
   * @generated from protobuf field: string assessment_name = 2;
   */
  assessmentName: string;
  /**
   * A list of student ids to find results for
   *
   * @generated from protobuf field: repeated string student_ids = 3;
   */
  studentIds: string[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.BatchGetAssessmentRevisionProgressResponse
 */
export interface BatchGetAssessmentRevisionProgressResponse {
  /**
   * Student ID to AssessmentRevisionProgress
   *
   * @generated from protobuf field: map<string, sparx.assessment.v1.AssessmentRevisionProgress> assessment_revision_progress = 1;
   */
  assessmentRevisionProgress: {
    [key: string]: AssessmentRevisionProgress;
  };
}
/**
 * @generated from protobuf message sparx.assessment.v1.AssessmentRevisionProgress
 */
export interface AssessmentRevisionProgress {
  /**
   * Unit display name to number of complete questions
   *
   * @generated from protobuf field: map<string, sparx.assessment.v1.RevisionProgress> unit_progress = 1;
   */
  unitProgress: {
    [key: string]: RevisionProgress;
  };
}
/**
 * @generated from protobuf message sparx.assessment.v1.RevisionProgress
 */
export interface RevisionProgress {
  /**
   * Number of task items completed
   *
   * @generated from protobuf field: int32 task_items_complete = 1;
   */
  taskItemsComplete: number;
  /**
   * Time spent on this unit
   *
   * @generated from protobuf field: int32 time_spent = 2;
   */
  timeSpent: number;
  /**
   * Last time the student attempted a question in this unit
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_attempted = 3;
   */
  lastAttempted?: Timestamp;
}
/**
 * @generated from protobuf enum sparx.assessment.v1.AssessmentStatus
 */
export enum AssessmentStatus {
  /**
   * @generated from protobuf enum value: ASSESSMENT_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: ASSESSMENT_STATUS_COMING_SOON = 1;
   */
  COMING_SOON = 1,
  /**
   * @generated from protobuf enum value: ASSESSMENT_STATUS_READY = 2;
   */
  READY = 2,
  /**
   * @generated from protobuf enum value: ASSESSMENT_STATUS_WILL_NOT_DO = 3;
   */
  WILL_NOT_DO = 3,
}
/**
 * @generated from protobuf enum sparx.assessment.v1.QuestionType
 */
export enum QuestionType {
  /**
   * @generated from protobuf enum value: QUESTION_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: QUESTION_TYPE_FLUENCY = 1;
   */
  FLUENCY = 1,
  /**
   * @generated from protobuf enum value: QUESTION_TYPE_PROBLEM_SOLVING = 2;
   */
  PROBLEM_SOLVING = 2,
}
/**
 * Represents the status of a fix up task for a student. This is separate to a fix up package
 * status.
 *
 * @generated from protobuf enum sparx.assessment.v1.FixUpTaskStatus
 */
export enum FixUpTaskStatus {
  /**
   * @generated from protobuf enum value: UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * Fix up is available for the student - we should be able to generate a fix up package.
   *
   * @generated from protobuf enum value: AVAILABLE = 1;
   */
  AVAILABLE = 1,
  /**
   * The student got full marks on the assessment and there is no fix up to do.
   *
   * @generated from protobuf enum value: FULL_MARKS = 2;
   */
  FULL_MARKS = 2,
  /**
   * Teacher has not input any QLA data for the student.
   *
   * @generated from protobuf enum value: NO_DATA = 3;
   */
  NO_DATA = 3,
  /**
   * The student was absent for the assessment.
   *
   * @generated from protobuf enum value: ABSENT = 4;
   */
  ABSENT = 4,
}
// @generated message type with reflection information, may provide speed optimized methods
class Assessment$Type extends MessageType<Assessment> {
  constructor() {
    super(
      'sparx.assessment.v1.Assessment',
      [
        {
          no: 1,
          name: 'name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
          options: {
            'sparx.api.bigquery.v1.column': {
              key: true,
              columnName: 'assessment_name',
            },
          },
        },
        {
          no: 2,
          name: 'display_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 9,
          name: 'subject_key',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 8,
          name: 'version_info',
          kind: 'message',
          T: () => AssessmentVersionInfo,
        },
        {
          no: 4,
          name: 'questions',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => AssessmentQuestion,
        },
        {
          no: 5,
          name: 'teacher_description',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 10,
          name: 'student_description',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 7,
          name: 'links',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => Link,
        },
        {
          no: 11,
          name: 'time_allowed_minutes',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 12,
          name: 'calculator_allowed',
          kind: 'scalar',
          T: 8 /*ScalarType.BOOL*/,
        },
        {
          no: 13,
          name: 'additional_equipment',
          kind: 'enum',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => [
            'sparx.content.v2.AdditionalEquipment',
            AdditionalEquipment,
            'ADDITIONAL_EQUIPMENT_',
          ],
        },
        { no: 14, name: 'hidden', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
        {
          no: 15,
          name: 'report_description',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 16,
          name: 'report_month',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 17,
          name: 'report_day',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 18,
          name: 'uk_year_group',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 19,
          name: 'revision_assessment_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 20,
          name: 'status',
          kind: 'enum',
          T: () => [
            'sparx.assessment.v1.AssessmentStatus',
            AssessmentStatus,
            'ASSESSMENT_STATUS_',
          ],
        },
        { no: 21, name: 'valid_from', kind: 'message', T: () => Timestamp },
        { no: 22, name: 'valid_until', kind: 'message', T: () => Timestamp },
      ],
      {
        'sparx.api.bigquery.v1.table': {
          name: 'assessment',
          partitionColumn: 'timestamp',
          additionalColumns: [
            {
              name: 'timestamp',
              type: 'TIMESTAMP',
              mode: 'KEY',
              doc: 'When the assessment was last deployed ',
            },
          ],
        },
      },
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.Assessment
 */
export const Assessment = new Assessment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentVersionInfo$Type extends MessageType<AssessmentVersionInfo> {
  constructor() {
    super('sparx.assessment.v1.AssessmentVersionInfo', [
      {
        no: 1,
        name: 'publication_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'revision_number',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'deployed_by',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'deployed_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.AssessmentVersionInfo
 */
export const AssessmentVersionInfo = new AssessmentVersionInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentQuestion$Type extends MessageType<AssessmentQuestion> {
  constructor() {
    super('sparx.assessment.v1.AssessmentQuestion', [
      {
        no: 1,
        name: 'name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
        options: {
          'sparx.api.bigquery.v1.column': {
            columnName: 'assessment_question_name',
          },
        },
      },
      {
        no: 2,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'available_marks',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'curriculum_topic_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 5, name: 'unit', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 9,
        name: 'assessment_skill',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'online_skill',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 8,
        name: 'type',
        kind: 'enum',
        T: () => [
          'sparx.assessment.v1.QuestionType',
          QuestionType,
          'QUESTION_TYPE_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.AssessmentQuestion
 */
export const AssessmentQuestion = new AssessmentQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentGroup$Type extends MessageType<AssessmentGroup> {
  constructor() {
    super(
      'sparx.assessment.v1.AssessmentGroup',
      [
        {
          no: 1,
          name: 'name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
          options: {
            'sparx.api.bigquery.v1.column': {
              key: true,
              columnName: 'assessment_group_name',
            },
          },
        },
        {
          no: 2,
          name: 'version_info',
          kind: 'message',
          T: () => AssessmentVersionInfo,
        },
        {
          no: 3,
          name: 'subject_key',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 4,
          name: 'assessment_names',
          kind: 'scalar',
          repeat: 2 /*RepeatType.UNPACKED*/,
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 5,
          name: 'display_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 6,
          name: 'uk_year_group',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
        { no: 7, name: 'valid_from', kind: 'message', T: () => Timestamp },
        { no: 8, name: 'valid_until', kind: 'message', T: () => Timestamp },
      ],
      {
        'sparx.api.bigquery.v1.table': {
          name: 'assessment_group',
          partitionColumn: 'timestamp',
          additionalColumns: [
            {
              name: 'timestamp',
              type: 'TIMESTAMP',
              mode: 'KEY',
              doc: 'When the assessment group was last deployed.',
            },
          ],
        },
      },
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.AssessmentGroup
 */
export const AssessmentGroup = new AssessmentGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentAssessment$Type extends MessageType<StudentAssessment> {
  constructor() {
    super(
      'sparx.assessment.v1.StudentAssessment',
      [
        {
          no: 1,
          name: 'school_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'student_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
          options: { 'sparx.api.bigquery.v1.column': { key: true } },
        },
        {
          no: 3,
          name: 'assessment_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
          options: { 'sparx.api.bigquery.v1.column': { key: true } },
        },
        {
          no: 4,
          name: 'marks',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => Mark,
        },
        { no: 5, name: 'created', kind: 'message', T: () => Timestamp },
        {
          no: 6,
          name: 'created_by',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 7,
          name: 'last_updated',
          kind: 'message',
          T: () => Timestamp,
          options: {
            'sparx.api.bigquery.v1.column': {
              key: true,
              columnName: 'timestamp',
            },
          },
        },
        {
          no: 8,
          name: 'last_updated_by',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 9, name: 'absent', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
        {
          no: 10,
          name: 'year_group_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
      ],
      {
        'sparx.api.bigquery.v1.table': {
          name: 'student_assessment',
          partitionColumn: 'timestamp',
        },
      },
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.StudentAssessment
 */
export const StudentAssessment = new StudentAssessment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Mark$Type extends MessageType<Mark> {
  constructor() {
    super('sparx.assessment.v1.Mark', [
      {
        no: 1,
        name: 'assessment_question_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'score',
        kind: 'scalar',
        opt: true,
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 3, name: 'attempted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 4,
        name: 'requires_review',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.Mark
 */
export const Mark = new Mark$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Link$Type extends MessageType<Link> {
  constructor() {
    super('sparx.assessment.v1.Link', [
      {
        no: 1,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'url', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.Link
 */
export const Link = new Link$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGroupSettingsRequest$Type extends MessageType<GetGroupSettingsRequest> {
  constructor() {
    super('sparx.assessment.v1.GetGroupSettingsRequest', [
      { no: 1, name: 'group_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetGroupSettingsRequest
 */
export const GetGroupSettingsRequest = new GetGroupSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListGroupSettingsRequest$Type extends MessageType<ListGroupSettingsRequest> {
  constructor() {
    super('sparx.assessment.v1.ListGroupSettingsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.ListGroupSettingsRequest
 */
export const ListGroupSettingsRequest = new ListGroupSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListGroupSettingsResponse$Type extends MessageType<ListGroupSettingsResponse> {
  constructor() {
    super('sparx.assessment.v1.ListGroupSettingsResponse', [
      {
        no: 1,
        name: 'settings',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => GroupSettings,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.ListGroupSettingsResponse
 */
export const ListGroupSettingsResponse = new ListGroupSettingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Settings$Type extends MessageType<Settings> {
  constructor() {
    super('sparx.assessment.v1.Settings', [
      { no: 1, name: 'last_updated', kind: 'message', T: () => Timestamp },
      {
        no: 2,
        name: 'last_updated_by',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'fix_up_started_at', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'fix_up_ended_at', kind: 'message', T: () => Timestamp },
      {
        no: 5,
        name: 'revision_started_at',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 6, name: 'revision_ended_at', kind: 'message', T: () => Timestamp },
      {
        no: 7,
        name: 'revision_assessment_group_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.Settings
 */
export const Settings = new Settings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GroupSettings$Type extends MessageType<GroupSettings> {
  constructor() {
    super(
      'sparx.assessment.v1.GroupSettings',
      [
        {
          no: 1,
          name: 'group_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
          options: { 'sparx.api.bigquery.v1.column': { key: true } },
        },
        {
          no: 2,
          name: 'assessment_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
          options: { 'sparx.api.bigquery.v1.column': { key: true } },
        },
        { no: 3, name: 'settings', kind: 'message', T: () => Settings },
      ],
      {
        'sparx.api.bigquery.v1.table': {
          name: 'assessment_group_settings',
          partitionColumn: 'timestamp',
          additionalColumns: [
            {
              name: 'timestamp',
              type: 'TIMESTAMP',
              mode: 'KEY',
              doc: 'When the assessment was last deployed ',
            },
            { name: 'group_id', doc: 'Group ID as parsed from the group name' },
            {
              name: 'school_id',
              doc: 'School ID as parsed from the group name',
            },
          ],
        },
      },
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GroupSettings
 */
export const GroupSettings = new GroupSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageProgress$Type extends MessageType<PackageProgress> {
  constructor() {
    super('sparx.assessment.v1.PackageProgress', [
      { no: 1, name: 'package_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'package_type',
        kind: 'enum',
        T: () => ['sparx.packages.v1.PackageType', PackageType],
      },
      { no: 3, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 5, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'num_task_items',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'num_task_items_complete',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 8,
        name: 'num_task_items_wrong',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 9,
        name: 'num_task_items_video_wrong',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 10, name: 'num_tasks', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 11,
        name: 'num_tasks_complete',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 12,
        name: 'num_tasks_in_progress',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 13,
        name: 'num_attempts',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 14,
        name: 'has_been_complete',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 15, name: 'revision', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 16, name: 'completion_date', kind: 'message', T: () => Timestamp },
      {
        no: 17,
        name: 'time_taken',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
      {
        no: 18,
        name: 'package_state',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 19,
        name: 'truncated_work_time',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
      {
        no: 20,
        name: 'truncated_watch_time',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
      {
        no: 21,
        name: 'status',
        kind: 'enum',
        T: () => ['sparx.homeworkman.v1.PackageStatus', PackageStatus],
      },
      { no: 22, name: 'completion', kind: 'message', T: () => Completion },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.PackageProgress
 */
export const PackageProgress = new PackageProgress$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentsRequest$Type extends MessageType<ListAssessmentsRequest> {
  constructor() {
    super('sparx.assessment.v1.ListAssessmentsRequest', [
      {
        no: 1,
        name: 'include_assessment_group_assessments',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 2,
        name: 'subject_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.ListAssessmentsRequest
 */
export const ListAssessmentsRequest = new ListAssessmentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentsResponse$Type extends MessageType<ListAssessmentsResponse> {
  constructor() {
    super('sparx.assessment.v1.ListAssessmentsResponse', [
      {
        no: 1,
        name: 'assessments',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Assessment,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.ListAssessmentsResponse
 */
export const ListAssessmentsResponse = new ListAssessmentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentRequest$Type extends MessageType<GetAssessmentRequest> {
  constructor() {
    super('sparx.assessment.v1.GetAssessmentRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetAssessmentRequest
 */
export const GetAssessmentRequest = new GetAssessmentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentGroupRequest$Type extends MessageType<GetAssessmentGroupRequest> {
  constructor() {
    super('sparx.assessment.v1.GetAssessmentGroupRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetAssessmentGroupRequest
 */
export const GetAssessmentGroupRequest = new GetAssessmentGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentGroupResponse$Type extends MessageType<GetAssessmentGroupResponse> {
  constructor() {
    super('sparx.assessment.v1.GetAssessmentGroupResponse', [
      {
        no: 1,
        name: 'assessment_group',
        kind: 'message',
        T: () => AssessmentGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetAssessmentGroupResponse
 */
export const GetAssessmentGroupResponse = new GetAssessmentGroupResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentGroupsRequest$Type extends MessageType<ListAssessmentGroupsRequest> {
  constructor() {
    super('sparx.assessment.v1.ListAssessmentGroupsRequest', [
      {
        no: 1,
        name: 'subject_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.ListAssessmentGroupsRequest
 */
export const ListAssessmentGroupsRequest =
  new ListAssessmentGroupsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentGroupsResponse$Type extends MessageType<ListAssessmentGroupsResponse> {
  constructor() {
    super('sparx.assessment.v1.ListAssessmentGroupsResponse', [
      {
        no: 1,
        name: 'assessment_groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssessmentGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.ListAssessmentGroupsResponse
 */
export const ListAssessmentGroupsResponse =
  new ListAssessmentGroupsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLatestAssessmentSnapshotsRequest$Type extends MessageType<ListLatestAssessmentSnapshotsRequest> {
  constructor() {
    super('sparx.assessment.v1.ListLatestAssessmentSnapshotsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.ListLatestAssessmentSnapshotsRequest
 */
export const ListLatestAssessmentSnapshotsRequest =
  new ListLatestAssessmentSnapshotsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class YearGroupIDs$Type extends MessageType<YearGroupIDs> {
  constructor() {
    super('sparx.assessment.v1.YearGroupIDs', [
      {
        no: 1,
        name: 'year_group_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.YearGroupIDs
 */
export const YearGroupIDs = new YearGroupIDs$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLatestAssessmentSnapshotsResponse$Type extends MessageType<ListLatestAssessmentSnapshotsResponse> {
  constructor() {
    super('sparx.assessment.v1.ListLatestAssessmentSnapshotsResponse', [
      {
        no: 1,
        name: 'assessment_snapshots',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => Timestamp },
      },
      {
        no: 2,
        name: 'assessment_year_groups',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => YearGroupIDs },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.ListLatestAssessmentSnapshotsResponse
 */
export const ListLatestAssessmentSnapshotsResponse =
  new ListLatestAssessmentSnapshotsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentSnapshotsRequest$Type extends MessageType<ListAssessmentSnapshotsRequest> {
  constructor() {
    super('sparx.assessment.v1.ListAssessmentSnapshotsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'start_date', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'end_date', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.ListAssessmentSnapshotsRequest
 */
export const ListAssessmentSnapshotsRequest =
  new ListAssessmentSnapshotsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentSnapshotsResponse$Type extends MessageType<ListAssessmentSnapshotsResponse> {
  constructor() {
    super('sparx.assessment.v1.ListAssessmentSnapshotsResponse', [
      {
        no: 1,
        name: 'assessment_snapshots',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => Timestamp },
      },
      {
        no: 2,
        name: 'assessment_year_groups',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => YearGroupIDs },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.ListAssessmentSnapshotsResponse
 */
export const ListAssessmentSnapshotsResponse =
  new ListAssessmentSnapshotsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentAssessmentsRequest$Type extends MessageType<ListStudentAssessmentsRequest> {
  constructor() {
    super('sparx.assessment.v1.ListStudentAssessmentsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'student_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.ListStudentAssessmentsRequest
 */
export const ListStudentAssessmentsRequest =
  new ListStudentAssessmentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentAssessmentsResponse$Type extends MessageType<ListStudentAssessmentsResponse> {
  constructor() {
    super('sparx.assessment.v1.ListStudentAssessmentsResponse', [
      {
        no: 1,
        name: 'student_assessments',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentAssessment,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.ListStudentAssessmentsResponse
 */
export const ListStudentAssessmentsResponse =
  new ListStudentAssessmentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetStudentAssessmentsRequest$Type extends MessageType<BatchGetStudentAssessmentsRequest> {
  constructor() {
    super('sparx.assessment.v1.BatchGetStudentAssessmentsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assessment_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'student_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.BatchGetStudentAssessmentsRequest
 */
export const BatchGetStudentAssessmentsRequest =
  new BatchGetStudentAssessmentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetStudentAssessmentsResponse$Type extends MessageType<BatchGetStudentAssessmentsResponse> {
  constructor() {
    super('sparx.assessment.v1.BatchGetStudentAssessmentsResponse', [
      {
        no: 1,
        name: 'student_assessments',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentAssessment,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.BatchGetStudentAssessmentsResponse
 */
export const BatchGetStudentAssessmentsResponse =
  new BatchGetStudentAssessmentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAllStudentAssessmentsRequest$Type extends MessageType<ListAllStudentAssessmentsRequest> {
  constructor() {
    super('sparx.assessment.v1.ListAllStudentAssessmentsRequest', [
      { no: 1, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.ListAllStudentAssessmentsRequest
 */
export const ListAllStudentAssessmentsRequest =
  new ListAllStudentAssessmentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAllStudentAssessmentsResponse$Type extends MessageType<ListAllStudentAssessmentsResponse> {
  constructor() {
    super('sparx.assessment.v1.ListAllStudentAssessmentsResponse', [
      {
        no: 1,
        name: 'student_assessments',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentAssessment,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.ListAllStudentAssessmentsResponse
 */
export const ListAllStudentAssessmentsResponse =
  new ListAllStudentAssessmentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOwnAssessmentDataRequest$Type extends MessageType<GetOwnAssessmentDataRequest> {
  constructor() {
    super('sparx.assessment.v1.GetOwnAssessmentDataRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'subject_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetOwnAssessmentDataRequest
 */
export const GetOwnAssessmentDataRequest =
  new GetOwnAssessmentDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOwnAssessmentDataResponse$Type extends MessageType<GetOwnAssessmentDataResponse> {
  constructor() {
    super('sparx.assessment.v1.GetOwnAssessmentDataResponse', [
      {
        no: 1,
        name: 'results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => OwnAssessmentData,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetOwnAssessmentDataResponse
 */
export const GetOwnAssessmentDataResponse =
  new GetOwnAssessmentDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OwnAssessmentData$Type extends MessageType<OwnAssessmentData> {
  constructor() {
    super('sparx.assessment.v1.OwnAssessmentData', [
      {
        no: 1,
        name: 'group_settings',
        kind: 'message',
        T: () => GroupSettings,
      },
      { no: 2, name: 'assessment', kind: 'message', T: () => Assessment },
      {
        no: 3,
        name: 'student_assessment',
        kind: 'message',
        T: () => StudentAssessment,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.OwnAssessmentData
 */
export const OwnAssessmentData = new OwnAssessmentData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOwnAssessmentPackagesRequest$Type extends MessageType<GetOwnAssessmentPackagesRequest> {
  constructor() {
    super('sparx.assessment.v1.GetOwnAssessmentPackagesRequest', [
      {
        no: 1,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetOwnAssessmentPackagesRequest
 */
export const GetOwnAssessmentPackagesRequest =
  new GetOwnAssessmentPackagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOwnAssessmentPackagesResponse$Type extends MessageType<GetOwnAssessmentPackagesResponse> {
  constructor() {
    super('sparx.assessment.v1.GetOwnAssessmentPackagesResponse', [
      { no: 1, name: 'package_data', kind: 'message', T: () => PackageData },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetOwnAssessmentPackagesResponse
 */
export const GetOwnAssessmentPackagesResponse =
  new GetOwnAssessmentPackagesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentPackageProgressRequest$Type extends MessageType<ListAssessmentPackageProgressRequest> {
  constructor() {
    super('sparx.assessment.v1.ListAssessmentPackageProgressRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'package_type',
        kind: 'enum',
        T: () => ['sparx.packages.v1.PackageType', PackageType],
      },
      {
        no: 4,
        name: 'student_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.ListAssessmentPackageProgressRequest
 */
export const ListAssessmentPackageProgressRequest =
  new ListAssessmentPackageProgressRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentPackageProgressResponse$Type extends MessageType<ListAssessmentPackageProgressResponse> {
  constructor() {
    super('sparx.assessment.v1.ListAssessmentPackageProgressResponse', [
      {
        no: 1,
        name: 'assessment_packages',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => PackageProgress },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.ListAssessmentPackageProgressResponse
 */
export const ListAssessmentPackageProgressResponse =
  new ListAssessmentPackageProgressResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentInsightsRequest$Type extends MessageType<GetAssessmentInsightsRequest> {
  constructor() {
    super('sparx.assessment.v1.GetAssessmentInsightsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'student_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'n_results', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetAssessmentInsightsRequest
 */
export const GetAssessmentInsightsRequest =
  new GetAssessmentInsightsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentInsightsResponse$Type extends MessageType<GetAssessmentInsightsResponse> {
  constructor() {
    super('sparx.assessment.v1.GetAssessmentInsightsResponse', [
      {
        no: 1,
        name: 'questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssessmentInsightsQuestion,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetAssessmentInsightsResponse
 */
export const GetAssessmentInsightsResponse =
  new GetAssessmentInsightsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentInsightsQuestion$Type extends MessageType<AssessmentInsightsQuestion> {
  constructor() {
    super('sparx.assessment.v1.AssessmentInsightsQuestion', [
      {
        no: 1,
        name: 'number_marked',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'number_incorrect',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'assessment_skill_question',
        kind: 'message',
        T: () => HelpTheClassSkillQuestion,
      },
      {
        no: 4,
        name: 'online_skill_questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HelpTheClassSkillQuestion,
      },
      {
        no: 5,
        name: 'topics',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssessmentInsightsTopicInformation,
      },
      {
        no: 6,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.AssessmentInsightsQuestion
 */
export const AssessmentInsightsQuestion = new AssessmentInsightsQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentInsightsTopicInformation$Type extends MessageType<AssessmentInsightsTopicInformation> {
  constructor() {
    super('sparx.assessment.v1.AssessmentInsightsTopicInformation', [
      {
        no: 1,
        name: 'topic_title',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'topic_code', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.AssessmentInsightsTopicInformation
 */
export const AssessmentInsightsTopicInformation =
  new AssessmentInsightsTopicInformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNationalComparisonReportRequest$Type extends MessageType<GetNationalComparisonReportRequest> {
  constructor() {
    super('sparx.assessment.v1.GetNationalComparisonReportRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'year', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 4,
        name: 'comparison_school_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'year_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetNationalComparisonReportRequest
 */
export const GetNationalComparisonReportRequest =
  new GetNationalComparisonReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNationalComparisonReportDryRunRequest$Type extends MessageType<GetNationalComparisonReportDryRunRequest> {
  constructor() {
    super('sparx.assessment.v1.GetNationalComparisonReportDryRunRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'comparison_school_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'year_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'dry_run_tag',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetNationalComparisonReportDryRunRequest
 */
export const GetNationalComparisonReportDryRunRequest =
  new GetNationalComparisonReportDryRunRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AverageByUnit$Type extends MessageType<AverageByUnit> {
  constructor() {
    super('sparx.assessment.v1.AverageByUnit', [
      { no: 1, name: 'fluency', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      {
        no: 2,
        name: 'problem_solving',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      { no: 3, name: 'total', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.AverageByUnit
 */
export const AverageByUnit = new AverageByUnit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NationalComparisonReportData$Type extends MessageType<NationalComparisonReportData> {
  constructor() {
    super('sparx.assessment.v1.NationalComparisonReportData', [
      {
        no: 1,
        name: 'number_of_schools',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'number_of_students',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'average_mark',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 4,
        name: 'score_distribution',
        kind: 'map',
        K: 5 /*ScalarType.INT32*/,
        V: { kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      },
      {
        no: 5,
        name: 'average_by_unit',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => AverageByUnit },
      },
      {
        no: 6,
        name: 'average_by_question',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.NationalComparisonReportData
 */
export const NationalComparisonReportData =
  new NationalComparisonReportData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNationalComparisonReportResponse$Type extends MessageType<GetNationalComparisonReportResponse> {
  constructor() {
    super('sparx.assessment.v1.GetNationalComparisonReportResponse', [
      { no: 1, name: 'assessment', kind: 'message', T: () => Assessment },
      {
        no: 2,
        name: 'school_data',
        kind: 'message',
        T: () => NationalComparisonReportData,
      },
      {
        no: 3,
        name: 'national_data',
        kind: 'message',
        T: () => NationalComparisonReportData,
      },
      {
        no: 4,
        name: 'snapshot_created_at',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetNationalComparisonReportResponse
 */
export const GetNationalComparisonReportResponse =
  new GetNationalComparisonReportResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTrustNationalComparisonReportRequest$Type extends MessageType<GetTrustNationalComparisonReportRequest> {
  constructor() {
    super('sparx.assessment.v1.GetTrustNationalComparisonReportRequest', [
      {
        no: 1,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'year_group_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'dry_run_tag',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetTrustNationalComparisonReportRequest
 */
export const GetTrustNationalComparisonReportRequest =
  new GetTrustNationalComparisonReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentRevisionRequest$Type extends MessageType<GetAssessmentRevisionRequest> {
  constructor() {
    super('sparx.assessment.v1.GetAssessmentRevisionRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetAssessmentRevisionRequest
 */
export const GetAssessmentRevisionRequest =
  new GetAssessmentRevisionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentRevisionResponse$Type extends MessageType<GetAssessmentRevisionResponse> {
  constructor() {
    super('sparx.assessment.v1.GetAssessmentRevisionResponse', [
      {
        no: 1,
        name: 'assessment_revision',
        kind: 'message',
        T: () => AssessmentRevision,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetAssessmentRevisionResponse
 */
export const GetAssessmentRevisionResponse =
  new GetAssessmentRevisionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentRevisionsRequest$Type extends MessageType<ListAssessmentRevisionsRequest> {
  constructor() {
    super('sparx.assessment.v1.ListAssessmentRevisionsRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.ListAssessmentRevisionsRequest
 */
export const ListAssessmentRevisionsRequest =
  new ListAssessmentRevisionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentRevisionsResponse$Type extends MessageType<ListAssessmentRevisionsResponse> {
  constructor() {
    super('sparx.assessment.v1.ListAssessmentRevisionsResponse', [
      {
        no: 1,
        name: 'assessment_revisions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssessmentRevision,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.ListAssessmentRevisionsResponse
 */
export const ListAssessmentRevisionsResponse =
  new ListAssessmentRevisionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentRevision$Type extends MessageType<AssessmentRevision> {
  constructor() {
    super(
      'sparx.assessment.v1.AssessmentRevision',
      [
        {
          no: 1,
          name: 'name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
          options: {
            'sparx.api.bigquery.v1.column': {
              key: true,
              columnName: 'assessment_revision_name',
            },
          },
        },
        {
          no: 2,
          name: 'version_info',
          kind: 'message',
          T: () => AssessmentVersionInfo,
        },
        {
          no: 3,
          name: 'units',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => AssessmentRevisionUnit,
        },
      ],
      {
        'sparx.api.bigquery.v1.table': {
          name: 'assessment_revision',
          partitionColumn: 'timestamp',
          additionalColumns: [
            {
              name: 'timestamp',
              type: 'TIMESTAMP',
              mode: 'KEY',
              doc: 'When the assessment revision was last deployed ',
            },
          ],
        },
      },
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.AssessmentRevision
 */
export const AssessmentRevision = new AssessmentRevision$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentRevisionUnit$Type extends MessageType<AssessmentRevisionUnit> {
  constructor() {
    super('sparx.assessment.v1.AssessmentRevisionUnit', [
      {
        no: 1,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssessmentRevisionQuestion,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.AssessmentRevisionUnit
 */
export const AssessmentRevisionUnit = new AssessmentRevisionUnit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentRevisionQuestion$Type extends MessageType<AssessmentRevisionQuestion> {
  constructor() {
    super('sparx.assessment.v1.AssessmentRevisionQuestion', [
      {
        no: 1,
        name: 'curriculum_topic_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'skill_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'calculator_allowed',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 4,
        name: 'offline_skill_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.AssessmentRevisionQuestion
 */
export const AssessmentRevisionQuestion = new AssessmentRevisionQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentSummaryRequest$Type extends MessageType<GetAssessmentSummaryRequest> {
  constructor() {
    super('sparx.assessment.v1.GetAssessmentSummaryRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'subject_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetAssessmentSummaryRequest
 */
export const GetAssessmentSummaryRequest =
  new GetAssessmentSummaryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentSummaryResponse$Type extends MessageType<GetAssessmentSummaryResponse> {
  constructor() {
    super('sparx.assessment.v1.GetAssessmentSummaryResponse', [
      {
        no: 1,
        name: 'assessments',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Assessment,
      },
      {
        no: 2,
        name: 'revision_tasks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => GetAssessmentSummaryResponse_RevisionTask,
      },
      {
        no: 3,
        name: 'fix_up_tasks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => GetAssessmentSummaryResponse_FixUpTask,
      },
      {
        no: 4,
        name: 'assessment_groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssessmentGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetAssessmentSummaryResponse
 */
export const GetAssessmentSummaryResponse =
  new GetAssessmentSummaryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentSummaryResponse_RevisionTask$Type extends MessageType<GetAssessmentSummaryResponse_RevisionTask> {
  constructor() {
    super('sparx.assessment.v1.GetAssessmentSummaryResponse.RevisionTask', [
      {
        no: 1,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assessment_group_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetAssessmentSummaryResponse.RevisionTask
 */
export const GetAssessmentSummaryResponse_RevisionTask =
  new GetAssessmentSummaryResponse_RevisionTask$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentSummaryResponse_FixUpTask$Type extends MessageType<GetAssessmentSummaryResponse_FixUpTask> {
  constructor() {
    super('sparx.assessment.v1.GetAssessmentSummaryResponse.FixUpTask', [
      {
        no: 1,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'student_assessment',
        kind: 'message',
        T: () => StudentAssessment,
      },
      {
        no: 3,
        name: 'status',
        kind: 'enum',
        T: () => ['sparx.assessment.v1.FixUpTaskStatus', FixUpTaskStatus],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetAssessmentSummaryResponse.FixUpTask
 */
export const GetAssessmentSummaryResponse_FixUpTask =
  new GetAssessmentSummaryResponse_FixUpTask$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentRevisionInsightsRequest$Type extends MessageType<GetAssessmentRevisionInsightsRequest> {
  constructor() {
    super('sparx.assessment.v1.GetAssessmentRevisionInsightsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assessment_revision_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'student_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'n_results', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetAssessmentRevisionInsightsRequest
 */
export const GetAssessmentRevisionInsightsRequest =
  new GetAssessmentRevisionInsightsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentRevisionInsightsResponse$Type extends MessageType<GetAssessmentRevisionInsightsResponse> {
  constructor() {
    super('sparx.assessment.v1.GetAssessmentRevisionInsightsResponse', [
      {
        no: 1,
        name: 'skills',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssessmentRevisionInsightsSkillWithQuestions,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.GetAssessmentRevisionInsightsResponse
 */
export const GetAssessmentRevisionInsightsResponse =
  new GetAssessmentRevisionInsightsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentRevisionInsightsSkillWithQuestions$Type extends MessageType<AssessmentRevisionInsightsSkillWithQuestions> {
  constructor() {
    super('sparx.assessment.v1.AssessmentRevisionInsightsSkillWithQuestions', [
      {
        no: 1,
        name: 'number_attempted',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'number_incorrect',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'skills_with_questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HelpTheClassSkillQuestion,
      },
      {
        no: 4,
        name: 'topics',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssessmentInsightsTopicInformation,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.AssessmentRevisionInsightsSkillWithQuestions
 */
export const AssessmentRevisionInsightsSkillWithQuestions =
  new AssessmentRevisionInsightsSkillWithQuestions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetAssessmentRevisionProgressRequest$Type extends MessageType<BatchGetAssessmentRevisionProgressRequest> {
  constructor() {
    super('sparx.assessment.v1.BatchGetAssessmentRevisionProgressRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'student_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.BatchGetAssessmentRevisionProgressRequest
 */
export const BatchGetAssessmentRevisionProgressRequest =
  new BatchGetAssessmentRevisionProgressRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetAssessmentRevisionProgressResponse$Type extends MessageType<BatchGetAssessmentRevisionProgressResponse> {
  constructor() {
    super('sparx.assessment.v1.BatchGetAssessmentRevisionProgressResponse', [
      {
        no: 1,
        name: 'assessment_revision_progress',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => AssessmentRevisionProgress },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.BatchGetAssessmentRevisionProgressResponse
 */
export const BatchGetAssessmentRevisionProgressResponse =
  new BatchGetAssessmentRevisionProgressResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentRevisionProgress$Type extends MessageType<AssessmentRevisionProgress> {
  constructor() {
    super('sparx.assessment.v1.AssessmentRevisionProgress', [
      {
        no: 1,
        name: 'unit_progress',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => RevisionProgress },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.AssessmentRevisionProgress
 */
export const AssessmentRevisionProgress = new AssessmentRevisionProgress$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RevisionProgress$Type extends MessageType<RevisionProgress> {
  constructor() {
    super('sparx.assessment.v1.RevisionProgress', [
      {
        no: 1,
        name: 'task_items_complete',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 2, name: 'time_spent', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'last_attempted', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.RevisionProgress
 */
export const RevisionProgress = new RevisionProgress$Type();
/**
 * @generated ServiceType for protobuf service sparx.assessment.v1.AssessmentService
 */
export const AssessmentService = new ServiceType(
  'sparx.assessment.v1.AssessmentService',
  [
    {
      name: 'ListAssessments',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:assessment',
          domain: 'tpcommon',
        },
      },
      I: ListAssessmentsRequest,
      O: ListAssessmentsResponse,
    },
    {
      name: 'GetAssessment',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:assessment',
          domain: 'tpcommon',
        },
      },
      I: GetAssessmentRequest,
      O: Assessment,
    },
    {
      name: 'ListLatestAssessmentSnapshots',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:assessment',
          domain: 'tpcommon',
        },
      },
      I: ListLatestAssessmentSnapshotsRequest,
      O: ListLatestAssessmentSnapshotsResponse,
    },
    {
      name: 'ListAssessmentSnapshots',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:studentassessment',
          domain: '{school_name}',
        },
      },
      I: ListAssessmentSnapshotsRequest,
      O: ListAssessmentSnapshotsResponse,
    },
    {
      name: 'ListStudentAssessments',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:studentassessment',
          domain: '{school_name}',
        },
      },
      I: ListStudentAssessmentsRequest,
      O: ListStudentAssessmentsResponse,
    },
    {
      name: 'BatchGetStudentAssessments',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:studentassessment',
          domain: '{school_name}',
        },
      },
      I: BatchGetStudentAssessmentsRequest,
      O: BatchGetStudentAssessmentsResponse,
    },
    {
      name: 'ListAllStudentAssessments',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:reportingadmin',
          domain: 'reportingadmin',
        },
      },
      I: ListAllStudentAssessmentsRequest,
      O: ListAllStudentAssessmentsResponse,
    },
    {
      name: 'UpdateStudentAssessment',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'assessment:studentassessment',
          domain: '{school_name}',
        },
      },
      I: StudentAssessment,
      O: StudentAssessment,
    },
    {
      name: 'GetGroupSettings',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:groupsettings',
          domain: '{group_name|parent}',
        },
      },
      I: GetGroupSettingsRequest,
      O: GroupSettings,
    },
    {
      name: 'ListGroupSettings',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:groupsettings',
          domain: '{school_name}',
        },
      },
      I: ListGroupSettingsRequest,
      O: ListGroupSettingsResponse,
    },
    {
      name: 'UpdateGroupSettings',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'assessment:groupsettings',
          domain: '{group_name|parent}',
        },
      },
      I: GroupSettings,
      O: GroupSettings,
    },
    {
      name: 'ListAssessmentPackageProgress',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:packages',
          domain: '{school_name}',
        },
      },
      I: ListAssessmentPackageProgressRequest,
      O: ListAssessmentPackageProgressResponse,
    },
    {
      name: 'GetAssessmentInsights',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:studentassessment',
          domain: '{school_name}',
        },
      },
      I: GetAssessmentInsightsRequest,
      O: GetAssessmentInsightsResponse,
    },
    {
      name: 'GetNationalComparisonReport',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:studentassessment',
          domain: '{school_name}',
        },
      },
      I: GetNationalComparisonReportRequest,
      O: GetNationalComparisonReportResponse,
    },
    {
      name: 'GetNationalComparisonReportDryRun',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:reportingadmin',
          domain: 'reportingadmin',
        },
      },
      I: GetNationalComparisonReportDryRunRequest,
      O: GetNationalComparisonReportResponse,
    },
    {
      name: 'GetTrustNationalReport',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:reportingadmin',
          domain: 'reportingadmin',
        },
      },
      I: GetTrustNationalComparisonReportRequest,
      O: GetNationalComparisonReportResponse,
    },
    {
      name: 'GetAssessmentRevision',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:assessment_revision',
          domain: 'tpcommon',
        },
      },
      I: GetAssessmentRevisionRequest,
      O: GetAssessmentRevisionResponse,
    },
    {
      name: 'ListAssessmentRevisions',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:assessment_revision',
          domain: 'tpcommon',
        },
      },
      I: ListAssessmentRevisionsRequest,
      O: ListAssessmentRevisionsResponse,
    },
    {
      name: 'GetAssessmentRevisionInsights',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:assessment_revision',
          domain: '{school_name}',
        },
      },
      I: GetAssessmentRevisionInsightsRequest,
      O: GetAssessmentRevisionInsightsResponse,
    },
    {
      name: 'BatchGetAssessmentRevisionProgress',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:assessment_revision',
          domain: '{school_name}',
        },
      },
      I: BatchGetAssessmentRevisionProgressRequest,
      O: BatchGetAssessmentRevisionProgressResponse,
    },
    {
      name: 'GetOwnAssessmentData',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:ownstudentassessment',
          domain: 'sw',
        },
      },
      I: GetOwnAssessmentDataRequest,
      O: GetOwnAssessmentDataResponse,
    },
    {
      name: 'GetOwnAssessmentPackages',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:ownassessmentpackages',
          domain: 'sw',
        },
      },
      I: GetOwnAssessmentPackagesRequest,
      O: GetOwnAssessmentPackagesResponse,
    },
    {
      name: 'GetAssessmentSummary',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:ownstudentassessment',
          domain: 'sw',
        },
      },
      I: GetAssessmentSummaryRequest,
      O: GetAssessmentSummaryResponse,
    },
    {
      name: 'GetAssessmentGroup',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:assessment_group',
          domain: 'tpcommon',
        },
      },
      I: GetAssessmentGroupRequest,
      O: GetAssessmentGroupResponse,
    },
    {
      name: 'ListAssessmentGroups',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessment:assessment_group',
          domain: 'tpcommon',
        },
      },
      I: ListAssessmentGroupsRequest,
      O: ListAssessmentGroupsResponse,
    },
  ],
);
