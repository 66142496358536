import { HStack, Switch, SwitchProps, Text } from '@chakra-ui/react';

export const ToggleSwitch = ({
  checked,
  setChecked,
  leftLabel,
  rightLabel,
  offColour,
  onColour,
  enableColourBG,
  ...switchProps
}: {
  checked: boolean;
  setChecked: (checked: boolean) => void;
  leftLabel: string;
  rightLabel: string;
  offColour?: string;
  onColour?: string;
  enableColourBG?: boolean;
} & SwitchProps) => (
  <HStack>
    <Text
      cursor="pointer"
      color={checked ? offColour || 'whiteAlpha.600' : onColour}
      fontSize="small"
      onClick={() => checked && setChecked(false)}
    >
      {leftLabel}
    </Text>
    <Switch
      sx={
        !enableColourBG
          ? {
              '.chakra-switch__track': {
                backgroundColor: 'gray.300',
              },
            }
          : undefined
      }
      isChecked={checked}
      onChange={e => setChecked(e.target.checked)}
      {...switchProps}
    />
    <Text
      cursor="pointer"
      color={!checked ? offColour || 'whiteAlpha.600' : onColour}
      fontSize="small"
      onClick={() => !checked && setChecked(true)}
    >
      {rightLabel}
    </Text>
  </HStack>
);
