import { ModalBody, Textarea } from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  ActivityPreparationProps,
  createBasicLessonActivityBlueprint,
  TaskBlueprint,
} from 'views/lessons/createactivity/activitytypes';

import { textToSkillBlueprintArray } from './utils';

interface ActivityBlueprint {
  title: string;
  tasks: TaskBlueprint[];
}

export const CreateTeamTeachingActivities = ({
  doSubmitMulti,
  getFooter,
}: ActivityPreparationProps) => {
  const [input, setInput] = useState('');
  const [activityBlueprints, setActivityBlueprints] = useState<ActivityBlueprint[]>([]);

  const onSubmit = async () =>
    doSubmitMulti(
      activityBlueprints.map(a => ({
        title: a.title,
        ...createBasicLessonActivityBlueprint(...a.tasks),
      })),
    );

  const updateInput = (value: string) => {
    value = value.replace(/"/g, '');
    setInput(value);

    try {
      const activities: { title: string; tasks: { title: string; value: string }[] }[] = [];

      const lines = value.trim().split('\n');
      for (const line of lines) {
        // Split by the first space
        const [first, ...rest] = line.split(' ');
        const split = [first, rest.length > 0 ? rest.join(' ') : ''];

        switch (split[0]) {
          case '~A':
            activities.push({ title: split[1], tasks: [] });
            break;
          case '~T':
            activities[activities.length - 1].tasks.push({ title: split[1], value: '' });
            break;
          default: {
            const activity = activities[activities.length - 1];
            activity.tasks[activity.tasks.length - 1].value += line + '\n';
            break;
          }
        }
      }

      const activityBlueprints: ActivityBlueprint[] = [];
      for (const activity of activities) {
        const tasks: TaskBlueprint[] = [];
        for (const task of activity.tasks) {
          const output: TaskBlueprint = {
            name: task.title,
            skills: textToSkillBlueprintArray(task.value, true),
          };
          if (output.skills.length === 0) {
            alert(`No skills in task ${task.title}`);
            return;
          }
          tasks.push(output);
        }
        activityBlueprints.push({ title: activity.title, tasks });
      }
      setActivityBlueprints(activityBlueprints);
      console.log(activityBlueprints);
    } catch (e) {
      console.log('Error parsing input', e);
      setActivityBlueprints([]);
    }
  };

  return (
    <>
      <ModalBody>
        <Textarea
          bg="white"
          fontFamily="monospace"
          height={300}
          value={input}
          onChange={e => updateInput(e.target.value)}
          placeholder="Enter lesson twiddle structure..."
        />
        {input.length > 0 && activityBlueprints.length === 0 && 'Invalid input'}
        {input.length > 0 &&
          activityBlueprints.length > 0 &&
          `Got ${activityBlueprints.length} activities`}
      </ModalBody>
      {getFooter(activityBlueprints.length > 0 ? onSubmit : undefined)}
    </>
  );
};
