// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/lessons/v1/notes.proto" (package "sparx.science.lessons.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.science.lessons.v1.StudentNotes
 */
export interface StudentNotes {
  /**
   * Notes on the student.
   *
   * @generated from protobuf field: string text = 1;
   */
  text: string;
  /**
   * The time that the notes were updated.
   *
   * @generated from protobuf field: google.protobuf.Timestamp update_time = 2;
   */
  updateTime?: Timestamp;
  /**
   * The ID of the user that updated the notes.
   *
   * @generated from protobuf field: string updated_user_id = 3;
   */
  updatedUserId: string;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.GetStudentNotesRequest
 */
export interface GetStudentNotesRequest {
  /**
   * Name of the student to request the notes for. In the format:
   * "schools/{school_id}/students/{student_id}"
   *
   * @generated from protobuf field: string student_name = 1;
   */
  studentName: string;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.GetStudentNotesResponse
 */
export interface GetStudentNotesResponse {
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.StudentNotes notes = 1;
   */
  notes?: StudentNotes;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.UpdateStudentNotesRequest
 */
export interface UpdateStudentNotesRequest {
  /**
   * Name of the student to update the notes for. In the format:
   * "schools/{school_id}/students/{student_id}"
   *
   * @generated from protobuf field: string student_name = 1;
   */
  studentName: string;
  /**
   * The new notes to set for the student.
   *
   * @generated from protobuf field: string text = 2;
   */
  text: string;
}
/**
 * @generated from protobuf message sparx.science.lessons.v1.UpdateStudentNotesResponse
 */
export interface UpdateStudentNotesResponse {
  /**
   * @generated from protobuf field: sparx.science.lessons.v1.StudentNotes notes = 1;
   */
  notes?: StudentNotes;
}
// @generated message type with reflection information, may provide speed optimized methods
class StudentNotes$Type extends MessageType<StudentNotes> {
  constructor() {
    super('sparx.science.lessons.v1.StudentNotes', [
      { no: 1, name: 'text', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'update_time', kind: 'message', T: () => Timestamp },
      {
        no: 3,
        name: 'updated_user_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.StudentNotes
 */
export const StudentNotes = new StudentNotes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentNotesRequest$Type extends MessageType<GetStudentNotesRequest> {
  constructor() {
    super('sparx.science.lessons.v1.GetStudentNotesRequest', [
      {
        no: 1,
        name: 'student_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.GetStudentNotesRequest
 */
export const GetStudentNotesRequest = new GetStudentNotesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentNotesResponse$Type extends MessageType<GetStudentNotesResponse> {
  constructor() {
    super('sparx.science.lessons.v1.GetStudentNotesResponse', [
      { no: 1, name: 'notes', kind: 'message', T: () => StudentNotes },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.GetStudentNotesResponse
 */
export const GetStudentNotesResponse = new GetStudentNotesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStudentNotesRequest$Type extends MessageType<UpdateStudentNotesRequest> {
  constructor() {
    super('sparx.science.lessons.v1.UpdateStudentNotesRequest', [
      {
        no: 1,
        name: 'student_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'text', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.UpdateStudentNotesRequest
 */
export const UpdateStudentNotesRequest = new UpdateStudentNotesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStudentNotesResponse$Type extends MessageType<UpdateStudentNotesResponse> {
  constructor() {
    super('sparx.science.lessons.v1.UpdateStudentNotesResponse', [
      { no: 1, name: 'notes', kind: 'message', T: () => StudentNotes },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.lessons.v1.UpdateStudentNotesResponse
 */
export const UpdateStudentNotesResponse = new UpdateStudentNotesResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.science.lessons.v1.LessonNotes
 */
export const LessonNotes = new ServiceType(
  'sparx.science.lessons.v1.LessonNotes',
  [
    {
      name: 'GetStudentNotes',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:student',
          domain: '{student_name|parent}',
        },
      },
      I: GetStudentNotesRequest,
      O: GetStudentNotesResponse,
    },
    {
      name: 'UpdateStudentNotes',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:student',
          domain: '{student_name|parent}',
        },
      },
      I: UpdateStudentNotesRequest,
      O: UpdateStudentNotesResponse,
    },
  ],
);
