import { Box, Flex, Heading, Tab, TabList, Tabs, Text } from '@chakra-ui/react';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import React from 'react';
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom';

import { IsRolloverProps, PageTab } from '..';
import { StatusIcon, TabContents } from './Tab';

export const TabsView = ({
  isRollover,
  tabs,
  rootPath,
}: IsRolloverProps & { tabs: PageTab[]; rootPath: string }) => {
  const location = useLocation();
  const tabDetails = tabs.map(({ path, detailsHook }) => ({
    path,
    ...detailsHook({ isRollover }),
  }));

  let selectedTabIndex = tabDetails.findIndex(
    tab => `/teacher${rootPath}/${tab.path}` === location.pathname,
  );
  if (selectedTabIndex < 0) {
    selectedTabIndex = 0;
  }
  const selectedTab = tabDetails[selectedTabIndex];

  if (selectedTab.status === 'locked') {
    return <Navigate to={tabs[0].path} replace />;
  }

  return (
    <Tabs
      isManual
      position="relative"
      index={selectedTabIndex}
      orientation="vertical"
      alignItems="flex-start"
      variant="unstyled"
    >
      <TabList gap={1}>
        {tabDetails.map((tab, idx) => (
          <React.Fragment key={tab.path}>
            {tab.contentBefore}
            <Tab
              as={Link}
              to={tab.path}
              _focus={{ boxShadow: 'none' }}
              _focusVisible={{ boxShadow: 'outline' }}
              backgroundColor="gray.100"
              borderColor="teal.500"
              _selected={{ backgroundColor: 'white', borderRadius: 0, borderLeftWidth: 2 }}
              justifyContent={'space-between'}
              borderTopLeftRadius={idx === 0 ? 'md' : undefined}
              borderBottomLeftRadius={idx === tabDetails.length - 1 ? 'md' : undefined}
              minHeight={16}
              textAlign="left"
              isDisabled={tab.status === 'locked'}
            >
              <TabContents {...tab} />
            </Tab>
          </React.Fragment>
        ))}
      </TabList>
      <Box
        backgroundColor="white"
        flex={1}
        padding={8}
        borderRadius="lg"
        borderTopLeftRadius={0}
        minHeight="70vh"
      >
        <Flex borderBottomWidth={1} pb={4} mb={4} justifyContent="space-between">
          <Heading size="md">{selectedTab.title}</Heading>
          {!selectedTab.statusText || typeof selectedTab.statusText === 'string' ? (
            <>
              {(selectedTab.status === 'complete' || selectedTab.status === 'error') && (
                <Text>
                  <StatusIcon status={selectedTab.status} size="lg" />{' '}
                  {selectedTab.statusText || (selectedTab.status === 'complete' ? 'Done' : '')}
                </Text>
              )}
            </>
          ) : (
            selectedTab.statusText
          )}
        </Flex>
        <SuspenseRoute>
          <Outlet />
        </SuspenseRoute>
      </Box>
    </Tabs>
  );
};
