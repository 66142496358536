import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import Button from '@sparx/mis-sync-import/src/MisSyncImport/components/common/Button/Button';
import { Info } from '@sparx/sparx-design/icons';
import classNames from 'classnames';
import { ReactNode } from 'react';

import styles from './Footer.module.css';

export type FooterVariant = 'info' | 'warning' | 'error';

export const Footer = ({
  children,
  variant,
  onSubmit,
  submitText,
  onCancel,
  isDisabled,
  unsavedChangesCount,
  groupSubject,
}: {
  unsavedChangesCount?: number;
  children: ReactNode;
  variant: FooterVariant;
  onSubmit: () => void;
  submitText: string;
  onCancel?: () => void;
  isDisabled?: boolean;
  groupSubject?: StudentGroupType;
}) => (
  <div
    className={classNames(styles.Footer, {
      [styles.Info]: variant === 'info',
      [styles.Warning]: variant === 'warning',
      [styles.Error]: variant === 'error',
    })}
  >
    <div
      className={classNames(styles.FooterText, {
        [styles.FooterTextScience]: groupSubject === StudentGroupType.CLASS_SCIENCE,
      })}
    >
      {variant === 'info' && <Info variant="Blue" className={styles.InfoIcon} />}
      {children}
    </div>
    <div className={styles.FooterButtonContainer}>
      {unsavedChangesCount !== undefined && unsavedChangesCount > 0 && (
        <div className={styles.UnsavedChanges}>
          {unsavedChangesCount} unsaved change{unsavedChangesCount === 1 ? '' : 's'}
        </div>
      )}

      <div className={styles.ButtonsRow}>
        {!!onCancel && (
          <Button colour="secondary" onClick={onCancel}>
            Cancel
          </Button>
        )}

        <Button isDisabled={isDisabled} onClick={onSubmit}>
          {submitText}
        </Button>
      </div>
    </div>
  </div>
);
