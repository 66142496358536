import { ResourceStatus } from '@sparx/api/apis/sparx/planning/v1/sol';
import { useSchoolYears } from 'api/school';
import { filterGroupsUsingSol, useSuspenseGroupsWithSettings } from 'api/scienceschool';
import { useSession } from 'api/sessions';
import { useEditableSols } from 'api/sol';
import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { AnnotatedSchemeOfLearning, SolTable } from './components/SolTable';
import { rowHasDraftVersion, rowHasPublishedVersion } from './solHelpers';

interface SolListProps {
  year: 'current' | 'next';
}

export const SolList = ({ year }: SolListProps) => {
  const { data: unsortedSols = [] } = useEditableSols({ suspense: true });
  const { data: schoolYears = [] } = useSchoolYears({ suspense: true }, false);
  const groups = useSuspenseGroupsWithSettings();

  const selectedYear = schoolYears.find(sy => (year === 'next' ? sy.next : sy.current));

  const { data: { schoolId } = { schoolId: '' } } = useSession();
  const filteredSols: AnnotatedSchemeOfLearning[] = useMemo(() => {
    const sols = unsortedSols
      .filter(
        s => `schools/${schoolId}/years/${s.schoolYear}` === selectedYear?.name, // Only show for selected year
      )
      .sort(
        (a, b) =>
          a.displayName.localeCompare(b.displayName, undefined, { numeric: true }) ||
          a.name.localeCompare(b.name) ||
          (b.metadata?.status || 0) - (a.metadata?.status || 0),
      );

    return sols.map(sol => ({
      ...sol,
      isTemplate: false,
      status: sol.metadata?.status || ResourceStatus.UNKNOWN_STATUS,
      hasDraft: Boolean(rowHasDraftVersion(sol, sols)),
      hasPublished: Boolean(rowHasPublishedVersion(sol, sols)),
      assignedGroups:
        sol.metadata?.status === ResourceStatus.PUBLISHED
          ? filterGroupsUsingSol(groups, sol.name)
          : [],
    }));
  }, [schoolId, selectedYear, unsortedSols, groups]);

  if (year === 'next' && !selectedYear) {
    // Didn't find year, this shouldn't really happen but redirect just in case
    return <Navigate to="/teacher/sol" replace={true} />;
  }

  return (
    <SolTable templateSols={false} filteredSols={filteredSols} defaultYear={selectedYear?.name} />
  );
};
