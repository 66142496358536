import { Class, Subject } from '@sparx/api/apis/sparx/misintegration/wondewitch/v1/wondewitch';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { UserInteractionEvent } from '@sparx/api/genproto/apis/uievents/uievents';
import { StudentGroupType, YearGroup } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';

export const WONDE_REGISTRATION_GROUP_SUBJECT_ID = 'REGISTRATION_GROUP';

export type SchoolData = {
  studentGroups: Group[];
  students: Record<string, Student>;
  yearGroups: Record<string, YearGroup>;
};

export type WondeData = {
  wondeClasses: Class[];
  wondeSubjects: Record<string, Subject>;
};

export const classTypeToSubjectString: Record<StudentGroupType, string> = {
  [StudentGroupType.CLASS]: 'Mathematics',
  [StudentGroupType.CLASS_ENGLISH]: 'English',
  [StudentGroupType.CLASS_SCIENCE]: 'Science',

  // Probably won't need this?
  [StudentGroupType.TUTORGROUP]: 'Tutor group',

  // Not sure what these are
  [StudentGroupType.MANUAL]: 'Manual',
  [StudentGroupType.GUEST]: 'Guest',
};

// Note: Similar lists exist in teacherportal/tpclient2/src/reducers/wonde-constants.ts

/** As specified on CLS-814, these are believed to identify most Maths classes */
export const DEFAULT_SUBJECT_SUBSTRINGS = [
  'math',
  'matem',
  '-ma-',
  '(ma)',
  'further ma',
  'statistics',
  'stats',
  'numeracy',
];

/** These are believed to identify some English classes */
export const DEFAULT_ENGLISH_SUBJECT_SUBSTRINGS = [
  'english',
  'reading',
  'library',
  'literacy',
  '(eng)',
];

/** These are believed to identify some Science classes */
export const DEFAULT_SCIENCE_SUBJECT_SUBSTRINGS = ['science', 'biology', 'chemistry', 'physics'];

/** We want to exclude classes that have the substrings above but also have the following
 * substrings. For example, we don't want to show classes with the subject "Computer Science" for
 * the Science product. */
export const SCIENCE_SUBJECT_SUBSTRINGS_TO_EXCLUDE = ['computer', 'food', 'sport'];

/**
 * This type wraps other types with a new field for specifying which year group they should belong
 * to.
 */
type WithConfiguredYearGroupId<T> = T & ConfigurableYearGroupId;

/**
 * Allows setting a year group ID. Shouldn't be used directly, see WithConfiguredYearGroupId.
 */
export type ConfigurableYearGroupId = {
  configuredYearGroupId: string;
  estimatedYearGroup: number | null;
};

/**
 * Wonde class with an extra field for configuring the year group ID.
 */
export type WondeClass = WithConfiguredYearGroupId<Class>;

/**
 * Sparx class (group) with an extra field for configuring the year group ID.
 */
export type SparxClass = WithConfiguredYearGroupId<Group>;

/**
 * Details of a student conflict - i.e. a student who is in 2 different classes for the same product.
 */
export interface ConflictingStudent {
  // The Wonde ID of the student
  studentWondeID: string;
  // The Wonde ID of the classes they are in for the product
  classWondeIDs: string[];
  // The default resolution for the conflict - i.e. the Wonde ID of the class they are currently in the school if
  // applicable
  defaultResolution?: string;
}

/**
 * These are copied from the shared analytics types. They are duplicated here to avoid issues with
 * the GA namespace not existing in Reader.
 */
type DefaultFields =
  | 'application'
  | 'schoolId'
  | 'userId'
  | 'sessionId'
  | 'connectionId'
  | 'version'
  | 'serverTimestamp';

type AutomaticFields = 'timestamp' | 'eventIndex' | 'page';

export type UserInteractionEventFieldsWithLabelsRequired = Omit<
  UserInteractionEvent,
  DefaultFields | AutomaticFields
>;
export type UserInteractionEventFields = Omit<
  UserInteractionEventFieldsWithLabelsRequired,
  'labels'
> & {
  labels?: Record<string, string | number | boolean>;
};
