import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { formatDistanceStrict, isAfter, isBefore, isSameDay, startOfDay } from 'date-fns';

interface CompletionDate {
  text?: string;
  isLate: boolean;
}

export const getCompletionDateDays = (
  due: Timestamp,
  completed?: Timestamp,
): CompletionDate | undefined => {
  const dueDate = Timestamp.toDate(due);
  if (!completed) {
    return { isLate: isAfter(new Date(), dueDate) };
  }

  const completedDate = Timestamp.toDate(completed);
  const isLate = isBefore(dueDate, completedDate);
  if (isSameDay(completedDate, dueDate)) {
    return { text: 'Hand-in day', isLate };
  }

  const suffix = isLate ? 'late' : 'early';
  const distance = formatDistanceStrict(startOfDay(dueDate), startOfDay(completedDate));
  return { text: `${distance} ${suffix}`, isLate };
};

export const getCompletionDateText = (cd: CompletionDate | undefined) => {
  if (cd?.text === undefined) {
    return 'Incomplete';
  }
  return cd.text;
};

export const getCompletionDateColour = (cd: CompletionDate | undefined) => {
  if (cd?.isLate) {
    return 'red.500';
  } else if (cd?.text === undefined) {
    return 'gray.500';
  }
  return 'gray.800';
};
