import { Box, Flex, Heading, IconButton, Text } from '@chakra-ui/react';
import { faChevronLeft, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useState } from 'react';

const CarouselItemContext = React.createContext<{ visible: boolean }>({ visible: false });

export const useCarouselItemContext = () => React.useContext(CarouselItemContext);

export const Carousel = ({
  pages,
  title,
  icon,
}: {
  pages: ReactNode[];
  title: string;
  icon: IconDefinition;
}) => {
  const [index, setIndex] = useState(0);

  const nextPage = () => setIndex(i => (i + 1) % pages.length);
  const prevPage = () => setIndex(i => (i - 1 + pages.length) % pages.length);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" mb={3}>
        <Heading flex={1} as="h3" size="md">
          <Text as="span" mr={3} ml={1} color="gray.700">
            <FontAwesomeIcon icon={icon} size="lg" />
          </Text>
          {title}
        </Heading>
        <Flex alignItems="center" visibility={pages.length > 1 ? 'visible' : 'hidden'}>
          <IconButton
            aria-label="Previous"
            variant="solid"
            size="sm"
            borderRadius="full"
            colorScheme="buttonTeal"
            onClick={prevPage}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>
          <Text w={14} textAlign="center">
            {index + 1} of {pages.length}
          </Text>
          <IconButton
            aria-label="Previous"
            variant="solid"
            size="sm"
            borderRadius="full"
            colorScheme="buttonTeal"
            onClick={nextPage}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </IconButton>
        </Flex>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        overflow="hidden"
        borderRadius="md"
        boxShadow="elevationLow"
        flex={1}
      >
        <Box
          flex={1}
          display="flex"
          alignItems="stretch"
          transform={`translateX(-${index * 100}%)`}
          transition="transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)"
        >
          {pages.map((child, i) => (
            <CarouselItemContext.Provider key={i} value={{ visible: i === index }}>
              <Flex key={i} height="100%" width="100%" flex="0 0 100%">
                {child}
              </Flex>
            </CarouselItemContext.Provider>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
